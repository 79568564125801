import { MyTabProps, MyTable, MyTableProps } from "atlas-ds";
import classNames from "classnames";
import { createRef, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export interface MyInfiniteTableProps {
  /**
   * Le tableau présenté
   */
  children: React.ReactElement<MyTableProps>;
  /**
   * Le nombre total d'éléments affichables
   */
  totalCount: number;
  /**
   * Le nombre d'éléments effectivement chargés
   */
  loadedCount: number;
  /**
   * L'action à éxécuter en fin de scroll
   */
  onScrollEnd: () => void;
  /**
   * Le header du tableau qui restera fixé en haut de l'écran au scroll
   */
  header: React.ReactNode;
  /**
   * Les différents onglets du tableau. S'il s'agit du seul onglet, il sera
   * automatiquement caché.
   */
  tabs?: React.ReactElement<MyTabProps>[];
}

/**
 * Un tableau présentant une liste de résultats à chargement au scroll.
 * Utilise la dépendance `react-infinite-scroll-component`.
 */
export function MyInfiniteTable(props: MyInfiniteTableProps) {
  const fixedRef = createRef<HTMLDivElement>();
  const tableThRef = createRef<HTMLTableRowElement>();

  useEffect(() => {
    const header = fixedRef?.current;
    if (header && tableThRef && window.ResizeObserver !== undefined) {
      const observer = new ResizeObserver(
        (entries: ReadonlyArray<ResizeObserverEntry>, _: ResizeObserver) => {
          const height = `${entries[0].contentRect.height}px`;
          tableThRef.current?.childNodes.forEach(
            (th) => ((th as HTMLElement).style.top = height)
          );
        }
      );
      observer.observe(header);

      return () => {
        observer.unobserve(header);
      };
    }
  }, [tableThRef, fixedRef]);

  return (
    <section
      className={classNames("my-infiniteTable", {
        "my-infiniteTable--hasTabs": props.tabs && props.tabs.length > 1,
      })}
    >
      <div className="my-infiniteTable__fixed" ref={fixedRef}>
        <div className="my-infiniteTable__header">{props.header}</div>
        {props.tabs && (
          <div className="my-infiniteTable__tabs">{props.tabs}</div>
        )}
      </div>
      <InfiniteScroll
        dataLength={props.loadedCount}
        next={props.onScrollEnd}
        hasMore={props.loadedCount < props.totalCount}
        loader={<></>}
      >
        <MyTable {...props.children.props} internalInfinite ref={tableThRef} />
      </InfiniteScroll>
    </section>
  );
}
