import { Step } from 'react-joyride'
import { ELEMENTS } from '../elements'

export const employeurSteps: Step[] = [
  {
    target: ELEMENTS.COMPANY_FIELD,
    content:
      "Vous pouvez choisir un établissement actif dans la liste en entrant la première lettre de l'établissement, les premiers chiffres de son SIRET ou sa commune.",
  },
  {
    target: ELEMENTS.PROGRESSION,
    content:
      'Vous pouvez revenir à tout moment sur une étape validée avec le suivi des étapes',
  },
  {
    target: ELEMENTS.TOOLBOX,
    content:
      'Retrouver des informations complémentaires sur ce dispositif dans votre boite à outils',
  },
  {
    target: ELEMENTS.INITIATE,
    content:
      'Commencer la création du dossier. Ce bouton est actif dès que tous les champs obligatoires de l’étape sont renseignés',
  },
];
