import { getOrganismesFormationParSiren } from 'api/commonAPI'
import { createApiSlice } from 'api/createApiSlice'


export const organismesSlice = createApiSlice({
    name: 'organismes/get',
    useCachedState: 'organismesState',
    getCacheKey: (state: any) => state.contextState.app + '/' + state.contextState.entreprise?.Id,
    call: getOrganismesFormationParSiren
})

export const organismesActions = {
    load: (siren?: string) => organismesSlice.actions.caller(siren)
}
