import store from 'app/store'
import axios from 'axios'
import { getRequestConfig } from './baseAPI'


export async function uploadDocumentSollicitation(idSollicitation: string, nom: string, file: File): Promise<string> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'

    const formData = new FormData()
    formData.append('nom', nom)
    formData.append('document', file)

    const { data } = await axios.post(`/sollicitations/${ idSollicitation }/documents`, formData, requestConfig)
    return data
}

export interface Sollicitation {
    Subject: string
    Priority: string
    Description: string
    ContactId?: string
    AccountId?: string
    Origin: string
    Type: string
    SousType__c: string
    DossierConcerne__c?: string
    FactureConcernee__c?: string
}

export async function postCreationSollicitation(sollicitation: Sollicitation, nomDocument?: string, document?: File | null): Promise<string> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'
    
    const formData = new FormData()
    formData.append('siren', store.getState().contextState.entreprise?.SIREN__c ?? '')
    formData.append('json', JSON.stringify(sollicitation))
    if (document && nomDocument) {
        formData.append('withDocument', 'true')
        formData.append('document', document, nomDocument)
    }
    
    const { data } = await axios.post('/sollicitations', formData, requestConfig)
    return data.id
}
