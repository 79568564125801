import { AtlasLinkProps } from "atlas-ds/atlas";
import classNames from "classnames";
import characterEntreprise from "../../../assets/img/character_entreprise.svg";
import characterPrestataire from "../../../assets/img/character_prestataire.svg";
import { MyTheme } from "../../theme";

export interface MyAppChoiceProps {
  /**
   * Les choix possibles (deux choix supportés pour le moment)
   */
  options: {
    /**
     * L'application associée à ce choix
     */
    app: "entreprise" | "prestataire";
    /**
     * Le label du choix
     */
    label: string;
    /**
     * Le lien associé à ce choix
     */
    link: React.ReactElement<AtlasLinkProps>;
  }[];
}

/**
 * Un encart permettant d'effectuer un choix lié à une application
 */
export function MyAppChoice(props: MyAppChoiceProps) {
  return (
    <div className="my-appChoice">
      <div className="my-appChoice__inner">
        {[props.options[0], props.options[1]].map((option, index) => (
          <MyTheme key={option.app} theme={option.app}>
            <div
              className={classNames("my-appChoice__option", {
                "my-appChoice__option--left": index === 0,
              })}
            >
              <div className="my-appChoice__optionInner">
                <img
                  className="my-appChoice__image"
                  src={
                    option.app === "entreprise"
                      ? characterEntreprise
                      : characterPrestataire
                  }
                  alt=""
                />

                <p
                  className="my-appChoice__label"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />

                {option.link}
              </div>
            </div>
          </MyTheme>
        ))}
      </div>
    </div>
  );
}
