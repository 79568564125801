import { createApiSlice } from 'api/createApiSlice'
import { getRefsBancaires, RefsBancairesSearchQuery } from 'api/refBancaireAPI'


export const refsBancairesSlice = createApiSlice({
    name: 'refsBancaires/get',
    call: (query: RefsBancairesSearchQuery | undefined) => query ? getRefsBancaires(query) : Promise.resolve({ totalCount: 0, items: [] }),
})

export const refsBancairesActions = {
    load: (query: RefsBancairesSearchQuery) => refsBancairesSlice.actions.caller(query),
    clear: () => refsBancairesSlice.actions.clear(),
}
