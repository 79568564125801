import { useFormContext } from '@dsid-opcoatlas/reform'
import { PieceTypeDTO, deleteDocument, uploadDocument } from 'api/documentAPI'
import { DossierPieceDTO, downloadDossierDoc, getDossierDocumentTypes } from 'api/dossierAPI'
import useApiState from 'api/useApiState'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasFlex, AtlasLoading, AtlasTooltip, MyFileCta, MyForm, MyTable } from 'atlas-ds'
import DepotFichierModal from 'components/docs/DepotFichierModal'
import TelechargerDoc from 'components/docs/TelechargerDoc'
import { formatDate } from 'components/format/Format'
import CheckboxField from 'components/reform/inputs/CheckboxField'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useState } from 'react'
import { BaseDossier } from 'slices/dossierCommon'
import { clearSteps } from 'slices/onboardingSlice'
import { useDossierNavigation } from '../useDossierNavigation'


interface DossierPiecesElementsProps<D extends BaseDossier> {
    metadata: FormMetadata<D>
    loading?: boolean
    documentTypeFilter?: (docType: PieceTypeDTO) => boolean
    piecesObligatoiresMessage?: JSX.Element
    warningMessage?: JSX.Element
}

export const piecesSubmitDisabled = (dossier: BaseDossier | null, pending: boolean) => {
    return pending || (dossier?.pieces?.find(piece => piece.obligatoire && !piece.fichier) !== undefined)
}

export function DossierPiecesElements<D extends BaseDossier>(props: DossierPiecesElementsProps<D>) {

    const dispatch = useAppDispatch()

    const [ nomFichierModal, setNomFichierModal ] = useState<string>()
    const [ { pending: deleting }, withDeleteDocument ] = useApiState(deleteDocument, { pending: false })

    const form = useFormContext<D>()
    const { step } = useDossierNavigation(props.metadata)

    const removeDocument = (doc: DossierPieceDTO) => {
        withDeleteDocument(form.values!.NumeroDossier__c!, doc.Id).then(_ => {
            const index = form.values!.pieces.findIndex(piece => piece.Id === doc.Id)
            if (index >= 0) {
                const piecesHelper = form.array<DossierPieceDTO>("pieces")!
                const piece = form.values!.pieces[index]
                if (piece.actif === true)
                    piecesHelper.replace(index, { ...piece, Id: '', dateAjout: '', fichier: ''})
                else
                    piecesHelper.remove(index)
            }
        })
    }

    const documentUploaded = (doc: DossierPieceDTO) => {
        const index = form.values!.pieces.findIndex(piece => piece.type === doc.type)
        const piece = form.values!.pieces[index]

        const piecesHelper = form.array<DossierPieceDTO>("pieces")!
        if (piece?.obligatoire === true) {
            piecesHelper.replace(index, {
                ...piece,
                Id: doc.Id,
                dateAjout: doc.dateAjout,
                fichier: doc.fichier,
                ContentVersionId: doc.ContentVersionId,
                uploadedToGed: doc.uploadedToGed
            })
        }
        else {
            piecesHelper.insert(index === -1 ? form.values!.pieces.length : index, {
                ...doc,
                obligatoire: false
            })
        }

        // Always skip the onboarding once a file is uploaded
        // This is to prevent an error on the "add file" cta which disappears
        // after the upload
        dispatch(clearSteps())
    }


    const tooltip = (doc: DossierPieceDTO) => {
        switch (doc?.type) {
            case 'JUSTIFICATIF DEROGATION AGE':
                return 'Justificatif apprenti(e) de plus de 29 ans (Copie notification rupture, attestation sur l\'honneur de l\'apprenti(e), ...)'
            case 'CONVENTION DE REDUCTION DE PARCOURS':
                return 'Convention réduction de durée de parcours'
            case 'PRECEDENT CONTRAT D APPRENTISSAGE':
                return 'Copie du précédent contrat d\'apprentissage de l\'apprenti(e)'
            case 'CONVENTION DE MISE A DISPOSITION GIE':
                return 'Convention de mise à disposition entre le jeune, le groupement d’employeurs et l\'entreprise utilisatrice'
            default:
                return undefined
        }
    }

    const docActions = (doc: DossierPieceDTO) => {
        if (doc.fichier && form.values) {
            const actions = [ <TelechargerDoc key="telechargerDoc" downloadDoc={() => downloadDossierDoc(doc, form.values!)} /> ]
            if (!doc.uploadedToGed) {
                actions.push(<AtlasButton
                    key="supprimer"
                    level={ 3 }
                    icon="trash"
                    spinner={{spinning: deleting}}
                    onClick={() => deleting || removeDocument(doc)}
                >Supprimer</AtlasButton>)
            }
            return actions
        } else return []
    }

    const documents = (form.values?.pieces ?? []).filter(d => d.obligatoire)
    const piecesComplementaires = (form.values?.pieces ?? []).filter(d => !d.obligatoire && !d.uploadedToGed)
    const piecesComplementairesAjoutees = piecesComplementaires.filter(d => d.fichier)
/*
        <Pieces<BaseDossier>
            dossier={ form.values }
            pending={ props.loading ?? false }
            api={ props.metadata.api as any } 
            documentTypeFilter={ props.documentTypeFilter }
            attestationPieces={
                step.options?.attestationPieces ?
                    <CheckboxField
                        name="attestationPieces"
                        label="L'employeur atteste disposer de l'ensemble des pièces justificatives nécessaires à l'enregistrement du contrat"
                    />
                :
                undefined
            }
            piecesObligatoiresMessage={ props.piecesObligatoiresMessage }
            warningMessage={ props.warningMessage } />
*/
    return (
        <MyForm>
            { documents && documents.length > 0 && <><MyForm.Fieldset legend="Ajout des pièces obligatoires">
                <AtlasLoading loading={ props.loading || form.values === null }>
                    <div id="pieces-obligatoires">
                        <MyTable
                            caption="Ajout des pièces obligatoires"
                            emptyText="Aucun document"
                            rowActions={docActions}
                            rows={documents}
                            rowKey={(doc) => doc.Id ? doc.Id : doc.type}
                            columns={[
                                {
                                    id: "nom",
                                    label: "Type document",
                                    value: (doc: any) => <AtlasFlex gap="xs" alignItems='center'>
                                        {doc.type + (doc.info ? ' ' + doc.info : '')}
                                        { tooltip(doc) && <AtlasTooltip>{ tooltip(doc) }</AtlasTooltip> }
                                    </AtlasFlex>
                                }, {
                                    id: "dateAjout",
                                    label: "Date d'ajout",
                                    value: (doc: any) => doc.dateAjout ? formatDate(doc.dateAjout) : 'En attente'
                                }, {
                                    id: "fichier",
                                    label: "Fichier",
                                    wrap: true,
                                    value: (doc: any) => doc?.fichier ?
                                        <p>{doc?.fichier}</p> :
                                        <MyFileCta
                                            label="Ajouter un document"
                                            onClick={ () => setNomFichierModal(doc.type) }
                                            tooltip={<AtlasTooltip title="Formats acceptés">
                                                Formats acceptés : pdf, docx, xlsx, pptx, doc, xls, ppt — 15 Mo maximum
                                            </AtlasTooltip>}
                                        />
                                }
                            ]}
                        />
                    </div>
                </AtlasLoading>
            </MyForm.Fieldset>

            { props.piecesObligatoiresMessage }
        </> }

        <MyForm.Fieldset legend="Ajout de pièces complémentaires">
            <AtlasLoading loading={ props.loading || form.values === null }>
                <AtlasFlex column gap="xl" alignItems="start">
                    <MyTable
                        caption="Ajout de pièces complémentaires"
                        rowActions={docActions}
                        emptyText="Aucune pièce"
                        rows={piecesComplementairesAjoutees}
                        columns={[
                            {
                                id: "nom",
                                label: "Type document",
                                value: (doc: any) => <AtlasFlex gap="xs" alignItems='center'>
                                    {doc.type}
                                    { tooltip(doc) && <AtlasTooltip>{ tooltip(doc) }</AtlasTooltip> }
                                </AtlasFlex>
                            },
                            {
                                id: "dateAjout",
                                label: "Date d'ajout",
                                value: (doc: any) => doc.dateAjout ? formatDate(doc.dateAjout) : 'En attente'
                            },
                            {
                                id: "fichier",
                                label: "Fichier",
                                wrap: true,
                                value: (doc: any) => doc?.fichier
                                    ? <p>{doc?.fichier}</p>
                                    : <MyFileCta
                                        label="Ajouter un document"
                                        onClick={() => setNomFichierModal(doc.type)}
                                        tooltip={<AtlasTooltip title="Formats acceptés">
                                            Formats acceptés : pdf, docx, xlsx, pptx, doc, xls, ppt — 15 Mo maximum
                                        </AtlasTooltip>}
                                    />
                            }
                        ]}
                    />

                    <AtlasButton
                        id="piece-complementaire"
                        level={ 2 }
                        icon="plus"
                        onClick={ () => setNomFichierModal('') }
                    >Ajouter une pièce complémentaire</AtlasButton>

                    { step.options?.attestationPieces &&
                        <CheckboxField
                            name="attestationPieces"
                            label="L'employeur atteste disposer de l'ensemble des pièces justificatives nécessaires à l'enregistrement du contrat"
                        />
                    }
                </AtlasFlex>
            </AtlasLoading>
        </MyForm.Fieldset>

        { nomFichierModal != null &&
        <DepotFichierModal<DossierPieceDTO> 
            idParent={ form.values?.NumeroDossier__c ?? '' }
            nom={ nomFichierModal }
            withType={ !nomFichierModal && form.values?.DispositifFO__c != null }
            getDocumentTypes={ getDossierDocumentTypes }
            withObligatoires={ false }
            documentTypeFilter={ props.documentTypeFilter }
            warningMessage={ props.warningMessage }
            // isUpdating={ props.isUpdating }
            upload={ uploadDocument }
            uploaded={ documentUploaded }
            close={ () => setNomFichierModal(undefined) }
            hideWarningFactures={ true }
        />
        }
    </MyForm>
    )
}
