import { AtlasLinkConstructor, MyNumber } from "atlas-ds";
export interface MyFeaturedNumberProps {
  /**
   * Le label
   */
  label: string;
  /**
   * Le nombre (peut être une chaîne, car accompagné d'une unité)
   */
  children: number | string;
  /**
   * Si nécessaire, un constructeur de lien qui rendra le nombre cliquable
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Un nombre mis en avant
 */
export function MyFeaturedNumber(props: MyFeaturedNumberProps) {
  const number = (
    <>
      <div className="my-featuredNumber__label">{props.label}</div>
      <MyNumber>{props.children}</MyNumber>
    </>
  );

  return (
    <div className="my-featuredNumber">
      {props.linkConstructor ? (
        props.linkConstructor(
          { className: "my-featuredNumber__number" },
          number
        )
      ) : (
        <div className="my-featuredNumber__number">{number}</div>
      )}
    </div>
  );
}
