import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  AtlasFieldBaseProps,
  AtlasFieldInternal,
} from "../../../../../atlas/components/form/fields/AtlasFieldInternal";
import { fr } from "./fr";

export interface MyFieldEditorProps
  extends Omit<AtlasFieldBaseProps<Editor>, "onChange | inputConstructor"> {
  /**
   * Une clé API TinyMCE
   */
  apiKey: string;
  /**
   * La valeur initiale du champ
   */
  initialValue?: string;
  /**
   * L'action à éxécuter lorsque la valeur saisie change
   */
  onEditorChange?: (value: string) => void;
}

/**
 * Un éditeur de HTML utilisant TinyMCE
 */
export function MyFieldEditor(props: MyFieldEditorProps) {
  const [editorBody, setEditorBody] = useState<HTMLBodyElement>();

  useEffect(() => {
    if (editorBody) {
      editorBody.setAttribute("aria-invalid", props.error ? "true" : "false");
      editorBody.setAttribute(
        "aria-label",
        `${props.label} ${props.error ? `, ${props.error}` : ""}`
      );
    }
  });

  const onInit = (event: any, editor: any) => {
    if (editor?.editorManager?.addI18n) {
      // Normally, language package should be loaded via the "langageUrl" props,
      // but this approach works better with the build system
      editor?.editorManager?.addI18n("fr_FR", fr);
    }

    if (editor?.contentDocument?.body) {
      setEditorBody(editor.contentDocument.body);
    }
  };

  return (
    <AtlasFieldInternal {...props}>
      <div
        className={classNames("my-fieldEditor", {
          "my-fieldEditor--disabled": props.disabled,
        })}
      >
        <Editor
          apiKey={props.apiKey}
          onEditorChange={props.onEditorChange}
          onInit={onInit}
          initialValue={props.initialValue}
          disabled={props.disabled}
          init={{
            menubar: false,
            statusbar: true,
            branding: false,
            elementpath: false,
            resize: true,
            plugins: "lists",
            language: "fr_FR",
            toolbar:
              "undo redo | styles | numlist bullist | bold italic underline",
            style_formats: [
              { title: "Heading 3", block: "h3" },
              { title: "Heading 4", block: "h4" },
              { title: "Heading 5", block: "h5" },
              { title: "Heading 6", block: "h6" },
            ],
          }}
        />
      </div>
    </AtlasFieldInternal>
  );
}
