import { Reference } from 'api/referencesAPI'
import { DATE_FORMAT, DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT, DISPLAY_FULL_DATE_FORMAT, REGEX_ISO_TIME, REGEX_TIME, TIME_FORMAT } from 'app/constants'
import { format, format as formatLocal, isValid, parse, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Adresse } from 'slices/dossierCommon'

const DATE = new Date()

const amountFormat = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
const euroFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })

export function parseDate(date?: any, format = DATE_FORMAT): Date | undefined {
    if (!date)
        return undefined
    if (date instanceof Date)
        return date
    if (typeof date === 'string' && (date as string).includes('T'))
        return parseISO(date)
    return parse(date, format, DATE)
}

export function formatDate(date?: Date | string | null): string {
    if (!date)
        return ''
    if (typeof date === 'string') {
        const parsedDate = (date as string).includes('T') ? parseISO(date) : parse(date, DATE_FORMAT, DATE)
        if (!isValid(parsedDate))
            return ''
        return format(parsedDate, DISPLAY_DATE_FORMAT)
    }
    if (!isValid(date))
        return ''
    return format(date, DISPLAY_DATE_FORMAT)
}

export function formatToFullDate(date?: Date | string | null): string {
    const parsedDate = (typeof date === 'string') ? stringToDate(date) : date
    return (parsedDate) ? formatLocal(parsedDate, DISPLAY_FULL_DATE_FORMAT, {locale: fr}) : ''
}

export function formatTime(time?: string | null): string {
    if(time && time.match(REGEX_ISO_TIME))
        return time.substr(0, 5)
    return (time && time.match(REGEX_TIME)) ? time : ''
}

export function formatISOTime(time?: string | null): string {
    if(time && time.match(REGEX_TIME))
        return time + ':00'
    return (time && time.match(REGEX_ISO_TIME)) ? time : ''
}

export function getTime(date?: Date | string | null): string {
    const parsedDate = (typeof date === 'string') ? stringToDate(date) : date
    return (parsedDate) ? format(parsedDate, TIME_FORMAT, {locale: fr}) : ''
}

export function normalizeInputDate(inputDate?: string | null): string | null {
    if (inputDate === undefined || inputDate === null)
        return null
    const parsedDate = parse(inputDate, DISPLAY_DATE_FORMAT, DATE)
    if (isNaN(parsedDate.getTime()))
        return null
    return format(parsedDate, DATE_FORMAT)
}

export function stringToDate(str?: string | null) : Date | null {
    if(!str)
        return null
    return str.includes('T') ? parseISO(str) : parse(str, DATE_FORMAT, DATE)
}

export function normalizeAPIDate(apiDate?: any): any {
    if (typeof apiDate !== 'string')
        return null
    // Ignore time from the API due to timezone issues
    const parsedDate = (apiDate as string).includes('T') ? parse(apiDate.substring(0, apiDate.indexOf('T')), DATE_FORMAT, DATE) : parse(apiDate, DATE_FORMAT, DATE)
    if (isNaN(parsedDate.getTime()))
        return null
    return format(parsedDate, DATE_FORMAT)
}

export function date2APIDate(date: Date): string {
    return format(date, DATE_FORMAT)
}

export function optionalDate2APIDate(date?: Date | null | undefined): string | null {
    if (date == null || !isValid(date))
        return null
    return format(date, DATE_FORMAT)
}


export function formatApiDate(date: any): string {
    if (date === null || date === undefined)
        return ''
    if (date instanceof Date)
        return format(date, DISPLAY_DATE_FORMAT)
    const parsedDate = parseISO(date)
    if (!isValid(parsedDate))
        return 'invalid date'
    return date ? format(parsedDate, DISPLAY_DATE_FORMAT) : ''
}

export function formatApiDateTime(date: any): string {
    if (date === null || date === undefined)
        return ''
    if (date instanceof Date)
        return format(date, DISPLAY_DATE_TIME_FORMAT)
    const parsedDate = parseISO(date)
    if (!isValid(parsedDate))
        return 'invalid date'
    return date ? format(parsedDate, DISPLAY_DATE_TIME_FORMAT) : ''
}

export function formatNumber(num?: number | null, suffix = '', zeroOuVide = '', fractionDigits = 0): string {
    if (num == null)
        return zeroOuVide
    if (typeof num === 'string')
        return num
    return new Intl.NumberFormat("fr-FR", { maximumFractionDigits: fractionDigits }).format(num).replace(/\s+/g, '\xa0') + suffix
}

export function formatAmount(num?: number | null, zeroOuVide = ''): string {
    if (typeof num === 'string')
        return num
    return num !== undefined && num != null ? amountFormat.format(num).replace(/\s+/g, '\xa0') : zeroOuVide
}

export function formatAmountNumber(num?: number | null): number {
    return num !== undefined && num != null ? Number(num.toFixed(2)): 0;
}

export function formatCurrency(value?: number | string | null, suffixe = '', vide: string | null = null, zeroEstVide: boolean = true): string {
    if (value === undefined || value === null)
        return vide !== null ? vide : ''
    if (typeof value === 'string') {
        value = value.trim()
        if (value === '')
            return vide !== null ? vide : ''
        value = Number(value)
    }
    if (Number.isNaN(value) || !Number.isFinite(value))
        return vide !== null ? vide : ''
    if (value === 0 && vide !== null && zeroEstVide)
        return vide
    return euroFormat.format(value).replace(/\s+/g, '\xa0') + suffixe
}

export function formatNom(nom?: string, prenom?: string) {
    return ((nom ?? '') + ' ' + (prenom ?? '')).trim()
}

export function formatAdresse(adresse?: Adresse | string | null | undefined, codePostal?: string | null, commune?: string | null) {
    if (typeof(adresse) === 'string') {
        return ((adresse ? adresse + ' ' : '') + 
            (codePostal ? codePostal + ' ' : '') + 
            (commune ? commune + ' ' : ''))
            .trim()
    }
    return ((adresse?.adresse ? adresse?.adresse + ' ' : '') + 
        (codePostal ? codePostal + ' ' : '') + 
        (commune ? commune + ' ' : ''))
        .trim()
}

export function formatConcat(tokens: (string | null | undefined)[], separator = ' ') {
    let s = ''
    tokens.forEach(token => {
        if (token) {
            if (s)
                s += separator
            s += token
        }
    })
    return s
}

export function formatRefCode(refs?: Reference[] | null, id?: string | null): string {
    return id && refs ? (refs.find(r => r.Id === id)?.CodeType__c ?? '(Inconnu)') : ''
}

export function formatRefNumber(refs: Reference[] | null | undefined, id: string | number | null | undefined): string {
    id = (typeof id === 'number') ? id.toFixed(0) : id
    let value = ''
    if (id != null && refs != null) {
        const ref = refs.find(r => r.Id === id)
        if (ref == null)
            value = '(Inconnu)'
        else {
            const libelle = ref.Libelle__c
            let idx = libelle.indexOf(' - ')
            if (idx < 0)
                idx = libelle.indexOf('- ')
            value = idx > 0 ? libelle.substring(0, idx) : libelle
        }
    }
    return value
}

export function formatRef(refs: Reference[] | null | undefined, id: string | number | null | undefined, options?: {
    code?: boolean
    libelle?: 'reduit' | 'complet' | 'aucun'

}): string {
    id = (typeof id === 'number') ? id.toFixed(0) : id
    let value = ''
    if (id != null && refs != null) {
        const ref = refs.find(r => r.Id === id)
        if (ref == null)
            value = '(Inconnu)'
        else {
            if (options?.code)
                value = ref.CodeType__c
            const libelle = options?.libelle ?? (options?.code ? 'aucun' : 'complet')
            if (libelle !== 'aucun') {
                if (value.length > 0)
                    value += ' - '
                value += (libelle === 'complet' ? ref.Libelle__c : ref.LibelleReduit__c)
            }
        }
    }
    return value
}

// export function formatRef(refs?: Reference[] | null, id?: string | null): string {
//     return id && refs ? (refs.find(r => r.Id === id)?.Libelle__c ?? '(Inconnu)') : ''
// }

export function formatRefReduit(refs?: Reference[] | null, id?: string | null): string {
    return id && refs ? (refs.find(r => r.Id === id)?.LibelleReduit__c ?? '(Inconnu)') : ''
}

export function formatOuiNon(val?: string): string {
    if (val === undefined || val === null)
        return ''
    return val === 'O' ? 'Oui' : 'Non'
}

export function formatParDefaut(num?: number | null, suffixe = '', zeroOuVide = ''): string {
    if (typeof num === 'string')
        return num
    return num !== undefined && num != null ? num + suffixe : zeroOuVide
}

export function splitAdresse(x: string | undefined | null): any {
    if (!x)
        return { numero: undefined, adresse: undefined }

    const a = x.trim().split(' ')

    if (a.length <= 1)
        return { numero: undefined, adresse: a.join('') }

    if (a[0].match(/[0-9\-/]+/)) {
        return { numero: a.shift(), adresse: a.join(' ') };
    } else {
        return { numero: undefined, adresse: x.trim()}
    }
}

/**
 * Format suffix with plural when necessary
 * 1 + repas -> 1 repas
 * 10 + repas -> 10 repas
 * 1 + heure(s) -> 1 heure
 * 10 + heure(s) -> 10 heures
 * @param suffix 
 * @param plural 
 * @returns 
 */
export function formatSuffix(suffix: string, plural: boolean) {
    if (plural) {
        return suffix.replaceAll('(', '').replaceAll(')', '')
    } else if (suffix.match(/\(.*\)/)) {
        return suffix.replaceAll(/\(.*\)/g, '')
    }
    return suffix
}