import { verifierNIR } from 'api/commonAPI'
import { SEXE_FEMININ, SEXE_MASCULIN } from 'api/references'
import { reformContext } from '@dsid-opcoatlas/reform'
import { getParentPath, AsyncValidationSkipped } from '@dsid-opcoatlas/yop'
import { OptionalString } from 'components/yop/constraints'
import { BaseDossierStagiaire } from 'slices/dossierCommon'

const NIR_ERROR_MESSAGE = "Le Nom de naissance et/ou le NIR ne semblent pas correspondre à ceux enregistrés au répertoire national. Veuillez vérifier l'orthographe exacte du nom sur la carte d'identité et le NIR saisis."

// http://xml.insee.fr/schema/nir.html#NIR
export const AsyncOptionalNIR = (sexePath?: string) => {
    return OptionalString
        .max(40)
        .when(context => context.parent?.ntt === true ?
            context.schema.matches(/^([a-zA-Z0-9]){11,40}$/, 'Le NTT doit comporter uniquement de 11 à 40 chiffres et lettres') :
            context.schema
                .test(context => {
                    const nir = context.value
                    if (nir != null) {
                        if (nir.length !== 13 && nir.length !== 15)
                            return context.createError("Le NIR est constitué de 13 ou 15 caractères avec la clé")
                        
                        const sexe = sexePath ? context.parent[sexePath] : null
                        if (sexe === SEXE_MASCULIN && nir.charAt(0) !== SEXE_MASCULIN)
                            return context.createError("Le NIR doit commencer par un 1 pour les hommes")
                        if (sexe === SEXE_FEMININ && nir.charAt(0) !== SEXE_FEMININ)
                            return context.createError("Le NIR doit commencer par un 2 pour les femmes")

                        if (!/^[12][0-9]{4}([0-9]{2}|2A|2B)[0-9]{6,8}$/.test(nir))
                            return context.createError("Le NIR doit comporter uniquement des chiffres (ou 2A/2B pour les départements corses)")

                        if (nir.length === 15) {
                            const nirWithoutKey = nir.slice(0, -2)
                            const nirKey = nir.slice(-2)
                            const computedKey = (97 - (parseInt(nirWithoutKey.replace("2A", "19").replace("2B", "18"), 10) % 97)).toFixed(0)
                            if (computedKey !== nirKey)
                                return context.createError("La clé de contrôle du NIR est invalide")
                        }
                    }
                    return true
                })
                .asyncTest<BaseDossierStagiaire>(async context => {
                    const path = context.path!
                    const parentPath = getParentPath(path)
                    const form = reformContext(context)

                    if (context.value == null || context.parent?.nomNaissance == null || context.parent?.sexe == null || context.parent?.ntt === true)
                        throw AsyncValidationSkipped
        
                    if (form.hasAsyncResultStatus(path, ['pending', 'unavailable']))
                        throw AsyncValidationSkipped
        
                    if (!form.hasChanged([path, `${ parentPath }.nomNaissance`, `${ parentPath }.ntt`]))
                        throw AsyncValidationSkipped
        
                    form.setAsyncResultPending(path, "Vérification du NIR en cours...")

                    const nir = context.value.length === 15 ? context.value.slice(0, -2) : context.value
                    return verifierNIR(context.parent!.nomNaissance, nir)
                        .then(result => {
                            if (result.success)
                                return true
                            if (result.message)
                                context.createError(NIR_ERROR_MESSAGE, `${ parentPath }.nomNaissance`)
                            return context.createError(NIR_ERROR_MESSAGE)
                        })
                })
        )
}
