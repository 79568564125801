import { getServices } from "api/aideContactsAPI"
import { useEffect } from "react"

import useApiState from "api/useApiState"
import ServicesTemplate from "./common/ServicesTemplate"

export default function ServicesFacture() {

    const [{ value: services }, withGetServices] = useApiState(getServices)
    useEffect(() => {
        withGetServices()
    }, [withGetServices])

    return (
        <ServicesTemplate service={ services?.facture }  />
    )
}
