import { BaseTextField, getFieldState, useFormContext, useRender } from '@dsid-opcoatlas/reform'
import { AtlasFieldText } from 'atlas-ds'
import { formatSuffix } from 'components/format/Format'
import { BaseFieldProps } from './utils/BaseFieldProps'

interface PhoneFieldProps<T extends object> extends BaseFieldProps<string, T> {
    autoComplete?: string
}

const phoneRegexp = /^[0-9 ]*$/
const formatPhone = (value: string | null) => {
    value = value?.replace(/[^0-9]/g, '') ?? null
    if (!value)
        return ''
    const phone = value.match(/([0-9]{1,2})/g)
    return (phone ?? []).join(' ')
}

export default function PhoneField<T extends object>(props: PhoneFieldProps<T>) {
    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)

    return <AtlasFieldText
        type="tel"
        name={ props.name }
        label={ props.label }
        disabled={ props.disabled }
        maxLength={ 10 + 4 }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        suffix={props.suffix ? formatSuffix(props.suffix, true) : undefined}
        error={fieldState.error}
        autoComplete={props.autoComplete}
        inputConstructor={(inputProps) => <BaseTextField<any, any>
            {...inputProps}
            render={render}
            acceptInputValue={(value) => phoneRegexp.test(value)}
            formatDisplayedValue={formatPhone}
            toModelValue={(value) => value.replace(/[^0-9]/g, '')}
            onChange={ props.onChange }
            onBlur={ props.onBlur }
        />}
    />
}
