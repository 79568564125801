/* eslint-disable jsx-a11y/anchor-is-valid */
import { entreprise } from 'api/entreprise'
import { AtlasFlex, AtlasHeading, MyContainer, MyForm } from 'atlas-ds'
import Link from 'components/Link'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'
import { BaseFieldProps } from 'components/reform/inputs/utils/BaseFieldProps'
import { RequiredBoolean } from 'components/yop/constraints'
import { SettingsFieldMetadata, createMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierVAEI } from './VAEIModel'

function StrategiePlus(props: BaseFieldProps<boolean, DossierVAEI>) {
    if (entreprise()?.VV__c) {
        return <MyForm.Field full>
            <MyContainer dark>
                <AtlasHeading tag="h3" size="m">Stratégie +</AtlasHeading>

                <RadioBooleanField
                    { ...props }
                    label="Souhaitez-vous que le financement de votre reste à charge soit financé avec votre budget versement volontaire (offre de statégie Stratégie +)"
                />
            </MyContainer>
        </MyForm.Field>
    }

    return (
        <MyForm.Field full>
            <MyContainer dark>
                <AtlasHeading size="m" tag="h3">Stratégie +</AtlasHeading>
                <AtlasFlex column gap="s">
                    <p>Si vous étiez adhérent Stratégie + votre reste à charge aurait pu être financé avec votre budget versement volontaire</p>
                    <Link to="/strategie-plus" icon="arrow-right" level={3}>
                        En savoir plus sur notre offre de service Stratégie +
                    </Link>
                </AtlasFlex>
            </MyContainer>
        </MyForm.Field>
    )
}

export function StrategiePlusFieldMetadata(settings: SettingsFieldMetadata<boolean> = {}) {
    return createMetadata(
        {
            yop: RequiredBoolean,
            input: StrategiePlus,
            // recap: RecapBooleanField,
            // export: ExportBooleanField,
        },
        settings
    )
}
