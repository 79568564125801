import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import MaCleCfa from './MaCleCfa'


const cleCfaModule = () => { 
    return {
        id: 'ma-cle-cfa',
        routes: [
            <AppRoute key="ma-cle-cfa" path="/ma-cle-cfa" menuIcon="key" label="Clé CFA">
                <MaCleCfa />
            </AppRoute>
        ],
    } as AppModule
}

export default cleCfaModule