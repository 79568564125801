import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

import { getStagiaire } from 'api/dossierAPI'

import useApiState from 'api/useApiState'
import { AtlasColumns, AtlasFlex, AtlasHeading, AtlasLoading, AtlasValue, AtlasValues, MyContainer } from 'atlas-ds'
import Breadcrumb from 'components/Breadcrumb'
import { formatApiDate } from 'components/format/Format'
import PageHeader from "components/PageHeader"
import { historyStackActions } from 'slices/historySlice'


export default function DetailStagiaire() {

    const location = useLocation()
    const dispatch = useDispatch()

    const idcontact = new URLSearchParams(location.search).get('cid') ?? ''
    const { iddossier, idstagiaire } = useParams() as any

    useEffect(() => {
        dispatch(historyStackActions.push([
            { title: 'Dossiers', pathname: '/mes-dossiers-contrats', search: '' },
            { title: 'Dossier sélectionné', pathname: '/detail-dossier-contrat/' + iddossier, search: '' },
            { title: 'Fiche stagiaire', pathname: location.pathname, search: location.search }
        ]))
    }, [location, iddossier, dispatch])

    const [{ pending: stagiairePending, value: stagiaire }, withGetStagiaire] = useApiState(getStagiaire)
    useEffect(() => {
        scroll.scrollToTop()
        withGetStagiaire(idstagiaire, idcontact)
    }, [idstagiaire, idcontact, withGetStagiaire]);

    // const actionsDossier = (dossier: Dossier) => {
    //     const actions = []
    //     actions.push(<XLinkDossier key="dossierLink" dossier={dossier} />)
    //     return actions
    // }

    return (
        <AtlasLoading loading={stagiairePending}>
            <PageHeader breadcrumb={<Breadcrumb />}>
                { stagiaire?.Prenom__c + ' ' + stagiaire?.Nom__c }
            </PageHeader>

            <AtlasColumns>
                <AtlasFlex column gap="xl">
                    <section>
                        <AtlasHeading tag="h2" size="m">Informations stagiaire</AtlasHeading>
                        <MyContainer>
                            <AtlasValues>
                                <AtlasValue label="Nom et prénom">{(stagiaire?.Nom__c ?? '') + ' ' + (stagiaire?.Prenom__c ?? '')}</AtlasValue>
                                <AtlasValues.Grid>
                                    <AtlasValue label="Date de naissance">{formatApiDate(stagiaire?.DateNaissance__c)}</AtlasValue>
                                    <AtlasValue label="Adresse mail">{stagiaire?.Email__c || ''}</AtlasValue>
                                    <AtlasValue label="Statut">{stagiaire?.NatureContratRef?.Libelle__c ?? ''}</AtlasValue>
                                    <AtlasValue label="CSP">{stagiaire?.CSPRef?.Libelle__c ?? ''}</AtlasValue>
                                    <AtlasValue label="Catégorie">{stagiaire?.CategorieActionRef?.LibelleReduit__c ?? ''}</AtlasValue>
                                    <AtlasValue label="Travailleur handicapé">{stagiaire?.Handicap__c === '1' ? 'Oui' : 'Non'}</AtlasValue>
                                </AtlasValues.Grid>
                            </AtlasValues>
                        </MyContainer>
                    </section>

                    {/* <section>
                        <AtlasHeading tag="h2" size="m">Historique</AtlasHeading>
                        <MyTable
                            caption="Historique"
                            rows={stagiaire?.Dossiers ?? []}
                            emptyText="Aucun dossier"
                            rowActions={actionsDossier}
                            columns={[
                                {
                                    id: "NumeroDossier__c",
                                    label: "Nom dossier",
                                    bold: true,
                                    wrap: true
                                },
                                {
                                    id: "Intitule__c",
                                    label: "Intitulé"
                                },
                                {
                                    id: "DispositifFORef",
                                    label: "Dispositif",
                                    value: (d: Dossier) => d.DispositifFORef?.Libelle__c ?? '(inconnu)'
                                }
                            ]}
                        />
                    </section> */}
                </AtlasFlex>

                <div>
                    { stagiaire?.FormationsSuggerees && <section>
                        <AtlasHeading tag="h2" size="m">Formations suggérées</AtlasHeading>
                        {stagiaire.FormationsSuggerees.map(f =>
                            <MyContainer key={f.Id}>
                                <AtlasValues>
                                    <AtlasValue label="Nom de la formation">{f.Intitule__c}</AtlasValue>
                                    <AtlasValues.Grid>
                                        <AtlasValue label="Début">{formatApiDate(f.DateDebut__c)}</AtlasValue>
                                        <AtlasValue label="Fin">{formatApiDate(f.DateFin__c)}</AtlasValue>
                                    </AtlasValues.Grid>
                                    <AtlasValue label="Type de formation">{f.Type__c}</AtlasValue>
                                </AtlasValues>
                            </MyContainer>
                        )}
                    </section> }
                </div>
            </AtlasColumns>
        </AtlasLoading>
    );
}
