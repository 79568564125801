import { Profil } from 'api/profilAPI'
import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormContentProps, FormMetadata, FormStep } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_SALARIE_AGORA } from 'pages/dossier/metadata/SalariesMetadata'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { BaseDossier, DossierWithSalaries } from 'slices/dossierCommon'

export function dossierSalariesInitializer<D extends DossierWithSalaries>(dossier: D, metadata: FormMetadata<D>, _: Profil | null, step: FormStep<D>) {
    if (step.options?.maxElements === 1 && dossier.salaries.length === 0)
        dossier.salaries = [metadata.api.createSalarie()]
    return dossier
}
export function dossierMultiSalariesInitializer<D extends DossierWithSalaries>(dossier: D, metadata: FormMetadata<D>, _: Profil | null, step: FormStep<D>) {
    return dossier
}

function BaseDossierSalariesElements<D extends BaseDossier | DossierWithSalaries>(props: FormContentProps<D> & { name: string, index?: number, selectLabel?: string }) {
    return (
        <MyForm.Grid>
            <FormElements metadata={ props.metadata } section="" path={ props.name } type="input" index={ props.index } />

            { props.step.sections?.includes(SECTION_SALARIE_AGORA) && 
                <MyForm.Fieldset legend="Informations complémentaires AGORA" tooltipContent={ TOOLTIPS.cpro.agora }>
                    <MyForm.Grid>
                        <FormElements metadata={ props.metadata } section={ SECTION_SALARIE_AGORA } path={ props.name } type="input"
                        index={ props.index != null ? 0 : undefined } />
                    </MyForm.Grid>
                </MyForm.Fieldset>
            }
        </MyForm.Grid>
    )
}

export function DossierSalarieElements<D extends BaseDossier>(props: FormContentProps<D> & { selectLabel?: string }) {
    return <BaseDossierSalariesElements { ...props } name={ props.step.yopPath! as string } />
}

export function DossierSalariesElements<D extends DossierWithSalaries>(props: FormContentProps<D> & { index?: number, selectLabel?: string }) {
    return <BaseDossierSalariesElements { ...props } index={ props.index ?? 0 } name='salaries' />
}
