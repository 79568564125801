import { findDispositifsEligiblesParBranche } from "api/references";
import { AccesRapide } from "api/referencesAPI";
import { RootState } from "app/rootReducer";
import Accesses from "components/AccesRapides";
import { useSelector } from "react-redux";


export default function AccesRapidesEntreprise() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    function filtrerParEligibles(accesRapides: AccesRapide[] | undefined) {

        const dispositifsEligiblesParBranche = findDispositifsEligiblesParBranche(entreprise)

        return accesRapides?.filter(accesRapide => {
            return !accesRapide.DispositifFOId || dispositifsEligiblesParBranche?.includes(accesRapide.DispositifFOId)
        })
    }

    return (
        <Accesses storageId="accesRapidesEntreprise" filtre={filtrerParEligibles} />
    )

}