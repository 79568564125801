import { AtlasColumns, AtlasFlex, AtlasGrid, AtlasHeading, AtlasLink, MyProduct } from "atlas-ds"
import annonces from "atlas-ds/my/assets/img/services/annonces.svg"
import index from "atlas-ds/my/assets/img/services/index.svg"
import mentor from "atlas-ds/my/assets/img/services/mentor.svg"
import simulateur from "atlas-ds/my/assets/img/services/simulateur.svg"
import NewRequest from "components/NewRequest"
import PageHeader from "components/PageHeader"

export default function Alternance() {
    return <>
        <PageHeader>Recruter un alternant</PageHeader>

        <AtlasColumns>
            <AtlasFlex column gap="xxl">
                <section>
                    <AtlasHeading tag="h2" size="m">M'informer sur l'alternance</AtlasHeading>
                    <p><strong>Atlas</strong> est votre interlocuteur unique pour la mise en œuvre de votre politique de recrutement en alternance. Actualités, webinaires, outils...</p>
                    <AtlasLink
                        external
                        level={3}
                        icon="arrow-right"
                        href="https://www.opco-atlas.fr/entreprise/recruter.html"
                    >Tout savoir sur l'alternance sur le site web d'Atlas</AtlasLink>
                </section>
                <section>
                    <AtlasHeading tag="h2" size="m">Choisir un candidat et un contrat</AtlasHeading>
                    <AtlasGrid columnsCount={3} gap="m">
                        <MyProduct
                            label="indexAtlas"
                            logo={index}
                            href="https://www.opco-atlas.fr/entreprise/indexAtlas.html"
                        >Prendre contact avec un CFA près de votre entreprise</MyProduct>
                        <MyProduct
                            label="annoncesAtlas"
                            logo={annonces}
                            href="https://annoncesatlas.opco-atlas.fr"
                        >Rendre visible votre offre d'emploi en alternance</MyProduct>
                        <MyProduct
                            label="simulateurAtlas"
                            logo={simulateur}
                            href="https://www.opco-atlas.fr/simulateur-cout-contrat-alternance"
                        >Connaître le coût d'un contrat en alternance</MyProduct>
                    </AtlasGrid>
                </section>
                <section>
                    <AtlasHeading tag="h2" size="m">Effectuer mes démarches administratives</AtlasHeading>
                    <AtlasFlex gap="m" wrap>
                        <AtlasLink external href="https://www.opco-atlas.fr/faq/contrat-dapprentissage.html" level={2}>
                            Consulter la FAQ en ligne
                        </AtlasLink>
                        <NewRequest menu="alternance" />
                    </AtlasFlex>
                </section>
                <section>
                    <AtlasHeading tag="h2" size="m">Intégrer mon alternant</AtlasHeading>
                    <AtlasGrid columnsCount={3} gap="m">
                        <MyProduct
                            label="mentorAtlas"
                            logo={mentor}
                            href="https://www.opco-atlas.fr/entreprise/tutorat.html"
                        >Offrir le meilleur accompagnement à vos alternants</MyProduct>
                    </AtlasGrid>
                </section>
            </AtlasFlex>
        </AtlasColumns>
    </>
}
