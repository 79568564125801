import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { TestValidationContext, ValidationContext } from '@dsid-opcoatlas/yop'
import { Entreprise, ReferenceIDCC } from 'api/referencesAPI'
import useApiState from 'api/useApiState'
import { VersementEnvoi, VersementInfo, getVersementInfo, postVersement } from 'api/versementAPI'
import { RootState } from 'app/rootReducer'
import { AtlasColumns, AtlasFlex, AtlasHtml, AtlasInfo, MyForm } from 'atlas-ds'
import { ToastService } from "components/BandeauAlert"
import ConfirmModal from "components/ConfirmModal"
import PageHeader from "components/PageHeader"
import { formatCurrency, formatNumber } from 'components/format/Format'
import { dateReverter } from 'components/metadata/DateFieldMetadata'
import Button from "components/reform/Button"
import ErrorBanner from 'components/reform/ErrorBanner'
import CheckboxField from 'components/reform/inputs/CheckboxField'
import CurrencyField from 'components/reform/inputs/CurrencyField'
import NumberField from 'components/reform/inputs/NumberField'
import RadioField from 'components/reform/inputs/RadioField'
import TextField from "components/reform/inputs/TextField"
import { useIdcc } from "components/reform/inputs/utils/useIdcc"
import { Ignored, OptionalBoolean, OptionalDate, OptionalNumber, OptionalString, RequiredBoolean, RequiredNumber, RequiredString, requiredObject } from "components/yop/constraints"
import { startOfToday } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { Adresse, nil } from 'slices/dossierCommon'
import CalculTVA from './CalculTVA'
import { OptionalPrelevement } from './PrelevementBlock'
import ReglementBlock, { createReglement } from "./ReglementBlock"

export function hasConventionnel(refsIdcc: ReferenceIDCC[] | null, entreprise?: Entreprise | null) {
    return refsIdcc?.find(r => r.Id === entreprise?.CodeIDCC__c)?.ContributionConventionnelle__c
}

function createVersement(entreprise?: Entreprise | null, versementInfo?: VersementInfo | null) { 
    const tauxTVA = versementInfo?.TauxTVAEnterprise__c
    const tauxTVALocal = versementInfo?.TauxTVAEnterprise__c ?? 20
    return {
        entreprise: {
            raisonSociale: (versementInfo?.NomAdh__c ?? entreprise?.Name ?? nil) as string,
            siren: (versementInfo?.SirenAdh__c ?? entreprise?.SIREN__c ?? nil) as string,
            codeNAF: (versementInfo?.CodeNAF__c ?? nil) as (string | null),
            codeIDCC: (versementInfo?.CodeIDCC__c ?? nil) as (string | null),
            branche: (versementInfo?.Branche__c ?? nil) as string,
            adresse: {
                adresse: (versementInfo?.AdresseADH__c ?? entreprise?.Adresse3__c ?? nil) as string,
                codePostal: (versementInfo?.CodePostalADH__c ?? entreprise?.BillingPostalCode ?? nil) as string,
                commune:  (versementInfo?.CommuneADH__c ?? entreprise?.BillingCity ?? nil) as string,
            } as Partial<Adresse>
        },
        effectifsETAM: {
            femmes: (versementInfo?.EffectifETAMFemme__c ?? nil) as number,
            hommes: (versementInfo?.EffectifETAMHomme__c ?? nil) as number,
        },
        effectifsCadres: {
            femmes: (versementInfo?.EffectifCadreFemme__c ?? nil) as number,
            hommes: (versementInfo?.EffectifCadreHomme__c ?? nil) as number,
        },
        effectifTotal: (versementInfo?.EffectifTotal__c ?? nil) as number,
        masseSalariale: ((versementInfo?.EffectifTotal__c === 0 ? 0 : versementInfo?.MasseSalariale__c) ?? nil) as number,
        masseSalarialeValidation: false,
        typeTVA: (versementInfo?.TVAEntreprise__c === true ? 'totale' : (versementInfo?.TVAEntreprise__c === false ? 'aucune' : nil)) as string,
        tauxTVA: (tauxTVA === tauxTVALocal ? tauxTVALocal : (tauxTVA === 0 ? 0 : nil)) as (number | null),
        ...createReglement(),
        cgu: nil as boolean,
        optoutEnquetes: false as (boolean | null),
        optoutPartenaires: false as (boolean | null),
        calculTVA: {
            tauxConventionnel: ((versementInfo?.EffectifTotal__c === 0 ? 0 : versementInfo?.TauxCC__c) ?? nil) as number,
            tauxTVALocal: (versementInfo?.TauxTVAEnterprise__c ?? nil) as number,
            appliqueTVA: (versementInfo?.TVAEntreprise__c ?? nil) as boolean,
            montantHT: (versementInfo?.MontantHT__c ?? nil) as number,
            montantTVA: (versementInfo?.MontantTVA__c ?? nil) as number,
            montantTTC: (versementInfo?.MontantTTC__c ?? nil) as number,
        },
    }
}
type Versement = ReturnType<typeof createVersement>


// Convertit le formatage affichage (avec €) vers un format double normal pour la comparaison de montants côté back
const formatAmount = (value: number) => formatCurrency(value, '', '-', false)
    .replace('€', '').replace(',', '.').replace(/\s/g, '').trim() as unknown as number

function testFractionDigits(ctx: TestValidationContext<number, any, any>, messageGT1?: string, messageLT1?: string) {
    if (!Number.isInteger(ctx.value)) {
        if (ctx.value > 1)
            return ctx.createError(messageGT1 ?? "L'effectif doit être arrondi à l'unité inférieure")
        return ctx.createError(messageLT1 ?? "L'effectif doit être arrondi à 1 si il est compris en 0 et 1")
    }
    return true
}

export default function VersementConventionnel() {
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const refsIdcc = useIdcc()

    useEffect(() => {
        if (!hasConventionnel(refsIdcc, entreprise)) {
            history.replace({
                pathname: '/mes-versements'
            })

            ToastService.showToast({
                type: "error",
                label: "Action impossible",
                content: "Vous n'avez pas la possibilité d'effectuer un versement conventionnel"
            })
        }
    }, [refsIdcc, entreprise])

    const history = useHistory()
    const [error, setError] = useState<string>()
    const [doublonError, setDoublonError] = useState<string>()
    const [{ value: versementInfo }, withGetVersementInfo] = useApiState(getVersementInfo)

    const tauxTVALocal = versementInfo?.TauxTVAEnterprise__c ?? 20

    const initialValues = createVersement(entreprise, versementInfo)

    const validationSchema = requiredObject<Versement>({
        entreprise: requiredObject({
            raisonSociale: RequiredString,
            siren: RequiredString,
            codeNAF: OptionalString,
            codeIDCC: RequiredString,
            branche: RequiredString,
            adresse: requiredObject({
                adresse: RequiredString,
                codePostal: RequiredString,
                commune: RequiredString,
            }),
        }),
        effectifsETAM: requiredObject({
            femmes: RequiredNumber.max(999_999).test(testFractionDigits),
            hommes: RequiredNumber.max(999_999).test(testFractionDigits),
        }),
        effectifsCadres: requiredObject({
            femmes: RequiredNumber.max(999_999).test(testFractionDigits),
            hommes: RequiredNumber.max(999_999).test(testFractionDigits),
        }),
        effectifTotal: RequiredNumber.max(999_999),
        masseSalariale: RequiredNumber.max(99_999_999_999)
            .test(ctx => {
                if (ctx.value === 0 && (ctx.parent?.effectifTotal ?? 0) > 0)
                    return ctx.createError("La masse salariale ne peut pas être nulle lorsque l'effectif total est non vide")
                if (ctx.value > 0 && ctx.parent?.effectifTotal === 0)
                    return ctx.createError("Il ne peut pas y avoir de masse salariale lorsque l'effectif total est vide")
                if (!Number.isInteger(ctx.value))
                    return ctx.createError("La masse salariale doit être arrondie à l'euro le plus proche")
                return true
            }),
        masseSalarialeValidation: versementInfo && versementInfo.AnneeFiscale >= 2023 ? RequiredBoolean.oneOf([true], "Cette case à cocher est obligatoire") : Ignored,
        typeTVA: RequiredString.oneOf(['totale', 'partielle', 'aucune']),
        tauxTVA: OptionalNumber.when((ctx: ValidationContext<number | null, Versement>) => {
            return ctx.parent?.typeTVA !== 'partielle' ? null : RequiredNumber.min(0.01).max(tauxTVALocal - 0.01)
        }),
        typeReglement: RequiredString.oneOf(['zero', 'virement', 'prelevement', 'cheque']),
        dateVirement: OptionalDate.requiredIf<Versement>(ctx => ctx.parent?.typeReglement === 'virement'),
        prelevement: OptionalPrelevement.requiredIf<Versement>(ctx => ctx.parent?.typeReglement === 'prelevement'),
        cgu: Ignored, // RequiredBoolean.oneOf([true], 'Veuillez accepter les CGU'),
        optoutEnquetes: OptionalBoolean,
        optoutPartenaires: OptionalBoolean,
        calculTVA: Ignored,
    })

    const doSubmit = (context: UseFormReturn<Versement>, confirm = false) => {
        setDoublonError(undefined)
        
        if (context.values!.typeReglement === 'virement') {
            context.setSubmitting(true)
            const versement = {
                EffectifCadreFemme__c: context.values!.effectifsCadres.femmes,
                EffectifCadreHomme__c: context.values!.effectifsCadres.hommes,
                EffectifETAMFemme__c: context.values!.effectifsETAM.femmes,
                EffectifETAMHomme__c: context.values!.effectifsETAM.hommes,
                EffectifTotal__c: context.values!.effectifTotal,
                MasseSalariale__c: context.values!.masseSalariale,
                MontantTTC__c: formatAmount(context.values!.calculTVA.montantTTC),
                MontantHT__c: formatAmount(context.values!.calculTVA.montantHT),
                MontantTVA__c: formatAmount(context.values!.calculTVA.montantTVA),
                TVAEntreprise__c: context.values!.typeTVA === 'totale',
                TauxTVAEnterprise__c: tauxTVALocal,
                TauxTVAPartielle__c: context.values!.typeTVA === 'partielle' ? context.values!.tauxTVA : null,
                MoyenPaiement__c: 'VIREMENT',
                DatePaiement__c: dateReverter(context.values!.dateVirement),
                EtablissementTitulaire: null,
                CGUVV__c: context.values!.cgu,
                NonConsentementTraitementDonnees__c: context.values!.optoutEnquetes,
                NonConsentementTransmissionDonnees__c: context.values!.optoutPartenaires,
                ByPassDoublon: confirm,
            } as VersementEnvoi

            postVersement(versement)
                .then(detail => {
                    context.setSubmitting(false)
                    history.push(`/detail-versement/${detail.IdHeroku}`, { show: 'bordereau' })
                }).catch(e => {
                    context.setSubmitting(false)
                    if (e.response?.status === 409) {
                        setDoublonError(e.response?.data?.message ?? 'Un versement de ce montant a déjà été effectué cette année pour cet adhérent')
                    } else {
                        setError(e.response?.data?.message ?? "Une erreur s'est produite lors du traitement du versement")
                    }
                })
        } else if (context.values!.typeReglement === 'prelevement') {
            context.setSubmitting(true)
            const versement = {
                EffectifCadreFemme__c: context.values!.effectifsCadres.femmes,
                EffectifCadreHomme__c: context.values!.effectifsCadres.hommes,
                EffectifETAMFemme__c: context.values!.effectifsETAM.femmes,
                EffectifETAMHomme__c: context.values!.effectifsETAM.hommes,
                EffectifTotal__c: context.values!.effectifTotal,
                MasseSalariale__c: context.values!.masseSalariale,
                MontantTTC__c: formatAmount(context.values!.calculTVA.montantTTC),
                MontantHT__c: formatAmount(context.values!.calculTVA.montantHT),
                MontantTVA__c: formatAmount(context.values!.calculTVA.montantTVA),
                TVAEntreprise__c: context.values!.typeTVA === 'totale',
                TauxTVAEnterprise__c: tauxTVALocal,
                TauxTVAPartielle__c: context.values!.typeTVA === 'partielle' ? context.values!.tauxTVA : null,
                MoyenPaiement__c: 'PRELEVEMENT',
                EtablissementTitulaire: context.values!.prelevement!.etablissementTitulaire!.Id,
                IBAN: context.values!.prelevement!.iban,
                BIC: context.values!.prelevement!.bic,
                Nom: context.values!.prelevement!.nom,
                Prenom: context.values!.prelevement!.prenom,
                Telephone: '+33' + context.values!.prelevement!.mobile.substring(1),
                Email: context.values!.prelevement!.email,
                CGUVV__c: context.values!.cgu,
                NonConsentementTraitementDonnees__c: context.values!.optoutEnquetes,
                NonConsentementTransmissionDonnees__c: context.values!.optoutPartenaires,
                returnUrl: window.location.href.replace('/versement-conventionnel', '/detail-versement/{IdHeroku}'),
                ByPassDoublon: confirm,
            } as VersementEnvoi

            postVersement(versement)
                .then(detail => {
                    if (detail.URLSignatureSlimpay__c) {
                        window.location.href = detail.URLSignatureSlimpay__c
                    } else {
                        setError('Impossible de rediriger vers le service de paiement en ligne')
                    }
                }).catch(e => {
                    context.setSubmitting(false)
                    if (e.response?.status === 409) {
                        setDoublonError(e.response?.data?.message ?? 'Un versement de ce montant a déjà été effectué cette année pour cet adhérent')
                    } else {
                        setError(e.response?.data?.message ?? "Une erreur s'est produite lors du traitement du versement")
                    }
                })
        } else if (context.values!.typeReglement === 'zero') {
            context.setSubmitting(true)
            const versement = {
                EffectifCadreFemme__c: context.values!.effectifsCadres.femmes,
                EffectifCadreHomme__c: context.values!.effectifsCadres.hommes,
                EffectifETAMFemme__c: context.values!.effectifsETAM.femmes,
                EffectifETAMHomme__c: context.values!.effectifsETAM.hommes,
                EffectifTotal__c: context.values!.effectifTotal,
                MasseSalariale__c: context.values!.masseSalariale,
                MontantTTC__c: 0,
                MontantHT__c: 0,
                MontantTVA__c: 0,
                TVAEntreprise__c: context.values!.typeTVA === 'totale',
                TauxTVAEnterprise__c: tauxTVALocal,
                TauxTVAPartielle__c: context.values!.typeTVA === 'partielle' ? context.values!.tauxTVA : null,
                MoyenPaiement__c: null,
                DatePaiement__c: dateReverter(startOfToday()),
                EtablissementTitulaire: null,
                CGUVV__c: context.values!.cgu,
                NonConsentementTraitementDonnees__c: context.values!.optoutEnquetes,
                NonConsentementTransmissionDonnees__c: context.values!.optoutPartenaires,
                ByPassDoublon: confirm,
            } as VersementEnvoi

            postVersement(versement)
                .then(detail => {
                    context.setSubmitting(false)
                    history.push(`/detail-versement/${detail.IdHeroku}`, { show: 'bordereau' })
                }).catch(e => {
                    context.setSubmitting(false)
                    if (e.response?.status === 409) {
                        setDoublonError(e.response?.data?.message ?? 'Un versement de ce montant a déjà été effectué cette année pour cet adhérent')
                    } else {
                        setError(e.response?.data?.message ?? "Une erreur s'est produite lors du traitement du versement")
                    }
                })
        } else {
            context.setSubmitting(false)
        }
    }

    const form = useForm({
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<Versement>) => {
            doSubmit(context)
        },
    })

    useEffect(() => {
        withGetVersementInfo('CC').then((versementInfo => {
            form.setValue('entreprise.raisonSociale', versementInfo.NomAdh__c ?? entreprise?.Name)
            form.setValue('entreprise.siren', versementInfo.SirenAdh__c ?? entreprise?.SIREN__c)
            form.setValue('entreprise.codeIDCC', versementInfo?.CodeIDCC__c)
            form.setValue('entreprise.codeNAF', versementInfo?.CodeNAF__c)
            form.setValue('entreprise.branche', versementInfo?.Branche__c)
            form.setValue('entreprise.adresse.adresse', versementInfo?.AdresseADH__c)
            form.setValue('entreprise.adresse.codePostal', versementInfo?.CodePostalADH__c)
            form.setValue('entreprise.adresse.commune', versementInfo?.CommuneADH__c)
            form.setValue('effectifsETAM.femmes', versementInfo?.EffectifETAMFemme__c)
            form.setValue('effectifsETAM.hommes', versementInfo?.EffectifETAMHomme__c)
            form.setValue('effectifsCadres.femmes', versementInfo?.EffectifCadreFemme__c)
            form.setValue('effectifsCadres.hommes', versementInfo?.EffectifCadreHomme__c)
            form.setValue('effectifTotal', versementInfo?.EffectifTotal__c)
            form.setValue('masseSalariale', versementInfo?.MasseSalariale__c)
            form.setValue('calculTVA.tauxConventionnel', versementInfo?.EffectifTotal__c === 0 ? 0 : versementInfo?.TauxCC__c)
            form.setValue('calculTVA.appliqueTVA', versementInfo?.TVAEntreprise__c)
            form.setValue('calculTVA.tauxTVALocal', versementInfo?.TauxTVAEnterprise__c ?? 20)
            form.setValue('calculTVA.montantHT', versementInfo?.MontantHT__c)
            form.setValue('calculTVA.montantTVA', versementInfo?.MontantTVA__c)
            form.setValue('calculTVA.montantTTC', versementInfo?.MontantTTC__c)
        })).catch (e => {
            setError(e.data?.message ?? 'Les informations de votre entreprise n\'ont pas pu être récupérées, merci de contacter le support')
            setTimeout(() => {
                const element = global.window.document.getElementById('error')
                if (element) {
                    element.scrollIntoView({block: 'center'});
                }
            }, 100)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withGetVersementInfo])

    const recalcEffectifs = () => {
        const effectifs: (number | null)[] = [
            form.values?.effectifsETAM?.femmes ?? null,
            form.values?.effectifsETAM?.hommes ?? null,
            form.values?.effectifsCadres?.femmes ?? null,
            form.values?.effectifsCadres?.hommes ?? null
        ]
        var effectifTotal: number | null = null
        if (!effectifs.every(effectif => effectif === null)) {
            effectifTotal = effectifs.reduce((previousValue, value) => previousValue! + (value ?? 0), 0)!
            if (effectifTotal === 0) {
                form.setValue('masseSalariale', 0)
                form.setValue('typeReglement', 'zero')
                form.setValue('dateVirement', null)
                form.setValue('prelevement', null)
                form.setValue('typeReglement', 'zero')
            }
        }
        form.setValue('effectifTotal', effectifTotal, true)
    }

    const onChangeTypeTVA = (type: string | null, form: UseFormReturn<Versement>) => {
        if (type === 'partielle' && form.values?.tauxTVA === tauxTVALocal) {
            form.setValue('tauxTVA', null)
        }
    }

    const onCalculTVA = ({ montantHT, montantTVA, montantTTC }: { montantHT: number, montantTVA: number, montantTTC: number }) => {
        const prevMontantHT = form.values?.calculTVA?.montantHT
        form.setValue('calculTVA.montantHT', montantHT)
        form.setValue('calculTVA.montantTVA', montantTVA)
        form.setValue('calculTVA.montantTTC', montantTTC)
        if (montantHT === 0) {
            form.setValue('typeReglement', 'zero')
            form.setValue('typeTVA', 'totale')
        } else if (montantTTC && montantTTC > 0 && form.values!.typeReglement === 'zero') {
            form.setValue('typeReglement', null)
        }
        if (montantHT !== prevMontantHT)
            form.renderForm()
    }

    const onChangeTypeConventionnel = (taux: number) => {
        if (taux !== form.values?.calculTVA.tauxConventionnel) {
            form.setValue('calculTVA.tauxConventionnel', taux)
            form.renderForm()
        }
    }

    const goBack = () => history.push("/mes-versements")

    return (
        <>
            <PageHeader>
                { "Versement de contribution conventionnelle " + (versementInfo?.AnneeFiscale != null ? versementInfo.AnneeFiscale + 1 : "") }
            </PageHeader>

            <AtlasColumns>
                <AtlasFlex column gap="l">
                    { versementInfo && versementInfo.AnneeFiscale < 2023 && <AtlasInfo type="warning" title="Attention">
                        La collecte 2023 de contribution conventionnelle (au titre de la masse salariale 2022) n’est pas encore clôturée. Pour
                        saisir un versement sur les salaires 2023, merci d'attendre l’ouverture de la collecte 2024 courant janvier.
                    </AtlasInfo> }
                    
                    <Form context={form} autoComplete="off" noValidate disabled={form.submitting}>
                        <MyForm>
                            <MyForm.Fieldset legend="Entreprise" >
                                <MyForm.Grid>
                                    <TextField name="entreprise.raisonSociale" label="Raison Sociale" disabled />
                                    <TextField name="entreprise.siren" label="SIREN" disabled />
                                    <TextField name="entreprise.codeNAF" label="Code NAF" disabled />
                                    <TextField name="entreprise.codeIDCC" label="IDCC applicable" disabled />
                                    <TextField name="entreprise.branche" label="Branche" disabled />
                                    <MyForm.Field full>
                                        <TextField name="entreprise.adresse.adresse" label="Adresse" disabled />
                                    </MyForm.Field>
                                    <TextField name="entreprise.adresse.codePostal" label="Code postal" disabled />
                                    <TextField name="entreprise.adresse.commune" label="Commune" disabled />
                                </MyForm.Grid>
                            </MyForm.Fieldset>

                            <MyForm.Fieldset
                                legend="Effectifs"
                                tooltipContent="Effectifs arrondis à l'unité inférieure. Pour l'effectif compris entre 0 et 1, l'arrondi est à 1."
                            >
                                <MyForm.Grid>
                                    <NumberField name="effectifsETAM.femmes" label="ETAM femmes" fractionDigits={2} minimumFractionDigits={0}
                                        onBlur={recalcEffectifs} />
                                    <NumberField name="effectifsETAM.hommes" label="ETAM hommes" fractionDigits={2} minimumFractionDigits={0}
                                        onBlur={recalcEffectifs} />
                                    <NumberField name="effectifsCadres.femmes" label="Cadres femmes" fractionDigits={2} minimumFractionDigits={0}
                                        onBlur={recalcEffectifs} />
                                    <NumberField name="effectifsCadres.hommes" label="Cadres hommes" fractionDigits={2} minimumFractionDigits={0}
                                        onBlur={recalcEffectifs} />
                                    <NumberField name="effectifTotal" label="Effectif total" fractionDigits={0} disabled />
                                    <CurrencyField
                                        name="masseSalariale"
                                        label={ "Masse salariale formation DSN " + (versementInfo?.AnneeFiscale ?? "") }
                                        suffix="€"
                                        minimumFractionDigits={0}
                                        onBlur={recalcEffectifs}
                                        tooltip="Masse salariale arrondie à l'euro le plus proche"
                                    />
                                </MyForm.Grid>
                                { versementInfo && versementInfo.AnneeFiscale >= 2023 &&
                                    <CheckboxField
                                        name="masseSalarialeValidation"
                                        label="La masse salariale, lorsqu'elle est présente, vous est fournie à titre indicatif. Veuillez la vérifier et la corriger si besoin, puis confirmer la vérification en cochant cette case."
                                    />
                                }
                            </MyForm.Fieldset>

                            {form.values?.calculTVA?.montantHT !== 0 && (tauxTVALocal != null && tauxTVALocal > 0) && <>
                                <RadioField name="typeTVA" label="TVA"
                                    onChange={onChangeTypeTVA}                  
                                    options={[
                                        { value: 'totale', label: `Assujetti à une TVA de ${formatNumber(form.values?.calculTVA?.tauxTVALocal, '', '', 2) ?? '20'}%` },
                                        { value: 'partielle', label: 'Assujetti à une TVA partielle' },
                                        { value: 'aucune', label: 'Non assujetti à la TVA' },
                                    ]} />
                                {form.values?.typeTVA === 'partielle' && <NumberField name="tauxTVA" fractionDigits={2}
                                    label="Taux de TVA partielle" suffix="%" />
                                }
                            </>}

                            <CalculTVA
                                anneeFiscale={versementInfo ? versementInfo.AnneeFiscale : 2022}
                                codeIDCC={form.values?.entreprise?.codeIDCC ?? null}
                                tauxTVALocal={form.values?.calculTVA?.tauxTVALocal ?? null}
                                effectifTotal={form.values?.effectifTotal ?? null}
                                masseSalariale={form.values?.masseSalariale ?? null}
                                typeTVA={form.values?.typeTVA ?? null}
                                tauxTVA={form.values?.tauxTVA ?? null}
                                calculTVA={onCalculTVA}
                                tauxConventionnelChange={onChangeTypeConventionnel} 
                                />

                            <ReglementBlock type="conventionnel" disabled={
                                form.values?.calculTVA?.montantHT === 0 || (form.values?.effectifTotal === 0 && form.values?.masseSalariale === 0)
                            } />

                            { /*
                            <CheckboxField name="cgu" label="J'accepte les CGU" disabled={ form.values?.calculTVA?.tauxConventionnel === 0 }  />
                            */ }

                            <MyForm.Fieldset legend="Informations complémentaires">
                                <AtlasHtml>
                                    Les données à caractère personnel des contacts désignés sur ce bordereau peuvent être utilisées pour la réalisation d’enquêtes et d’études menées par Atlas ou par ses sous-traitants. Pour les entreprises IDCC 1486, elles peuvent également être transmises aux partenaires suivants : les associations ADESATT (N° RNA : W751143790) et OPIIEC (N° RNA : W751149276). <br />
                                    <strong>Si vous ne souhaitez pas que ce soit le cas, merci de le préciser.</strong>
                                </AtlasHtml>

                                <CheckboxField name="optoutEnquetes" 
                                    label="Non, je ne souhaite pas que ces informations soient utilisées par ATLAS pour la réalisation d’enquêtes et d’études."
                                />
                                <CheckboxField name="optoutPartenaires" 
                                    label="Non, je ne souhaite pas que ces informations soient transmises aux partenaires mentionnés ci-dessus."
                                />
                            </MyForm.Fieldset>

                            <div id="error">
                                <ErrorBanner title="Erreur lors du traitement du versement" message={error} />
                            </div>

                            <MyForm.Actions>
                                <Button
                                    submit={true}
                                    disabled={form.values?.effectifTotal == null || form.values?.masseSalariale == null || !form.values?.typeTVA}
                                    spinner={{spinning: form.submitting}}
                                >Valider</Button>
                                <Button level={2} disabled={form.submitting} onClick={() => goBack()}>Annuler</Button>
                            </MyForm.Actions>
                        </MyForm>
                    </Form>
                </AtlasFlex>
            </AtlasColumns>

            { doublonError &&
            <ConfirmModal title="ATTENTION" confirm={ () => doSubmit(form, true) } cancel={ () => setDoublonError(undefined) }>
                { doublonError }
            </ConfirmModal>
            }
        </>
    )
}
