import { AtlasButton, AtlasTagsProps } from "atlas-ds";
import { MouseEventHandler } from "react";

export interface MyListInputProps {
  /**
   * Le champ
   */
  input: React.ReactNode;
  /**
   * L'action à éxécuter lors de l'ajout
   */
  onAdd: MouseEventHandler<HTMLButtonElement>;
  /**
   * Les valeurs validées, sous forme d'une liste de tags
   */
  values?: React.ReactElement<AtlasTagsProps>;
}

/**
 * Un input permettant de saisir une liste d'éléments de même type
 */
export function MyListInput(props: MyListInputProps) {
  return (
    <div className="my-listInput">
      <div className="my-listInput__input">
        {props.input}
        <AtlasButton onClick={props.onAdd} icon="plus">
          Ajouter
        </AtlasButton>
      </div>
      {props.values}
    </div>
  );
}
