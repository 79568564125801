import { getFieldState, SetValueOptions, useFormContext } from '@dsid-opcoatlas/reform'
import { ValidationContext, Yop } from '@dsid-opcoatlas/yop'
import { DossierApprentissageDTO, DossierSalairePeriodeDTO } from 'api/dossierAPI'
import { references } from 'api/references'
import { AtlasButton, AtlasCheckbox, AtlasFlex, AtlasHtml, AtlasSpacer, AtlasValues, MyForm, MyModal } from 'atlas-ds'
import { formatDate, formatRef, normalizeAPIDate, parseDate } from 'components/format/Format'
import { dateConverter, dateReverter } from 'components/metadata/DateFieldMetadata'
import DateField from 'components/reform/inputs/DateField'
import NumberField from 'components/reform/inputs/NumberField'
import PseudoTextField from 'components/reform/inputs/PseudoTextField'
import RadioRefField from 'components/reform/inputs/RadioRefField'
import { Ignored, OptionalBoolean, OptionalDate, OptionalString, PercentNumber, RequiredBoolean } from 'components/yop/constraints'
import { addDays, addYears, differenceInYears, isAfter, isBefore, isEqual } from 'date-fns'
import { isEqual as _isEqual, cloneDeep } from 'lodash'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { BlockValue } from 'pages/dossier/cerfa/CerfaDpc'
import { FieldMapping, MappingContext } from 'pages/dossier/metadata/DossierMapping'
import { SettingsFieldMetadata, createMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapNumberField from 'pages/dossier/recap/RecapNumberField'
import RecapRefField from 'pages/dossier/recap/RecapRefField'
import RecapTextField from 'pages/dossier/recap/RecapTextField'
import { useEffect, useRef, useState } from 'react'
import { nil } from 'slices/dossierCommon'
import { DossierCAPP, DossierCAPPAnneeRemuneration, DossierCAPPRemuneration } from './CAPPModel'
import { rebuildAnneesRemuneration } from './rebuildAnneesRemuneration'


export function RemunerationFieldMetadata(settings: SettingsFieldMetadata<DossierCAPPRemuneration>, editForm?: boolean) {
    const anneeRemunerationSchema = Yop.object<DossierCAPPAnneeRemuneration>({
        IdHeroku1: Ignored,
        IdHeroku2: Ignored,
        dossierC : Ignored,
        remunerationDateDebut: OptionalDate
            .requiredIf<DossierCAPPAnneeRemuneration>(ctx =>
                ctx.root?.contrat?.remuneration?.modeManuelRemuneration === true &&
                ctx.parent?.remunerationDateChangement1 != null, "Obligatoire si une date de fin est définie"
            )
            .test<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => {
                if (!(ctx.root?.contrat?.remuneration?.modeManuelRemuneration))
                    return true
                const annee = parseInt(ctx.path?.substring('contrat.remuneration.anneesRemuneration['.length) ?? '')
                if (!ctx.value) {
                    if (annee === 0 || ctx.parent?.remunerationPourcentage1 != null)
                        return ctx.createError('Champ obligatoire')
                    return true
                }
                const dateDebutContrat = parseDate(ctx.root.contrat?.dateDebut)
                const dateDebutPeriode = parseDate(ctx.value)
                if (!dateDebutContrat || !dateDebutPeriode)
                    return true
                if (isEqual(dateDebutPeriode, addYears(dateDebutContrat, annee)))
                    return true
                return ctx.createError(annee === 0 ?
                    "La date de début de période doit être la date de début du contrat" :
                    "La date de début de période doit être la date anniversaire du contrat"
                )
            }),
        remunerationDateChangement1: OptionalDate
            .requiredIf<DossierCAPPAnneeRemuneration>(ctx => 
                ctx.root?.contrat?.remuneration?.modeManuelRemuneration === true &&
                ctx.parent?.remunerationDateDebut != null, "Obligatoire si une date de début est définie"
            )
            .test<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => {
                if (!(ctx.root?.contrat?.remuneration?.modeManuelRemuneration) && !editForm)
                    return true
                if (!ctx.parent?.remunerationDateDebut)
                    return true
                const dateDebutPeriode = parseDate(ctx.parent.remunerationDateDebut)
                const dateFinPeriode = parseDate(ctx.value)
                if (!dateFinPeriode || !dateDebutPeriode)
                    return true
                if (isAfter(dateFinPeriode, addYears(dateDebutPeriode, 1))) {
                    return ctx.createError('La période doit faire moins d\'une année')
                }
                if (isAfter(dateFinPeriode, dateDebutPeriode) || isEqual(dateFinPeriode, dateDebutPeriode))
                    return true
                return ctx.createError('La date de fin de période doit être après la date de début')
            }),
        remunerationDateChangement: OptionalDate
            .requiredIf<DossierCAPPAnneeRemuneration>(ctx => 
                ctx.root?.contrat?.remuneration?.modeManuelRemuneration === true &&
                ctx.parent?.remunerationDateFin != null, "Obligatoire si une date de fin est définie"
            )
            .test<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => {
                if (!(ctx.root?.contrat?.remuneration?.modeManuelRemuneration))
                    return true
                if (!ctx.value)
                    return true
                const dateDebutPeriode2 = parseDate(ctx.value)
                if (!dateDebutPeriode2)
                    return true
                if (ctx.parent?.remunerationDateDebut) {
                    const dateDebutPeriode = parseDate(ctx.parent?.remunerationDateDebut)
                    if (!dateDebutPeriode)
                        return true
                    if (isBefore(dateDebutPeriode2, addDays(dateDebutPeriode, 1))) {
                        return ctx.createError('Le début de période 2 doit être après le début de période 1')
                    }
                }
                if (ctx.parent?.remunerationDateChangement1) {
                    const dateFinPeriode1 = parseDate(ctx.parent?.remunerationDateChangement1)
                    if (!dateFinPeriode1)
                        return true
                    if (isEqual(dateDebutPeriode2, addDays(dateFinPeriode1, 1)))
                        return true
                    return ctx.createError('Le début de période 2 doit suivre la fin de période 1')
                }
                return true
            }),
        remunerationDateFin: OptionalDate
            .requiredIf<DossierCAPPAnneeRemuneration>(ctx => 
                ctx.root?.contrat?.remuneration?.modeManuelRemuneration === true &&
                ctx.parent?.remunerationDateChangement != null, "Obligatoire si une date de début est définie"
            )
            .test<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => {
                if (!(ctx.root?.contrat?.remuneration?.modeManuelRemuneration) && !editForm)
                    return true
                if (!ctx.parent?.remunerationDateChangement)
                    return true
                const dateDebutPeriode = parseDate(ctx.parent?.remunerationDateChangement)
                const dateFinPeriode = parseDate(ctx.value)
                if (!dateDebutPeriode || !dateFinPeriode)
                    return true
                if (ctx.parent?.remunerationDateDebut) {
                    const remunerationDateDebut = parseDate(ctx.parent?.remunerationDateDebut)
                    if (!remunerationDateDebut)
                        return true
                    if (isAfter(dateFinPeriode, addYears(remunerationDateDebut, 1))) {
                        return ctx.createError('La période doit faire moins d\'une année')
                    }
                }
                if (isAfter(dateFinPeriode, dateDebutPeriode) || isEqual(dateFinPeriode, dateDebutPeriode))
                    return true
                return ctx.createError('La date de fin de période doit être après la date de début')
            }),
        remunerationPourcentage1: PercentNumber
            .when<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => {
                return ctx.parent?.remunerationDateDebut 
                    ? ctx.schema.required()
                        // eslint-disable-next-line no-template-curly-in-string
                        .min((c: ValidationContext<number, DossierCAPPAnneeRemuneration>) => c.parent?.remunerationGrille1, 'Taux minimum légal ${min}%') 
                    : ctx.schema
                }
            ),
        remunerationType1: OptionalString
            .when<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => ctx.parent?.remunerationDateDebut 
                ? ctx.schema.required('Choix obligatoire') : ctx.schema
            ),
        remunerationPourcentage2: PercentNumber
            .when<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => ctx.parent?.remunerationDateChangement 
                ? ctx.schema.required()
                    // eslint-disable-next-line no-template-curly-in-string
                    .min((c: ValidationContext<number, DossierCAPPAnneeRemuneration>) => c.parent?.remunerationGrille2, 'Taux minimum légal ${min}%') 
                : ctx.schema
            ),
        remunerationType2: OptionalString
            .when<DossierCAPPAnneeRemuneration, DossierCAPP>(ctx => ctx.parent?.remunerationDateChangement 
                ? ctx.schema.required('Choix obligatoire') : ctx.schema
            ),
        remunerationTranche1: Ignored,
        remunerationTranche2: Ignored,
        remunerationAge1: Ignored,
        remunerationAge2: Ignored,
        remunerationGrille1: Ignored,
        remunerationGrille2: Ignored,
        remunerationForceType1: Ignored,
        remunerationForceType2: Ignored,
    })

    return createMetadata({
        props: {
            exportLabel: 'Année rémunération',
        },
        yop: Yop.object<DossierCAPPRemuneration>({
            modeManuelRemuneration: RequiredBoolean,
            employeurAttesteRemuneration: OptionalBoolean.requiredIf<DossierCAPPRemuneration>(ctx => !!ctx.parent?.modeManuelRemuneration),
            anneesRemuneration: Yop.array<DossierCAPPAnneeRemuneration>(anneeRemunerationSchema)
                .test(ctx => {
                    const annees = ctx.value
                    if (annees) {
                        for (let i = 0; i < annees.length - 1; i++) {
                            const finAnnee1 = annees[i]?.remunerationDateFin ?? annees[i]?.remunerationDateChangement1
                            const debutAnnee2 = annees[i + 1]?.remunerationDateDebut
                            if (!finAnnee1 || !debutAnnee2)
                                break
                            if (!isEqual(debutAnnee2, addDays(finAnnee1, 1))) {
                                return ctx.createError(
                                    'La date de début doit être 1 jour après la date de fin de la période précédente', 
                                    `${ctx.path}[${i + 1}].remunerationDateDebut`
                                )
                            }
                        }
                        
                        if (!ctx.parent?.modeManuelRemuneration && !editForm)
                            return true
                        if (!ctx.root?.contrat?.dateFin)
                            return true

                        const dateDebut = annees[0]?.remunerationDateDebut
                        if (!dateDebut || !isEqual(dateDebut, ctx.root?.contrat?.dateDebut)) {
                            return ctx.createError(
                                'La date de début de la première période doit être la date de début du contrat',
                                `${ctx.path}[0].remunerationDateDebut`
                            )
                        }

                        let dateFin, lastIndex, lastField
                        for (let i = 0; i < annees.length; i++) {
                            const finAnnee = annees[i]?.remunerationDateFin ?? annees[i]?.remunerationDateChangement1
                            if (finAnnee) {
                                dateFin = finAnnee
                                lastIndex = i
                                lastField = annees[i]?.remunerationDateFin ? 'remunerationDateFin' : 'remunerationDateChangement1'
                            }
                        }
                        if (!dateFin || !isEqual(dateFin, ctx.root?.contrat?.dateFin)) {
                            return ctx.createError(
                                'La date de fin de la dernière période doit être la date de fin du contrat',
                                `${ctx.path}[${lastIndex}].${lastField}`
                            )
                        }
                    }
                    return true
                })
                .required(),
        }).required(),
        convert: (value: DossierCAPPRemuneration) => {
            if (!value || !value.anneesRemuneration)
                return value
            value.anneesRemuneration.forEach(a => {
                a.remunerationDateDebut = dateConverter(a.remunerationDateDebut)
                a.remunerationDateChangement = dateConverter(a.remunerationDateChangement)
                a.remunerationDateChangement1 = dateConverter(a.remunerationDateChangement1)
                a.remunerationDateFin = dateConverter(a.remunerationDateFin)
            })
            return value
        },
        revert: (value: DossierCAPPRemuneration) => {
            if (!value || !value.anneesRemuneration)
                return value
            value.anneesRemuneration.forEach(a => {
                a.remunerationDateDebut = dateReverter(a.remunerationDateDebut)
                a.remunerationDateChangement = dateReverter(a.remunerationDateChangement)
                a.remunerationDateChangement1 = dateReverter(a.remunerationDateChangement1)
                a.remunerationDateFin = dateReverter(a.remunerationDateFin)
            })
            return value
        },
        diff: diffRemunerations,
        input: RemunerationFields,
        recap: RecapRemuneration,
        export: ExportRemuneration,
        mapping: remunerationMapping(),
    }, settings)
}

interface RemunerationFieldsProps {
    name: string
    editMode?: boolean
}

const safeDifferenceInYears = (d1?: Date, d2?: Date) => {
    if (!d1 || !d2)
        return null
    return differenceInYears(d1, d2)
}

const safeHasChanged = (d1?: Date, d2?: Date) => {
    if (d1 == null)
        return d2 != null
    if (d2 == null)
        return d1 != null
    return !isEqual(d1, d2)
}

export function RemunerationFields(props: RemunerationFieldsProps) {
    const context = useFormContext<DossierCAPP>()
    const fieldState = props.name ? getFieldState<DossierCAPPRemuneration | null>(context, props.name) : null
    const saveDates = useRef({ 
        dateNaissance: context.values?.stagiaire?.dateNaissance, 
        dateDebut: context.values?.contrat?.dateDebut, 
        dateFin: context.values?.contrat?.dateFin, 
    })
    const remuneration = fieldState?.value

    useEffect(() => {
        if (context.values && remuneration && (
            safeHasChanged(context.values?.stagiaire?.dateNaissance, saveDates.current.dateNaissance)
            || safeHasChanged(context.values?.contrat?.dateDebut, saveDates.current.dateDebut) 
            || safeHasChanged(context.values?.contrat?.dateFin, saveDates.current.dateFin))) {
            if (context.values.contrat.remuneration.modeManuelRemuneration || props.editMode) {
                rebuildAnneesRemuneration(context.values, props.editMode ?? false, true)
                context.values.contrat.remuneration.anneesRemuneration.forEach((annee, index) => {
                    const dateDebut = annee.remunerationDateDebut ? parseDate(annee.remunerationDateDebut) : undefined
                    const dateFin = annee.remunerationDateChangement1 ? parseDate(annee.remunerationDateChangement1)! : undefined
                    const singleLine = dateDebut && dateFin && ((safeDifferenceInYears(addDays(dateFin, 1), dateDebut) ?? 0) >= 1 
                        || (context.values?.contrat?.dateFin && isEqual(dateFin, parseDate(context.values?.contrat.dateFin)!)))
                    if (singleLine) {
                        context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateChangement`, null, SetValueOptions.Untouch)
                        context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateFin`, null, SetValueOptions.Untouch)
                        context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationPourcentage2`, null, SetValueOptions.Untouch)
                        context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationType2`, null, SetValueOptions.Untouch)
                    }
                })
            } else {
                const valuesCopy = cloneDeep(context.values)
                rebuildAnneesRemuneration(valuesCopy, props.editMode ?? false, true)
                context.setValue(props.name, valuesCopy.contrat.remuneration)
            }
            context.renderForm()
        }
        saveDates.current = { 
            dateNaissance: context.values?.stagiaire?.dateNaissance, 
            dateDebut: context.values?.contrat?.dateDebut, 
            dateFin: context.values?.contrat?.dateFin,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.values?.stagiaire?.dateNaissance, context.values?.contrat?.dateDebut, context.values?.contrat?.dateFin])
    
    if (!remuneration || (remuneration.anneesRemuneration?.length ?? 0) === 0) {
        return null
    }

    return remuneration.modeManuelRemuneration || props.editMode ?
        <>{ remuneration.anneesRemuneration?.map((annee, index) => {
            const dateDebut = annee.remunerationDateDebut ? parseDate(annee.remunerationDateDebut) : undefined
            const dateFin = annee.remunerationDateChangement1 ? parseDate(annee.remunerationDateChangement1)! : undefined
            const singleLine = dateDebut && dateFin && ((safeDifferenceInYears(addDays(dateFin, 1), dateDebut) ?? 0) >= 1 
                || (context.values?.contrat?.dateFin && isEqual(dateFin, parseDate(context.values?.contrat.dateFin)!)))
            if (singleLine) {
                if (annee.remunerationDateChangement)
                    context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateChangement`, null, SetValueOptions.Untouch)
                if (annee.remunerationDateFin)
                    context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateFin`, null, SetValueOptions.Untouch)
                if (annee.remunerationPourcentage2)
                    context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationPourcentage2`, null, SetValueOptions.Untouch)
                if (annee.remunerationType2)
                    context.setValue(`${props.name}.anneesRemuneration[${index}].remunerationType2`, null, SetValueOptions.Untouch)
            }
            
            return <MyForm.Fieldset
                key={props.name + '-' + index}
                legend={`Rémunération - Année ${index + 1}`}
                tooltipContent={ TOOLTIPS.capp.contrat.remuneration }
            >
                <MyForm.Grid>
                    <MyForm.Field short>
                        <DateField name={`${props.name}.anneesRemuneration[${index}].remunerationDateDebut`}
                            label={annee.remunerationAge1 && !isNaN(annee.remunerationAge1) ? `${annee.remunerationAge1} ans, à partir du` : 'À partir du'}
                            onBlur={(rdc, ctx, _, change) => {
                                if (change) {
                                    const valuesCopy = cloneDeep(context.values)
                                    if (valuesCopy) {
                                        rebuildAnneesRemuneration(valuesCopy, props.editMode ?? false, true)
                                        const modified = valuesCopy.contrat.remuneration.anneesRemuneration[index]
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationTranche1`, modified.remunerationTranche1)
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationAge1`, modified.remunerationAge1)
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationGrille1`, modified.remunerationGrille1)
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationForceType1`, modified.remunerationForceType1)
                                    }
                                }
                            }} />
                    </MyForm.Field>

                    <MyForm.Field short>
                        <DateField name={`${props.name}.anneesRemuneration[${index}].remunerationDateChangement1`}
                            label="Jusqu'au"
                            onBlur={(rdc1, ctx, _, change) => {
                                const dateFin = parseDate(rdc1) ?? undefined
                                if (change && dateDebut && dateFin && ((safeDifferenceInYears(addDays(dateFin, 1), dateDebut) ?? 0) >= 1 
                                    || (context.values?.contrat?.dateFin && isEqual(dateFin, parseDate(context.values?.contrat?.dateFin)!)))) {
                                    ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateChangement`, null)
                                    ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateFin`, null)
                                    ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationPourcentage2`, null)
                                    ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationType2`, null, true)
                                }
                            }} />
                    </MyForm.Field>

                    <MyForm.Field short>
                        <NumberField name={`${props.name}.anneesRemuneration[${index}].remunerationPourcentage1`}
                            label="Pourcentage" suffix="%" />
                    </MyForm.Field>

                    <MyForm.Field short>
                        <RadioRefField name={`${props.name}.anneesRemuneration[${index}].remunerationType1`}
                            label="Du"
                            options={refs => refs.REF_TYPE_MONTANT} />
                    </MyForm.Field>
                </MyForm.Grid>

                <MyForm.Grid>
                    <MyForm.Field short>
                        <DateField name={`${props.name}.anneesRemuneration[${index}].remunerationDateChangement`}
                            label={annee.remunerationAge2 && !isNaN(annee.remunerationAge2) ? `${annee.remunerationAge2} ans, à partir du` : 'À partir du'}
                            onBlur={(rdc1, ctx, _, change) => {
                                if (change) {
                                    const valuesCopy = cloneDeep(context.values)
                                    if (valuesCopy) {
                                        rebuildAnneesRemuneration(valuesCopy, props.editMode ?? false, true)
                                        const modified = valuesCopy.contrat.remuneration.anneesRemuneration[index]
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationTranche2`, modified.remunerationTranche2)
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationAge2`, modified.remunerationAge2)
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationGrille2`, modified.remunerationGrille2)
                                        ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationForceType2`, modified.remunerationForceType2)
                                    }
                                }
                            }}
                            disabled={ singleLine }
                        />
                    </MyForm.Field>

                    <MyForm.Field short>
                        <DateField name={`${props.name}.anneesRemuneration[${index}].remunerationDateFin`}
                            label="Jusqu'au"
                            disabled={ singleLine }
                        />
                    </MyForm.Field>

                    <MyForm.Field short>
                        <NumberField name={`${props.name}.anneesRemuneration[${index}].remunerationPourcentage2`}
                            label="Pourcentage" suffix="%" fractionDigits={2}
                            disabled={ singleLine }
                        />
                    </MyForm.Field>

                    <MyForm.Field short>
                        <RadioRefField name={`${props.name}.anneesRemuneration[${index}].remunerationType2`}
                            label="Du"
                            disabled={ singleLine }
                            options={refs => refs.REF_TYPE_MONTANT} />
                    </MyForm.Field>
                </MyForm.Grid>
            </MyForm.Fieldset>
        }) }</>
        :
        <>
            <MyForm.Field full>
                <GrilleManuelle name={ props.name } />
            </MyForm.Field>

            <>{ remuneration.anneesRemuneration?.filter(annee => annee.remunerationDateDebut).map((annee, index) => {
                return <MyForm.Fieldset
                    key={`anneeRemuneration-${index}`}
                    legend={`Rémunération - Année ${index + 1}`}
                    tooltipContent={ TOOLTIPS.capp.contrat.remuneration }
                >
                    {annee.remunerationDateChangement ? <>
                        <MyForm.Grid>
                            <MyForm.Field short>
                                <PseudoTextField
                                    label={`${annee.remunerationAge1} ans, à partir du`}
                                    type="date"
                                    value={annee.remunerationDateDebut} />
                            </MyForm.Field>

                            <MyForm.Field short>
                                <PseudoTextField
                                    label="Jusqu'au"
                                    type="date"
                                    value={annee.remunerationDateChangement1} />
                            </MyForm.Field>

                            <MyForm.Field short>
                                <NumberField name={`${props.name}.anneesRemuneration[${index}].remunerationPourcentage1`}
                                    label="Pourcentage" fractionDigits={2}
                                    suffix="%"
                                />
                            </MyForm.Field>

                            <MyForm.Field short>
                                <RadioRefField name={`${props.name}.anneesRemuneration[${index}].remunerationType1`}
                                    label="Du"
                                    options={refs => refs.REF_TYPE_MONTANT}
                                    disabled={annee.remunerationForceType1 !== null} />
                            </MyForm.Field>
                        </MyForm.Grid>
                        <MyForm.Grid>
                            <MyForm.Field short>
                                <PseudoTextField
                                    label={`${annee.remunerationAge2} ans, à partir du`}
                                    type="date"
                                    value={annee.remunerationDateChangement} />
                            </MyForm.Field>

                            <MyForm.Field short>
                                <PseudoTextField
                                    label="Jusqu'au"
                                    type="date"
                                    value={annee.remunerationDateFin} />
                            </MyForm.Field>

                            <MyForm.Field short>
                                <NumberField name={`${props.name}.anneesRemuneration[${index}].remunerationPourcentage2`}
                                    label="Pourcentage" fractionDigits={2}
                                    suffix="%"
                                />
                            </MyForm.Field>

                            <MyForm.Field short>
                                <RadioRefField name={`${props.name}.anneesRemuneration[${index}].remunerationType2`}
                                    label="Du"
                                    options={refs => refs.REF_TYPE_MONTANT}
                                    disabled={annee.remunerationForceType2 !== null} />
                            </MyForm.Field>
                        </MyForm.Grid>
                    </> :
                    <MyForm.Grid>
                        <MyForm.Field short>
                            <PseudoTextField
                                label={`${annee.remunerationAge1} ans, à partir du`}
                                type="date"
                                value={annee.remunerationDateDebut} />
                        </MyForm.Field>

                        <MyForm.Field short>
                            <PseudoTextField
                                label="Jusqu'au"
                                type="date"
                                value={annee.remunerationDateFin} />
                        </MyForm.Field>

                        <MyForm.Field short>
                            <NumberField name={`${props.name}.anneesRemuneration[${index}].remunerationPourcentage1`}
                                label="Pourcentage" fractionDigits={2}
                                suffix="%"
                            />
                        </MyForm.Field>

                        <MyForm.Field short>
                            <RadioRefField name={`${props.name}.anneesRemuneration[${index}].remunerationType1`}
                                label="Du"
                                options={refs => refs.REF_TYPE_MONTANT}
                                disabled={annee.remunerationForceType1 !== null} />
                        </MyForm.Field>
                    </MyForm.Grid>}
                </MyForm.Fieldset>
            })} </>
        </>
}

interface GrilleManuelleProps {
    name: string
}

export function GrilleManuelle(props: GrilleManuelleProps) {
    const ctx = useFormContext<DossierCAPP>()
    const fieldState = props.name ? getFieldState<DossierCAPPRemuneration | null>(ctx, props.name) : null
    const remuneration = fieldState?.value

    const [showConfirmModeManuelRemuneration, setShowConfirmModeManuelRemuneration] = useState(false)
    const [atteste, setAtteste] = useState(false)

    const show = () => {
        setAtteste(false)
        setShowConfirmModeManuelRemuneration(true)
    }

    const onAttesteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAtteste(event.target.checked)
    }

    const confirmModeManuelRemuneration = () => {
        ctx.setValue(`${props.name}.modeManuelRemuneration`, true)
        ctx.setValue(`${props.name}.employeurAttesteRemuneration`, true)

        remuneration?.anneesRemuneration?.forEach((annee, index) => {
            if (!annee.remunerationDateChangement) {
                ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateChangement1`, annee.remunerationDateFin)
                ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationDateFin`, null)
            }
            /* #2805
            ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationTranche1`, 2)
            ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationTranche2`, 2)
            ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationGrille1`, GRILLE_LEGALE[index][2])
            ctx.setValue(`${props.name}.anneesRemuneration[${index}].remunerationGrille2`, GRILLE_LEGALE[index][2]) */
        })

        ctx.renderForm()

        setShowConfirmModeManuelRemuneration(false)
    }

    return <>
        <AtlasFlex column gap="m" alignItems='start'>
            <p>La grille de rémunération est construite dynamiquement en fonction de la réglementation en vigueur et des caractéristiques de votre contrat, cependant vous pouvez avoir plus de souplesse en utilisant une saisie manuelle.</p>

            <AtlasButton level={ 2 } onClick={ show }>
                Utiliser la grille de rémunération manuelle
            </AtlasButton>
        </AtlasFlex>

        { showConfirmModeManuelRemuneration &&
        <MyModal
            label="Confirmer le mode manuel"
            okButton={<AtlasButton onClick={confirmModeManuelRemuneration} disabled={!atteste}>Confirmer</AtlasButton>}
            cancelButton={<AtlasButton onClick={() => setShowConfirmModeManuelRemuneration(false)}>Annuler</AtlasButton>}
        >
            <AtlasHtml>
                <p>
                    L’OPCO Atlas, en vue du dépôt du contrat auprès des services du Ministère en charge de la formation professionnelle et de sa prise en charge financière,
                    vérifie à cet effet que le contrat satisfait aux conditions de l’article D6222-26 du code du travail conformément aux éléments de rémunération proposés.
                </p>
                <p>
                    Vous avez la possibilité de modifier les éléments de rémunération, notamment lorsque des dispositions légales ou conventionnelles plus favorables s’appliquent.
                    Les grilles applicables dans votre branche professionnelle sont accessibles dans la boite à outils.
                </p>
                    <p><strong>Important</strong> : une modification entrainant le non-respect des règles applicables aura pour effet un délai de traitement plus important ou un refus du financement, aussi&nbsp;</p>
                    <ul>
                        <li>Le taux modifié ne peut être inférieur à celui proposé</li>
                        <li>L’amplitude des dates sur une année ne peut être supérieure à celle proposée et les dates entre la fin et le début de chaque période doivent se suivre (J+1 sur le début de la période suivante)</li>
                        <li>Pour chaque année, la date de début la première période doit impérativement correspondre à la date d’anniversaire du contrat, elle est remplie en fonction de l’année d’exécution du contrat et non l’année de formation</li>
                    </ul>
            </AtlasHtml>
            <AtlasSpacer />
            <AtlasCheckbox
                name="remunerationManuelle"
                value="remunerationManuelle"
                label="En validant ces informations, l’employeur atteste sur l’honneur que le salaire correspondant au pourcentage par rapport au SMIC ou SMC renseigné dans cette rubrique est le plus favorable à l’apprenti conformément à l’article D6222.26 du code du travail"
                required
                checked={ atteste }
                onChange={ onAttesteChange }
            />
        </MyModal>
        }
    </>
}

export function remunerationMapping(): FieldMapping<DossierCAPPRemuneration | null> {
    return {
        mapModelToDTO(value, dto: DossierApprentissageDTO) {
            dto.RemunerationApprentissageModeManuelFO__c = value?.modeManuelRemuneration ?? null
            dto.EmployeurAttesteSalaireplusFavorable__c = value?.employeurAttesteRemuneration ?? null
            dto.Remunerations = value?.anneesRemuneration?.flatMap((ar, index) => {
                return [{
                    IdHeroku: ar.IdHeroku1 ?? null,
                    Dossier__c: ar.dossierC  ?? null,
                    Periode__c: index * 2,
                    DateDebut__c: ar.remunerationDateDebut ?? null,
                    DateFin__c: (ar.remunerationDateChangement1 ? ar.remunerationDateChangement1 : ar.remunerationDateFin) ?? null,
                    Actif__c: ar.remunerationDateDebut ? true : false,
                    Taux__c: ar.remunerationPourcentage1 ?? null,
                    TypeMontant__c: ar.remunerationDateDebut ? ar.remunerationType1 ?? null : null
                }, {
                    IdHeroku: ar.IdHeroku2 ?? null,
                    Dossier__c: ar.dossierC  ?? null,
                    Periode__c: index * 2 + 1,
                    DateDebut__c: ar.remunerationDateChangement ?? null,
                    DateFin__c: ar.remunerationDateChangement ? ar.remunerationDateFin : null,
                    Actif__c: ar.remunerationDateChangement ? true : false,
                    Taux__c: ar.remunerationPourcentage2 ?? null,
                    TypeMontant__c: ar.remunerationDateDebut ? ar.remunerationType2 ?? null : null
                }] as DossierSalairePeriodeDTO[]
            }) ?? null
        },
        mapDTOToModel(dto: DossierApprentissageDTO, context: MappingContext<DossierCAPP>) {
            const dateNaissance = parseDate(dto.Stagiaires?.[0]?.DateNaissance__c)
            const remuneration = context.dossier?.contrat?.remuneration
            if (!dateNaissance)
                return remuneration
            remuneration.modeManuelRemuneration = dto.RemunerationApprentissageModeManuelFO__c ?? null
            remuneration.employeurAttesteRemuneration = dto.EmployeurAttesteSalaireplusFavorable__c ?? null
            if (remuneration && (remuneration.modeManuelRemuneration || context.editMode)) {
                for (let i = 0; i < 4; i++) {
                    const remunerationPeriode1 = dto.Remunerations?.find(r => r.Periode__c === i * 2) ?? { IdHeroku: null, DateDebut__c: null, DateFin__c: null, TypeMontant__c: null, Taux__c: null }
                    const remunerationPeriode2 = dto.Remunerations?.find(r => r.Periode__c === i * 2 + 1) ?? { IdHeroku: null, DateDebut__c: null, DateFin__c: null, TypeMontant__c: null, Taux__c: null }
                    if (i >= remuneration.anneesRemuneration.length) {
                        remuneration.anneesRemuneration.push({
                            IdHeroku1: remunerationPeriode1?.IdHeroku ?? (nil as number),
                            IdHeroku2: remunerationPeriode2?.IdHeroku ?? (nil as number),
                            dossierC: dto.Id ?? (nil as string),
                            remunerationDateDebut: normalizeAPIDate(remunerationPeriode1.DateDebut__c) ?? null,
                            remunerationDateChangement1: normalizeAPIDate(remunerationPeriode1.DateFin__c) ?? null,
                            remunerationDateChangement: normalizeAPIDate(remunerationPeriode2.DateDebut__c) ?? null,
                            remunerationDateFin: normalizeAPIDate(remunerationPeriode2.DateFin__c) ?? null,
                            remunerationTranche1: null,
                            remunerationGrille1: 0,
                            remunerationPourcentage1: remunerationPeriode1.Taux__c ?? null,
                            remunerationType1: remunerationPeriode1.TypeMontant__c ?? null,
                            remunerationTranche2: null,
                            remunerationGrille2: 0,
                            remunerationPourcentage2: remunerationPeriode2.Taux__c ?? null,
                            remunerationType2: remunerationPeriode2.TypeMontant__c ?? null,
                            remunerationForceType1: null,
                            remunerationForceType2: null,
                            remunerationAge1: remunerationPeriode1.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode1.DateDebut__c)!, dateNaissance) : null,
                            remunerationAge2: remunerationPeriode2.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode2.DateDebut__c)!, dateNaissance) : null,
                        })
                    } else {
                        const anneeRemuneration = remuneration.anneesRemuneration[i]
                        anneeRemuneration.IdHeroku1 = remunerationPeriode1.IdHeroku ?? (nil as number)
                        anneeRemuneration.IdHeroku2 = remunerationPeriode2.IdHeroku ?? (nil as number)
                        anneeRemuneration.remunerationDateDebut = normalizeAPIDate(remunerationPeriode1.DateDebut__c) ?? null
                        anneeRemuneration.remunerationDateChangement1 = normalizeAPIDate(remunerationPeriode1.DateFin__c) ?? null
                        anneeRemuneration.remunerationDateChangement = normalizeAPIDate(remunerationPeriode2.DateDebut__c) ?? null
                        anneeRemuneration.remunerationDateFin = normalizeAPIDate(remunerationPeriode2.DateFin__c) ?? null
                        anneeRemuneration.remunerationPourcentage1 = remunerationPeriode1.Taux__c ?? null
                        anneeRemuneration.remunerationType1 = remunerationPeriode1.TypeMontant__c ?? null
                        anneeRemuneration.remunerationPourcentage2 = remunerationPeriode2.Taux__c ?? null
                        anneeRemuneration.remunerationType2 = remunerationPeriode2.TypeMontant__c ?? null
                        anneeRemuneration.remunerationAge1 = remunerationPeriode1.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode1.DateDebut__c)!, dateNaissance) : null
                        anneeRemuneration.remunerationAge2 = remunerationPeriode2.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode2.DateDebut__c)!, dateNaissance) : null
                        anneeRemuneration.remunerationForceType1 = null
                        anneeRemuneration.remunerationForceType2 = null
                    }
                }
            } else if (remuneration) {
                for (let i = 0; i < 4; i++) {
                    const remunerationPeriode1 = dto.Remunerations?.find(r => r.Periode__c === i * 2) ?? { IdHeroku: null, DateDebut__c: null, DateFin__c: null, TypeMontant__c: null, Taux__c: null }
                    const remunerationPeriode2 = dto.Remunerations?.find(r => r.Periode__c === i * 2 + 1) ?? { IdHeroku: null, DateDebut__c: null, DateFin__c: null, TypeMontant__c: null, Taux__c: null }
                    if (i >= remuneration.anneesRemuneration.length) {
                        remuneration.anneesRemuneration.push({
                            IdHeroku1: remunerationPeriode1?.IdHeroku ?? (nil as number),
                            IdHeroku2: remunerationPeriode2?.IdHeroku ?? (nil as number),
                            dossierC: dto.Id ?? (nil as string),
                            remunerationDateDebut: normalizeAPIDate(remunerationPeriode1.DateDebut__c) ?? null,
                            remunerationDateChangement1: normalizeAPIDate(remunerationPeriode1.DateFin__c) ?? null,
                            remunerationDateChangement: normalizeAPIDate(remunerationPeriode2.DateDebut__c) ?? null,
                            remunerationDateFin: (normalizeAPIDate(remunerationPeriode2.DateDebut__c) ? normalizeAPIDate(remunerationPeriode2.DateFin__c) : normalizeAPIDate(remunerationPeriode1.DateFin__c)) ?? null,
                            remunerationTranche1: null,
                            remunerationGrille1: 0,
                            remunerationPourcentage1: remunerationPeriode1.Taux__c ?? null,
                            remunerationType1: remunerationPeriode1.TypeMontant__c ?? null,
                            remunerationTranche2: null,
                            remunerationGrille2: 0,
                            remunerationPourcentage2: remunerationPeriode2.Taux__c ?? null,
                            remunerationType2: remunerationPeriode2.TypeMontant__c ?? null,
                            remunerationForceType1: null,
                            remunerationForceType2: null,
                            remunerationAge1: remunerationPeriode1.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode1.DateDebut__c)!, dateNaissance) : null,
                            remunerationAge2: remunerationPeriode2.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode2.DateDebut__c)!, dateNaissance) : null,
                        })
                    } else {
                        const anneeRemuneration = remuneration.anneesRemuneration[i]
                        anneeRemuneration.IdHeroku1 = remunerationPeriode1.IdHeroku ?? anneeRemuneration.IdHeroku1
                        anneeRemuneration.IdHeroku2 = remunerationPeriode2.IdHeroku ?? anneeRemuneration.IdHeroku2
                        anneeRemuneration.remunerationDateDebut = normalizeAPIDate(remunerationPeriode1.DateDebut__c) ?? anneeRemuneration.remunerationDateDebut
                        anneeRemuneration.remunerationDateChangement1 = normalizeAPIDate(remunerationPeriode1.DateFin__c) ?? anneeRemuneration.remunerationDateChangement1
                        anneeRemuneration.remunerationDateChangement = normalizeAPIDate(remunerationPeriode2.DateDebut__c) ?? anneeRemuneration.remunerationDateChangement
                        anneeRemuneration.remunerationDateFin = (normalizeAPIDate(remunerationPeriode2.DateDebut__c) ? normalizeAPIDate(remunerationPeriode2.DateFin__c) : normalizeAPIDate(remunerationPeriode1.DateFin__c)) ?? anneeRemuneration.remunerationDateFin
                        anneeRemuneration.remunerationPourcentage1 = remunerationPeriode1.Taux__c ?? anneeRemuneration.remunerationPourcentage1
                        anneeRemuneration.remunerationType1 = (remunerationPeriode1.TypeMontant__c ?? anneeRemuneration.remunerationType1) as (string | null)
                        anneeRemuneration.remunerationPourcentage2 = remunerationPeriode2.Taux__c ?? anneeRemuneration.remunerationPourcentage2
                        anneeRemuneration.remunerationType2 = (remunerationPeriode2.TypeMontant__c ?? anneeRemuneration.remunerationType2) as (string | null)
                        anneeRemuneration.remunerationAge1 = remunerationPeriode1.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode1.DateDebut__c)!, dateNaissance) : anneeRemuneration.remunerationAge1
                        anneeRemuneration.remunerationAge2 = remunerationPeriode2.DateDebut__c && dateNaissance ? differenceInYears(parseDate(remunerationPeriode2.DateDebut__c)!, dateNaissance) : anneeRemuneration.remunerationAge2
                        anneeRemuneration.remunerationForceType1 = null
                        anneeRemuneration.remunerationForceType2 = null
                    }
                }
            }
            return remuneration
        },
    }
}


interface RecapRemunerationProps {
    value: DossierCAPPRemuneration
    editMode: boolean
}

export function RecapRemuneration(props: RecapRemunerationProps) {
    if (!props.value) {
        return null
    }
    const manuel = props.editMode || props.value?.modeManuelRemuneration
    return <> 
        { props.value?.anneesRemuneration
            ?.filter(a => a.remunerationDateDebut)
            ?.map((a: DossierCAPPAnneeRemuneration, index: number) =>
                <AtlasValues.Grid condensed key={`ar${index}`} label={`Rémunération - Année ${index+1}`}>
                    {(manuel && a.remunerationDateChangement1)
                        || (!manuel && a.remunerationDateChangement1 && a.remunerationDateChangement) 
                    ? <>
                        <RecapTextField label="À partir du" type="date" value={a?.remunerationDateDebut} />
                        <RecapTextField label="Jusqu'au" type="date" value={a?.remunerationDateChangement1} />
                        <RecapNumberField label="Pourcentage" suffix="%" value={a?.remunerationPourcentage1} />
                        <RecapRefField label="Du" options={ refs => refs.REF_TYPE_MONTANT } value={ a?.remunerationType1 } />

                        <RecapTextField label="À partir du" type="date" value={a?.remunerationDateChangement} />
                        <RecapTextField label="Jusqu'au" type="date" value={a?.remunerationDateFin} />
                        <RecapNumberField label="Pourcentage" suffix="%" value={a?.remunerationPourcentage2} />
                        <RecapRefField label="Du" options={ refs => refs.REF_TYPE_MONTANT } value={ a?.remunerationType2 } />
                    </> : <>
                        <RecapTextField label="À partir du" type="date" value={a?.remunerationDateDebut} />
                        <RecapTextField label="Jusqu'au" type="date" value={a?.remunerationDateFin} />
                        <RecapNumberField label="Pourcentage" suffix="%" value={a?.remunerationPourcentage1} />
                        <RecapRefField label="Du" options={ refs => refs.REF_TYPE_MONTANT } value={ a?.remunerationType1 } />
                    </>}
                </AtlasValues.Grid>
            )
        }
    </>
}

interface ExportRemunerationProps {
    value: DossierCAPPAnneeRemuneration
    editMode: boolean
    cell: boolean
}

export function ExportRemuneration(props: ExportRemunerationProps) {
    const refs = references()

    const annee = props.value
    let values = annee.remunerationDateChangement ? [
        { label: '', value: 'Du ' + formatDate(annee.remunerationDateDebut) + ' au ' + formatDate(annee.remunerationDateChangement1) +
            ': ' + annee.remunerationPourcentage1 + '% du ' + formatRef(refs?.REF_TYPE_MONTANT, annee.remunerationType1) },
        { label: '', value: 'Du ' + formatDate(annee.remunerationDateChangement) + ' au ' + formatDate(annee.remunerationDateFin) +
        ': ' + annee.remunerationPourcentage2 + '% du ' + formatRef(refs?.REF_TYPE_MONTANT, annee.remunerationType2) },
    ] : [
        { label: '', value: 'Du ' + formatDate(annee.remunerationDateDebut) + ' au ' + formatDate(annee.remunerationDateChangement1) +
            ': ' + annee.remunerationPourcentage1 + '% du ' + formatRef(refs?.REF_TYPE_MONTANT, annee.remunerationType1) },
    ]
    
    return <BlockValue value={{
        cell: props.cell,
        title: 'Rémunération',
        values
    }} />
}

export function diffRemunerations(remu1: DossierCAPPRemuneration, remu2: DossierCAPPRemuneration) {
    const diff1 = []
    const diff2 = []
    const size = Math.max(remu1.anneesRemuneration.length, remu2.anneesRemuneration.length)
    for (let i = 0; i < size; i++) {
        const annee1 = remu1.anneesRemuneration[i]
        const annee2 = remu2.anneesRemuneration[i]
        const cannee1 = {
            dateDebut: annee1.remunerationDateDebut, 
            dateChangement: annee1.remunerationDateChangement, 
            dateChangement1: annee1.remunerationDateChangement1,
            dateFin: annee1.remunerationDateFin,
            pourcentage1: annee1.remunerationPourcentage1,
            pourcentage2: annee1.remunerationPourcentage2,
            type1: annee1.remunerationType1,
            type2: annee1.remunerationType2,
        }
        const cannee2 = { 
            dateDebut: annee2.remunerationDateDebut, 
            dateChangement: annee2.remunerationDateChangement, 
            dateChangement1: annee2.remunerationDateChangement1,
            dateFin: annee2.remunerationDateFin,
            pourcentage1: annee2.remunerationPourcentage1,
            pourcentage2: annee2.remunerationPourcentage2,
            type1: annee2.remunerationType1,
            type2: annee2.remunerationType2,
        }
        if (!_isEqual(cannee1, cannee2)) {
            diff1.push(annee1)
            diff2.push(annee2)
        }
    }
    return [ diff1, diff2 ]
}