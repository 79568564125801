import { Step } from 'react-joyride'
import { ELEMENTS } from '../elements'

export const piecesSteps: Step[] = [
  {
    target: ELEMENTS.FILE_MANDATORY,
    content: 'Ajouter les pièces jointes pour finaliser le dossier',
  },
  {
    target: ELEMENTS.FILE_OPTIONAL,
    content: 'Ajouter une pièce complémentaire',
  },
  {
    target: ELEMENTS.PIECES_CONFIRM,
    content:
      'Enregistrer et passer au récapitulatif. Ce bouton est actif dès que tous les pièces jointes obligatoires de l’étape sont renseignées.',
  },
];
