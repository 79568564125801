import { postCreationSollicitation } from "api/sollicitationAPI"
import { AtlasToastsItemType, MyForm, MyModal } from 'atlas-ds'

import { Form, useForm } from '@dsid-opcoatlas/reform'
import { Sollicitation } from "api/sollicitationAPI"
import Button from "components/reform/Button"
import FileField from "components/reform/inputs/FileField"
import MultilineTextField from "components/reform/inputs/MultilineTextField"
import { AcceptedDocuments, OptionalFileAccept, RequiredString, requiredObject } from 'components/yop/constraints'


interface NousEcrireModalProps {
    close: () => void
    message: (text: string, status: AtlasToastsItemType) => void
    infoNousEcrire: {
        Subject: string
        Priority: string
        ContactId?: string
        AccountId?: string
        Origin: string
        Type: string
        SousType__c: string
        Reference: string
    }
}

const createNousEcrireValues = () => {
    return {
        messageClient: '' as (string),
        file: null as (File | null | undefined)
    }
}
type NousEcrireValues = ReturnType<typeof createNousEcrireValues>

export default function NousEcrireModal(props: NousEcrireModalProps) {

    const initialValues = createNousEcrireValues()
    const validationSchema = requiredObject<NousEcrireValues>({
        messageClient: RequiredString,
        file: OptionalFileAccept(Object.keys(AcceptedDocuments)),
    })



    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            const refDossier = props.infoNousEcrire.SousType__c === 'Facture' ? 'FactureConcernee__c' : 'DossierConcerne__c'
            const sollicitation: Sollicitation = {
                Subject: props.infoNousEcrire.Subject,
                Priority: props.infoNousEcrire.Priority,
                Description: context.values?.messageClient ?? '',
                ContactId: props.infoNousEcrire.ContactId,
                AccountId: props.infoNousEcrire.AccountId,
                Origin: props.infoNousEcrire.Origin,
                Type: props.infoNousEcrire.Type,
                SousType__c: props.infoNousEcrire.SousType__c,
                [`${refDossier}`]: props.infoNousEcrire.Reference
            }

            postCreationSollicitation(sollicitation, context.values?.file?.name, context.values?.file)
                .then(res => {
                    props.message("Votre message a bien été pris en compte et sera traité par nos équipes dans les plus brefs délais", "success")
                }).catch(e => {
                    props.message("Une erreur est survenue durant l'envoi de votre message", "error")
                }).finally(() => {
                    form.reset()
                    context.setSubmitting(false)
                    props.close()
                })
        },
    })

    return (
        <MyModal label="Nous écrire" onClose={ props.close }  isPending={form.submitting}>
            <Form context={form} noValidate>
                <MyForm>
                    <MultilineTextField name="messageClient" label="Message" />
                    <FileField
                        name="file"
                        tooltip={`Formats acceptés : ${Object.values(AcceptedDocuments).join(', ')} — 15 Mo maximum`}
                        label={<>Ajouter une pièce complémentaire{' '} </>}
                    />

                    <MyForm.Actions>
                        <Button submit={true} spinner={{spinning: form.submitting}}>Enregistrer</Button>
                        <Button level={2} onClick={() => props.close()}>Annuler</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}