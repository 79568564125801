export interface MyCenteredProps {
  /**
   * Un ou plusieurs éléments à contenir
   */
  children: React.ReactNode;
}

/**
 * Un ou plusieurs éléments centrés avec une largeur maximale
 */
export function MyCentered(props: MyCenteredProps) {
  return (
    <div className="my-centered">
      <div>{props.children}</div>
    </div>
  );
}
