import axios from 'axios'
import { getRequestConfig } from './baseAPI'

export interface Parametre {
    id: string
    titre: string
    contenu: string
    type?: 'info' | 'success' | 'warning' | 'error'
    labelLien?: string
    lien?: string
    items?: [{
        item: string
        description: string
    }]
    dispositifs?: string[]
}

type Categorie = 'BANDEAUX_INFORMATIONS' | 'BANDEAUX_INFORMATIONS_BANCAIRES' | 'BOITE_A_OUTILS'

export async function getParametres(categorie: Categorie): Promise<Parametre[]> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.append('categorie', categorie)

    requestConfig.params = params

    const { data } = await axios.get<Parametre[]>('/config/parametres', requestConfig)

    return data;
}
