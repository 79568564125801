import { AtlasHeading, AtlasInfo, AtlasSpacer, MyTable } from "atlas-ds"
import { Link } from "react-router-dom"

export interface WebAdhesionErrorProps {
    errorMessage: string;
    codesNAF?: { CODE_NAF: string; INTITULE_CODE_APE: string }[];
}


export default function WebAdhesionError(props: WebAdhesionErrorProps) {
    return <>
        <AtlasInfo
            type="error"
            title="Erreur lors de la récupération des données"
            link={<Link to="/">Retour à l'accueil</Link>}
        >{ props.errorMessage }</AtlasInfo>

        {props.codesNAF && props.codesNAF.length > 0 && (<>
            <AtlasSpacer size="xl" />

            <AtlasHeading tag="h2" size="s">Liste des codes NAF relevant d'Atlas</AtlasHeading>

            <MyTable
                key='codesNAF'
                caption="Codes NAF relevant d'Atlas"
                rows={props.codesNAF.sort((a, b) => a.CODE_NAF.localeCompare(b.CODE_NAF))}
                emptyText="Aucun code NAF trouvé"
                columns={[
                    {
                        id: 'CODE_NAF',
                        label: 'Code NAF',
                    },
                    {
                        id: 'INTITULE_CODE_APE',
                        label: 'INTITULE',
                    },
                ]}
            />
        </>)}
    </>
}
