import { useLocation } from "react-router-dom"

export const useQueryParam = (name: string): string | null => {
    const { search } = useLocation()
    const params = new URLSearchParams(search)
    return params.get(name)
}

export const useIntegerQueryParam = (name: string): number | null => {
    const value = useQueryParam(name)
    if (value != null && /^\d+$/.test(value))
        return parseInt(value)
    return null
}

