import { getLienActivation } from "api/monCompteAPI"
import useApiState from "api/useApiState"
import { AtlasButton, AtlasFlex, AtlasInfo } from "atlas-ds"
import PageHeader from "components/PageHeader"
import ErrorBanner from "components/reform/ErrorBanner"
import { useLocation } from "react-router-dom"
import { EmailState } from "./MamcApp"

export function CreerMonCompteValidationEmail() {

    const location = useLocation()
    const email = (location.state as EmailState)?.email

    const [{ pending, value, error }, withGetLienActivation] = useApiState(getLienActivation, { pending: false })

    const renvoyerEmail = () => {
        if (email != null)
            withGetLienActivation(email)
    }

    return <>
        <PageHeader>Une dernière étape !</PageHeader>

        <AtlasFlex column gap="m">
            <AtlasInfo title="Vous recevrez dans quelques minutes à votre adresse email un lien vous permettant d'activer votre compte myAtlas." >
                Attention ! Le lien a une durée de validité d'une heure. Au-delà de ce délai, vous devrez refaire le processus de création de compte.
            </AtlasInfo>

            { email != null && error == null &&
            <AtlasFlex gap="m" alignItems="baseline">
                <p>Vous n'avez pas reçu de mail ?</p>
                <AtlasButton type="button"  icon="aide" key="ernvoiMailValidatin" level={3} disabled={ pending } onClick={ renvoyerEmail }>
                    Renvoyer un mail de validation
                </AtlasButton>
            </AtlasFlex>
            }
            { email != null && pending === false && value === true && <AtlasInfo title="E-mail renvoyé">
                L'e-mail d'activation a été renvoyé à l'adresse { email }.
            </AtlasInfo> }
            { email != null && pending === false && error != null &&
                <ErrorBanner title="Impossible de renvoyer l'e-mail d'activation" message={ error } />
            }
        </AtlasFlex>
     </>
}
