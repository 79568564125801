import { DossierDetail, getDossierDetailReglements } from "api/dossierAPI"
import { findModeReglement } from "api/references"
import useApiState from "api/useApiState"
import { AtlasHeading, AtlasLoading, AtlasText, MyTable } from "atlas-ds"
import { formatApiDate, formatCurrency } from "components/format/Format"
import { capitalize } from "lodash"
import { useEffect } from "react"

export default function DetailDossierReglements(props: { dossier: DossierDetail | null }) {
    const [{ pending, value: reglements }, withGetDossierDetailReglements] = useApiState(getDossierDetailReglements)
    useEffect(() => {
        if (props.dossier)
            withGetDossierDetailReglements(props.dossier.Id)
    }, [props.dossier, withGetDossierDetailReglements])

    return <>
        <AtlasHeading tag="h2" size="m">Règlements</AtlasHeading>
        <AtlasLoading loading={pending}>
            <MyTable
                caption="Règlements"
                emptyText="Aucun règlement"
                rows={reglements ?? []}
                columns={[
                    {
                        id: "ReferencePaiement__c",
                        label: "N° de règlement",
                        wrap: true
                    },
                    {
                        id: "RefFactureClient__c",
                        label: "N° facture",
                        nowrap: true,
                        value: (row: any) => row.Facture?.RefFactureClient__c
                    },
                    {
                        id: "DateReglement__c",
                        label: "Date de règlement",
                        value: (row: any) => [
                            formatApiDate(row.DateReglement__c),
                            <AtlasText small>{capitalize(findModeReglement(row.ModeReglement__c)?.LibelleReduit__c ?? '')}</AtlasText>
                        ]
                    },
                    {
                        id: "MontantHT__c",
                        label: "Montant",
                        value: (row: any) => [
                            <AtlasText bold>{formatCurrency(row.MontantTCC__c, '\xa0TTC')}</AtlasText>,
                            <AtlasText small>{formatCurrency(row.MontantHT__c, '\xa0HT')}</AtlasText>
                        ]
                    },
                    {
                        id: "TypeEmetteur",
                        label: "Bénéficiaire",
                        wrap: true,
                        value: (row: any) => row.Facture?.EtablissementEmetteur?.Name ? row.Facture?.EtablissementEmetteur?.Name + ' - ' + row.Facture?.EtablissementEmetteur?.BillingCity : ''
                    }
                ]}
            />
        </AtlasLoading>
    </>
}
