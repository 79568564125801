import { AtlasValueProps } from 'atlas-ds';

export interface MyGaugeProps {
  /**
   * Le pourcentage à représenter (taille de la barre horizontale),
   * entre 0 et 100
   */
  percentage: number;
  /**
   * La valeur restante, qui sera mise en avant
   */
  remaining?: React.ReactElement<AtlasValueProps>;
  /**
   * La valeur totale
   */
  total: React.ReactElement<AtlasValueProps>;
  /**
   * La valeur utilisée, c'est à dire décomptée du total
   */
  used: React.ReactElement<AtlasValueProps>;
}

/**
 * Une jauge de décompte (d'un budget, d'un nombre de places...)
 */
export function MyGauge(props: MyGaugeProps) {
  return (
    <div className="my-gauge">
      <div className="my-gauge__numbers">
        <div className="my-gauge__remaining">
          { props.remaining }
        </div>

        <div className="my-gauge__breakdown">
          { props.total }
          { props.used }
        </div>
      </div>

      <div
        className="my-gauge__progress"
        style={{ width: `${props.percentage}%` }}
        aria-hidden="true"
      ></div>
    </div>
  );
}
