import { UseFormReturn } from '@dsid-opcoatlas/reform'
import { Reference, References } from 'api/referencesAPI'
import RadioRefField from 'components/reform/inputs/RadioRefField'
import SelectRefField from 'components/reform/inputs/SelectRefField'
import { OptionalString } from 'components/yop/constraints'
import { SettingsFieldMetadata, createMetadata } from 'pages/dossier/metadata/FormMetadata'
import CerfaRadioRefField from 'pages/dossier/cerfa/CerfaRadioRefField'
import CerfaRefField from 'pages/dossier/cerfa/CerfaRefField'
import ExportRefField from 'pages/dossier/export/ExportRefField'
import RecapRefField from 'pages/dossier/recap/RecapRefField'

type ExtendedSettingsFieldMetadata = SettingsFieldMetadata<string | null, {
    options?: (refs: References, parent?: any, form?: UseFormReturn<any>) => Reference[]
    sorted?: boolean
    code?: boolean
    libelle?: boolean
}>

export function SelectRefFieldMetadata(settings: ExtendedSettingsFieldMetadata = {}) {
    return createMetadata(
        {
            yop: OptionalString,
            input: SelectRefField,
            recap: RecapRefField,
            export: ExportRefField,
            cerfa: CerfaRefField,
        },
        settings,
    )
}

export function RadioRefFieldMetadata(settings: ExtendedSettingsFieldMetadata = {}) {
    return SelectRefFieldMetadata({
        input: RadioRefField,
        cerfa: CerfaRadioRefField,
        ...settings,
    })
}