import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierCPRO } from './CPROModel'
import { SECTION_CPRO_CONTRAT } from './CPROContratMetadata'

export function DossierCPROContratElements<D extends DossierCPRO>(props: { metadata: FormMetadata<D> }) {

    return (
        <>
            <MyForm.Grid>
                <FormElements section={ SECTION_CPRO_CONTRAT } metadata={ props.metadata } path="contrat" type="input" />
            </MyForm.Grid>
        </>
    )
}

