import { useEffect, useRef } from "react"

interface CFTurnstileOptions {
    sitekey: string
    callback: (token: string) => void
    'error-callback'?: (cause: any) => void
    'expired-callback'?: () => void
    'unsupported-callback'?: () => void
    'timeout-callback'?: () => void
    'response-field'?: boolean
    execution?: 'render' | 'execute'
}

interface CFTurnstile {
    render: (container: HTMLElement, options: CFTurnstileOptions) => string
    execute: (container: HTMLElement, options: CFTurnstileOptions) => void
}

export type TurnstileErrorType = "error" | "expired" | "unsupported" | "timeout"

type TurnstileProps = {
    setTurnstile: (_: { execute: () => Promise<string> }) => void
}

export function Turnstile({  setTurnstile }: TurnstileProps) {

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        (window as any).onTurnstileScriptLoaded = function () {
            const turnstile = (window as any).turnstile as CFTurnstile

            setTurnstile({
                execute: () => {
                    return new Promise<string>((resolve, reject) => {
                        turnstile.render(containerRef.current!, {
                            sitekey: import.meta.env.REACT_APP_TURNSTILE_SECRET_KEY,
                            callback: (token) => resolve(token),
                            'error-callback': (cause: any) => reject({ type: "error", cause: cause }),
                            'expired-callback': () => reject({ type: "expired" }),
                            'unsupported-callback': () => reject({ type: "unsupported" }),
                            'timeout-callback': () => reject({ type: "timeout" }),
                            'response-field': false,
                        })
                    })
                }
            })
        }

        const script = document.createElement('script')
        script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onTurnstileScriptLoaded"
        script.defer = true
        script.async = true

        const parentNode = document.getElementsByTagName('head')[0]
        parentNode.appendChild(script)

        return () => {
            delete (window as any).onTurnstileScriptLoaded
            parentNode.removeChild(script)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div ref={ containerRef }></div>
    )
}
