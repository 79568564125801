import { AccesRapide } from "api/referencesAPI"
import Accesses from "components/AccesRapides"


export default function AccesRapidesPrestataire() {

    function filtrerParEligibles(accesRapides: AccesRapide[] | undefined) {
        return accesRapides
    }

    return (
        <Accesses  storageId="accesRapidesPrestataire" filtre={filtrerParEligibles} />
    )

}