import { getReferencesIdcc } from "api/referencesAPI";
import { createApiSlice } from 'api/createApiSlice'


export const referencesIdccSlice = createApiSlice({
    name: 'referencesIdcc/get',
    call: getReferencesIdcc,
    getCacheKey: (state: any) => state.contextState.app + '/refsIdcc',
    useCachedState: 'referencesIdccState',
})

export const referencesIdccActions = {
    load: () => referencesIdccSlice.actions.caller(null)
}
