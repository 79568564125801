import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
    document: {
        // fontFamily: 'Arial',
    },
    page: {
        fontFamily: 'Arial',
        fontSize: 11,
        paddingTop: 25,
        paddingBottom: 20,
    },
    cols: {
        display: 'flex',
        flexDirection: 'row',
    },
    colLeft: {
        width: '49%',
        marginRight: '1%',
    },
    colRight: {
        width: '49%',
        marginLeft: '1%',
    },
    tripleCol: {
        width: '29%',
        marginRight: '3%',
    },
    tripleColLast: {
        width: '28%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    ministryImage: {
        marginTop: -10,
        width: 120,
        marginLeft: 8,
    },
    titles: {
        width: '50%',
        textAlign: 'center',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 11.5,
    },
    subtitle: {
        fontSize: 8.5,
    },
    notice: {
        marginTop: 15,
        fontSize: 8.5,
        fontWeight: 'bold',
    },
    info: {
        marginTop: 3,
        fontSize: 9,
        fontWeight: 'light',
        fontStyle: 'italic',
    },
    cerfa: {
        width: 120,
        marginRight: 8,
        marginTop: 15,
        display: 'flex',
        alignItems: 'center',
    },
    cerfaImage: {
        width: 45,
    },
    cerfaReference: {
        fontSize: 7,
        fontWeight: 'bold',
        marginTop: 8,
    },
    mode: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    main: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    section: {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
    },
    sectionHeader: {
        backgroundColor: '#D9D9D9',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 2,
    },
    sectionHeaderV4: {
        backgroundColor: '#D9D9D9',
        borderBottom: '1px solid black',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 2,
    },
    sectionSubheader: {
        fontSize: 9,
        fontStyle: 'italic'
    },
    sectionContent: {
        padding: 5,
        lineHeight: 1.3,
    },
    employeurHeader: {
        padding: 3,
    },
    employeurFooter: {
        marginTop: 5,
        fontSize: 9,
    },
    pageLastSection: {
        borderBottom: '1px solid black',
    },
    signatureSection: {
        paddingBottom: 20,
    },
    footer: {
        textAlign: 'center',
        border: '1px solid black',
    },
    bordered: {
        border: '1px solid black',
        padding: 2,
        marginBottom: 4,
    },
    center: {
        textAlign: 'center',
    },
    sup: {
        fontSize: 8,
    },
    xSmall: {
        fontSize: 9,
    },
    small: {
        fontSize: 10,
    },
    large: {
        fontSize: 12,
    },
    compact: {
        lineHeight: 1.2,
    },
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
    boldItalic: {
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    user: {
        fontFamily: 'Inconsolata',
    },
});
