import { Form, UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { AtlasColumns, AtlasHeading, AtlasInfo, MyForm } from 'atlas-ds'
import PageHeader from "components/PageHeader"
import Button from 'components/reform/Button'
import log from 'loglevel'
import { convert, revert } from 'pages/dossier/metadata/DossierMapping'
import { useYopSchema } from 'pages/dossier/metadata/FormMetadata'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DossierWithSalaries } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'
import { DossierSalariesElements } from './elements/DossierSalariesElements'
import { BaseDossierComponentProps, DossierIdParams, useDossier } from './useDossier'
import { useDossierNavigation } from './useDossierNavigation'
import { useIntegerQueryParam } from './useQueryParam'

export function DossierSalariesEditForm<D extends DossierWithSalaries>(props: BaseDossierComponentProps<D>) {
    
    const dispatch = useAppDispatch() 
    const { dossierId } = useParams<DossierIdParams>()
    const salarieId = useIntegerQueryParam("id")
    
    const { etape, step, navigateTo } = useDossierNavigation(props.metadata)
    const { loading, saving, dossier: initialValues, error } = useDossier<D>(
        props.metadata.api,
        dossierId,
    )

    const index = Math.max(0, 
        salarieId == null ?
        initialValues?.salaries?.length ?? 0 :
        initialValues?.salaries?.findIndex(salarie => salarie.IdHeroku === salarieId) ?? 0
    )

    const validationSchema = useYopSchema(props.metadata, step)?.focusOn(`salaries[${ index }]`)

    const initialValuesConverter = (dossier: D) => {
        if (salarieId == null)
            dossier.salaries.push(props.metadata.api.createSalarie())
        return convert(dossier, props.metadata)
    }
    
    const form = useForm<D>({
        initialValues,
        initialValuesConverter,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<D>) => {
            const dossier = revert(context.values!, props.metadata!)
            dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier })).then(unwrapResult)
                .then(dossier => {
                    navigateTo(dossier.NumeroDossier__c!, etape)
                })
                .catch(error => {
                    log.error("Erreur dossier save salarie", error)
                    context.setSubmitting(false)
                })
        }
    })

    useEffect(() => {
        if (salarieId != null && initialValues != null && form.validateAt(`salaries[${ index }]`, false)) {
            const firstErrorKey = form.getErrorPaths()?.[0]
            const element = global.window.document.getElementById(firstErrorKey)
            if (element) {
                setTimeout(() => {
                    element.focus();
                    element.scrollIntoView({ block: 'center' });
                }, 250)
            }

            form.renderForm()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salarieId, initialValues])


    function onAnnuler () {
        navigateTo(dossierId!, etape)
    }

    return (
        <>
            <PageHeader>
                { `${ props.metadata.title } ${ dossierId ? `<br />${ dossierId }` : ''}` }
            </PageHeader>
            
            <AtlasColumns>
                <div>
                    <Form context={ form } autoComplete="off" noValidate disabled={ loading }>
                        <AtlasHeading tag="h2" size="m">{ salarieId == null ? "Ajouter un salarié" : "Modifier un salarié" }</AtlasHeading>
                        <MyForm pending={loading}>
                            { !loading && error && <AtlasInfo type="error" title="Erreur sur ce dossier">
                                { error }
                            </AtlasInfo> }

                            <DossierSalariesElements metadata={ props.metadata } step={ step } index={ index } />

                            { !loading && <MyForm.Actions>
                                <Button level={ 2 } onClick={ onAnnuler }>Annuler</Button>
                                <Button submit={ true } spinner={{spinning: saving}}>Enregistrer</Button>
                            </MyForm.Actions> }
                        </MyForm>
                    </Form>
                </div>
            </AtlasColumns>
        </>
    )
}
