import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { Facture } from 'api/dossierAPI'
import { getFactures } from 'api/factureAPI'
import { TYPE_FACTURE_FACTURE } from 'api/references'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'

interface FactureProps {
    name: string
    getIdFacture?: (id: string) => void

}

export default function FactureSelector(props: FactureProps ) {

    const form = useFormContext<any>()
    const fieldState = getFieldState<string | null>(form, props.name)

    async function searchFactures(recherche: string) {
        const page = await getFactures({
            page: 0,
            parPage: 10,
            page2: 0,
            parPage2: 0,
            tri: 'DateEmission__c',
            ordre: 'desc',
            recherche: recherche,
            etatFactureFo: [],
            typeFacture: TYPE_FACTURE_FACTURE
        })
        return page.items
    }

    const onSelect = (facture: Facture) => {
        form.setValue(props.name, facture.NumeroDossier__c, true)
        if (facture.Id)
            props.getIdFacture?.(facture.Id)
    }

    return (
        <AutocompleteBaseField<Facture>
            label="Facture N°"
            defaultValue={ fieldState.value ?? "" }
            placeholder="Rechercher une facture..."
            required={ fieldState.constraints.required }
            error={ fieldState.error }
            onBlur={ value => form.setValue(props.name, value === "" ? null : form.getValue(props.name), true) }
            
            search={ searchFactures }

            optionFor={ facture => ({
                id: facture.NumeroDossier__c,
                content: `${facture.RefFactureClient__c} ${facture.NumeroDossier__c ? `N° dossier : ${facture.NumeroDossier__c }` : ''}`
            })}
            onSelect={ onSelect }
        />
    )
}
