import { commonRoutes } from "app/App"
import { useAppContext } from "app/useAppContext"
import { AtlasHtml } from "atlas-ds"
import PageHeader from "components/PageHeader"
import { Link } from "react-router-dom"

export default function PlanDuSite() {
    const appContext = useAppContext()

    // Combine module routes and common routes
    const routeGroups = appContext.appInfo.modules.flatMap(moduleGroup =>
        moduleGroup.map(module =>
            appContext.resolvedRoutes.get(module.id) ?? []
        )
    ).concat(commonRoutes)

    return (
        <>
            <PageHeader>Plan du site</PageHeader>

            <AtlasHtml>
                <ul>
                    {routeGroups.flatMap(routeGroup => {
                        const [mainRoute, ...subRoutes] = routeGroup
                            .filter(route => !!route.props.label && !route.props.path.includes(':'))

                        return mainRoute && <li key={mainRoute.key}>
                            <Link to={mainRoute.props.path}>{mainRoute.props.label}</Link>

                            {subRoutes.length > 0 && <ul>
                                {subRoutes.map(route => <li key={route.key}>
                                    <Link to={route.props.path}>{route.props.label}</Link>
                                </li>)}
                            </ul>}
                        </li>
                    })}
                </ul>
            </AtlasHtml>
        </>
    );
}
