import { ETAT_VERSEMENT, EXTERNAL_ID_SOR_NULL, VersementDetail, getVersement } from 'api/versementAPI'
import { AtlasHeading, AtlasInfo, AtlasValue, AtlasValues, MyContainer } from 'atlas-ds'
import State from 'components/State'
import { formatApiDate, formatCurrency, formatNumber } from 'components/format/Format'
import { Location } from 'history'
import Detail from 'pages/detail-common/Detail'
import CalculVV from './CalculVV'
import DetailVersementDocuments from './DetailVersementDocuments'


export const detailVersementRefreshEvent = "DetailVersement:Refresh"

const TYPES_VERSEMENT = {
    '7': 'PRELEVEMENT',
    'PRELEVEMENT': 'PRELEVEMENT',
    '2': 'CHEQUE',
    'CHEQUE': 'CHEQUE',
    '3': 'VIREMENT',
    'VIREMENT': 'VIREMENT',
} as Record<string, string>

export default function DetailVersementVV() {

    const history = (location: Location<any>) => [
        { title: 'Versements', pathname: '/mes-versements', search: '' },
        { title: 'Versement sélectionné', pathname: location.pathname, search: location.search }
    ]

    const sections = (versement: VersementDetail | null, versementPending: boolean) => {

        let codeAdherent = versement?.Etablissement__c?.CodeAdherent__c ?? ''
        const iDot = codeAdherent.indexOf('.')
        if (iDot >= 0)
            codeAdherent = codeAdherent.substring(0, iDot)

        return [
            <div key="etat" id="etat">
                <AtlasHeading tag="h2" size="m">État du versement</AtlasHeading>
                {versement?.EtatVersement__c === "10"
                    ? <AtlasInfo type="error" title="Versement annulé">{ versement?.ErreurSlimpay__c ?? "Erreur inconnue" }</AtlasInfo>
                    : <MyContainer>
                    <AtlasValues>
                        <AtlasValue label="État">
                            <State type="versement" id={versement?.EtatVersement__c || ''}>
                                {versement?.ExternalID_Sor__c == null ? EXTERNAL_ID_SOR_NULL : (ETAT_VERSEMENT[versement?.EtatVersement__c ?? ''] ?? 'Inconnu')}
                            </State>
                        </AtlasValue>
                        <AtlasValue label="Créé le">
                            { formatApiDate(versement?.DatePaiement__c) }
                        </AtlasValue>
                    </AtlasValues>
                </MyContainer>}
            </div>,

            <div key="informations" id="informations">
                <AtlasHeading tag="h2" size="m">Informations</AtlasHeading>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <AtlasValue label="Raison sociale">
                                { versement?.Entreprise__c?.Name }
                                { versement?.Entreprise__c?.Name }
                            </AtlasValue>
                            <AtlasValue label="SIRET">
                                { versement?.Entreprise__c?.SIRET__c }
                                { versement?.Entreprise__c?.SIRET__c }
                            </AtlasValue>
                            <AtlasValue label="IDCC applicable">
                                { versement?.Etablissement__c?.CodeIDCC__c }
                                { versement?.Etablissement__c?.CodeIDCC__c }
                            </AtlasValue>
                            <AtlasValue label="Code Adherent">
                                { codeAdherent }
                            </AtlasValue>
                            <AtlasValue label="Adresse">
                                { versement?.Entreprise__c?.Adresse3__c }
                                { versement?.Entreprise__c?.Adresse3__c }
                            </AtlasValue>
                            <AtlasValue label="Code postal">
                                { versement?.Entreprise__c?.BillingPostalCode }
                                { versement?.Entreprise__c?.BillingPostalCode }
                            </AtlasValue>
                            <AtlasValue label="Commune">
                                { versement?.Entreprise__c?.BillingCity }
                                { versement?.Entreprise__c?.BillingCity }
                            </AtlasValue>
                            <AtlasValue label="Montant budget">
                                { formatCurrency(versement?.MontantBudgetVV__c, '', '-') }
                            </AtlasValue>
                            <AtlasValue label="Frais de gestion">
                                { formatCurrency(versement?.FraisDeGestion__c, '', '-') }
                            </AtlasValue>
                            <AtlasValue label="TVA">
                                { formatNumber(versement?.TauxTVAPartielle__c !== null ? versement?.TauxTVAPartielle__c : versement?.TauxTVAEnterprise__c, '%', '-', 2) }
                            </AtlasValue>
                            <AtlasValue label="Type de règlement">
                                { TYPES_VERSEMENT[versement?.MoyenPaiement__c ?? ''] }
                            </AtlasValue>
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </div>,

            <div id="calcul" key="calcul">
                <CalculVV
                    tauxTVALocal={versement?.TauxTVAEnterprise__c ?? null}
                    montant={versement?.MontantHT__c ?? null}
                    typeTVA={null}
                    tauxTVA={(versement?.TauxTVAPartielle__c !== null ? versement?.TauxTVAPartielle__c : versement?.TauxTVAEnterprise__c) ?? null}
                    montantHT={versement?.MontantHT__c}
                    montantTVA={versement?.MontantTVA__c}
                    montantTTC={versement?.MontantTTC__c}
                />
            </div>,

            <DetailVersementDocuments key="documents" versement={versement} versementPending={versementPending} />
        ]
    }

    return <Detail<VersementDetail> name="DetailVersement"
        paramName="idversement"
        title={(versement: VersementDetail | null) => 'Versement de contribution volontaire' + (versement?.ExternalID_Sor__c ? ' N°' + versement?.ExternalID_Sor__c : '')}
        getId={(versement: VersementDetail | null) => versement?.IdHeroku?.toString() ?? null}
        getDetail={getVersement}
        loadErrorMessage="Erreur lors de récupération du versement"
        history={history}
        nav={[
            { targetId: "etat", label: "État du versement" },
            { targetId: "informations", label: "Informations" },
            { targetId: "calcul", label: "Calcul de la contribution" },
            { targetId: "documents", label: "Documents" }
        ]}
        sections={sections}
    />
}
