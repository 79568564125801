import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'
import { CAPPFormationMetadata } from 'pages/dossier-capp/CAPPFormationMetadata'
import { DossierCAPPFormation, DossierCAPPStagiaire } from 'pages/dossier-capp/CAPPModel'
import { CAPPStagiaireMetadata } from 'pages/dossier-capp/CAPPStagiaireMetadata'
import CerfaAdresseField from 'pages/dossier/cerfa/CerfaAdresseField'
import CerfaBooleanField from 'pages/dossier/cerfa/CerfaBooleanField'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'


export default function Formation({ formation, stagiaire }: { formation: DossierCAPPFormation, stagiaire: DossierCAPPStagiaire | null }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>LA FORMATION</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>
              CFA d'entreprise :{'    '}
              <CerfaBooleanField value={formation?.cfa?.entreprise} spacing={8} />
            </Text>
            <Text>Dénomination du CFA responsable :</Text>
            <CerfaTextField value={formation?.cfa?.nom} />
            <Text>
              N° UAI du CFA :{' '}
              <CerfaTextField value={formation?.cfa?.numeroUAI} />
            </Text>
            <Text>
              N° SIRET du CFA :{' '}
              <CerfaTextField value={formation?.cfa?.siret} />
            </Text>
            <Text style={styles.bold}>Adresse du CFA responsable :</Text>
            <CerfaAdresseField value={formation?.cfa} />
            <Text>
              <Checkbox checked={formation?.adresseRealisationIdentiqueAgora}></Checkbox>
              <Text>Si le CFA responsable est le lieu de formation principal cochez la case ci-contre</Text>
            </Text>
            <Text>Visa du CFA (cachet et signature du directeur)</Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              Diplôme ou titre visé par l’apprenti :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.diplomeVise } value={ stagiaire?.diplomeVise } />
            </Text>
            <Text>
              Intitulé précis :{' '}
              <CerfaTextField value={ formation?.diplome?.intitulePrecis } />
            </Text>
            <Text>
              Code du diplôme :{' '}
              <CerfaTextField value={ formation?.diplome?.codeDiplome } />
            </Text>
            <Text>
              Code RNCP : <CerfaTextField value={ formation?.diplome?.codeRNCP } />
            </Text>
            <Text style={styles.bold}>
              Organisation de la formation en CFA :
            </Text>
            <Text>Date de début du cycle de formation :{' '}
              <CerfaElement metadata={ CAPPFormationMetadata.dateDebutFormation } value={ formation?.dateDebutFormation } />
            </Text>
            <Text>Date prévue de la fin des épreuves ou examens :{' '}
              <CerfaElement metadata={ CAPPFormationMetadata.dateFinFormation } value={ formation?.dateFinFormation } />
            </Text>
            <Text>
              Durée de la formation :{' '}
              <CerfaElement metadata={ CAPPFormationMetadata.dureeTotale } value={ formation?.dureeTotale } />
            </Text>

            <Text style={styles.bold}>Lieu principal de réalisation de la formation si différent du CFA responsable : </Text>
            <View>
              <Text>Dénomination du lieu de formation principal :</Text>
              <CerfaTextField value={ formation?.organismePrincipalFormationAgora?.nom } />
            </View>
            <Text>N° UAI :{' '}
              <CerfaTextField value={ formation?.organismePrincipalFormationAgora?.numeroUAI } />
              {'     '}
              N° SIRET :{' '}
              <CerfaTextField value={ formation?.organismePrincipalFormationAgora?.siret } />
            </Text>
            <Text style={styles.bold}>Adresse du lieu de formation principal :</Text>
            <CerfaAdresseField value={ formation?.organismePrincipalFormationAgora } />
          </View>
        </View>
      </View>
    </View>
  )
}
