import { getSecteurs } from 'api/diagatlasAPI'
import { createApiSlice } from 'api/createApiSlice'

export const secteursSlice = createApiSlice({
    name: 'diagatlas/secteurs/get',
    call: getSecteurs,
})

export const secteursActions = {
    load: () => secteursSlice.actions.caller(null),
}
