import {
  AtlasIcon,
  MyDropdownMenu,
  MyDropdownMenuProps,
  MyLogo,
  MyLogoProps,
} from "atlas-ds";

export interface MySiteInfoProps {
  /**
   * Un ou plusieurs liens d'information du site
   */
  links: React.ReactNode | React.ReactNode[];
  /**
   * Le logo de l'application (logo générique myAtlas si non spécifié)
   */
  logo?: React.ReactElement<MyLogoProps>;
  /**
   * Un menu déroulant
   */
  dropdownMenu?: React.ReactElement<MyDropdownMenuProps>;
}

/**
 * Les informations de présentation du site
 */
export function MySiteInfo(props: MySiteInfoProps) {
  return (
    <div className="my-siteInfo">
      <div className="my-siteInfo__logoAndDropdown">
        {props.logo ?? <MyLogo />}
        <div className="my-siteInfo__dropdown">
          {props.dropdownMenu && (
            <MyDropdownMenu
              {...props.dropdownMenu.props}
              opener={<AtlasIcon name="info" size="s" title="Liens externes" />}
            />
          )}
        </div>
      </div>

      <div className="my-siteInfo__links">{props.links}</div>
    </div>
  );
}
