import classNames from "classnames";

export interface MyThemeProps {
  /**
   * Le thème à utiliser
   */
  theme: "entreprise" | "prestataire";
  /**
   * Le contenu
   */
  children: React.ReactNode;
  /**
   * Appliquer le thème à l'ensemble du document.
   * Utile pour cibler aussi les éléments qui ne sont pas enfant de ce composant
   * (exemple des modales placées à la racine grâce aux portals)
   */
  applyToBody?: boolean;
}

/**
 * Un conteneur permettant d'appliquer un thème myAtlas.
 * Peut englober l'ensemble d'une page ou une partie seulement.
 */
export function MyTheme(props: MyThemeProps) {
  return (
    <div
      className={classNames("my-theme", `my-theme--${props.theme}`, {
        "my-theme--applyToBody": props.applyToBody,
      })}
    >
      {props.children}
    </div>
  );
}
