import { ReferenceIDCC } from 'api/referencesAPI'
import { MyCalculator } from 'atlas-ds'
import { formatCurrency, formatNumber } from 'components/format/Format'
import { useIdcc } from 'components/reform/inputs/utils/useIdcc'
import { useEffect, useState } from 'react'

const TVA_DEFAUT = 20.0

interface CalculTVAProps {
    anneeFiscale: number
    codeIDCC: string | null
    effectifTotal: number | null
    masseSalariale: number | null
    typeTVA: string | null
    tauxTVALocal: number | null
    tauxTVA: number | null
    tauxConventionnel?: number
    montantHT?: number
    montantTVA?: number
    montantTTC?: number
    calculTVA?: ({ montantHT, montantTVA, montantTTC }: { montantHT: number, montantTVA: number, montantTTC: number }) => void
    tauxConventionnelChange?: (taux: number) => void
}

function calculTauxConventionnel(effectifTotal: number, idcc?: ReferenceIDCC) {
    let tauxConventionnel = null
    if (idcc?.ContributionConventionnelle__c) {
        if (effectifTotal < 11 && idcc.TauxMoins11Salaries__c !== null) {
            tauxConventionnel = idcc.TauxMoins11Salaries__c!
        } else if (effectifTotal >= 11 && effectifTotal <= 19 && idcc.Taux11a19Salaries__c !== null) {
            tauxConventionnel = idcc.Taux11a19Salaries__c!
        } else if (effectifTotal >= 20 && effectifTotal <= 49 && idcc.Taux20a49Salaries__c !== null) {
            tauxConventionnel = idcc.Taux20a49Salaries__c!
        } else if (effectifTotal >= 50 && effectifTotal <= 299 && idcc.Taux50a299Salaries__c !== null) {
            tauxConventionnel = idcc.Taux50a299Salaries__c!
        } else if (effectifTotal >= 300 && idcc.Taux300EtPlusSalaries__c !== null) {
            tauxConventionnel = idcc.Taux300EtPlusSalaries__c!
        }
    }
    return tauxConventionnel
}

interface TVAState {
    tauxTVA: number | null
    tauxConventionnel: number | null
    montantHT: number | null
    montantTVA: number | null
    montantTTC: number | null
}

function calculMontants(anneeFiscale: number, idcc: ReferenceIDCC, tauxTVALocal: number, typeTVA: string | null, tauxTVA: number | null, effectifTotal: number, masseSalariale: number | null): TVAState {
    const tva = {
        tauxConventionnel: calculTauxConventionnel(effectifTotal!, idcc)
    } as TVAState

    // Recalcule en local si non fourni
    if (!tva.tauxConventionnel) {
        // Rien à payer, montants à 0
        tva.tauxConventionnel = 0
        
        tva.tauxTVA = tauxTVALocal
        tva.montantHT = 0
        tva.montantTVA = 0
        tva.montantTTC = 0
    }
    else if (masseSalariale == null) {
        // Pas de masse salariale => aucun calcul pour le moment
        tva.tauxTVA = null
        tva.montantHT = null
        tva.montantTVA = null
        tva.montantTTC = null
    }
    else {
        tva.tauxTVA = getTauxTVA(tauxTVALocal, tauxTVA, typeTVA)
        let montantBase = Math.round(masseSalariale * tva.tauxConventionnel) / 100.0

        if (masseSalariale > 0 && anneeFiscale >= 2023 && idcc.Id === "1486" && montantBase < 25)
            montantBase = 25

        tva.montantHT = montantBase
        if (tva.tauxTVA === null) {
            // Non assujetti : TVA = x% mais TTC = HT
            // const montantTTCTheorique = montantBase + Math.round(montantBase * (tauxTVALocal ?? TVA_DEFAUT)) / 100.0
            tva.montantTTC = tva.montantHT
            tva.montantTVA = 0
        } else {
            // Assujetti : TVA = x%, TTC = HT + TVA
            tva.montantTTC = montantBase + Math.round(montantBase * (tva.tauxTVA ?? tauxTVALocal ?? TVA_DEFAUT)) / 100.0
            tva.montantTVA = tva.montantTTC - tva.montantHT
        }
    }
    return tva
}

const getTauxTVA = (tauxTVALocal: number | null, tauxTVA: number | null, typeTVA: string | null) => {
    if (typeTVA === 'totale') {
        return tauxTVALocal
    } else if (typeTVA === 'aucune') {
        return null
    }
    return tauxTVA
}

export default function CalculTVA(props: CalculTVAProps) {

    const refsIdcc = useIdcc()

    const [tva, setTVA] = useState<TVAState>({ 
        tauxConventionnel: props.tauxConventionnel ?? null, 
        tauxTVA: props.tauxTVA ?? null,
        montantHT: props.montantHT ?? null, 
        montantTVA: props.montantTVA ?? null, 
        montantTTC: props.montantTTC ?? null 
    })

    const { 
        codeIDCC, 
        effectifTotal, masseSalariale, 
        tauxConventionnel, 
        tauxTVALocal, typeTVA, tauxTVA, 
        montantHT, montantTVA, montantTTC, 
        tauxConventionnelChange, calculTVA,
    } = props

    useEffect(() => {
        if (!props.tauxConventionnelChange) {
            setTVA({
                tauxConventionnel: props.tauxConventionnel ?? null, 
                tauxTVA: props.tauxTVA ?? null,
                montantHT: props.montantHT ?? null, 
                montantTVA: props.montantTVA ?? null, 
                montantTTC: props.montantTTC ?? null 
            })
            return
        }
        if (effectifTotal === null) {
            return
        }
    
        const idcc = refsIdcc?.find(r => r.Id === codeIDCC)

        let tvaCalculee = tva
        if (!tauxConventionnel && idcc) {
            tvaCalculee = calculMontants(props.anneeFiscale, idcc, tauxTVALocal ?? TVA_DEFAUT, typeTVA, tauxTVA, effectifTotal, masseSalariale)
            setTVA(tvaCalculee)
            if (tauxConventionnelChange)
                tauxConventionnelChange(tva.tauxConventionnel!)
        }

        if (calculTVA && tvaCalculee.montantHT != null && tvaCalculee.montantTVA != null && tvaCalculee.montantTTC != null) {
            calculTVA({ 
                montantHT: tvaCalculee.montantHT, 
                montantTVA: tvaCalculee.montantTVA, 
                montantTTC: tvaCalculee.montantTTC,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refsIdcc, codeIDCC, effectifTotal, masseSalariale, tauxConventionnel, tauxTVALocal, 
        typeTVA, tauxTVA, montantHT, montantTVA, montantTTC, tauxConventionnelChange, calculTVA])

    return <MyCalculator
        label="Calcul de la contribution conventionnelle"
        columns={[
            {
                "values": [
                    {
                        "label": "Taux conventionnel",
                        "value": `${formatNumber(tva.tauxConventionnel, '%', '', 3)}`,
                    }
                ]
            },
            {
                "values": [
                    {
                        "label": "Montant HT",
                        "value": formatCurrency(tva.montantHT, '', '-', false),
                    },
                    {
                        "label": `TVA${ props.typeTVA === 'aucune' ? '' : ' ' + formatNumber(tva.tauxTVA ?? props.tauxTVALocal ?? TVA_DEFAUT, '%', '', 2) }`,
                        "value": formatCurrency(tva.montantTVA, '', '-', false),
                    },
                    {
                        "label": "Montant TTC",
                        "value": formatCurrency(tva.montantTTC, '', '-', false),
                        "outputFor": props.tauxConventionnelChange
                            ? "masseSalariale typeTVA_totale typeTVA_partielle typeTVA_aucune tauxTVA"
                            : undefined
                    }
                ]
            }
        ]}
    />
}
