import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer'
import styles from './CerfaDpc.styles'
import styles2 from './Infos.styles'
import fonts from './fonts/fonts'
import logo from 'pages/dossier/cerfa/img/opco-atlas.png'
import arrow from 'pages/dossier/cerfa/img/arrow.png'

fonts.forEach((font) => Font.register(font))

export function Value({ value, row, cell }: { value: any, row?: boolean, cell?: boolean }) {
    const label = value.exportLabel ?? value.label
    return value.text ? (
        <Text style={styles.text}>{value.text}</Text>
    ) : cell ?
        <Text style={styles2.cell}>{value.value}</Text>
        : row ? (
            <>
                {label && <Text style={styles2.tableRowLabel}>{label}</Text>}
                <Text style={styles2.tableRowValue}>{value.value}</Text>
            </>
        )
            : (
                <Text>
                    {label && <Text>{label} : </Text>}
                    <Text style={styles.value}>{value.value}</Text>
                </Text>
            )
}

export function BlockValue({ value }: { value: any }) {
    if (value.cell) {
        return <View style={styles2.cell}>
            {value.values.map((value: any, index: number) => (
                <Value key={index} value={value}></Value>
            ))}
        </View>
    }
    return <>
        <Text style={styles.subStepTitle}>
            <Image source={arrow} style={styles.arrow}></Image>{' '}
            {value.title}
        </Text>
        {value.values.map((value: any, index: number) => (
            <View key={index}>
                <Value value={value}></Value>
            </View>
        ))}
        <Text>&nbsp;</Text>
    </>
}

export async function createCerfaDpc(data: any) {
    return (
        <Document>
            <Page style={styles.page}>
                <Image source={logo} style={styles.logo}></Image>

                <View style={styles.header}>
                    <Text style={styles.title}>{data.nomDispositif}</Text>
                    <Text style={styles.subtitle}>{data.actionDispositif}</Text>
                </View>

                {data.steps.map((step: any, index: number) => (
                    <View key={index}>
                        <Text style={styles.stepTitle}>
                            {index + 1}. {step.title}
                        </Text>
                        {step.values.map((value: any, index: number) => (
                            <View key={index}>{
                                value.hasOwnProperty('props') ? value : (
                                    value.title ?
                                    <BlockValue value={value}></BlockValue> :
                                    <Value value={value}></Value>
                                )
                            }</View>
                        ))}
                    </View>
                ))}
            </Page>
        </Document>
    )
}
