
import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasFieldPeriodPreset, MyTags } from 'atlas-ds'
import { formatApiDate } from 'components/format/Format'
import { isEqual } from 'date-fns'
import { ValueType } from './inputs/DropdownDateRangePicker'

interface SelectedPeriodProps {
    label: string
    name: string
    ranges: AtlasFieldPeriodPreset[]
}

export default function SelectedPeriod(props: SelectedPeriodProps) {

    const context = useFormContext()
    const fieldState = getFieldState<ValueType>(context, props.name)
    
    const selectedValues = (fieldState.value ?? [null, null]) as Date[]

    const range = props.ranges.find(r => {
        const rangeDates = r.values as Date[]
        return isEqual(selectedValues[0], rangeDates[0]) && isEqual(selectedValues[1], rangeDates[1])
    })?.label 
        ?? 'du ' + formatApiDate(selectedValues[0]) + ' au ' + formatApiDate(selectedValues[1])

    return <MyTags.Item label={ props.label }>{ range }</MyTags.Item>
}
