import {
  AtlasButton,
  AtlasButtonProps,
  AtlasDetails,
  AtlasIcon,
  AtlasIconButton,
  AtlasLink,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler } from "react";

export type MyActionCategory = "Formation" | "Recrutement" | "Pratiques RH";

export interface MyActionTag {
  /**
   * Le label du tag
   */
  label: string;
  /**
   * La catégorie du tag
   */
  category?: MyActionCategory;
}

export interface MyActionProps {
  /**
   * Le label de l'action
   */
  children: string;
  /**
   * L'URL associée à la carte (via le bouton "En savoir plus")
   */
  infoHref?: string;
  /**
   * Un bouton d'action qui sera révélé au survol. L'action associé au bouton
   * sera exécutée après une animation de sortie de la carte.
   */
  button?: React.ReactElement<AtlasButtonProps>;
  /**
   * Une action de suppression de l'action. Un bouton de suppression sera
   * présenté si cette action est définie.
   */
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Une liste de boutons d'actions présentés dans un menu
   */
  menuActions?: {
    onClick: () => void;
    label: React.ReactNode;
    ariaLabel?: string;
    iconName?: string;
  }[];
  /**
   * Un ou plusieurs tag associé à la carte.
   */
  tags?: MyActionTag[];
  /**
   * Afficher un indicateur de nouveauté pour cette action
   */
  isNew?: boolean;
  /**
   * Passer ce booléen à true déclanchera l'animation de sortie de la carte
   */
  isLeaving?: boolean;
}

/**
 * Une carte d'action
 */
export function MyAction(props: MyActionProps) {
  return (
    <article
      className={classNames("my-action", {
        "my-action--isLeaving": props.isLeaving,
      })}
      {...{
        inert: props.isLeaving ? "" : undefined,
      }} /* inert not supported by React, bypass it */
    >
      <div className="my-action__inner">
        <div className="my-action__content">
          {props.tags && (
            <div className="my-action__tags">
              {props.tags.map((tag) => (
                <div
                  key={tag.label}
                  className="my-action__tag"
                  style={
                    tag.category
                      ? ({
                          "--my-action-tag-background": `${MyAction.getColor(
                            tag.category
                          )}`,
                        } as React.CSSProperties)
                      : undefined
                  }
                >
                  {tag.label}
                </div>
              ))}
            </div>
          )}
          <p className="my-action__label">{props.children}</p>
          {props.infoHref && (
            <AtlasLink
              external
              href={props.infoHref}
              level={3}
              ariaLabel={`En savoir plus sur ${props.children}`}
              icon="arrow-right"
            >
              En savoir plus
            </AtlasLink>
          )}
          {props.button && (
            <div className="my-action__button">
              <AtlasButton level={2} {...props.button.props} />
            </div>
          )}
          {props.onDelete && (
            <div className="my-action__delete">
              <AtlasIconButton
                onClick={props.onDelete}
                ariaLabel={`Supprimer l'action ${props.children}`}
              >
                <AtlasIcon name="close" size="xs" />
              </AtlasIconButton>
            </div>
          )}
          {props.menuActions && (
            <div className="my-action__menu">
              <AtlasDetails>
                <summary className="my-action__menuOpener">
                  <AtlasIcon
                    size="xs"
                    name="more"
                    title={`Actions pour ${props.children}`}
                  />
                </summary>
                <ul className="my-action__options">
                  {props.menuActions.map((action, index) => (
                    <li key={`action-${index}`}>
                      <button
                        type="button"
                        onClick={action.onClick}
                        aria-label={action.ariaLabel}
                      >
                        {action.iconName && (
                          <AtlasIcon size="xs" name={action.iconName} />
                        )}
                        {action.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </AtlasDetails>
            </div>
          )}
        </div>
        {props.isNew && (
          <div className="my-action__new">
            <div className="my-action__newInner">Nouveau</div>
          </div>
        )}
      </div>
    </article>
  );
}

/**
 * Obtenir la variable de couleur associée à une catégorie
 */
MyAction.getColor = (category: MyActionCategory): string => {
  return {
    Formation: "var(--my-color-action-formation)",
    Recrutement: "var(--my-color-action-recrutement)",
    "Pratiques RH": "var(--my-color-action-pratique-rh)",
  }[category];
};
