import { debounce } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

export default function useDebounce<T>(defaultValue: T, delay: number = 750): [T, (v: T) => void, number] {
    const [value, setValueImmediately] = useState(defaultValue)
    const [debounced, setDebounced] = useState(Date.now())

    const setValue = useCallback((value: any) => {
        setValueImmediately(value)
        triggerUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useMemo instead of useCallback, see: https://github.com/facebook/react/issues/19240
    const triggerUpdate = useMemo(() => debounce(() => {
        setDebounced(Date.now())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, delay), [])

    return [value, setValue, debounced]
}