import { getDiagnosticDocuments } from 'api/diagatlasAPI'
import { createApiSlice } from 'api/createApiSlice'

export const diagnosticDocumentsSlice = createApiSlice({
    name: 'diagatlas/diagnosticDocuments/get',
    call: getDiagnosticDocuments,
})

export const diagnosticDocumentsActions = {
    load: () => diagnosticDocumentsSlice.actions.caller(null),
    clear: () => diagnosticDocumentsSlice.actions.clear(),
}
