import { DossierAFDTO, DossierCoreDTO } from 'api/dossierAPI'
import { normalizeAPIDate } from 'components/format/Format'
import { cloneDeep, get, set } from 'lodash'
import { BaseDossier, BaseDossierModule, BaseDossierStagiaire, DossierAF, nil, normalizePiecesDTO } from 'slices/dossierCommon'
import { mapDTOToEmployeur, mapEmployeurToDTO } from './EmployeurMetadata'
import { dateReverter } from '../../../components/metadata/DateFieldMetadata'
import { HolderFieldMetadata } from '../../../components/metadata/HolderFieldMetadata'
import { FieldMetadata, FormMetadata, ObjectMetadata } from './FormMetadata'
import { mapDTOToModule, mapModuleToDTO } from './ModulesMetadata'
import { mapDTOToStagiaire, mapStagiaireToDTO } from './SalariesMetadata'


export interface FieldMapping<T> {
    source?: string
    mapModelToDTO: (value: T, dto: any, index?: number) => void
    mapDTOToModel: (dto: any, context: MappingContext, index?: number) => any
}

export function simpleMapping<T extends string | number | boolean | null>(path: string, source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any) => set(dto, path, value),
        mapDTOToModel: (dto: any) => get(dto, path) ?? (nil as T),
    }
}

export function stringToNumberMapping<T extends string | null>(path: string, source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any) => set(dto, path, value ? parseInt(value) : null),
        mapDTOToModel: (dto: any) => {
            const value = get(dto, path)
            if (value != null)
                return value.toFixed(0)
            return nil as string
        },
    }
}

export function booleanToDoubleMapping<T extends boolean | null>(path: string, source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any) => set(dto, path, value === true ? 1 : 0),
        mapDTOToModel: (dto: any) => {
            const value = get(dto, path)
            if (value != null)
                return value > 0.1
            return nil as boolean
        },
    }
}

export function nonNullBooleanMapping<T extends boolean | null>(path: string, source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any) => set(dto, path, value === true),
        mapDTOToModel: (dto: any) => {
            const value = get(dto, path)
            return value === true
        },
    }
}

export function indexedMapping<T extends string | number | boolean | null>(path: string[], source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any, index?: number) => set(dto, path[index ?? 0], value),
        mapDTOToModel: (dto: any, context: MappingContext, index?: number) => get(dto, path[index ?? 0]) ?? (nil as T),
    }
}

export function dateMapping<T extends Date | null>(path: string, source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any) => set(dto, path, value),
        mapDTOToModel: (dto: any) => normalizeAPIDate(get(dto, path)) ?? (nil as T)
    }
}

export function indexedDateMapping<T extends Date | null>(path: string[], source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any, index?: number) => set(dto, path[index ?? 0], value),
        mapDTOToModel: (dto: any, context: MappingContext, index?: number) => normalizeAPIDate(get(dto, path[index ?? 0])) ?? (nil as T)
    }
}

export function convert<D extends BaseDossier>(object: any, metadata: FormMetadata<D>) {
    const fields = Object.keys(metadata.fields)
    fields.forEach(field => {
        const fieldMetadata = (metadata.fields as any)[field] as FieldMetadata<any>
        if (fieldMetadata.hasOwnProperty('type')) {
            const holderMetadata = fieldMetadata as HolderFieldMetadata<any>
            const fieldsMetadata = holderMetadata.fields as { [x: string]: FieldMetadata<any> }

            if (holderMetadata.type === 'array') {
                const array = (object as any)[field]
                if (array != null) {
                    array.filter((e: any) => e != null).forEach((element: any) => {
                        for (const [key, fmd] of Object.entries(fieldsMetadata)) {
                            if (fmd && fmd.convert) {
                                element[key] = fmd.convert(element[key])
                            }
                        }
                    })    
                }
            } else {
                const element = object[field]
                if (element != null) {
                    for (const [key, fmd] of Object.entries(fieldsMetadata)) {
                        if (fmd && fmd.convert) {
                            element[key] = fmd.convert(element[key])
                        }
                    }
                }
            }
        } else if (fieldMetadata.convert) {
            object[field] = fieldMetadata.convert(object[field])
        }
    })
    return object
}

export function revert<D extends BaseDossier>(object: D, metadata: FormMetadata<D>, clone = true) {
    if (clone)
        object = cloneDeep(object)
    const fields = Object.keys(metadata.fields)
    fields.forEach(field => {
        const fieldMetadata = (metadata.fields as any)[field] as FieldMetadata<any>
        if (fieldMetadata.hasOwnProperty('type')) {
            const holderMetadata = fieldMetadata as HolderFieldMetadata<D, any>
            const fieldsMetadata = holderMetadata.fields as { [x: string]: FieldMetadata<any> }

            if (holderMetadata.type === 'array') {
                const array = (object as any)[field]
                if (array != null) {
                    array.filter((e: any) => e != null).forEach((element: any) => {
                        for (const [key, fmd] of Object.entries(fieldsMetadata)) {
                            if (fmd && fmd.revert) {
                                element[key] = fmd.revert(element[key])
                            }
                        }
                    })    
                }
            } else {
                const element = (object as any)[field]
                if (element != null) {
                    for (const [key, fmd] of Object.entries(fieldsMetadata)) {
                        if (fmd && fmd.revert) {
                            element[key] = fmd.revert(element[key])
                        }
                    }
                }
            }
        } else if (fieldMetadata.revert) {
            (object as any)[field] = fieldMetadata.revert((object as any)[field])
        }
    })
    if (object.signature) {
        object.signature.date = dateReverter(object.signature.date)
    }
    return object
}

export function mapDTOToModel<T extends object, DTO extends object>(
    context: MappingContext,
    modelMetadata: ObjectMetadata<T>,
    model: T,
    dto: DTO,
    source?: string,
    index?: number,
) {
    const metadata = modelMetadata as { [key: string]: FieldMetadata<any> }
    for (const [key, fmd] of Object.entries(metadata)) {
        if (fmd.mapping && ((!source && !fmd.mapping.source) || fmd.mapping.source === source)) {
            (model as any)[key] = fmd.mapping.mapDTOToModel(dto, context, index)
        }
    }
}

export function mapDTOSimpleFieldsToModel<T extends object, DTO extends object>(
    context: MappingContext,
    modelMetadata: ObjectMetadata<T>,
    model: T,
    dto: DTO,
) {
    const metadata = modelMetadata as { [key: string]: FieldMetadata<any> }
    for (const [key, fmd] of Object.entries(metadata)) {
        if (fmd.mapping && !fmd.hasOwnProperty('fields')) {
            (model as any)[key] = fmd.mapping.mapDTOToModel(dto, context)
        }
    }
}

export type MappingContext<D = any, DTO = any> = {
    metadata: ObjectMetadata<D>,
    editMode: boolean,
    dossier: D,
    dossierDTO: DTO,
}

export function mapModelToDTO<T extends object>(
    modelMetadata: ObjectMetadata<T>, 
    root: any,
    model: T | null,
    dto = {},
    source?: string,
    index?: number): any {

    if (model) {
        const metadata = modelMetadata as { [key: string]: FieldMetadata<any> }
        for (const [key, fmd] of Object.entries(metadata)) {
            if (fmd.mapping && ((!source && !fmd.mapping.source) || fmd.mapping.source === source)) {
                const modelValue = fmd.mappedValue 
                    ? fmd.mappedValue({
                        root,
                        parent: model,
                        value: (model as any)[key],
                    })
                    : (model as any)[key]
                fmd.mapping.mapModelToDTO(modelValue, dto, index)
            }
        }
    }
    return dto
}

export function mapSimpleFieldsToDTO<T extends object>(
    modelMetadata: ObjectMetadata<T>, 
    root: any,
    model: T | null,
    dto = {}): any {

    if (model) {
        const metadata = modelMetadata as { [key: string]: FieldMetadata<any> }
        for (const [key, fmd] of Object.entries(metadata)) {
            if (fmd.mapping && !fmd.hasOwnProperty('fields')) {
                const modelValue = fmd.mappedValue 
                    ? fmd.mappedValue({
                        root,
                        parent: model,
                        value: (model as any)[key],
                    })
                    : (model as any)[key]
                fmd.mapping.mapModelToDTO(modelValue, dto)
            }
        }
    }
    return dto
}

export function mapCoreDTOToBaseDossier(dossier: BaseDossier, dossierDTO: DossierCoreDTO) {
    dossier.Id = dossierDTO.Id ?? (nil as string)
    dossier.IdHeroku = dossierDTO.IdHeroku ?? (nil as number)
    dossier.NumeroDossier__c = dossierDTO.NumeroDossier__c ?? (nil as string)
    dossier.DispositifFO__c = dossierDTO.DispositifFO__c ?? (nil as string)
    dossier.EtatDossierFO__c = dossierDTO.EtatDossierFO__c ?? (nil as string)
    dossier.traitementEnCours = dossierDTO.EnCoursTransmissionSOR__c ?? false
}

export function mapDTOToDossierAF<S extends BaseDossierStagiaire, M extends BaseDossierModule, D extends DossierAF<S, M>>(
    formMetadata: FormMetadata<D>,
    dossierDTO: DossierAFDTO
): D {

    const dossier = formMetadata.api.create()
    mapCoreDTOToBaseDossier(dossier, dossierDTO)

    const context: MappingContext<D, DossierAFDTO> = {
        metadata: formMetadata.fields,
        editMode: formMetadata.api.isEdit ?? false,
        dossier,
        dossierDTO,
    }

    // Employeur
    mapDTOToEmployeur(context, (formMetadata.fields.employeur as any).fields, dossier.employeur, dossierDTO)

    // Salaries
    dossier.salaries = dossierDTO.Stagiaires?.map(salarieDTO => {
        const salarie = formMetadata.api.createSalarie() as S
        mapDTOToStagiaire(context, (formMetadata.fields.salaries as any).fields, salarie, salarieDTO)
        return salarie
    }) ?? []

    // Modules
    dossier.modules = dossierDTO.Modules?.map(moduleDTO => {
        const module = formMetadata.api.createModule!() as M
        mapDTOToModule(context, (formMetadata.fields.modules as any).fields, module, moduleDTO)
        return module
    }) ?? []
    if (dossier.modules.length > 0) {
        mapDTOToModel(context, (formMetadata.fields.modules as any).fields, dossier.modules[0], dossierDTO, 'dossier')
    }

    // Signature
    mapDTOToModel(context, (formMetadata.fields.signature as any).fields, dossier.signature, dossierDTO)

    dossier.pieces = normalizePiecesDTO(dossierDTO.Pieces)

    return dossier
}

export function mapDossierAFToDTO<S extends BaseDossierStagiaire, M extends BaseDossierModule, D extends DossierAF<S, M>>(formMetadata: FormMetadata<D>, dossier: D): DossierAFDTO {
    const mainModule = dossier.modules?.[0]
    const dto = {
        IdHeroku: dossier.IdHeroku ?? null,
        NumeroDossier__c: dossier.NumeroDossier__c ?? null,
        TypeDossier__c: null,   // Non utilisé
        DispositifFO__c: dossier.DispositifFO__c ?? null,

        Intitule__c: mainModule?.intitulePrecis ?? null,

        // Employeur
        ...mapEmployeurToDTO((formMetadata.fields.employeur as any).fields, dossier, dossier.employeur),

        // Salaries
        Stagiaires: dossier.salaries
            ?.map(s => mapStagiaireToDTO((formMetadata.fields.salaries as any).fields, dossier, s))
            ?.filter(s => s !== null) ?? null,

        // Modules
        Modules: dossier.modules
            ?.map((m, index) => mapModuleToDTO((formMetadata.fields.modules as any).fields, dossier, m, index))
            ?.filter(m => m !== null) ?? null,

        // Signature
        ...mapModelToDTO((formMetadata.fields.signature as any).fields, dossier, dossier.signature),
    } as DossierAFDTO

    if (mainModule) {
        mapModelToDTO((formMetadata.fields.modules as any).fields, dossier, mainModule, dto, 'dossier')
    }

    return dto
}
