import { SetValueOptions, UseFormReturn } from "@dsid-opcoatlas/reform"
import { Yop } from '@dsid-opcoatlas/yop'
import { DossierModuleDTO } from "api/dossierAPI"
import { TYPES_QUALIFICATIONS_RNCP, TYPE_COUT_AFEST, TYPE_QUALIFICATIONS_VAEI, TYPE_QUALIFICATIONS_VAEI_RNCP, TYPE_QUALIFICATION_RECONNUE } from "api/references"
import { References } from "api/referencesAPI"
import { formatDate } from "components/format/Format"
import { BooleanFieldMetadata, CheckboxFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { CFAFieldMetadata } from "components/metadata/CFAFieldMetadata"
import { SelectRefFieldMetadata } from "components/metadata/RefFieldMetadata"
import { TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import { Ignored, RequiredNumber } from "components/yop/constraints"
import { addMonths, isAfter, isBefore, isEqual } from "date-fns"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { MappingContext, simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ElementContext, IgnoredFieldMetadata, ObjectMetadata, override } from "pages/dossier/metadata/FormMetadata"
import { moduleAgoraFields, moduleFields, typeCoutMapping } from "pages/dossier/metadata/ModulesMetadata"
import { DossierCFA, TypeCout, createDossierCFA } from "slices/dossierCommon"
import { DossierAPI } from "slices/dossierSlice"
import { DossierVAEI, DossierVAEIModule, createDossierVAEIModule } from "./VAEIModel"


export const SECTION_MODULE_PRINCIPAL = "principal"
export const SECTION_MODULE_PRINCIPAL_AGORA = "principal_agora"
export const SECTION_MODULE_AFEST = "afest"
export const SECTION_MODULE_AFEST_AGORA = "afest_agora"

export const VAEIModulePrincipalMetadata: ObjectMetadata<DossierVAEIModule> = {
    formationInterne: override(moduleFields.formationInterne, {
        props: {
            tooltip: TOOLTIPS.formation.serviceFormationInterne,
            full: true
        },
        section: SECTION_MODULE_PRINCIPAL,
    }),
    attestationFormationInterne: override(moduleFields.attestationFormationInterne, {
        props: {
            label: "L'employeur atteste disposer des moyens nécessaires à la réalisation de la formation du salarié et joint l'annexe pédagogique complétée",
            full: true
        },
        section: SECTION_MODULE_PRINCIPAL,
        visibleIf: (context: ElementContext<DossierVAEIModule, DossierVAEI>) => (
            context.parent?.formationInterne === true
        ),
    }),
    formationAfest: IgnoredFieldMetadata(),
    attestationFormationAfest: IgnoredFieldMetadata(),
    cfa: CFAFieldMetadata({
        props: {
            label: 'Organisme de formation',
            da: true,
            adresseRequired: true,
            nomTooltip: TOOLTIPS.cpro.cfa.nom,
        },
        visibleIf: (context: ElementContext<DossierVAEIModule, DossierVAEI>) => context.parent?.formationInterne === false,
        section: SECTION_MODULE_PRINCIPAL,
        mapping: {
            mapModelToDTO: (value: DossierCFA | null, dto: DossierModuleDTO) => {
                dto.EtablissementOFId__c = (value?.EtablissementOFId__c ?? null) as string
                dto.CodeEtablissementOF__c = (value?.CodeEtablissementOF__c ?? null) as string
                dto.SiretOF__c = (value?.siret ?? null) as string
                dto.NomOF__c = (value?.nom ?? null) as string
                dto.UaiOF__c = (value?.numeroUAI ?? null) as string
                dto.NdaOF__c = (value?.numeroDA ?? null) as string
                dto.Adresse1OF__c = (value?.appartement ?? null) as string
                dto.Adresse2OF__c = (value?.batiment ?? null) as string
                dto.Adresse3OF__c = (value?.adresse ?? null) as string
                dto.Adresse4OF__c = (value?.complement ?? null) as string
                dto.CodePostalOF__c = (value?.codePostal ?? null) as string
                dto.CommuneOF__c = (value?.commune ?? null) as string
            },
            mapDTOToModel: (dto: DossierModuleDTO, context: MappingContext) => {
                if (dto.SiretOF__c == null)
                    return null
                const of = createDossierCFA()
                of.EtablissementOFId__c = dto.EtablissementOFId__c ?? null
                of.CodeEtablissementOF__c = dto.CodeEtablissementOF__c ?? null
                of.siret = dto.SiretOF__c ?? null
                of.nom = (dto.NomOF__c ?? null) as string
                of.numeroUAI = dto.UaiOF__c ?? null
                of.numeroDA = dto.NdaOF__c ?? null
                of.appartement = dto.Adresse1OF__c ?? null
                of.batiment = dto.Adresse2OF__c ?? null
                of.adresse = dto.Adresse3OF__c ?? null
                of.complement = dto.Adresse4OF__c ?? null
                of.codePostal = (dto.CodePostalOF__c ?? null) as string
                of.commune = (dto.CommuneOF__c ?? null) as string
                return of
            },
        },
    }),
    subrogation: override(moduleFields.subrogation, {
        section: SECTION_MODULE_PRINCIPAL,
        props: {
            full: true
        }
    }),
    intitulePrecis: override(moduleFields.intitulePrecis, {
        props: {
            label: "Intitulé formation précis"
        },
        section: SECTION_MODULE_PRINCIPAL,
    }).mutate(yop => yop.max(240)),
    typeQualification: override(moduleFields.typeQualification(TYPES_QUALIFICATIONS_RNCP), {
        props: {
            label: "Type qualification visée",
            options: (refs: References) => refs?.REF_TYPE_QUALIFICATION.filter(ref =>
                TYPE_QUALIFICATIONS_VAEI.includes(ref.Id) &&
                ref.Categorie__c?.split(',').includes('2') &&
                ref.IsActive__c && ref.IsActiveFO__c
            ) ?? [],
            onChange: (value: string | null, form: UseFormReturn<any>) => {
                if (value != null && !TYPE_QUALIFICATIONS_VAEI_RNCP.includes(value))
                    form.setValue('modulePrincipal.codeRNCP', null, SetValueOptions.Untouch | SetValueOptions.Validate)
            }
        },
        section: SECTION_MODULE_PRINCIPAL,
    }),
    numeroProjet: TextFieldMetadata({
        props: {
            label: 'Numéro de projet', 
            tooltip: "Ce numéro figure sur la décision d’acceptation du projet délivrée au porteur de projet par la DGEFP",
        },
        section: SECTION_MODULE_PRINCIPAL,
        mapping: simpleMapping('NumeroProjet__c', 'dossier'),
    }).required().mutate(yop => yop
        .max(11)
        .matches(/^\d*$/, "Doit contenir des chiffres uniquement")
    ),
    codeRNCP: override(moduleFields.codeRNCP(TYPE_QUALIFICATIONS_VAEI_RNCP), {
        props: {
            tooltip: TOOLTIPS.formation.codeRNCP,
        },
        section: SECTION_MODULE_PRINCIPAL,
    }),
    specialiteFormation: override(moduleFields.specialiteFormation, {
        props: {
            tooltip: TOOLTIPS.formation.diplomeSpecialite,
            alone: true
        },
        section: SECTION_MODULE_PRINCIPAL,
    }),
    dureeTotale: override(moduleFields.dureeTotale, {
        props: {
            label: "Durée totale des actions d'évaluation, d'accompagnement et des enseignements",
        },
        section: SECTION_MODULE_PRINCIPAL,
    }).mutate(yop => yop
        .max(9999.9)
    ),
    dureeEnseignement: override(moduleFields.dureeEnseignement, {
        section: SECTION_MODULE_PRINCIPAL,
    }).mutate(yop => yop
        .max(ctx => ctx.parent?.dureeTotale, "La durée des enseignements doit être inférieur ou égale à la durée totale des actions d'évaluation,  d'accompagnement et des enseignements")
    ),
    dateDebutFormation: override(moduleFields.dateDebutFormation, {
        props: {
            label: "Date de début du cycle de formation",
            tooltip: TOOLTIPS.cpro.formation.dateDebut,
        },
        section: SECTION_MODULE_PRINCIPAL,
    }).mutate(yop => yop
        .max<DossierVAEIModule>(context => context.parent!.dateFinFormation, "La date de début du cycle de formation est postérieure à la date de fin des épreuves")
        .test<DossierVAEIModule, DossierVAEI>(ctx => {
            const dateDebutFormation = ctx.value
            if (ctx.root?.contrat?.dateDebut != null) {
                const dateDebutContrat = ctx.root.contrat.dateDebut
                if (isBefore(dateDebutFormation, dateDebutContrat))
                    return ctx.createError(`La date de début doit être postérieure ou égale à la date de début du contrat (${formatDate(dateDebutContrat)})`)
                const dateDebutContrat2Months = addMonths(dateDebutContrat, 2)
                if (isAfter(dateDebutFormation, dateDebutContrat2Months))
                    return ctx.createError(`La date de début ne doit pas dépasser de 2 mois la date de début de contrat ${formatDate(dateDebutContrat2Months)}`)
            }
            return true
        })
    ),
    dateFinFormation: override(moduleFields.dateFinFormation, {
        props: {
            label: "Date prévue de fin des épreuves ou des examens",
            tooltip: TOOLTIPS.cpro.formation.dateFin,
        },
        section: SECTION_MODULE_PRINCIPAL,
    }).mutate(yop => yop
        .min<DossierVAEIModule>(context => context.parent!.dateDebutFormation, "La date de fin des épreuves est antérieure à la date de début du cycle de formation")
        .test<DossierVAEIModule, DossierVAEI>(ctx => {
            const dateFinFormation = ctx.value
            if (ctx.root?.contrat?.dateFin != null) {
                const dateFinContrat = ctx.root.contrat.dateFin
                const typeQualification = ctx.parent?.typeQualification
                if (typeQualification === TYPE_QUALIFICATION_RECONNUE && !isEqual(dateFinFormation, dateFinContrat))
                    return ctx.createError(`La date de fin de formation doit être égale à la date de fin de contrat (${formatDate(dateFinContrat)})`,)
                if (isAfter(dateFinFormation, dateFinContrat))
                    return ctx.createError(`La date de fin de formation ne doit pas être postérieure à la fin de contrat le ${formatDate(dateFinContrat)}`)
            }
            return true
        })
    ),
    coutPedagogique: override(moduleFields.coutPedagogique, {
        props: {
            label: "Coût pédagogique",
        },
        section: SECTION_MODULE_PRINCIPAL,
    }).mutate(_ => Yop.object<TypeCout>({ IdHeroku: Ignored, montant: RequiredNumber.min(0.01).max(300_000) }).required()),
    fraisAnnexesInternational: BooleanFieldMetadata({
        section: SECTION_MODULE_PRINCIPAL,
        props: {
            label: 'Mobilité internationale',
            tooltip: TOOLTIPS.capp.formation.fraisAnnexesInternational,
            onChange: (value, form) => {
                if (!value)
                    form.setValue('modulePrincipal.conditionRealisation', null, SetValueOptions.Untouch | SetValueOptions.Validate)
            },
        },
        mapping: simpleMapping('MobiliteInternationale__c'),
    }).required(),
    conditionRealisation: SelectRefFieldMetadata({
        section: SECTION_MODULE_PRINCIPAL,
        props: {
            label: 'Condition de réalisation',
            options: refs => refs?.REF_CONDITION_REALISATION.filter(ref => ref.IsActiveFO__c),
            sorted: true,
        },
        mapping: simpleMapping('ConditionRealisation__c'),
        visibleIf: (ctx: ElementContext<DossierVAEIModule, DossierVAEI>) => ctx.parent?.fraisAnnexesInternational === true,
    }).mutate(yop => yop.requiredIf(ctx => ctx.parent?.fraisAnnexesInternational === true)),
    modalitePedagogiqueAgora: override(moduleAgoraFields.modalitePedagogiqueAgora, {
        props: {
            full: true,
        },
        section: SECTION_MODULE_PRINCIPAL_AGORA,
    }),
    adresseRealisationIdentiqueAgora: override(moduleAgoraFields.adresseRealisationIdentiqueAgora, {
        props: {
            full: true,
        },
        section: SECTION_MODULE_PRINCIPAL_AGORA,
    }),
    lieuPrincipalFormationAgora: override(moduleAgoraFields.lieuPrincipalFormationAgora, {
        section: SECTION_MODULE_PRINCIPAL_AGORA,
    }),
    typeFormation: IgnoredFieldMetadata(),
    commentaire: IgnoredFieldMetadata(),
}

export function createDossierVAEIModuleAfest<D extends DossierVAEI>(api: DossierAPI<D>, dossier: D) {
    const module = api.createModule!() as DossierVAEIModule
    module.formationInterne = true
    module.subrogation = false
    module.specialiteFormation = dossier.modulePrincipal?.specialiteFormation ?? ''
    module.formationAfest = true
    module.coutPedagogique = { IdHeroku: null, montant: 500 }
    return module
}

export const VAEIModuleAFESTMetadata: ObjectMetadata<DossierVAEIModule> = {
    formationInterne: IgnoredFieldMetadata(),
    attestationFormationInterne: IgnoredFieldMetadata(),
    formationAfest: IgnoredFieldMetadata(simpleMapping("FormationAFEST__c")),
    attestationFormationAfest: CheckboxFieldMetadata({
        props: {
            label: "L'employeur atteste disposer des moyens nécessaires à la réalisation d'une action de formation en situation de travail (AFEST)",
            tooltip: TOOLTIPS.cpro.attestationFormationAfest,
            full: true
        },
        section: SECTION_MODULE_AFEST,
        mapping: simpleMapping('EmployeurAttesteAFEST__c', 'dossier'),
    }).mutate(yop => yop.required('Veuillez cocher cette case pour confirmer').oneOf([true], 'Veuillez cocher cette case pour confirmer')),
    cfa: IgnoredFieldMetadata(),
    subrogation: IgnoredFieldMetadata(simpleMapping('SubrogationPaiement__c')),
    intitulePrecis: override(moduleFields.intitulePrecis, {
        props: {
            label: "Intitulé du module AFEST",
            tooltip: undefined,
        },
        section: SECTION_MODULE_AFEST,
    }),
    typeQualification: IgnoredFieldMetadata(),
    numeroProjet: IgnoredFieldMetadata(),
    codeRNCP: IgnoredFieldMetadata(),
    specialiteFormation: IgnoredFieldMetadata(),
    dureeTotale: override(moduleFields.dureeTotale, {
        props: {
            label: "Total des heures AFEST",
        },
        section: SECTION_MODULE_AFEST,
    }).mutate(yop => yop
        .test<DossierVAEIModule, DossierVAEI>(ctx => {
            if (ctx.value <= 3) {
                return ctx.createError("La durée totale des heures AFEST doit être strictement supérieure à 3 heures")
            }
            return true
        })
        .max(9999.9)
    ),
    dureeEnseignement: IgnoredFieldMetadata(),
    dateDebutFormation: override(moduleFields.dateDebutFormation, {
        props: {
            label: "Date de début du module",
            tooltip: TOOLTIPS.cpro.formation.dateDebut,
        },
        section: SECTION_MODULE_AFEST,
    }).mutate(yop => yop
        .max<DossierVAEIModule>(context => context.parent!.dateFinFormation, "La date de début du module est postérieure à la date de fin du module")
        .test<DossierVAEIModule, DossierVAEI>(ctx => {
            const dateDebutFormation = ctx.value
            if (ctx.root?.contrat?.dateDebut != null) {
                const dateDebutContrat = ctx.root.contrat.dateDebut
                if (isBefore(dateDebutFormation, dateDebutContrat))
                    return ctx.createError(`La date de début doit être postérieure ou égale à la date de début du contrat (${formatDate(dateDebutContrat)})`)
            }
            return true
        })
    ),
    dateFinFormation: override(moduleFields.dateFinFormation, {
        props: {
            label: "Date de fin du module",
            tooltip: TOOLTIPS.cpro.formation.dateFin,
        },
        section: SECTION_MODULE_AFEST,
    }).mutate(yop => yop
        .min<DossierVAEIModule>(context => context.parent!.dateDebutFormation, "La date de fin du module est antérieure à la date de début du module")
        .test<DossierVAEIModule, DossierVAEI>(ctx => {
            const dateFinFormation = ctx.value
            if (ctx.root?.contrat?.dateFin != null) {
                const dateFinContrat = ctx.root.contrat.dateFin
                const typeQualification = ctx.parent?.typeQualification
                if (typeQualification === TYPE_QUALIFICATION_RECONNUE && !isEqual(dateFinFormation, dateFinContrat))
                    return ctx.createError(`La date de fin du module doit être égale à la date de fin de contrat (${formatDate(dateFinContrat)})`,)
                if (isAfter(dateFinFormation, dateFinContrat))
                    return ctx.createError(`La date de fin du module ne doit pas être postérieure à la fin de contrat le ${formatDate(dateFinContrat)}`)
            }
            return true
        })
    ),
    coutPedagogique: override(moduleFields.coutPedagogique, {
        props: {
            label: "Coût pédagogique",
            disabled: true,
        },
        section: SECTION_MODULE_AFEST,
        mapping: typeCoutMapping(TYPE_COUT_AFEST),
    }),
    modalitePedagogiqueAgora: override(moduleAgoraFields.modalitePedagogiqueAgora, {
        section: SECTION_MODULE_AFEST_AGORA,
    }),
    adresseRealisationIdentiqueAgora: IgnoredFieldMetadata(simpleMapping('AdresseRealisationIdentiqueAgora__c')),
    lieuPrincipalFormationAgora: override(moduleAgoraFields.lieuPrincipalFormationAgora, {
        section: SECTION_MODULE_AFEST_AGORA,
    }),
    fraisAnnexesInternational: IgnoredFieldMetadata(),
    conditionRealisation: IgnoredFieldMetadata(),
    typeFormation: IgnoredFieldMetadata(),
    commentaire: IgnoredFieldMetadata(),
}

export function dossierVAEIFormationInitializer<D extends DossierVAEI>(dossier: D) {
    if (dossier.modulePrincipal === null)
        dossier.modulePrincipal = createDossierVAEIModule()
    return dossier
}
