import { MyForm } from "atlas-ds"
import CodePostalCommuneField from "components/reform/inputs/CodePostalCommuneField"
import TextField from "components/reform/inputs/TextField"

interface AdresseFieldsProps {
    name: string
    label?: string
    disabled?: boolean
    adresseEnabled?: boolean
    communeEnabled?: boolean
}

export function AdresseFields(props: AdresseFieldsProps) {
    return <MyForm.Fieldset legend={ props.label ?? 'Adresse' }>
        <MyForm.Grid>
            <TextField name={ props.name + '.appartement' }
                label="Appartement"
                autoComplete="none"
                disabled={ props.disabled && !props.adresseEnabled } />

            <TextField name={ props.name + '.batiment' }
                label="Bâtiment"
                autoComplete="none"
                disabled={ props.disabled && !props.adresseEnabled } />

            <MyForm.Field long>
                <TextField name={ props.name + '.adresse' }
                    label="N° et voie"
                    disabled={ props.disabled && !props.adresseEnabled } />
            </MyForm.Field>

            <MyForm.Field short>
                <TextField name={ props.name + '.complement' }
                    label="Lieu dit / BP"
                    autoComplete="none"
                    disabled={ props.disabled && !props.adresseEnabled } />
            </MyForm.Field>

            <CodePostalCommuneField name={ props.name }
                label="Code postal et commune"
                disabled={ props.disabled && !props.communeEnabled } /> 
        </MyForm.Grid>
    </MyForm.Fieldset>
}
