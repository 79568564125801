import {
  AtlasButton,
  AtlasButtonProps,
  AtlasIcon,
  AtlasIconButton,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler, useEffect, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";

export interface MyModalProps {
  /**
   * Le nom de la modale
   */
  label: string;
  /**
   * Cette modale doit-elle être montrée ?
   * Elle le sera toujours si ce paramètre est omis.
   */
  show?: boolean;
  /**
   * Le contenu de la modale
   */
  children?: React.ReactNode;
  /**
   * Le bouton de validation de la modale
   */
  okButton?: React.ReactElement<AtlasButtonProps>;
  /**
   * Le bouton d'annulation de la modale
   */
  cancelButton?: React.ReactElement<AtlasButtonProps>;
  /**
   * L'action de fermeture de la modale. Si ce paramètre n'est pas fourni, la
   * modale devra être retirée par d'autres moyens ou sera permanente
   */
  onClose?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Obtenir une modale plus grande que la modale par défaut
   */
  large?: boolean;
  /**
   * Cette modale est-elle en train d'éxécuter une action ? Si oui, les boutons
   * seront adaptés.
   */
  isPending?: boolean;
  /**
   * Cette modale représente-t-elle un processus de chargement ? Si oui, elle
   * présentera notamment une animation de processus en cours.
   */
  isLoader?: boolean;
  /**
   * Cette modale représente-t-elle une erreur ? Si oui, le style sera adapté.
   */
  isError?: boolean;
  /**
   * Les éléments de la modale sont-ils autorisés à sortir du conteneur ?
   * Nécessaire dans le cas d'une liste déroulante dépassant du contenu,
   * par exemple. À réserver aux modales très courtes car elle ne sera plus
   * scrollable !
   */
  allowOverflow?: boolean;
  /**
   * Aligner les boutons à gauche.
   * Usage interne uniquement.
   */
  internalAlignActionToLeft?: boolean;
}

/**
 * Une modale.
 * Elle sera automatiquement placée en tant qu'enfant du body.
 */
export function MyModal(props: MyModalProps) {
  const ref = useRef<HTMLDialogElement>(null);
  const id = props.label.replace(/\W/g, "_");
  const show = props.show ?? true;

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (show && !ref.current?.open) {
        ref.current?.showModal();
      } else if (!show && ref.current?.open) {
        ref.current?.close();
      }
    });
  }, [show]);

  // If the modal is conditionnally rendered, call the close API before it is
  // removed from the DOM (otherwise the focus will be lost)
  useLayoutEffect(
    () => () => {
      if (ref.current?.open) {
        ref.current?.close();
      }
    },
    []
  );

  // Prevent modal closing via escape key.
  // This may be a bit brutal but this is to avoid regression with some modals
  // (previously using rsuite) that should not be closed, such as loading or
  // mandatory confirmation modals
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);

    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  });

  // createPortal is used to position the <dialog> at body level, preventing
  // DOM structure issues (such as form inside form)
  return createPortal(
    <dialog
      aria-describedby={id}
      ref={ref}
      className={classNames("my-modal", {
        "my-modal--large": props.large,
        "my-modal--error": props.isError,
        "my-modal--loader": props.isLoader,
        "my-modal--allowOverflow": props.allowOverflow,
        "my-modal--alignActionsToLeft": props.internalAlignActionToLeft,
      })}
    >
      <div className="my-modal__inner">
        {props.onClose && (
          <AtlasIconButton
            ariaLabel="Fermer"
            onClick={props.onClose}
            disabled={props.isPending}
          >
            <AtlasIcon name="close" size="s" />
          </AtlasIconButton>
        )}

        <div className="my-modal__title" id={id}>
          {props.label}
        </div>

        {props.children}

        {(props.okButton || props.cancelButton) && (
          <div className="my-modal__actions">
              {props.okButton && (
                <AtlasButton
                  spinner={{ spinning: !!props.isPending }}
                  {...props.okButton.props}
                />
              )}
              {props.cancelButton && (
                <AtlasButton
                  level={2}
                  disabled={props.isPending}
                  {...props.cancelButton.props}
                />
              )}
          </div>
        )}
      </div>
    </dialog>,
    document.body
  );
}

export interface MyModalActionsProps {
  /**
   * Les boutons d'action
   */
  children:
    | React.ReactElement<AtlasButtonProps>
    | React.ReactElement<AtlasButtonProps>[];
}
