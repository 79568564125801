import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { ReferenceIDCC } from 'api/referencesAPI'
import { TextFieldProps } from 'components/reform/inputs/TextField'
import { useIdcc } from 'components/reform/inputs/utils/useIdcc'
import { unaccentLowerCase } from './FieldUtil'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'

interface CodeIdccFieldProps<T extends object> extends TextFieldProps<T> {
    parent: any
    parentPath: string
    editMode?: boolean
}

export default function CodeIdccField<T extends object>(props: CodeIdccFieldProps<T>) {
    
    const refsIdcc = useIdcc()

    const form = useFormContext<T>()
    const fieldState = getFieldState<string | null>(form, props.name)

    async function searchIdcc(value: string) {
        const search = unaccentLowerCase(value.trim())
        if (search === '' || refsIdcc == null)
            return []
        return refsIdcc.filter(ref => ref.IsActive__c && (ref.Id.indexOf(search) >= 0 || unaccentLowerCase(ref.Libelle__c ?? '').indexOf(search) >= 0))
    }

    return (
        <AutocompleteBaseField<ReferenceIDCC>
            label={ props.label }
            defaultValue={ fieldState.value ?? "" }
            error={ fieldState.error }
            required={ fieldState.constraints.required }
            disabled={ props.disabled }
            placeholder="Rechercher par code ou libellé (3 caractères minimum)"
            onBlur={ value => form.setValue(props.name, value === "" ? null : form.getValue(props.name), true) }
        
            search={ searchIdcc }
            
            optionFor={ refIdcc => ({ id: refIdcc.Id, content: `${ refIdcc.Id } - ${ refIdcc.Libelle__c }` })}
            onSelect={ refIdcc => form.setValue(props.name, refIdcc.Id, true) }
        />
    )
}