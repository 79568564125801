import axios from 'axios'
import log from 'loglevel'
import { getRequestConfig } from './baseAPI'
import { findCivilite } from './references'
import { Entreprise, Reference } from './referencesAPI'


export interface ContactPatch {
    Phone: string
    MobilePhone: string
    Titre__c: string
    AbonnementNewsletter__c: boolean
    NotificationEmail__c: boolean
    FrequenceNotificationEmail__c?: string
}

export interface Contact extends ContactPatch {
    GuidIAM: string
    FirstName: string
    LastName: string
    Salutation: string
    SalutationRef?: Reference
    Email: string
    AbonnementNewsletter__c: boolean
    FonctionMetier__c: string
    AccountId: string
    Id: string
    AcceptationCGU__c: boolean
}

export interface Profil {
    Contact: Contact
    Entreprises: Entreprise[]
    EntreprisesOF: Entreprise[]
    EntreprisesProspect: Entreprise[]
}

export interface PresentationEntreprise {
    DescriptionOFCampus__c: string
    CertificationsOFCampus__c: string
    SiteInternetOFCampus__c: string
}

export interface ContactEntreprise {
    Id?: string
    IdHeroku?: number
    LastName: string
    FirstName: string
    Phone: string
    Email: string
    defaultCampus__c: boolean | null
}

export interface AdresseEntreprise {
    Id?: string
    IdHeroku?: number
    Adresse1__c: string | null
    Adresse2__c: string | null
    Adresse3__c: string
    Adresse4__c: string | null
    CodePostal__c: string
    Ville__c: string
    Departement__c: string
    Region__c: string
}

/*  export async function getProfil(): Promise<Profil> {
    const requestConfig = await getRequestConfig()
    const type = requestConfig.headers['Atlas-Context'] === 'entreprise' ? 'EntrepriseCliente' : 'OrganismeFormation'
    const { data } = await axios.get<Profil>(`/comptes/profil?type=${type}`, requestConfig)
    data.Contact.SalutationRef = findCivilite(data.Contact.Salutation)
    return data
}
  */
export async function getProfil(type?: 'EntrepriseCliente' | 'OrganismeFormation'): Promise<Profil> {
    const requestConfig = await getRequestConfig()
    const defaultType = requestConfig.headers['Atlas-Context'] === 'entreprise'
        ? 'EntrepriseCliente': requestConfig.headers['Atlas-Context'] === 'prestataire'
        ? 'OrganismeFormation' : 'compte'
    const finalType = type || defaultType  // Utilisez type ou la valeur par défaut


    if (finalType === 'EntrepriseCliente') {
        requestConfig.headers['Atlas-Context'] = 'entreprise'
    } else if (finalType === 'OrganismeFormation') {
        requestConfig.headers['Atlas-Context'] = 'prestataire'
    } else {
        requestConfig.headers['Atlas-Context'] = 'compte'
    }

    const { data } = await axios.get<Profil>(`/comptes/profil?type=${finalType}`, requestConfig)
    data.Contact.SalutationRef = findCivilite(data.Contact.Salutation)
   
    return data
}

export async function getProfilServices(): Promise<string[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<string[]>('/comptes/profil/services', requestConfig)
    return data
}

export async function setContact(contact: ContactPatch): Promise<void> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.patch('/comptes/profil', contact, requestConfig)
    log.debug(data)
}

export async function getPresentationEntreprise(id: number): Promise<PresentationEntreprise> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<PresentationEntreprise>(`/campus/entreprises/${id}`, requestConfig)
    return data
}

export async function setPresentationEntreprise(id: number, presentation: PresentationEntreprise): Promise<PresentationEntreprise> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.patch(`/campus/entreprises/${id}`, presentation, requestConfig)
    return data
}

export async function getContactsEntreprise(id: number, parPage?: number): Promise<ContactEntreprise[]> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.set('par-page', (parPage ?? 20).toFixed(0))
    requestConfig.params = params

    const { data } = await axios.get<ContactEntreprise[]>(`/campus/entreprises/${id}/contacts`, requestConfig)
    return data
}

export async function getAdressesEntreprise(id: number, recherche: string, idStage?: number | undefined, parPage?: number): Promise<AdresseEntreprise[]> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()

    if (recherche)
        params.append('recherche', recherche)

    if (idStage)
        params.append('id-stage', idStage.toFixed(0))

    params.set('par-page', (parPage ?? 20).toFixed(0))

    requestConfig.params = params

    const { data } = await axios.get<AdresseEntreprise[]>(`/campus/entreprises/${id}/adresses`, requestConfig)
    return data
}

export async function postAddAdresseEntreprise(idEntreprise: number, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post(`/campus/entreprises/${idEntreprise}/adresses`, formData, requestConfig)
    return data
}

export async function patchAdresseEntreprise(idAdresse: number, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.patch(`campus/adresses/${idAdresse}`, formData, requestConfig)
    return data
}

export async function deleteAdresseEntreprise(idAdresse: number): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.delete(`campus/adresses/${idAdresse}`, requestConfig)
    return data
}

export async function getAdressesEntrepriseById(id: number, idEntreprise: string): Promise<AdresseEntreprise> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.set('entrepriseId', idEntreprise)
    requestConfig.params = params
    const { data } = await axios.get<AdresseEntreprise>(`/campus/adresses/${id}`, requestConfig)
    return data
}

export async function getContactEntrepriseById(id: number, idEntreprise: string): Promise<ContactEntreprise> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.set('entrepriseId', idEntreprise)
    requestConfig.params = params
    const { data } = await axios.get<ContactEntreprise>(`/campus/contacts/${id}`, requestConfig)
    return data
}

export async function postAddContactEntreprise(idEntreprise: number, formData: any) {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post<ContactEntreprise>(`/campus/entreprises/${idEntreprise}/contacts`, formData, requestConfig)
    return data
}

export async function patchContactEntreprise(idContact: number, idEntreprise: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const params = new URLSearchParams()
    params.set('entrepriseId', idEntreprise)
    requestConfig.params = params
    const { data } = await axios.patch(`campus/contacts/${idContact}`, formData, requestConfig)
    return data
}

export async function deleteContact(idHeroku: number): Promise<string> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'

    const { data } = await axios.delete<string>(`/campus/contacts/${idHeroku}`, requestConfig)

    return data
}
