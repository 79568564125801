import classNames from "classnames";

export type MyStateType =
  | "actionNeeded"
  | "done"
  | "draft"
  | "inactive"
  | "negative"
  | "pending"
  | "positive";

export interface MyStateProps {
  /**
   * Le label de l'état
   */
  children: string;
  /**
   * Le type d'état
   */
  type?: MyStateType;
  /**
   * Réduire la taille d'affichage
   */
  small?: boolean;
}

/**
 * Une pastille représentant l'état d'un objet
 */
export function MyState(props: MyStateProps) {
  return (
    <span
      className={classNames("my-state", {
        "my-state--small": props.small,
        [`my-state--${props.type}`]: props.type,
      })}
    >
      {props.children}
    </span>
  );
}
