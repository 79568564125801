import { MyLogo } from "../../image";

export interface MyPublicTemplateProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
}

/**
 * Le template utilisé pour la partie publique du site, dénué de l'interface
 * habituelle (header, sidebar...)
 */
export function MyPublicTemplate(props: MyPublicTemplateProps) {
  return <div className="my-publicTemplate">
    <div className="my-publicTemplate__inner">
      <a href="/">
        <MyLogo />
      </a>
      <main id="main">
        {props.children}
      </main>
    </div>
  </div>
}
