import { OidcProvider, OidcSecure } from '@axa-fr/react-oidc'
import { Reform } from '@dsid-opcoatlas/reform'
import { Yop } from '@dsid-opcoatlas/yop'
import * as Sentry from '@sentry/react'
import App, { CONTEXTE_PARAM } from 'app/App'
import { appTheme } from 'app/appInfo'
import MamcApp, { MamcRoutes } from 'app/public/MamcApp'
import store from 'app/store'
import { AtlasButton, MySplash, MyTheme } from 'atlas-ds'
import AtlasIcons from 'atlas-ds/atlas/assets/icons.svg?react'
import 'atlas-ds/atlas/atlas.css'
import MyIcons from 'atlas-ds/my/assets/icons.svg?react'
import 'atlas-ds/my/my.css'
import log from 'loglevel'
import { oidcConfiguration } from 'oidc/configuration'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { RouteProps, matchPath } from 'react-router-dom'

Yop.setLocale('fr-FR')
Reform.debugFormErrors = import.meta.env.REACT_APP_ENV === 'dev'

log.setLevel(process.env.NODE_ENV === 'production' ? 'error' : 'debug')
log.debug('Loading application in "', process.env.NODE_ENV, '" mode')

class SentryError extends Error {
    constructor(name: string, message: string, data: any) {
        super(message)
        this.name = name
        if (data) {
            Object.assign(this, data)
        }
    }
}

if (import.meta.env.REACT_APP_ENV !== 'dev') {
    Sentry.init({
        dsn: import.meta.env.REACT_APP_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
    })
    // Setup sentry error logging
    var originalFactory = log.methodFactory
    log.methodFactory = function (methodName, logLevel, loggerName) {
        const rawMethod = originalFactory(methodName, logLevel, loggerName)

        return function (message, error) {
            rawMethod(message, error)
            if (methodName === 'error') {
                if (error instanceof Error)
                    Sentry.captureException(error)
                else
                    Sentry.captureException(new SentryError(message, message, error))
            }
        }
    }
}
log.setLevel(log.getLevel())  // Be sure to call setLevel method in order to apply plugin

// TODO: supprimer ce nettoyage du local storage qui ne servira plus à rien
function cleanLocalStorage() {
    const BROUILLON_DOSSIER_PREFIX = 'brouillon-dossier-'
    const INITIALE_DOSSIER_PREFIX = 'initiale-dossier-'

    log.info('Cleaning up local storage...')
    try {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)
            if (key?.startsWith(BROUILLON_DOSSIER_PREFIX) || key?.startsWith(INITIALE_DOSSIER_PREFIX))
                localStorage.removeItem(key)
        }
    } catch (e: any) {
        log.info('Could not clean local storage: ', e)
    }
}
cleanLocalStorage()

const Authenticating = () => {
    const params = new URLSearchParams(document.location.search)
    const context = params.get(CONTEXTE_PARAM)
    
    return <MyTheme theme={appTheme(context)}>
        <MySplash>
            <p>Authentification en cours</p>
            <p>Vous allez être redirigé sur la page de login</p>
            <p>Si vous n'êtes pas automatiquement redirigé vers la page de login cliquez sur le bouton suivant&nbsp;:</p>
            <AtlasButton level={2} onClick={() => document.location.reload()}>
                S'authentifier
            </AtlasButton>
        </MySplash>
    </MyTheme>
}

const Authenticated = () => {
    const params = new URLSearchParams(document.location.search)
    const context = params.get(CONTEXTE_PARAM)
    
    return <MyTheme theme={appTheme(context)}>
        <MySplash>
            <p>Vous êtes authentifié</p>
            <p>Vous allez être redirigé sur votre espace MyAtlas</p>
        </MySplash>
    </MyTheme>
}

const ServiceWorkerNotSupported = () => {
    const params = new URLSearchParams(document.location.search)
    const context = params.get(CONTEXTE_PARAM)
    
    return <MyTheme theme={appTheme(context)}>
        <MySplash>
            <p>Votre navigateur internet ne supporte pas notre procédure d'authentification.</p>
            <p>Veuillez mettre à jour votre navigateur.</p>
        </MySplash>
    </MyTheme>
}

const SessionExpired = () => {
    const params = new URLSearchParams(document.location.search)
    const context = params.get(CONTEXTE_PARAM)
    
    useEffect(() => {
        setTimeout(() => { 
            document.location.reload()
         }, 1800)
    }, [])

    return <MyTheme theme={appTheme(context)}>
        <MySplash>
            <p>Votre session a expiré.</p>
            <p>Vous allez être automatiquement redirigé vers la page d'authentification.</p>
            <p>Si ce n'est pas le cas, cliquez sur le bouton suivant pour vous authentifier&nbsp;:</p>
            <AtlasButton level={ 2 } onClick={ () => document.location.reload() }>
                S'authentifier
            </AtlasButton>
        </MySplash>
    </MyTheme>
}

function NotAuthenticated() {
    const params = new URLSearchParams(document.location.search)
    const message = params.get('message')
    const context = params.get(CONTEXTE_PARAM)
    
    if (message?.startsWith('iat is in the future') || message?.startsWith('nbf is in the future') || message?.startsWith('exp is in the future')) {
        return <MyTheme theme={appTheme(context)}>
            <MySplash error>
                <p>Authentification échouée</p>
                <p>La date et l'heure de votre poste semblent être décalées de plus de 5 minutes.</p>
                <p>Merci de les ajuster pour continuer puis cliquez sur le bouton suivant&nbsp;:</p>
                <AtlasButton level={2} onClick={() => document.location.href = '/'}>
                    S'authentifier
                </AtlasButton>
            </MySplash>
        </MyTheme>
    }
    
    document.location.href = "/"
    return null
}


const render = () => {
    
    const pathname = new URL(document.location.href).pathname
    const container = document.getElementById('root')
    const root = createRoot(container!)

    const isMamcPath = import.meta.env.REACT_APP_ALLOW_MON_COMPTE === 'true' && MamcRoutes.find(route => matchPath(pathname, {
        path: (route.props as RouteProps<string>).path,
        exact: true,
        strict: true
    }) != null)

    root.render(
        <Sentry.ErrorBoundary>
            <React.StrictMode>
                <HelmetProvider>
                    <Helmet htmlAttributes={{ lang: 'fr' }} />
                </HelmetProvider>
                <AtlasIcons />
                <MyIcons />
                { isMamcPath ?
                <MamcApp /> :
                <OidcProvider
                    configuration={ oidcConfiguration }
                    serviceWorkerNotSupportedComponent={ ServiceWorkerNotSupported }
                    loadingComponent={ Authenticating }
                    authenticatingComponent={ Authenticating }
                    callbackSuccessComponent={ Authenticated }
                    authenticatingErrorComponent={ NotAuthenticated }
                    sessionLostComponent={ SessionExpired }>
                    <OidcSecure>
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </OidcSecure>
                </OidcProvider>
                }
            </React.StrictMode>
        </Sentry.ErrorBoundary>
    )
}

render()


