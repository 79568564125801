import { AtlasLink, AtlasLinkConstructor, AtlasLinkProps } from "atlas-ds"
import { Link as ReactLink } from "react-router-dom"

interface LinkProps extends Omit<AtlasLinkProps, "href"> {
    to?: any;
    href?: string;
}

export const linkConstructor: AtlasLinkConstructor = (
    props: any,
    content: React.ReactNode,
): any => {
    const { key, ...rest } = props;
    return (
        <ReactLink key={key} {...rest} to={props.href}>
            {content}
        </ReactLink>
    )
};

export default function Link(props: LinkProps) {
    // Common link, just pass through
    if (props.href) {
        return <AtlasLink {...(props as AtlasLinkProps)} />;
    }

    if (props.to) {
        // React link

        return (
            <AtlasLink {...props} href={props.to} linkConstructor={linkConstructor} />
        );
    }

    throw new Error("Link should have either a `to` or a `href` props");
}
