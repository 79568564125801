import useApiState from 'api/useApiState'
import useEventListener, { AppEvent } from 'app/events'
import { useAppDispatch } from 'app/store'
import { AtlasColumns, AtlasFlex, AtlasInfo, AtlasLoading, AtlasToastsItemType, MyPageHeaderAnchorLink } from 'atlas-ds'
import Breadcrumb from 'components/Breadcrumb'
import PageHeader from "components/PageHeader"
import Toolbox from 'components/Toolbox'
import { Location } from 'history'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { historyStackActions } from 'slices/historySlice'

export interface DetailActionResult {
    title: string
    status: AtlasToastsItemType
    message: string
}

export interface DetailProps<T> {
    name: string
    title: (detail: T | null) => string
    loadErrorMessage: string
    paramName: string
    getId: (detail: T | null) => number | null
    getDetail: (id: number) => Promise<T>
    history: (location: Location<any>, detail: T | null) => any
    nav?: MyPageHeaderAnchorLink[]
    sections: (detail: T | null, pending: boolean) => JSX.Element[]
    peutAjouterPieces?: boolean
    getActions?: (detail: T) => JSX.Element[] | undefined | null
}


export default function Detail<T>(props: DetailProps<T>) {
    const params = useParams<any>()
    const idDetail = params[props.paramName]

    const location = useLocation()
    const dispatch = useAppDispatch()

    const [{ pending, value: detail, error }, withGetDetail] = useApiState(props.getDetail)
    useEffect(() => {
        if (location.hash !== '#documents') {
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
        }

        withGetDetail(idDetail)
    }, [idDetail, withGetDetail, location.hash]);


    const detailRefreshEvent = props.name + ':Refresh'

    useEventListener(detailRefreshEvent, (_: AppEvent<any>) => {
        withGetDetail(idDetail)
    })

    useEffect(() => {
        if ((props.history.length === 1) || !pending)
            dispatch(historyStackActions.push(props.history(location, detail)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch, detail])

    const actionsContextuelles = detail && props.getActions ? props.getActions(detail) : undefined

    return (
        <AtlasLoading loading={pending}>
            <PageHeader breadcrumb={ <Breadcrumb /> } anchorLinks={props.nav}>
                { props.title(detail) }
            </PageHeader>

            { error && <AtlasInfo type="error" title={ props.loadErrorMessage }>
                { error }
            </AtlasInfo> }

            <AtlasColumns>
                <AtlasFlex column gap="xl">
                    { props.sections(detail, pending) }
                </AtlasFlex>

                <div>
                    { actionsContextuelles && <Toolbox
                        heading="Actions"
                        items={ actionsContextuelles }
                    /> }
                </div>
            </AtlasColumns>
        </AtlasLoading>
    )
}
