import { SetValueOptions } from "@dsid-opcoatlas/reform"
import { BooleanFieldMetadata, CheckboxFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata"
import { TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import { startOfToday, subYears } from "date-fns"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { DeepPartial, ElementContext, FieldMetadata, FormMetadata, ObjectMetadata, override } from "pages/dossier/metadata/FormMetadata"
import { tuteurFields } from "pages/dossier/metadata/TuteursMetadata"
import { BaseDossierModule } from 'slices/dossierCommon'
import { DossierVAEI, DossierVAEITuteur, createDossierVAEITuteur } from "./VAEIModel"
import { searchTuteur } from "pages/dossier-cpro/CPROTuteurMetadata"

export const tuteur16ans = subYears(startOfToday(), 18)

export const SECTION_TT = "Travail temporaire"

function ignoreIfTravailTemporaire<T, P>(
    metadata: FieldMetadata<T, P>,
    extra?: DeepPartial<FieldMetadata<T, P>>
): FieldMetadata<T, P> {
    return override(metadata, {
        ...extra,
        visibleIf: (ctx: ElementContext<DossierVAEITuteur, DossierVAEI>) => {
            if (ctx.root?.tuteurTravailTemporaire === true)
                return false
            if (typeof extra?.visibleIf === "function")
                return extra.visibleIf(ctx)
            return true
        }
    }).mutate(yop => yop.ignoredIf<DossierVAEITuteur, DossierVAEI>(ctx => ctx.root?.tuteurTravailTemporaire !== false) as any)
}

export const tuteurTravailTemporaireMetadata = BooleanFieldMetadata({
    props: {
        label: "Le tuteur est-il ?",
        labelYes: "Au sein de l'entreprise utilisatrice, si travail temporaire ou GEIQ",
        labelNo: "Au sein de l'établissement employeur",
        reverse: true,
        onChange(value, form, parentPath) {
            if (value === false) {
                if (form.values.tuteur != null)
                    form.setValue(`tuteur.codeNAFEntrepriseUtilisatrice`, null, SetValueOptions.Untouch | SetValueOptions.Validate)
                else
                    form.untouch(`tuteur.codeNAFEntrepriseUtilisatrice`)
            }
        },
    },
    section: SECTION_TT,
    mapping: simpleMapping('Tuteur_TT__c'),
}).required()

export const VAEITuteurMetadata: ObjectMetadata<DossierVAEITuteur> = {
    nomNaissance: tuteurFields.nomNaissance(searchTuteur),
    nom: tuteurFields.nom,
    prenom: tuteurFields.prenom,
    dateNaissance: override(tuteurFields.dateNaissance, { props: {
        tooltip: TOOLTIPS.cpro.tuteur.dateNaissance,
    }}).mutate(yop => yop.max(tuteur16ans, "Le tuteur doit avoir au moins 16 ans")),
    emploiOccupe: tuteurFields.emploiOccupe,
    codeNAFEntrepriseUtilisatrice: TextFieldMetadata({
        props: {
            label: "Code NAF de l'entreprise utilisatrice",
        },
        visibleIf: (ctx: ElementContext<DossierVAEITuteur, DossierVAEI>) => ctx.root?.tuteurTravailTemporaire === true,
        mapping: simpleMapping('CodeNAFEntrepriseUtilisatrice__c'),
    }).required().mutate(yop => yop.max(50).ignoredIf<DossierVAEITuteur, DossierVAEI>(ctx => ctx.root?.tuteurTravailTemporaire !== true)),
    employeurDirigeant: ignoreIfTravailTemporaire(tuteurFields.employeurDirigeant),
    exerciceFonction2Annees: ignoreIfTravailTemporaire(tuteurFields.exerciceFonction2Annees, {
        props: {
            label: 'Tutorat exercé au cours des 2 dernières années',
            disabled: false,
        },
        mapping: simpleMapping('Exercice2AnneesTuteur__c'),
    }),
    anneesExperience: ignoreIfTravailTemporaire(NumberFieldMetadata({
        props: {
            label: "Nombre d'années d'expérience",
        },
        mapping: simpleMapping('NbAnneeExperienceTuteur__c'),
    }).mutate(yop => yop.max(99))),
    salariesSuivis: ignoreIfTravailTemporaire(NumberFieldMetadata({
        props: {
            label: "Nombre d'alternants actuellement suivis par le tuteur",
        },
        mapping: simpleMapping('NbSalariesSuiviTuteur__c'),
    }).mutate(yop => yop.min(1).max(99))),
    formation: ignoreIfTravailTemporaire(tuteurFields.formation),
    dateFormation: ignoreIfTravailTemporaire(tuteurFields.dateFormation.mutate(yop => yop.max(startOfToday(), "La date de formation doit être dans le passé"))),
    priseEnChargeFonctionTutorale: ignoreIfTravailTemporaire(tuteurFields.priseEnChargeFonctionTutorale),
    formateurAfest: BooleanFieldMetadata({
        props: {
            label: 'Le tuteur est-il également formateur AFEST ?',
        },
        visibleIf: (ctx: ElementContext<BaseDossierModule, DossierVAEI>) => !!ctx.root?.moduleAfest?.formationAfest,
        mapping: simpleMapping('TuteurFormateurAFEST__c'),
    }).mutate(yop => yop.requiredIf<BaseDossierModule, DossierVAEI>(ctx => !!ctx.root?.moduleAfest?.formationAfest)),
    eligibilite: CheckboxFieldMetadata({
        props: {
            label: "L'employeur atteste sur l'honneur que le tuteur répond à l'ensemble des critères d'éligibilité à cette fonction.",
            tooltip: TOOLTIPS.cpro.maitre.eligibilite,
            full: true
        },
        mapping: simpleMapping('EmployeurAttesteSurHonneurTuteur__c'),
    }).required().mutate(yop => yop.oneOf([true], "Veuillez cocher cette case pour confirmer l'éligibilité du tuteur")),
}

export function dossierVAEITuteurInitializer<D extends DossierVAEI>(dossier: D) {
    if (dossier.tuteur == null)
        dossier.tuteur = createDossierVAEITuteur()
    return dossier
}
