import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { Dossier, getDossiers } from 'api/dossierAPI'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE, ETAT_DOSSIER_ACCORDE, ETAT_DOSSIER_CLOTURE, ETAT_DOSSIER_EN_COURS } from 'api/references'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import { BaseFieldProps } from 'components/reform/inputs/utils/BaseFieldProps'
import { DossierAF } from 'slices/dossierCommon'

export default function NumeroDossierApprentissageField(props: BaseFieldProps<string, DossierAF<any, any>>) {

    const form = useFormContext<DossierAF<any, any>>()
    const fieldState = getFieldState<string | null>(form, props.name)

    async function getDossiersApprentissage(recherche: string) {
        const page = await getDossiers({ page: 0, parPage: 20, recherche, tri: 'NumeroDossier__c', 
            dispositifFo: [DISPOSITIF_CONTRAT_APPRENTISSAGE], 
            etatDossierFo: [ETAT_DOSSIER_EN_COURS, ETAT_DOSSIER_ACCORDE, ETAT_DOSSIER_CLOTURE] 
        })
        return page.items
    }

    return (
        <AutocompleteBaseField<Dossier>
            label={ props.label }
            defaultValue={ fieldState.value ?? "" }
            disabled={ props.disabled }
            placeholder="Rechercher par code ou libellé (3 caractères minimum)"
            required={ fieldState.constraints.required }
            error={ fieldState.error }
            onBlur={ value => form.setValue(props.name, value === "" ? null : form.getValue(props.name), true) }
            
            search={ getDossiersApprentissage }

            optionFor={ dossier => ({ id: dossier.NumeroDossier__c, content: `${dossier.NumeroDossier__c} - ${dossier.Intitule__c}` }) }
            onSelect={ dossier => form.setValue(props.name, dossier.NumeroDossier__c, true) }
        />
    )
}