import { AppDossierModule } from 'pages/detail-dossier/modules'
import { MetadataPDC as Metadata} from 'pages/dossier-pdc/PDCFormMetadata'
import { actionsEntrepriseRelanceAnnulerSubrogation, actionsEntrepriseRelanceCloture, actionsEntrepriseRelanceDemanderSubrogation } from 'pages/detail-dossier/relanceff/entreprise'
import { actionsPrestataireRelanceAnnulation, actionsPrestataireRelanceCloture, actionsPrestataireRelanceModifierDates, actionsPrestataireRelanceAnnulerSubrogation } from 'pages/detail-dossier/relanceff/prestataire'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import { actionsAnnulerDossier, actionsEntrepriseChangerPeriode, actionsEntrepriseDeposerFacture } from 'pages/detail-dossier/modules/entreprise'
import { actionsPrestataireAccordeDeposerFacture } from 'pages/detail-dossier/modules/prestataire'


const dossierPDCModule: AppDossierModule = {
    name: 'PDC',
    dispositifId: Metadata.dispositif,
    metadata: { 
        entreprise: { create: Metadata } 
    },
    extensions: {
        entreprise: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsEntrepriseChangerPeriode(dossier, actions)
                    actionsAnnulerDossier(dossier, actions)
                    actionsEntrepriseDeposerFacture(dossier, actions)
                    
                    actionsEntrepriseRelanceCloture(dossier, actions)
                    actionsEntrepriseRelanceDemanderSubrogation(dossier, actions)
                    actionsEntrepriseRelanceAnnulerSubrogation(dossier, actions)
                },
            },
        } as DetailDossierExtension,
        prestataire: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsPrestataireAccordeDeposerFacture(dossier, actions)
                    
                    actionsPrestataireRelanceCloture(dossier, actions)
                    actionsPrestataireRelanceAnnulerSubrogation(dossier, actions)
                    actionsPrestataireRelanceAnnulation(dossier, actions)
                    actionsPrestataireRelanceModifierDates(dossier, actions)
                },
            },
        } as DetailDossierExtension,
    },
}

export default dossierPDCModule