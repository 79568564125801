import { useHistory } from "react-router-dom"
import { RootState } from 'app/rootReducer'
import { useSelector } from 'react-redux'
import { useLastLocation } from 'components/lastlocation'

const ETAPE_MATCH = /^\/mon-diagnostic-etape([0-9]+)?.*/

const storageKey = (entrepriseId: string) => `diagnostic-${ entrepriseId }`

export const getDiagnosticEtapeMax = (entrepriseId?: string | null) => {
    if (entrepriseId != null) {
        const etapeMax = parseInt(localStorage.getItem(storageKey(entrepriseId)) ?? '')
        if (!isNaN(etapeMax))
            return etapeMax
    }
    return null
}

export const clearDiagnosticEtape = (entrepriseId?: string | null) => {
    if (entrepriseId != null)
        localStorage.removeItem(storageKey(entrepriseId))
}

export const useDiagnosticNavigation = (navigationGuard?: (etape: number, next: () => void) => boolean) => {
    const { location, push, replace } = useHistory()
    const lastLocation = useLastLocation()
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const match = location.pathname.match(ETAPE_MATCH)
    const etape = Number(match?.[1] ?? 1)

    const pathTo = (etape: number) => {
        return `/mon-diagnostic-etape${etape}`
    }

    const prevMatch = lastLocation?.pathname?.match(ETAPE_MATCH)
    const prevEtape = prevMatch && prevMatch?.[1] ? Number(prevMatch?.[1]) : null

    const replaceTo = (etape: number, skipGuard: boolean = false) => {
        if (!skipGuard && navigationGuard && navigationGuard(etape, () => replace(pathTo(etape))))
            return
        replace(pathTo(etape))
    }

    const replaceToPath = (path: string, skipGuard: boolean = false) => {
        if (!skipGuard && navigationGuard && navigationGuard(etape, () => doNavigateTo(etape)))
            return
        replace(`/mon-diagnostic-${path}`)
    }

    const doNavigateTo = (toEtape: number, prevEtapeMax?: number) => {
        const path = pathTo(toEtape)
        push(path)
    }

    const navigateTo = (etape: number, skipGuard: boolean = false) => {
        if (!skipGuard && navigationGuard && navigationGuard(etape, () => doNavigateTo(etape)))
            return
        doNavigateTo(etape)
    }

    const navigateToPath = (path: string, skipGuard: boolean = false) => {
        if (!skipGuard && navigationGuard && navigationGuard(etape, () => doNavigateTo(etape)))
            return
        push(`/mon-diagnostic-${path}`)
    }

    const navigatePrev = () => {
        navigateTo(etape - 1)
    }
    
    const navigateNext = (saveEtape = true) => {
        const nextEtape = etape + 1
        let prevEtapeMax
        if (entreprise?.Id) {
            const key = storageKey(entreprise?.Id)
            prevEtapeMax = parseInt(localStorage.getItem(key) ?? '')
            if (saveEtape && entreprise?.Id && nextEtape > 1) {
                if (isNaN(prevEtapeMax) || prevEtapeMax < nextEtape)
                    localStorage.setItem(key, nextEtape.toFixed(0))
            }
        }
        doNavigateTo(nextEtape, prevEtapeMax)
    }

    return {
        etape,
        prevEtape,
        pathTo,
        navigatePrev,
        navigateNext,
        navigateTo,
        replaceTo,
        navigateToPath,
        replaceToPath,
    }
}
