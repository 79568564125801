import { DetailFormationCampus, SessionFormation, getSessions } from "api/formationAPI"
import { MODALITE_STAGE_EN_LIGNE, TYPE_STAGE_INTRA } from 'api/references'
import useApiState from "api/useApiState"
import { RootState } from "app/rootReducer"
import { AtlasButton, AtlasFlex, AtlasHeading, AtlasIcon, AtlasLoading, AtlasTooltip, MyTable } from "atlas-ds"
import Link from "components/Link"
import { formatApiDate, formatRef } from "components/format/Format"
import DeleteSessionModal from "pages/detail-session/DeleteSessionModal"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

export default function DetailFormationSessions(props: { formation: DetailFormationCampus | null, disabled?: boolean }) {

    const history = useHistory()

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)
    const [{ pending, value: sessions, error }, withGetFormationSessions] = useApiState(getSessions)
    const [showDeleteModal, setShowDeleteModal] = useState<{ show: boolean, session?: SessionFormation }>({ show: false })

    useEffect(() => {
        if (props.formation)
            withGetFormationSessions(props.formation.IdHeroku)
    }, [props.formation, withGetFormationSessions])

    const sessionsActions = (session: SessionFormation) => props.disabled ? [] : [
        <AtlasButton
            key="supprimer"
            icon="trash"
            level={ 3 }
            onClick={() => setShowDeleteModal({ show: true, session: session })}
        >Supprimer</AtlasButton>,
        <AtlasButton
            key="dupliquer"
            icon="copy"
            level={ 3 }
            onClick={() => history.push(`/nouvelle-session/${props?.formation?.IdHeroku}/${session.IdHeroku}/session`)}
        >Dupliquer</AtlasButton>,
        <Link
            key="detailSession"
            icon="details"
            to={`/detail-session/${session.IdHeroku}`}
            level={ 3 }
        >Détail session</Link>
    ]

    const getLibellesTypes = (session: SessionFormation) => {
        let libelles = ''
        session?.Type__c?.split(';').forEach((type) => {
            if (libelles)
                libelles += `, `
            libelles += formatRef(refs?.REF_CAMPUS_TYPE, type)
        })
        return libelles
    }

    return <>
        <div id="sessions">
            <AtlasHeading
                tag="h2"
                size="m"
                button={<AtlasButton
                    level={3}
                    icon="plus"
                    onClick={() => history.push(`/nouvelle-session/${props?.formation?.IdHeroku}/session`)}
                    disabled={props.disabled}
                >Ajouter une session</AtlasButton>}
            >{(sessions?.length ?? 0) > 1 ? `${sessions?.length} sessions` : ((sessions?.length ?? 0) === 1 ? `${sessions?.length} session` : 'Aucune session')}</AtlasHeading>
            <AtlasLoading loading={pending}>
                <MyTable
                    caption="Sessions"
                    rows={sessions ?? []}
                    emptyText="Aucune session"
                    error={error ?? undefined}
                    rowActions={sessionsActions}
                    columns={[
                        {
                            id: "DateDebut__c",
                            label: "Date de formation",
                            value: (row: SessionFormation) => row?.Type__c !== TYPE_STAGE_INTRA && row?.Modalite__c !== MODALITE_STAGE_EN_LIGNE ? (formatApiDate(row.DateDebut__c) || '') : 'Aucune date'
                        },
                        {
                            id: "NbPlacesOccupe__c",
                            label: "Inscrits / Places",
                            value: (row: SessionFormation) => (row.Stagiaires?.length ?? 0) > 0 ? <AtlasFlex alignItems="center" gap="s">
                                {row.NbPlacesOccupees__c} {row?.Type__c !== TYPE_STAGE_INTRA && row?.Modalite__c !== MODALITE_STAGE_EN_LIGNE ? ' / ' + row.NbPlacesTotalAtlas__c : ''}
                                <AtlasTooltip opener={<AtlasIcon name="users" size="s" title="Voir la liste des stagiaires" />}>
                                    <ul>{ row.Stagiaires?.map((stagiaire, index) =>
                                        <li key={ index }>{stagiaire.Nom__c ?? ''} {stagiaire.Prenom__c }</li>
                                    )}</ul>
                                </AtlasTooltip>
                            </AtlasFlex> : <></>
                        },
                        {
                            id: "Adresse.Ville__c",
                            label: "Commune",
                            value: (row: SessionFormation) => row?.Modalite__c !== MODALITE_STAGE_EN_LIGNE ? (row?.Adresse?.Ville__c || '') : 'En ligne'
                        },
                        {
                            id: "Modalite__c",
                            value: (row: SessionFormation) => getLibellesTypes(row),
                            label: "Modalité"
                        },
                        {
                            id: "Type__c",
                            value: (row: SessionFormation) => formatRef(refs?.REF_CAMPUS_MODALITE, row?.Modalite__c),
                            label: "Type"
                        }
                    ]}
                />
            </AtlasLoading>
        </div>
        
        { showDeleteModal.show && <DeleteSessionModal
            show={ true }
            session={showDeleteModal.session}
            idStage={props?.formation?.IdHeroku}
            close={() => setShowDeleteModal({ show: false })}
        /> }
    </>
}
