import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
    page: {
        fontFamily: 'OpenSans',
        fontSize: 11,
        padding: 40,
        lineHeight: 1.6,
    },
    logo: {
        width: 75,
    },
    header: {
        marginTop: 40,
        marginBottom: 20,
        marginLeft: 80,
    },
    title: {
        fontFamily: 'Palanquin',
        color: '#7A6BB7',
        borderBottom: '2px solid #7A6BB7',
        fontSize: 22,
    },
    subtitle: {
        fontFamily: 'Palanquin',
        fontSize: 17,
    },
    stepTitle: {
        marginTop: 20,
        fontWeight: 'bold',
        fontSize: 14,
        color: '#7A6BB7',
        textTransform: 'uppercase',
    },
    subStepTitle: {
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 2,
    },
    arrow: {
        width: 8,
    },
    value: {
        fontWeight: 'bold',
    },
    text: {
        marginTop: 5,
    },
})
