import { BaseCheckboxField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldCheckbox } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"

interface CheckboxFieldProps<T extends object> extends BaseFieldProps<boolean, T> {}

export default function CheckboxField<T extends object>(props: CheckboxFieldProps<T>) {
    const context = useFormContext<T>()
    const fieldState = getFieldState<boolean>(context, props.name)
    const render = useRender()

    return <AtlasFieldCheckbox
        name={props.name}
        disabled={props.disabled}
        tooltipContent={props.tooltip}
        label={props.label}
        required={fieldState.constraints.required}
        error={ fieldState.error }
        link={props.link}
        button={props.button}
        inputConstructor={(inputProps) => <BaseCheckboxField
            {...inputProps}
            onChange={props.onChange}
            render={render}
        />}
    ></AtlasFieldCheckbox>
}
