import { getFieldState, SetValueOptions, useFormContext } from '@dsid-opcoatlas/reform'
import { Reference } from "api/referencesAPI"
import { RootState } from 'app/rootReducer'
import { unaccentLowerCase } from 'components/fields/FieldUtil'
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { useSelector } from 'react-redux'
import AutocompleteBaseField from './AutocompleteBaseField'

interface DepartementFieldProps<T extends object> extends BaseFieldProps<string, T> {
    communeName?: string
}

export default function DepartementField<T extends object>(props: DepartementFieldProps<T>) {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const context = useFormContext<T>()
    const fieldState = getFieldState<string | null>(context, props.name)

    const getReferencesDepartement = (text: string) => {
        const utext = unaccentLowerCase(text)
        return Promise.resolve(refs?.REF_DEPARTEMENT?.filter(r =>
            r.Id !== '099' && (r.Id.indexOf(text) >= 0 || unaccentLowerCase(r.LibelleReduit__c)?.indexOf(utext) >= 0)
        ) ?? [])
    }
    
    const onSelect = (departement: Reference) => {
        const previousValue = fieldState.value
        if (props.communeName && ((previousValue === '099' || departement?.Id === '099') || departement?.Id !== previousValue))
            context.setValue(props.parentPath + '.' + props.communeName, null, SetValueOptions.Touch)

        context.setValue(props.name, departement.Id, true)

        if (props.onChange)
            props.onChange(departement?.Id ?? null, context)
    }

    return (
        <AutocompleteBaseField<Reference>
            label={ props.label ?? 'Département' }
            defaultValue={ fieldState.value ?? "" }
            error={ fieldState.error }
            required={ fieldState.constraints.required }
            disabled={ props.disabled }
            placeholder="Rechercher par numéro ou nom (2 caractères minimum)"
            tooltip={ props.tooltip }
            onBlur={ value => context.setValue(props.name, value === "" ? null : context.getValue(props.name), true) }
        
            search={ getReferencesDepartement }
            searchDebounceOptions={{ minChars: 2 }}
            
            optionFor={ departement => ({ id: departement.Id, content: `${ departement.Id } - ${ departement.LibelleReduit__c }` })}
            onSelect={ onSelect }
        />
    )
}
