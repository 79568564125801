import { TextFieldMetadata } from '../../../components/metadata/TextFieldMetadata'
import { DateFieldMetadata } from '../../../components/metadata/DateFieldMetadata'
import { dateMapping, simpleMapping } from './DossierMapping'


export const SECTION_CONTRAT_EMPLOI_OCCUPE = 'emploiOccupe'
export const SECTION_CONTRAT_EMPLOI_VISE = 'emploiVise'

export const contratFields = {
    emploiOccupe: TextFieldMetadata({
        props: {
            label: 'Emploi occupé dans le cadre du contrat de travail (intitulé précis)',
        },
        section: SECTION_CONTRAT_EMPLOI_OCCUPE,
        mapping: simpleMapping('EmploiOccupe__c'),
    }),
    dateDebutContrat: DateFieldMetadata({
        props: {
            label: "Date de début d'exécution du contrat",
        },
        section: SECTION_CONTRAT_EMPLOI_OCCUPE,
        mapping: dateMapping('DateDebutContrat__c'),
    }).required(),
    classificationEmploi: TextFieldMetadata({
        props: {
            label: "Classification de l'emploi dans la convention collective",
        },
        section: SECTION_CONTRAT_EMPLOI_OCCUPE,
        mapping: simpleMapping('ClassificationEmploi__c'),
    }).mutate(yop => yop.min(1).max(250)),
    niveauEmploi: TextFieldMetadata({
        props: {
            label: 'Niveau ou position',
        },
        section: SECTION_CONTRAT_EMPLOI_OCCUPE,
        mapping: simpleMapping('NiveauCCN__c'),
    }).mutate(yop => yop.min(1).max(15)),
    coefficientHierarchique: TextFieldMetadata({
        props: {
            label: 'Coefficient hiérachique',
        },
        section: SECTION_CONTRAT_EMPLOI_OCCUPE,
        mapping: simpleMapping('CoefficientCCN__c'),
    }).mutate(yop => yop.min(1).max(10)),
}
