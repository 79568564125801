import { getAllDispositifFO } from 'api/references'
import { AppState } from 'app/appInfo'
import appDossierModules from 'pages/detail-dossier/modules'
import dossierDefaut from 'pages/detail-dossier/modules/DossierDefaut'
import { DossierCreationMetadata, DossiersModuleContext } from 'pages/mes-dossiers-contrats'

const dossierContextPrestataire = (appState: AppState) => {
    // Construit la liste des mddules dossiers à partir de la liste complète des dispositifs
    const dispositifs = getAllDispositifFO()

    const ctx = new DossiersModuleContext()

    if (dispositifs) {
        for (let d of dispositifs) {
            const module = appDossierModules.find(dm => dm.dispositifId === d.Id)
            if (module) {
                ctx.dossiersModules.push(module)
            } else {
                ctx.dossiersModules.push(dossierDefaut(d))
            }
        }
    }
    
    // Construit la liste des metadata de création de dossier
    ctx.creationMetadata = [] as DossierCreationMetadata[]
    for (const module of ctx.dossiersModules) {
        const metadata = module?.metadata?.prestataire
        if (!metadata) continue

        ctx.creationMetadata.push({
            module,
            create: metadata.create,
            edit: metadata.edit,
        })
    }

    return ctx
}

export default dossierContextPrestataire
