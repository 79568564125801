import { Step } from 'react-joyride'
import { ELEMENTS } from '../elements'

export const dossiersSteps: Step[] = [
  {
    target: ELEMENTS.NEW_REQUEST,
    content:
      "Commencer une nouvelle demande de prise en charge sur l'ensemble des dispositifs Atlas",
    placementBeacon: 'left',
    placement: 'left',
  },
  {
    target: ELEMENTS.TABLE,
    content:
      'Tableau de résultats contextualisés selon vos filtres actifs. Passer sur une des lignes de résultats pour voir les interactions possibles sur ce dossier',
    placementBeacon: 'top',
    placement: 'top',
  },
];
