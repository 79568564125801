import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { Etablissement, getOrganismeMamc } from "api/commonAPI"
import { postAttacherOF, recupererFactureAleatoire } from "api/monCompteAPI"
import { TYPE_ORGANISME } from "api/references"
import useApiState from "api/useApiState"
import { RootState } from "app/rootReducer"
import { useAppDispatch } from "app/store"
import { AtlasButton, AtlasColumns, AtlasInfo, AtlasInfoProps, AtlasSpacer, MyForm } from 'atlas-ds'
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import CurrencyField from "components/reform/inputs/CurrencyField"
import TextField from "components/reform/inputs/TextField"
import { Ignored, RequiredNumber } from "components/yop/constraints"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link as ReactLink, useHistory, useLocation } from "react-router-dom"
import { nil } from 'slices/dossierCommon'
import { profilActions } from "slices/profilSlice"
import AttacherEtablissementField from "../components/AttacherEtablissementField"

export function createAttachementPrestataire() {
    return {
        etablissement: nil as Etablissement,
        montantTTC: nil as number,
    }
}

export type AttachementPrestataire = ReturnType<typeof createAttachementPrestataire>

export function AttacherUnPrestataire() {

    const { profil } = useSelector((state: RootState) => state.profilState)

    const [{ pending: facturePending, value: factureInfo }, withRecupererFactureAleatoire, setFactureInfo] = useApiState(recupererFactureAleatoire)

    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation()

    const [backendError, setBackendError] = useState<string | null>(null)

    const [info, setInfo] = useState<AtlasInfoProps | null>(null)

    const initialValues = createAttachementPrestataire()
    const validationSchema = Yop.object<AttachementPrestataire>({
        etablissement: Ignored,
        montantTTC: RequiredNumber,
    })

    const form: UseFormReturn<AttachementPrestataire> = useForm<AttachementPrestataire>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<AttachementPrestataire>) => {
            const entrepriseMamc = context.values!
            const idFact = factureInfo?.data.id

            const params = {
                type: TYPE_ORGANISME,
                siren: entrepriseMamc.etablissement.SIREN__c,
                IdEntreprise: entrepriseMamc.etablissement.Id,
                montantTTC: entrepriseMamc.montantTTC ?? undefined,
                IdFacture: idFact,
            }

            postAttacherOF(JSON.stringify(params)).then(_ => {
                const params = new URLSearchParams(location.search)
                params.append("nouveauPrestataireSiren", entrepriseMamc.etablissement.SIREN__c)
                dispatch(profilActions.getServices()).then(() => {
                    history.replace({ pathname: "/mes-prestataires", search: params.toString() })
                })
            }).catch(error => {
                const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
                setBackendError(message)
                context.setSubmitting(false)
            })
        },
    })

    const searchEtablissement = async (recherche: string) => {
        const etablissements = await getOrganismeMamc(recherche)
        setInfo(
            etablissements.length === 0 ?
            {
                type: "error",
                title: `Votre organisme de formation (SIREN : ${recherche}) n'est pas référencé chez Atlas`,
                children: "Merci de vous rapprocher du support.",
                link: <a target="_blank" rel="noopener noreferrer" href="https://www.opco-atlas.fr/contact/formulaire-webassistance.html">Accéder au formulaire de web assistance</a>
            } :
            null
        )
        return etablissements
    }

    const getFactureAleatoire = () => {
        if (form.values?.etablissement?.Id != null) {
            setFactureInfo({ pending: true, value: null, error: null })
            withRecupererFactureAleatoire(form.values.etablissement.Id, factureInfo?.data.id).catch(_ => {
                setInfo({
                    type: "warning",
                    title: `Pas de première facture détectée pour l'entreprise ${form.values?.etablissement?.Name} (SIREN : ${form.values?.etablissement?.SIREN__c})`,
                    children: "Pour poursuivre le processus de rattachement, vous devez avoir déjà facturé Atlas. Transmettez-nous votre première facture accompagnée d'une attestation de présence via le formulaire ci-dessous.",
                    link: <a target="_blank" rel="noopener noreferrer" href="https://www.opco-atlas.fr/prestataire/espace-organisme-formation.html">Accéder au formulaire</a>
                })
            })
        }
    }

    const onSelect = (etablissement: Etablissement) => {
        if (profil?.EntreprisesOF.some(entreprise => entreprise.SIREN__c === etablissement.SIREN__c)) {
            setInfo({
                title: `Le prestataire ${etablissement.Name} (SIREN : ${etablissement.SIREN__c}) est déjà rattaché à votre compte.`,
                children: "Vous pouvez la retrouver dans votre liste de prestataires.",
                link: (
                    <ReactLink to="/mes-prestataires">Retour à mes prestataires</ReactLink>
                ),
            })
        }
        else
            getFactureAleatoire()
    }

    return <>
        <PageHeader>Attacher un prestataire</PageHeader>

        <AtlasColumns>
            <Form context={form} autoComplete="off" noValidate>
                <MyForm>
                    <AttacherEtablissementField
                        name="etablissement"
                        label="Rechercher un prestataire par SIREN"
                        search={ searchEtablissement }
                        onChange={ _ => setInfo(null) }
                        onSelect={ onSelect }
                    />

                    {
                    info != null ?
                    <AtlasInfo { ...info } /> :

                    form.values?.etablissement?.SIREN__c != null && factureInfo?.data != null ?
                    <>
                        <MyForm.Grid>
                            <TextField disabled label="Raison Sociale" name="etablissement.Name" />

                            <TextField disabled label="Siren" name="etablissement.SIREN__c" />
                        </MyForm.Grid>

                        <AtlasInfo
                            type="success"
                            title="Votre entreprise est bien référencée comme prestataire."
                        >
                            <p>Pour validation, veuillez nous indiquer le montant TTC correspondant à la facture suivante :</p>
                            <ul>
                                <li>Référence : {factureInfo.data.referenceFacture}</li>
                                <li>Date d'émission : {factureInfo.data.dateEmission}</li>
                                <li>Numéro de dossier : {factureInfo.data.numeroDossier}</li>
                                <li>Intitulé du dossier : {factureInfo.data.intituleDossier}</li>
                            </ul>

                            <AtlasSpacer size="xs" />

                            { factureInfo?.hasMore === true &&
                                <AtlasButton
                                    onClick={ getFactureAleatoire }
                                    level={ 2 }
                                    icon="reload"
                                    disabled={ facturePending }
                                    spinner={{ spinning: facturePending }}
                                >Changer de facture</AtlasButton>
                            }
                        </AtlasInfo>

                        <CurrencyField name="montantTTC" label="Montant TTC" />
                    </> :

                    null
                    }
                    
                    { backendError &&
                    <AtlasInfo type="error" title="Erreur" >{ backendError }</AtlasInfo>
                    }

                    <MyForm.Actions>
                        <Link to="/mes-prestataires" level={ 2 } disabled={ form.submitting }>Précédent</Link>
                        <Button
                            submit
                            spinner={{ spinning: form.submitting }}
                            disabled={ info != null || facturePending || form.submitting || form.values?.etablissement?.SIREN__c == null || factureInfo?.data.id == null }
                        >Valider</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </AtlasColumns>
    </>
}
