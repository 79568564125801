import { AtlasBreadcrumbProps, AtlasColumns, AtlasHeading, AtlasSpacer } from 'atlas-ds'
import PageHeader from 'components/PageHeader'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import DiagAtlasSteps from './DiagAtlasSteps'
import DiagPageHeader from './DiagPageHeader'

export interface DiagLayoutProps {
    step?: number
    stepMax?: number
    noStepInTitle?: boolean
    navigationGuard?: (etape: number, next: () => void) => boolean
    headingAlert?: JSX.Element
    headingTitle?: string
    headingInfoBlock?: JSX.Element
    headingInfo?: string
    breadcrumb?: React.ReactElement<AtlasBreadcrumbProps>;
    children: React.ReactNode
}

export default function DiagLayout(props: DiagLayoutProps) {
    useScrollToTop()
    
    return <>
        <DiagPageHeader>
            <PageHeader breadcrumb={props.breadcrumb}>Diagnostic</PageHeader>
        </DiagPageHeader>

        <AtlasColumns>
            <div>
                { props.headingAlert }

                { props.headingTitle && <div>
                    <AtlasHeading tag="h2" size="m">{ props.headingTitle }</AtlasHeading>
                    { props.headingInfoBlock }
                    { props.headingInfo && <p>{ props.headingInfo }</p> }
                </div> }

                <AtlasSpacer size="l" />

                { props.children }
            </div>

            { props.step && <DiagAtlasSteps step={props.step} stepMax={props.stepMax!} navigationGuard={props.navigationGuard} /> }
        </AtlasColumns>
    </>
}
