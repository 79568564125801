import { AtlasToastsItemType } from 'atlas-ds'
import axios from 'axios'
import { AppType } from 'slices/contextSlice'
import { getRequestConfig } from './baseAPI'

export interface Entreprise {
    Actif: boolean
    BillingCity: string | null
    BillingCountry: string | null
    BillingPostalCode: string | null
    BillingStreet: string | null
    Adresse1__c: string | null
    Adresse2__c: string | null
    Adresse3__c: string | null
    Adresse4__c: string | null
    CodeAdherent__c: string
    CodeEtablissement__c: string
    CodeIDCC__c: string | null
    CodeNAF__c: string | null
    Name: string
    Id: string
    IdHeroku: number
    SIREN__c: string
    SIRET__c: string
    ParentId: string | null
    VV__c: boolean
    Branche__c: string | null
    VVAnneeN1__c: number | null
    VVAnneeN2__c: number | null
    VVBudgetAnneeN1__c: number | null
    VVBudgetAnneeN2__c: number | null
    VVEngageAnneeN1__c: number | null
    VVEngageAnneeN2__c: number | null
    VVFdgAnneeN1__c: number | null
    VVFdgAnneeN2__c: number | null
    VVSoldeAnneeN1__c: number | null
    VVSoldeAnneeN2__c: number | null
    VVVersementAnneeN1__c: number | null
    VVVersementAnneeN2__c: number | null
    Region__c: string | null
    TailleEntreprise__c: string
    NomPrenomAnalysteMyAtlas__c: string | null
    NomPrenomConseillerMyAtlas__c: string | null
    NomPrenomDeveloppeurMyAtlas__c: string | null
    NumberOfEmployees: number | null
    NumeroDA__c: string | null
    TypeEnregistrement: string
    OFCampus__c: boolean
    CertificationCA__c: boolean | null
    IdSOR: string
    WebAdhesionRefusee__c: boolean
}

export interface Bandeau {
    id: string
    titre: string
    contenu: string
    dateFinPublication: string | null
    actif: boolean
    type: AtlasToastsItemType
    lien: string
    labelLien: string | null
    items: [{
        item: string
        description: string
    }]
}

export interface BordereauVV {
    lien: string
}

export interface Dispositif {
    Id: string
}

export interface BoiteAOutils {
    Id: string
    Nom: string
    Lien: string
    DispositifFOIds: string[]
}

export interface MenuElement {
    Id: string
    Nom: string
    DispositifFOIds?: string[]
}

export interface AccesRapide {
    Id: string
    DispositifFOId?: string
    Lien: string
    Nom: string
    Defaut: string
}

export interface Selfe {
    lien: string
}

export interface CproFinancementResteAChargePdc {
    BranchesAutorisees: string[]
}

export interface DispositifsEligiblesParBranche {
    BrancheId: string
    DispositifFOIds: string[]
}

export interface AjoutPiecesComplementaire {
    PieceId: string
    DispositifFOIds: string[]
}

export interface AjoutPiecesComplementaireFacture {
    PieceId: string
    nom?: string // TODO: à enlever quand les données de config sont prêtes
}
export interface PeriodeSMIC {
    DateDebut: string
    DateFin: string
    Valeur: number
}

export interface ConditionsGenerales {
    lien: string
}

export interface ConditionsGeneralesCampus {
    lien: string
}

export interface PlaceDeLaFormation {
    lien: string
}

export interface ContexteSpecifique {
    ContexteSpecifiqueId: number
    BranchesAutorisees: string[]
}

export interface RapportsSelLegacy {
    lien: string
}

export interface DispositifsCreationDesactiveeParBranche {
    BrancheIds: string[]
    DispositifFOIds: string[]
}

export interface GrilleRemunerationCPRO {
    lien: string
}

export interface Mamc {
    AnneeMasseSalariale: number;
}

export interface Config {
    Version: number | undefined | null
    AccesRapides: AccesRapide[]
    AjoutPiecesComplementaires: AjoutPiecesComplementaire[]
    AjoutPiecesComplementairesFacture: AjoutPiecesComplementaireFacture[]
    BoiteAOutils: BoiteAOutils[]
    BordereauVV: BordereauVV
    CproFinancementResteAChargePdc: CproFinancementResteAChargePdc
    DispositifsEligiblesParBranche: DispositifsEligiblesParBranche[]
    DispositifCreationDesactiveeFOIds: string[]
    Menu: MenuElement[]
    SMIC: PeriodeSMIC[]
    Selfe: Selfe
    ConditionsGenerales: ConditionsGenerales
    ConditionsGeneralesCampus: ConditionsGeneralesCampus
    PlaceDeLaFormation: PlaceDeLaFormation
    ContexteSpecifiquePdc: ContexteSpecifique[]
    ContexteSpecifiqueAcn: ContexteSpecifique[]
    RapportsSelLegacy: RapportsSelLegacy
    DispositifsCreationDesactiveeParBranche : DispositifsCreationDesactiveeParBranche[]
    GrilleRemunerationCPRO: GrilleRemunerationCPRO
    Mamc: Mamc
}

export interface Reference {
    Categorie__c: string
    CodeType__c: string
    IsActive__c: boolean
    IsActiveFO__c: boolean
    Libelle__c: string
    LibelleReduit__c: string
    Priorite__c: boolean
    Id: string
}

export interface Service extends Reference {
    IdFront__c: string
    URL__c: string
    URLRecette__c: string
    ClientId: string
}

export interface ReferenceIDCC extends Reference {
    IDCCBranchesAtlas__c: boolean
    ContributionConventionnelle__c: boolean
    TauxMoins11Salaries__c: number | null
    Taux11a19Salaries__c: number | null
    Taux20a49Salaries__c: number | null
    Taux50a299Salaries__c: number | null
    Taux300EtPlusSalaries__c: number | null
}

export interface ReferenceNAF extends Reference {
    ActifWebAdhesion__c: boolean
}

export interface References {
    Version: number | undefined | null
    REF_ANTENNE: Reference[]
    REF_BRANCHE: Reference[]
    REF_CANAL: Reference[]
    REF_CATEGORIE_ACTION: Reference[]
    REF_CIVILITE: Reference[]
    REF_CLASSE_APPRENTI: Reference[]
    REF_CONDITION_REALISATION: Reference[]
    REF_DEPARTEMENT: Reference[]
    REF_DIPLOME: Reference[]
    REF_EMPLOYEUR_SPECIFIQUE: Reference[]
    REF_ETAT_DOSSIER: Reference[]
    REF_ETAT_DOSSIER_FO: Reference[]
    REF_ETAT_FACTURE: Reference[]
    REF_ETAT_FACTURE_FO: Reference[]
    REF_FORMATION_CERTIFIANTE: Reference[]
    REF_HANDICAP: Reference[]
    REF_MOBILITE: Reference[]
    REF_MODE_VERSEMENT: Reference[]
    REF_MOTIF: Reference[]
    REF_NATIONALITE: Reference[]
    REF_NATURE_CONTRAT: Reference[]
    REF_NIVEAU_DIPLOME: Reference[]
    REF_NIVEAU_FORMATION: Reference[]
    REF_OBJECTIF_GENERAL_FORMATION: Reference[]
    REF_OBJECTIF_GENERAL_OF: Reference[]
    REF_OPCA_BRANCHE: Reference[]
    REF_ORIGINE: Reference[]
    REF_PAYS: Reference[]
    REF_REGIME_COLLECTE: Reference[]
    REF_REGIME_SOCIAL: Reference[]
    REF_REGION: Reference[]
    REF_SEGMENT: Reference[]
    REF_SEXE: Reference[]
    REF_SITE: Reference[]
    REF_SITUATION_ECONOMIQUE: Reference[]
    REF_SITUATION_SALARIE: Reference[]
    REF_SITUATION_HANDICAP: Reference[]
    REF_SOURCE: Reference[]
    REF_SPECIALITE_FORMATION: Reference[]
    REF_SUPPORT: Reference[]
    REF_SUPPORT_FO: Reference[]
    REF_TAUX_TVA: Reference[]
    REF_TITRE: Reference[]
    REF_TYPE_ADRS_DEST: Reference[]
    REF_TYPE_ALERTE_ADH: Reference[]
    REF_TYPE_ALERTE_OF: Reference[]
    REF_TYPE_CONTRAT: Reference[]
    REF_TYPE_COUT: Reference[]
    REF_TYPE_CSP: Reference[]
    REF_TYPE_DEROGATION: Reference[]
    REF_MOTIF_REDUCTION: Reference[]
    REF_TYPE_DOSSIER: Reference[]
    REF_TYPE_EMPLOYEUR: Reference[]
    REF_TYPE_ENTREPRISE: Reference[]
    REF_TYPE_FORMATION: Reference[]
    REF_TYPE_MINIMUM_SOCIAL: Reference[]
    REF_TYPE_MODE_CONTRACTUEL: Reference[]
    REF_TYPE_MODEREGLEMENT: Reference[]
    REF_TYPE_MONTANT: Reference[]
    REF_TYPE_OF: Reference[]
    REF_TYPE_PERE: Reference[]
    REF_TYPE_QUALIFICATION: Reference[]
    REF_TYPE_TRANSAC: Reference[]
    REF_TYPE_PROA: Reference[]
    REF_VOLET: Reference[]
    REF_OBJECTIF_PARCOURS: Reference[]
    REF_CONTEXTE_PARCOURS: Reference[]
    REF_CONTEXTE_SPECIFIQUE: Reference[]
    SEL_SERVICE_EN_LIGNE: Service[]
    REF_STATUT_PERSONNE_ENTREE_FORMATION: Reference[]
    REF_NIVEAU_MAX_CLASSE_ENTREE_FORMATION: Reference[]
    REF_MODALITE_PEDAGOGIQUE:Reference[]
    REF_CAMPUS_TYPE: Reference[]
    REF_CAMPUS_MODALITE: Reference[]
    REF_MOTIF_ADMINISTRATIF: Reference[]
}

export interface Piece {
    IsActive__c: boolean
    Id: string
    Libelle__c: string
    LibelleReduit__c: string
    ObligatoireWeb__c: boolean
    Priorite__c: boolean
    SupportWeb__c: string[]
    SupportFrontWeb__c: string[]
    TypeFormation__c: boolean
    Description__c?: string
}

export interface Commune {
    Id: string
    CdInsee__c: string
    CdCodex__c: string
    Nom__c: string
    Region__c: string
    Departement__c: string
}

export async function getReferences(): Promise<References> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<References>('/refs', requestConfig)
    return data
}

export async function getReferencesIdcc(): Promise<ReferenceIDCC[]> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<ReferenceIDCC[]>('/refs/idcc', requestConfig)
    return data
}

export async function getReferencesPieces(): Promise<Piece[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<Piece[]>('/refs/piece', requestConfig)
    return data
}

export async function getReferencesCommune(recherche: string): Promise<Commune[]> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.append('par-page',(100).toFixed(0))
    requestConfig.params = params
    
    const { data } = await axios.get<Commune[]>('/refs/commune/recherche?recherche=' + encodeURIComponent(recherche), requestConfig)
    return data;
}

export async function getConfig(app?: AppType): Promise<Config> {
    const requestConfig = await getRequestConfig()
    requestConfig.baseURL = import.meta.env.REACT_APP_CONFIG_ENDPOINT
    const { data } = await axios.get<Config>(app === AppType.Prestataire ? '/config-prestataire' : '/config', requestConfig)
    return data
}

export async function getFonctions(): Promise<Reference[]> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<Reference[]>('/refs/fonction', requestConfig)
    return data
}

export async function getReferencesNAF(): Promise<ReferenceNAF[]> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<ReferenceNAF[]>('/refs/naf/webadhesion', requestConfig)
    return data
}
