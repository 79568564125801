import { BlockValue } from 'pages/dossier/cerfa/CerfaDpc'
import { formatAdresse } from 'components/format/Format'
import { get } from 'lodash'
import { Adresse, BaseDossier } from 'slices/dossierCommon'


interface ExportAdresseFieldProps {
    label?: string
    exportLabel?: string
    cell?: boolean
    name?: string
    value?: Adresse | null
    root?: BaseDossier | null
}

export default function ExportAdresseField(props: ExportAdresseFieldProps) {
    const adresse = props.value ?? (props.name && props.root ? get(props.root, props.name) : null)
    
    let values = (adresse?.appartement ? [
        { label: 'Appartement', value: adresse?.appartement }] : []
    ).concat(adresse?.batiment ? [
        { label: 'Bâtiment', value: adresse?.batiment }] : []
    ).concat([
        { label: '', value: adresse?.adresse },
    ]).concat([
        { label: '', value: formatAdresse(null, adresse?.codePostal, adresse?.commune) },
    ]).concat(adresse?.complement ? [
        { label: 'Complement', value: adresse?.complement }] : []
    )
    if (props.cell) {
        values = values.filter(v => v.value)
    }

    return <BlockValue value={{
        cell: props.cell,
        title: props.exportLabel ?? props.label,
        values,
    }} />
}