import { downloadDossierDoc } from 'api/dossierAPI'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasColumns, AtlasFlex, AtlasHeading, AtlasInfo } from 'atlas-ds'
import PageHeader from "components/PageHeader"
import TelechargerDoc from 'components/docs/TelechargerDoc'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { DossierAF } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'
import { BaseDossierComponentProps, DossierIdParams, useDossier } from './useDossier'


const DOCTYPE_ETAT_MODIF_MOD = 'ETAT MODIFICATIONS MODIFICATION'
const DOCTYPE_ETAT_MODIF_COR = 'ETAT MODIFICATIONS CORRECTION'
const DOCTYPE_ETAT_MODIF_AVT = 'ETAT MODIFICATIONS AVENANT'

const DOCTYPE_ETAT_MODIF = [DOCTYPE_ETAT_MODIF_MOD, DOCTYPE_ETAT_MODIF_COR, DOCTYPE_ETAT_MODIF_AVT]


export default function DossierConfirmationForm(props: BaseDossierComponentProps) {
        
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { dossierId } = useParams<DossierIdParams>()
    const { dossier, loading } = useDossier<DossierAF>(props.metadata.api, dossierId, true, true)
    const [retry, setRetry] = useState(0)
    const [piecesError, setPiecesError] = useState(false)

    useEffect(() => { 
        scroll.scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const nouvelleDemande = () => {
        history.push(metadata.pathname)
    }

    const gotoDetail = () => {
        // localStorage.removeItem(keyModification)
        history.push('/detail-dossier-contrat/'+ dossier?.Id)
    }

    const metadata = props.metadata

    const pieces = dossier?.pieces // ?.sort((p1, p2) => p1.dateAjout > p2.dateAjout ? 1 : -1)

    const etatModif = props.metadata.api.isEdit ? pieces?.find(p => DOCTYPE_ETAT_MODIF.includes(p.type)) : null 
 
    useEffect(() => { 
        scroll.scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        if (!loading && dossier && dossier.pieces && dossierId) {
            // Verifie qu'on a bien toutes les pieces en ged
            const docTypes = [DOCTYPE_ETAT_MODIF_MOD, DOCTYPE_ETAT_MODIF_COR, DOCTYPE_ETAT_MODIF_AVT]
                .concat(props.metadata.exportProps.map(ep => ep.documentType))
            const missing = dossier.pieces?.find(p => docTypes.includes(p.type) && !p.uploadedToGed)
            if (missing) {
                if (retry >= 5) {
                    setPiecesError(true)
                } else {
                    setRetry(retry+1)
                    setTimeout(() => {
                        dispatch(dossierSliceActions.loadWithPieces({ api: props.metadata.api, dossierId }))
                    }, 5000)
                }
            } else {
                setRetry(0)
                setPiecesError(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.metadata.api, dossierId, dossier?.pieces, loading])

    const title = typeof props.metadata.title === 'function' ? props.metadata.title(dossier) : props.metadata.title

    const loadingOrRetrying = loading || (retry > 0 && !piecesError)

    return <>
        <PageHeader>{ `${title} <br />${ dossierId }` }</PageHeader>

        <AtlasColumns>
            <div>
                <AtlasHeading tag="h2" size="m">Confirmation</AtlasHeading>
                <AtlasFlex column gap="l" alignItems="start">
                    { metadata.api.isEdit ? <>
                        <p>Votre modification du contrat {dossierId} est bien envoyée pour traitement. Vous pouvez la suivre sur la page du détail de votre dossier</p>

                        { !loading && piecesError && <AtlasInfo type="warning" title="Documents non disponibles">
                            La demande de prise en charge et les documents associés ne sont pas encore disponibles en téléchargement. Vous pourrez les retrouver dans le détail du dossier d'ici quelques minutes.
                        </AtlasInfo> }

                        <AtlasFlex gap="xl" wrap>
                            { props.metadata.exportProps.map(ep => {
                                const piece = pieces?.find(p => p.type === ep.documentType && p.uploadedToGed)
                                return <TelechargerDoc key={ 'telecharger' + ep.documentType } level={ 2 } 
                                    label={ ep.label ?? "Télécharger la demande de prise en charge" } 
                                    disabled={ !piece || loadingOrRetrying }
                                    spinning={ loadingOrRetrying } 
                                    downloadDoc={() => downloadDossierDoc(piece!, dossier!)} />
                            }) }                            

                            <TelechargerDoc key="telechargerDocEdit" level={ 2 } 
                                label="Télécharger l'etat des modifications" 
                                disabled={ !etatModif || !etatModif.uploadedToGed || loadingOrRetrying }
                                spinning={ loadingOrRetrying } 
                                downloadDoc={() => downloadDossierDoc(etatModif!, dossier!)} />

                            <AtlasButton onClick={gotoDetail}>Retour au détail du dossier</AtlasButton>
                        </AtlasFlex>
                    </> : <>
                        <p>Votre dossier numéro { dossierId } a bien été créé et pris en compte dans nos systèmes.</p>

                        { !loading && piecesError && <AtlasInfo type="warning" title="Documents non disponibles">
                            La demande de prise en charge et les documents associés ne sont pas encore disponibles en téléchargement.
                            Vous pourrez les retrouver dans le détail du dossier d'ici quelques minutes.
                        </AtlasInfo> }

                        <AtlasFlex gap="xl" wrap>
                            { props.metadata.exportProps.map(ep => {
                                const piece = pieces?.find(p => p.type === ep.documentType && p.uploadedToGed)
                                return <TelechargerDoc key={ 'telecharger' + ep.documentType } level={ 2 } 
                                    label={ ep.label ?? "Télécharger la demande de prise en charge" }
                                    disabled={ !piece || loadingOrRetrying }
                                    spinning={ loadingOrRetrying } 
                                    downloadDoc={() => downloadDossierDoc(piece!, dossier!)} />
                            }) }                            

                            <AtlasButton onClick={ nouvelleDemande }>Faire une nouvelle demande</AtlasButton>
                        </AtlasFlex>

                        { props.metadata.confirmationContent ? props.metadata.confirmationContent({ metadata: props.metadata, dossier }) : null }
                    </> }
                </AtlasFlex>
            </div>
        </AtlasColumns>
    </>
}
