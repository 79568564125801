import axios from 'axios'
import { axiosSalesforce, getRequestConfig } from './baseAPI';


export interface ChildCategorie {
    childCategories: string
    label: string
    name: string
    url: string
}

export interface ChildCategories {
    childCategories: ChildCategorie[]
}


// QC => Question Categories
export async function getQuestionCategories(source: string): Promise<ChildCategories> {
    const requestConfig = await getRequestConfig();

    const params = new URLSearchParams()
    params.set('sObjectName', 'KnowledgeArticleVersion')
    params.set('topCategoriesOnly', 'false')
    requestConfig.params = params

    const { data } = await axiosSalesforce.get<ChildCategories>(
        'services/data/v49.0/support/dataCategoryGroups/' + source + '/dataCategories/Tout',
        requestConfig
    )
    return data
}

// QC => Question Categories
export async function getContenuArticle(source: string): Promise<ContenuAnswers> {
    const requestConfig = await getRequestConfig();

    const { data } = await axiosSalesforce.get<ContenuAnswers>(
        '/services/data/v49.0/support/knowledgeArticles/' + source,
        requestConfig
    )

    return data
}


export interface Article {
    articleNumber: string
    categoryGroups: categoryGroups[]
    downVoteCount: BigInteger
    id: string
    lastPublishedDate: string
    summary: string
    title: string
    upVoteCount: BigInteger
    url: string
    urlName: string
    viewCount: BigInteger
    viewScore: Float32Array
}

export interface categoryGroups {
    groupLabel: string
    groupName: string
    selectedCategories: categoryName[]
}

export interface categoryName {
    categoryLabel: string
    categoryName: string
    url: string
}

export interface Articles {
    articles: Article[]
    currentPageUrl: string
    nextPageUrl: string
    pageNumber: BigInteger
}

export interface AutoSuggestResult {
    attributes: Attribute
    Id: string
    Title: string
    UrlName: string
    KnowledgeArticleId: string
}

export interface AutoSuggestResults {
    autoSuggestResults: AutoSuggestResult[]
    hasMoreResults: boolean
}

export interface ArticleSearch {
    // KnowledgeArticleId: string
    Title: string
    Summary: string
}

export interface Answer {
    id: string
    title: string
    summary: string
}

export interface DetaiContenuAnswers {
    label: string
    name: string
    value: string
    type: string
}
export interface ContenuAnswers {
    layoutItems: DetaiContenuAnswers[]
}

export async function findAnswers(query: { categorie?: string, childCategorie?: string, search?: string, context?: string } = {}): Promise<Answer[]> {
    if (query.search && query.search !== '')
        return findAnswersBySearch(query.search, query.context)
    return findAnswersByCategorie(query.categorie, query.childCategorie, query.context)
}

export async function findAnswersByCategorie(categorie?: string, childCategorie?: string, context?: string): Promise<Answer[]> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.set('sort', 'ViewScore')

    params.append('channel', context === 'entreprise' ? 'Csp' : 'Prm')

    params.set('pageSize', '20')
    params.set('publishStatus', 'Online')
    params.set('validationStatus', 'Validated')

    if (childCategorie && childCategorie !== '')
        params.set('categories', `{${categorie ?? ''}:${childCategorie}}`)
    requestConfig.params = params

    const { data } = await axiosSalesforce.get<Articles>('services/data/v49.0/support/knowledgeArticles', requestConfig)
    return data.articles
}

export async function findAnswersBySearch(search: string, context?: string): Promise<Answer[]> {
    if (!search || search === '')
        return Promise.resolve([])

    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    
    params.set('language', 'fr')
    params.set('publishStatus', 'Online')
    params.append('channel', context === 'entreprise' ? 'Csp' : 'Prm')

    params.set('q', search)
    params.set('limit', '10')
    requestConfig.params = params

    const { data } = await axiosSalesforce.get<AutoSuggestResults>('/services/data/v50.0/search/suggestTitleMatches', requestConfig)
    const promises = data.autoSuggestResults.map(result =>
        axiosSalesforce.get<ArticleSearch>('/services/data/v50.0/sobjects/KnowledgeArticleVersion/' + result.Id)
    )
    const answers: Answer[] = []
    await Promise.all(promises).then(results => results.map(result => result.data)).then(articles =>
        articles.forEach(article =>
            answers.push({ id: (article as any).KnowledgeArticleId, title: article.Title, summary: article.Summary ?? '' })
        )
    )
    return answers
}

export interface Attribute {
    type: string
    url: string
}

export interface SousType {
    label: string
    ID: string
}
export interface Type {
    label: string
    ID: string
    sousType: SousType[]
}

export interface Service {
    label: string
    serviceTel: string
    nomConseiller?: string | null
    numeroTel: string
    optionTel: string
    type: Type[]
}

export interface Services {
    facture: Service
    conseil: Service
    assistance: Service
    cotisation: Service
}

export async function getServices(): Promise<Services> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<{ services: any[] }>(requestConfig.headers['Atlas-Context'] === 'prestataire' ? '/categorisationAide-prestataire' : '/categorisationAide', requestConfig)
    return Object.assign({}, ...data.services)
}

export interface SourceCategorieKnowledge {
    categorieSource: string
    childCategoriesDefaut: string
}
export async function getCategorieDefautKnowledge(): Promise<SourceCategorieKnowledge> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<SourceCategorieKnowledge>('/categorieDefautKnowledge', requestConfig)
    return data
}