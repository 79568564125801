import NumberField from 'components/reform/inputs/NumberField'
import { OptionalNumber } from 'components/yop/constraints'
import CerfaNumberField from 'pages/dossier/cerfa/CerfaNumberField'
import ExportNumberField from 'pages/dossier/export/ExportNumberField'
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapNumberField from 'pages/dossier/recap/RecapNumberField'

export function DurationFieldMetadata(settings: SettingsFieldMetadata<number | null> = {}) {
    return createMetadata(
        {
            yop: OptionalNumber,
            input: props => <NumberField {...props} fractionDigits={1} minimumFractionDigits={0} />,
            recap: RecapNumberField,
            export: ExportNumberField,
            cerfa: CerfaNumberField,
        },
        settings,
    )
}
