import { Simulation, creerDossierDepuisSimulation } from 'api/simulationAPI'
import useDossiersModuleContext from 'app/entreprise/mes-dossiers/useDossiersModuleContext'
import { AtlasButton, AtlasSpacer, MyModal } from 'atlas-ds'
import ErrorBanner from 'components/reform/ErrorBanner'
import { getDossierCreationPath } from 'pages/dossier/useDossierNavigation'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface PriseEnChargeSimulationModalProps {
    simulation: Simulation
    close: () => void
}

export default function PriseEnChargeSimulationModal(props: PriseEnChargeSimulationModalProps) {
    const ctx = useDossiersModuleContext()
    const [createPending, setCreatePending] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState('')

    const history = useHistory()

    const handleCreate = () => {
        setCreatePending(true)
        setErrorMessage('')

        creerDossierDepuisSimulation(props.simulation!.id)
            .then(dossier => {
                const path = getDossierCreationPath(dossier, ctx!)
                history.push(path)
            })
            .catch (() => setErrorMessage("Le dossier n'a pas pu être créé."))
            .finally(() => setCreatePending(false))
    }

    return (
        <MyModal
            label="Prise en charge"
            onClose={ props.close }
            okButton={<AtlasButton
                onClick={ handleCreate }
                spinner={{spinning: createPending}}
                disabled={ createPending }
            >Créer</AtlasButton>}
            cancelButton={<AtlasButton
                onClick={ props.close }
                disabled={ createPending }
            >Annuler</AtlasButton>}
        >
            <p>Voulez-vous vraiment créer un dossier à partir de cette simulation ?</p>

            { errorMessage && <>
                <AtlasSpacer />
                <ErrorBanner title="Erreur de création" message={ errorMessage } />
            </> }
        </MyModal>
    )
}
