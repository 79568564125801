import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasHeading, MyAsideInfo } from 'atlas-ds'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { delaisTraitementDossierActions } from 'slices/delaisTraitementSlice'

interface DelayProps {
    dispositifId?: string
}

export default function DelaiTraitement(props: DelayProps) {

    const dispatch = useAppDispatch()
    
    const { delais } = useSelector((state: RootState) => state.delaisTraitementDossierState)

    useEffect(() => {
        if (!delais)
            dispatch(delaisTraitementDossierActions.load())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const delai = delais?.find((d) => d.DispositifFOIds?.includes(props.dispositifId ?? ''))

    if (!delai)
        return null

    return <div>
        <AtlasHeading tag="h2" size="m">Délai de traitement</AtlasHeading>
        <MyAsideInfo
            label={ `${ delai.DelaiTraitement } jours` }
            content="Délai moyen pour une première analyse de votre dossier."
            iconName="calendar"
            updatedAt={ new Date(delai.DateDerniereModification) }
        />
    </div>
}
