import { useState } from 'react'
import { useSelector } from 'react-redux'
import { AtlasButton } from 'atlas-ds'

import { DISPOSITIF_CONTRAT_PROFESSIONNALISATION } from 'api/references'
import { RootState } from 'app/rootReducer'
import InformationRuptureModal from 'components/annuler/InformationRuptureModal'
import { RuptureFormMetadata, RuptureInfo } from 'pages/dossier/metadata/RuptureFormMetadata'
import { AppType } from 'slices/contextSlice'
import { DossierActionProps } from './DetailDossierContrat'
import { checkDetailAction } from 'pages/detail-common/Detail'

interface DossierInformationRuptureProps<R extends RuptureInfo = RuptureInfo> extends DossierActionProps {
    formMetadata: RuptureFormMetadata<R>
}

export default function DossierInformationRupture<R extends RuptureInfo = RuptureInfo>(props: DossierInformationRuptureProps<R>) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const [showInfoRupture, setShowInfoRupture] = useState(false)
    const { app } = useSelector((state: RootState) => state.contextState)

    return <>
       { (app === AppType.Entreprise || dossier.DispositifFO__c !== DISPOSITIF_CONTRAT_PROFESSIONNALISATION) && <AtlasButton
            id="rupture"
            level={ 2 }
            disabled={ props.formMetadata.disabled ? props.formMetadata.disabled(dossier) : false }
            onClick={() => setShowInfoRupture(true)}
        >Informer la rupture du contrat</AtlasButton> }

        { showInfoRupture && <InformationRuptureModal formMetadata={ props.formMetadata } dossier={ dossier }
            close={ () => setShowInfoRupture(false) } />
        }
    </>
}


