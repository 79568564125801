import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import SelectedText from 'components/reform/SelectedText'
import FiltreSelectField from 'components/recherche/FiltreSelectField'
import { organismesActions } from 'slices/organismesSlice'


export const FiltreOrganisme = (props: { label?: string, withSiret?: boolean }) => {

    const dispatch = useAppDispatch()
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const { fulfilled: organismes } = useSelector((state: RootState) => state.organismesState)
    useEffect(() => {
        if (entreprise)
            dispatch(organismesActions.load(entreprise.SIREN__c))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entreprise])

    const organismesOptions = [{ label: 'Tous', value: '' }].concat(organismes?.map(o => { return { label: `${o.Name} - ${o.BillingCity}`, value: props.withSiret ? o.Id + '/' + o.SIRET__c : o.Id } }) ?? [])
    const label = props.label ?? 'é'.toUpperCase() + 'tablissement'

    return {
        input: <FiltreSelectField key="organisme" name="organisme" label={ label } options={ organismesOptions }/>,
        filtre: (values: any) => <SelectedText key="organisme" name="organisme" label={ label.toLowerCase( ) } options={ organismesOptions } />,
        defaultValue: { organisme: '' },
        initialValue: (query: any) => { 
            return { organisme: query.organisme ?? '' }
        },
        valueToParam: (values: any, params: URLSearchParams) => { 
            params.set('organisme', values.organisme ?? '')
        },
        paramToValue: (params: URLSearchParams) => { 
            return { organisme: params.get('organisme') ?? '' }
        },
    }
}
