import {
    DossierApprentissageDTO, DossierCappModuleDTO, DossierModuleDTO, loadAndMergeDossierPieces, loadDossierApprentissage,
    saveDossierApprentissage, sendDossierApprentissage
} from 'api/dossierAPI'
import { Profil } from 'api/profilAPI'
import {
    DISPOSITIF_CONTRAT_APPRENTISSAGE
} from 'api/references'
import { parseDate } from 'components/format/Format'
import { CheckboxFieldMetadata } from 'components/metadata/BooleanFieldMetadata'
import {
    ObjectFieldMetadata, OptionalObjectFieldMetadata
} from 'components/metadata/HolderFieldMetadata'
import {
    addYears, isAfter
} from 'date-fns'
import { DossierCAPPContratElements } from 'pages/dossier-capp/DossierCAPPContratElements'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierFormationElements } from 'pages/dossier/elements/DossierFormationElements'
import { DossierMaitresElements } from 'pages/dossier/elements/DossierMaitresElements'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import {
    DossierRecapitulatifElements, dossierRecapitulatifInitializer, DossierRecapitulatifPreElements,
    onDossierRecapitulatifSubmit
} from 'pages/dossier/elements/DossierRecapitulatifElements'
import {
    DossierSalarieElements
} from 'pages/dossier/elements/DossierSalariesElements'
import {
    mapCoreDTOToBaseDossier,
    mapDTOSimpleFieldsToModel, mapDTOToModel, mapModelToDTO, MappingContext, mapSimpleFieldsToDTO,
    simpleMapping
} from 'pages/dossier/metadata/DossierMapping'
import {
    mapDTOToEmployeur, mapEmployeurToDTO,
    SECTION_EMPLOYEUR_CONTACT, SECTION_EMPLOYEUR_EXTRA
} from 'pages/dossier/metadata/EmployeurMetadata'
import {
    FieldMetadata,
    FormMetadata, FormStep, mapDTOToPointsReserve,
    ObjectMetadata, registerMetadata, validatePointsReserve
} from 'pages/dossier/metadata/FormMetadata'
import {
    mapDTOToCFA, SECTION_CFA, SECTION_MODULE_AGORA
} from 'pages/dossier/metadata/ModulesMetadata'
import {
    mapDTOToStagiaire, mapStagiaireToDTO, SECTION_SALARIE_AGORA
} from 'pages/dossier/metadata/SalariesMetadata'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import RecapCAPPContrat from 'pages/dossier/recap/RecapCAPPContrat'
import RecapCAPPFormation from 'pages/dossier/recap/RecapCAPPFormation'
import RecapCAPPMaitres from 'pages/dossier/recap/RecapCAPPMaitres'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapStagiaire from 'pages/dossier/recap/RecapStagiaire'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { AppType } from 'slices/contextSlice'
import {
    createDossierStagiaireRepresentant, DossierCFA,
    normalizePiecesDTO
} from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'

import {
    Yop
} from '@dsid-opcoatlas/yop'

import { AtlasInfo } from 'atlas-ds'
import { createCerfaCAPP } from 'pages/dossier-capp/cerfa/CerfaCAPP'
import { DossierCentreFormationElements } from 'pages/dossier/elements/DossierCentreFormationElements'
import {
    CAPPContratMetadata, CAPPRepresentantMetadata,
    dossierCAPPContratInitializer, SECTION_CAPP_CONTRAT, SECTION_CAPP_CONTRAT_AVANTAGES,
    SECTION_CAPP_CONTRAT_REPRESENTANT, SECTION_CAPP_CONTRAT_SALAIRE
} from './CAPPContratMetadata'
import { CAPPEmployeurMetadata } from './CAPPEmployeurMetadata'
import { CAPPFormationMetadata, SECTION_FORMATION_ORGA } from './CAPPFormationMetadata'
import { CAPPMaitre1Metadata, CAPPMaitre2Metadata } from './CAPPMaitresMetadata'
import { createDossierCAPP, createDossierCAPPFormation, createDossierCAPPMaitre, createDossierCAPPStagiaire, DossierCAPP, DossierCAPPContrat, DossierCAPPFormation, DossierCAPPStagiaire } from './CAPPModel'
import { CAPPStagiaireMetadata } from './CAPPStagiaireMetadata'
import DossierCAPPConfirmation from './DossierCAPPConfirmation'
import DossierCAPPInformationsModal from './DossierCAPPInformationsModal'
import { dossierCAPPPointsReserve } from './Recos'

export function mapDTOToDossierCAPP<D extends DossierCAPP>(metadata: FormMetadata<D>, dossierDTO: DossierApprentissageDTO): D {

    const dossier = metadata.api.create()
    mapCoreDTOToBaseDossier(dossier, dossierDTO)

    const context: MappingContext = {
        metadata: metadata.fields,
        editMode: metadata.api.isEdit ?? false,
        dossier,
        dossierDTO,
    }

    // Employeur
    mapDTOToEmployeur(context, (metadata.fields.employeur as any).fields, dossier.employeur, dossierDTO)

    // Stagiaire
    const stagiaireDTO = dossierDTO.Stagiaires && dossierDTO.Stagiaires.length > 0 ? dossierDTO.Stagiaires[0] : null
    if (stagiaireDTO) {
        dossier.stagiaire = metadata.api.createSalarie() as DossierCAPPStagiaire
        mapDTOToStagiaire(context, (metadata.fields.stagiaire as any).fields, dossier.stagiaire, stagiaireDTO)

        // Représentant légal
        if (stagiaireDTO.NomRepresentantLegal__c || stagiaireDTO.PrenomRepresentantLegal__c) {
            dossier.representant = createDossierStagiaireRepresentant()
            mapDTOToModel(context, (metadata.fields.representant as any).fields, dossier.representant, stagiaireDTO)
        }
    }

    if (dossierDTO.NomTuteur__c) {
        if (dossier.maitre1 == null)
            dossier.maitre1 = createDossierCAPPMaitre()
        dossier.maitre1.IdContact = dossierDTO.Tuteur__c ?? null
        mapDTOToModel(context, (metadata.fields.maitre1 as any).fields, dossier.maitre1, dossierDTO)
    }
    if (dossierDTO.NomTuteur__c && dossierDTO.NomTuteur2__c) {
        if (dossier.maitre2 == null)
            dossier.maitre2 = createDossierCAPPMaitre()
        dossier.maitre2.IdContact = dossierDTO.Tuteur2__c ?? null
        mapDTOToModel(context, (metadata.fields.maitre2 as any).fields, dossier.maitre2, dossierDTO, undefined, 1)
    }

    // Simple fields
    mapDTOSimpleFieldsToModel(context, metadata.fields, dossier, dossierDTO)

    const moduleDTO = dossierDTO.Modules?.[0]
    if (moduleDTO) {
        if (dossier.formation == null)
            dossier.formation = createDossierCAPPFormation()
        dossier.formation.IdHeroku = moduleDTO?.IdHeroku
        dossier.formation.cfa = mapDTOToCFA(moduleDTO)
        if (dossier.formation.cfa) {
            dossier.formation.cfa.entreprise = dossierDTO.CFAEntreprise__c ?? false
        }
        // Formation
        if (moduleDTO) {
            mapDTOToModel(context, (metadata.fields.formation as any).fields, dossier.formation, moduleDTO)
            mapDTOToModel(context, (metadata.fields.formation as any).fields, dossier.formation, dossierDTO, 'dossier')
            mapDTOToModel(context, (metadata.fields.contrat as any).fields, dossier.contrat, moduleDTO, 'module')
        }
    }

    // Contrat
    mapDTOToModel(context, (metadata.fields.contrat as any).fields, dossier.contrat, dossierDTO)

    // Signature
    mapDTOToModel(context, (metadata.fields.signature as any).fields, dossier.signature, dossierDTO)

    // Pièces
    dossier.pieces = normalizePiecesDTO(dossierDTO.Pieces)

    // Conformité
    dossier.indicateurConformite = dossierDTO.IndicateurConformite__c ?? null
    
    // Points de réserve
    dossier.pointsReserve = mapDTOToPointsReserve(dossierDTO.PointsReserve__c ?? null)
    validatePointsReserve(dossier, dossierCAPPPointsReserve)

    return dossier
}


export function mapDossierCAPPToDTO<D extends DossierCAPP>(metadata: FormMetadata<D>, dossier: D): DossierApprentissageDTO {
    const dto = {
        IdHeroku: dossier.IdHeroku ?? null,
        NumeroDossier__c: dossier.NumeroDossier__c ?? null,
        TypeDossier__c: null,   // Non utilisé
        DispositifFO__c: dossier.DispositifFO__c ?? null,

        // Employeur
        ...mapEmployeurToDTO((metadata.fields.employeur as any).fields, dossier, dossier.employeur),
        
        // Salaries
        Stagiaires: dossier.stagiaire ?
            [ mapStagiaireToDTO((metadata.fields.stagiaire as any).fields, dossier, dossier.stagiaire) ]
            : null,

        // Maitres
        Tuteur__c: dossier.maitre1?.IdContact ?? null,
        ...mapModelToDTO((metadata.fields.maitre1 as any).fields, dossier, dossier.maitre1),
        Tuteur2__c: dossier.maitre2?.IdContact ?? null,
        ...mapModelToDTO((metadata.fields.maitre2 as any).fields, dossier, dossier.maitre2),

        // EmployeurAttesteSurHonneurTuteur__c: dossier.eligibilite ?? null,
        // EmployeurAttestePiecesJustificatives__c: dossier.attestationPieces ?? null,

        // Formation
        Intitule__c: dossier.formation?.diplome?.intitulePrecis ?? null,
        Modules: dossier.formation ? [{
            ...mapFormationToDTO((metadata.fields.formation as any).fields, dossier),
            ...mapModelToDTO((metadata.fields.contrat as any).fields, dossier, dossier.contrat, {}, 'module'),
        }] : null,
        CFAEntreprise__c: dossier.formation?.cfa?.entreprise ?? null,
        SpecialiteFormation__c: null,
        CoutPedagogique__c: dossier.formation?.organismeFormationCout ?? null,
        MobiliteLieu__c: null, // Laisser à null
      
        Remunerations: [],

        // Signature
        ...mapModelToDTO((metadata.fields.signature as any).fields, dossier, dossier.signature),
    } as DossierApprentissageDTO

    // Formation niveau dossier
    mapModelToDTO((metadata.fields.formation as any).fields, dossier, dossier.formation, dto, 'dossier')
    // Contrat niveau dossier
    mapModelToDTO((metadata.fields.contrat as any).fields, dossier, dossier.contrat, dto)

    // Champs simple (eligibilite, attestation, etc.)
    mapSimpleFieldsToDTO(metadata.fields, dossier, dossier, dto)

    if (dto.Stagiaires && dossier.representant) {
        // Représentant légal
        mapModelToDTO((metadata.fields.representant as any).fields, dossier, dossier.representant, dto.Stagiaires[0])
    }
    
    return dto
}


function mapFormationToDTO(moduleMetadata: ObjectMetadata<DossierCAPPFormation>, dossier: DossierCAPP): DossierModuleDTO | null {
    const cfa = dossier.formation?.cfa

    const dto: Partial<DossierCappModuleDTO> = {
        IdHeroku: dossier.formation?.IdHeroku ?? null,
        EtablissementOFId__c: cfa?.EtablissementOFId__c ?? null,
        CodeEtablissementOF__c: cfa?.CodeEtablissementOF__c ?? null,
        EtatEtablissementOF__c: cfa?.etat ?? null,
        // TypeFormation__c: module?.typeFormation ?? null,
        Principal__c: cfa ? true : null,
        NomOF__c: cfa?.nom ?? null,
        SiretOF__c: cfa?.siret ?? null,
        Adresse1OF__c: cfa?.appartement ?? null,
        Adresse2OF__c: cfa?.batiment ?? null,
        Adresse3OF__c: cfa?.adresse ?? null,
        Adresse4OF__c: cfa?.complement ?? null,
        CodePostalOF__c: cfa?.codePostal ?? null,
        CommuneOF__c: cfa?.commune ?? null,
        NdaOF__c: cfa?.numeroDA ?? null,
        UaiOF__c: cfa?.numeroUAI ?? null,
        SubrogationPaiement__c: true
    }

    return mapModelToDTO(moduleMetadata, dossier, dossier.formation, dto)
}


export function apiDossierCAPP<D extends DossierCAPP>(
    metadata: () => FormMetadata<D>, 
    create: (storedState?: D | null) => D, 
    ): DossierAPI<D> { return {

    create,
    createSalarie: createDossierCAPPStagiaire,
    createTuteur: createDossierCAPPMaitre,
    load: async (dossierId: string, storedState?: D | null) => {
        const dossierDTO = await loadDossierApprentissage(dossierId)
        const loadedDossier = mapDTOToDossierCAPP(metadata(), dossierDTO)
        return loadedDossier
    },
    loadWithPieces: async (dossierId: string, storedState?: D | null) => {
        const dossierDTO = await loadDossierApprentissage(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
            dossierDTO.Pieces.forEach(piece => {
                if (piece.type === 'CONVENTION DE FORMATION')
                    piece.info = '(signée)'
            })
        }
        const loadedDossier = mapDTOToDossierCAPP(metadata(), dossierDTO)
        return loadedDossier
    },
    save: async (dossier: D) => {
        const dossierDTO = mapDossierCAPPToDTO(metadata(), dossier)
        const savedDossierDTO = await saveDossierApprentissage(dossierDTO)
        const savedDossier = mapDTOToDossierCAPP(metadata(), savedDossierDTO)
        return savedDossier
    },
    send: async (dossier: D) => {
        const dossierDTO = mapDossierCAPPToDTO(metadata(), dossier)
        const sentDossierDTO = await sendDossierApprentissage(dossierDTO)
        return mapDTOToDossierCAPP(metadata(), sentDossierDTO)
    },
    postSend: async (dossier: D) => {
        await postSendDossier(metadata(), dossier)
    },
}}



type DossierCAPPLight = Omit<DossierCAPP, 'pieces' | 'pointsReserve' | 'indicateurConformite'>
const CAPPMetadataLight: ObjectMetadata<DossierCAPPLight> = {
    employeur: ObjectFieldMetadata(CAPPEmployeurMetadata),
    stagiaire: ObjectFieldMetadata(CAPPStagiaireMetadata),
    maitre1: ObjectFieldMetadata(CAPPMaitre1Metadata),
    maitre2: OptionalObjectFieldMetadata(CAPPMaitre2Metadata),
    eligibilite: CheckboxFieldMetadata({
        props: {
            label: "L'employeur atteste sur l'honneur que le maître d'apprentissage répond à l'ensemble des critères d'éligibilité à cette fonction",
            tooltip: TOOLTIPS.capp.maitre.eligibilite,
            full: true
        },
        mapping: simpleMapping('EmployeurAttesteSurHonneurTuteur__c'),
    }).required().mutate(s => s.oneOf([true], "Veuillez cocher cette case pour confirmer l'éligibilité des maîtres d'apprentissage")),
    formation: ObjectFieldMetadata(CAPPFormationMetadata),
    contrat: ObjectFieldMetadata(CAPPContratMetadata),
    representant: OptionalObjectFieldMetadata(CAPPRepresentantMetadata),
    attestationPieces: CheckboxFieldMetadata({
        mapping: simpleMapping('EmployeurAttestePiecesJustificatives__c'),
    }).required().mutate(s => s.oneOf([true], 'Veuillez cocher cette case pour confirmer que toutes les pièces justificatives nécessaires sont présentes')),
    signature: ObjectFieldMetadata(signatureMetadata),
}
export const CAPPMetadata = CAPPMetadataLight as ObjectMetadata<DossierCAPP>

function dossierCAPPStagiaireInitializer(dossier: DossierCAPP, metadata: FormMetadata<DossierCAPP>, _: Profil | null, step: FormStep<DossierCAPP>) {
    if (dossier.stagiaire == null)
        dossier.stagiaire = createDossierCAPPStagiaire()
    return dossier
}

function dossierCAPPFormationInitializer(dossier: DossierCAPP) {
    if (dossier.formation === null)
        dossier.formation = createDossierCAPPFormation()

    // // Force l'affichage du centre de formation à "Nouveau"
    // if (dossier.formation.cfa === null) {
    //     dossier.formation.cfa = createDossierCFA()
    //     dossier.formation.cfa.EtablissementOFId__c = null
    // }

    // // Force l'affichage de la formation à "Nouveau"
    // if (dossier.formation.diplome === null) {
    //     dossier.formation.diplome = createDossierDiplome()
    //     dossier.formation.diplome.IdFormation = null
    // }
    
    return dossier
}

export const DOCTYPE_CERFA_CAPP = 'CERFA'
export const DOCNAME_CERFA_CAPP = `${ DOCTYPE_CERFA_CAPP }-Apprentissage.pdf`


export const MetadataCAPP: FormMetadata<DossierCAPP> = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_CONTRAT_APPRENTISSAGE,
    title: "Transmission d'un contrat d'apprentissage",
    pathname: '/nouveau-dossier-apprentissage',
    steps: [
        { 
            title: 'Employeur',
            rubrique: 'EMPLOYEUR',
            sections: [ SECTION_EMPLOYEUR_EXTRA, SECTION_EMPLOYEUR_CONTACT ],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            preFormContent: props => {
                return props.dossier?.DispositifFO__c == null || props.dossier?.NumeroDossier__c != null ? null : <DossierCAPPInformationsModal />
            },
            formContent: props => <DossierEmployeurElements {...props} label="Sélectionner l'établissement d'exécution du contrat" />,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Apprenti(e)',
            rubrique: 'APPRENTI',
            sections: [ SECTION_SALARIE_AGORA ],
            recap: RecapStagiaire,
            initializer: dossierCAPPStagiaireInitializer,
            yopPath: 'stagiaire',
            formContent: props => <DossierSalarieElements { ...props } selectLabel="Choix de l'apprenti(e)" />,
        }, {
            title: "Maître d'apprentissage",
            rubrique: 'MAITRE',
            sections: [],
            recap: RecapCAPPMaitres,
            yopPath: ['maitre1', 'maitre2', 'eligibilite'],
            yopPathSchemaAppender: (s, path) => path === 'maitre1' ? s.required() : s.defined(),
            formContent: DossierMaitresElements,
        }, {
            title: 'Centre de formation',
            rubrique: 'FORMATION',
            initializer: dossierCAPPFormationInitializer,
            sections: [ SECTION_CFA ],
            yopSchema: (fmd: FormMetadata<DossierCAPP>) => Yop.object({ 
                formation: Yop.object({ cfa: ((fmd.fields.formation as any).fields.cfa as FieldMetadata<DossierCFA>).yop }) 
            }),
            formContent: DossierCentreFormationElements,
        }, {
            title: 'Formation',
            rubrique: 'FORMATION',
            initializer: dossierCAPPFormationInitializer,
            sections: [ SECTION_FORMATION_ORGA, SECTION_MODULE_AGORA ],
            recap: RecapCAPPFormation,
            yopPath: 'formation',
            formContent: DossierFormationElements,
        }, {
            title: 'Contrat',
            rubrique: 'CONTRAT',
            initializer: dossierCAPPContratInitializer,
            sections: [ SECTION_CAPP_CONTRAT, SECTION_CAPP_CONTRAT_SALAIRE, SECTION_CAPP_CONTRAT_AVANTAGES, SECTION_CAPP_CONTRAT_REPRESENTANT ],
            recap: RecapCAPPContrat,
            yopPath: ['contrat', 'representant'],
            yopPathSchemaAppender: (s, path) => {
                if (path === 'representant') {
                    return s.requiredIf<DossierCAPPContrat, DossierCAPP>(ctx => {
                        const dateNaissance = parseDate(ctx.root?.stagiaire?.dateNaissance)
                        const dateConclusion = parseDate(ctx.root?.contrat?.dateConclusion)
                        if (!dateNaissance || !dateConclusion) {
                            return false
                        }
                        return isAfter(addYears(dateNaissance, 18), dateConclusion)
                    })
                } else {
                    return s.required()
                }
            },
            formContent: DossierCAPPContratElements,
        }, {
            title: 'Pièces',
            rubrique: 'PIECES',
            sections: [],
            recap: RecapPieces,
            yopPath: 'attestationPieces',
            formContent: props => <DossierPiecesElements { ...props }
                documentTypeFilter={ docType => docType.nom !== 'CERFA' && docType.nom !== 'AVENANT AU CONTRAT' }
                warningMessage={
                    <AtlasInfo type="warning" title="Un CERFA sera automatiquement généré et joint à votre demande">
                        Merci de ne pas ajouter le cerfa original signé dans les pièces. En effet, les informations transmises sur myAtlas sont réputées conformes au contrat conclu avec l'apprenti(e).
                    </AtlasInfo>
                } />,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
                attestationPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            rubrique: 'RECAP',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
                excludePointsReserve: true,
            },
        },
    ],
    confirmationContent: DossierCAPPConfirmation,
    api: apiDossierCAPP(() => MetadataCAPP, createDossierCAPP),
    fields: CAPPMetadata,
    exportProps: [{
        label: 'Télécharger le Cerfa',
        exportDossier: async (metadata, dossier) => createCerfaCAPP(metadata, dossier),
        documentType: DOCTYPE_CERFA_CAPP,
        documentName: DOCNAME_CERFA_CAPP,
    }],
})
