import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import DetailVersement from './DetailVersement'
import DetailVersementVV from './DetailVersementVV'
import MesVersements from './MesVersements'
import VersementConventionnel from './VersementConventionnel'
import VersementVolontaire from './VersementVolontaire'
// import DetailVersement from './DetailVersement'

const versementsModule = () => {
    return {
        id: 'mes-versements',
        isEnabled: () => import.meta.env.REACT_APP_MODULES?.includes('versements'),
        routes: [
            <AppRoute key="mes-versements1" path="/mes-versements" component={ MesVersements } label="Versements & Contributions" menuIcon="versements" />,
            <AppRoute key="versement-conventionnel" path="/versement-conventionnel" component={ VersementConventionnel } label="Versement conventionnel" />,
            <AppRoute key="versement-volontaire" path="/versement-volontaire" component={ VersementVolontaire } label="Versement volontaire" />,
            <AppRoute key="detail-versement" path="/detail-versement/:idversement" component={ DetailVersement } />,
            <AppRoute key="detail-versementv" path="/detail-versementv/:idversement" component={ DetailVersementVV } />,
        ],
    } as AppModule
}

export default versementsModule