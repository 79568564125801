import { DetailFormationCampus, getFormation } from 'api/formationAPI'
import { TYPE_STAGE_INTER, TYPE_STAGE_INTRA } from 'api/references'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasHeading, AtlasHtml, AtlasValue, AtlasValues, MyContainer, MyTags } from 'atlas-ds'
import { formatCurrency, formatNumber, formatRef } from 'components/format/Format'
import { Location } from 'history'
import DetailCampus from 'pages/detail-common/DetailCampus'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import DetailFormationForm from './DetailFormationForm'
import DetailFormationSessions from './DetailFormationSessions'

export const detailFormationRefreshEvent = "DetailFormation:Refresh"
export const detailFormationDocumentsRefreshEvent = "DetailFormation:DocumentsRefresh"

export default function DetailFormation() {

    const breadcrumb = (location: Location<any>, formation: DetailFormationCampus | null) => [
        { title: 'Formations', pathname: '/mes-formations', search: '' },
        { title: formation?.Libelle__c ?? 'Formation et sessions', pathname: location.pathname, search: location.search }
    ]

    const history = useHistory()

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const [editing, setEditing] = useState(false)

    const ajouterSessionDisabled = (formation: DetailFormationCampus | null) => editing

    const getDetailFormation = (formation: DetailFormationCampus | null) => {

        const arrayRegions = (formation?.RegionAutorisee__c?.split(';') ?? []).map(region => formatRef(refs?.REF_REGION, region)).sort()
        const arrayModalites = (formation?.Modalite__c?.split(';') ?? []).map(modalite => formatRef(refs?.REF_CAMPUS_MODALITE, modalite)).sort()
    
        const interInitial = formation?.CoutInter__c ? <small>(initial : {formatCurrency(formation?.CoutInter__c)})</small> : ''
        const intraInitial = formation?.CoutIntra__c ? <small>(initial : {formatCurrency(formation?.CoutIntra__c)})</small> : ''
        const certificationInitial = formation?.CoutCertification__c ? <small>(initial : {formatCurrency(formation?.CoutCertification__c)})</small> : ''
        const supportInitial = formation?.CoutSupportCours__c ? <small>(initial : {formatCurrency(formation?.CoutSupportCours__c)})</small> : ''

        const contenuForm = <DetailFormationForm formation={ formation } setEditing={ setEditing } />

        const getInterIntraInfos = (): JSX.Element => {
            const interInfos = <AtlasValues.Grid>
                <AtlasValue label="Prix Inter Applicable">
                    {import.meta.env.REACT_APP_ALLOW_REVALORISATION_PRIX !== 'false' ? <>{formatCurrency(formation?.CoutinterEtrRevise__c ?? 0)} {interInitial}</> : formatCurrency(formation?.CoutInter__c)}
                </AtlasValue>
                <AtlasValue label="Nombre de stagiaires Inter">
                    Entre {formatNumber(formation?.Module?.StagiaireMinInter__c)} et {formatNumber(formation?.Module?.StagiaireMaxInter__c)}
                </AtlasValue>
            </AtlasValues.Grid>

            const intraInfos = <AtlasValues.Grid>
                <AtlasValue label="Prix Intra Applicable">
                    {import.meta.env.REACT_APP_ALLOW_REVALORISATION_PRIX !== 'false' ? <>{formatCurrency(formation?.CoutintraEtrRevise__c ?? 0)} {intraInitial}</> : formatCurrency(formation?.CoutIntra__c)}
                </AtlasValue>
                <AtlasValue label="Nombre de stagiaires Intra">
                    Entre  {formatNumber(formation?.Module?.StagiaireMinIntra__c)} et {formatNumber(formation?.Module?.StagiaireMaxIntra__c)}
                </AtlasValue>
            </AtlasValues.Grid>

            const certifSupportInfos = <AtlasValues.Grid>
                {formation?.CoutCertificationRevise__c ? <AtlasValue label="Coût de la certification applicable">
                    {formatCurrency(formation?.CoutCertificationRevise__c ?? 0)} {certificationInitial}
                </AtlasValue> : <></>}
                {formation?.CoutSupportDeCoursRevise__c ? <AtlasValue label="Coût du support de cours applicable">
                    {formatCurrency(formation?.CoutSupportDeCoursRevise__c ?? 0)} {supportInitial}
                </AtlasValue> : <></>}
            </AtlasValues.Grid>

            switch (formation?.Type__c) {
                case TYPE_STAGE_INTER:
                    return interInfos
                case TYPE_STAGE_INTRA:
                    return intraInfos
                default:
                    return <>
                        {interInfos}
                        {intraInfos}
                        {import.meta.env.REACT_APP_ALLOW_REVALORISATION_PRIX !== 'false' && certifSupportInfos}
                    </>
            }
        }

        return [
            <div key="details" id="details">
                <AtlasHeading tag="h2" size="m">Informations générales</AtlasHeading>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValue label="Présentation">
                            <AtlasHtml>{formation?.Module?.ObjectifModule__c}</AtlasHtml>
                        </AtlasValue>
                        <AtlasValues.Grid>
                            <AtlasValue label="Modalités">
                                <MyTags>
                                    { arrayModalites && (arrayModalites ?? []).map((modalite) =>
                                        <MyTags.Item key={modalite}>{ modalite }</MyTags.Item>
                                    )}
                                </MyTags>
                            </AtlasValue>
                            <AtlasValue label="Régions">
                                <MyTags expandable>
                                    { arrayRegions && (arrayRegions ?? []).map((region) =>
                                        <MyTags.Item key={region}>{ region }</MyTags.Item>
                                    )}
                                </MyTags>
                            </AtlasValue>
                        </AtlasValues.Grid>
                        {getInterIntraInfos()}
                    </AtlasValues>
                </MyContainer>
            </div>,

            <div key="contenu" id="contenu">
                <AtlasHeading
                    tag="h2"
                    size="m"
                    button={contenuForm ? <AtlasButton
                        level={3}
                        icon="arrow-right"
                        onClick={() => setEditing(true)}
                        disabled={editing}
                    >Modifier</AtlasButton> : undefined}
                >Contenu de la formation</AtlasHeading>
                { editing ? contenuForm : <MyContainer>
                    <AtlasValues>
                        <AtlasValue label="Objectifs">
                            <AtlasHtml>{formation?.Module?.ObjectifPedagogique__c}</AtlasHtml>
                        </AtlasValue>
                        <AtlasValues.Grid>
                            <AtlasValue label="Public">
                                <AtlasHtml>{formation?.Module?.PublicVise__c}</AtlasHtml>
                            </AtlasValue>
                            <AtlasValue label="Pré requis">
                                <AtlasHtml>{formation?.Module?.PreRequis__c}</AtlasHtml>
                            </AtlasValue>
                        </AtlasValues.Grid>
                        <AtlasValue label="Programme détaillé">
                            <AtlasHtml>{formation?.ProgrammeDetaille__c}</AtlasHtml>
                        </AtlasValue>
                        <AtlasValues.Grid>
                            <AtlasValue label="Modalités d'évaluation">
                                {formation?.ModaliteEvaluation__c || ''}
                            </AtlasValue>
                            <AtlasValue label="Modalités de certification">
                                {formation?.ModaliteCertification__c || ''}
                            </AtlasValue>
                        </AtlasValues.Grid>
                        <AtlasValue label="Contact pour cette formation">
                            {formation?.Contact?.FirstName} {formation?.Contact?.LastName}
                        </AtlasValue>
                    </AtlasValues>
                </MyContainer> }
            </div>,

            <DetailFormationSessions key="sessions" formation={ formation } disabled={ ajouterSessionDisabled(formation) } />
        ]
    }

    const getActions = (formation: DetailFormationCampus | null) =>
        formation ? [
            <AtlasButton
                key="addSession"
                level={ 2 }
                disabled={ ajouterSessionDisabled(formation) }
                onClick={ () => history.push(`/nouvelle-session/${formation.IdHeroku}/session`) }
            >Ajouter une session</AtlasButton>
        ] : undefined

    return <DetailCampus<DetailFormationCampus> name="DetailFormation"
        paramName="idformation"
        title={(formation: DetailFormationCampus | null) => formation?.Libelle__c ?? ''}
        getId={(formation: DetailFormationCampus | null) => formation?.IdHeroku as number}
        getDetail={getFormation}
        loadErrorMessage="Erreur lors de récupération de la formation"
        history={breadcrumb}
        nav={[
            { targetId: "details", label: "Informations générales" },
            { targetId: "contenu", label: "Contenu de la formation" },
            { targetId: "sessions", label: "Sessions" }
        ]}
        sections={getDetailFormation}
        getActions={getActions}
    />
}


