import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormContentProps } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_CFA } from 'pages/dossier/metadata/ModulesMetadata'
import { BaseDossier } from 'slices/dossierCommon'


export function DossierCentreFormationElements<D extends BaseDossier>(props: FormContentProps<D>) {

    return (
        <FormElements metadata={ props.metadata } section={ SECTION_CFA } path="formation" type="input" />
    )
}
