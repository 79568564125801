import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import { splitAdresse } from 'components/format/Format'
import { DossierProASalarie, ProASalarieMetadata } from 'pages/dossier-proa/ProAFormMetadata'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'

export default function Salarie({ salarie }: { salarie: DossierProASalarie | undefined }) {
  const adresse = splitAdresse(salarie?.adresse?.adresse) 

  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>LE SALARIÉ</Text>
      <View style={styles.sectionContent}>
        <Text style={styles.bold}>
          Nom de naissance du salarié :{' '}
          <CerfaElement metadata={ ProASalarieMetadata.nom } value={ salarie?.nom }/>
        </Text>
        <Text style={styles.bold}>
          Prénom du salarié :{' '}
          <CerfaElement metadata={ ProASalarieMetadata.prenom } value={ salarie?.prenom }/>
        </Text>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Adresse du salarié :</Text>
            <Text>
              N° : <Text style={styles.user}>{adresse.numero ?? ' '}</Text>
              {'   '}
              Voie : <Text style={styles.user}>{adresse.adresse ?? ' '}</Text>
            </Text>
            <Text>
              Complément :{' '}
              <Text style={styles.user}>{salarie?.adresse?.complement ?? ' '}</Text>
            </Text>
            <Text>
              Code Postal :{' '}
              <Text style={styles.user}>{salarie?.adresse?.codePostal ?? ' '}</Text>
            </Text>
            <Text>
              Commune :{' '}
              <Text style={styles.user}>{salarie?.adresse?.commune ?? ' '}</Text>
            </Text>
            <Text>
              Téléphone :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.telephone } value={ salarie?.telephone }/>
            </Text>
            <Text>Courriel :</Text>
            <CerfaElement metadata={ ProASalarieMetadata.courriel } value={ salarie?.courriel }/>
          </View>
          <View style={styles.colRight}>
            <Text>
              Nir du salarié* :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.nir } value={ salarie?.nir }/>
            </Text>
            <Text style={[styles.xSmall, styles.italic]}>
              *Pour les employeurs du secteur privé dans le cadre de l'article
              L.6353-10 du code du travail
            </Text>
            <Text>
              Date de naissance : <CerfaElement metadata={ ProASalarieMetadata.dateNaissance } value={ salarie?.dateNaissance }/>
            </Text>
            <Text>
              Sexe :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.sexe } value={ salarie?.sexe }/>
            </Text>
            <Text>
              Déclare bénéficier de la reconnaissance travailleur handicapé :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.handicap } value={ salarie?.handicap }/>
            </Text>
            <Text>
              Déclare être inscrit à Pôle Emploi depuis plus d'un an :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.poleEmploi } value={ salarie?.poleEmploi }/>
            </Text>
            <Text>
              Situation du salarié :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.situationAvantContrat } value={ salarie?.situationAvantContrat }/>
            </Text>
            <Text>
              Type de minimum social, si bénéficiaire :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.typeMinimumSocial } value={ salarie?.typeMinimumSocial }/>
            </Text>
            <Text>
              Diplôme ou titre le plus élevé obtenu :{' '}
              <CerfaElement metadata={ ProASalarieMetadata.plusHautDiplome } value={ salarie?.plusHautDiplome }/>
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
