import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'
import { CAPPMaitre1Metadata } from 'pages/dossier-capp/CAPPMaitresMetadata'
import { DossierCAPPMaitre } from 'pages/dossier-capp/CAPPModel'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'


export default function Maitres({ maitres, eligibilite }: { maitres: (DossierCAPPMaitre | null)[], eligibilite: boolean }) {
  function Maitre({ index }: { index: number }) {
    const maitre = maitres && maitres[index] ? maitres[index] : { prenom: '', nomNaissance: '', dateNaissance: null, nir: '', email: '', emploiOccupe: '', plusHautDiplome: null, niveauPlusHautDiplome: null }

    return (
      <>
        <Text style={styles.bold}>Maître d'apprentissage n°{index + 1}</Text>
        <Text style={styles.bold}>
          Nom de naissance :{' '}
          <CerfaElement metadata={ CAPPMaitre1Metadata.nomNaissance } value={ maitre?.nomNaissance } />
        </Text>
        <Text style={styles.bold}>
          Prénom : <CerfaElement metadata={ CAPPMaitre1Metadata.prenom } value={ maitre?.prenom } />
        </Text>
        <Text>
          Date de naissance : <CerfaElement metadata={ CAPPMaitre1Metadata.dateNaissance } value={ maitre?.dateNaissance } />
        </Text>
        {/* <Text>
          NIR :{' '}
          <Text style={ styles.user }>{ ' ' }</Text>
        </Text> */}
        <Text>
          Courriel :{' '}
          <CerfaElement metadata={ CAPPMaitre1Metadata.email } value={ maitre?.email } default="________________@________________" />
        </Text>
        <Text>
          Emploi occupé : <CerfaElement metadata={ CAPPMaitre1Metadata.emploiOccupe } value={ maitre?.emploiOccupe } />
        </Text>
        <Text>Diplôme ou titre le plus élevé obtenu :</Text>
        <CerfaElement metadata={ CAPPMaitre1Metadata.plusHautDiplome } value={ maitre?.plusHautDiplome } />
        <Text>
          Niveau de diplôme ou titre le plus élevé obtenu :{' '}
          <CerfaElement metadata={ CAPPMaitre1Metadata.niveauPlusHautDiplome } value={ maitre?.niveauPlusHautDiplome } />
        </Text>
      </>
    );
  }

  return (
    <View style={[styles.section, styles.pageLastSection]}>
      <Text style={styles.sectionHeader}>LE MAÎTRE D'APPRENTISSAGE</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Maitre index={0}></Maitre>
          </View>
          <View style={styles.colRight}>
            <Maitre index={1}></Maitre>
          </View>
        </View>
        <Text style={[styles.xSmall, styles.boldItalic]}>
          <Checkbox checked={eligibilite}></Checkbox> L’employeur
          atteste sur l’honneur que le maître d’apprentissage répond à
          l’ensemble des critères d’éligibilité à cette fonction
        </Text>
      </View>
    </View>
  )
}
