import { unwrapResult } from '@reduxjs/toolkit'
import { findTypeReglement, RefBancaireDTO } from 'api/refBancaireAPI'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasHeading, AtlasHtml, AtlasValue, AtlasValues, MyContainer } from 'atlas-ds'
import { formatAdresse, formatApiDate } from 'components/format/Format'
import State from 'components/State'
import { Location } from 'history'
import Detail, { DetailActionElement, DetailActionProps } from 'pages/detail-common/Detail'
import { useSelector } from 'react-redux'
import { refsBancairesActions } from 'slices/refsBancairesSlice'
import RefBancaireDesactiver from './RefBancaireDesactiver'


const detailName = 'DetailRefBancaire'

export type RefBancaireActionProps = DetailActionProps<RefBancaireDTO>

export type RefBancaireActionElement = DetailActionElement<RefBancaireDTO>

export const detailRefBancaireRefreshEvent = "DetaiRefBancaire:Refresh"
export const detailRefBancaireDocumentsRefreshEvent = "DetaiRefBancaire:DocumentsRefresh"

export default function DetailRefBancaire() {

    const dispatch = useAppDispatch()
    const { fulfilled: refsBancaires } = useSelector((state: RootState) => state.refsBancairesState)

    const getRefBancaire = (id: string) => {
        if (!refsBancaires)
            return dispatch(refsBancairesActions.load({ page: 0 })).then(unwrapResult)
                .then(res => res.items.find(e => e.Id.toString() === id)!)
        return Promise.resolve(refsBancaires.items.find(e => e.Id.toString() === id)!)
    }

    const history = (location: Location<any>) => [
        { title: 'Références bancaires', pathname: '/mes-refs-bancaires', search: '' },
        { title: 'IBAN sélectionné', pathname: location.pathname, search: location.search }
    ]

    const sections = (refBancaire: RefBancaireDTO | null) => {
        return [
            <div key="details" id="details">
                <AtlasHeading tag="h2" size="m">Référence bancaire</AtlasHeading>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <AtlasValue label="Etablissement titulaire">
                                {refBancaire?.etablissement}
                            </AtlasValue>
                            <AtlasValue label="Statut">
                                <State id={ refBancaire?.statut === 'Actif' ? '3' : '5' } type="bill">{ refBancaire?.statut ?? '' }</State>
                            </AtlasValue>
                            <AtlasValue label="Créé le">
                                { formatApiDate(refBancaire?.dateCreation) }
                            </AtlasValue>
                            <AtlasValue label="Par">
                                { refBancaire?.creePar || '' }
                            </AtlasValue>
                            { refBancaire?.paiement ? <AtlasValue label="Paiement">
                                <State id="4" type="bill">Facture en cours</State>
                            </AtlasValue> : <></> }
                        </AtlasValues.Grid>
                        <AtlasValue label="Dispositif">
                            { refBancaire && (findTypeReglement(refBancaire.dispositif)?.Libelle ?? '') }
                        </AtlasValue>
                        <AtlasValue label="Banque">
                            { refBancaire?.banque }
                        </AtlasValue>
                        <AtlasValues.Grid>
                            <AtlasValue label="IBAN">
                                { refBancaire?.iban }
                            </AtlasValue>
                            <AtlasValue label="BIC">
                                { refBancaire?.bic }
                            </AtlasValue>
                        </AtlasValues.Grid>
                        <AtlasValue label="Domiciliation">
                            <AtlasHtml>
                                {refBancaire?.banque && <>{refBancaire?.banque} <br/></>}
                                {refBancaire?.guichet && <>{refBancaire?.guichet} <br/></>}
                                {refBancaire?.adresse && <>{refBancaire?.adresse} <br/></>}
                                {formatAdresse('', refBancaire?.codePostal, refBancaire?.commune)}
                            </AtlasHtml>
                        </AtlasValue>
                    </AtlasValues>
                </MyContainer>
            </div>
        ]
    }
    
    return <Detail<RefBancaireDTO> name={detailName}
        paramName="idrefbancaire"
        title={ (refBancaire: RefBancaireDTO | null) => `${refBancaire?.iban ?? ''}`}
        getId={ (refBancaire: RefBancaireDTO | null) => refBancaire?.Id ?? null }
        getDetail={ getRefBancaire }
        loadErrorMessage="Erreur lors de récupération de la référence bancaire"
        history={ history }
        sections={ sections }
        actions={ [
            <RefBancaireDesactiver key="desactiver" when={ rb => rb.statut === 'Actif' } />
        ] }
    />
}
