import { useFormContext } from '@dsid-opcoatlas/reform'
import { Profil } from 'api/profilAPI'
import { MyForm } from 'atlas-ds'
import { FormFields } from 'components/elements/FormFields'
import { CompanySummary } from 'components/reform/inputs/CompanySummary'
import SelectOrCreateEntityField from 'components/reform/inputs/SelectOrCreateEntityField'
import { FormContentProps, FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierCFA } from 'slices/dossierClasses'
import { DossierWithModules } from 'slices/dossierCommon'
import { join } from 'components/reform/inputs/utils/BaseFieldProps'
import { getOrganismesFormation, OFSearchOptions } from 'api/commonAPI'
import { useElementsContext } from 'components/elements/engine'
import { TOOLTIPS } from '../Tooltips'
import { DossierAdresseElements } from './DossierAdresseElements'
import { DossierFMA } from 'pages/dossier-fma-x/FMAFormMetadata'

export function dossierModulesInitializer<D extends DossierWithModules>(dossier: D, metadata: FormMetadata<D>, _: Profil | null) {
    if (dossier.modules.length === 0)
        dossier.modules = [metadata.api.createModule!()]
    return dossier
}

async function searchDossierCFA(recherche: string, options: OFSearchOptions = { actif: true }): Promise<DossierCFA[]> {
    const ofs = await getOrganismesFormation(recherche, options)
    return ofs.map(of => Object.assign(new DossierCFA(), {
        EtablissementOFId__c: of.Id ?? null,
        CodeEtablissementOF__c: of.CodeEtablissement__c ?? null,
        nom: of.Name ?? null,
        siret: of.SIRET__c?.replaceAll(' ', '') ?? null,
        numeroUAI: of.Uai__c ?? null,
        numeroDA: of.NumeroDA__c ?? null,
        etat: of.EtatEtablissement__c ?? '',
        entreprise: false,
        appartement: of.Adresse1__c ?? null,
        batiment: of.Adresse2__c ?? null,
        adresse: of.Adresse3__c ?? null,
        complement: of.Adresse4__c ?? null,
        codePostal: of.BillingPostalCode ?? null,
        commune: of.BillingCity ?? null,
    }))
}

export function DossierAFModulesElements(props: FormContentProps<any>) {

    const { model } = useElementsContext<DossierFMA>()

    const path = props.step.path!.toString()
    const index = props.step.path!.at(-1)?.index ?? 0
    const form = useFormContext()
    const module = form.getValue(path)

    return ( <> 
        { module && <>
        <FormFields model={ model } names={{ modules: [index, {
            formationInterne: true
        }]}} />

        { module.formationInterne === false && <>
        <SelectOrCreateEntityField
            name={ `${ path }.cfa` }
            label={ "Choix de l'organisme de formation" }
            placeholder="Rechercher par SIRET ou raison sociale (3 caractères minimum)"
            tooltip={ <>
                <p>
                    Vous pouvez choisir un établissement actif dans la liste en entrant les 3 premières lettres du centre de formation,
                    certains champs seront remplis automatiquement.
                </p>
                <p>Si la recherche ne remonte pas de résultat, vous pouvez aussi entrer manuellement le centre de formation de votre choix.</p>
            </> }
            searchValues={ recherche => searchDossierCFA(recherche, { actif: true }) }
            create={{
                newValue: () => new DossierCFA(),
                buttonLabel: "Ajouter un organisme de formation",
                infoTitle: "Ajout d'un nouvel organisme de formation",
                infoContent: <>
                    Vous n'avez pas trouvé l'organisme de formation que vous cherchiez ?<br/>
                    Utilisez les champs ci-dessous pour renseigner votre organisme de formation.
                </>,
            }}
            renderValue={ cfa => <CompanySummary nom={ cfa.nom ?? "" } siret={ cfa.siret } adresse={ join(cfa.adresse, cfa.codePostal, cfa.commune) } /> }
            getValueId={ value => value.EtablissementOFId__c ?? "" }
            onChange={ () => form.renderForm() }
        />

        <FormFields model={ model } names={{ modules: [index, {
            cfa: {
                nom: true,
                siret: true,
                numeroDA: true,
            }
        }]}} />

        <MyForm.Fieldset legend="Adresse">
            <MyForm.Grid>
                <DossierAdresseElements model={ model } names={{ modules: [index, {
                    cfa: {}
                }]}} />
            </MyForm.Grid>
        </MyForm.Fieldset>
        </> }

        <FormFields model={ model } names={{ modules: [index, {
            subrogation: true
        }]}} />
        
        <MyForm.Grid>
            <FormFields model={ model } names={{ modules: [index, {
                intitulePrecis: true,
                dureeTotale: true,
                dateDebutFormation: true,
                dateFinFormation: true,
                coutPedagogique: {
                    montant: true,
                },
                numeroDossierApprentissage: true,
                typeFormation: true,
            }]}} />
        </MyForm.Grid>

        <MyForm.Fieldset legend="Informations complémentaires AGORA" tooltipContent={ TOOLTIPS.capp.formation.agora }>
            <MyForm.Grid>
                <FormFields model={ model } names={{ modules: [index, {
                    formationCertifianteAgora: true,
                    categorieActionAgora: true,
                    objectifAgora: true,
                }]}} />
            </MyForm.Grid>
        </MyForm.Fieldset>
        </> }
    </> )
}
