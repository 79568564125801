import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import styles from "./CerfaFne.styles";
import logo from "pages/dossier/cerfa/img/opco-atlas.png";
import ministryImage from "pages/dossier/cerfa/img/ministry.png";

function Value({ label, value }: { label: string, value: string }) {
    return (
        <Text>
            <Text>{label} : </Text>
            <Text style={styles.strong}>{value}</Text>
        </Text>
    );
}

type FneProps = {
    entreprise: {
        denominationSociale: string
        siren: string
        adresse: string
        effectif: string
        contact: {
            nom: string
            fonction: string
            telephone: string
            email: string
        }
    }
    formation: {
        referenceDossier: string
        formation: string
        of: string
        lieu: string
        dateDebut: string
        dateFin: string
        heures: string
        coutTotal: string
        categorieEntreprise: string
        montantSubventionFne: string
    }
    salaries: {
        nom: string
        prenom: string
        dateNaissance: string
        contrat: string
    }[]
    soussigne: string
    date: string
}


export async function createCerfaFne(data: FneProps) {
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.header} wrap={false}>
                    <Image source={ministryImage} style={styles.ministryImage}></Image>
                    <Image source={logo} style={styles.logo}></Image>
                </View>

                <View style={styles.title} wrap={false}>
                    <Text>Demande de subvention FNE-Formation</Text>
                    <Text>valant Attestation sur l’honneur</Text>
                </View>

                <View style={styles.part} wrap={false}>
                    <Text style={styles.sectionTitle}>
                        Identification de l'entreprise
                    </Text>
                    <Value
                        label="Dénomination sociale"
                        value={data.entreprise.denominationSociale}
                    />
                    <Value label="SIREN de l'entreprise" value={data.entreprise.siren} />
                    <Value
                        label="Adresse du siège social"
                        value={data.entreprise.adresse}
                    />
                    <Value
                        label="Effectif de l'entreprise"
                        value={data.entreprise.effectif}
                    />
                    <Value label="Nom du contact" value={data.entreprise.contact.nom} />
                    <Value label="Fonction" value={data.entreprise.contact.fonction} />
                    <Value label="Tél." value={data.entreprise.contact.telephone} />
                    <Value label="E-mail" value={data.entreprise.contact.email} />
                </View>

                <View style={styles.part} wrap={false}>
                    <Text>
                        Je certifie l’exactitude des renseignements indiqués dans le présent
                        dossier.
                    </Text>
                </View>

                <View style={styles.part} wrap={false}>
                    <Text>
                        L’entreprise confirme sa demande visant à bénéficier du soutien de
                        l’Etat au titre du FNE-Formation pour réaliser l’action suivante :
                    </Text>
                    {!data.formation.referenceDossier.startsWith('B-') && <Value
                        label="Référence du dossier"
                        value={data.formation.referenceDossier}
                    />}
                    <Value label="Intitulé formation" value={data.formation.formation} />
                    <Value label="Organisme de formation" value={data.formation.of} />
                    <Value label="Lieu de réalisation" value={data.formation.lieu} />
                    <Value
                        label="Période de réalisation"
                        value={`${data.formation.dateDebut} au ${data.formation.dateFin}`}
                    />
                    <Value label="Durée" value={`${data.formation.heures} heures`} />
                    <Value
                        label="Coût total demandé"
                        value={`${data.formation.coutTotal}`}
                    />
                    <Value
                        label="Catégorie d'entreprise"
                        value={`${data.formation.categorieEntreprise}`}
                    />
                    <Value
                        label="Montant subvention FNE-Formation demandé"
                        value={`${data.formation.montantSubventionFne}`}
                    />
                </View>

                <View style={styles.part} wrap={false}>
                    <Text style={styles.sectionTitle}>
                        Liste des salariés en formation
                    </Text>
                    {data.salaries.map((salarie: any) => (
                        <Text key={salarie.nom}>
                            {salarie.nom} {salarie.prenom} - {salarie.dateNaissance} -{" "}
                            {salarie.contrat}
                        </Text>
                    ))}
                </View>

                <View style={styles.part} wrap={false}>
                    <Text>
                        La formation mise en œuvre concourt à la prise en compte et
                        l’accélération des transitions numérique et/ou écologique au sein de
                        mon entreprise. Ces enjeux ont été repérés par les représentants de
                        mon secteur et une réponse opérationnelle est apportée à travers la
                        mise à disposition d’une offre de formation sélectionnée et négociée
                        accessible via la plateforme campusAtlas.
                    </Text>
                </View>

                <View style={styles.part} wrap={false}>
                    <Text>
                        J’ai connaissance des obligations liées au bénéfice de cette aide
                        publique, à savoir de maintenir dans l’emploi les salariés pendant
                        la période de formation et, le cas échéant, de recueillir l’accord
                        préalable par écrit de tout salarié en activité partielle suivant
                        une action durant son temps d’inactivité.
                    </Text>
                </View>

                <View style={styles.part} wrap={false}>
                    <Text>J’atteste sur l’honneur :</Text>
                    <Text>
                        - de la régularité de la situation fiscale et sociale de l’organisme
                        que je représente ;
                    </Text>
                    <Text>
                        - ne pas être en difficulté (au sens de l’annexe 1 du régime cadre
                        exempté de notification N° SA.58981 relatif aux aides à la formation
                        pour la période 2014-2023) ou ne pas l’avoir été avant le 31
                        décembre 2019 et l’être devenu entre le 1er janvier 2020 et le 30
                        juin 2021.
                    </Text>
                    <Text>
                        - si l’entreprise fait partie d’un groupe, que la demande de FNE de
                        l’ensemble du groupe auprès d’ATLAS pour l’année 2024 ne dépasse pas
                        500 K€
                    </Text>
                </View>

                <View style={styles.part} wrap={false}>
                    <Text>
                        Je m’engage à conserver l’ensemble des pièces justificatives qui ont
                        permis d’établir la présente attestation, y compris les bulletins de
                        salaires et contrats de travail des stagiaires, jusqu’à 10 ans après la
                        date d’octroi de l’aide. Je m’engage à transmettre, sur demande, la
                        présente demande de subvention FNE-Formation valant Attestation sur
                        l’honneur faisant mention de la signature du responsable légal et du
                        cachet de l’entreprise.
                    </Text>
                </View>

                <View style={styles.part} wrap={false}>
                    <Value label="Je soussigné" value={data.soussigne} />
                    <Value label="Date" value={data.date} />
                </View>

                <Text
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                    style={styles.pageNumber}
                />
            </Page>
        </Document>
    );
}
