import { Step } from 'react-joyride'
import { ELEMENTS } from '../elements'

export const recapSteps: Step[] = [
  {
    target: ELEMENTS.EDIT,
    content: 'Retourner à l’étape indiquée et modifier les informations',
    placementBeacon: 'left',
  },
];
