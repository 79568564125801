import { getTableauToken } from 'api/commonAPI'
import { MyTemplate } from 'atlas-ds'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

declare global {
    namespace JSX {
      interface IntrinsicElements {
        "tableau-viz": any
        "viz-filter": any
      }
    }
}

interface TableauVizProps {
    src: string
}

export default function TableauViz(props: TableauVizProps) {

  const { profil } = useSelector((state: any) => state.profilState)
  const { entreprise } = useSelector((state: any) => state.contextState)
  const [token, setToken] = useState('')

  useEffect(() => {
      getTableauToken().then(data => {
        setToken(data.token)

        /* setTimeout(() => {
          let viz = (document as any).getElementById('tableauViz')
          if (viz && viz.workbook && viz.workbook.activeSheet) {
              viz.workbook.activeSheet.getFiltersAsync().then((dashFilters: any) => {
              // show # and names of categorical filters in the Console
              log.info(dashFilters);
            })
          }
        }, 10000) */
      })
  }, [])

  //   src='https://dub01.online.tableau.com/t/pocembedded/views/Suividesenveloppes/RcapSuividesenveloppes'>
  // </tableau-viz>`
  // 'https://dub01.online.tableau.com/t/pocembedded/views/RapportDcaissement/Rapportdcaissementdtailsdossiers_'

  return token ? <MyTemplate.Breakout>
    <tableau-viz id="tableauViz" hide-tabs hide-toolbar hide-share
      token={ token } height='927px' width='100%'
      src={ props.src }>
        <viz-filter field="Email" value={ profil.Contact.Email }></viz-filter>
        <viz-filter field="Entreprise__r.SIREN" value={ entreprise.SIREN__c }></viz-filter>
    </tableau-viz>
  </MyTemplate.Breakout> : <p>Chargement en cours...</p>
}
