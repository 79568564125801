import store from 'app/store'
import axios from 'axios'
import { getRequestConfig } from './baseAPI'


export interface ServiceStrategiePlus {
    nom: string
    description: string
    lien: string
}

export interface Presentation {
    titre: string
    description: string
    enSavoirPlus: string
    lien: string
}

export interface Adhesion {
    titre: string
    description: string
    enSavoirPlus: string
    lien: string
}

export interface StrategiePlusServices {
    presentation: Presentation[]
    adhesion: Adhesion[]
    services: ServiceStrategiePlus[]
}

export async function getServicesStrategiePlus(): Promise<StrategiePlusServices> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<StrategiePlusServices>('/strategiePlus', requestConfig)
    return data
}

export async function postVersementStrategiePlus(json: string, nomDocument?: string, document?: File): Promise<string> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'
    
    const formData = new FormData()
    formData.append('siren', store.getState().contextState.entreprise?.SIREN__c ?? '')
    formData.append('json', json)
    if (document && nomDocument) {
        formData.append('withDocument', 'true')
        formData.append('document', document, nomDocument)
    }
    
    const { data } = await axios.post('/sollicitations', formData, requestConfig)
    return data.id
}
