import { AtlasButton } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import { useState } from 'react'


interface TelechargerDocProps {
    label?: string
    level?: 1 | 2 | 3 | 4
    disabled?: boolean
    spinning?: boolean
    downloadDoc: () => Promise<void>
    onSuccess?: (res: any) => void
    onError?: (e: any) => void
}

export default function TelechargerDoc(props: TelechargerDocProps) {

    const [downloading, setDownloading] = useState(false)

    const onDownloadSuccess = () => {
        ToastService.showToast({
            label: 'Documents',
            type: 'success',
            icon: 'file',
            content: 'Le fichier a été téléchargé'
        })
    }

    const onDownloadError = () => {
        ToastService.showToast({
            label: 'Documents',
            type: 'error',
            icon: 'file',
            content: 'Une erreur est survenue durant le téléchargement'
        })
    }

    const downloadDoc = () => {
        setDownloading(true)
        props.downloadDoc()
            .then(props.onSuccess ?? onDownloadSuccess)
            .catch(props.onError ?? onDownloadError)
            .finally(() => setDownloading(false))
    }

    return (
        <AtlasButton
            key="telecharger"
            level={ props.level ?? 3 }
            icon="download"
            disabled={ props.disabled }
            spinner={{spinning: props.spinning || downloading}}
            onClick={() => downloading || downloadDoc()}
        >{ props.label ?? 'Télécharger' }</AtlasButton>
    )
}
