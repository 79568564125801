import { RootState } from "app/rootReducer"
import { useAppDispatch } from "app/store"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { referencesIdccActions } from "slices/referencesIdccSlice"


export const useIdcc = (load = true) => {
    
    const dispatch = useAppDispatch()
    const { pending, fulfilled: refsIdcc } = useSelector((state: RootState) => state.referencesIdccState)
    
    useEffect(() => {
        if (refsIdcc == null && !pending && load === true)
            dispatch(referencesIdccActions.load())
    }, [load, dispatch, refsIdcc, pending])

    return refsIdcc
}