import { RootState } from 'app/rootReducer'
import { AtlasInfo, AtlasSpacer, MyForm } from 'atlas-ds'
import DateField from 'components/reform/inputs/DateField'
import { useSelector } from 'react-redux'

export default function VirementBlock(props: { type: "conventionnel" | "volontaire" }) {
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    return <div>
        <AtlasInfo title="Information de virement" type="warning">
            <p>Afin d'identifier le virement émis par votre banque, merci de libeller cet ordre avec votre code adhérent et votre nom d’entreprise :</p>

            <p><strong>{entreprise?.CodeAdherent__c.split('.')[0]} {entreprise?.Name}</strong></p>

            <p>
                Centre d'affaires IDF Institutions<br/>
                IBAN : { props.type === "volontaire" ? "FR76 3000 4028 3700 0109 4790 194" : "FR76 3000 4028 3700 0109 1627 994" }<br/>
                BIC : BNPAFRPPXXX
            </p>

            <p><strong>Attention : compte dédié aux versements { props.type }s</strong></p>
        </AtlasInfo>

        <AtlasSpacer size="l" />

        <MyForm.Field alone>
            <DateField name="dateVirement" label="Date du virement" />
        </MyForm.Field>
    </div>
}