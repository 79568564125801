import { InputHTMLAttributes } from "react"
import { useFormContext } from "@dsid-opcoatlas/reform"
import { ClassConstructor, ExtendedPath, FieldNames, FormFieldContext, FormFieldMetadata, getMetadata, useElementsContext } from "./engine"
import { cloneDeep } from "lodash-es"

function FormField(props: {
    model: ClassConstructor,
    name: string,
    parentPath: string | undefined,
    overrides: FormFieldMetadata<any> | undefined
}) {

    const elements = useElementsContext()
    const form = useFormContext()
    const name = props.parentPath ? `${ props.parentPath }.${ props.name }` : props.name
    const metadata = getMetadata(props.model) as Record<string, FormFieldMetadata<any>>
    
    const overrides = Object.fromEntries(Object.entries(props.overrides ?? {}).filter(([, value]) => value !== undefined))
    const ancestor = ExtendedPath.fromString(name, elements!.model!)?.at(-2)
    const fieldOverridesClass = ancestor?.class
    if (fieldOverridesClass != null) {
        const fieldOverrides = getMetadata(fieldOverridesClass)[ancestor!.property]?.overrides?.[props.name]
        if (fieldOverrides != null)
            Object.assign(overrides, fieldOverrides)
    }

    const fieldMetadata = { ...metadata[props.name], ...overrides }
    const { required, ignored, override, disabled, visible, contextConsumer, input: Input, ...rest } = fieldMetadata

    const context = new FormFieldContext<any>(elements!.model!, name, form)

    if (Input == null)
        return null

    if (visible != null && (typeof visible === 'boolean' ? visible : visible(context)) === false)
        return null

    const computedProps = {} as InputHTMLAttributes<HTMLInputElement>
    if (disabled != null)
        computedProps.disabled = (typeof disabled === 'boolean' ? disabled : disabled(context))

    return <Input name={ name } { ...rest } { ...overrides } { ...computedProps } context={ context } />
}

export function resolveLeaf(model: ClassConstructor, names: FieldNames<any>, parentPath?: string): [string, ClassConstructor] | undefined {
    let leafClass: ClassConstructor = model
    let path = parentPath
    for (let [name, fieldMetadata] = Object.entries(names)?.[0]; fieldMetadata != null; [name, fieldMetadata] = Object.entries(names)?.[0]) {
        const metadata = getMetadata(leafClass)[name]
        const objectClass = metadata.object ?? metadata.array
        if (objectClass == null)
            return undefined
        if (typeof fieldMetadata === 'boolean') {
            path = path != null ? `${ path }.${ name }` : name
            return [path, objectClass]
        }
        if (Array.isArray(fieldMetadata)) {
            const [index, itemNames] = fieldMetadata as [number, FieldNames<any>]
            path = path != null ? `${ path }.${ name }[${ index }]` : `${ name }[${ index }]`
            names = itemNames
        }
        else {
            path = path != null ? `${ path }.${ name }` : name
            names = fieldMetadata as FieldNames<any>
        }
        leafClass = objectClass
    }
    return undefined
}

export function firstLeafModel(model: ClassConstructor, names: FieldNames<any>) {
    let leafClass: ClassConstructor = model
    let path: string = ''
    for (let [name, fieldMetadata] = Object.entries(names)?.[0]; fieldMetadata != null; [name, fieldMetadata] = Object.entries(names)?.[0]) {
        const metadata = getMetadata(leafClass)[name]
        const objectClass = metadata.object ?? metadata.array
        if (objectClass == null)
            return undefined
        if (typeof fieldMetadata === 'boolean')
            return [name, objectClass]
        names = Array.isArray(fieldMetadata) ? fieldMetadata[1] : fieldMetadata as FieldNames<any>
        leafClass = objectClass
    }
    return leafClass
}

function appendFormFields<T>(formFields: JSX.Element[], model: ClassConstructor<T>, names: FieldNames<T>, parentPath?: string) {
    for (const [name, fieldMetadata] of Object.entries(names)) {
        if (fieldMetadata === false)
            continue
        
        let overrides: FormFieldMetadata<any> | undefined = undefined
        if (typeof fieldMetadata === 'object') {
            const metadata = getMetadata(model)[name]
            const objectClass = metadata.object ?? metadata.array
            if (objectClass == null)
                overrides = fieldMetadata as FormFieldMetadata<any>
            else {
                if (Array.isArray(fieldMetadata)) {
                    const [index, itemNames] = fieldMetadata as [number, FieldNames<any>]
                    const path = parentPath ? `${ parentPath }.${ name }[${ index }]` : `${ name }[${ index }]`
                    appendFormFields(formFields, objectClass, itemNames, path)
                }
                else {
                    const path = parentPath ? `${ parentPath }.${ name }` : name
                    appendFormFields(formFields, objectClass, names[name as keyof T] as FieldNames<any>, path)
                }
                continue
            }
        }
        
        formFields.push(<FormField key={ name as string } model={ model } name={ name } parentPath={ parentPath } overrides={ overrides } />)
    }
    
    return formFields
}

export function FormFields<T, P = any>(props: { model: ClassConstructor<T>, names: FieldNames<T>, parent?: { model: ClassConstructor<P>, path: FieldNames<P> }, parentPath?: string }) {
    let model = props.model as ClassConstructor
    let names = props.names as FieldNames<any>
    if (props.parent != null) {
        const parent = cloneDeep(props.parent)
        let leaf = Object.values(parent.path)?.[0];
        while (typeof leaf === "object") {
            const newLeaf = Object.values(Array.isArray(leaf) ? leaf[1] : leaf)?.[0]
            if (newLeaf == null)
                break
            leaf = newLeaf
        }
        if (leaf != null) {
            Object.assign(leaf, props.names)
            names = parent.path as FieldNames<any>
            model = parent.model
        }
    }
    const formFields = appendFormFields([], model, names, props.parentPath)
    return <> { formFields } </>
}

export function initFormFields<T>(model: ClassConstructor<T>, path: string) {
    return function (props: { names: FieldNames<T> }) {
        return <FormFields model={ model } parentPath={ path } names={ props.names } />
    }
}
