import { Form, SetValueOptions, UseFormReturn, getFieldState, useForm } from '@dsid-opcoatlas/reform'
import { Yop } from '@dsid-opcoatlas/yop'
import { unwrapResult } from '@reduxjs/toolkit'
import { CATEGORIES_TYPES_ACTIONS, DiagActionDTO, DiagDiagnosticActionDTO, getActions, getTypesAction } from 'api/diagatlasAPI'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasCheckbox, AtlasFlex, AtlasInfo, MyAction, MyActionList, MyActionTag, MyDropdownMenu, MyFilter, MyForm, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import Link from 'components/Link'
import PageHeader from 'components/PageHeader'
import { formatToFullDate } from 'components/format/Format'
import Button from 'components/reform/Button'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import SelectField from 'components/reform/inputs/SelectField'
import TextField from 'components/reform/inputs/TextField'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import { OptionalString, RequiredString } from 'components/yop/constraints'
import { addSeconds, isAfter, isBefore, parseISO, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Diagnostic, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import { nil } from 'slices/dossierCommon'
import DiagPageHeader from './DiagPageHeader'
import DiagPlanActionOperations from './DiagPlanActionOperations'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


interface MonDiagnosticActionsProps {
}


interface DiagnosticActionsPreconiseesProps {
    diagnostic: Diagnostic | null
    filters?: string[]
    sources?: string[]
    maxElements?: number
    ajouterLabel?: string
    ajouterAction: (action: DiagDiagnosticActionDTO) => Promise<DiagDiagnosticActionDTO>
    desactiverAction?: (action: DiagDiagnosticActionDTO) => Promise<void>
}

export function DiagnosticActionsPreconisees(props: DiagnosticActionsPreconiseesProps) {
    const [updatingAction, setUpdatingAction] = useState('')

    const tagsPourAction = (da: DiagDiagnosticActionDTO) => {
        const tags = [{ label: da.Action.Type.Libelle, category: da.Action.Type.CategorieLibelle }] as MyActionTag[]
        if (da.Action.Source !== 'Atlas') {
            tags.push({ label: 'Action personnalisée' } as MyActionTag)
        }
        return tags
    }

    const filters = props.filters ?? ['all']
    const sources = props.sources ?? ['Atlas', 'Entreprise']

    const planAction = props.maxElements ? props.diagnostic?.planAction?.slice(0, props.maxElements) : props.diagnostic?.planAction

    const minDateNew = subDays(Date.now(), 1)

    const dateRealisation = props.diagnostic?.dateRealisation ? parseISO(props.diagnostic?.dateRealisation) : null
    const dateModification = props.diagnostic?.dateModification ? parseISO(props.diagnostic?.dateModification) : null

    const deleteAction = (da: DiagDiagnosticActionDTO) => {
        if (props.desactiverAction) {
            setUpdatingAction(da.Action.Id)
            setTimeout(() => {
                props.desactiverAction!(da)
                    .finally(() => setUpdatingAction(''))
            }, 100)
        }
    }

    const ajouterAction = (da: DiagDiagnosticActionDTO) => {
        setUpdatingAction(da.Action.Id)
        setTimeout(() => {
            props.ajouterAction(da)
                .finally(() => setUpdatingAction(''))
        }, 100)
    }
    
    return <MyActionList>
        { planAction?.filter(da => filters.includes('all') || filters.includes(da.Action.Type.CategorieLibelle))
            // ?.filter(da => sources.includes(da.Action.Source) || (!da.Action.Source && sources.includes('Atlas')))
            ?.filter(da => (sources.includes('Atlas') && !da.AjoutEntreprise) || (sources.includes('Entreprise') && da.AjoutEntreprise))
            ?.map(da => {
                const dateAjout = da.DateAjout ? parseISO(da.DateAjout) : null
                const isNew = !!dateModification && !!dateAjout && isAfter(dateAjout!, addSeconds(dateRealisation!, 3)) && ( // Pas à la création
                    !isBefore(dateAjout!, dateModification!) // Modifiés depuis la dernière maj du diag
                    || isAfter(dateAjout!, minDateNew)       // Modifiés depuis - de 24h
                )
                return <MyAction
                    key={ `action-${da.ExternalIdHeroku}` }
                    tags={ tagsPourAction(da) } 
                    isNew={ isNew }
                    isLeaving={ updatingAction === da.Action.Id }
                    onDelete={ () => deleteAction(da) }
                    infoHref={ da.Action.URL } 
                    button={ <AtlasButton icon="plus" onClick={ () => ajouterAction(da) }>
                        { props.ajouterLabel ?? "Ajouter au plan d'action" }
                    </AtlasButton> }
                >{ da.Action.Libelle }</MyAction>
            }) ?? [] 
        }
    </MyActionList>
}


export default function MonDiagnosticActions(props: MonDiagnosticActionsProps) {
    const dispatch = useAppDispatch()
    const navigation = useDiagnosticNavigation()
    useScrollToTop()

    const storedFilters = { filters: null, sources: null } // Ne plus sauvegarder les filtres #3108)

    const [showAddAction, setShowAddAction] = useState(false)
    const [showCreateAction, setShowCreateAction] = useState(false)
    const [disableAskDesactiverAction, setDisableAskDesactiverAction] = useState(false)
    const [showAskDesactiverAction, setShowAskDesactiverAction] = useState(null as (DiagDiagnosticActionDTO | null))
    const [filters, setFilters] = useState(storedFilters?.filters ?? ['all'])
    const [sources, setSources] = useState(storedFilters?.sources ?? ['Atlas', 'Entreprise'])

    const [actionUpdating, setActionUpdating] = useState('')

    const { diagnostic, loading } = useDiagnostic(null, ['plan-action', 'has-plan-action'], { statuts: 'preconisee' })

    useEffect(() => setDisableAskDesactiverAction(localStorage.getItem('diagatlas.askDesactiverCheckbox') === 'true'), [])

    const changeFilter = (value: string, checked: boolean) => {
        if (value === 'all' && checked) {
            setFilters(['all'])
            return
        }
        const newFilters = [ ...filters ]
        if (value !== 'all' && checked) {
            const index = newFilters.indexOf('all')
            if (index >= 0)
                newFilters.splice(index, 1)
        }
        const index = newFilters.indexOf(value)
        if (checked)
            newFilters.push(value)
        else if (!checked && index >= 0)
            newFilters.splice(index, 1)

        if (newFilters.length === 0 || newFilters.length >= 3) {
            newFilters.splice(0, newFilters.length, 'all')
        }

        setFilters(newFilters)
        // sessionStorage.setItem('diagnostic-filtres-actions', JSON.stringify({ filters: newFilters, sources }))
    }

    const changeSource = (value: string, checked: boolean) => {
        const newSources = [ ...sources ]
        const index = newSources.indexOf(value)
        if (checked)
            newSources.push(value)
        else
            newSources.splice(index, 1)

        setSources(newSources)        
        // sessionStorage.setItem('diagnostic-filtres-actions', JSON.stringify({ filters, sources: newSources }))
    }

    const ajouterAction = (action: DiagDiagnosticActionDTO) => {
        if (actionUpdating !== '') {
            return Promise.resolve(action)
        }
        setActionUpdating(action.Id)
        return dispatch(diagnosticSliceActions.addAction({ action: action.Action.Id, preconisee: false }))
            .then(unwrapResult)
            .then(res => {
                ToastService.showToast({
                    type: "success",
                    label: "Plan d'action",
                    content: `L'action ${res.Action.Libelle} a bien été ajoutée à votre plan d'action`,
                    icon: "diagnostic",
                    link: <Link to="/mon-diagnostic-plan">Voir mon plan d'action</Link>,
                })
                return res
            })
            .finally(() => setActionUpdating(''))
    }

    const doDesactiverAction = (action: DiagDiagnosticActionDTO) => {
        if (actionUpdating !== '') {
            return Promise.resolve()
        }
        setActionUpdating(action.Id)
        return dispatch(diagnosticSliceActions.desactiverAction({ action: action.Action.Id }))
            .then(unwrapResult)
            .finally(() => setActionUpdating(''))
}

    const desactiverAction = (action: DiagDiagnosticActionDTO) => {
        if (disableAskDesactiverAction)
            return doDesactiverAction(action)
        else
            setShowAskDesactiverAction(action)
        return Promise.resolve()
    }

    const creerAction = () => {
        setShowAddAction(false)
        setShowCreateAction(true)
    }

    return <>
        <DiagPageHeader>
            <PageHeader>Actions préconisées</PageHeader>
        </DiagPageHeader>

        <AtlasFlex column gap="xl">
            <AtlasFlex column gap="s">
                <p>Diagnostic réalisé{ diagnostic?.dateRealisation ? ' le ' + formatToFullDate(diagnostic?.dateRealisation) : '' }{ diagnostic?.contactRealisation ? ' par ' + diagnostic?.contactRealisation : '' }{ diagnostic?.dateModification ? ' et modifié le ' + formatToFullDate(diagnostic?.dateModification) : '' }{ diagnostic?.contactModification ? ' par ' + diagnostic?.contactModification : '' }</p>
            </AtlasFlex>

            <DiagPlanActionOperations diagnostic={ diagnostic } />

            <AtlasInfo title="Votre diagnostic est terminé !">
                <p>Découvrez les actions recommandées par l'Opco Atlas.</p>
                <p><strong>Survolez les actions et sélectionnez celles que vous souhaitez ajouter à votre Plan d'action. Vous pouvez ajuster ces recommandations en modifiant le diagnostic ou en ajoutant une action.</strong></p>
            </AtlasInfo>

            <AtlasFlex alignItems='start' justifyContent='space-between'>
                <div>
                    <AtlasFlex column gap="s">
                        <AtlasFlex gap="m">
                            <MyDropdownMenu
                                label="Modifier diagnostic"
                                items={[
                                    <Link icon="pen" to="/mon-diagnostic-besoins">Modifier besoins entreprise</Link>,
                                    <Link icon="pen" to="/mon-diagnostic-metiers">Modifier métiers et compétences</Link>,
                                ]}></MyDropdownMenu>
                            <AtlasButton onClick={ () => setShowAddAction(true) }>Ajouter une action</AtlasButton>
                        </AtlasFlex>
                    </AtlasFlex>
                </div>

                { diagnostic?.hasPlanAction && <AtlasButton onClick={ () => navigation.navigateToPath('plan') }>
                    Voir mon plan d'action
                </AtlasButton> }
            </AtlasFlex>

            <AtlasFlex column gap="l">
                <AtlasFlex column gap="m">
                    <AtlasFlex gap="s" alignItems='center'>
                        <p>Afficher pour</p>
                        <MyFilter
                            id="all"
                            label="Tout"
                            checked={ filters.includes('all') }
                            onChange={ event => changeFilter('all', event.currentTarget.checked) }
                        />
                        <MyFilter
                            id="formation"
                            label="Formation"
                            checked={ filters.includes('Formation') }
                            onChange={ event => changeFilter('Formation', event.currentTarget.checked) }
                            color={MyAction.getColor("Formation")}
                        />
                        <MyFilter
                            id="recrutement"
                            label="Recrutement"
                            checked={ filters.includes('Recrutement') }
                            onChange={ event => changeFilter('Recrutement', event.currentTarget.checked) }
                            color={MyAction.getColor("Recrutement")}
                        />
                        <MyFilter
                            id="pratiquerh"
                            label="Pratiques RH"
                            checked={ filters.includes('Pratiques RH') }
                            onChange={ event => changeFilter('Pratiques RH', event.currentTarget.checked) }
                            color={MyAction.getColor("Pratiques RH")}
                        />
                    </AtlasFlex>
                    <AtlasFlex gap="s" alignItems='center'>
                        <p>Ajoutées par</p>
                        <MyFilter
                            id="atlas"
                            label="Atlas"
                            checked={ sources.includes('Atlas') }
                            onChange={ event => changeSource('Atlas', event.currentTarget.checked) }
                        />
                        <MyFilter
                            id="entreprise"
                            label="Entreprise"
                            checked={ sources.includes('Entreprise') }
                            onChange={ event => changeSource('Entreprise', event.currentTarget.checked) }
                        />
                    </AtlasFlex>
                </AtlasFlex> 

                { !loading && <DiagnosticActionsPreconisees diagnostic={ diagnostic } filters={ filters } sources={ sources }
                    ajouterAction={ ajouterAction }
                    desactiverAction={ desactiverAction } />
                }
            </AtlasFlex>
        </AtlasFlex>

        { showAddAction && <AjouterActionModal show={showAddAction} close={ () => setShowAddAction(false) } 
            next={ () => setShowAddAction(false)} create={ creerAction } /> }

        { showCreateAction && <CreerActionModal show={showCreateAction} close={ () => setShowCreateAction(false) } 
            next={ () => setShowCreateAction(false)} /> }

        { showAskDesactiverAction && <AskDesactiverActionModal action={ showAskDesactiverAction } close={ () => setShowAskDesactiverAction(null) } 
            next={ () => {
                setDisableAskDesactiverAction(localStorage.getItem('diagatlas.askDesactiverCheckbox') === 'true')
                doDesactiverAction(showAskDesactiverAction)
                setShowAskDesactiverAction(null)
            } } /> }
    </>
}

export function AjouterActionModal(props: { show: boolean, close: () => void, next: () => void, create: () => void }) {

    const dispatch = useAppDispatch()
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { saving, error } = useSelector((state: RootState) => state.diagnosticState)

    const clearError = () => {
        dispatch(diagnosticSliceActions.clear())
    }

    const close = () => {
        clearError()
        props.close()
    }

    const create = () => {
        clearError()
        props.create()
    }

    const [typesAction, setTypesAction] = useState([] as any[])

    useEffect(() => {
        if (entreprise) {
            getTypesAction(entreprise!.Id)
                .then(typesAction => setTypesAction(typesAction.map(ta => ({
                    value: ta.Id,
                    label: ta.Libelle,
                }))))
        }
    }, [dispatch, entreprise])

    const initialValues = { type: nil as string, action: nil as { Id: string } }

    const validationSchema = Yop.object<typeof initialValues>({
        type: RequiredString,
        action: Yop.object<{ Id: string }>({ Id: RequiredString }).defined().required(),
    })

    const onSubmit = (context: UseFormReturn<typeof initialValues>) => {
        dispatch(diagnosticSliceActions.addAction({ action: context.values!.action.Id, preconisee: true }))
            .then(unwrapResult)
            .then(() => props.next())
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit, 
    })
    const fieldState = getFieldState<DiagActionDTO | null>(form, 'action')

    const searchActions = async (recherche: string) => {
        if (!entreprise || !form.values?.type || recherche == null)
            return []

        const actions = await getActions(entreprise.Id, form.values.type, recherche)
        return actions
    }

    return (
        <MyModal show={ props.show } label="Ajouter une action">
            <Form context={form} noValidate>
                <MyForm>
                    <SelectField
                        selectionner
                        name="type"
                        label="Type d'action"
                        options={ typesAction }
                        onChange={ () => {
                            form.setValue("action", null, SetValueOptions.Untouch)
                            clearError()
                        } }
                    />

                    <AutocompleteBaseField<DiagActionDTO>
                        label="Action"
                        defaultValue={ fieldState.value?.Libelle ?? "" }
                        error={ fieldState.error }
                        required={ fieldState.constraints.required }
                        disabled={ form.values?.type == null }
                        placeholder="Intitulé action"
                    
                        search={ searchActions }
                        searchDebounceOptions={{ immediate: form.values?.type != null, empty: true, minChars: 0 }}
                        
                        optionFor={ action => ({ id: action.Code, content: action.Libelle }) }
                        onSelect={ action => { clearError(); form.setValue("action", action, true) } }
                        hideNoResultsMessage
                    />

                    { !saving && error && (error.startsWith('action already exists in plan') ? 
                        <AtlasInfo type="warning" title="Erreur d'ajout">
                            Cette action est déjà présente dans le plan
                        </AtlasInfo> :
                        error.startsWith('action already exists in preco') ? 
                        <AtlasInfo type="warning" title="Erreur d'ajout">
                            Cette action est déjà présente dans la liste des actions préconisées
                        </AtlasInfo> :
                        <AtlasInfo type="error" title="Erreur d'ajout">{ error }</AtlasInfo> )
                    }

                    <MyForm.Actions>
                        <AtlasButton type="submit" level={ 1 } disabled={ !form.values?.action } spinner={{ spinning: saving }} >
                            Ajouter
                        </AtlasButton>
                        <AtlasButton level={ 2 } onClick={ close }>
                            Annuler
                        </AtlasButton>
                    </MyForm.Actions>

                    <p>Vous ne trouvez pas l'action recherchée ?<br/>
                    Créez votre action personnalisée en cliquant{' '}
                    <AtlasButton type="button" level={3} onClick={ create }>ici</AtlasButton></p>
                </MyForm>
            </Form>
        </MyModal>
    )
}

export function CreerActionModal(props: { show: boolean, close: () => void, next: () => void }) {

    const dispatch = useAppDispatch()
    const { saving, error } = useSelector((state: RootState) => state.diagnosticState)

    const clearError = () => {
        dispatch(diagnosticSliceActions.clear())
    }

    const close = () => {
        clearError()
        props.close()
    }

    const initialValues = { categorie: nil as string, libelle: nil as string, lien: nil as string | null }

    const validationSchema = Yop.object<typeof initialValues>({
        categorie: RequiredString,
        libelle: RequiredString,
        lien: OptionalString.matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9()@:%_*.~#?&/=]*)/),
    })

    const onSubmit = (context: UseFormReturn<typeof initialValues>) => {
        dispatch(diagnosticSliceActions.createAction({ categorie: context.values!.categorie, libelle: context.values!.libelle, lien: context.values!.lien ?? undefined }))
            .then(unwrapResult)
            .then(() => props.next())
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit,
    })

    return (
        <MyModal show={ props.show } label="Créer une action personnalisée">
            <Form context={form} noValidate>
                <MyForm>
                    <SelectField
                        name="categorie"
                        label="Catégorie d'action"
                        options={ Object.entries(CATEGORIES_TYPES_ACTIONS).map(([id, libelle]) => ({
                            value: id,
                            label: libelle,
                        })) }
                        onChange={ clearError }
                    />

                    <TextField 
                        name="libelle"
                        label="Intitulé de l'action"
                        trim={ true }
                        onChange={ clearError }
                    />

                    <TextField 
                        name="lien"
                        label="Lien URL"
                        onChange={ clearError }
                    />

                    { !saving && error && (error.startsWith('action already exists in plan') ? 
                        <AtlasInfo type="warning" title="Erreur de création">
                            Cette action est déjà présente dans le plan
                        </AtlasInfo>
                        : error.startsWith('action already exists in preco') ? 
                        <AtlasInfo type="warning" title="Erreur de création">
                            Cette action est déjà présente dans la liste d'actions préconisées
                        </AtlasInfo>
                        : error.startsWith('action already exists') ?
                        <AtlasInfo type="warning" title="Erreur de création">
                            Cette action a déjà été créée par votre entreprise. Retrouvez-là dans la liste des actions personnalisées.
                        </AtlasInfo>
                        : <AtlasInfo type="error" title="Erreur de création">{ error }</AtlasInfo> )
                    }

                    <MyForm.Actions>
                        <AtlasButton type="submit" level={ 1 } disabled={ !form.values?.categorie } spinner={{ spinning: saving }}>
                            Enregistrer et ajouter
                        </AtlasButton>
                        <AtlasButton level={ 2 } onClick={ close }>Annuler</AtlasButton>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}

export function AskCreerPlanActionModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Validation du diagnostic"
            okButton={ <Button onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button onClick={ props.close }>Annuler</Button> }
        >
            <AtlasFlex column gap="l">
                <p>La validation du diagnostic créera une liste d'actions préconisées par Atlas en fonction de vos choix.</p>
            </AtlasFlex>
        </MyModal>
    )
}

export function AskDesactiverActionModal(props: { action: DiagDiagnosticActionDTO, close: () => void, next: () => void }) {
    const [ask, setAsk] = useState(false)

    useEffect(() => {
        const ask = localStorage.getItem('diagatlas.askDesactiverCheckbox') === 'true'
        setAsk(ask)
    }, [])

    const confirm = () => {
        if (ask)
            localStorage.setItem('diagatlas.askDesactiverCheckbox', 'true')
        else 
            localStorage.removeItem('diagatlas.askDesactiverCheckbox')
        props.next()
    }

    return (
        <MyModal
            label="Désactiver une action"
            okButton={ <Button onClick={ confirm }>Confirmer</Button> }
            cancelButton={ <Button onClick={ props.close }>Annuler</Button> }
        >
            <AtlasFlex column gap="l">
                <p>{ props.action.Action.Libelle }</p>

                <AtlasCheckbox
                    name="askDesactiverCheckbox"
                    value="askDesactiverCheckbox"
                    label="Ne plus me demander de confirmer"
                    checked={ ask }
                    onChange={ event => setAsk(event.target.checked) }
                />
            </AtlasFlex>
        </MyModal>
    )
}
