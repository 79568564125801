import { BaseTextField, getFieldState, useFormContext, useRender } from '@dsid-opcoatlas/reform'
import { AtlasFieldText } from 'atlas-ds'
import { formatSuffix } from 'components/format/Format'
import { BaseFieldProps } from './utils/BaseFieldProps'

export interface NameFieldProps<T extends object> extends BaseFieldProps<string, T> {
    firstName?: boolean
}

export default function NameField<T extends object>(props: NameFieldProps<T>) {
    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)

    const toModelValue = (value: string | null) => {
        if (value != null) {
            value = value.trim().replace(/ +/g, ' ').replace(/( *)?-+( *)?/g, '-').replace(/^-|-$/g, '')
            if (props.firstName)
                value = value.replace(/\s/, '\0').replace(/\s.*/g, '').replace(/\0/g, ' ')
            return value
        }
        return null
    }

    return <AtlasFieldText
        name={ props.name }
        label={ props.label }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        suffix={ props.suffix ? formatSuffix(props.suffix, true) : undefined }
        error={ fieldState.error }
        disabled={ props.disabled }
        inputConstructor={(inputProps) => <BaseTextField
            { ...inputProps }
            toModelValue={ toModelValue }
            acceptInputValue={ value => value.replaceAll(/[^ \-'A-Za-zÀ-ÖØ-öø-ž]/gu, '') === value }
            onChange={ props.onChange }
            onBlur={ props.onBlur }
            render={ render }
        />}
    />
}
