import { addDays, isBefore, startOfDay } from 'date-fns'
import log from 'loglevel'
import { useState } from 'react'
import { Document, pdf } from '@react-pdf/renderer'
import { AtlasButton, AtlasInfo, AtlasLink, AtlasSpacer, MyModal } from 'atlas-ds'
import { postAnnulerDossier } from 'api/dossierAPI'
import { dispatchAppEvent } from 'app/events'
import { ToastService } from 'components/BandeauAlert'
import { buildCerfaAnnuler } from 'components/annuler/generateCerfa'
import { parseDate } from 'components/format/Format'
import { detailDossierContratRefreshEvent, detailDossierDocumentsRefreshEvent, DossierActionProps } from './DetailDossierContrat'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'


export default function DossierAnnulerEntreprise(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    const [showAnnulerDossier, setShowAnnulerDossier] = useState(false)
    const [actionPending, setActionPending] = useState(false)
    const { profil } = useSelector((state: RootState) => state.profilState)
    const authName = `${profil?.Contact?.Email ?? ''}`

    const isConditionConformite = !(
        dossier.IndicateurConformite__c === 'Vert' &&
        dossier.DateReception__c != null &&
        isBefore(Date.now(), addDays(startOfDay(parseDate(dossier?.DateReception__c)!), 1))
    )
    
    const confirmAnnuler = async () => {
        setActionPending(true)
        try {
            const instance = pdf(<Document />);
            instance.updateContainer(buildCerfaAnnuler(dossier, authName));
            const blob = await instance.toBlob();

            await postAnnulerDossier(dossier?.NumeroDossier__c, 'DEMANDE_ANNULATION', blob, 'demande-annulation-' + dossier.NumeroDossier__c + '.pdf')
            log.debug(`Upload DEMANDE_ANNULATION  ok`)
            ToastService.showToast({
                label: 'Annuler dossier',
                type: 'success',
                content: "Votre demande d'annulation a bien été prise en compte. Elle sera traitée dans les meilleurs délais",
            })
        }
        catch (e) {
            log.error('Erreur annulation dossier ' + dossier.NumeroDossier__c, e)
            ToastService.showToast({
                label: 'Annuler dossier',
                type: 'error',
                content: 'Une erreur est survenue. Veuillez réessayer ultérieurement',
            })
        }

        // Attend 5 secondes et rafraîchit le statut et la liste des documents (récupération du récépissé)
        setTimeout(() => {
            dispatchAppEvent(detailDossierContratRefreshEvent)
            dispatchAppEvent(detailDossierDocumentsRefreshEvent)
            setActionPending(false)
            setShowAnnulerDossier(false)
        }, 5000)
    }

    return <>
        { isConditionConformite &&
            <AtlasButton onClick={ () => setShowAnnulerDossier(true) } level={ 2 }>
                Annuler le dossier
            </AtlasButton>
        }

        { showAnnulerDossier &&
        <MyModal show={showAnnulerDossier}
            label="Demande d'annulation"
            isPending={actionPending}
            okButton={<AtlasButton destructive onClick={confirmAnnuler}>Confirmer</AtlasButton>}
            cancelButton={<AtlasButton onClick={() => setShowAnnulerDossier(false)}>Annuler</AtlasButton>}>

            <AtlasInfo type="warning" title="Attention">
                <p>Le contrat d'apprentissage peut être annulé s'il n'a pas commencé. Cette fonction est à utiliser uniquement si le contrat ne s'exécutera finalement pas.</p>

                <p>Si vous souhaitez modifier un élément sur le dossier saisi, nous vous invitons à cliquer sur "Corriger le contrat".</p>

                <p>
                    Si le contrat a commencé, il peut être rompu mais pas annulé, voir la FAQ du site atlas :
                    <AtlasLink
                        external
                        level={ 3 }
                        href="https://www.opco-atlas.fr/faq/contrat-dapprentissage.html#question-reponse-faq-131"
                    >Quelles sont les modalités de rupture d'un contrat d'apprentissage ?</AtlasLink>
                </p>
            </AtlasInfo>

            <AtlasSpacer size="l" />

            <p>Confirmez-vous l'annulation de votre dossier N°{dossier.NumeroDossier__c} ?</p>
        </MyModal>
        }
    </>
}

