import { createContext } from 'react'
import { AppInfo, AppModule, RouteElement } from './appInfo'
import { Extension } from './ExtensionPoint'
import { AppType } from 'slices/contextSlice'

export interface AppContextType {
    appInfo: AppInfo
    enabledModules: AppModule[],
    resolvedRoutes: Map<string, RouteElement[]>
    context: Map<string, DetailsModuleContext<any>>
}

const AppContext = createContext<AppContextType>({} as AppContextType)

export default AppContext

export interface AppDetailModule {
    name: string
    extensions?: Record<string, Extension> & {
        [app in AppType]?: Record<string, Extension>
    }
}

export interface DetailsModuleContext<D> {
    findModule: (detail: D) => AppDetailModule | undefined
}
