import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from '@dsid-opcoatlas/yop'
import { ContactEntreprise, patchContactEntreprise, postAddContactEntreprise } from "api/profilAPI"
import { Entreprise } from "api/referencesAPI"
import { dispatchAppEvent } from "app/events"
import { MyForm, MyModal } from "atlas-ds"
import Button from "components/reform/Button"
import ErrorBanner from "components/reform/ErrorBanner"
import CheckboxField from "components/reform/inputs/CheckboxField"
import PhoneField from "components/reform/inputs/PhoneField"
import TextField from "components/reform/inputs/TextField"
import { OptionalBoolean, RequiredEmail, RequiredPhone, RequiredString } from "components/yop/constraints"
import { useState } from "react"


interface ContactEntrepriseModalProps {
    entreprise: Entreprise | null
    contact?: ContactEntreprise
    forceDefault?: boolean
    refreshEvent?: string
    onCreate?: (contact: ContactEntreprise) => void
    close: () => void
}

export default function ContactEntrepriseModal(props: ContactEntrepriseModalProps) {

    const [error, setError] = useState<string>()

    const modification = props.contact?.IdHeroku != null

    const initialValues: ContactEntreprise = {
        FirstName: props.contact?.FirstName ?? '',
        LastName: props.contact?.LastName ?? '',
        Phone: props.contact?.Phone ?? '',
        Email: props.contact?.Email ?? '',
        defaultCampus__c: props.contact?.defaultCampus__c ?? props.forceDefault ?? false
    }

    const validationSchema = Yop.object<ContactEntreprise>({
        LastName: RequiredString.max(80),
        FirstName: RequiredString.max(40),
        Phone: RequiredPhone,
        Email: RequiredEmail,
        defaultCampus__c: OptionalBoolean,
    })

    const catch409Error = (error: any) => {
        if (error?.response?.status === 409)
            setError(error.response.data?.message)
    }

    const formContext = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<ContactEntreprise>) => {
            if (modification) {
                patchContactEntreprise(props.contact!.IdHeroku!, props.entreprise!.Id, context.values)
                    .then(() => {
                        if (props.refreshEvent)
                            dispatchAppEvent(props.refreshEvent, "Votre contact a été modifié avec succès")
                        props.close()
                    })
                    .catch(catch409Error)
                    .finally(() => {
                        context.setSubmitting(false)
                    })
            }
            else {
                postAddContactEntreprise(props.entreprise!.IdHeroku, context.values)
                    .then((contact) => {
                        if (props.refreshEvent)
                            dispatchAppEvent(props.refreshEvent, "Votre contact a été ajouté avec succès")
                        props.onCreate?.(contact)
                        props.close()
                    })
                    .catch(catch409Error)
                    .finally(() => {
                        context.setSubmitting(false)
                    })
            }
        }
    })

    return (
        <MyModal label={ modification ? "Modifier un contact" : "Ajouter un contact" } onClose={ props.close } isPending={ formContext.submitting }>
            <Form context={ formContext } noValidate>
                <MyForm>
                    <MyForm.Grid>
                        <TextField name={'LastName'} label="Nom du contact" />
                        <TextField name={'FirstName'} label="Prénom du contact" />
                        <PhoneField name={'Phone'} label="Téléphone" />
                        <TextField name={'Email'} label="Adresse e-mail" />
                    </MyForm.Grid>

                    <CheckboxField name="defaultCampus__c" label=" Définir comme contact principal" disabled={ props.forceDefault } />

                    <ErrorBanner title="Erreur" message={ error } />

                    <MyForm.Actions>
                        <Button submit={ true } spinner={{spinning: formContext.submitting}}>Enregistrer</Button>
                        <Button level={ 3 } onClick={ () => props.close() }>Annuler</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}
