import { appInfo } from 'app/appInfo'
import { RootState } from 'app/rootReducer'
import { useSelector } from 'react-redux'

export default function Logo() {
    // Ne pas utiliser useAppContext, le logo est utilisé dans certains cas 
    // hors application (page d'erreur, maintenance, etc...)
    const { app } = useSelector((state: RootState) => state.contextState)
    return appInfo(app ?? 'entreprise').logo
}
