import { AtlasValues, MyContainer } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { DossierAF } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapTuteur<D extends DossierAF<any, any>>(props: RecapProps<D>) {
    const { navigateTo } = useDossierNavigation(props.metadata, props.navigationGuard)

    return <>
        <RecapSection
            title={ props.step.title ?? 'Tuteur' } 
            onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }
        >
            <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <FormElements metadata={ props.metadata } dossier={ props.dossier } path="tuteur" type="recap" />
                    </AtlasValues.Grid>
                </AtlasValues>
            </MyContainer>
        </RecapSection>
    </>
}
