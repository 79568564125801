import { ChildCategorie, findAnswers, getCategorieDefautKnowledge, getServices } from 'api/aideContactsAPI'
import useApiState from 'api/useApiState'
import useEventListener, { AppEvent, dispatchAppEvent } from 'app/events'
import { RootState } from 'app/rootReducer'
import { AtlasFlex, AtlasGrid, AtlasHeading, AtlasLoading, AtlasSpacer, MyCard, MyCentered } from 'atlas-ds'
import { Categories, categoriesSelectAllButtonEvent } from 'components/Categories'
import { HelpForm, searchQuestionsClearEvent } from 'components/HelpForm'
import Link from 'components/Link'
import PageHeader from "components/PageHeader"
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Questions } from '../../components/Questions'

export const aideContactCategorieChangeEvent = 'AideContacts:CategorieChange'
export const aideContactSearchChangeEvent = 'AideContacts:SearchChange'


export default function AideContacts(props: { title: string }) {

    const location = useLocation()
    const recherche = new URLSearchParams(location.search).get('recherche')
    const { app } = useSelector((state: RootState) => state.contextState)

    const [{ value: categorieDefautKnowledge }, withGetCategorieDefautKnowledge] = useApiState(getCategorieDefautKnowledge)
    const [{ pending: questionsPending, value: questions }, withFindQuestions] = useApiState(findAnswers)

    useEffect(() => {
        withGetCategorieDefautKnowledge().then((categorie) => {
            withFindQuestions({ categorie: categorie?.categorieSource ?? '', search: recherche ?? undefined, context: app ?? '' })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withGetCategorieDefautKnowledge, withFindQuestions, recherche])

    useEventListener(aideContactCategorieChangeEvent, (event: AppEvent<ChildCategorie>) => {
        withFindQuestions({ categorie: categorieDefautKnowledge?.categorieSource ?? '', childCategorie: event.detail?.name ?? '' , context: app ?? '' })
        dispatchAppEvent(searchQuestionsClearEvent)
    })

    useEventListener(aideContactSearchChangeEvent, (event: AppEvent<string>) => {
        withFindQuestions({ search: event.detail ?? '' , context: app ?? '' })
        dispatchAppEvent(categoriesSelectAllButtonEvent)
    })

    const [{ value: services }, withGetServices] = useApiState(getServices)
    useEffect(() => {
        withGetServices()
    }, [withGetServices])

    return (
        <>
            <PageHeader>{ props.title }</PageHeader>

            <AtlasFlex column gap="xxl">
                <AtlasFlex column gap="l">
                    <HelpForm search={ recherche ?? undefined } />

                    <MyCentered>
                        <p>Ou choississez une catégorie pour trouver rapidement l’aide qu’il vous faut</p>
                    </MyCentered>

                    <Categories sourceCategorie={categorieDefautKnowledge?.categorieSource} />
                </AtlasFlex>

                <MyCentered>
                    <Questions answers={ questions } loading={ questionsPending } />

                    <AtlasSpacer size="xxl" />

                    <AtlasHeading tag="h2" size="m">Vous avez toujours une question ?</AtlasHeading>
                    <p>Si vous n’avez pas trouvé une réponse à votre question avec notre assistance et nos guides, vous pouvez
                    toujours nous contacter en sélectionnant la thématique adéquate. Nous nous efforcerons de vous apporter
                    une réponse rapidement.</p>

                    <AtlasSpacer size="l" />

                    <AtlasGrid columnsCount={2} gap="l">
                        <AtlasLoading.Loader />
                        <AtlasLoading.Loader />
                        <AtlasLoading.Loader />
                        <AtlasLoading.Loader />
                        { services && Object.entries(services).map(([id, service]) => <MyCard
                            key={ id }
                            link={<Link to={`/services-${id}`} ariaLabel={`Accéder à ${service.label}`}>Accéder</Link>}
                            title={ service.label }
                        />) }
                    </AtlasGrid>
                </MyCentered>
            </AtlasFlex>
        </>
    )
}
