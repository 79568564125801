import { AtlasDetails, AtlasOptionsProps } from "atlas-ds";

export interface MyCompanySelectorProps {
  /**
   * Le label du sélecteur
   */
  label: string;
  /**
   * Le nom de l'entreprise actuellement sélectionnée
   */
  currentCompany: string;
  /**
   * Les entreprises sélectionnables
   */
  companies: React.ReactElement<AtlasOptionsProps>;
}

/**
 * Un sélecteur d'entreprise
 */
export function MyCompanySelector(props: MyCompanySelectorProps) {
  return (
    <div className="my-companySelector">
      <p className="my-companySelector__title">{props.label}</p>

      <AtlasDetails className="my-companySelector__cta">
        <summary>
          <p className="my-companySelector__current">{props.currentCompany}</p>
        </summary>
        {props.companies}
      </AtlasDetails>
    </div>
  );
}
