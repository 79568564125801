import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierProATuteur, ProATuteurMetadata } from 'pages/dossier-proa/ProAFormMetadata'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'


export default function Tuteur({ tuteur }: { tuteur: DossierProATuteur | null }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>LE TUTEUR</Text>

      <View style={styles.sectionContent}>
        <Text style={styles.bold}>
          Nom de naissance du tuteur au sein de l'établissement employeur :{' '}
        </Text>
        <CerfaElement metadata={ ProATuteurMetadata!.nom } value={ tuteur?.nom ?? '' } />
        <Text style={styles.bold}>
          Prénom du tuteur au sein de l'établissement employeur :{' '}
        </Text>
        <CerfaElement metadata={ ProATuteurMetadata!.prenom } value={ tuteur?.prenom ?? '' } />

        <Text>
          Date de naissance :{' '}
          <CerfaElement metadata={ ProATuteurMetadata!.dateNaissance } value={ tuteur?.dateNaissance ?? '' } />
        </Text>

        <View style={styles.sectionContent}>
          <CerfaElement metadata={ ProATuteurMetadata!.eligibilite } value={ tuteur?.eligibilite ?? '' } />
        </View>
      </View>
    </View>
  )
}
