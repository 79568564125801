import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { AtlasValue } from 'atlas-ds'
import { formatRef } from 'components/format/Format'
import { useSelector } from 'react-redux'

interface RecapRefFieldProps {
    label: string
    options: (refs: References, parent?: any, root?: any) => Reference[] | undefined
    value: string | null | undefined
    parent?: any
    root?: any
    code?: boolean
    reduit?: boolean
}

export default function RecapRefField(props: RecapRefFieldProps) {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const options = refs ? (props.options(refs, props.parent, props.root) ?? []) : []

    return <AtlasValue label={ props.label }>
        {formatRef(options, props.value, {
            code: props.code,
            libelle: props.reduit ? 'reduit' : 'complet'
        })}
    </AtlasValue>
}

