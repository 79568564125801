import { AtlasButton, AtlasCheckbox, AtlasFlex, AtlasHtml, MyModal } from 'atlas-ds'
import { useState } from 'react'

export default function DossierCAPPInformationsModal() {

    const SHOW_INFORMATIONS_MODAL_CAPP_ID = "show-information-modal-capp"
    const storedShow: string | null = localStorage.getItem(SHOW_INFORMATIONS_MODAL_CAPP_ID)
    
    const [show, setShow] = useState<boolean>(storedShow === null || storedShow === 'true')
    const [checked, setChecked] = useState<boolean>(false)

    function valider() {
        localStorage.setItem(SHOW_INFORMATIONS_MODAL_CAPP_ID, (!checked).toString())
        setShow(false)
    }

    return (
        <MyModal
            show={show}
            onClose={() => setShow(false)}
            label="Informations importantes"
            okButton={<AtlasButton onClick={valider}>Valider</AtlasButton>}
        >
            <AtlasFlex column gap="l">
                <AtlasHtml>
                    <p>Vous allez démarrer la saisie d’un contrat d’apprentissage en vue de son financement et de son dépôt par l’OPCO auprès des services de l’état en charge de la formation professionnelle.</p>
                    <p>Assurez-vous en amont de disposer de la convention de formation, elle est nécessaire pour renseigner les informations relatives à la formation et à son financement, et doit être jointe au dossier à l’issue de la saisie.</p>
                    <br/>
                    <p><strong>Les étapes à suivre pour la transmission du contrat sur myAtlas :</strong></p>
                    <ul>
                        <li>Saisie des informations demandées sur le formulaire</li>
                        <li>Ajout des pièces obligatoires (convention de formation, le cas échéant convention de réduction de la durée du parcours, …)</li>
                        <li>Vérification des éléments à l’étape récapitulatif et génération du formulaire cerfa</li>
                        <li>Sauvegarde du dossier sur myAtlas dans les brouillons</li>
                        <li>Recueil du visa de l’organisme de formation en apprentissage et des signatures sur le formulaire cerfa avant la date de début du contrat</li><li>Validation de l’envoi du contrat sur myAtlas, au plus tard dans les cinq jours qui suivent la date de début du contrat.</li>
                    </ul>
                    <p>Nous attirons votre attention sur le fait qu’il n’est pas nécessaire de joindre le cerfa original signé pour l’envoi. En effet, les informations transmises sur myAtlas sont réputées conformes au contrat conclu avec l’apprenti(e).</p>
                    <p>Pour vous guider dans la conclusion du contrat et garantir sa conformité pour le dépôt dans les meilleurs délais auprès des services de l’état,  une saisie assistée alerte sur une information non conforme pour le dépôt du contrat ou attire votre attention sur une disposition réglementaire importante.</p>
                    <p>Lors de l’instruction du contrat, nos services pourront si besoin, vous demander des éléments complémentaires.</p>
                    <br/>
                    <p><strong>Quelques points de vigilance :</strong></p>
                    <ul>
                        <li>L’établissement de l’entreprise sélectionnée doit correspondre strictement <em>au lieu d’exécution du contrat et à la DSN de l’entreprise</em></li>
                        <li>La date de conclusion du contrat s'entend comme étant celle du jour où les signatures des parties au contrat sont recueillies(employeur, apprenti(e), voire représentant légal). <em>Elle ne peut être postérieure à la date de début du contrat.</em></li>
                    </ul>
                    <p>Enfin, si vous avez un doute sur l’information attendue, pensez à consulter les infobulles, vous pouvez également contacter nos services.</p>
                </AtlasHtml>

                <AtlasCheckbox name="seen" value="seen" label="Ne plus afficher" onChange={(e) => setChecked(e.target.checked)} />
            </AtlasFlex>
        </MyModal>
    )
}
