import { AdresseEntreprise, getAdressesEntreprise } from 'api/profilAPI'
import { Entreprise } from 'api/referencesAPI'
import useApiState from 'api/useApiState'
import useEventListener from 'app/events'
import { AtlasButton, AtlasHeading, AtlasLoading, MyTable } from 'atlas-ds'
import DeleteAdresseModal from 'pages/gestion-compte-entreprise/DeleteAdresseModal'
import { useEffect, useState } from 'react'

import { AppEvent } from 'app/events'
import { ToastService } from 'components/BandeauAlert'
import AdresseEntrepriseModal from './AdresseEntrepriseModal'

export const adresseRefreshEvent = "ListAdresses:Refresh"

export default function AdressesCompteEntreprise(props: {
    entreprise: Entreprise | null,
    editing: boolean,
    showAddAdresse: boolean,
    setShowAddAdresse: (e: boolean) => void
}) {

    const [updateAdresse, setUpdateAdresse] = useState<AdresseEntreprise | null>(null)
    const [deleteAdresse, setDeleteAdresse] = useState<AdresseEntreprise | null>(null)

    const [{ pending, value: adresses, error }, withGetAdressesEntreprise] = useApiState(getAdressesEntreprise)

    const PAR_PAGE_ADRESSE = 100

    useEffect(() => {
        if (props.entreprise?.IdHeroku)
            withGetAdressesEntreprise(props.entreprise.IdHeroku, '', undefined, PAR_PAGE_ADRESSE)
    }, [withGetAdressesEntreprise, props.entreprise?.IdHeroku])

    useEventListener(adresseRefreshEvent, (event: AppEvent<string>) => {
        ToastService.showToast({
            label: "Adresses de l'entreprise",
            content: event.detail
        })
        withGetAdressesEntreprise(props.entreprise?.IdHeroku!, '', undefined, PAR_PAGE_ADRESSE)
    })

    const adressesActions = (adresse: AdresseEntreprise) => {
        const actions = []
        if (!props.editing) {
            actions.push(<AtlasButton
                key="supprimer"
                icon="trash"
                level={ 3 }
                onClick={ () => setDeleteAdresse(adresse) }
            >Supprimer</AtlasButton>)
            actions.push(<AtlasButton
                key="modifier"
                icon="pen"
                level={ 3 }
                onClick={ () => setUpdateAdresse(adresse) }
            >Modifier</AtlasButton>)
        }
        return actions
    }

    return (
        <>
            <div>
                <AtlasHeading
                    tag="h2"
                    size="m"
                    button={<AtlasButton
                        onClick={() => props.setShowAddAdresse(true)}
                        level={3}
                        icon="plus"
                        disabled={props.editing}
                    >Ajouter une adresse</AtlasButton>}
                >Adresses</AtlasHeading>

                <AtlasLoading loading={pending}>
                    <MyTable
                        caption="Adresses"
                        rows={adresses ?? []}
                        emptyText="Aucune adresse n'est associé à cette entreprise pour le moment."
                        error={error ?? undefined}
                        rowActions={adressesActions}
                        columns={[
                            {
                                id: "Adresse3__c",
                                label: "Adresse",
                                value: (row: AdresseEntreprise) => row.Adresse3__c
                            },
                            {
                                id: "Ville__c",
                                label: "Commune",
                                value: (row: AdresseEntreprise) => `${row.Ville__c} (${row.CodePostal__c})`
                            },
                        ]}
                    />
                </AtlasLoading>

                { props.showAddAdresse &&
                <AdresseEntrepriseModal entreprise={props.entreprise} close={() => props.setShowAddAdresse(false)} refreshEvent={adresseRefreshEvent} />
                }

                { updateAdresse &&
                <AdresseEntrepriseModal entreprise={props.entreprise} adresse={ updateAdresse } close={() => setUpdateAdresse(null)} refreshEvent={adresseRefreshEvent} />
                }

                { deleteAdresse &&
                <DeleteAdresseModal adresse={deleteAdresse} refreshEvent={adresseRefreshEvent} close={() => setDeleteAdresse(null)} />
                }
            </div>
        </>
    )
}
