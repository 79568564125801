import { DISPOSITIF_ACN } from 'api/references'
import { RootState } from 'app/rootReducer'
import { AtlasHeading, AtlasInfo, MyForm } from 'atlas-ds'
import { DossierACN } from 'pages/dossier-acn/ACNFormMetadata'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useSelector } from 'react-redux'
import { BaseDossier } from 'slices/dossierCommon'


interface RecapSignatureProps<D extends BaseDossier> {
    capp?: boolean
    campus?: boolean
    metadata: FormMetadata<D>
    dossier: D | null
}

export default function RecapSignature<D extends BaseDossier>(props: RecapSignatureProps<D>) {
    
    const { fulfilled: config } = useSelector((state: RootState) => state.configState)

    const fne = props.dossier?.DispositifFO__c === DISPOSITIF_ACN && ((props.dossier as unknown as DossierACN)?.modules?.[0]?.fne ?? false)

    return (<>
        { fne && <AtlasInfo title="Information relative au FNE-Formation" >
            Cette action de formation bénéficie du soutien de l’Etat au titre du FNE-Formation.<br/><br/>
            Elle concourt à la prise en compte et l’accélération des transitions numérique et/ou écologique.<br/><br/>
            En confirmant ma demande de prise en charge, je valide la mobilisation du soutien de l’Etat au titre du FNE-Formation pour cette action. En contrepartie, j’atteste sur l’honneur avoir pris connaissance des obligations liées au bénéfice de cette aide publique notamment de la régularité de la situation fiscale et sociale de l’organisme que je représente. Je m’engage à conserver et tenir à disposition pendant 10 ans les pièces relatives au financement et à la réalisation de cette action de formation.
            Plus d’informations sur le FNE-Formation : <a href="https://www.opco-atlas.fr/entreprise/beneficier-du-fne-formation.html" target='_blank' rel='noreferrer'>https://www.opco-atlas.fr/entreprise/beneficier-du-fne-formation.html</a>.<br/><br/>
            En cas de refus ou d’impossibilité à tenir les engagements évoqués ci-dessus, merci de contacter votre conseiller pour envisager d’autres modalités de financement de votre formation.
        </AtlasInfo> }

        <div>
            <AtlasHeading tag="h2" size="m">Envoi</AtlasHeading>

            <MyForm.Grid>
                <FormElements metadata={ props.metadata } path="signature" type="input" />
            </MyForm.Grid>
        </div>
            
        { props.capp === true ?
        <p>
            atteste :<br />
            — de l'exactitude des informations mentionnées dans la précédente demande<br />
            — que les informations saisies dans le formulaire sont conformes aux informations du contrat conclu avec l'apprenti(e)<br />
            — que le Cerfa est visé par l’organisme de formation en apprentissage<br />
            — que le Cerfa est signé par les parties (employeur, apprenti(e) et à son représentant légal le cas échéant) et m’engage :<br />
            — à tenir les pièces justificatives à disposition des services de contrôle (OPCO, administration)<br />
            — avoir pris connaissance et accepte <a href={ config?.ConditionsGenerales?.lien ?? '' } target="_blank" rel="noreferrer">les conditions générales en vigueur</a>.
        </p>
        : (props.campus ?
        <p>
            En ma qualité de représentant habilité de l'entreprise, je certifie l'exactitude des renseignements portés sur le formulaire
            et atteste avoir pris connaissance et accepter sans réserve <a href={ config?.ConditionsGeneralesCampus?.lien ?? '' } target="_blank" rel="noreferrer"> les conditions générales d'utilisation du site campusAtlas</a>.<br/>
            J'ai bien noté qu'Atlas transmettra mes coordonnées à l'organisme de formation pour l'organisation de la session dans le respect du RGPD.
        </p> 
        :
        <p>
            En ma qualité de représentant légal de l'entreprise, je certifie l'exactitude des renseignements portés sur le formulaire
            et atteste avoir pris connaissance et accepté <a href={ config?.ConditionsGenerales?.lien ?? '' } target="_blank" rel="noreferrer">les conditions générales en vigueur</a>.
        </p>
        ) }
    </>)
}