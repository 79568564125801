import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Parametre, getParametres } from 'api/parametresAPI'


interface ParametresApiState {
    pending: boolean
    bandeaux: Parametre[] | null
    bandeauxBancaires: Parametre[] | null
    boiteAOutils: Parametre[] | null
    rejected: string | null
}

const loadBandeauxInformations = createAsyncThunk(
    'parametres/getBandeauxInformations',
    async() => getParametres("BANDEAUX_INFORMATIONS")
)

const loadBandeauxBancaires = createAsyncThunk(
    'parametres/getBandeauxBancaires',
    async() => getParametres("BANDEAUX_INFORMATIONS_BANCAIRES")
)

const loadBoiteAOutils = createAsyncThunk(
    'parametres/getBoiteAOutils',
    async() => getParametres("BOITE_A_OUTILS")
)

const slice = createSlice({
    name: 'profil',
    initialState: { pending: false, bandeaux: null, rejected: null } as ParametresApiState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadBandeauxInformations.pending, (state, _) => {
                state.pending = true
            })
            .addCase(loadBandeauxInformations.fulfilled, (state, action) => {
                state.bandeaux = action.payload
                state.pending = false
                state.rejected = null
            })
            .addCase(loadBandeauxInformations.rejected, (state, action) => {
                state.bandeaux = []
                state.rejected = action.error.message ?? null
                state.pending = false
            })
            .addCase(loadBandeauxBancaires.pending, (state, _) => {
                state.pending = true
            })
            .addCase(loadBandeauxBancaires.fulfilled, (state, action) => {
                state.bandeauxBancaires = action.payload
                state.pending = false
                state.rejected = null
            })
            .addCase(loadBandeauxBancaires.rejected, (state, action) => {
                state.bandeauxBancaires = []
                state.rejected = action.error.message ?? null
                state.pending = false
            })
            .addCase(loadBoiteAOutils.pending, (state, _) => {
                state.pending = true
            })
            .addCase(loadBoiteAOutils.fulfilled, (state, action) => {
                state.boiteAOutils = action.payload
                state.pending = false
                state.rejected = null
            })
            .addCase(loadBoiteAOutils.rejected, (state, action) => {
                state.boiteAOutils = []
                state.rejected = action.error.message ?? null
                state.pending = false
            })
    }
})


export const parametresSlice = {
    slice
}

export const parametresActions = {
    loadBandeauxInformations: loadBandeauxInformations,
    loadBandeauxBancaires: loadBandeauxBancaires,
    loadBoiteAOutils: loadBoiteAOutils
}
