import { useFormContext } from '@dsid-opcoatlas/reform'
import { Profil } from 'api/profilAPI'
import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormContentProps, FormMetadata, FormStep } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA, SECTION_MODULE_OF } from 'pages/dossier/metadata/ModulesMetadata'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { DossierWithModules } from 'slices/dossierCommon'

export function dossierModulesInitializer<D extends DossierWithModules>(dossier: D, metadata: FormMetadata<D>, _: Profil | null, step: FormStep<D>) {
    if (dossier.modules.length === 0)
        dossier.modules = [metadata.api.createModule!()]
    return dossier
}

export function dossierMultiModulesInitializer<D extends DossierWithModules>(dossier: D, metadata: FormMetadata<D>, _: Profil | null, step: FormStep<D>) {
    return dossier
}

export function DossierModulesElements<D extends DossierWithModules>(props: FormContentProps<D> & {
    index?: number
    modulesProperty?: string
}) {

    const modulesProperty = props.modulesProperty ?? 'modules'

    const form = useFormContext<D>()
    const index = props.index ?? 0
    const module = (form.values as { [key: string]: any })?.[modulesProperty]?.[index]

    return (
        <>
            { module && <>
                <FormElements metadata={ props.metadata } section={ SECTION_MODULE_OF } path={ modulesProperty } type="input" index={ index } />
                <MyForm.Grid>
                    <FormElements metadata={ props.metadata } section="" path={ modulesProperty } type="input" index={ index } />
                </MyForm.Grid>
            </> }
            { module && module.formationInterne !== null && props.step.sections.includes(SECTION_MODULE_AGORA) &&
            <MyForm.Fieldset
                legend="Informations complémentaires AGORA"
                tooltipContent={ TOOLTIPS.capp.formation.agora }
            >
                <MyForm.Grid>
                    <FormElements metadata={ props.metadata } section={ SECTION_MODULE_AGORA } path={ modulesProperty } type="input" index={ index } />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }
        </>
    )
}
