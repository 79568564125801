import { cloneElement, ReactNode } from 'react'
import { useAppContext } from './useAppContext'

export type Extension = {
    [name: string]: Record<string, any>
}

interface ExtensionPointProps<X extends Extension> {
    name: keyof X
    point: keyof X[keyof X]
    detail?: object | null
    extensions?: JSX.Element[] | ((detail: any, extensions: JSX.Element[]) => void)
    children?: (items: JSX.Element | JSX.Element[] | undefined) => ReactNode
}

export default function ExtensionPoint<X extends Extension>(props: ExtensionPointProps<X>) {
    const appContext = useAppContext()

    const { name, point, detail, extensions: initialExtensions, ...extraProps } = props
    const prefix = 'REACT_APP_' + appContext.appInfo.id + '_' + point.toString().toUpperCase() + '_'

    // Cherche les extensions sur tous les modules actifs
    const extensions = appContext?.enabledModules
        ?.flatMap(m => {
            // Recherche dans le module app 
            // extensions.bloc.point
            // puis extension.app.bloc.point
            const allExts = [] as JSX.Element[]

            if (props.detail && appContext.context.has(m.id)) {
                const exts = [] as JSX.Element[]

                // Puis dans le module detail
                // extensions.bloc.point puis extensions.app.bloc.point
                const dm = appContext.context.get(m.id)?.findModule(props.detail)
                const dext = (dm?.extensions as any)?.[name]?.[point]
                if (typeof dext === 'function' && dext.name === point) {
                    dext(props.detail, exts)
                } else if (Array.isArray(dext)) {
                    exts.push(...dext)
                } else if (dext) {
                    exts.push(dext)
                }
                const dappext = (dm?.extensions as any)?.[appContext.appInfo.app as string]?.[name]?.[point]
                if (typeof dappext === 'function' && dappext.name === point) {
                    dappext(props.detail, exts)
                } else if (Array.isArray(dappext)) {
                    exts.push(...dappext)
                } else if (dappext) {
                    exts.push(dappext)
                }

                if (dm) {
                    const prefixm = 'REACT_APP_' + appContext.appInfo.id + '_' + dm.name + '_' + point.toString().toUpperCase() + '_'
                    allExts.push(...exts.filter(x => import.meta.env[prefixm + x.key?.toUpperCase()] !== 'false'))
                } else {
                    allExts.push(...exts)
                }
            }

            const appext = (((m.extensions as any)?.[appContext.appInfo.app as string] as any)?.[name] as any)?.[point]
            if (typeof appext === 'function' && appext.name === point) {
                appext(props.detail, allExts)
            } else if (Array.isArray(appext)) {
                allExts.push(...appext)
            } else if (appext) {
                allExts.push(appext)
            }
            const ext = ((m.extensions as any)?.[name] as any)?.[point]
            if (typeof ext === 'function' && ext.name === point) {
                ext(props.detail, allExts)
            } else if (Array.isArray(ext)) {
                allExts.push(...ext)
            } else if (ext) {
                allExts.push(ext)
            }
            
            return allExts.filter(ext => import.meta.env[prefix + ext.key?.toUpperCase()] !== 'false')
        })
        ?.filter(e => e != null)

    if (Array.isArray(initialExtensions)) {
        extensions.push(...initialExtensions)
    } else if (typeof initialExtensions === 'function') {
        initialExtensions(props.detail, extensions)
    }
    
    // Ajoute les props supplémentaires
    const expanded = extensions?.map((extension: any) => {
        if (detail && extension.props.when && !extension.props.when(detail)) return null
        return detail || extraProps ? cloneElement(extension, { ...extension.props, detail, ...extraProps }) : extension
    })

    if (!expanded) {
        return undefined
    }

    if (props.children) {
        return props.children(expanded)
    }
    return expanded
}
