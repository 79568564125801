import { ValidationContext, Yop } from '@dsid-opcoatlas/yop'
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import ExportOrganismeFormation from 'pages/dossier/export/ExportOrganismeFormation'
import RecapOrganismeFormation from 'pages/dossier/recap/RecapOrganismeFormation'
import { BaseDossier, BaseDossierModule, DossierCFA } from 'slices/dossierCommon'
import { Ignored, OptionalString, RequiredString, AdresseValidationFieldsParams, RequiredBoolean } from 'components/yop/constraints'
import OrganismeFormationFields from 'components/fields/OrganismeFormationFields'
import { OptionalSiret, RequiredSiret } from './SiretFieldMetadata'
import { DISPOSITIF_PACC_RH } from 'api/references'
import { OFSearchOptions } from 'api/commonAPI'

export const OptionalUAI = OptionalString.max(8).matches(/^(\d){7}[A-Z]{1}$/, 'Format de numéro UAI incorrect (7 chiffres puis 1 lettre)');
export const RequiredUAI = RequiredString.max(8).matches(/^(\d){7}[A-Z]{1}$/, 'Format de numéro UAI incorrect (7 chiffres puis 1 lettre)');
export const OptionalDA = OptionalString.matches(/^[0-9A-Z]{11}$/, 'Format de numéro DA incorrect (11 caractères)');
export const RequiredDA = RequiredString.matches(/^[0-9A-Z]{11}$/, 'Format de numéro DA incorrect (11 caractères)');

export type CFAFieldMetadataProps = {
    noCreate?: boolean
    da?: boolean
    entreprise?: boolean
    nomLabel?: string
    selectLabel?: string
    uai?: boolean
    uaiLabel?: string
    uaiOptional?: boolean
    siretLabel?: string
    siretOptional?: boolean
    adresseRequired?: boolean
    communeEnabled?: boolean
    forceDisabled?: boolean
    moduleSecondaire?: boolean
    adresse?: boolean
    adresseValidationFieldParams?: any
    searchOptions?: OFSearchOptions
    nomTooltip?: string | JSX.Element
    requiredIf?: (ctx: ValidationContext<DossierCFA | null, any, any>) => boolean
    requiredMessage?: string
}

export function CFAFieldMetadata(settings: SettingsFieldMetadata<DossierCFA | null, CFAFieldMetadataProps> = {}) {
    const adresseValidation = settings?.props?.adresseValidationFieldParams ?? AdresseValidationFieldsParams
    const requiredIf = settings.props?.requiredIf ?? (context => context.parent?.formationInterne !== true)
    const requiredMessage = settings.props?.requiredMessage ?? (settings?.props?.noCreate
        ? "Vous devez sélectionner un organisme de formation" 
        : "Vous devez sélectionner un organisme de formation ou en créer un nouveau"
    )
    const fieldMetadata = createMetadata(
        {
            yop: Yop.object<DossierCFA>({
                nom: RequiredString.max(200),
                siret: settings?.props?.siretOptional ? OptionalSiret : (OptionalSiret.when<DossierCFA>(ctx => ctx.parent?.etat === '2' ? null : RequiredSiret)),
                entreprise: settings?.props?.entreprise ? RequiredBoolean : Ignored,
                etat: Ignored,
                numeroUAI: settings?.props?.uai ? (settings?.props?.uaiOptional ? OptionalUAI : RequiredUAI) : Ignored,
                numeroDA: OptionalDA.when<DossierCFA, BaseDossier>(ctx => settings.props?.da === false || ctx.parent?.etat === '2' || ctx.root?.DispositifFO__c === DISPOSITIF_PACC_RH || ctx.parent?.EtablissementOFId__c !== null ? null : RequiredDA),
                appartement: OptionalString.max(adresseValidation.Apt),
                batiment: OptionalString.max(adresseValidation.Bat),
                adresse: settings?.props?.adresseRequired ? RequiredString.max(adresseValidation.Adr) : OptionalString.max(adresseValidation.Adr),
                complement: OptionalString.max(adresseValidation.Cpt),
                codePostal: RequiredString.max(adresseValidation.Cp)
                    .when<DossierCFA>(ctx => ctx.parent?.etat === '2' ? null : RequiredString
                        .max(adresseValidation.Cpt).matches(adresseValidation.CpRegex, adresseValidation.CpMessage)),
                commune: RequiredString.max(adresseValidation.Com),
            }).defined().requiredIf<BaseDossierModule>(requiredIf, requiredMessage),
            input: OrganismeFormationFields,
            recap: RecapOrganismeFormation,
            export: ExportOrganismeFormation,
        },
        settings,
    )
    return fieldMetadata
}
