import { AtlasValue } from 'atlas-ds'
import { formatCurrency } from 'components/format/Format'

interface RecapCurrencyFieldProps {
    label: string,
    value: string | number | undefined | null,
    suffix?: string,
}

export default function RecapCurrencyField(props: RecapCurrencyFieldProps) {
    const suffix = props.suffix && props.suffix.startsWith("€") ? props.suffix.substring(1) : props.suffix

    return <AtlasValue label={ props.label }>
        { formatCurrency(props.value, suffix) }
    </AtlasValue>
}
