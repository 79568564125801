import { AppModule, AppState, campusEnabled } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import DetailFormation from 'pages/detail-formation/DetailFormation'
import DetailSession from 'pages/detail-session/DetailSession'
import NouvelleSession from 'pages/detail-session/NouvelleSession'
import MesFormations from './MesFormations'

const formationsModule = () => {
    return {
        id: 'mes-formations',
        isEnabled: (appContext: AppState) => campusEnabled(appContext),
        routes: [
            <AppRoute key="formations" path="/mes-formations" component={MesFormations} label="Formations campusAtlas" menuIcon="formations" />,
            <AppRoute key="formations-detail" path="/detail-formation/:idformation" component={DetailFormation} />,
            <AppRoute key="formations-add-session" path="/nouvelle-session/:idformation/:idsession?" component={NouvelleSession} />,
            <AppRoute key="formations-detail-session" path="/detail-session/:idsession" component={DetailSession} />
        ],
    } as AppModule
}

export default formationsModule
