import { DISPOSITIF_CONTRAT_PROFESSIONNALISATION } from "api/references"
import { DossierPointReserve, createAdresse, createBaseDossier, createBaseDossierModule, createBaseDossierTuteur, createDossierEmployeurExtended, createDossierStagiaire, nil } from "slices/dossierCommon"

function createDossierCPROEmployeur() { return {
    ...createDossierEmployeurExtended(),
    caisseRetraiteComplementaire: nil as string,
    organismePrevoyance: null as (string | null),
    effectifSalarie: nil as number,
}}
export type DossierCPROEmployeur = ReturnType<typeof createDossierCPROEmployeur>

export function createDossierCPROModule() { return {
    ...createBaseDossierModule(),
    attestationFormationInterne: nil as (boolean | null),
    formationAfest: false,
    attestationFormationAfest: nil as (boolean | null),
    typeQualification: nil as string,
    codeRNCP: nil as (string | null),
    specialiteFormation: nil as string,
    dureeEnseignement: nil as number,
    fraisAnnexesInternational: nil as boolean,
    conditionRealisation: nil as (string | null),
    modalitePedagogiqueAgora: nil as string,
    adresseRealisationIdentiqueAgora: true,
    lieuPrincipalFormationAgora: createAdresse(),
}}
export type DossierCPROModule = ReturnType<typeof createDossierCPROModule>


export function createDossierCPROSalarie() { return {
    ...createDossierStagiaire(),
    adresse: createAdresse(),
    naissanceFrance: true as boolean,
    communeNaissance: nil as string,
    departementNaissance: nil as string,
    // Salarie
    statut: nil as string,
    poleEmploi: nil as boolean,
    numeroPoleEmploi: nil as (string | null),
    dureePoleEmploi: nil as (number | null),
    typeMinimumSocial: nil as (string | null),
    niveauMaximumClasseEntreeFormationAgora: nil as string,
}}
export type DossierCPROSalarie = ReturnType<typeof createDossierCPROSalarie>


export function createDossierCPROTuteur() { return {
    ...createBaseDossierTuteur(),
    eligibilite: nil as boolean,
    employeurDirigeant: false,
    priseEnChargeFonctionTutorale: false,
    exerciceFonction2Annees: false as (boolean | null),
}}
export type DossierCPROTuteur = ReturnType<typeof createDossierCPROTuteur>

export function createDossierCPROContrat() { return { 
    ...({} as {
        Id?: string | null
    }),
    natureContrat: nil as string,
    typeContrat: nil as string,
    emploiOccupe: nil as string,
    dateDebut: nil as Date,
    dateFin: nil as Date,
    dateConclusion: nil as Date,
    dureePeriodeEssai: nil as number,
    dureeHebdomadaireTravail: { hours: nil as number, minutes: nil as number },
    salaireEmbauche: nil as number,
    classificationEmploi: nil as (string | null),
    niveau: nil as string,
    coefficient: nil as (string | null),
    financementPdc: false,
    strategiePlus: false,
    dateEffet: nil as (Date | null),
    dateConclusionAvenant: nil as (Date | null),
}}
export type DossierCPROContrat = ReturnType<typeof createDossierCPROContrat>


export function createDossierCPRO(dispositif?: string) { return {
    ...createBaseDossier(dispositif ?? DISPOSITIF_CONTRAT_PROFESSIONNALISATION),
    employeur: createDossierCPROEmployeur(),
    modulePrincipal: nil as DossierCPROModule,
    moduleAfest: null as (DossierCPROModule | null),
    salarie: nil as DossierCPROSalarie,
    tuteur: nil as DossierCPROTuteur,
    contrat: createDossierCPROContrat(),
    pointsReserve: null as DossierPointReserve[] | null | undefined,
}}
export type DossierCPRO = ReturnType<typeof createDossierCPRO>
