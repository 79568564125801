
import { AtlasValue } from 'atlas-ds'
import { formatRef } from 'components/format/Format'
import { useIdcc } from 'components/reform/inputs/utils/useIdcc'

interface RecapCodeIdccFieldProps {
    label: string,
    value: string,
}

export default function RecapCodeIdccField(props: RecapCodeIdccFieldProps) {
    const refsIdcc = useIdcc()

    return <AtlasValue label={ props.label }>
        { props.value } - { formatRef(refsIdcc, props.value) }
    </AtlasValue>
}
