import { useState } from 'react'
import log from 'loglevel'
import { AtlasButton, MyModal } from 'atlas-ds'
import { patchAnnulerDossier } from 'api/dossierAPI'
import { dispatchAppEvent } from 'app/events'
import { ToastService } from 'components/BandeauAlert'
import { detailDossierContratRefreshEvent, detailDossierDocumentsRefreshEvent, DossierActionProps } from './DetailDossierContrat'
import { checkDetailAction } from 'pages/detail-common/Detail'


export default function DossierAnnuler(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const [showAnnulerDossier, setShowAnnulerDossier] = useState(false)
    const [actionPending, setActionPending] = useState(false)

    const confirmAnnuler = () => {
        setActionPending(true)
        patchAnnulerDossier(props.detail!.NumeroDossier__c, JSON.stringify({ 'Motif': ' ' })).then(_ => {
            ToastService.showToast({
                label: 'Annuler dossier',
                type: 'success',
                content: 'Votre demande d\'annulation a bien été prise en compte. Elle sera traitée dans les meilleurs délais',
            })
        }).catch(e => {
            log.error('Erreur annulation dossier ' + dossier.NumeroDossier__c, e)
            ToastService.showToast({
                label: 'Annuler dossier',
                type: 'error',
                content: 'Une erreur est survenue. Veuillez réessayer ultérieurement',
            })
        }).finally(() => {
            // Attend 5 secondes et rafraîchit le statut et la liste des documents (récupération du récépissé)
            setTimeout(() => {
                dispatchAppEvent(detailDossierContratRefreshEvent)
                dispatchAppEvent(detailDossierDocumentsRefreshEvent)
                setActionPending(false)
                setShowAnnulerDossier(false)
            }, 5000)
        })
    }

    return <>
        <AtlasButton level={ 2 } onClick={() => setShowAnnulerDossier(true)}>
            Annuler le dossier
        </AtlasButton>

        <MyModal show={showAnnulerDossier}
            label="Demande d'annulation"
            isPending={actionPending}
            okButton={<AtlasButton destructive onClick={confirmAnnuler}>Confirmer</AtlasButton>} 
            cancelButton={<AtlasButton onClick={() => setShowAnnulerDossier(false)}>Annuler</AtlasButton>}
        >
            <p>Confirmez-vous l'annulation de votre dossier N°{dossier.NumeroDossier__c} ?</p>
        </MyModal>
    </>
}
