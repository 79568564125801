import { RootState } from 'app/rootReducer'
import Link from 'components/Link'
import { useSelector } from 'react-redux'


export default function LinkGrille() : any {
    const { fulfilled: config } = useSelector((state: RootState) => state.configState)
    return <Link icon="arrow-right" href={config?.GrilleRemunerationCPRO.lien } level={ 3 }>
        Consulter la grille des rémunérations
    </Link>
}


export const TOOLTIPS = {
    employeur: {
        codeIdcc: <p>
            Identifiant de la convention collective de branche applicable par l’établissement dans le cadre de l’exécution du contrat ou à défaut de la convention
            d’entreprise (non adaptive d’une convention de branche) ou enfin dans le cas de certaines grandes entreprises du code du statut.
            <br/>
            <br/>
            Ce code peut être obtenu sur le site du Ministère du Travail <a href="https://travail-emploi.gouv.fr/dialogue-social/negociation-collective/article/conventions-collectives-nomenclatures" target="_blank" rel="noopener noreferrer">
            travail-emploi.gouv.fr</a>. S’il n’y a pas de convention collective veuillez indiquer le code 9999, si la convention collective est en cours de négociation,
            veuillez indiquer le code 9998.
            </p>,
        caisseRetraiteComplementaire: "Le rattachement à une caisse de retraite complémentaire est une obligation de l’employeur dès la première embauche d’un salarié. Rapprochez-vous de votre comptable ou de l’URSSAF.",
        effectifSalarie: "L’effectif renseigné est celui de l’entreprise dans sa globalité, au sens de l’article L. 130-1.-I du code de la sécurité sociale et non seulement l’effectif de l’établissement d’exécution du contrat.",
        contact: "Préciser les coordonnées d’une personne en charge de la formation dans votre entreprise, disponible pour d’éventuels compléments d’information.",
        contactEntreprise: "Préciser les coordonnées d’une personne en charge de la formation dans l'entreprise cliente, disponible pour d’éventuels compléments d’information.",
    },
    salarie: {
        nationalite: "S'assurer que le salarié étranger, non citoyen européen, dispose au début du contrat d’un titre de séjour valable l‘autorisant à travailler en France et d’une autorisation de travail délivrée dans le cadre de l’article L. 5221-5 du code du travail.",
        heuresHTT: "Heures Hors Temps de Travail",
        heuresFoad: "Nombre d’heures de formation se déroulant à distance",
        heuresAfest: "Parcours de formation dispensé sur le poste de travail",
        nir: <p>
            Numéro de sécurité sociale Français, officiellement appelé Numéro d'Inscription au Répertoire des personnes physiques. Ce numéro est notamment inscrit sur la carte Vitale, en dessous du nom et du prénom du porteur.<br/>
            Lors de votre saisie, Atlas vérifie l’identité (couple NOM / NIR) dans le référentiel national des identités (SNGI = Système National de Gestion des Identifiants) pour éviter toute erreur.<br/>
            En l’absence d’un NIR, et à défaut d’un NIA (Numéro d'Identification Temporaire), vous devez, en tant qu’employeur et lors de l’embauche d’un salarié, lui attribuer un NTT (Numéro Technique Temporaire).
            Le NTT est composé de 11 à 40 caractères. En principe, il commence par le numéro 1 ou 2 en fonction du sexe du salarié embauché, puis est suivi par le numéro SIREN de l’entreprise, pour finir normalement par un numéro propre à chaque individu.
            Le NTT a une durée de vie limitée à 3 mois. Il sert uniquement le temps que le salarié effectue les démarches nécessaires pour obtenir son NIR. Dès que le salarié aura obtenu son NIR définitif, vous devrez mettre à jour les informations.<br/>
            <br/>
            Seuls les OPCO sont habilités à collecter le NIR et uniquement pour AGORA: Les OPCO ont l’obligation de collecter le NIR afin de transmettre toutes les informations nominatives de formation professionnelle sur la plateforme AGORA : plateforme de référence d’échange et de partage de données de la formation professionnelle visant à simplifier et améliorer le suivi des parcours de formation.
        </p>,
        agora: "Les coordonnées du stagiaire sont collectées dans le cadre d’AGORA, plateforme d’échange avec la DGEFP. Les OPCO sont habilités par la loi Travail n°2016-1088 du 8 Août 2016 à les collecter afin de mettre en œuvre leur obligation de partage d’informations"
    },
    capp: {
        apprenti: {
            situationAvantContrat: "Sélectionner, parmi les propositions, le numéro correspondant à la situation dans laquelle se trouvait l'apprenti·e avant son embauche.",
            dernierDiplome: "Sélectionner, parmi les propositions, le numéro correspondant au dernier diplôme préparé par l'apprenti·e avant son embauche.",
            derniereClasse: "Sélectionner, parmi les propositions, le numéro correspondant à la dernière classe fréquentée par l'apprenti·e avant son embauche.",
            intituleDernierDiplome: "Préciser l'intitulé du diplôme et la spécialité (Ex : BTS assurances).",
            plusHautDiplome: "Sélectionner, parmi les propositions, le numéro correspondant au niveau de formation le plus élevé obtenu par l'apprenti·e à l’entrée de son contrat.",
            representantLegal: "Renseigner cette partie si l'apprenti·e est mineur·e non émancipé·e.",
            dateNaissance: <>
                <p>Nul ne peut être engagé en qualité d'apprenti s'il n'est âgé de seize ans au moins à vingt-neuf ans révolus
                    au début de l'apprentissage.</p>
                <p>Toutefois, les jeunes âgés d'au moins quinze ans peuvent débuter un contrat d'apprentissage s'ils justifient
                    avoir accompli la scolarité du premier cycle de l'enseignement secondaire.</p>
                <p>Les jeunes qui atteignent l'âge de quinze ans avant le terme de l'année civile peuvent être inscrits, sous statut
                    scolaire, dans un lycée professionnel ou dans un centre de formation d'apprentis pour débuter leur formation, dans
                    des conditions fixées par décret en Conseil d'Etat.</p>
                <p>Des dérogations à la limite d’âge de 29 ans sont possibles dans les cas suivants :</p>
                <ul>
                    <li>lorsque le contrat fait suite à un contrat d’apprentissage et conduit à un niveau de diplôme supérieur à celui obtenu;</li>
                    <li>lorsqu’il y a eu rupture de contrat pour des causes indépendantes de la volonté de l’apprenti (Exemple : cessation
                        d’activité de l’employeur, faute de l’employeur ou manquements répétés à ses obligations, mise en œuvre de la procédure de
                        suspension du contrat d'apprentissage par l'inspection du travail en cas d’atteinte à la santé ou à l’intégrité morale et
                        physique de l’apprenti) ou bien suite à une inaptitude physique et temporaire de celui-ci constatée par la médecine du travail.</li>
                </ul>
                <p>Dans les 2 cas :</p>
                <ul>
                    <li>le contrat d’apprentissage doit être souscrit dans un délai maximum d’un an après l’expiration du précédent.</li>
                    <li>l’âge de l’apprenti au moment de la conclusion du contrat est de 30 au plus. Cette limite d’âge est portée à 35 ans pour tout
                        contrat d’apprentissage conclu à compter du 01/04/2020.</li>
                </ul>
                <p>Il n'existe aucune limite d’âge dans les 3 cas suivants :</p>
                <ul>
                    <li>lorsque le contrat d’apprentissage est conclu par une personne reconnue travailleur handicapé</li>
                    <li>lorsque le contrat d’apprentissage est souscrit par une personne qui a un projet de création ou de reprise d’entreprise dont la
                        réalisation est subordonnée à l’obtention du diplôme ou titre sanctionnant la formation poursuivie ;</li>
                    <li>lorsque le contrat d’apprentissage est conclu par une personne (sportifs, entraîneurs, arbitres et juges sportifs) inscrite en
                        tant que sportif de haut niveau sur la liste arrêtée par le ministre chargé des sports (liste mentionnée au premier alinéa de
                        l’article L. 221-2 du code du sport)</li>
                    <li>lorsque, suite à un échec à l’obtention du diplôme ou du titre visé, l’apprentissage est prolongé pour une durée d’un an maximum par
                        conclusion d’un nouveau contrat avec un autre employeur (pour les contrats conclus à compter du 01/04/2020).</li>
                </ul>
            </>,
            handicap: "L'accompagnement financier d'un·e apprenti·e en situation de handicap fait l'objet d'une majoration de la prise en charge.",
            agora: "AGORA est à la fois un carrefour d’échange de données entre financeurs et un référentiel des données de la formation professionnelle pour les services de l’État. Alimentée par les différents acteurs de la formation professionnelle, la plateforme a pour objectif de mieux appréhender les parcours professionnels et leur évolution pour en améliorer l’accompagnement.",
            nir: <p>
                Numéro de sécurité sociale Français, officiellement appelé Numéro d'Inscription au Répertoire des personnes physiques. Ce numéro est notamment inscrit sur la carte Vitale, en dessous du nom et du prénom du porteur.<br/>
                Lors de votre saisie, Atlas vérifie l’identité (couple NOM / NIR) dans le référentiel national des identités (SNGI = Système National de Gestion des Identifiants) pour éviter toute erreur.<br/>
                En l’absence d’un NIR, et à défaut d’un NIA (Numéro d'Identification Temporaire), vous devez, en tant qu’employeur et lors de l’embauche d’un salarié, lui attribuer un NTT (Numéro Technique Temporaire).
                Le NTT est composé de 11 à 40 caractères. En principe, il commence par le numéro 1 ou 2 en fonction du sexe du salarié embauché, puis est suivi par le numéro SIREN de l’entreprise, pour finir normalement par un numéro propre à chaque individu.
                Le NTT a une durée de vie limitée à 3 mois. Il sert uniquement le temps que le salarié effectue les démarches nécessaires pour obtenir son NIR. Dès que le salarié aura obtenu son NIR définitif, vous devrez mettre à jour les informations.<br/>
                <br/>
                Seuls les OPCO sont habilités à collecter le NIR et uniquement pour AGORA: Les OPCO ont l’obligation de collecter le NIR afin de transmettre toutes les informations nominatives de formation professionnelle sur la plateforme AGORA : plateforme de référence d’échange et de partage de données de la formation professionnelle visant à simplifier et améliorer le suivi des parcours de formation.
            </p>,

        },
        maitre: {
            dateNaissance: "Le maître d'apprentissage doit être âgé d'au moins dix huit ans.",
            anneesExperience: <span key="tooltip-ma-exp-root">
                    <span key="tooltip-ma-exp-span">Le maître d'apprentissage doit avoir au moins :<br/></span>
                    <ul key="tooltip-ma-exp-ul">
                        <li key="1">1 année d’exercice d’une activité professionnelle en rapport avec la qualification préparée par l’apprenti·e et titulaire d’un titre ou diplôme d’un niveau au moins équivalent</li>
                        <li key="2">2 années d’exercice d’une activité professionnelle en rapport avec la qualification préparée par l’apprenti·e</li>
                    </ul>
                </span>,
            salariesSuivis: "Le nombre maximal d’apprenti·e·s ou d’élèves de classes préparatoires à l’apprentissage pouvant être accueilli·e·s simultanément dans une entreprise ou un établissement est fixé à deux pour chaque maître d’apprentissage, plus éventuellement un·e apprenti·e 'redoublant·e'.",
            formation: "Indiquer oui, si le maître d'apprentissage a déjà suivi une formation spécifique de maître d'apprentissage. Selon les critères de prise en charge en vigueur, un accompagnement financier complémentaire peut être déclenché.",
            changement: "Le changement de maître d’apprentissage en cours de contrat implique de conclure un avenant au contrat initial sauf à ce que le contrat d’apprentissage initial ait prévu un second maître d’apprentissage (composant une équipe tutorale).",
            eligibilite: <p>À défaut de dispositions conventionnelles particulières applicables dans l’entreprise, le maître d'apprentissage doit justifier d’une formation et d’une expérience professionnelle minimales fixées par l’article R. 6223-242 du code du travail et encadrer au maximum 2 apprentis plus un redoublant (article R. 6223-6 du même code).<br/> 
                En cochant cette case, l’employeur atteste que le maître d’apprentissage répond bien à l’ensemble de ces critères.<br/>
                Le changement de maître d’apprentissage en cours de contrat implique de conclure un avenant au contrat initial sauf à ce que le contrat d’apprentissage initial ait prévu un second maître d’apprentissage (composant une équipe tutorale). </p>,
            aideFonction :<p>Il s'agit d'une aide qui peut être versée par Atlas à l'entreprise pour valoriser la fonction tutorale et favoriser l'accompagnement des alternants.<br/>
            Les modalités de cette aide (montant, durée, conditions d'éligibilité) différent selon les critères définis par la branche professionnelle.</p>,
            nir: <p>
                Numéro de sécurité sociale Français, officiellement appelé Numéro d'Inscription au Répertoire des personnes physiques. Ce numéro est notamment inscrit sur la carte Vitale, en dessous du nom et du prénom du porteur.<br/>
                Lors de votre saisie, Atlas vérifie l’identité (couple NOM / NIR) dans le référentiel national des identités (SNGI = Système National de Gestion des Identifiants) pour éviter toute erreur.<br/>
                En l’absence d’un NIR, et à défaut d’un NIA (Numéro d'Identification Temporaire), vous devez, en tant qu’employeur et lors de l’embauche d’un salarié, lui attribuer un NTT (Numéro Technique Temporaire).
                Le NTT est composé de 11 à 40 caractères. En principe, il commence par le numéro 1 ou 2 en fonction du sexe du salarié embauché, puis est suivi par le numéro SIREN de l’entreprise, pour finir normalement par un numéro propre à chaque individu.
                Le NTT a une durée de vie limitée à 3 mois. Il sert uniquement le temps que le salarié effectue les démarches nécessaires pour obtenir son NIR. Dès que le salarié aura obtenu son NIR définitif, vous devrez mettre à jour les informations.<br/>
                <br/>
                Seuls les OPCO sont habilités à collecter le NIR et uniquement pour AGORA: Les OPCO ont l’obligation de collecter le NIR afin de transmettre toutes les informations nominatives de formation professionnelle sur la plateforme AGORA : plateforme de référence d’échange et de partage de données de la formation professionnelle visant à simplifier et améliorer le suivi des parcours de formation.
            </p>,
        },
        cfa: {
            nom: "L’établissement de formation responsable à indiquer sur le contrat d’apprentissage peut être différent du lieu de formation. Indiquer les informations indiquées sur la convention de formation.",
            entreprise: <>
                <p>Préciser si la formation théorique est assurée au sein de l'entreprise.</p>
                <p>Si oui, joindre une annexe pédagogique et financière au lieu de la convention de formation.</p>
                <p>Le CFA d'entreprise peut être :</p>
                <ul>
                    <li>une filiale de l'entreprise</li>
                    <li>un CFA interne à l'entreprise</li>
                    <li>un CFA constitué par un groupe d'entreprises</li>
                    <li>un CFA constitué par plusieurs entreprises partageant des perspectives communes d'évolution
                        des métiers ou qui interviennent dans des secteurs d'activité complémentaires</li>
                </ul>
                <p>Le CFA d'entreprise :</p>
                <ul>
                    <li>doit déposer sa déclaration d'activité auprès de l'administration accompagnée d'un contrat
                        d'apprentissage et d'une attestation de l'entreprise précisant la situation du CFA</li>
                    <li>est dispensé d'avoir des statuts mentionnant expressément dans leur objet l'activité de formation en apprentissage</li>
                </ul>
            </>,
        },
        formation: {
            dateEntree: <>
                Date du 1er jour où débute effectivement la formation théorique en centre de formation.
            </>,
            dateSortie: "La date de fin du contrat doit englober l’ensemble des épreuves nécessaires à l’obtention du titre ou du diplôme.",
            duree: "Indiquer le nombre d'heures de formation en centre. Se reporter à la convention de formation.",
            coutPedagogique: "Coût total de l’action de formation exprimé en net de taxes (hors frais annexes). Se reporter à la convention de formation.",
            majorationHandicapCout: <>
                <p>Les contrats concernant des apprentis reconnus en qualité de travailleur handicapé (RQTH) bénéficient de l’accès à cette majoration du niveau
                    de prise en charge, ce montant est le cas échéant présent dans la convention de formation. Ce montant couvre les modalités de modulation du
                    niveau de prise en charge pour l'accueil d'un apprenti reconnu travailleur handicapé. Cela se matérialise par l’application d’une grille de
                    majoration adaptée aux besoins de l’apprenti et nécessaire à la sécurisation de son parcours.</p>
                <p>Les aménagements nécessaires à cette sécurisation peuvent consister aussi bien en des aides humaines, animalières, techniques (lecteur scripteur,
                    conditions de sécurité) que des adaptations pédagogiques et d’apprentissage (réduction du temps de travail hebdomadaire et allongement de la
                    durée du contrat). Ce peut-être également un soutien pédagogique.</p>
            </>,
            fraisAnnexes: "Des frais annexes comme l'hébergement, le repas, le premier équipement sont pris en charge dès lors qu'ils sont supportés par le CFA. Reportez vous à la convention de formation.",
            fraisAnnexesInternational: "Pendant la période de mobilité, la relation contractuelle qui lie l’alternant à son employeur pourra être différente selon qu’il s’agit d'une mise à disposition de l'apprenti par l'entreprise ou d'une mise en veille du contrat. Pour la mise en œuvre de cette mobilité internationale, une convention de mobilité associant les différentes parties prenantes doit être conclue et transmise à l’OPCO Atlas dès que possible. Atlas accompagne les frais générés à l’occasion d’une mobilité internationale par l’intermédiaire du CFA. Pour plus de précisions, contacter le référent mobilité internationale au sein du CFA.",
            fraisAnnexesHebergement: "Préciser le nombre de nuitées en centre. Se reporter à la convention de formation.",
            fraisAnnexesRestauration: "Préciser le nombre de repas en centre. Se reporter à la convention de formation.",
            fraisAnnexesEquipementNature: "Préciser le type de dépenses supportées par le CFA. Se reporter à la convention de formation.",
            fraisAnnexesEquipementCout: "Préciser le montant supporté par le CFA. Se reporter à la convention de formation.",
            agora: "AGORA est à la fois un carrefour d’échange de données entre financeurs et un référentiel des données de la formation professionnelle pour les services de l’État. Alimentée par les différents acteurs de la formation professionnelle, la plateforme a pour objectif de mieux appréhender les parcours professionnels et leur évolution pour en améliorer l’accompagnement.",
        },
        contrat: {
            typeContrat: "Sélectionner, parmi les propositions, le numéro correspondant au type de contrat.",
            numContratPrecedent: "Indiquer le numéro d'enregistrement ou de dépôt du précédent contrat signé par l'apprenti·e.",
            typeDerogation: "En cas de réduction de parcours, joindre la convention d'aménagement de la durée.",
            dateDebut: <p>Date du 1<sup>er</sup> jour où débute effectivement le contrat (peut être la date de début de formation pratique en entreprise ou en centre de formation).</p>,
            dateDebutPratique: <p>Date du 1<sup>er</sup> jour où débute effectivement la formation pratique chez l’employeur.</p>,
            dateAvenant: <p>Date du 1<sup>er</sup> jour où débute effectivement l'avenant (en entreprise comme en centre de formation).</p>,
            dateFin: "Dans le cadre d’un CDI, préciser la date de fin de l’action de formation (examens inclus).",
            dateConclusion: "Date à laquelle le présent contrat de travail (qu’il s’agisse d’un contrat initial ou d’un avenant) est conclu par les deux parties (signatures).",
            dureeHebdomadaireTravail: <p>
                Préciser l’horaire collectif de travail de l’entreprise si infra ou égal à 35 heures.<br/>
                Remarque : La circulaire n° 2012-15 du 19 juillet 2012 de la DGEFP précise que le Cerfa doit indiquer 35 heures dans le cas de salariés travaillant plus de 35 heures hebdomadaires, les heures au-delà étant récupérées sous forme de RTT.
                </p>,
            travailDangereux: <>
                <p>
                Pour les apprentis mineurs, certaines catégories de travaux ne sont pas autorisées (article L. 4153-8 du code du travail). Dans certains cas
                spécifiquement prévus par la règlementation, des dérogations sont possibles (article L. 4153-9 du code du travail).
                </p><p>
                La règlementation relative aux travaux interdits et réglementés pour les jeunes âgés de quinze ans au moins et de moins de dix-huit ans s’appliquant
                est détaillée aux articles D. 4153-15 à D. 4153-37 du code du travail. Le cadre précis des dérogations pour les jeunes en formation professionnelle
                sont spécifiés aux articles R. 4153-38 à R. 4153-45 du code du travail.
                </p><p>
                Pour les apprentis en contrat à durée limitée, quel que soit leur âge, leur sont interdits l'exécution des travaux listés à l’article D. 4154-1 du
                code du travail. Le cadre des dérogations est précisé aux articles D. 4154-2 à D. 4154-6 du code du travail.
                </p>
            </>,
            remuneration: <p>
                Sauf dispositions contractuelles ou conventionnelles plus favorables, l’apprenti·e perçoit un salaire déterminé en pourcentage du SMIC. <br/>
                Ce montant varie en fonction de l’âge et de la progression dans le cycle de formation. <br/>
                Le passage d’un niveau de rémunération à un autre s’effectue à l’issue de chaque année d’exécution du contrat (12 mois effectifs). <br/>
                Lorsque l’apprenti·e change de tranche d’âge, le taux de rémunération change le 1<sup>er</sup> du mois qui suit son anniversaire.<br/>
                Des règles spécifiques s'appliquent en cas de succession de contrat ou d'aménagement de la durée du contrat.<br/>
                Consulter la grille de rémunération applicable.
                </p>,
            anneeFormationEntreeContrat: <p>
                Lorsque la durée du contrat d’apprentissage est inférieure à celle du cycle de formation (en prenant en compte le niveau de l’apprenti ou si la formation au CFA a commencé avant la signature du contrat, ou s’est poursuivie après la rupture d’un 1er contrat), la rémunération de l’apprenti est alors calculée comme s’il avait accompli une durée d’apprentissage égale à ce cycle de formation (Art. 6222-28-1).<br/>
                Veuillez préciser  dans quelle année de formation du cycle, l’apprenti est inscrit au moment du démarrage de ce contrat d’apprentissage.<br/>
                Exemple &gt;&gt; Si un contrat d’apprentissage est conclu pour les deux dernières années de formation du baccalauréat professionnel (prévu sur trois ans),  préciser 2.
                </p>,
            niveauOuEchelon: "Le niveau ou l’échelon de l’apprenti est déterminé en fonction de critères retenus par la convention collective applicable au salarié.",
            coefficientHierarchique: "Le coefficient de salaire, aussi appelé le coefficient hiérarchique, représente la position du salarié dans la classification hiérarchique établie par la convention collective applicable au salarié. Le coefficient de salaire permet - entre autres - de déterminer à partir du salaire de base et du pourcentage appliqué, la rémunération applicable à l’apprenti.",
            salaireEmbauche: "Salaire mensuel que l'apprenti·e va percevoir.",
            avantagesNature: "Préciser la nature et le montant des avantages en nature fournis par l'entreprise à l'apprenti·e.",
        },
    },
    cpro: {
        formation: {
            dateDebut: <p>Date du 1<sup>er</sup> jour où débute effectivement le contrat (en entreprise comme en centre de formation).</p>,
            dateFin: "Dans le cadre d’un CDI, préciser la date de fin de l’action de formation (examens inclus).",
        },
        cfa: {
            nom: "L’établissement de formation responsable à indiquer sur le contrat de professionnalisation peut être différent du lieu de formation. Indiquer les informations indiquées sur la convention de formation.",
        },
        tuteur:{
            dateNaissance: "Le tuteur doit être âgé d'au moins seize ans.",
            priseEnChargeFonctionTutorale : <p>Il s'agit d'une aide qui peut être versée par Atlas à l'entreprise pour valoriser la fonction tutorale et favoriser l'accompagnement des alternants.<br/>
                Les modalités de cette aide (montant, durée, conditions d'éligibilité) différent selon les critères définis par la branche professionnelle.</p>,
            exerciceFonction2Annees : <p>Indiquer oui si le maître d’apprentissage désigné a encadré un ou plusieurs alternant(s) durant les deux années précédentes. Selon
                les critères de prise en charge en vigueur, un accompagnement financier complémentaire peut être déclenché.</p>,
        },
        contrat: {
            typeContrat: "Sélectionner, parmi les propositions, le numéro correspondant au type de contrat.",
            avenantContratInitial: "Uniquement dans le cadre d'un contrat de professionnalisation associant des actions de validation des acquis de l'expérience mentionné à l'article 11 de la loi n° 2022-1598 du 21 décembre 2022",
            dateDebut: <p>Date du 1<sup>er</sup> jour où débute effectivement le contrat (en entreprise comme en centre de formation).</p>,
            dateFin: "Dans le cadre d’un CDI, préciser la date de fin de l’action de formation (examens inclus).",
            dateAvenant: <p>Date du 1<sup>er</sup> jour où débute effectivement l'avenant (en entreprise comme en centre de formation).</p>,
            dateConclusion: "Date à laquelle le contrat de travail est conclu par les deux parties (signatures).",
            dureeHebdomadaireTravail: <p>
                Préciser l’horaire collectif de travail de l’entreprise si infra ou égal à 35 heures.<br/>
                Remarque : La circulaire n° 2012-15 du 19 juillet 2012 de la DGEFP précise que le Cerfa doit indiquer 35 heures dans le cas de salariés travaillant plus de 35 heures hebdomadaires, les heures au-delà étant récupérées sous forme de RTT.
                </p>,
            salaireEmbauche:<>
            <p>Le salaire inclut les primes ou accessoires de salaires.</p>
            <LinkGrille></LinkGrille></>,
        },
        maitre: {
            eligibilite: <p>À défaut de dispositions conventionnelles particulières applicables dans l’entreprise, le tuteur doit justifier d’une expérience professionnelle minimale dont la durée est fixée par l’article D6325-6 du code du travail et encadrer au maximum trois bénéficiaires de contrats de professionnalisation ou d'apprentissage ou de périodes de professionnalisation, deux maximum s’il est l’employeur (<a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000018523013" target="_blank" rel="noopener noreferrer">Article D6325-9</a> du même code).<br/>
            En cochant cette case, l’employeur atteste que le tuteur répond bien à l’ensemble de ces critères.<br/>
            Le changement de tuteur en cours de contrat implique de conclure un avenant au contrat initial sauf à ce que le contrat initial ait prévu un second tuteur (composant une équipe tutorale).</p>,
        },
        agora: "AGORA est à la fois un carrefour d’échange de données entre financeurs et un référentiel des données de la formation professionnelle pour les services de l’État. Alimentée par les différents acteurs de la formation professionnelle, la plateforme a pour objectif de mieux appréhender les parcours professionnels et leur évolution pour en améliorer l’accompagnement.",

        attestationFormationAfest: <p>
            Dans le cadre du financement par Atlas d’un module de formation AFEST associé à un contrat de professionnalisation, l’entreprise s’engage à :
            Créer les conditions favorables aux apprentissages de l’alternant en mettant en œuvre les conditions adéquates à la réalisation de l’Action de Formation En Situation de Travail (AFEST),<br/>
            <br/>
            Transmettre une séquence pédagogique associée à un module de formation AFEST au moment de la demande de financement. Cette séquence pédagogique intégrera les deux phases caractéristiques de l’AFEST à savoir les situations de travail apprenantes et les séquences réflexives,<br/>
            <br/>
            Désigner un ou plusieurs formateurs salariés devant respecter les deux conditions suivantes :<br/>
            <br/>
            Être volontaire ;<br/>
            Justifier d’une expérience professionnelle d’au moins 2 ans sur les sujets traités.<br/>
            Dans la mesure du possible avoir suivi une formation de formateur ou disposer d'une expérience en tant que formateur occasionnel.<br/>
            Dissocier les heures réalisées dans le cadre de l’AFEST des heures d’enseignements généraux, professionnels et technologiques prévues dans le cadre du contrat de professionnalisation.<br/>
            <br/>
            Transmettre, à l’OPCO, à l’issue de la formation, un certificat de réalisation permettant de justifier de l’effectivité de la formation AFEST et de bénéficier d’un financement forfaitaire de 500 euros. Pour bénéficier de ce financement, l’Entreprise adressera à Atlas, via les services en ligne, une facture en bonne et due forme et joindra le certificat de réalisation.<br/>
            <br/>
            Tenir à disposition tout élément de preuve relatif au temps de formation et d’évaluation (feuille de présence, support de formation, test/quizz, fiche observation formateur, attestation, étude de cas …) pouvant être demandé par Atlas en cas de contrôle visant à vérifier l’effectivité de la formation financée.
            </p>,
    },
    cprox: {
        experimental: <p>Par dérogation, un contrat de professionnalisation peut désormais viser des compétences définies entre l’employeur et l’OPCO. 
            Il s’agit du contrat de professionnalisation expérimental.<br/>
            Il intervient en complément du contrat de professionnalisation de droit commun qui vise une certification enregistrée au RNCP, 
            une qualification de branche ou un CQP.​</p>,

        tiersFacilitateur: <p>Les tiers facilitateurs sont des cabinets conseil sélectionnés par Atlas pour accompagner les entreprises dans la mise en œuvre 
            de leur premier contrat de professionnalisation expérimental</p>,

        attestationFormationAfest: <p>
            La mise en place d’une AFEST nécessite l’analyse des activités de travail au sein de l’entreprise, puis la mise en œuvre alternée de situations de travail apprenantes et de séquences réflexives.<br/>
            Plus d’informations sur <a href="https://www.opco-atlas.fr/entreprise/formation-en-situation-de-travail.html" target="_blank" rel="noreferrer noopener">www.opco-atlas.fr/entreprise/formation-en-situation-de-travail.html</a>
            </p>,
    },
    cproa: {
        eligibilite: <p>À défaut de dispositions conventionnelles particulières applicables dans l’entreprise, le tuteur doit justifier d’une expérience professionnelle minimale dont la durée est fixée par l’article D6325-6 du code du travail et encadrer au maximum trois bénéficiaires de contrats de professionnalisation ou d'apprentissage ou de périodes de professionnalisation, deux maximum s’il est l’employeur (<a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000018523013" target="_blank" rel="noopener noreferrer">Article D6325-9</a> du même code).<br/>
            En cochant cette case, l’employeur atteste que le tuteur répond bien à l’ensemble de ces critères.<br/>
            Le changement de tuteur en cours de contrat implique de conclure un avenant au contrat initial sauf à ce que le contrat initial ait prévu un second tuteur (composant une équipe tutorale).</p>,
    },
    formation: {
        diplomeIntitulePrecis: "Préciser l'intitulé du diplôme et la spécialité (Ex : BTS assurances)",
        diplomeCode: <p>
            Cette codification à huit positions concerne l’ensemble des diplômes et titres préparés par la voie de l’apprentissage.<br/>
            Se reporter à la convention de formation ou se renseigner auprès de l’établissement de formation responsable.
            </p>,
        rechercheFormationParRNCP: <>
            <p>Indiquez le code afférent, en chiffres « XXXXX » (qui suit la mention RNCP) mentionné dans la convention de formation.</p>
            <p>La fiche RNCP du diplôme ou du titre visé doit être active à la date de conclusion du contrat et ouverte à l'apprentissage (sauf exception).
                Pour plus d'information sur la certification et son éligibilité à l'apprentissage, consulter le <a href="https://www.francecompetences.fr/recherche-resultats/?types=certification&search=&pageType=certification&active=1" target="_blank" rel="noreferrer noopener">site Internet de France
                Compétences</a> ou se renseigner auprès de l'établissement
                de formation responsable.</p>
            <p>Si la recherche ne renvoie aucun résultat, vérifiez bien le code RNCP en lien avec l'organisme de formation en apprentissage, le cas échéant
                cliquer sur « ajouter une formation » et saisissez les informations demandées. Cette action nécessitera une analyse par nos équipes et
                entrainera un délai supplémentaire dans l'instruction du dossier.</p>
        </>,
        codeRNCP: <p>Renseigner le code RNCP le cas échéant, (y compris pour les blocs de compétences mentionnés à l'article L. 6113-1 
            du code du travail) pour les deux expérimentations en cours (types de qualification 4 et 8)</p>,
        diplomeSpecialite: <p>
            Préciser le domaine professionnel dans lequel s’effectue l’action de formation. 
            Remarque : La nomenclature de codage à utiliser est la nomenclature des spécialités de formation (NSF à 3 chiffres). 
            Voir liste NSF dans votre boîte à outils.<br/>
            La liste complète est disponible sur le site <a href="https://www.francecompetences.fr/recherche-resultats/?types=certification&search=&pageType=certification&active=1" target="_blank" rel="noreferrer noopener">www.francecompetences.fr/recherche_certificationprofessionnelle</a>.
            </p>,
        typeFormation: <>
            <p>La formation en Intra est dispensée pour un groupe de salariés d’une seule et même entreprise.</p>
            <p>La formation en Inter est dispensée pour un groupe de salariés d’entreprises différentes.</p>
        </>,
        departementNaissance: <>
            <p>Indiquez le numéro du département.</p>
            <p>Pour les personnes nées à l’étranger, indiquez 099.</p>
        </>,
        serviceFormationInterne : <p>
            Existence d’un « service de formation interne » lorsque l’entreprise dispose d’une structure pérenne de formation identifiée comme telle dans l’organisation de l’entreprise et donc des moyens nécessaires à une prestation de formation.
            L’attestation de l’employeur portée sur le contrat constitue son engagement en la matière.<br/>
            En cas de formation effectuée en interne, renseignez dans la rubrique « formation » le SIRET de l’établissement employeur auquel est rattaché le service de formation interne,
            le numéro de déclaration d’activité de celui-ci le cas échéant ainsi que le nombre d’organismes intervenant en incluant le service de formation interne.
            </p>,
        contexteSpecifique: "Ce champ ne doit être complété que si votre action correspond à l'un des cas spécifiques indiqués dans nos critères de prise en charge qui précisent le code à sélectionner",
        formationCertifianteAgora: "Merci d'indiquer si la formation vise une certification (diplôme, titre, certificat de qualification ou bloc de compétences)",
    },
    stagiaire: {
        diplomeVise: "Sélectionner, parmi les propositions, le numéro correspondant au diplôme ou titre visé par l'apprenti·e.",
    },
    cfa: {
        numeroUAI: "Chaque établissement scolaire (écoles, collèges, lycées, CFA, enseignement supérieur, public ou privé) possède un code unique " +
            "inscrit dans le répertoire national des établissements (RNE). " +
            "Se renseigner auprès de l’établissement de formation responsable.",
        numeroDA: <p>
            Toute personne physique ou morale qui exerce, à titre principal ou accessoire, l’activité de dispensateur de formation professionnelle doit, 
            lors de sa création, déclarer son activité auprès de la Direction Régionale des Entreprises, de la Concurrence, de la Consommation, du Travail 
            et de l’Emploi (DIRECCTE) afin d’obtenir un numéro d’enregistrement en tant qu’organisme de formation.<br/>
            Se reporter à la convention de formation ou se rapprocher du CFA pour obtenir ce numéro.
            </p>,
    },
    rupture: {
        coutPedagogique: "Le cas échéant, montant de la prestation modifié suite au départ anticipé de l’apprenti du centre de formation en apprentissage. Le montant ajusté permet de recalculer l’engagement du contrat et l’échéancier de facturation",
    }
}