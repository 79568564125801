import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
    page: {
        fontFamily: 'OpenSans',
        fontSize: 11,
        padding: 40,
        lineHeight: 1.6,
    },
    logo: {
        width: 75,
    },
    title: {
        textAlign: 'center',
        marginTop: 30,
        fontSize: 14,
    },
    strong: {
        fontWeight: 'bold',
    },
    part: {
        marginTop: 50,
    },
    partTitle: {
        textTransform: 'uppercase',
        backgroundColor: '#452f85',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 2,
    },
    tableHead: {
        fontWeight: 'bold',
        borderBottom: '1px solid black',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    evenRow: {
        backgroundColor: '#f7eaff',
    },
    cell: {
        padding: 3,
        width: '44%',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableRowLabel: {
        padding: 3,
        width: '50%',
    },
    tableRowValue: {
        padding: 3,
        width: '50%',
        fontWeight: 'bold',
    },
    footer: {
        marginTop: 20,
        textAlign: 'left',
    },
    footerText: {
        fontSize: 10,
        color: 'grey',
    },
});
