import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'


export default function Organisme({ organisme }: { organisme: any }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>
        CADRE RÉSERVÉ À L'ORGANISME EN CHARGE DU DÉPÔT DU CONTRAT
      </Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Nom de l'organisme :{' '}
              <Text style={styles.user}>{organisme?.nom || ' '}</Text>
            </Text>
            <Text>Date de réception du dossier complet :{' '}
              <CerfaTextField type="date" value={organisme?.dateReception} />
            </Text>
            <Text>
              Numéro de dépôt :{' '}
              <CerfaTextField value={organisme?.numeroDepot} />
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              <Text style={styles.bold}>N°SIRET de l'organisme : </Text>
              <CerfaTextField value={organisme?.numeroSiret} />
            </Text>
            <Text>Date de la décision:{' '}
              <CerfaTextField value={organisme?.dateDecision} />
            </Text>
            <Text>
              Numéro d'avenant :{' '}
              <CerfaTextField value={organisme?.numeroAvenant} />
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
