import { Form, useForm } from '@dsid-opcoatlas/reform'
import { PresentationEntreprise, getPresentationEntreprise, setPresentationEntreprise } from 'api/profilAPI'
import { sanitizeHtmlEditor } from 'api/references'
import { Entreprise } from 'api/referencesAPI'
import useApiState, { apiStateSuccess } from 'api/useApiState'
import { AtlasButton, AtlasFieldText, AtlasHeading, AtlasHtml, AtlasValue, AtlasValues, MyContainer, MyForm, MyListInput, MyTags } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import { decodeFromCsv, encodeToCsv } from 'components/csv/Csv'
import Button from 'components/reform/Button'
import HtmlEditorField from 'components/reform/inputs/HtmlEditorField'
import TextField from 'components/reform/inputs/TextField'
import { RequiredString, requiredArray, requiredObject } from 'components/yop/constraints'
import { useEffect, useRef } from 'react'


const createPresentation = (presentation: PresentationEntreprise | null) => { return {
    description: sanitizeHtmlEditor(presentation?.DescriptionOFCampus__c ?? ''),
    certifications: decodeFromCsv(presentation?.CertificationsOFCampus__c),
    siteInternet: presentation?.SiteInternetOFCampus__c ?? ''
}}
type Presentation = ReturnType<typeof createPresentation>


export default function PresentationCompteEntreprise(props: {
    entreprise: Entreprise | null,
    editing: boolean,
    setEditing: (editing: boolean) => void
}) {
    const [{ value: presentation }, withGetPresentationEntreprise, setPresentationState] = useApiState(getPresentationEntreprise)

    useEffect(() => {
        if (props.entreprise?.IdHeroku)
            withGetPresentationEntreprise(props.entreprise.IdHeroku)
    }, [withGetPresentationEntreprise, props.entreprise?.IdHeroku])

    const initialValues: Presentation = createPresentation(presentation)
    const validationSchema = requiredObject<Presentation>({
        description: RequiredString.max(30000, "Description trop longue"),
        certifications: requiredArray(RequiredString),
        siteInternet: RequiredString
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            const paramsPresentation = {
                DescriptionOFCampus__c: sanitizeHtmlEditor(form.values!.description),
                CertificationsOFCampus__c: encodeToCsv(form.values!.certifications),
                SiteInternetOFCampus__c: form.values!.siteInternet
            }

            setPresentationEntreprise(props.entreprise!.IdHeroku, paramsPresentation)
                .then((presentation) => {
                    setPresentationState(apiStateSuccess(presentation))
                    ToastService.showToast({
                        label: "Sauvegarde",
                        content: "Présentation de l'entreprise sauvegardée",
                        type: "success"
                    })
                })
                .finally(() => {
                    context.setSubmitting(false)
                    props.setEditing(false)
                })
        },
        resetConfiguration: {
            deps: [presentation]
        }
    })

    const addCertificationInput = useRef<HTMLInputElement | null>(null)

    const addCertification = () => {
        const certification = addCertificationInput.current?.value?.trim() ?? ''
        if (certification.length > 0)
            form.setValue('certifications', form.values!.certifications.concat([certification]), true)
        addCertificationInput.current!.value = ''
    }

    const removeCertification = (index: number) => {
        form.setValue('certifications', form.values!.certifications.filter((_, i) => i !== index ), true)
    }

    const cancelEdit = () => {
        form.reset(true)
        props.setEditing(false)
    }

    const valuesForm = (
        <Form context={ form } noValidate>
            <MyForm>
                <HtmlEditorField name="description" label="Description"
                    tooltip="Cette information sera utilisée sur nos différentes plateformes pour présenter votre entreprise." />

                <MyListInput
                    onAdd={addCertification}
                    input={<AtlasFieldText
                        ref={addCertificationInput}
                        name="certification"
                        label="Certifications"
                        placeholder="Nom de la certification"
                        maxLength={50}
                        autoComplete="off"
                    />}
                    values={<MyTags>
                        { form.values?.certifications.map((certification, index) => <MyTags.Item
                            key={'certi-' + index}
                            light
                            delete={{
                                ariaLabel: `Retirer la certification ${certification}`,
                                onDelete: () => removeCertification(index)
                            }}
                        >{ certification }</MyTags.Item>) || []}
                    </MyTags>}
                />

                <TextField maxLength={100} name="siteInternet" label="Site Internet" />

                <MyForm.Actions>
                    <Button submit={ true } spinner={{spinning: form.submitting}}>Valider</Button>
                    <Button level={ 2 } onClick={ cancelEdit }>Annuler</Button>
                </MyForm.Actions>
            </MyForm>
        </Form>
    )

    return (
        <div>
            <AtlasHeading
                tag="h2"
                size="m"
                button={form ? <AtlasButton
                    level={3}
                    icon="arrow-right"
                    onClick={() => props.setEditing(true)}
                >Modifier</AtlasButton> : undefined}
            >Présentation de l'entreprise</AtlasHeading>
            { props.editing ? valuesForm : <MyContainer>
                <AtlasValues>
                    <AtlasValue label="Description">
                        <AtlasHtml>{ form.values?.description }</AtlasHtml>
                    </AtlasValue>
                    <AtlasValue label="Certifications">
                        <MyTags>
                            {form.values?.certifications ? form.values?.certifications.map((certification:string) => <MyTags.Item
                                key={certification}
                            >{ certification }</MyTags.Item>): [<></>]}
                        </MyTags>
                    </AtlasValue>
                    <AtlasValue label="Site Internet">
                        <a rel="noopener noreferrer" href={ form.values?.siteInternet ?? '#' }>
                            { form.values?.siteInternet ?? '#' }
                        </a>
                    </AtlasValue>
                </AtlasValues>
            </MyContainer> }
        </div>
    )
}
