import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface QueryPayload {
    name: string
    query?: string
}

type RechercheState = {
    queries: {
        [key: string]: string
    }
}

const initialState: RechercheState = {
    queries: {}
}

const recherchesSlice = createSlice({
    name: 'recherche',
    initialState,
    reducers: {
        setQuery(state, action: PayloadAction<QueryPayload>) {
            if (!action.payload.query || action.payload.query.trim().length === 0)
                recherchesSlice.caseReducers.clearQuery(state, { payload: action.payload.name, type: action.type })
            else
                state.queries = { ...state.queries, [action.payload.name]: action.payload.query ?? '' }
        },

        clearQuery(state, action: PayloadAction<string>) {
            const newQueries = { ...state.queries }
            delete newQueries[action.payload]
            state.queries = newQueries
        }
    },
})

export const { setQuery, clearQuery } = recherchesSlice.actions
export default recherchesSlice.reducer
