import { AtlasValues, MyContainer } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { BaseDossier } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapSalarie(props: RecapProps<BaseDossier>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)

    const metadata = props.metadata
    const salarieEtape = steps.indexOf(props.step) + 1

    const skeleton = props.loading ? {} : {}
    const salarie = (props.dossier as any)?.salarie ?? skeleton
    const dossier = props.dossier ?? { salarie } as any

    return <RecapSection
            title="Salarié"
            onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, salarieEtape) }
        >

        <MyContainer>
            <AtlasValues>
                <AtlasValues.Grid>
                    <FormElements key="salarie" dossier={ dossier } metadata={ metadata } path="salarie" type="recap" />
                </AtlasValues.Grid>
            </AtlasValues>
        </MyContainer>
    </RecapSection>
}
