import { scroller, Link } from 'react-scroll'
import scrollHash from 'react-scroll/modules/mixins/scroll-hash'

export default class    FirstScrollLink extends Link {
    spyHandler = (x: number, y: number) => {
        let scrollSpyContainer = (this as any).getScrollSpyContainer()

        if ((scrollHash as any).isMounted() && !(scrollHash as any).isInitialized()) {
            return;
        }

        let to = this.props.to;
        let element = null;
        let isInside;
        let isOutside;

        let elemTopBound = 0;
        let elemBottomBound = 0;
        let containerTop = 0;

        if (scrollSpyContainer.getBoundingClientRect) {
            let containerCords = scrollSpyContainer.getBoundingClientRect();
            containerTop = containerCords.top;
        }

        if (!element || this.props.isDynamic) {
            element = scroller.get(to)
            if (!element) { return; }
            let cords = element.getBoundingClientRect()
            elemTopBound = 0
            elemBottomBound = (cords.top - containerTop + y) + cords.height
        }

        let offsetY = y - (this.props as any).offset;
        isInside = (offsetY >= Math.floor(elemTopBound) && offsetY < Math.floor(elemBottomBound));
        isOutside = (offsetY < Math.floor(elemTopBound) || offsetY >= Math.floor(elemBottomBound));
  
        let activeLink = scroller.getActiveLink();
  
        if (isOutside) {
            if (to === activeLink) {
                scroller.setActiveLink('');
            }
  
            if (this.props.hashSpy && (scrollHash as any).getHash() === to) {
                const { saveHashHistory = false } = this.props as any
                (scrollHash as any).changeHash("", saveHashHistory);
            }
  
            if (this.props.spy && (this.state as any).active) {
                this.setState({ active: false });
                this.props.onSetInactive && (this.props as any).onSetInactive(to, element);
            }  
        }
  
        if (isInside && (activeLink !== to || (this.state as any).active === false)) {
            scroller.setActiveLink(to);
            const { saveHashHistory = false } = this.props
            this.props.hashSpy && (scrollHash as any).changeHash(to, saveHashHistory);
  
            if (this.props.spy) {
                this.setState({ active: true });
                this.props.onSetActive && (this.props as any).onSetActive(to, element);
            }
        }
    }    
}
