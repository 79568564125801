import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CATEGORIES_TYPES_ACTIONS, DiagDiagnosticActionDTO, DiagDiagnosticBesoinDTO, DiagDiagnosticCompetenceDTO, DiagDiagnosticDTO, DiagDiagnosticMetierDTO, DiagDiagnosticScenarioDTO, addDiagnosticAction, createDiagnosticAction, deleteDiagnostic, deleteDiagnosticCompetence, deleteDiagnosticMetier, desactiverDiagnosticAction, getDiagnostic, moveDiagnosticAction, saveDiagnostic, saveDiagnosticEtape, updateDiagnosticBesoin, updateDiagnosticCompetence, updateDiagnosticMetier, updateDiagnosticScenario, validerPlanActions } from 'api/diagatlasAPI'
import { RootState } from 'app/rootReducer'
import { WritableDraft } from 'immer'
import { clearDiagnosticEtape } from 'pages/mon-diagnostic/useDiagnoticNavigation'


export interface Diagnostic {
    id: string
    entrepriseId: string
    dateCreation: string | null 
    contactCreation: string | null
    dateRealisation: string | null 
    contactRealisation: string | null
    dateModification: string | null
    contactModification: string | null
    statut: string
    etape: number
    tailleEntreprise: string
    secteur: string
    scenarios?: DiagDiagnosticScenarioDTO[]
    besoins?: DiagDiagnosticBesoinDTO[]
    metiers?: DiagDiagnosticMetierDTO[]
    competences?: DiagDiagnosticCompetenceDTO[]
    planAction?: DiagDiagnosticActionDTO[]
    hasPlanAction?: boolean | null
}

export type DiagnosticUpdate = Omit<Diagnostic, 'contactCreationId' | 'dateCreation'>

export interface DiagnosticState {
    loading: boolean
    saving: boolean
    diagnostic: Diagnostic | null
    error: any
}

const initialState: DiagnosticState = { loading: false, saving: false, diagnostic: null, error: null }


export const STATUTS_ACTIONS_PLAN = [
    { id: 'envisagee', label: "Actions envisagées à 3 ans", shortLabel: "Envisagée à 3 ans", emoji: '🗓️' },
    { id: 'a-mener', label: "Actions à mener dans l'année", shortLabel: "A mener dans l'année", emoji: '💪' },
    { id: 'realisee', label: "Actions réalisées", shortLabel: "Réalisée", emoji: '✅' },
]

const mapAction = (a: DiagDiagnosticActionDTO): DiagDiagnosticActionDTO => {
    const na = { ...a }
    if (a.Action.Type)
        na.Action.Type.CategorieLibelle = CATEGORIES_TYPES_ACTIONS[a.Action.Type.Categorie] as any
    return na
}

const mapDTOToModel = (diagnostic: DiagDiagnosticDTO): Diagnostic => {
    const scenarios = diagnostic.Scenarios
    if (scenarios)
        scenarios?.sort((s1, s2) => s1.Scenario.Facteur.Libelle < s2.Scenario.Facteur.Libelle ? -1 : 1)
    return {
        id: diagnostic.Id,
        entrepriseId: diagnostic.Profil.Entreprise,
        dateCreation: diagnostic.Profil.DateCreation ?? null,
        contactCreation: diagnostic.Profil.ContactCreation ?? null,
        dateRealisation: diagnostic.Profil.DateRealisation ?? null,
        contactRealisation: diagnostic.Profil.ContactRealisation ?? null,
        dateModification: diagnostic.Profil.DateModification ?? null,
        contactModification: diagnostic.Profil.ContactModification ?? null,
        statut: diagnostic.Profil.Statut!,
        etape: parseInt(diagnostic.Profil.Etape ?? '1'),
        tailleEntreprise: diagnostic.Profil.TailleEntreprise,
        secteur: diagnostic.Profil.Secteur,
        scenarios,
        besoins: diagnostic.Besoins,
        metiers: diagnostic.Metiers,
        competences: diagnostic.Competences,
        planAction: diagnostic.PlanAction?.map(mapAction),
        hasPlanAction: diagnostic.HasPlanAction,
    }
}

const mapModelToDTO = (diagnostic: DiagnosticUpdate): DiagDiagnosticDTO => {
    return {
        Id: diagnostic.id,
        Profil: {
            Entreprise: diagnostic.entrepriseId,
            Etape: (diagnostic.etape ?? 1).toString(),
            TailleEntreprise: diagnostic.tailleEntreprise,
            Secteur: diagnostic.secteur,
        },
    }
}

const load = (function() {
    return createAsyncThunk('diagatlas/diagnostic/get',
        async ({ entrepriseId, detailed, section, categorie, statuts }: { entrepriseId: string, detailed?: boolean, section?: string | string[], categorie?: string, statuts?: string | string[] }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            if (diagnosticState.diagnostic && !section && !categorie) {
                return diagnosticState.diagnostic
            }
            const diagnosticDTO = await getDiagnostic(entrepriseId, detailed, section, categorie, statuts)
            return mapDTOToModel(diagnosticDTO)
        })
})()

const save = (function() {
    return createAsyncThunk('diagatlas/diagnostic/save',
        async ({ diagnostic, reset }: { diagnostic: DiagnosticUpdate, reset: boolean }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            if (!diagnostic.entrepriseId) {
                diagnostic.entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }
            const diagnosticDTO = mapModelToDTO(diagnostic)
            const savedDiagnosticDTO = await saveDiagnostic(diagnosticDTO, reset)
            return mapDTOToModel(savedDiagnosticDTO)
        })
})()

const saveEtape = (function() {
    return createAsyncThunk('diagatlas/diagnostic/saveEtape',
        async ({ diagnostic, section, categorie }: { diagnostic: DiagnosticUpdate, section?: string | string[], categorie?: string }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            if (!diagnostic.entrepriseId) {
                diagnostic.entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }
            const diagnosticDTO = mapModelToDTO(diagnostic)
            const savedDiagnosticDTO = await saveDiagnosticEtape(diagnosticDTO, section, categorie)
            return mapDTOToModel(savedDiagnosticDTO)
        })
})()

const updateScenario = (function() {
    return createAsyncThunk('diagatlas/diagnostic/updateScenario',
        async ({ facteur, categorie }: { facteur: string, categorie: string }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            const savedDiagnosticDTO = await updateDiagnosticScenario({ Entreprise: entrepriseId, Facteur: facteur, Categorie: categorie })
            return savedDiagnosticDTO
        })
})()

const updateBesoin = (function() {
    return createAsyncThunk('diagatlas/diagnostic/updateBesoin',
        async ({ besoin, choix }: { besoin: string, choix: boolean }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            const savedDiagnosticDTO = await updateDiagnosticBesoin({ Entreprise: entrepriseId, Besoin: besoin, Choix: choix })
            return savedDiagnosticDTO
        })
})()

const updateCompetence = (function() {
    return createAsyncThunk('diagatlas/diagnostic/updateCompetence',
        async ({ competence, ajoutEntreprise, actif }: { competence: string, actif?: boolean | null, ajoutEntreprise?: boolean | null }, 
            { getState, rejectWithValue }) => {

            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            try {
                const savedDiagnosticDTO = await updateDiagnosticCompetence({ Entreprise: entrepriseId, Competence: competence, AjoutEntreprise: ajoutEntreprise, Actif: actif })
                return savedDiagnosticDTO
            } catch (e: any) {
                if (!e.response || !e.response.data) {
                    throw e
                }
                return rejectWithValue(e.response.data)
            }
        })
})()

const deleteCompetence = (function() {
    return createAsyncThunk('diagatlas/diagnostic/deleteCompetence',
        async ({ competence }: { competence: string }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            const savedDiagnosticDTO = await deleteDiagnosticCompetence({ Entreprise: entrepriseId, Competence: competence })
            return savedDiagnosticDTO
        })
})()

const updateMetier = (function() {
    return createAsyncThunk('diagatlas/diagnostic/updateMetier',
        async ({ metier, ajoutEntreprise, sinformer, former, recruter }: { metier: string, ajoutEntreprise?: boolean | null, sinformer?: boolean | null, former?: boolean | null, recruter?: boolean | null }, 
            { getState, rejectWithValue }) => {

            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            try {
                const savedDiagnosticDTO = await updateDiagnosticMetier({ Entreprise: entrepriseId, AjoutEntreprise: ajoutEntreprise, Metier: metier, Sinformer: sinformer, Former: former, Recruter: recruter })
                return savedDiagnosticDTO
            } catch (e: any) {
                if (!e.response || !e.response.data) {
                    throw e
                }
                return rejectWithValue(e.response.data)
            }
        })
})()

const deleteMetier = (function() {
    return createAsyncThunk('diagatlas/diagnostic/deleteMetier',
        async ({ metier }: { metier: string }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            const savedDiagnosticDTO = await deleteDiagnosticMetier({ Entreprise: entrepriseId, Metier: metier })
            return savedDiagnosticDTO
        })
})()

const validerPlan = (function() {
    return createAsyncThunk('diagatlas/diagnostic/validerPlan',
        async (diagnostic: DiagnosticUpdate, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            if (!diagnostic.entrepriseId) {
                diagnostic.entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }
            const diagnosticDTO = mapModelToDTO(diagnostic)
            const savedDiagnosticDTO = await validerPlanActions(diagnosticDTO)
            return mapDTOToModel(savedDiagnosticDTO)
        })
})()

const addAction = (function() {
    return createAsyncThunk('diagatlas/diagnostic/addAction',
        async ({ action, preconisee }: { action: string, preconisee: boolean }, { getState, rejectWithValue }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            try {
                const savedDiagnosticActionDTO = await addDiagnosticAction({ Entreprise: entrepriseId, Action: action, preconisee })
                return mapAction(savedDiagnosticActionDTO)
            } catch (err: any) {
                return rejectWithValue(err?.response?.data?.message ?? "error")
            }
        })
})()

const createAction = (function() {
    return createAsyncThunk('diagatlas/diagnostic/createAction',
        async ({ categorie, libelle, lien }: { categorie: string, libelle: string, lien?: string }, { getState, rejectWithValue }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            try {
                const savedDiagnosticActionDTO = await createDiagnosticAction({ 
                    Entreprise: entrepriseId, 
                    ActionInfo: { Categorie: categorie, Libelle: libelle, URL: lien }
                })
                return mapAction(savedDiagnosticActionDTO)
            } catch (err: any) {
                return rejectWithValue(err?.response?.data?.message ?? "error")
            }
        })
})()

const moveAction = (function() {
    return createAsyncThunk('diagatlas/diagnostic/moveAction',
        async ({ action, statut, ordre }: { action: string, statut: string, ordre?: number }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            const savedDiagnosticActionDTO = await moveDiagnosticAction({ Entreprise: entrepriseId, Action: action, Statut: statut, Ordre: ordre })
            return mapAction(savedDiagnosticActionDTO)
        })
})()

const deleteAction = (function() {
    return createAsyncThunk('diagatlas/diagnostic/deleteAction',
        async ({ action }: { action: string }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            const savedDiagnosticActionDTO = await moveDiagnosticAction({ Entreprise: entrepriseId, Action: action, Statut: 'preconisee', Ordre: 0 })
            return mapAction(savedDiagnosticActionDTO)
        })
})()

const desactiverAction = (function() {
    return createAsyncThunk('diagatlas/diagnostic/desactiverAction',
        async ({ action }: { action: string }, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            let entrepriseId = diagnosticState.diagnostic?.entrepriseId
            if (!entrepriseId) {
                entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }

            await desactiverDiagnosticAction({ Entreprise: entrepriseId, Action: action })
        })
})()

const deleteAll = (function() {
    return createAsyncThunk('diagatlas/diagnostic/delete',
        async (diagnostic: DiagnosticUpdate, { getState }) => {
            const diagnosticState = (getState() as RootState).diagnosticState
            if (!diagnostic.entrepriseId) {
                diagnostic.entrepriseId = (getState() as RootState).contextState.entreprise?.Id ?? ''
            }
            localStorage.removeItem('confettisDiagSeen')
            const diagnosticDTO = mapModelToDTO(diagnostic)
            await deleteDiagnostic(diagnosticDTO)
        })
})()

const reset = (state: WritableDraft<DiagnosticState>, values?: Partial<DiagnosticState>) => {
    Object.assign(state, { ...initialState, ...values })
}

const slice = createSlice({
    name: 'diagatlas/diagnostic',
    initialState: initialState,
    reducers: {
        clear(state) {
            state.error = null
            state.loading = false
            state.saving = false
        },
    },
    extraReducers: (builder) => { builder
        .addCase(load.pending, (state, action) => {
            reset(state, { diagnostic: state.diagnostic, loading: true })
        })
        .addCase(load.fulfilled, (state, action) => {
            const diagnostic = action.payload
            reset(state, { diagnostic, loading: false })
        })
        .addCase(load.rejected, (state, action) => {
            reset(state, { error: action.error.message, loading: false })
        })

        .addCase(save.pending, (state, action) => {
            reset(state, { diagnostic: state.diagnostic, saving: true })
        })
        .addCase(save.fulfilled, (state, action) => {
            const diagnostic = action.payload
            reset(state, { diagnostic })
        })
        .addCase(save.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(saveEtape.pending, (state, action) => {
            reset(state, { diagnostic: state.diagnostic, saving: true })
        })
        .addCase(saveEtape.fulfilled, (state, action) => {
            const diagnostic = action.payload
            reset(state, { diagnostic })
        })
        .addCase(saveEtape.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(updateScenario.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.scenarios?.findIndex(s => s.Scenario.Facteur.Id === action.meta.arg.facteur)
            if (diagnostic && diagnostic.scenarios && index != null && index >= 0) {
                diagnostic.scenarios[index] = action.payload
            }
            reset(state, { diagnostic })
        })
        .addCase(updateScenario.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(updateBesoin.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.besoins?.findIndex(b => b.Besoin.Id === action.meta.arg.besoin)
            if (diagnostic && diagnostic.besoins && index != null && index >= 0) {
                diagnostic.besoins[index] = action.payload
            }
            reset(state, { diagnostic })
        })
        .addCase(updateBesoin.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(updateCompetence.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.competences?.findIndex(mc => mc.Competence.Id === action.meta.arg.competence)
            if (diagnostic && diagnostic.competences && index != null && index >= 0) {
                diagnostic.competences[index] = action.payload
            } else if (diagnostic && diagnostic.competences) {
                const addIndex = diagnostic?.competences?.findIndex(mc => mc.Competence.Code >= action.payload.Competence.Code)
                if (addIndex >= 0)
                    diagnostic.competences.splice(addIndex, 0, action.payload)
                else
                    diagnostic.competences.push(action.payload)
            }
            reset(state, { diagnostic })
        })
        .addCase(updateCompetence.rejected, (state, action) => {
            reset(state, { error: (action?.payload as any)?.message ?? action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(deleteCompetence.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.competences?.findIndex(mc => mc.Competence.Id === action.meta.arg.competence)
            if (diagnostic && diagnostic.competences && index != null && index >= 0) {
                diagnostic.competences.splice(index, 1)
            }
            reset(state, { diagnostic })
        })
        .addCase(deleteCompetence.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(updateMetier.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.metiers?.findIndex(mc => mc.Metier.Id === action.meta.arg.metier)
            if (diagnostic && diagnostic.metiers && index != null && index >= 0) {
                diagnostic.metiers[index] = action.payload
            } else if (diagnostic && diagnostic.metiers) {
                const addIndex = diagnostic?.metiers?.findIndex(mc => mc.Metier.Code >= action.payload.Metier.Code)
                if (addIndex >= 0)
                    diagnostic.metiers.splice(addIndex, 0, action.payload)
                else
                    diagnostic.metiers.push(action.payload)
            }
            reset(state, { diagnostic })
        })
        .addCase(updateMetier.rejected, (state, action) => {
            reset(state, { error: (action?.payload as any)?.message ?? action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(deleteMetier.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.metiers?.findIndex(mc => mc.Metier.Id === action.meta.arg.metier)
            if (diagnostic && diagnostic.metiers && index != null && index >= 0) {
                diagnostic.metiers.splice(index, 1)
            }
            reset(state, { diagnostic })
        })
        .addCase(deleteMetier.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(validerPlan.pending, (state, action) => {
            reset(state, { diagnostic: state.diagnostic, saving: true })
        })
        .addCase(validerPlan.fulfilled, (state, action) => {
            const diagnostic = action.payload
            reset(state, { diagnostic })
        })
        .addCase(validerPlan.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(addAction.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            if (diagnostic) {
                diagnostic.dateModification = action.payload.DiagnosticDateModification
                diagnostic.contactModification = action.payload.DiagnosticContactModification
            }
            if (action.meta.arg.preconisee) {
                if (diagnostic && diagnostic.planAction)
                    diagnostic.planAction.splice(0, 0, action.payload!)
            } else {
                const index = diagnostic?.planAction?.findIndex(act => act.Action.Id === action.meta.arg.action)
                if (diagnostic && diagnostic.planAction && index != null && index >= 0) {
                    diagnostic?.planAction?.splice(index, 1)
                }
            }
            if (diagnostic && action.payload.Ordre != null) {
                diagnostic.hasPlanAction = true
            }
            reset(state, { diagnostic })
        })
        .addCase(addAction.rejected, (state, action) => {
            reset(state, { error: action.payload, diagnostic: state.diagnostic })
        })

        .addCase(createAction.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            if (diagnostic) {
                diagnostic.dateModification = action.payload.DiagnosticDateModification
                diagnostic.contactModification = action.payload.DiagnosticContactModification
            }
            if (diagnostic && diagnostic.planAction)
                diagnostic.planAction.splice(0, 0, action.payload!)
            reset(state, { diagnostic })
        })
        .addCase(createAction.rejected, (state, action) => {
            reset(state, { error: action.payload, diagnostic: state.diagnostic })
        })

        .addCase(moveAction.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.planAction?.findIndex(act => act.Action.Id === action.meta.arg.action)
            if (diagnostic && diagnostic.planAction && index != null && index >= 0) {
                const prevAction = diagnostic?.planAction?.[index]
                diagnostic.planAction.splice(index, 1)
                diagnostic.planAction.forEach(a => {
                    if (a.Statut === prevAction.Statut && a.Ordre > prevAction.Ordre)
                        a.Ordre--
                })
                const newStatutLibelle = STATUTS_ACTIONS_PLAN.find(s => s.id === action.meta.arg.statut)?.label
                diagnostic.planAction.forEach(a => {
                    if (a.Statut === newStatutLibelle && a.Ordre >= (action.meta.arg.ordre ?? 0))
                        a.Ordre++
                })
                const addIndex = diagnostic?.planAction?.findIndex(act => act.Statut === newStatutLibelle && act.Ordre >= (action.meta.arg.ordre ?? 0))
                if (diagnostic && diagnostic.planAction && addIndex != null && addIndex >= 0) {
                    diagnostic.planAction.splice(addIndex, 0, action.payload)
                } else if (diagnostic && diagnostic.planAction) {
                    diagnostic.planAction.push(action.payload)
                }
            }
            if (diagnostic && action.payload.Ordre != null) {
                diagnostic.hasPlanAction = true
            }
            reset(state, { diagnostic })
        })
        .addCase(moveAction.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(deleteAction.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.planAction?.findIndex(act => act.Action.Id === action.meta.arg.action)
            if (diagnostic && diagnostic.planAction && index != null && index >= 0) {
                diagnostic.planAction[index] = action.payload
            }
            reset(state, { diagnostic })
        })
        .addCase(deleteAction.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(desactiverAction.fulfilled, (state, action) => {
            const diagnostic = state.diagnostic
            const index = diagnostic?.planAction?.findIndex(act => act.Action.Id === action.meta.arg.action)
            if (diagnostic && diagnostic.planAction && index != null && index >= 0) {
                diagnostic.planAction.splice(index, 1)
            }
            reset(state, { diagnostic })
        })
        .addCase(desactiverAction.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })

        .addCase(deleteAll.pending, (state, action) => {
            reset(state, { diagnostic: state.diagnostic, saving: true })
        })
        .addCase(deleteAll.fulfilled, (state, action) => {
            reset(state, {})
            clearDiagnosticEtape(action.meta.arg.entrepriseId)
        })
        .addCase(deleteAll.rejected, (state, action) => {
            reset(state, { error: action.error.message, diagnostic: state.diagnostic })
        })
    }
})

export default slice

export const diagnosticSliceActions = {
    ...slice.actions,
    load,
    save,
    saveEtape,
    updateScenario,
    updateBesoin,
    updateCompetence,
    deleteCompetence,
    updateMetier,
    deleteMetier,
    validerPlan,
    addAction,
    createAction,
    moveAction,
    deleteAction,
    desactiverAction,
    deleteAll,
}
