
import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { MyTags } from 'atlas-ds'
import { DropdownCheckbox } from './inputs/DropdownCheckboxes'

interface SelectedCheckboxesProps {
    label: string
    name: string
    checkboxes: DropdownCheckbox[]
    hideRemove?: boolean
}

export default function SelectedCheckboxes(props: SelectedCheckboxesProps) {

    const context = useFormContext()
    const fieldState = getFieldState<string[]>(context, props.name)

    const removeId = (value: string) => {
        context.setValue(props.name, (fieldState.value ?? []).filter(id => id !== value), true)
    }

    return (<>{
        (fieldState.value ?? []).map(selectedValue => {
            const value = props.checkboxes.find(checkbox => (checkbox.value === selectedValue))?.label ?? '?'
            return (
                <MyTags.Item
                    key={ props.name + '_sel' + selectedValue }
                    label={ props.label }
                    delete={props.hideRemove ? undefined : {
                        ariaLabel: `Retirer le filtre ${value}`,
                        onDelete: () => removeId(selectedValue)
                    }}
                >{ value }</MyTags.Item>
            )
        })
    }</>)
}
