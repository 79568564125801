import { Text } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import { formatConcat, splitAdresse } from 'components/format/Format'
import { Adresse } from 'slices/dossierCommon'

interface CerfaAdresseFieldProps {
    label?: string
    value?: Adresse | null
}

export default function CerfaAdresseField(props: CerfaAdresseFieldProps) {
    const adresse = splitAdresse(props.value?.adresse)
    const complement = formatConcat([props.value?.appartement, props.value?.batiment, props.value?.complement], ' / ')

    return (<>
        <Text>
            { props.label ?? '' }N° : <Text style={styles.user}>{adresse.numero ?? ' '}</Text>
            {'   '}
            Voie : <Text style={styles.user}>{adresse.adresse ?? ' '}</Text>
        </Text>
        <Text>
            Complément :{' '}
            <Text style={styles.user}>{complement ?? ' '}</Text>
        </Text>
        <Text>
            Code Postal :{' '}
            <Text style={styles.user}>{props.value?.codePostal ?? ' '}</Text>
        </Text>
        <Text>
            Commune :{' '}
            <Text style={styles.user}>{props.value?.commune ?? ' '}</Text>
        </Text>
    </>)
}
