import { DelaiTraitementDossier } from 'api/dossierAPI'
import { findDispositifFO } from 'api/references'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { MyAsideInfo } from 'atlas-ds'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { delaisTraitementDossierPrestataireActions } from 'slices/delaisTraitementSlice'

export default function DelaiTraitementPrestataire() {

    const dispatch = useAppDispatch()

    const { delais } = useSelector((state: RootState) => state.delaisTraitementDossierPrestataireState)

    useEffect(() => {
        if (!delais)
            dispatch(delaisTraitementDossierPrestataireActions.load())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    if (delais == null || delais.length === 0)
        return null

    const groupedDelais: {[index: string]: DelaiTraitementDossier[]} = {}

    delais.forEach(delai => {
        if (!groupedDelais[delai.DelaiTraitement]) {
            groupedDelais[delai.DelaiTraitement] = []
        }

        groupedDelais[delai.DelaiTraitement].push(delai)
    });

    // Find the maximum length of a group
    const maxGroupLength = Math.max(...Object.values(groupedDelais).map(group => group.length))

    // If there is a group with more than 1 item, store its index
    const maxGroupIndex = maxGroupLength === 1
        ? -1
        : Object.keys(groupedDelais).findIndex(key => groupedDelais[key].length === maxGroupLength)

    return <MyAsideInfo
        iconName="calendar"
        label="Délai moyen pour une première analyse de votre dossier :"
        content={<ul>
            {Object.values(groupedDelais).map((delai, index) =>
                index === maxGroupIndex
                    ? <li key="rest">
                        {delai[0].DelaiTraitement} jours pour {Object.values(groupedDelais).length === 1
                            ? "tous les types de dossier" :
                            "les autres types de dossier"
                        }
                    </li>
                    : delai.map(delai => <li key={ delai.DispositifFOIds[0] }>
                        { delai.DelaiTraitement } jours pour un { findDispositifFO(delai.DispositifFOIds[0])?.Libelle__c.toLowerCase() }
                    </li>
                )
            )}
        </ul>}
        updatedAt={ new Date(delais[0].DateDerniereModification) }
    />
}
