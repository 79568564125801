import { ETAT_DOSSIER_EN_COURS, ETAT_DOSSIER_INCOMPLET } from 'api/references'
import { getDossiersStatistiques } from 'api/statsAPI'
import useApiState from 'api/useApiState'
import { RootState } from 'app/rootReducer'
import { AtlasInfo, AtlasLoading, MyFeaturedData } from 'atlas-ds'
import Link, { linkConstructor } from 'components/Link'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactLink } from 'react-router-dom'

export default function DossiersStatistiques() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const [{ pending, value: statistiques }, withGetDossiersStatistiques] = useApiState(getDossiersStatistiques)
    useEffect(() => {
        if (entreprise)
            withGetDossiersStatistiques(entreprise)
    }, [entreprise, withGetDossiersStatistiques]);

    const statistique = (etatDossierId: string) => {
        let value = 0
        statistiques?.forEach(dispositif => {
            dispositif.Statistiques.forEach(stat => {
                if (stat.EtatDossierFO__c === etatDossierId)
                    value += stat.Nb ?? 0
            })
        })
        return value
    }

    const incomplets = statistique(ETAT_DOSSIER_INCOMPLET)
    const enCours = statistique(ETAT_DOSSIER_EN_COURS)
    const alertes = statistiques?.reduce((sum, dispositif) => sum + (dispositif.NbAlertes ?? 0), 0) ?? 0

    const dossiersIncomplets = {
        value: incomplets,
        label: "Dossiers incomplets",
        href: `/mes-dossiers-contrats?etat-dossier-fo=${ETAT_DOSSIER_INCOMPLET}`
    }

    const dossiersEnCours = {
        value: enCours,
        label: "Dossiers en cours",
        href: `/mes-dossiers-contrats?etat-dossier-fo=${ETAT_DOSSIER_EN_COURS}`
    }

    const { info = undefined, datas } = ( import.meta.env.REACT_APP_RELANCE === 'true' ?
        {
            info: statistiques == null ? undefined : ( alertes > 0 ?
                <AtlasInfo
                    type="error"
                    title={ `${ alertes } dossier${ alertes > 1 ? 's' : '' } requi${ alertes > 1 ? 'èrent' : 'ert' } une action de votre part` }
                    link={<ReactLink to="/mes-dossiers-contrats?alerte=oui&demande=non">Voir les dossiers</ReactLink>}
                /> :
                <AtlasInfo
                    type="success"
                    title="Vos dossiers sont à jour"
                >Aucun dossier ne requiert d'action de votre part.</AtlasInfo>
            ),
            datas: [dossiersEnCours]
        } :
        {
            datas: [dossiersIncomplets, dossiersEnCours]
        }
    )

    return (
        <AtlasLoading loading={pending}>
            <MyFeaturedData
                label="Dossiers"
                icon="dossiers"
                info={ info }
                datas={ datas }
                linkConstructor={linkConstructor}
                link={<Link to="/mes-dossiers-contrats?reset">Tous les dossiers</Link>}
            ></MyFeaturedData>
        </AtlasLoading>
    )
}
