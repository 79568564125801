export const ELEMENTS = {
  ACCESSES: '#acces-rapides,.o-accesses',
  COMPANY: '.my-template__company,.l-template__company',
  COMPANY_FIELD: '.atlas-fieldText',
  DATA: '#mes-donnees,.o-dataSummaries',
  DIAG: '#diagInfo',
  EDIT: '#modifier',
  FILE_MANDATORY: '#pieces-obligatoires',
  FILE_OPTIONAL: '#piece-complementaire',
  INITIATE: '.atlas-button[type="submit"],.a-cta[type="submit"]',
  MENU: '.my-menu,.m-menu',
  NEW_REQUEST: '.my-dropdownMenu__cta',
  NEWS: '.my-newsFeed,.m-newsFeed__list',
  NOTIFICATIONS: '.atlas-nav__notificationsCta,.o-atlasNav__notificationsCta',
  PIECES_CONFIRM: '.atlas-button[type="submit"],.a-cta[type="submit"]',
  PROGRESSION: '.my-steps,.o-progression',
  TABLE: '.my-table thead,.o-table thead',
  TOOLBOX: '#toolbox,.o-toolbox',
  TOOLTIP: '.atlas-tooltip,.m-tooltip',
  USER: '.atlas-userButton,.o-atlasNav__atlasCta',
};
