import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useOidc } from '@axa-fr/react-oidc'
import * as Sentry from '@sentry/react'
import { AtlasUserButton } from 'atlas-ds'
import AtlasMenu from 'atlas-menu'
import { useAppState } from 'app/useAppState'
import { removeConfigFromStorage } from 'slices/configSlice'
import { AppType } from 'slices/contextSlice'
import { removeReferencesFromStorage } from 'slices/referencesSlice'

export default function AtlasCta() {
    const { app, profil, services } = useAppState()
    const { logout } = useOidc()
    const history = useHistory()

    const clearStorageAndLogout = () => {
        removeConfigFromStorage()
        removeReferencesFromStorage()
        logout()
        Sentry.setUser(null)
    }

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const buttonRef = useRef(null)
    const menu = useRef(null)

    useEffect(() => {
        if (!menu.current && services) {
            menu.current = new AtlasMenu({
                opener: buttonRef.current,
                onLogout: clearStorageAndLogout,
                top: 30,
                onOpen: () => setMenuIsOpen(true),
                onClose: () => setMenuIsOpen(false),
                services: [
                    {
                        id: "my-fafiec-portail",
                        url: import.meta.env.REACT_APP_MENU_SERVICE_FAFIEC
                    },
                    ...services.find(service => service.Id === 'sel-gad-adh') ? [{
                        id: "sel-atlas",
                        ...app === AppType.Entreprise
                            ? {isCurrentPage: true}
                            : {url: import.meta.env.REACT_APP_MENU_SERVICE_ENTREPRISE}
                    }] : [],
                    ...services.find(service => service.Id === 'selof') ? [{
                        id: "sel-atlas-prestataire",
                        ...app === AppType.Prestataire
                            ? {isCurrentPage: true}
                            : {url: import.meta.env.REACT_APP_MENU_SERVICE_PRESTATAIRE}
                    }] : [],
                    {
                        id: "campus",
                        url: import.meta.env.REACT_APP_MENU_SERVICE_CAMPUS
                    },
                    {
                        id: "soa",
                        url: import.meta.env.REACT_APP_MENU_SERVICE_SOA
                    }
                ],
                parameters: [
                    {
                        id: "compte",
                        onClick: () => { history.push('/mes-parametres') }
                    },
                    ...app === AppType.Prestataire ? [
                        {
                            id: "campus",
                            onClick: () => { history.push('/gestion-compte-entreprise') }
                        }
                    ] : []
                ]
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const firstName = profil?.Contact.FirstName
        ? profil.Contact.FirstName[0].toUpperCase() + profil.Contact.FirstName.slice(1)
        : ''
    const lastName = profil?.Contact.LastName
        ? profil.Contact.LastName[0].toUpperCase() + profil.Contact.LastName.slice(1)
        : ''

    return <AtlasUserButton
        ref={buttonRef}
        open={menuIsOpen}
        userName={`${firstName} ${lastName}`}
    />
}
