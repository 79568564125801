import axios from 'axios'
import { getRequestConfig } from './baseAPI'

export interface Stage {
    Id: number
    Reference: string
    Libelle: string
    dureeHeures: number
    OF: StageOF
    ReferenceRNCP: string
    Specialite: string
    NiveauVise: string
    TypeQualification: string
    Foad: string
    dureeJours: number
    Cout: number
    CoutInterEntr: number
    CoutIntraEntr: number
    CoutSupportCours: number
    CoutCertification: number
    CoutSupportCoursRevise: number
    CoutCertificationRevise: number
    CoutInterEntrRevise: number
    CoutIntraEntrRevise: number
    CertificationObligatoire: boolean
    StagiaireMinInterEntr: number
    StagiaireMaxInterEntr: number
    StagiaireMinIntraEntr: number
    StagiaireMaxIntraEntr: number
    Filiere: StageFiliere
    DateDebutValidite: string
    DateFinValidite: string
}

export interface StageBranche {
    Id: string
    LibelleReduit: string
    Libelle: string
    Actif: boolean
    Commentaire: string
}

export interface StageFiliere {
    BranchesFiliere: string
    Branches: StageBranche[]
    Id: string
    LibelleReduit: string
    Libelle: string
    Actif: boolean
    Commentaire: string
}

interface StageOF {
    AdressePrincipale: string
    Entreprise: string
    NumeroActivite: string
    SIRET: string
}

export async function getStage(codeStage: string): Promise<Stage> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<Stage>('/stages/' + codeStage, requestConfig)
    return data
}

interface CampusOFAdresse {
    Adresse1__c: string
    Adresse2__c: string
    Adresse3__c: string
    Adresse4__c: string
    CodePostal__c: string
    Ville__c: string
}

interface CampusAdresse extends CampusOFAdresse {
    Id: string
    IdHeroku: number
    Departement__c: string
    Region__c: string
}

interface CampusSession {
    Id: string
    IdHeroku: number
    DateDebut__c: string
    DateFin__c: string
    HeureDebut__c: string
    HeureFin__c: string
    Modalite__c: string
    SessionGarantie__c: boolean
    Adresse: CampusAdresse
    Type__c: string
    NbPlacesTotalAtlas__c: number
    NbPlacesOccupees__c: number
    NbPlacesDisponibles__c: number
    Stage: CampusStage
}

interface CampusStage {
    Id: string
    IdHeroku: number
    Reference__c: string
    Libelle__c: string
    DureeHeures__c: number
    CoutInter__c: number
    CoutIntra__c: number
    SupportCours__c: boolean
    CoutSupportCours__c: number
    CertificationObligatoire__c: boolean
    Certification__c: boolean
    CoutCertification__c: number
    DateDebutValidite__c: string
    DateFinValidite__c: string
    EtablissementOF: CampusOF
    Module: CampusModule
    CoutCertificationRevise__c: number
    CoutSupportDeCoursRevise__c: number
    CoutinterEtrRevise__c: number
    CoutintraEtrRevise__c: number
}

interface CampusOF {
    Id: string
    IdHeroku: number
    Name: string
    CodeEtablissement__c: string
    SIRET__c: string
    UAI__c: string
    NumeroDA__c: string
    Adresse: CampusOFAdresse
}

interface CampusModule {
    Id: string
    IdHeroku: number
    StagiaireMinInter__c: number
    StagiaireMaxInter__c: number
    StagiaireMinIntra__c: number
    StagiaireMaxIntra__c: number
}

export async function getCampusSession(codeStage: string): Promise<CampusSession> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<CampusSession>('/campus/sessions/' + codeStage, requestConfig)
    return data
}