import { DossierDetail, postRelanceCloturerDossier } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_CLOTURE } from 'api/references'
import { AtlasInfo } from 'atlas-ds'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { DossierRelanceActionProps } from '.'
import DossierRelanceAction from './DossierRelanceAction'


export function DossierRelanceCloturer(props: DossierRelanceActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    return <DossierRelanceAction
        dossier={ dossier }
        id="cloture"
        label="Demander la clôture du dossier"
        of={props.of}
        typeDemande={DEMANDE_EN_COURS_CLOTURE}
        successMessage="Votre demande de clôture a bien été prise en compte. Elle sera traitée dans les meilleurs délais"
        formContent={<AtlasInfo type="warning" title="Aucune autre facture ne pourra être déposée.">
            Confirmez-vous la clôture de votre dossier N°{dossier.NumeroDossier__c} ?
        </AtlasInfo>}
        doAction={ (dossier: DossierDetail) => postRelanceCloturerDossier(dossier.NumeroDossier__c, JSON.stringify({ 'Motif': ' ' })) }
    />
}
