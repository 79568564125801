import { SetValueOptions, UseFormReturn } from "@dsid-opcoatlas/reform"
import { SITUATION_FORMATION_CFA, SITUATION_HANDICAP, TYPE_DEROGATION_MOINS_16ANS, TYPE_DEROGATION_PLUS_29ANS, findDiplome, references } from "api/references"
import { formatDate } from "components/format/Format"
import { AdresseFieldMetadata, adresseMapping } from "components/metadata/AdresseFieldMetadata"
import { BooleanFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { HandicapFieldMetadata } from "components/metadata/HandicapFieldMetadata"
import { SelectRefFieldMetadata } from "components/metadata/RefFieldMetadata"
import { TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import CommuneField from "components/reform/inputs/CommuneField"
import DepartementField from "components/reform/inputs/DepartementField"
import RadioBooleanField from "components/reform/inputs/RadioBooleanField"
import { addMonths, addYears, isAfter, isBefore, startOfToday, startOfYear, subDays, subYears } from "date-fns"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ElementContext, ObjectMetadata, override, requiredIf } from "pages/dossier/metadata/FormMetadata"
import { SECTION_SALARIE_AGORA, salarieFields } from "pages/dossier/metadata/SalariesMetadata"
import { DossierCAPP, DossierCAPPStagiaire, v10required } from "./CAPPModel"

const today = startOfToday()

const DATE_NAISSANCE_MIN = subYears(today, 81)
const DATE_NAISSANCE_MAX = subDays(startOfYear(today), 1)

export const CAPPStagiaireMetadata: ObjectMetadata<DossierCAPPStagiaire> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: override(salarieFields.prenom, { props: { label: "Premier prénom de l’apprenti(e) selon l’état civil" }})
        .mutate(yop => yop.matches(/^[\s-]*[^\s-]*([\s-]*[^\s-]*)?[\s-]*$/, 'Merci de saisir uniquement le premier prénom')),
    sexe: salarieFields.sexe,
    dateNaissance: override(salarieFields.dateNaissance, {
        props: {
            tooltip: TOOLTIPS.capp.apprenti.dateNaissance,
        },
    }).mutate(yop => yop
        .min(DATE_NAISSANCE_MIN, "L'apprenti(e) ne peut pas avoir plus 80 ans")
        .max(DATE_NAISSANCE_MAX, "L'apprenti(e) doit être né(e) avant l'année en cours")
        .test<DossierCAPPStagiaire, DossierCAPP>(ctx => {
            const dateNaissance = ctx.value
            const typeDerogation = ctx.root?.contrat?.typeDerogation
            
            const dateDebutContrat = ctx.root?.contrat?.dateDebut
            if (dateDebutContrat != null) {
                const date15ans = addYears(dateNaissance, 15)
                if (isAfter(date15ans, dateDebutContrat))
                    return ctx.createError(`L'apprenti(e) aura 15 ans le ${formatDate(date15ans)} et n'a donc pas l’âge requis pour conclure un contrat d’apprentissage`)

                const date16ans = addYears(dateNaissance, 16)
                if (typeDerogation !== TYPE_DEROGATION_MOINS_16ANS && isAfter(date16ans, dateDebutContrat)) {
                    return ctx.createError(
                        "L'apprenti(e) n'a pas 16 ans au début du contrat. Il n'a pas l'âge requis pour conclure un contrat d'apprentissage. " +
                        "En cas de dérogation, veuillez le préciser dans le champ « type dérogation »"
                    )
                }
            }

            const dateConclusionContrat = ctx.root?.contrat?.dateConclusion
            if (dateConclusionContrat != null) {
                const date30ans = addYears(dateNaissance, 30)

                if (typeDerogation !== TYPE_DEROGATION_PLUS_29ANS && !isAfter(date30ans, dateConclusionContrat)) {
                    return ctx.createError(
                        "L'apprenti(e) a plus de 29 ans à la date de conclusion du contrat. Il n'a pas l'âge requis pour conclure un contrat d'apprentissage. " +
                        "En cas de dérogation, veuillez le préciser dans le champ « type dérogation »")
                }
            }
        
            return true
        })
        .ignoredIf(ctx => ctx.parent == null)
    ),
    nir: override(salarieFields.nir.required(), { props: { tooltip: TOOLTIPS.capp.apprenti.nir }}),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    projetEntreprise: requiredIf(BooleanFieldMetadata({
        props: {
            label: 'Projet de création ou de reprise d’entreprise',
        },
        mapping: simpleMapping('ProjetCreationRepriseEntrepris__c'),
    }), v10required),
    situationHandicap: SelectRefFieldMetadata({
        props: {
            label: 'En situation de handicap',
            options: refs => refs.REF_SITUATION_HANDICAP.filter(r => r.IsActive__c && r.Priorite__c && r.Categorie__c === '5'),
            onChange: (value, form, parentPath) => {
                if (value !== '' && value !== SITUATION_HANDICAP)
                    form.setValue(parentPath + '.handicap', '2', SetValueOptions.Untouch)
            },
        },
        mapping: simpleMapping("SituationHandicap__c"),
    }).required(),
    handicap: HandicapFieldMetadata({
        props: {
            label: 'Reconnaissance de travailleur handicapé (RQTH)',
            disabled: (ctx: ElementContext<DossierCAPPStagiaire>) => ctx.parent?.situationHandicap !== '' && ctx.parent?.situationHandicap !== SITUATION_HANDICAP,
        },
        mapping: simpleMapping('Handicap__c'),
    }).required(),
    telephone: salarieFields.telephone,
    nationalite: SelectRefFieldMetadata({
        props: {
            label: 'Nationalité',
            options: refs => refs.REF_NATIONALITE,
            tooltip: TOOLTIPS.salarie.nationalite,
            code: true,
            // TODO: libelle: true,
        },
        mapping: simpleMapping('Nationalite__c'),
    }).required(),
    adresse: AdresseFieldMetadata({
        props: {
            exportLabel: 'Adresse',
        },
        mapping: adresseMapping('Adresse1__c', 'Adresse2__c', 'Adresse3__c', 'Adresse4__c', 'CodePostal__c', 'Ville__c')
    }).required(),
    naissanceFrance: BooleanFieldMetadata({
        props: {
            label: "Lieu de naissance",
            labelYes: 'France',
            labelNo: 'Etranger',
            alone: true
        } as any,
        input: props => <RadioBooleanField { ...props }
            onChange={ (value, ctx) => {
                ctx.setValue(props.parentPath + '.communeNaissance', null, SetValueOptions.Untouch)
                if (value)
                    ctx.setValue(props.parentPath + '.departementNaissance', null, SetValueOptions.Untouch | SetValueOptions.Validate)
                else {
                    ctx.setValue(props.parentPath + '.departementNaissance', '099', SetValueOptions.Untouch | SetValueOptions.Validate)
                }
            } } />,
        mapping: {
            mapDTOToModel: (dto: any) => dto.DepartementNaissance__c !== '099',
            mapModelToDTO: (value, dto) => { if (value === false) { dto.DepartementNaissance__c = '099' }},
        },
    }).required(),
    communeNaissance: TextFieldMetadata({
        props: {
            label: 'Commune de naissance',
        },
        input: props => <CommuneField { ...props } departementName="departementNaissance" />,
        mapping: simpleMapping('CommuneNaissance__c'),
    }).required().mutate(yop => yop.max(50)),
    departementNaissance: TextFieldMetadata({
        props: {
            label: "Département de naissance",
        },
        input: props => <DepartementField { ...props } communeName="communeNaissance" />,
        mapping: simpleMapping('DepartementNaissance__c'),
        visibleIf: ctx => ctx.parent?.departementNaissance !== '099',
    }).required().mutate(yop => yop.matches(/^((\d){2,3}|2A|2B|69D|69M)$/, 'Le département doit comporter 2 ou 3 chiffres')),
    regimeSocial: SelectRefFieldMetadata({
        props: {
            label: 'Régime social',
            options: refs => refs.REF_REGIME_SOCIAL.filter(ref => ref.Categorie__c?.split(',').includes('5')),
        },
        mapping: simpleMapping('RegimeSocial__c'),
    }).required(),
    situationAvantContrat: SelectRefFieldMetadata({
        props: {
            label: 'Situation avant contrat',
            options: refs => refs.REF_SITUATION_SALARIE.filter(ref =>
                ref.IsActive__c && ref.Priorite__c && ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            libelle: true,
            tooltip: TOOLTIPS.capp.apprenti.situationAvantContrat,
        },
        mapping: simpleMapping('Situation__c'),
    }).required().mutate(yop => yop.test<DossierCAPPStagiaire, DossierCAPP>((ctx: any) => {
        const dateDebutFormation = ctx.root?.formation?.dateDebutFormation
        const dateConclusion = ctx.root?.contrat?.dateConclusion
        const situationAvantContrat = ctx.value
        if (dateConclusion != null && dateDebutFormation != null && situationAvantContrat === SITUATION_FORMATION_CFA) {
            if (isBefore(dateConclusion, dateDebutFormation)) {
                return ctx.createError("La contrat est conclu avant le début du cycle de la formation. La situation avant contrat de l'apprenti(e) ne peut pas être « 7 - En formation au CFA sous statut de stagiaire de la formation professionnelle, avant conclusion d'un contrat d'apprentissage ». Veuillez modifier la situation avant contrat dans la rubrique Apprenti(e).")
            }
            if (isAfter(dateConclusion, addMonths(dateDebutFormation, 3))) {
                return ctx.createError("La parcours sous statut de stagiaire de la formation professionnelle en amont de la conslusion du contrat ne peut pas dépasser 3 mois à compter de l'entrée de l'apprenti dans le cycle de formation, la situation de l'apprenti avant contrat ne peut pas être « 7 - En formation au CFA sous statut de stagiaire de la formation professionnelle, avant conclusion d'un contrat d'apprentissage ». Veuillez modifier la situation avant contrat dans la rubrique Apprenti(e).")
            }
        }
        })),
    dernierDiplome: SelectRefFieldMetadata({
        props: {
            label: 'Dernier diplôme ou titre préparé',
            options: refs => refs.REF_DIPLOME.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            tooltip: TOOLTIPS.capp.apprenti.dernierDiplome,
        },
        mapping: simpleMapping('DiplomeObtenu__c'),
    }).required(),
    derniereClasse: SelectRefFieldMetadata({
        props: {
            label: 'Dernière classe / Année suivie',
            options: refs => refs.REF_CLASSE_APPRENTI.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            code: true,
            tooltip: TOOLTIPS.capp.apprenti.derniereClasse,
        },
        mapping: simpleMapping('ClasseApprenti__c'),
    }).required(),
    intituleDernierDiplome: TextFieldMetadata({
        props: {
            label: 'Intitulé précis du dernier diplôme ou titre préparé',
            tooltip: TOOLTIPS.capp.apprenti.intituleDernierDiplome,
        },
        mapping: simpleMapping('IntitulePrecisDiplome__c'),
    }).required().mutate(yop => yop.max(255)),
    plusHautDiplome: SelectRefFieldMetadata({
        props: {
            label: 'Diplôme ou titre le plus élevé obtenu',
            options: refs => refs.REF_DIPLOME.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            tooltip: TOOLTIPS.capp.apprenti.plusHautDiplome,
        },
        mapping: simpleMapping('DiplomePlusEleveObtenu__c'),
    }).required(),
    diplomeVise: SelectRefFieldMetadata({
        props: {
            label: "Diplôme ou titre visé par l'apprenti(e)",
            options: refs => refs.REF_DIPLOME.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            tooltip: TOOLTIPS.stagiaire.diplomeVise,
        },
        mapping: simpleMapping('DiplomeVise__c'),
    }).required().mutate(yop => yop.test<DossierCAPPStagiaire, DossierCAPP>(ctx => {
        const libelleDiplomeVise = findDiplome(ctx.value)?.Libelle__c
        if (libelleDiplomeVise == null)
            return true
        if (libelleDiplomeVise?.startsWith('13') || libelleDiplomeVise?.startsWith('61'))
            return ctx.createError("L'action de formation ne vise pas l'obtention du diplôme ou du titre.")

        const codeDiplome = ctx.root?.formation?.diplome?.codeDiplome
        if (codeDiplome == null)
            return true
        
        if ((codeDiplome.startsWith('1') && !libelleDiplomeVise?.startsWith('7')) ||
            (codeDiplome.startsWith('2') && !libelleDiplomeVise?.startsWith('6')) ||
            (codeDiplome.startsWith('3') && !libelleDiplomeVise?.startsWith('5')) ||
            (codeDiplome.startsWith('4') && !libelleDiplomeVise?.startsWith('4')) ||
            (codeDiplome.startsWith('5') && !libelleDiplomeVise?.startsWith('3'))) {
                const prefix = (8 - parseInt(codeDiplome.substring(0, 1))).toString()
                const diplomeExemple = references()?.REF_DIPLOME.map(r => r.Libelle__c).find(r => r.startsWith(prefix))
                return ctx.createError(
                    `Le titre visé ne correspond pas au code du diplôme ${codeDiplome}. \
                    Ce code correspond à un numéro de titre de la forme ${prefix}X${diplomeExemple != null ? (", par exemple " + diplomeExemple) : "" }.<br> \
                    Le premier caractère du code diplôme précise le niveau du titre selon l'ancienne nomenclature des diplômes.`
                )
        }
        
        return true
    }).ignoredIf(ctx => ctx.parent == null)),
    listeSportifs: BooleanFieldMetadata({
        props: {
            label: "Inscrit sur la liste des sportifs de haut niveau",
            tooltip: "Conformément au 5° de l’article L6222-2 du code du travail.",
        },
        mapping: simpleMapping('InscriptionListeSportifs__c'),
    }).required(),
    statutEntreeEnFormationAgora: SelectRefFieldMetadata({
        section: SECTION_SALARIE_AGORA,
        props: {
            label: 'Statut de la personne à l’entrée en formation',
            options: refs => refs.REF_STATUT_PERSONNE_ENTREE_FORMATION.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        mapping: simpleMapping('StatutEntreeEnFormationAgora__c'),
    }).required(),
    niveauMaximumClasseEntreeFormationAgora: SelectRefFieldMetadata({
        section: SECTION_SALARIE_AGORA,
        props: {
            label: 'Niveau maximum de classe au moment de l\'entrée en formation',
            options: refs => refs.REF_NIVEAU_MAX_CLASSE_ENTREE_FORMATION.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        mapping: simpleMapping('NiveauMaximumClasseEntreeFormationAgora__c'),
    }).required(),
}
