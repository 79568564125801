import { ETAT_FACTURE_EN_COURS, ETAT_FACTURE_INCOMPLET } from 'api/references'
import { getFacturesStatistiques } from 'api/statsAPI'
import useApiState from 'api/useApiState'
import { RootState } from 'app/rootReducer'
import { AtlasInfo, AtlasLoading, MyFeaturedData } from 'atlas-ds'
import Link, { linkConstructor } from 'components/Link'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactLink } from 'react-router-dom'


export default function FacturesStatistiques({ label }: { label?: string }) {

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const [{ pending, value: statistiques }, withGetFacturesStatistiques] = useApiState(getFacturesStatistiques)
    useEffect(() => {
        if (entreprise)
            withGetFacturesStatistiques(entreprise)
    }, [entreprise, withGetFacturesStatistiques]);

    const statistique = (etatFactureId: string) => {
        let value = 0
        statistiques?.forEach(dispositif => {
            dispositif.Statistiques.forEach(stat => {
                if (stat.EtatFactureFO__c === etatFactureId)
                    value += stat.Nb ?? 0
            })
        })
        return value
    }

    const incomplets = statistique(ETAT_FACTURE_INCOMPLET);
    const enCours = statistique(ETAT_FACTURE_EN_COURS);
    const alertes = statistiques?.reduce((sum, dispositif) => sum + (dispositif.NbAlertes ?? 0), 0) ?? 0

    const facturesIncompletes = {
        value: incomplets,
        label: "Factures incomplètes",
        href: `/mes-factures?etat-facture-fo=${ETAT_FACTURE_INCOMPLET}`
    }

    const facturesEnCours = {
        value: enCours,
        label: "Factures en cours",
        href: `/mes-factures?etat-facture-fo=${ETAT_FACTURE_EN_COURS}`
    }

    const { info = undefined, datas } = ( import.meta.env.REACT_APP_RELANCE === 'true' ?
        {
            info: statistiques == null ? undefined : ( alertes > 0 ?
                <AtlasInfo
                    type="error"
                    title={ `${ alertes } facture${ alertes > 1 ? 's' : '' } requi${ alertes > 1 ? 'èrent' : 'ert' } une action de votre part` }
                    link={<ReactLink to="/mes-factures?alerte=oui&demande=non">Voir les factures</ReactLink>}
                /> :
                <AtlasInfo type="success" title="Vos factures sont à jour">
                    Aucune facture ne requiert d'action de votre part.
                </AtlasInfo>
            ),
            datas: [facturesEnCours]
        } :
        {
            datas: [facturesIncompletes, facturesEnCours]
        }
    )
    

    return (
        <AtlasLoading loading={pending}>
            <MyFeaturedData
                label={ label ?? 'Factures' }
                icon="factures"
                info={ info }
                datas={ datas }
                linkConstructor={linkConstructor}
                link={<Link to="/mes-factures?reset">Toutes les factures</Link>}
            ></MyFeaturedData>
        </AtlasLoading>
    )
}
