import { EXTERNAL_LINK_REGEX } from 'app/constants'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasLoading } from 'atlas-ds'
import Link from 'components/Link'
import Toolbox from 'components/Toolbox'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { parametresActions } from 'slices/parametresSlice'

export interface BoiteAOutilsProps {
    filter: string[]
    all?: string[]
}

export default function BoiteAOutils(props: BoiteAOutilsProps) {
    const dispatch = useAppDispatch()

    const { boiteAOutils, pending } = useSelector((state: RootState) => state.parametresState)
    useEffect(() => {
        if (!boiteAOutils) {
            dispatch(parametresActions.loadBoiteAOutils())
        }
    }, [boiteAOutils, dispatch])

    let toolboxItems = () => {
        if (pending) {
            return (
                <>
                    <AtlasLoading.Loader />
                    <AtlasLoading.Loader />
                    <AtlasLoading.Loader />
                </>
            )
        }

        const outils = (boiteAOutils ?? []).filter(item => {
            if (props.filter.includes('all')) {
                if (props.all ?? false)
                    return props.all!.find(id => { return item.dispositifs?.includes(id) })
                return true
            }
            return props.filter.find(id => { return item.dispositifs?.includes(id) })
        })

        if (outils.length === 0) {
            return <>Aucun outil disponible.</>
        }

        return outils.map((toolboxItem) => {
            const lien = toolboxItem.lien ?? ''

            if (EXTERNAL_LINK_REGEX.test(lien)) {
                return (
                    <Link href={ lien } level={ 2 } key={ toolboxItem.id } external>
                        { toolboxItem.labelLien ?? toolboxItem.titre }
                    </Link>
                )
            }

            return (
                <Link level={ 2 } key={ toolboxItem.id } to={ toolboxItem.lien }>
                    { toolboxItem.titre }
                </Link>
            )
        })
    }

    return <div id="toolbox">
        <Toolbox
            heading="Boîte à outils"
            loading={ pending }
            items={ toolboxItems() }
        ></Toolbox>
    </div>
}