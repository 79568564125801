import { createSlice } from '@reduxjs/toolkit';
import { Step } from 'react-joyride';

interface OnboardingState {
  steps: Step[];
  unseenSteps: Step[];
  seenStepsIds: string[];
  id: number;
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: { steps: [], unseenSteps: [], seenStepsIds: [], id: 0 } as OnboardingState,
  reducers: {
    // Set the steps associated to the current view
    setSteps(state, { payload: steps }) {
      state.steps = steps;

      const localSeenSteps = window.localStorage.getItem('seenSteps');
      state.seenStepsIds = localSeenSteps ? JSON.parse(localSeenSteps) : [];
      state.unseenSteps = steps.filter(
        (step: Step) => {
          // Les sélecteurs d'éléments peuvent être stockés sous la forme :
          // '.my-template__company,.l-template__company',
          // pour permettre de ne pas revoir une étape déjà vue, même quand la
          // classe cible a changé (changement de design system par exemple)
          // Ici, on vérifie donc qu'on a pas déjà vu 'my-template__company' ou
          // 'l-template__company'. Il aussi tester dans l'autre sens (le local
          // storage peut contenir un sélecteur multiple alors que le sélecteur
          // final est simple).
          const stepParts = (step.target as string).split(',');

          return !state.seenStepsIds.find(seenStep => {
            const seenStepParts = seenStep.split(',');

            return stepParts.find(stepPart => seenStepParts.indexOf(stepPart) >= 0)
              || seenStepParts.find(seenStepPart => stepParts.indexOf(seenStepPart) >= 0)
          })
        }
      );
      state.id++;
    },
    // Empty the step data for a view without onboarding
    clearSteps(state) {
      state.steps = [];
      state.unseenSteps = [];
      state.id++;
    },
    // Reset the step data so the user can see the onboarding again
    resetUnseenSteps(state) {
      const newSteps = state.steps;
      newSteps[0].disableBeacon = true; // This will trigger the autostart
      state.unseenSteps = newSteps;
      state.id++;
    },
    // Save a step as seen in the local storage
    saveStepAsSeen(state, { payload: step }) {
      const target = step.target as string;
      const seenSteps = JSON.parse(window.localStorage.getItem('seenSteps') ?? '[]')
      seenSteps.push(target)

      if (state.seenStepsIds.indexOf(target) === -1) {
        window.localStorage.setItem('seenSteps', JSON.stringify(seenSteps))
      }
    },
    // Save all the steps as seen in the local storage
    saveAllStepsAsSeen(state) {
      const unseenStepsIds = state.unseenSteps
        .map((step) => step.target as string)
        .filter((stepId) => state.seenStepsIds.indexOf(stepId) === -1);

      window.localStorage.setItem(
        'seenSteps',
        JSON.stringify([...state.seenStepsIds, ...unseenStepsIds]),
      );

      state.unseenSteps = [];
    },
  },
});

export const {
  setSteps,
  clearSteps,
  resetUnseenSteps,
  saveStepAsSeen,
  saveAllStepsAsSeen,
} = onboardingSlice.actions;
