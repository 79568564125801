import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { sendLienMDPOublie } from "api/monCompteAPI"
import { AtlasInfo, MyForm } from "atlas-ds"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import TextField from "components/reform/inputs/TextField"
import { RequiredEmail } from "components/yop/constraints"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { createEmailState, nil } from "./MamcApp"

export function createEmail() {
    return {
        email: nil as string,
    }
}
export type Email = ReturnType<typeof createEmail>


export function MotDePasseOublie() {

    const history = useHistory()
    const [backendError, setBackendError] = useState<string | null>(null)

    const initialValues = createEmail()
    const validationSchema = Yop.object<Email>({
        email: RequiredEmail,
    })

    const form = useForm<Email>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<Email>) => {
            const email = context.values!.email
            sendLienMDPOublie(email)
                .then(_ => {
                    history.replace({
                        pathname: "/mot-de-passe-oublie-validation-email",
                        state: createEmailState(email),
                    })
                })
                .catch(error => {
                    console.error(error)
                    const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
                    setBackendError(message)
                    context.setSubmitting(false)
                })
        }
    })

    return <>
        <PageHeader>Mot de passe oublié</PageHeader>

        <Form context={form} autoComplete="off" noValidate>
            <MyForm>
                <p>Saisissez votre adresse e-mail pour réinitialiser votre mot de passe :</p>

                <MyForm.Grid>
                    <TextField type="email" name="email" label="Adresse e-mail" />
                </MyForm.Grid>

                {backendError && <AtlasInfo type="error" title="Erreur">{ backendError }</AtlasInfo> }

                <MyForm.Actions toLeft>
                    <Button
                        submit={true}
                        spinner={{ spinning: form.submitting }}
                        disabled={form.submitting}
                    >Envoyer le mail de réinitialisation</Button>
                </MyForm.Actions>
            </MyForm>
        </Form>
    </>
}
