import { AtlasValue } from "atlas-ds"

interface RecapBooleanFieldProps {
    label: string,
    value: boolean | null | undefined,
}

export default function RecapBooleanField(props: RecapBooleanFieldProps) {
    return <AtlasValue label={ props.label }>
        { props.value ? 'Oui' : 'Non' }
    </AtlasValue>
}
