import { AtlasIcon, AtlasIconButton, AtlasLinkConstructor } from "atlas-ds";
import { Fragment, MouseEventHandler } from "react";

export interface MyMenuProps {
  /**
   * Les items du menu. Séparer les items en différents tableaux permet de
   * placer des séparateurs.
   */
  itemsGroups: React.ReactElement<MyMenuItemProps>[][];
  /**
   * L'action à éxécuter au clic sur le bouton de fermeture
   */
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Le menu principal
 */
export function MyMenu(props: MyMenuProps) {
  return (
    <nav className="my-menu" aria-label="Menu">
      <AtlasIconButton ariaLabel="Fermer le menu" onClick={props.onClose}>
        <AtlasIcon name="close" />
      </AtlasIconButton>

      <ul className="my-menu__list">
        {props.itemsGroups.map((itemGroup, index) => (
          <Fragment key={`group-${index}`}>
            {index > 0 && (
              <li aria-hidden="true" className="my-menu__separator" />
            )}
            {itemGroup}
          </Fragment>
        ))}
      </ul>
    </nav>
  );
}

export interface MyMenuItemProps {
  /**
   * Le nom de l'élément
   */
  children: string;
  /**
   * L'icône associée à l'élément
   */
  iconName: string;
  /**
   * Le chemin de l'élément
   */
  href: string;
  /**
   * L'élément correspond-t-il à la page actuellement ouverte ?
   */
  active?: boolean;
  /**
   * Action à exécuter au click
   */
  onClick?: MouseEventHandler;
  /**
   * Nombre de notifications associées à cet élément
   */
  notificationsCount?: number;
  /**
   * Si un simple lien ne suffit pas, un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Un élément du menu
 */
MyMenu.Item = (props: MyMenuItemProps) => {
  const linkConstructor: AtlasLinkConstructor =
    props.linkConstructor ||
    ((props: any, content: React.ReactNode) => (
      <a href={props.href} aria-current={props.active ? "page" : undefined}>
        {content}
      </a>
    ));

  const linkContent = (
    <div className="my-menu__itemLink">
      <AtlasIcon name={props.iconName} />
      <p className="my-menu__itemLabel">{props.children}</p>
      {(props.notificationsCount ?? 0) > 0 && (
        <span className="my-menu__itemNotification">
          {props.notificationsCount}
        </span>
      )}
    </div>
  );

  return (
    <li className="my-menu__item" onClick={props.onClick}>
      {linkConstructor(props, linkContent)}
    </li>
  );
};
