import styles from 'pages/dossier/cerfa/global.styles'
import { Text, View } from '@react-pdf/renderer'

export default function Opco({ opco }: { opco?: { nom?: string | null, numeroAdherent?: string | null } | null }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeaderV4}>CADRE RÉSERVÉ À L'OPCO</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>Nom de l'Opco :</Text>
            <Text style={styles.user}>{opco?.nom ?? ' '}</Text>
          </View>

          <View style={styles.colRight}>
            <View> { /* style={styles.greyBackground}> */ }
              <Text>N° d'adhérent de l'employeur à l'Opco s'il existe :</Text>
              <Text style={styles.user}>{opco?.numeroAdherent ?? ' '}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
