import { Yop } from '@dsid-opcoatlas/yop'
import { PointReserveCheck } from 'slices/dossierCommon'
import { SITUATION_FORMATION_PRO, TYPE_CONTRAT_PREMIER, TYPE_MONTANT_SMIC } from 'api/references'
import { parseDate } from 'components/format/Format'
import { isAfter, isBefore, subMonths } from 'date-fns'
import store from 'app/store'
import { getPeriodeSMIC } from 'api/config'
import { diffMonths } from 'api/util'
import { DossierCAPP } from './CAPPModel'


export const dossierCAPPPointsReserve: PointReserveCheck<DossierCAPP>[] = [{
    id: 1010, rubrique: 'CONTRAT', type: 'reco',
    schema: Yop.object<DossierCAPP>({} as any).required()
        .test<DossierCAPP>(ctx => {
            if (!ctx.value.contrat.dateConclusion || !ctx.value.contrat.dateDebut)
                return true

            const anneeSignature = new Date(ctx.value.contrat.dateConclusion).getFullYear()
            const dateContrat = new Date(ctx.value.contrat.dateDebut)
            const moisContrat = dateContrat.getMonth()
            const anneeContrat = dateContrat.getFullYear()

            return !(anneeSignature > (moisContrat === 11 ? anneeContrat + 1 : anneeContrat))
        }, 
        `La date de conclusion (signature) du contrat indiquée semble erronée. En effet, le contrat doit être conclu maximum cinq jours après la date de début de contrat.`,
    )
}, {
    id: 1014, rubrique: 'CONTRAT', type: 'reco',
    schema: Yop.object<DossierCAPP>({} as any).required()
        .test<DossierCAPP>(ctx => {
            if (!ctx.value.contrat.typeContrat || ctx.value.contrat.typeContrat !== TYPE_CONTRAT_PREMIER || ctx.value.stagiaire?.situationAvantContrat === SITUATION_FORMATION_PRO || !ctx.value.stagiaire?.situationAvantContrat || !ctx.value.formation?.dateDebutFormation || !ctx.value.contrat?.dateDebut)
                return true
            const dateDebutFormation = parseDate(ctx.value.formation?.dateDebutFormation)
            const dateDebut = parseDate(ctx.value.contrat?.dateDebut)
            const dateConclusion = parseDate(ctx.value.contrat?.dateConclusion)
            if (!dateDebutFormation || !dateDebut || !dateConclusion)
                return true
            if (isBefore(dateDebutFormation, dateDebut)  && !isBefore(dateConclusion, dateDebut) && isBefore(dateDebutFormation, dateConclusion) && !isBefore(dateDebutFormation, subMonths(dateConclusion, 3))) {
                return ctx.createError(`En cas d’entrée en formation préalable en CFA sans contrat sous statut de stagiaire de la formation professionnelle, dans le champ situation avant contrat/rubrique l’apprenti(e), renseigner « 7 En formation au CFA sous statut de stagiaire de la formation professionnelle, avant conclusion d’un contrat d’apprentissage ».`)
            }
            return true
        },
    )
}, {
    id: 270, rubrique: 'CONTRAT', type: 'reco',
    schema: Yop.object<DossierCAPP>({} as any).required()
        .test<DossierCAPP>(ctx => {
            if ((ctx.value.contrat.remuneration.anneesRemuneration?.length ?? 0) === 0)
                return true
            if (ctx.value.contrat.remuneration.anneesRemuneration[0].remunerationType1 !== TYPE_MONTANT_SMIC)
                return true
            const periodesSMIC = getPeriodeSMIC(store.getState().configState.fulfilled ?? undefined)
            if (!periodesSMIC)
                return true
            const dateDebutContrat = new Date(ctx.value.contrat.dateDebut)
            const periodeSmic = periodesSMIC.find((periodeSmic) => {
                const start = new Date(periodeSmic.DateDebut)
                const end = periodeSmic.DateFin ? new Date(periodeSmic.DateFin) : null
                return !isBefore(dateDebutContrat, start) && (end === null || !isAfter(dateDebutContrat, end))
            })
            if (!periodeSmic)
                return true
            const pourcentageSmicPeriode1 = ((ctx.value.contrat.remuneration.anneesRemuneration[0].remunerationPourcentage1 ?? 0) * periodeSmic.Valeur) / 100;
            return !(
                ctx.value.contrat.typeContrat === TYPE_CONTRAT_PREMIER &&
                ctx.value.contrat.dureeHebdomadaireTravail?.hours === 35 && ctx.value.contrat.dureeHebdomadaireTravail?.minutes === 0 &&
                (ctx.value.contrat.salaireEmbauche ?? 0) < pourcentageSmicPeriode1
            )
        },
        `Le montant du salaire mensuel à l'embauche renseigné est inférieur au montant correspondant au pourcentage de rémunération appliqué par rapport au SMIC en vigueur.`,
    )
}, {
    id: 1019, rubrique: 'CONTRAT', type: 'reco',
    schema: Yop.object<DossierCAPP>({} as any).required()
        .test<DossierCAPP>(ctx => {
            if ((ctx.value.contrat.remuneration.anneesRemuneration?.length ?? 0) === 0)
                return true
            if (ctx.value.contrat.remuneration.anneesRemuneration[0].remunerationType1 !== TYPE_MONTANT_SMIC)
                return true
            const periodesSMIC = getPeriodeSMIC(store.getState().configState.fulfilled ?? undefined)
            if (!periodesSMIC)
                return true
            const dateDebutContrat = new Date(ctx.value.contrat.dateDebut)
            const periodeSmic = periodesSMIC.find((periodeSmic) => {
                const start = new Date(periodeSmic.DateDebut)
                const end = periodeSmic.DateFin ? new Date(periodeSmic.DateFin) : null
                return !isBefore(dateDebutContrat, start) && (end === null || !isAfter(dateDebutContrat, end))
            })
            if (!periodeSmic)
                return true
            const pourcentageSmicPeriode1 = ((ctx.value.contrat.remuneration.anneesRemuneration[0].remunerationPourcentage1 ?? 0) * periodeSmic.Valeur) / 100;
            return !(
                ctx.value.contrat.typeContrat === TYPE_CONTRAT_PREMIER &&
                (
                    ctx.value.contrat.dureeHebdomadaireTravail?.hours > 35 ||
                    (ctx.value.contrat.dureeHebdomadaireTravail?.hours === 35 && ctx.value.contrat.dureeHebdomadaireTravail?.minutes > 0)
                ) &&
                (ctx.value.contrat.salaireEmbauche ?? 0) <= pourcentageSmicPeriode1
            )
        },
        `Le montant du salaire mensuel à l'embauche renseigné est inférieur au montant correspondant au pourcentage de rémunération appliqué par rapport au SMIC en vigueur, compte tenu de la durée hebdomadaire du temps de travail.`,
    )
}, {
    // Ticket #2484
    id: 1020, rubrique: ['FORMATION', 'CONTRAT'], type: 'reco',
    schema: Yop.object<DossierCAPP>({} as any).required()
        .test<DossierCAPP>(ctx => {
            if (!ctx.value.contrat.dateDebut || !ctx.value.contrat.dateFin || !ctx.value.formation || !ctx.value.formation.organismeFormationCout)
                return true
            
            const duree = diffMonths(ctx.value.contrat.dateFin, ctx.value.contrat.dateDebut)
            if (!duree) {
                return true
            }
            return ctx.value.formation.organismeFormationCout >= duree * 500
        },
        `<p>Le cout pédagogique doit correspondre au montant total de la prestation de l’organisme de formation en apprentissage (hors frais annexes), le cas échéant sur plusieurs années de formation. Le montant renseigné contient potentiellement une erreur, qui entrainerait une incohérence sur le montant pris en charge par l’OPCO Atlas et la formalisation d’une demande de correction. Vérifiez bien le coût total indiqué sur la convention de formation et reportez ce montant.</p>`,
    )
}]
