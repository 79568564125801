import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import MesSimulations from './MesSimulations'
import PriseEnChargeSimulation from './PriseEnChargeSimulation'

const simulationsModule = () => {
    return {
        id: 'mes-simulations',
        isEnabled: () => import.meta.env.REACT_APP_MODULES?.includes('simulations'),
        routes: [
            <AppRoute key="simulations1" path="/mes-simulations" render={() => <MesSimulations />} label="Simulations" menuIcon="simulations" />,
            <AppRoute key="convertir" path="/prise-en-charge-simulation" render={() => <PriseEnChargeSimulation /> } />,
        ],
    } as AppModule
}

export default simulationsModule