import { useAppDispatch } from 'app/store'
import { AtlasBreadcrumb, AtlasButton, AtlasFlex, MyAction, MyActionTag, MyKanban, MyKanbanCardMoveEvent, MyModal } from 'atlas-ds'
import PageHeader from 'components/PageHeader'
import { formatToFullDate } from 'components/format/Format'
import Button from 'components/reform/Button'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import { Link } from 'react-router-dom'
import { STATUTS_ACTIONS_PLAN, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import DiagPageHeader from './DiagPageHeader'
import DiagPlanActionOperations from './DiagPlanActionOperations'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


const STATUTS_ACTIONS_PLAN_IDS = STATUTS_ACTIONS_PLAN.map(s => s.id)

interface MonDiagnosticPlanActionProps {
}

export default function MonDiagnosticPlanAction(props: MonDiagnosticPlanActionProps) {

    const dispatch = useAppDispatch()
    const { diagnostic, loading } = useDiagnostic(null, 'plan-action', { statuts: STATUTS_ACTIONS_PLAN_IDS })
    const nav = useDiagnosticNavigation()
    useScrollToTop()

    const supprimerAction = (cardId: string) => {
        const action = diagnostic?.planAction?.find(da => da.ExternalIdHeroku === cardId)
        if (action) {
            dispatch(diagnosticSliceActions.deleteAction({ action: action.Action.Id }))
        }
    }

    const deplacerAction = ({ cardId, toColumnId, beforeCardId }: MyKanbanCardMoveEvent) => {
        const action = diagnostic?.planAction?.find(da => da.ExternalIdHeroku === cardId)
        if (!action) 
            return

        const statut = toColumnId
        const labelStatut = STATUTS_ACTIONS_PLAN.find(s => s.id === toColumnId)?.label
        const beforeAction = diagnostic?.planAction?.find(da => da.ExternalIdHeroku === beforeCardId)
        if (beforeAction) {
            const ordre = beforeAction.Statut === action.Statut && beforeAction.Ordre > action.Ordre ? beforeAction.Ordre - 1 : beforeAction.Ordre
            dispatch(diagnosticSliceActions.moveAction({ action: action.Action.Id, statut, ordre }))
        } else {
            const actionsReverse = diagnostic?.planAction?.concat()?.reverse()
            const lastAction = actionsReverse?.find(a => a.Statut === labelStatut)
            const ordre = lastAction ? (lastAction.Statut === action.Statut && lastAction.Ordre > action.Ordre ? lastAction.Ordre : lastAction.Ordre + 1) : 0
            dispatch(diagnosticSliceActions.moveAction({ action: action.Action.Id, statut, ordre }))
        }
    }

    const ajouterAction = () => {
        nav.navigateToPath('actions')
    }

    return <>
        <DiagPageHeader>
            <PageHeader
                breadcrumb={<AtlasBreadcrumb currentPageLabel="Plan d'action"
                linkConstructor={ (props, content) => <Link to={ props.href }>{ content }</Link> }
                parts={[{
                    label: "Actions préconisées",
                    href: "/mon-diagnostic-actions",
                }]} />}
            >Plan d'action</PageHeader>
        </DiagPageHeader>

        <AtlasFlex column gap="xl">
            <AtlasFlex column gap="s">
                <p>Sur la base du diagnostic réalisé{ diagnostic?.dateRealisation ? ' le ' + formatToFullDate(diagnostic?.dateRealisation) : '' }{ diagnostic?.contactRealisation ? ' par ' + diagnostic?.contactRealisation : '' }{ diagnostic?.dateModification ? ' et modifié le ' + formatToFullDate(diagnostic?.dateModification) : '' }{ diagnostic?.contactModification ? ' par ' + diagnostic?.contactModification : '' }</p>
            </AtlasFlex>

            <DiagPlanActionOperations diagnostic={ diagnostic } />

            <AtlasFlex column gap="s">
                <p>Retrouvez ici votre sélection d'actions préconisées !</p>
                <p><strong>Vous pouvez les classer en fonction de l'horizon de temps auquel vous envisagez de les mettre en œuvre.</strong></p>
            </AtlasFlex>

            { !loading && <MyKanban
                onCardMove={ deplacerAction }
                onCardRemove={ supprimerAction }
                addCta={ <AtlasButton onClick={ ajouterAction }>Ajouter une action préconisée</AtlasButton> }
                columns={ STATUTS_ACTIONS_PLAN.map(s => ({
                    id: s.id,
                    label: s.label,
                    moveLabel: s.shortLabel,
                    emoji: s.emoji,
                    cards: diagnostic?.planAction?.filter(da => da.Statut === s.label)?.map(da => {
                        const tags = [{ label: da.Action.Type.Libelle, category: da.Action.Type.CategorieLibelle }] as MyActionTag[]
                        if (da.Action.Source !== 'Atlas') {
                            tags.push({ label: 'Action personnalisée' } as MyActionTag)
                        }
                        return { id: da.ExternalIdHeroku, card: <MyAction
                            key={ `action-${da.ExternalIdHeroku}` }
                            tags={ tags } 
                            infoHref={ da.Action.URL }
                        >{ da.Action.Libelle }</MyAction> }
                    }) ?? [],
                })) } />
            }
        </AtlasFlex>
    </>
}

export function AskCreerPlanActionModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Validation du diagnostic"
            okButton={ <Button level={ 2 } onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button level={ 3 } onClick={ props.close }>Annuler</Button> }
        >
            <p>La validation du diagnostic créera une liste d'actions préconisées par Atlas en fonction de vos choix.</p>
        </MyModal>
    )
}