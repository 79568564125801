import { Form, useForm } from '@dsid-opcoatlas/reform'
import { DetailFormationCampus, patchFormation } from 'api/formationAPI'
import { ContactEntreprise, getContactsEntreprise } from 'api/profilAPI'
import { sanitizeHtmlEditor } from 'api/references'
import useApiState from 'api/useApiState'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasFlex, MyForm } from 'atlas-ds'
import Button from 'components/reform/Button'
import HtmlEditorField from 'components/reform/inputs/HtmlEditorField'
import MultilineTextField from 'components/reform/inputs/MultilineTextField'
import SelectField from 'components/reform/inputs/SelectField'
import { OptionalString, RequiredString, requiredObject } from 'components/yop/constraints'
import ContactEntrepriseModal from 'pages/gestion-compte-entreprise/ContactEntrepriseModal'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function DetailFormationForm(props: { formation: DetailFormationCampus | null, setEditing: (editing: boolean) => void }) {
    const formation = props.formation

    const history = useHistory()
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const [ showAddContact, setShowAddContact ] = useState(false)
    const [ { value: contacts, pending: contactsPending }, withGetContactsEntreprise ] = useApiState(getContactsEntreprise)
    useEffect(() => {
        if (entreprise?.IdHeroku)
            withGetContactsEntreprise(entreprise.IdHeroku)
    }, [withGetContactsEntreprise, entreprise?.IdHeroku])

    const contactsDefaultCampus = contacts?.filter(c => c.defaultCampus__c === true) ?? []

    type EditableFormation = Pick<DetailFormationCampus, 'ProgrammeDetaille__c' | 'ModaliteEvaluation__c'> & {
        Contact__c: string
        ModaliteCertification__c: string | null
    }

    const initialValues: EditableFormation = {
        ProgrammeDetaille__c: sanitizeHtmlEditor(formation?.ProgrammeDetaille__c ?? ''),
        ModaliteEvaluation__c: formation?.ModaliteEvaluation__c ?? '',
        ModaliteCertification__c: formation?.ModaliteCertification__c ?? '',
        Contact__c: formation?.Contact?.IdHeroku!.toFixed(0) ?? contactsDefaultCampus?.[0]?.IdHeroku!.toFixed(0) ?? ''
    }

    const validationSchema = requiredObject<EditableFormation>({
        ProgrammeDetaille__c: RequiredString.max(25000, "Description trop longue"),
        ModaliteEvaluation__c: RequiredString.max(200),
        ModaliteCertification__c: OptionalString.max(200).requiredIf(_ => formation?.Certification__c === true),
        Contact__c: RequiredString,
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            sanitizeHtmlEditor(formation?.ProgrammeDetaille__c ?? '')
            
            patchFormation(formation?.IdHeroku as number, context.values)
                .then(() => {
                    history.push(history.location.pathname)
                }).finally(() => {
                    form.setSubmitting(false)
                })
            
        },
    })

    const onCreateNewContact = (contact: ContactEntreprise) => {
        form.setValue("Contact__c", contact.IdHeroku!.toFixed(0))
        setTimeout(() => withGetContactsEntreprise(entreprise!.IdHeroku))
    }

    return <>
        <Form context={ form } noValidate>
            <MyForm>
                <HtmlEditorField name="ProgrammeDetaille__c" label="Programme détaillé" />
                <MultilineTextField name="ModaliteEvaluation__c" label="Modalités d'évaluation" />
                <MultilineTextField name="ModaliteCertification__c" label="Modalités de certification" disabled={ formation?.Certification__c !== true } />
                <AtlasFlex gap="s" alignItems='end' wrap>
                    <AtlasFlex.Grow>
                        <SelectField name="Contact__c" label="Contact pour cette formation" selectionner={ true }
                            disabled={ contactsPending }
                            options={ contacts?.map(contact => ({
                                value: contact.IdHeroku!.toFixed(0),
                                label: `${ contact.FirstName } ${ contact.LastName }`
                            })) ?? []
                        } />
                    </AtlasFlex.Grow>
                    <AtlasButton icon="plus" onClick={() => setShowAddContact(true)} level={2}>
                        Ajouter un contact
                    </AtlasButton>
                </AtlasFlex>
                <MyForm.Actions>
                    <Button submit={ true } spinner={{spinning: form.submitting }}>Valider</Button>
                    <Button level={ 2 } onClick={ () => props.setEditing(false) }>Annuler</Button>
                </MyForm.Actions>
            </MyForm>
        </Form>
        { showAddContact &&
            <ContactEntrepriseModal
                entreprise={ entreprise }
                forceDefault={ (contacts?.length ?? 0) === 0 }
                close={ () => setShowAddContact(false) }
                onCreate={ onCreateNewContact }
            />
        }
    </>
}
