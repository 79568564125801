import { Step } from 'react-joyride'
import { ELEMENTS } from '../elements'

export const accueilSteps: Step[] = [
  {
    target: ELEMENTS.COMPANY,
    placement: 'right',
    placementBeacon: 'right',
    content:
      'Sélectionner une de vos entreprises rattachées à votre compte myAtlas',
  },
  {
    target: ELEMENTS.MENU,
    placement: 'right',
    placementBeacon: 'right',
    content: 'Accéder aux différentes rubriques de la plateforme myAtlas',
  },
  {
    target: ELEMENTS.NOTIFICATIONS,
    content:
      'Nouveau ! Vous pouvez consulter les derniers événements Atlas sur vos dossiers et factures',
  },
  {
    target: ELEMENTS.USER,
    content:
      'Gérer vos paramètres personnels, vous déconnecter de myAtlas et accéder aux différents services Atlas',
  },
  {
    target: ELEMENTS.ACCESSES,
    placement: 'right',
    placementBeacon: 'right',
    content: 'Accès rapide aux principales fonctionnalités de myAtlas',
  },
  {
    target: ELEMENTS.DATA,
    placement: 'bottom',
    placementBeacon: 'bottom',
    content: "Synthèse de l'activité en cours sur vos dossiers et factures",
  },
  {
    target: ELEMENTS.DIAG,
    content: 'Nouveau ! Découvrez le diagnosticAtlas.'
  }
]
