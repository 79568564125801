import { DISPLAY_DATE_FORMAT } from "app/constants"
import ExtensionPoint from 'app/ExtensionPoint'
import { AtlasFlex, AtlasHeading, MyContainer } from "atlas-ds"
import { format, startOfToday, subYears } from "date-fns"

export type DataSummariesExtension = {
    dataSummaries: {
        stats?: JSX.Element
    }
}

export default function DataSummaries() {
    const date = format(subYears(startOfToday(), 1), DISPLAY_DATE_FORMAT);

    return <div id="mes-donnees">
        <AtlasHeading tag="h2" size="m" secondaryText={ `(depuis le ${date})` }>
            Toutes mes données
        </AtlasHeading>
        <MyContainer colored>
            <AtlasFlex grow wrap gap="l">
                <ExtensionPoint<DataSummariesExtension> name="dataSummaries" point="stats" />
            </AtlasFlex>
        </MyContainer>
    </div>
}
