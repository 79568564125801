import { Dossier } from "api/dossierAPI"
import { FormMetadata } from "pages/dossier/metadata/FormMetadata"
import { DossiersModuleContext } from 'pages/mes-dossiers-contrats'
import { useHistory } from "react-router-dom"

const ETAPE_MATCH = /^\/nouveau-dossier-[0-9a-z\\-]+-etape([0-9]+)?(-edit)?\/.*/
const EDIT_ETAPE_MATCH = /^\/modifier-dossier-[0-9a-z\\-]+-etape([0-9]+)?(-edit)?\/.*/

const storageKey = (dossierId: string) => `dossier-${ dossierId }`

export const getDossierEtapeMax = (dossierId?: string | null) => {
    if (dossierId != null) {
        const etapeMax = parseInt(localStorage.getItem(storageKey(dossierId)) ?? '')
        if (!isNaN(etapeMax))
            return etapeMax
    }
    return null
}

export const getDossierCreationPath = (dto: Dossier, ctx: DossiersModuleContext) => {
    const metadata = ctx.creationMetadata.find(m => m.module.dispositifId === dto.DispositifFO__c)?.create
    if (!metadata)
        return '/'
    
    const etapeMax = getDossierEtapeMax(dto.NumeroDossier__c)
    return !etapeMax || etapeMax <= 1 ?
        `${ metadata.pathname }/${ dto.NumeroDossier__c }` :
        `${ metadata.pathname }-etape${ Math.min(etapeMax, metadata.steps.length) }/${ dto.NumeroDossier__c }`
}

export const getDossierEditPath = (dto: Dossier, ctx: DossiersModuleContext) => {
    const metadata = ctx.creationMetadata.find(m => m.module.dispositifId === dto.DispositifFO__c)?.edit
    if (!metadata)
        return '/'
    return `${ metadata.pathname }-etape1/${ dto.NumeroDossier__c }`
}

export const useDossierNavigation = (metadata: FormMetadata<any>, navigationGuard?: (next: () => void) => boolean) => {
    const { location, push } = useHistory()

    const stepIndex = metadata.steps.findIndex(s => s.pathname === location.pathname)
    const match = stepIndex >= 0 ? null : location.pathname.match(metadata.api.isEdit ? EDIT_ETAPE_MATCH : ETAPE_MATCH)
    const etape = stepIndex >= 0 ? 1 : Number(match?.[1] ?? 1)
    const steps = stepIndex >= 0 && metadata.steps[stepIndex].stepButton ? [ metadata.steps[stepIndex] ] : metadata.steps.filter(s => !s.stepButton)
    const step = stepIndex >= 0 ? metadata.steps[stepIndex] : steps[etape - 1]

    const pathTo = (dossierId: string, etape: number, edit = false) => {
        const editSuffix = edit ? '-edit' : ''
        const step1 = metadata.steps.findIndex(s => !s.options?.step0)+1
        if ((etape <= 1 && !metadata.api.isEdit) || (step1 === 1 && !dossierId))
            return `${ metadata.pathname }${ editSuffix }/${ dossierId ?? '' }`
        if (etape <= metadata.steps.filter(s => !s.stepButton).length)  // Ne pas utiliser steps tout court ici, le stepIndex a changé entre temps
            return `${ metadata.pathname }-etape${ etape }${ editSuffix }/${ dossierId ?? '' }`
        return `${ metadata.pathname }-confirmation/${ dossierId }`
    }

    const doNavigateTo = (dossierId: string, toStep: number, prevEtapeMax?: number, edit = false, id?: number) => {
        const path = pathTo(dossierId, toStep, edit)
        if (dossierId && toStep > steps.length)
            localStorage.removeItem(storageKey(dossierId))
        if (id == null) {
            const state = prevEtapeMax && prevEtapeMax >= toStep ? { back: true } : undefined
            push({ pathname: path, state })
        } else {
            push({ pathname: path, search: `id=${ id }`})
        }
    }

    const navigateTo = (dossierId: string, etape: number, skipGuard: boolean = false) => {
        if (!skipGuard && navigationGuard && navigationGuard(() => doNavigateTo(dossierId, etape)))
            return
        doNavigateTo(dossierId, etape)
    }

    const navigatePrev = (dossierId: string) => {
        navigateTo(dossierId, etape - 1)
    }
    
    const navigateNext = (dossierId: string, saveEtape = true) => {
        const nextEtape = etape + 1
        let prevEtapeMax
        if (dossierId) {
            const key = storageKey(dossierId)
            prevEtapeMax = parseInt(localStorage.getItem(key) ?? '')
            if (saveEtape && dossierId && nextEtape > 1) {
                if (isNaN(prevEtapeMax) || prevEtapeMax < nextEtape)
                    localStorage.setItem(key, nextEtape.toFixed(0))
            }
        }
        doNavigateTo(dossierId, nextEtape, prevEtapeMax)
    }

    const navigateEdit = (dossierId: string, etape: number, id?: number) => {
        doNavigateTo(dossierId, etape, undefined, true, id)
    }

    return {
        etape,
        step,
        steps,
        pathTo,
        navigatePrev,
        navigateNext,
        navigateEdit,
        navigateTo,
    }
}
