import { getFieldState, SetValueOptions, useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasButton, AtlasInfo, MyForm } from 'atlas-ds'
import { BaseFieldProps } from 'components/reform/inputs/utils/BaseFieldProps'
import { useState } from 'react'
import { SearchDebounceOptions } from '../useSearchDebounce'
import AutocompleteBaseField from './AutocompleteBaseField'

interface SelectOrCreateFieldProps<FieldValueType, FormObjectType extends object> extends BaseFieldProps<FieldValueType, FormObjectType> {
    placeholder?: string
    searchValues: (text: string) => Promise<FieldValueType[]>
    getValueId: (value: FieldValueType) => string
    renderValue: (value: FieldValueType) => JSX.Element | string
    searchDebounceOptions?: SearchDebounceOptions
    noCreate?: boolean
    create?: {
        newValue?: (text?: string | null) => FieldValueType
        onCreated?: (text?: string | null) => void
        buttonLabel?: string
        infoTitle?: string
        infoContent?: React.ReactNode
    }
}

export default function SelectOrCreateEntityField<FieldValueType, FormObjectType extends object>(props: SelectOrCreateFieldProps<FieldValueType, FormObjectType>) {

    const form = useFormContext<FormObjectType>()
    const fieldState = getFieldState<FieldValueType>(form, props.name)

    const [searchText, setSearchText] = useState<string | null>(null)
    const [isCreating, setIsCreating] = useState(false)

    const onCreate = () => {
        form.setValue(props.name, props.create?.newValue!(searchText), SetValueOptions.Untouch | SetValueOptions.Validate)
        props.create?.onCreated?.(searchText)
        setIsCreating(true)
        setSearchText(null)
    }

    return (
        <MyForm.Field full>
            {isCreating && props.disabled !== true ?
            <AtlasInfo title={ props.create?.infoTitle ?? "Ajout d'un nouvel élément" }>
                <p>{props.create?.infoContent ?? <>
                    Vous n'avez pas trouvé ce que vous cherchiez ?<br/>
                    Utilisez les champs ci-dessous pour renseigner vos informations.
                </>}</p>
                <AtlasButton icon="search" level={3} onClick={() => {
                    form.setValue(props.name, null, SetValueOptions.Untouch | SetValueOptions.Validate)
                    setIsCreating(false)
                }}>Refaire une recherche</AtlasButton>
            </AtlasInfo> :
            <AutocompleteBaseField<FieldValueType>
                label={ props.label }
                tooltip={ props.tooltip }
                placeholder={ props.placeholder }
                disabled={ props.disabled }
                error={ fieldState.error }
                required={ fieldState.value == null && fieldState.constraints.required }
                onChange={ value => setSearchText(value || null) }
                onBlur={ (value, initialValueOnFocus) => {
                    form.setValue(props.name, value === "" && value !== initialValueOnFocus ? null : form.getValue(props.name), true) 
                    setSearchText(null)
                }}

                search={ props.searchValues }
                searchDebounceOptions={ props.searchDebounceOptions }
                
                optionFor={ result => ({ id: props.getValueId(result) ?? "", content: props.renderValue(result) })}
                onSelect={ value => form.setValue(props.name, value, true) }
                addButton={ props.noCreate ? undefined : {
                    label: props.create?.buttonLabel ?? "Nouveau",
                    onClick: onCreate,
                }}
            />
            }
        </MyForm.Field>
    )
}
