import { AtlasBreadcrumb, AtlasFlex, AtlasHeading, AtlasHtml, AtlasSpacer, MyEvolution, MyEvolutionList, MyForm, direction } from 'atlas-ds'
import Button from 'components/reform/Button'
import { Link } from 'react-router-dom'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import DiagLayout from './DiagLayout'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'

interface MonDiagnosticFacteursInfoProps {
    type: string
    categories: string[]
}

export default function MonDiagnosticFacteursInfo(props: MonDiagnosticFacteursInfoProps) {

    const { diagnostic } = useDiagnostic(null, 'scenarios-type', { detailed: true, categorie: props.type })
    const navigation = useDiagnosticNavigation()

    const facteurs = props.categories
        .map(categorie => {
            const cat = diagnostic?.scenarios?.find(s => s.Scenario.Facteur.Categorie.Code === categorie && s.Scenario.Facteur.Type === props.type)?.Scenario?.Facteur?.Categorie
            return cat ? { 
                categorie: cat.Code, 
                libelle: cat.Libelle, 
                description: cat.Description,
                facteurs: diagnostic?.scenarios?.filter(s => s.Scenario.Facteur.Categorie.Code === categorie && s.Scenario.Facteur.Type === props.type)
            } : null
        })
        .filter(c => !!c)

    return <DiagLayout
        headingTitle={ `Les facteurs externes de mutation` }
        headingInfoBlock={ <div><MyEvolutionList /><AtlasSpacer size="l"/></div> }
        breadcrumb={<AtlasBreadcrumb currentPageLabel="Facteurs externes de mutation"
            linkConstructor={ (props, content) => <Link to={ props.href }>{ content }</Link> }
            parts={[{
                label: "Diagnostic",
                href: "/mon-diagnostic-etape3",
            }]} />
        }>

        <AtlasFlex column gap="s">
            <p><strong>Voici les tendances en cours qui risquent de s'imposer à votre entreprise&nbsp;:</strong></p>
        </AtlasFlex>

        <AtlasSpacer size="l" />

        <AtlasFlex column gap="xl">
            { facteurs.map(f => <AtlasFlex key={ f!.categorie } column gap="m">
                <AtlasHeading tag="h3" size="s">{ f!.libelle }</AtlasHeading>
                <AtlasFlex column gap="m">
                    { f!.facteurs?.map(s => <AtlasFlex key={s.Scenario.Facteur.Id} column gap="s">
                        <MyEvolution
                            key={ s.Scenario.Code }
                            direction={ parseInt(s.Scenario.Categorie)-3 as direction }
                        >{ s.Scenario.Libelle }</MyEvolution>
                        <AtlasHtml>
                            <p>{ s.Scenario.Facteur.Description }</p>

                            { /* props.facteur.LienURL1 && <p>Source: <a href={ props.facteur.LienURL1 } target="_blank" rel="noopener noreferrer">{ props.facteur.LienURL1 }</a></p> }
                            { props.facteur.LienURL2 && <p>Source: <a href={ props.facteur.LienURL2 } target="_blank" rel="noopener noreferrer">{ props.facteur.LienURL2 }</a></p> */ }
                        </AtlasHtml>
                    </AtlasFlex> ) }
                </AtlasFlex>
            </AtlasFlex> ) }
        </AtlasFlex>

        <AtlasSpacer size="l" />

        <MyForm>
            <MyForm.Actions>
                <Button level={2} onClick={ () => navigation.navigateTo(3) }>
                    Retour au diagnostic
                </Button>
            </MyForm.Actions>
        </MyForm>
    </DiagLayout>
}
