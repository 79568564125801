import { AtlasValues, MyContainer } from 'atlas-ds'
import { SECTION_FORMATION, SECTION_FORMATION_FRAIS, SECTION_FORMATION_ORGA } from 'pages/dossier-capp/CAPPFormationMetadata'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { SECTION_CFA, SECTION_MODULE_AGORA } from 'pages/dossier/metadata/ModulesMetadata'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapCAPPFormation(props: RecapProps<DossierCAPP>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)
    
    const metadata = props.metadata
    const modulesEtape = steps.indexOf(props.step) + 1

    const skeleton = props.loading ? [{ cfa: {} }] : []
    const formation = props.dossier?.formation ?? skeleton
    const dossier = props.dossier ?? { formation } as any

    const onModifier = (etape?: number) => navigateTo(props.dossier!.NumeroDossier__c!, etape ?? modulesEtape)

    return <>
        <RecapSection
            title="Centre de formation"
            onModifier={ () => onModifier(modulesEtape-1) }
        >
            <MyContainer>
                <FormElements<DossierCAPP> dossier={ dossier } metadata={ metadata } path="formation" type="recap" section={ SECTION_CFA } />
            </MyContainer>
        </RecapSection>


        <RecapSection
            title={ props.step.title ?? 'Formation' }
            onModifier={ () => onModifier() }
        >
            <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <FormElements section={ SECTION_FORMATION } dossier={ dossier } metadata={ metadata } path="formation" type="recap"/>
                    </AtlasValues.Grid>

                    <AtlasValues.Grid label="Organisation de la formation en CFA">
                        <FormElements section={ SECTION_FORMATION_ORGA } dossier={ dossier } metadata={ props.metadata } path="formation" type="recap" />
                    </AtlasValues.Grid>

                    <AtlasValues.Grid label="Frais annexes supportés par le CFA">
                        <FormElements section={ SECTION_FORMATION_FRAIS } dossier={ dossier } metadata={ props.metadata } path="formation" type="recap" />
                    </AtlasValues.Grid>

                    <AtlasValues.Grid label="Informations supplémentaires AGORA">
                        <FormElements section={ SECTION_MODULE_AGORA } dossier={ dossier } metadata={ props.metadata } path="formation" type="recap" />
                    </AtlasValues.Grid>
                </AtlasValues>
            </MyContainer>
        </RecapSection>
    </>
}