import { BaseTextField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldText } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"

interface TimeFieldProps<T extends object> extends BaseFieldProps<string, T> {
    min?: string
    max?: string
}

const asTimeString = (value: any) => {
    return typeof value === 'string' ? value : undefined
}

export default function TimeField<T extends object>(props: TimeFieldProps<T>) {
    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)

    return <AtlasFieldText
        type="time"
        label={ props.label }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        suffix={ props.suffix }
        name={ props.name }
        disabled={ props.disabled }
        error={ fieldState.error }
        min={ props.min ?? asTimeString(fieldState.constraints.min) }
        max={ props.min ?? asTimeString(fieldState.constraints.max) }
        inputConstructor={ (inputProps) => <BaseTextField
            { ...inputProps }
            render={ render }
            onChange={ props.onChange }
            onBlur={ props.onBlur }
            toModelValue={ (value) => value === '' ? null : value }
        /> }
    />
}
