import { PointReserveCheck } from 'slices/dossierCommon'
import { DossierVAEI } from './VAEIModel'
import { Yop } from '@dsid-opcoatlas/yop'

/*
const nonBlockingValidations: NonBlockingValidationProps<DossierProfessionnalisation>[] = [{
    validate: (values, _) => {
        return (values.maitres ?? []).findIndex(maitre => (maitre?.anneesExperience ?? 1) < 1) === -1
    },
    message: `À défaut de dispositions conventionnelles particulières applicables dans l’entreprise, le tuteur doit
        justifier d’une formation et d’une expérience professionnelle minimales fixées par l’article R. 6223-22 du code du travail.`
}, {
    validate: (values, _) => {
        return (values.maitres ?? []).findIndex(maitre => (
            (maitre?.employeurDirigeant ?? false) && ((maitre?.salariesSuivis ?? 0) > 2)
        )) === -1
    },
    message: `Le tuteur s'il est employeur dirigeant peut encadrer au maximum un apprenti plus un redoublant.`
}, {
    validate: (values, _) => {
        return (values.maitres ?? []).findIndex(maitre => (
            !(maitre?.employeurDirigeant ?? true) && ((maitre?.salariesSuivis ?? 0) > 3)
        )) === -1
    },
    message: `Le tuteur s'il est salarié peut encadrer au maximum deux apprentis plus un redoublant.`
}]
*/

export const dossierVAEIPointsReserve: PointReserveCheck<DossierVAEI>[] = [{
    id: 1001, rubrique: 'TUTEUR', type: 'reco',
    schema: Yop.object<DossierVAEI>({} as any)
        .test<DossierVAEI>(ctx => {
            if (ctx.value?.tuteur?.anneesExperience == null)
                return true

            return ctx.value.tuteur.anneesExperience >= 1
        }, 
        `À défaut de dispositions conventionnelles particulières applicables dans l’entreprise, le tuteur doit
        justifier d’une formation et d’une expérience professionnelle minimales fixées par l’article R. 6223-22 du code du travail.`,
    ).required()
}, {
    id: 1002, rubrique: 'TUTEUR', type: 'reco',
    schema: Yop.object<DossierVAEI>({} as any)
        .test<DossierVAEI>(ctx => {
            if (ctx.value?.tuteur?.employeurDirigeant == null || ctx.value.tuteur?.salariesSuivis == null)
                return true

            return !ctx.value.tuteur.employeurDirigeant || ctx.value.tuteur.salariesSuivis <= 2
        }, 
        `Le tuteur s'il est employeur dirigeant peut encadrer au maximum un apprenti plus un redoublant.`,
    ).required()
}, {
    id: 1003, rubrique: 'TUTEUR', type: 'reco',
    schema: Yop.object<DossierVAEI>({} as any)
        .test<DossierVAEI>(ctx => {
            if (ctx.value?.tuteur?.employeurDirigeant == null || ctx.value.tuteur?.salariesSuivis == null)
                return true

            return ctx.value.tuteur.employeurDirigeant || ctx.value.tuteur.salariesSuivis <= 3
        }, 
        `Le tuteur s'il est salarié peut encadrer au maximum deux apprentis plus un redoublant.`,
    ).required()
}]
