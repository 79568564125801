import { AtlasHtml, AtlasIcon, MyContainer } from 'atlas-ds';
import { format } from "date-fns";

export interface MyAsideInfoProps {
  /**
   * Le titre de l'information
   */
  label: string;
  /**
   * Le contenu
   */
  content: React.ReactNode;
  /**
   * Une icône associée à l'information
   */
  iconName: string;
  /**
   * Une date de mise à jour de l'information
   */
  updatedAt?: Date;
}

/**
 * Bloc d'information générique, annexe à un contenu principal
 */
export function MyAsideInfo(props: MyAsideInfoProps) {
  return (
    <aside className="my-asideInfo">
      <MyContainer>
        <div className="my-asideInfo__inner">
          <AtlasIcon name={props.iconName} size="s" />
          <div className="my-asideInfo__content">
            <div className="my-asideInfo__label">{props.label}</div>
            <AtlasHtml>{props.content}</AtlasHtml>
            {props.updatedAt && (
              <small className="my-asideInfo__updatedAt">
                (dernière mise à jour le {format(props.updatedAt, "dd/MM/yyyy")}
                )
              </small>
            )}
          </div>
        </div>
      </MyContainer>
    </aside>
  );
}
