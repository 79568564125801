import { unwrapResult } from '@reduxjs/toolkit'
import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasInfo, AtlasLoading } from 'atlas-ds'
import { AccueilExtension } from 'pages/accueil/Accueil'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import AccueilDiagAtlas from './AccueilDiagAtlas'
import DiagLayout from './DiagLayout'
import MonDiagnosticActions from './MonDiagnosticActions'
import MonDiagnosticBesoins, { MonDiagnosticModifierBesoins } from './MonDiagnosticBesoins'
import MonDiagnosticDocuments from './MonDiagnosticDocuments'
import MonDiagnosticEntreprise from './MonDiagnosticEntreprise'
import MonDiagnosticFacteurs from './MonDiagnosticFacteurs'
import MonDiagnosticFacteursInfo from './MonDiagnosticFacteursInfo'
import MonDiagnosticMetiers, { MonDiagnosticModifierMetiers } from './MonDiagnosticMetiers'
import MonDiagnosticPlanAction from './MonDiagnosticPlanAction'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


const diagAtlas = () => {
    return {
        id: 'mon-diagnostic',
        isEnabled: () => import.meta.env.REACT_APP_MODULES?.includes('diagatlas'),
        routes: [
            <AppRoute key="diagnostic-home" path="/mon-diagnostic"  render={(props) => <DiagnosticRouter { ...props } />} label="Diagnostic" menuIcon="diagnostic" />,

            <AppRoute key="diagnostic-step1" path="/mon-diagnostic-etape1" render={() => <MonDiagnosticEntreprise />} />,
            <AppRoute key="diagnostic-step2" path="/mon-diagnostic-etape2" render={() => <MonDiagnosticBesoins step={2} />} />,
            <AppRoute key="diagnostic-step3" path="/mon-diagnostic-etape3" render={() => <MonDiagnosticFacteurs type="STRAT" categories={['NUM', 'ENVI', 'SOCI']} step={3} />} />,
            <AppRoute key="diagnostic-step4" path="/mon-diagnostic-etape4" render={() => <MonDiagnosticMetiers step={4} />} />,

            <AppRoute key="diagnostic-facteurs-exo" path="/mon-diagnostic-facteurs" render={() => <MonDiagnosticFacteursInfo type="EXO" categories={['NUM', 'ENVI', 'SOCI', 'REGL', 'ECON']} />} />,

            <AppRoute key="diagnostic-actions" path="/mon-diagnostic-actions" render={() => <MonDiagnosticActions />} />,
            <AppRoute key="diagnostic-plan" path="/mon-diagnostic-plan" render={() => <MonDiagnosticPlanAction />} />,
            <AppRoute key="diagnostic-metiers" path="/mon-diagnostic-metiers" render={() => <MonDiagnosticModifierMetiers />} />,
            <AppRoute key="diagnostic-besoins" path="/mon-diagnostic-besoins" render={() => <MonDiagnosticModifierBesoins />} />,

            <AppRoute key="diagnostic-exports" path="/mon-diagnostic-documents" render={() => <MonDiagnosticDocuments />} />,
        ],
        extensions: {
            accueil: {
                widgets: <AccueilDiagAtlas key="accueilDiagAtlas" />,
            },
        } as AccueilExtension,
    } as AppModule
}

export default diagAtlas

function DiagnosticRouter(props: any) {
    const dispatch = useAppDispatch()
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { loading, error } = useSelector((state: RootState) => state.diagnosticState)
    const nav = useDiagnosticNavigation()

    useEffect(() => {
        if (nav.prevEtape) {
            nav.replaceTo(nav.prevEtape)
            return
        }

        dispatch(diagnosticSliceActions.load({ entrepriseId: entreprise!.Id }))
            .then(unwrapResult)
            .then(diagnostic => {
                if (diagnostic.statut === "Diagnostic réalisé" || diagnostic.statut === "Plan d'action en cours d'exécution") {
                    nav.replaceToPath('actions')
                } else {
                    const etape = diagnostic.etape >= 1 && diagnostic.etape <= 4 ? diagnostic.etape : (!diagnostic.etape ? 0 : 4)
                    nav.replaceTo(etape)
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dispatch, entreprise, nav.prevEtape ])

    return <DiagLayout>
        { loading && <AtlasLoading loading={ loading }>
            <p>Chargement du diagnostic en cours...</p>
        </AtlasLoading> }
        { error && <AtlasInfo type="error" title="Erreur de chargement du diagnostic" >{ error }</AtlasInfo> }
    </DiagLayout>
}
