import { useLocation } from "react-router-dom"

import { sendLienMDPOublie } from "api/monCompteAPI"
import useApiState from "api/useApiState"
import { AtlasButton, AtlasFlex, AtlasInfo } from "atlas-ds"
import PageHeader from "components/PageHeader"
import ErrorBanner from "components/reform/ErrorBanner"
import { EmailState } from "./MamcApp"

export function MotDePasseOublieValidationEmail() {

    const location = useLocation()
    const email = (location.state as EmailState)?.email

    const [{ pending, value, error }, withSendLienMDPOublie] = useApiState(sendLienMDPOublie, { pending: false })

    const renvoyerEmail = () => {
        if (email != null)
            withSendLienMDPOublie(email)
    }

    return <>
        <PageHeader>Mot de passe oublié ?</PageHeader>

        <AtlasFlex column gap="m">
            <AtlasInfo title="Un e-mail vous a été envoyé">
                Vous allez recevoir un mail pour réinitialiser votre mot de passe si vous disposez d'un compte myAtlas.
            </AtlasInfo>

            {email != null && error == null &&
                <AtlasFlex gap="m" alignItems="baseline">
                    <p>Vous n'avez pas reçu de mail ?</p>
                    <AtlasButton type="button"  icon="aide" key="réinitialisation" level={3} disabled={ pending } onClick={ renvoyerEmail }>
                        Renvoyer un mail de réinitialisation
                    </AtlasButton>
                </AtlasFlex>
            }
            {email != null && pending === false && value === true &&
                <AtlasInfo title="E-mail renvoyé">
                    L'e-mail de réinitialisation a été renvoyé à l'adresse { email } si vous disposez d'un compte myAtlas.
                </AtlasInfo>
            }
            {email != null && pending === false && error != null &&
                <ErrorBanner title="Impossible de renvoyer l'e-mail de réinitialisation" message={ error } />
            }
        </AtlasFlex>
    </>
}
