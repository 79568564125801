import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import DetailFacture from 'pages/detail-facture/DetailFacture'
import MesReglements from './MesReglements'

const reglementsModule = (filtreOrganisme: boolean) => {
    return {
        id: 'mes-reglements',
        routes: [
            <AppRoute key="reglements1" path="/mes-reglements" render={() => <MesReglements organisme={filtreOrganisme} />} label="Règlements" menuIcon="reglements" />,
            
            <AppRoute key="reglements2" path="/detail-reglement/:idreglement">
                <DetailFacture />
            </AppRoute>,
        ],
    } as AppModule
}

export default reglementsModule