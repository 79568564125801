import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "app/rootReducer"
import { useAppDispatch } from "app/store"
import { diagnosticSliceActions, DiagnosticState } from './diagnosticSlice'
import { unwrapResult } from '@reduxjs/toolkit'


export const useDiagnostic = (etape: number | null, section?: string | string[], options?: { categorie?: string, statuts?: string | string[], detailed?: boolean }): DiagnosticState => {
    const dispatch = useAppDispatch()
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const diagnosticState = useSelector<RootState, DiagnosticState>(state => state.diagnosticState)
    const [currentSection, setCurrentSection] = useState<string | string[]>('')
    const [currentOptions, setCurrentOptions] = useState<typeof options | null>(null)

    useEffect(() => {
        if ((!diagnosticState.diagnostic || (section && !currentSection) || (options && !currentOptions)) && entreprise) {
            if (diagnosticState.diagnostic && etape != null && etape > diagnosticState.diagnostic.etape) {
                if (diagnosticState.saving) {
                    setCurrentSection(section!)
                    setCurrentOptions(options!)
                } else {
                    dispatch(diagnosticSliceActions.saveEtape({ diagnostic: { ...diagnosticState.diagnostic, etape }, section, categorie: options?.categorie }))
                        .then(unwrapResult)
                        .then(() => {
                            setCurrentSection(section!)
                            setCurrentOptions(options!)
                        })
                }
            } else {
                if (diagnosticState.loading) {
                    setCurrentSection(section!)
                    setCurrentOptions(options!)
                } else if (!diagnosticState.error) {
                    dispatch(diagnosticSliceActions.load({ entrepriseId: entreprise.Id, section, ...options }))
                        .then(unwrapResult)
                        .then(() => {
                            setCurrentSection(section!)
                            setCurrentOptions(options!)
                        })
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entreprise, diagnosticState.diagnostic, diagnosticState.error, diagnosticState.loading, diagnosticState.saving])

    return diagnosticState as DiagnosticState
}
