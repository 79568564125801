import { axiosMamc, axiosMamcPrivate, getRequestConfig } from "./baseAPI"
import { Entreprise } from './referencesAPI';


export type MonCompteDTO = {
    prenom: string
    nom: string,
    password: string,
    email: string,
    telephone: string,
    civilite: string,
    abonnementNewsletter: boolean | null,
    token: string
}

export type FactureMAMCResponse = {
    id: number
    dateEmission: string,
    numeroDossier: string,
    intituleDossier: string,
    referenceFacture: string,
    dateDebutFormation: string
}

export type InseeDetail = {
    success: boolean | null
    AccountDTO: AccountDetail
    message: string
    CodesNAF: CodeNAF[]
}

type AccountDetail = {
    Name: string
    SIREN__c: string
    SIRET__c: string
    CodeNAF__c: string
    Type_Entreprise: string
    BillingCity: string
    BillingPostalCode: string
    BillingStreet: string
    DateDebutActiviteEntreprise__c: Date
}

export type CodeNAF = {
    CODE_NAF: string
    INTITULE_CODE_APE: string
}

type CompteSel = {
    Id: string
    Email: string
    LastName: string
    FirstName: string
}

type AccountDTO = {
    Name: string
    SIREN__c: string
    SIRET__c: string
    CodeNAF__c: string
    CodeAdherent__c: string
    ComptesSEL : CompteSel[]
}


export type EntrepriseDetail = {
    success: boolean | null
    AccountDTO : AccountDTO
   

}



export async function createMonCompte(formData: MonCompteDTO): Promise<boolean> {
    const headers: any = {
        'Content-type': 'application/json'
    }

    await axiosMamc.post<string>('/comptes', formData, { headers })
    return true
}

export async function validerMonCompte(guid: string): Promise<boolean> {
    const formData = new FormData()
    formData.append('guid', guid)
    const { data } = await axiosMamc.post('/comptes/valider', formData)
    return data
}

export async function getLienActivation(email: string): Promise<boolean> {
    const params = new URLSearchParams()
    params.set('email', email)

    await axiosMamc.get('/lien-activation', { params })
    return true
}

export async function sendLienMDPOublie(email: string): Promise<boolean> {
    const params = new URLSearchParams()
    params.set('email', email)

    await axiosMamc.post('/envoyer-lien-mot-de-passe-oublie', undefined, { params })
    return true
}

export async function validerMDPOublie(token: string, password: string): Promise<boolean> {
    await axiosMamc.post('/valider-mot-de-passe-oublie', { param: token, password })
    return true
}

export async function postAttacherEntreprise(formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axiosMamcPrivate.post<any>(`/attacher-entreprise`, formData, requestConfig)
    return data
}

export async function postAttacherOF(jsonFactureOF: string): Promise<any> {
    const requestConfig = await getRequestConfig();
    requestConfig.headers['Content-type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('factureOF', jsonFactureOF);

    const { data } = await axiosMamcPrivate.post<any>(`/attacher-of`, formData, requestConfig)
    return data
}

export async function postDettacherEntreprise(type: string, siren: string): Promise<any> {
    const requestConfig = await getRequestConfig()

    const formData = new FormData()
    formData.append('type', type)
    formData.append('siren', siren)

    const { data } = await axiosMamcPrivate.post<any>(`/detacher-entreprise`, formData, requestConfig)
    return data
}

export async function recupererFactureAleatoire(idEntreprise: string, exclude?: number): Promise<{
    data: FactureMAMCResponse,
    hasMore: boolean
}> {
    const requestConfig = await getRequestConfig()

    if (exclude) {
        requestConfig.params = { exclude };
    }

    const response = await axiosMamcPrivate.get('/recuperer-facture-of/' + idEntreprise, requestConfig)

    return {
        data: response.data,
        hasMore: response.headers['x-total-count'] > 1
    }
}

export async function verifierEntreprise(siren: string): Promise<InseeDetail> {
    const requestConfig = await getRequestConfig()
    const formData = new FormData()
    formData.append('siren', siren)
    const { data } = await axiosMamcPrivate.post<InseeDetail>(`/verifier-entreprise`, formData, requestConfig)
    return data
}

type ContactDTO = {
    firstname: string
    lastname: string,
    email: string,
    phone: string,
    civilite: string
}

type WebAdhesionDTO = {
    raisonSocial: string,
    siren: string,
    siret: string,
    codeNaf: string,
    codeIdcc: string,
    type: string,
    typeEntreprise: string,
    ville: string,
    codePostal: string,
    branche: string,
    adresse: string,
    contact: ContactDTO,
    insee: boolean,
    dateDebutActivite: Date
}

// Fonction de conversion
export function convertirEnWebAdhesionDTO(webadhesion: any): WebAdhesionDTO {
    const { raisonSocial, siren, siret, codeNaf, codeIdcc, type, typeEntreprise, ville, codePostal, branche, adresse, contact, insee, dateDebutActivite } = webadhesion;
    const { firstname, lastname, email, phone, civilite } = contact;

    return {
        raisonSocial,
        siren,
        siret,
        codeNaf,
        codeIdcc,
        type,
        typeEntreprise,
        ville,
        codePostal,
        branche,
        adresse,
        contact: {
            firstname,
            lastname,
            email,
            phone,
            civilite
        },
        insee,
        dateDebutActivite
    };
}

export async function postWebAdhesion(jsonWebadhesion: string, file?: File, fileName?: string): Promise<any> {
    const requestConfig = await getRequestConfig();
    requestConfig.headers['Content-type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('webAdhesionDTO', jsonWebadhesion);

    if (file && fileName) {
        const name = (fileName ?? (file as any).name ?? fileName).replaceAll(/[\\/|:*?"<>]/g, '_');
        formData.append('name', name);
        formData.append('file', file, name);
    }

    const { data } = await axiosMamcPrivate.post<any>(`/souscrire-webadhesion`, formData, requestConfig);
    return data;
}

export async function patchContact(formData: string): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axiosMamcPrivate.patch<any>(`/modifier-user`, formData, requestConfig);
    return data;
}

export async function modifierMotDePasse(formData: string): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axiosMamcPrivate.post<any>(`/modifier-mot-de-passe`, formData, requestConfig);
    return data;
}


export async function postAcceptCgu(): Promise<string> {
    const requestConfig = await getRequestConfig()

    const { data } = await axiosMamcPrivate.post('/accepter-cgu', {}, requestConfig)

    return data;
}

export async function getDetailEntreprise(idEntreprise: string, type: string) : Promise <EntrepriseDetail>{

    

    const requestConfig = await getRequestConfig()
     requestConfig.headers['atlas-context'] = type

    const { data } = await axiosMamcPrivate.get<EntrepriseDetail>('/comptes/' + idEntreprise, requestConfig)
    return data;
}
