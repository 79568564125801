import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasFieldCheckboxDropdown } from 'atlas-ds'

export interface DropdownCheckbox {
    label: string
    value: string
}

interface DropdownCheckboxesProps {
    label: string
    name: string
    checkboxes: DropdownCheckbox[]
}

export default function DropdownCheckboxes(props: DropdownCheckboxesProps) {
    const context = useFormContext()
    const fieldState = getFieldState<string[]>(context, props.name)

    const checkboxes = [{
        label: "Tous",
        value: "tous",
        checked: fieldState.value?.length === 0
    }].concat((props.checkboxes ?? [])
        .map(checkbox => ({...checkbox, checked: fieldState.value?.includes(checkbox.value) }))
        .sort((a, b) => {
            return (a.label ?? '').localeCompare(b.label ?? '')
        }
    ))

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const value = event.target.value;

        if (value === "tous") {
            context.setValue(props.name, [], true)
        } else {
            if (!checked)
                context.setValue(props.name, (fieldState.value ?? []).filter(id => id !== value), true)
            else {
                let values = fieldState.value ?? []
                if (!values.includes(value)) {
                    values.push(value)
                }
                if (values.length === (checkboxes.length - 1))
                    values = []
                context.setValue(props.name, values, true)
            }
        }
    }

    return <AtlasFieldCheckboxDropdown
        name={ props.name }
        label={ props.label }
        options={ checkboxes }
        onChange={ onChange }
        separateFirstOption
    />
}
