import { DossierDetail, getDossierPourOF } from 'api/dossierAPI'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE } from 'api/references'
import { useAppState } from 'app/useAppState'
import { AtlasInfo } from 'atlas-ds'
import DetailDossierContrat from 'pages/detail-dossier/DetailDossierContrat'
import DossierDeposerFactureSelfe from 'pages/detail-dossier/DossierDeposerFactureSelfe'


export default function DetailDossierPrestataire() {
    const { entreprise } = useAppState()
    
    return <DetailDossierContrat
        getDossier={(id: string) => getDossierPourOF(id, entreprise?.Id ?? '')}
        depotPieces={(dossier: DossierDetail) => dossier.DispositifFO__c === DISPOSITIF_CONTRAT_APPRENTISSAGE ?
            <AtlasInfo title="Ajout de documents non disponible" type="warning">
                Seule l'entreprise peut ajouter un document.
            </AtlasInfo>
            : undefined}
        actions={ (dossier: DossierDetail, actions: JSX.Element[]) => {
            if (actions.length === 0) {
                // Ajout de facture par défaut pour les types de dossiers non gérés
                actions.push(<DossierDeposerFactureSelfe key="deposerFacture" detail={dossier} mode="facture" />)
            }
        }}
    />
}