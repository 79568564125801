import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { MesInformations } from './MonCompte'
import { MotDePasse } from './MotDePasse'

const monCompteModule = {
    id: 'mon-compte',
    routes: [
        <AppRoute exact key="mon-compte" path="/mon-compte" label="Mon compte" menuIcon="user">
            <MesInformations />
        </AppRoute>,
        <AppRoute exact key="mon-compte-mot-de-passe" path="/mon-compte/mot-de-passe" label="Modifier mon mot de passe">
            <MotDePasse />
        </AppRoute>,
    ],
} as AppModule

export default monCompteModule