import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { AtlasBreadcrumb, BreadcrumbPart } from "atlas-ds";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { historyStackActions } from "slices/historySlice";

interface BreadcrumbProps {
    parts?: BreadcrumbPart[]
    currentPageLabel?: string
}

export default function Breadcrumb(props: BreadcrumbProps) {

    const dispatch = useAppDispatch()

    const { history } = useSelector((rootState: RootState) => rootState.historyStackState)

    const updateHistory = (index: number) => {
        dispatch(historyStackActions.go(index))
    }

    if (props.parts && props.currentPageLabel) {
        return <AtlasBreadcrumb
            parts={ props.parts }
            currentPageLabel={ props.currentPageLabel }
            linkConstructor={ (props: any, content: React.ReactNode) =>
                <Link to={ props.href }>{ content }</Link>
            }
        ></AtlasBreadcrumb>
    }

    return <AtlasBreadcrumb
        parts={ history?.slice(0, history.length-1).map((h: any, index) => ({
            href: h.pathname + h.search,
            label: h.title
        }))}
        currentPageLabel={ !history ? '' : (history[history.length-1]?.title ?? '') }
        linkConstructor={ (props: any, content: React.ReactNode, index?: number) =>
            <Link to={ props.href } onClick={ () => updateHistory(index!) }>{ content }</Link>
        }
    ></AtlasBreadcrumb>
}
