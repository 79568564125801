import { MyForm } from 'atlas-ds'
import { SECTION_FORMATION, SECTION_FORMATION_FRAIS, SECTION_FORMATION_ORGA } from 'pages/dossier-capp/CAPPFormationMetadata'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA } from 'pages/dossier/metadata/ModulesMetadata'
import { BaseDossier } from 'slices/dossierCommon'

export function DossierFormationElements<D extends BaseDossier>(props: { metadata: FormMetadata<D> }) {
    return (
        <>
            <MyForm.Grid>
                <FormElements section={ SECTION_FORMATION } metadata={ props.metadata } path="formation" type="input" />
            </MyForm.Grid>

            <MyForm.Fieldset legend="Organisation de la formation en CFA">
                <MyForm.Grid>
                    <FormElements section={ SECTION_FORMATION_ORGA } metadata={ props.metadata } path="formation" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>

            <MyForm.Fieldset
                legend="Frais annexes supportés par le CFA"
                tooltipContent={TOOLTIPS.capp.formation.fraisAnnexes}
            >
                <MyForm.Grid>
                    <FormElements section={ SECTION_FORMATION_FRAIS } metadata={ props.metadata } path="formation" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>

            <MyForm.Fieldset legend="Informations supplémentaires AGORA">
                <MyForm.Grid>
                    <FormElements section={ SECTION_MODULE_AGORA } metadata={ props.metadata } path="formation" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
        </>
    )
}

