import useApiState from 'api/useApiState'
import useEventListener, { AppEvent } from 'app/events'
import ExtensionPoint from 'app/ExtensionPoint'
import { useAppDispatch } from 'app/store'
import { AtlasColumns, AtlasFlex, AtlasInfo, AtlasLoading, AtlasSpacer, AtlasToastsItemType, MyPageHeaderAnchorLink } from 'atlas-ds'
import Breadcrumb from 'components/Breadcrumb'
import PageHeader from "components/PageHeader"
import Toolbox from 'components/Toolbox'
import { Location } from 'history'
import { ReactElement, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { historyStackActions } from 'slices/historySlice'

export interface DetailActionResult {
    title: string
    status: AtlasToastsItemType
    message: string
}

export interface DetailProps<D> {
    name: string
    title: (detail: D | null) => string
    loadErrorMessage: string
    paramName: string
    getId: (detail: D | null) => string | null
    getDetail: (id: string) => Promise<D>
    history: (location: Location<any>, detail: D | null) => any
    nav?: MyPageHeaderAnchorLink[]
    sections: (detail: D | null, pending: boolean) => JSX.Element[]
    peutAjouterPieces?: boolean
    actions?: DetailActionElement<D>[] | ((detail: D, actions: DetailActionElement<D>[]) => void)
    onShow?: (detail: D, show: string) => void
}


export interface DetailActionProps<D> {
    detail?: D
    when?: (detail: D) => boolean
}

export function checkDetailAction<D>(props: DetailActionProps<D>): D | undefined | null {
    return props.detail && (!props.when || props.when(props.detail)) ? props.detail : null
}

export type DetailActionElement<D> = ReactElement<DetailActionProps<D>>

export type DetailExtension<D> = {
    [name: string]: {
        alertes?: JSX.Element
        actions?: DetailActionElement<D>[] | ((detail: D, actions: DetailActionElement<D>[]) => void)
    }
}


export default function Detail<D>(props: DetailProps<D>) {
    const params = useParams<any>()
    const idDetail = params[props.paramName]

    const location = useLocation()
    const dispatch = useAppDispatch()

    const [{ pending, value: detail, error }, withGetDetail] = useApiState(props.getDetail)
    useEffect(() => {
        if (location.hash !== '#documents') {
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
        }

        withGetDetail(idDetail)
    }, [idDetail, withGetDetail, location.hash]);

    useEffect(() => {
        if (props.onShow && detail && (location.state as any)?.show) {
            props.onShow(detail, (location.state as any)?.show)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, detail])

    const detailRefreshEvent = props.name + ':Refresh'

    useEventListener(detailRefreshEvent, (_: AppEvent<any>) => {
        withGetDetail(idDetail)
    })

    useEffect(() => {
        if ((props.history.length === 1) || !pending)
            dispatch(historyStackActions.push(props.history(location, detail)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch, detail])

    return (
        <AtlasLoading loading={pending}>
            <PageHeader sticky breadcrumb={ <Breadcrumb />} anchorLinks={props.nav}>
                { props.title(detail) }
            </PageHeader>

            <AtlasColumns>
                <AtlasFlex column gap="xl">
                    { error && <AtlasInfo type="error" title={props.loadErrorMessage}>
                        { error }
                    </AtlasInfo> }
                    { props.sections(detail, pending) }
                </AtlasFlex>

                <div>
                    { detail && <ExtensionPoint<DetailExtension<D>> name={props.name} point="alertes" detail={ detail }>
                        { children => <>
                            { children }
                            <AtlasSpacer size="xl" />
                        </>}
                    </ExtensionPoint> }

                    { detail && <ExtensionPoint<DetailExtension<D>> name={props.name} point="actions" 
                        detail={ detail } extensions={ props.actions }>
                        { children => <Toolbox heading="Actions" items={ children } /> }
                    </ExtensionPoint> }
                </div>
            </AtlasColumns>
        </AtlasLoading>
    )
}
