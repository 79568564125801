
import { Parametre } from 'api/parametresAPI'
import useEventListener, { dispatchAppEvent } from 'app/events'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasLinkProps, AtlasToasts, AtlasToastsItemProps, AtlasToastsItemType } from 'atlas-ds'
import Link from 'components/Link'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { parametresActions } from 'slices/parametresSlice'

const showTransientToastEvent = 'BandeauAlert:ShowTransientToast'

// On garde les transientToasts (toasts passagers) dans une classe séparée pour
// éviter qu'ils ne disparaissent quand la page change.
// Note : les toasts partent tout seul au bout de 5s, mais ce délai est
// ré-initialisé quand la page change (donc le toast reste plus longtemps).
// C'est un effet secondaire indésirable, mais peu gênant, a priori...
class ToastManager {
    public transientToasts: React.ReactElement<AtlasToastsItemProps>[] = []

    showToast(toast: {
        label: string;
        content: string;
        type?: AtlasToastsItemType;
        link?: React.ReactElement<AtlasLinkProps>;
        icon?: string;
    }) {
        const newToast = <AtlasToasts.Item
            key={`toast-${Date.now()}`}
            { ...toast }
            label={ toast.label }
            children={ toast.content }
            type={ toast.type }
            link={ toast.link }
            icon={ toast.icon }
            onClose={() => {
                this.transientToasts = this.transientToasts
                    .filter(toast => toast.key !== newToast.key)
                dispatchAppEvent(showTransientToastEvent)
            }}
            closeAfterDelay={5000}
        />

        this.transientToasts.push(newToast);
        dispatchAppEvent(showTransientToastEvent)
    }
}

export const ToastService = new ToastManager();

function bandeauKey(id: string) {
    return `id-bandeau-${id}`
}

export default function BandeauAlert() {
    const [, refresh] = useState({})
    const dispatch = useAppDispatch()

    const { bandeaux } = useSelector((state: RootState) => state.parametresState)
    useEffect(() => {
        if (!bandeaux) {
            dispatch(parametresActions.loadBandeauxInformations())
        }
    }, [bandeaux, dispatch])

    const closeBandeau = (id: string) => {
        localStorage.setItem(bandeauKey(id), "true");
        refresh({})
    }

    const isBandeauUnseen = (bandeau: Parametre) =>
        !localStorage.getItem(bandeauKey(bandeau.id))

    useEventListener(showTransientToastEvent, () => refresh({}))

    return (
        <AtlasToasts>
            { (bandeaux ?? []).filter(isBandeauUnseen).map(bandeau =>
                <AtlasToasts.Item
                    key={ bandeauKey(bandeau.id) }
                    large
                    type={ bandeau.type }
                    onClose={ () => closeBandeau(bandeau.id) }
                    label={ bandeau.titre }
                    children={ bandeau.contenu }
                    link={ bandeau.lien ? <Link href={ bandeau.lien }>{ bandeau.labelLien }</Link> : undefined }
                />
            ).concat(ToastService.transientToasts)}
        </AtlasToasts>
    )
}
