import { postDettacherEntreprise } from "api/monCompteAPI"
import { TYPE_ORGANISME } from "api/references"
import { Entreprise } from "api/referencesAPI"
import { useAppDispatch } from "app/store"
import { AtlasButton, MyModal } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import log from "loglevel"
import { useState } from "react"
import { profilActions } from "slices/profilSlice"


interface DetailDetacherEntrepriseProps {
    entreprise: Entreprise | null
    type: string

}

export default function DetailDetacherEntreprise(props: DetailDetacherEntrepriseProps) {
    const dispatch = useAppDispatch();
    const [showDettacherEntreprise, setShowDettacherEntreprise] = useState(false)
    const [pending, setPending] = useState(false)

    const confirmDetacher = async () => {
        setPending(true)
        postDettacherEntreprise(props.type, props.entreprise?.SIREN__c ?? '').then(_ => {
            dispatch(profilActions.getServices())
            ToastService.showToast({
                type: "success",
                label: "Détachement effectué",
                content: `${props.type === TYPE_ORGANISME ? "Le prestataire" : "L'entreprise"} ${props.entreprise?.Name} a été détaché${props.type === TYPE_ORGANISME ? "" : "e"} de votre compte`
            })
        }).catch(error => {
            log.error('Erreur détachement Entreprise ' + props.entreprise?.Name, error)
            setShowDettacherEntreprise(false)
            ToastService.showToast({
                type: "error",
                label: "Détachement impossible",
                content: error?.response?.data?.message ?? "Le détachement n'a pas pu être effectué."
            })
        }).finally(() => setPending(false))
    }

    return <>
        <AtlasButton type="button" key="detacher" icon="details" level={3} onClick={() => setShowDettacherEntreprise(true)}>Détacher</AtlasButton>
        { showDettacherEntreprise && <MyModal
            label={props.type === TYPE_ORGANISME ? "Détachement d'un prestataire" : "Détachement d'une entreprise"}
            isPending={pending}
            okButton={<AtlasButton destructive onClick= {confirmDetacher}>Confirmer le détachement</AtlasButton>}
            cancelButton={<AtlasButton onClick= {() => setShowDettacherEntreprise(false)}>Annuler</AtlasButton>}
        >
            <p>Confirmez-vous le détachement de votre {props.type === TYPE_ORGANISME ? "prestataire" : "entreprise"} <strong>{props.entreprise?.Name}</strong> ?</p>
            <p>Vous ne pourrez plus gérer vos dossiers sur myAtlas.</p>
        </MyModal> }
    </>
}