import { AtlasIcon } from "atlas-ds";

export type direction = -2 | -1 | 0 | 1 | 2;

export const EVOLUTIONS: {
  icon: string;
  label: string;
  id: string;
  direction: direction;
}[] = [
  {
    icon: "evolution-up-double",
    label: "Forte hausse",
    id: "forte-hausse",
    direction: 2,
  },
  {
    icon: "evolution-up",
    label: "Hausse",
    id: "hausse",
    direction: 1,
  },
  {
    icon: "evolution-stable",
    label: "Stable",
    id: "stable",
    direction: 0,
  },
  {
    icon: "evolution-down",
    label: "Baisse",
    id: "baisse",
    direction: -1,
  },
  {
    icon: "evolution-down-double",
    label: "Forte baisse",
    id: "forte-baisse",
    direction: -2,
  },
];

export interface MyEvolutionListProps {}

/**
 * La liste des types d'évolutions
 */
export function MyEvolutionList(props: MyEvolutionListProps) {
  return (
    <ul className="my-evolutionList">
      {EVOLUTIONS.map((evolution) => (
        <li key={evolution.id}>
          <AtlasIcon size="s" name={evolution.icon} />
          <div className="my-evolutionList__label">{evolution.label}</div>
        </li>
      ))}
    </ul>
  );
}
