import { DocumentsSearchQuery, downloadDoc, getDocuments, PieceDTO } from 'api/documentAPI'
import { MyTableColumn } from 'atlas-ds'
import TelechargerDoc from 'components/docs/TelechargerDoc'
import { formatApiDate } from 'components/format/Format'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { Filtre } from 'components/recherche/Filtres'
import RechercheBase, { singleTab } from 'components/recherche/RechercheBase'

export class DocumentsSearchQueryImpl extends BaseSearchQueryImpl implements DocumentsSearchQuery {

    constructor(search: URLSearchParams, tri?: string) {
        super(search, tri ?? 'dateAjout')
        this.applySearch(search)
    }

    clone() {
        return new DocumentsSearchQueryImpl(this.toSearchParams())
    }
}

export const displayDocumentDate = (document: PieceDTO) => {
    let date: (string | null) = document.dateAjout
    if (!date)
        return ''
    return formatApiDate(date);
}

export default function MesDocuments() {
    
    const filtres: Filtre[] = []

    const colonnes = (_: DocumentsSearchQuery): MyTableColumn[] => [
        {
            id: "type",
            label: "Type document",
            sortable: true
        },
        {
            id: "fichier",
            label: "Nom document",
            sortable: true,
            wrap: true
        },
        {
            id: "dateAjout",
            label: "Date d'ajout",
            sortable: true,
            value: (row: any) => displayDocumentDate(row),
        }
    ]

    const actions = (document: PieceDTO) => {
        const actions = []
        actions.push(<TelechargerDoc downloadDoc={() => downloadDoc(document)} key="dl" />)
        return actions
    }

    const newQuery = (params: URLSearchParams) => new DocumentsSearchQueryImpl(params)

    return (
        <RechercheBase<PieceDTO, DocumentsSearchQueryImpl>
            name="MesDocuments"
            title="Documents"
            newQuery={ newQuery }
            filtres={ filtres }
            colonnes={ colonnes }
            tabs={ singleTab(getDocuments) }
            tableActions={ actions }
        />
    )
}
