import { AppDossierModule } from 'pages/detail-dossier/modules'
import { MetadataACN as Metadata } from 'pages/dossier-acn/ACNFormMetadata'
import { DossierDetail, loadDossierAF } from 'api/dossierAPI'
import { getCampusSession, getStage } from 'api/stageAPI'
import { normalizeAPIDate } from 'components/format/Format'
import { actionsEntrepriseRelanceCloture, actionsEntrepriseRelanceDemanderSubrogation } from 'pages/detail-dossier/relanceff/entreprise'
import { actionsPrestataireRelanceAnnulation, actionsPrestataireRelanceCloture, actionsPrestataireRelanceModifierDates } from 'pages/detail-dossier/relanceff/prestataire'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import { actionsAnnulerDossier, actionsEntrepriseChangerPeriode, actionsEntrepriseDeposerFacture } from 'pages/detail-dossier/modules/entreprise'
import { actionsPrestataireAccordeDeposerFacture } from 'pages/detail-dossier/modules/prestataire'


const getDateFinValidite = async (dossier: DossierDetail) => {
    const dossierACN = await loadDossierAF(dossier?.NumeroDossier__c)
    if (dossierACN?.Modules && dossierACN?.Modules.length && dossierACN.Modules[0].Reference__c) {
        const ref = dossierACN.Modules[0].Reference__c
        if (!ref || ref.startsWith('Stage-')) {
            const campusSession = await getCampusSession(dossierACN.CampusSession__c ?? '')
            return (campusSession?.Stage?.DateFinValidite__c) ? normalizeAPIDate(campusSession?.Stage?.DateFinValidite__c) : ""
        } else {
            const stage = await getStage(dossierACN.Modules[0].Reference__c)
            return (stage?.DateFinValidite) ? normalizeAPIDate(stage?.DateFinValidite) : ""
        }
    }
    return ""
}

const dossierACNModule: AppDossierModule = {
    name: 'ACN',
    dispositifId: Metadata.dispositif,
    metadata: {
        entreprise: { create: Metadata }
    },
    extensions: {
        entreprise: {
            DetailDossierContrat: {
                actions: (dossier: DossierDetail, actions: JSX.Element[]) => {
                    actionsEntrepriseChangerPeriode(dossier, actions, getDateFinValidite)
                    actionsAnnulerDossier(dossier, actions)
                    
                    actionsEntrepriseDeposerFacture(dossier, actions)
        
                    actionsEntrepriseRelanceCloture(dossier, actions)
                    actionsEntrepriseRelanceDemanderSubrogation(dossier, actions)
                }
            },
        } as DetailDossierExtension,
        prestataire: {
            DetailDossierContrat: {
                actions: (dossier: DossierDetail, actions: JSX.Element[]) => {
                    actionsPrestataireAccordeDeposerFacture(dossier, actions)   
                    
                    actionsPrestataireRelanceCloture(dossier, actions)
                    actionsPrestataireRelanceAnnulation(dossier, actions)
                    actionsPrestataireRelanceModifierDates(dossier, actions)
                },
            },
        } as DetailDossierExtension,
    },
}

export default dossierACNModule