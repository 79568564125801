import { RootState } from 'app/rootReducer'
import { AtlasInfo } from 'atlas-ds'
import CheckboxField from 'components/reform/inputs/CheckboxField'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useSelector } from 'react-redux'


export function DossierCAPPMAPMandat(props: { metadata: FormMetadata<DossierCAPP> }) {
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    if (!entreprise?.CertificationCA__c) {
        return <AtlasInfo type="warning" title="Mandat contrat d'apprentissage">
            Votre organisme ne peut transmettre de contrat d’apprentissage car il ne dispose pas, à date, de certification contrat d’apprentissage Qualiopi.<br/><br/>
            En cas d’erreur merci de nous contacter.
        </AtlasInfo>
    }

    return (<>
        <p>
        Afin de compléter les services en ligne déjà proposés aux entreprises adhérentes à l'OPCO Atlas pour la transmission de leurs contrats d'apprentissage, <strong>cet espace est ouvert aux CFA ayant été expressément mandatés par l'entreprise signataire d'un contrat d'apprentissage</strong> pour transmettre les éléments du CERFA à l'OPCO Atlas. Le CFA porte donc la responsabilité de l'exactitude des données saisies et de leur correspondance au CERFA signé entre l'entreprise et l'apprenti.
        </p>
        <p>
        <strong>Avant de commencer la saisie</strong>, vous devez vous assurer de disposer de la convention de formation au format PDF à joindre obligatoirement pour finaliser la transmission et que celle-ci mentionne explicitement le mandat donné par l'entreprise, suivant le modèle mis à disposition par la DGEFP dans <a href="https://travail-emploi.gouv.fr/IMG/pdf/precis-apprentissage.pdf">Précis de l'Apprentissage</a> - annexe2.
        </p>
        <p>
        A l'issue de cette saisie un mail de notification est adressé au CFA et à l'entreprise signataire du contrat pour acter la transmission des informations.
        </p>
        <p>
        Les données saisies via cet espace sont ensuite intégrées dans le SI de l'OPCO Atlas et traitées dans le cadre de l'instruction des contrats. En cas de besoin l'OPCO Atlas contactera directement l'entreprise pour les éventuels compléments ou ajustements nécessaires.
        </p>
        <p>
        L'entreprise et le CFA seront informés simultanément de la décision prise suite à l'instruction et du dépôt du contrat.
        </p>

        <CheckboxField name="attestationMandat"
            label="J'atteste sur l'honneur avoir été mandaté par l'entreprise signataire du CERFA et m'engage" />
        <ul>
            <li>à saisir les données exactes contenues dans ce CERFA original</li>
            <li>à tenir à disposition de l'OPCO la version originale de ce CERFA signée par l'ensemble des parties</li>
        </ul>
    </>)
}
