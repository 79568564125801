import { useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasButton, MyForm } from 'atlas-ds'
import CheckboxField from 'components/reform/inputs/CheckboxField'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useEffect, useState } from 'react'
import MaitreFields from '../../../components/fields/MaitreFields'


export function DossierMaitresElements<D extends DossierCAPP>(props: { metadata: FormMetadata<D> }) {

    const context = useFormContext<D>()
    const [maitre2, setMaitre2] = useState(context.values?.maitre2 != null)

    const addMaitre = () => {
        context.setValue('maitre2', props.metadata.api.createTuteur!())
        setMaitre2(true)
    }

    const removeMaitre = () => {
        context.setValue('maitre2', null)
        setMaitre2(false)
    }

    useEffect(() => {
        if (context.values?.maitre2 != null && maitre2 !== true)
            setMaitre2(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.values?.maitre2])

    return (<>
        <MyForm.Fieldset
            legend="Maître d'apprentissage N°1"
            tooltipContent={ TOOLTIPS.capp.maitre.changement }
        >
            <MaitreFields metadata={ props.metadata } name="maitre1" />
        </MyForm.Fieldset>

        { maitre2
        ?
        <MyForm.Fieldset
            legend="Maître d'apprentissage N°2"
            tooltipContent={ TOOLTIPS.capp.maitre.changement }
            button={<AtlasButton icon='trash' onClick={removeMaitre}>Retirer</AtlasButton>}
        >
            <MaitreFields metadata={ props.metadata } name="maitre2" />
        </MyForm.Fieldset>
        :
            <AtlasButton icon="plus" onClick={ addMaitre } level={ 2 }>Ajouter un maître d'apprentissage</AtlasButton>
        }

        <CheckboxField name="eligibilite"
            label="L'employeur atteste sur l'honneur que le maître d'apprentissage répond à l'ensemble des critères d'éligibilité à cette fonction"
            tooltip={ TOOLTIPS.capp.maitre.eligibilite } />
    </>)
}

