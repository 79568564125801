import { AtlasSpacer } from "atlas-ds"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import { CguContent } from "pages/cgu/Cgu"

export function Cgu() {
    return <>
        <PageHeader
            actions={<Link level={2} to="/creer-mon-compte">Créer mon compte</Link>}
        >Conditions générales d'utilisation</PageHeader>

        <CguContent />

        <AtlasSpacer size="xl" />

        <Link to="/creer-mon-compte">Créer mon compte</Link>
     </>
}
