import {
  AtlasIcon,
  AtlasInfoProps,
  AtlasLinkConstructor,
  AtlasLinkProps,
  MyNumber,
  defaultLinkConstructor,
} from "atlas-ds";

export interface MyFeaturedDataProps {
  /**
   * Le label du bloc de données
   */
  label: string;
  /**
   * L'icône associé à ces données
   */
  icon: string;
  datas: {
    /**
     * Label de la donnée
     */
    label: string;
    /**
     * Valeur
     */
    value: string | number;
    /**
     * Lien vers les données détaillées
     */
    href: string;
  }[];
  /**
   * Un lien global vers les données détaillées
   */
  link: React.ReactElement<AtlasLinkProps>;
  /**
   * Si un simple lien ne suffit pas, le constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
  /**
   * Une information associée au bloc
   */
  info?: React.ReactElement<AtlasInfoProps>;
}

/**
 * Une mise en avant de données
 */
export function MyFeaturedData(props: MyFeaturedDataProps) {
  const link = {
    ...props.link,
    props: {
      level: 3,
      icon: "arrow-right",
      ...props.link.props,
    },
  };

  const linkConstructor = props.linkConstructor ?? defaultLinkConstructor;

  return (
    <article className="my-featuredData">
      <div className="my-featuredData__title">
        <AtlasIcon name={props.icon} />
        {props.label}
      </div>

      {props.info}

      <div className="my-featuredData__inner">
        {props.datas.map((data) =>
          linkConstructor(
            {
              key: data.label,
              href: data.href,
              className: "my-featuredData__data",
            },
            <>
              <p className="my-featuredData__stat">{data.label}</p>
              <div className="my-featuredData__number">
                <MyNumber>{data.value}</MyNumber>
              </div>
            </>
          )
        )}

        {link}
      </div>
    </article>
  );
}
