export const styles = {
  options: {
    primaryColor: 'var(--atlas-color-red)',
    textColor: 'var(--atlas-color-text-main)',
  },
  buttonNext: {
    backgroundColor: 'var(--atlas-color-accent-darker)',
  },
  buttonBack: {
    color: 'var(--atlas-color-accent-darkest)',
  }
};
