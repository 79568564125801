
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import { OptionalFile } from 'components/yop/constraints'
import FileField from 'components/reform/inputs/FileField'

export function FileFieldMetadata(settings: SettingsFieldMetadata<File | null> = {}) {
    return createMetadata<File | null>(
        {
            yop: OptionalFile,
            input: FileField,
        },
        settings,
    )
}
