import { BaseTextField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldText } from "atlas-ds"
import { formatSuffix } from 'components/format/Format'
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { useMemo } from "react"

export interface NumberFieldProps<T extends object> extends BaseFieldProps<number, T> {
    fractionDigits?: number
    minimumFractionDigits?: number
    formatDisplayedValue?: boolean
}

export default function NumberField<T extends object>(props: NumberFieldProps<T>) {
    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<number>(context, props.name)

    const [numberRegex, numberFormatter] = useMemo(() => {
        return [
            (props.fractionDigits ?? 0) > 0 ? new RegExp(`^[\\d\\s]*([,.]\\d{0,${ props.fractionDigits }})?$`) : /^[\d\s]*$/,
            new Intl.NumberFormat("fr-FR", {
                minimumFractionDigits: props.minimumFractionDigits ?? props.fractionDigits,
                maximumFractionDigits: props.fractionDigits
            })
        ]
    }, [props.fractionDigits, props.minimumFractionDigits])

    const getModelValue = (value: string) => {
        const inputValue = parseFloat(value.replaceAll(/\s/g, '').replace(',', '.'))
        return isNaN(inputValue) ? null : inputValue
    }

    return <AtlasFieldText
        name={ props.name }
        disabled={ props.disabled }
        inputMode="decimal"
        label={ props.label }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        suffix={ typeof props.suffix === 'string' ? formatSuffix(props.suffix, fieldState.value >= 2) : props.suffix }
        error={ fieldState.error }

        inputConstructor={(inputProps) => <BaseTextField
            {...inputProps}
            onChange={props.onChange}
            onBlur={props.onBlur}
            render={render}
            acceptInputValue={(value) => numberRegex.test(value)}
            formatDisplayedValue={
                props.formatDisplayedValue !== false ?
                ((value) => value != '' ? numberFormatter.format(parseFloat(value)).replace(/\s+/g, '\xa0') : '') :
                undefined
            }
            formatOnEdit={false}
            toModelValue={getModelValue}
        />}
    />
}
