import { AtlasHeading, MyContainer, MySteps } from 'atlas-ds'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'

interface DiagAtlasStepsProps {
    step: number
    stepMax: number
    navigationGuard?: (etape: number, next: () => void) => boolean
}

export default function DiagAtlasSteps(props: DiagAtlasStepsProps) {

    const navigation = useDiagnosticNavigation(props.navigationGuard)
    
    const toEtape = (etape: number) => {
        if (etape !== props.step)
            navigation.navigateTo(etape)
    }

    return <div>
        <AtlasHeading tag="h3" size="m">Suivi des étapes</AtlasHeading>
        <MyContainer>
            <MySteps direction='column'>
                <MySteps.Item active={props.step === 1} onClick={() => toEtape(1)}>
                    Taille et secteur
                </MySteps.Item>
                <MySteps.Item disabled={props.stepMax < 2} active={props.step === 2} onClick={() => toEtape(2)}>
                    Besoins de l'entreprise
                </MySteps.Item>
                <MySteps.Item disabled={props.stepMax < 3} active={props.step === 3} onClick={() => toEtape(3)}>
                    Facteurs de mutation
                </MySteps.Item>
                <MySteps.Item disabled={props.stepMax < 4} active={props.step === 4} onClick={() => toEtape(4)}>
                    Métiers et compétences
                </MySteps.Item>
                <MySteps.Item disabled={true}>
                    Plan d'action
                </MySteps.Item>
            </MySteps>
        </MyContainer>
    </div>
}
