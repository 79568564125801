import { AtlasButton, AtlasInfo } from 'atlas-ds'
import { DossierPointReserve } from 'slices/dossierCommon'

interface PointsReserveProps {
    pointsReserve: DossierPointReserve[] | false | null
    rubrique?: string
    filtreRubrique?: boolean
    dirty?: boolean | null
    spinning?: boolean
    disabled?: boolean
    onClick?: () => void 
}

export function PointsReserve(props: PointsReserveProps) {
    if (props.pointsReserve === false) {
        return <AtlasInfo type="success" title="Points de réserve résolus">
            Tous les points de réserve ont été résolus.
        </AtlasInfo>
    }
    const pointsReserve = props.pointsReserve?.filter(r => !props.filtreRubrique || r.rubrique === props.rubrique || (Array.isArray(r.rubrique) && r.rubrique.includes(props.rubrique ?? ''))) ?? null
    if (!pointsReserve || pointsReserve.length === 0) {
        return null
    }
    const reserves = pointsReserve.filter(r => r.type === 'reserve')
    const recommandations = pointsReserve.filter(r => r.type === 'reco')
    const pointsReserveRubrique = props.rubrique && props.pointsReserve!.find(r => r.rubrique === props.rubrique || (Array.isArray(r.rubrique) && r.rubrique.includes(props.rubrique ?? '')))

    return <AtlasInfo title="Informations à vérifier">
        <p>Nous vous conseillons de corriger les points suivants avant de soumettre votre dossier&nbsp;:</p>
        { reserves && <ul>
            {/* <li>Le champ XXX doit correspondre à YYY</li> */}
            { reserves?.map((reserve: DossierPointReserve) =>
            <li key={ `${reserve.id}` }><strong>Point de réserve</strong> : { reserve.label }<br/>
            { reserve.message && <span dangerouslySetInnerHTML={ { __html: reserve.message ?? '' } }></span> }
            </li>
            )}
        </ul> }
        { recommandations && <ul>
            {/* <li>Le champ XXX doit correspondre à YYY</li> */}
            { recommandations?.map((reco: DossierPointReserve) =>
            <li key={ `${reco.id}` }><strong>Recommandation</strong> : { reco.label }<br/>
            { reco.message && <span dangerouslySetInnerHTML={ { __html: reco.message ?? '' } }></span> }
            </li>
            )}
        </ul> }
        { props.dirty && pointsReserveRubrique &&
            <AtlasButton
                level={ 2 }
                spinner={{spinning: !!props.spinning}}
                onClick={ props.onClick }
                disabled={ props.disabled }
            >Vérifier à nouveau</AtlasButton>
        }
        { reserves.length > 0 &&
            <details>
                <summary>Plus d'informations sur les points de réserve</summary>
                <p><small>Atlas vous accompagne sur la conclusion du contrat : en complément des points qui conditionnent le dépôt auprès des services de l'État en charge de la formation professionnelle, un point de réserve est un élément identifié comme non conforme à une disposition légale ou conventionnelle en vigueur ou encore incohérent. Ce ou ces élément(s) peu(vent) avoir un impact sur le montant pris en charge par Atlas, le versement d'une aide ou représente un risque en cas de contrôle ou de contentieux. Nous vous invitons à revoir les données renseignées.</small></p>
            </details>
        }
    </AtlasInfo>
}