import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'
import { dateDebutCycleFormation, dateFinCycleFormation, dureeHeureEvaluation } from 'pages/dossier-cpro/CPROFormMetadata'
import { CPROModulePrincipalMetadata } from 'pages/dossier-cpro/CPROFormationMetadata'
import { DossierCPRO, DossierCPROModule } from 'pages/dossier-cpro/CPROModel'
import { CPROSalarieMetadata } from 'pages/dossier-cpro/CPROSalarieMetadata'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import CerfaNumberField from 'pages/dossier/cerfa/CerfaNumberField'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'

export default function Formation({ dossier }: { dossier: DossierCPRO }) {
  const module: DossierCPROModule | null | undefined = dossier.modulePrincipal

  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeaderV4}>LA FORMATION</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>Dénomination de l'organisme de formation principal :</Text>
            <Text style={styles.user}>{ module?.cfa?.nom ?? ' '}</Text>
            <View style={styles.bordered}>
              <Text>S'agit-il d'un service de formation interne :</Text>
              <Text>
                <CerfaElement metadata={ CPROModulePrincipalMetadata.formationInterne } value={ module?.formationInterne }/>
              </Text>
              <Text> </Text>
              <Text style={[styles.small, styles.bold, styles.italic]}>
              <Checkbox checked={ module?.attestationFormationInterne }></Checkbox>{' '}
                L'employeur atteste que le service de formation interne dispose
                des moyens nécessaires à la réalisation de la formation du
                salarié.
              </Text>
            </View>
          </View>
          <View style={styles.colRight}>
            <Text>N° SIRET de l'organisme de formation principal :</Text>
            <Text>
              <CerfaTextField value={ module?.cfa?.siret ?? ' ' } />
            </Text>
            <Text>N° de déclaration d'activité de cet organisme :</Text>
            <Text>
              <CerfaTextField value={ module?.cfa?.numeroDA ?? ' ' } />
            </Text>
            <Text>
              Nombre d'organismes de formation intervenant :{' '}
              <Text style={styles.user}>{ module?.cfa != null ? 1 : 0 }</Text>
            </Text>
          </View>
        </View>
        <View style={styles.cols}>
          <Text style={styles.tripleCol}>
            Type qualification visée :{' '}
            <CerfaElement metadata={ CPROModulePrincipalMetadata.typeQualification } numberOnly value={ module?.typeQualification }/>
          </Text>
          <View style={styles.tripleCol}>
            <Text>
              Diplôme ou titre visé :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.diplomeVise } numberOnly value={ dossier.salarie?.diplomeVise }/>
            </Text>
          </View>
          <View style={styles.tripleColLast}>
            <Text>
              Code RNCP :{' '}
              <CerfaTextField value={ module?.codeRNCP?.startsWith('RNCP') ? module?.codeRNCP?.substring(4) : module?.codeRNCP }/>
            </Text>
          </View>
        </View>
        <Text>
          Intitulé précis :{' '}
          <CerfaElement metadata={ CPROModulePrincipalMetadata.intitulePrecis } value={ module?.intitulePrecis }/>
        </Text>
        <Text>
          Spécialité de formation :{' '}
          <CerfaElement metadata={ CPROModulePrincipalMetadata.specialiteFormation } value={ module?.specialiteFormation }/>
        </Text>
        <Text style={styles.bold}>Organisation de la formation :</Text>
        <Text>
          Durée totale des actions d'évaluation, d'accompagnement et des enseignements :{' '}
          <CerfaNumberField value={ dureeHeureEvaluation(dossier) } suffix='heure(s)' />
        </Text>
        <Text style={styles.bold}>
          Dont durée des enseignements généraux, professionnells et technologiques :{' '}
          <CerfaElement metadata={ CPROModulePrincipalMetadata.dureeEnseignement } value={ module?.dureeEnseignement }/>
        </Text>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>Date de début du cycle de formation</Text>
            <CerfaTextField value={ dateDebutCycleFormation(dossier) } type='date' />
          </View>
          <View style={styles.colRight}>
            <Text>Date prévue de fin des épreuves ou examens :</Text>
            <CerfaTextField value={ dateFinCycleFormation(dossier) } type='date' />
          </View>
        </View>
        <View style={styles.signatureSection}>
          <Text style={[styles.bold, styles.xSmall, styles.compact]}>
            En application de l’article L.6325-3 du code du travail, l’employeur
            s’engage à assurer au titulaire du contrat une formation lui
            permettant d’acquérir une qualification professionnelle et à lui
            fournir un emploi en relation avec cet objectif pendant la durée du
            CDD ou de l’action de professionnalisation du CDI. Le titulaire du
            contrat s’engage à travailler pour le compte de son employeur et à
            suivre la formation prévue au contrat.
          </Text>
          <Text> </Text>
          <Text>
            Fait à :{' '}
            <CerfaTextField value={dossier.signature?.lieu}></CerfaTextField>
          </Text>
          <View style={styles.cols}>
            <View style={styles.colLeft}>
              <Text style={[styles.boldItalic, styles.center]}>
                Signature de l'employeur
              </Text>
            </View>
            <View style={styles.colRight}>
              <Text style={[styles.boldItalic, styles.center]}>
                Signature du salarié
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
