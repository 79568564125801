import { UseFormReturn } from '@dsid-opcoatlas/reform'
import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import SelectField from './SelectField'


export interface SelectRefFieldProps<T extends object, V> extends BaseFieldProps<V, T> {
    options: (refs: References, parent?: any, form?: UseFormReturn<any>) => Reference[] | undefined
    firstOptions?: string[]
    lastOptions?: string[]
    libelle?: boolean
    sorted?: boolean
    code?: boolean
    sortedCode?: boolean
    successMessage?: ReactNode
    infoMessage?: ReactNode
    warningMessage?: ReactNode
    emptyOptionLabel?: string
    isMAMC?: boolean
}

export default function SelectRefField<T extends object, V = string>(props: SelectRefFieldProps<T, V>) {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    let options = refs ? (props.options(refs, props.parent, props.root)?.map(opt => {
        return {
            value: opt.Id,
            code: opt.CodeType__c,
            label: (props.code ? opt.CodeType__c + ' - ' : '') + (props.libelle ? opt.Libelle__c : (props.isMAMC ? opt.Id : opt.LibelleReduit__c) ?? opt.Libelle__c)
        }
    }) ?? []) : []
    
    if (options && props.code) {
        options = [...options].sort((a, b) => {
            return (a.code ?? '').localeCompare(b.code ?? '')
        })
    }
    else if (options && props.sortedCode) {
        options = [...options].sort((a, b) => {
            return parseInt(a.code) < parseInt(b.code) ? -1 : 1
        })
    }
    else if (options && props.sorted) {
        options = [...options].sort((a, b) => {
            if (props.firstOptions) {
                if (props.firstOptions.indexOf(a.value) >= 0) {
                    if (props.firstOptions.indexOf(b.value) >= 0) {
                        return props.firstOptions.indexOf(a.value) < props.firstOptions.indexOf(b.value) ? -1 : 1
                    } else {
                        return -1
                    }
                } else if (props.firstOptions.indexOf(b.value) >= 0) {
                    return 1
                }
            }
            if (props.lastOptions) {
                if (props.lastOptions.indexOf(a.value) >= 0) {
                    if (props.lastOptions.indexOf(b.value) >= 0) {
                        return props.lastOptions.indexOf(a.value) > props.lastOptions.indexOf(b.value) ? 1 : -1
                    } else {
                        return 1
                    }
                } else if (props.lastOptions.indexOf(b.value) >= 0) {
                    return -1
                }
            }
            if (a.label.match(/^[0-9]+\s-.*/) && b.label.match(/^[0-9]+\s-.*/)) {
                return parseInt(a.label) < parseInt(b.label) ? -1 : 1
            }
            return (a.label ?? '').localeCompare(b.label ?? '')
        })
    }

    return (
        <SelectField name={ props.name }
            selectionner
            label={ props.label ?? '' }
            tooltip={ props.tooltip }
            successMessage={ props.successMessage }
            infoMessage={ props.infoMessage }
            warningMessage={ props.warningMessage }
            emptyOptionLabel={ props.emptyOptionLabel }
            disabled={ props.disabled }
            options={ options }
            parentPath={ props.parentPath }
            onErrorLink={ props.onErrorLink }
            onChange={ props.onChange } />
    )
}
