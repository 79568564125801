import { Etablissement } from "api/commonAPI"
import { Entreprise } from "api/referencesAPI"
import { AtlasValue, AtlasValues } from "atlas-ds"
import { formatAdresse } from "./format/Format"

type company = Entreprise | Etablissement;

interface CompanyProps {
    company: company;
    showSiret?: boolean;
    labelCode?: string;
}

export function Company(props: CompanyProps) {
    const formatCode = (code: string): string => {
        if (code && typeof code === "string")
            return code.split(".")[0];
        return "";
    };

    return (
        <AtlasValues label={props.company.Name}>
            <AtlasValues.Grid>
                <AtlasValue label={!props.showSiret ? "SIREN" : "SIRET"}>
                    {!props.showSiret && "SIREN__c" in props.company
                        ? props.company.SIREN__c
                        : props.company.SIRET__c
                    }
                </AtlasValue>
                {props.labelCode ? <AtlasValue label={props.labelCode}>
                    {formatCode(props.company.CodeAdherent__c)}
                </AtlasValue> : <></>}
            </AtlasValues.Grid>
            <AtlasValue label="Adresse">
                { formatAdresse(
                    props.company.BillingStreet,
                    props.company.BillingPostalCode,
                    props.company.BillingCity
                ) }
            </AtlasValue>
        </AtlasValues>
    );
}
