import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierProAContrat, ProAContratMetadata } from 'pages/dossier-proa/ProAFormMetadata'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'

export default function EmploiVise({ contrat }: { contrat: DossierProAContrat }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>
        L'EMPLOI VISÉ PAR LA PRO-A
      </Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>La Pro-A vise :</Text>
            <CerfaElement metadata={ ProAContratMetadata.typeProA } value={ contrat.typeProA } />
            <Text> </Text>

            <Text>
              La Pro-A comporte-t-elle des actions permettant de valider les
              acquis de l'expérience (VAE) ?
            </Text>
            <Text>
              <CerfaElement metadata={ ProAContratMetadata.vaeProA } value={ contrat.vaeProA } />
            </Text>

            <Text>La Pro-A est effectuée sur le temps de travail :</Text>
            <Text>
              <CerfaElement metadata={ ProAContratMetadata.tempsTravailProA } value={ contrat.tempsTravailProA } />
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>Emploi visé par la Pro-A (intitulé précis) :</Text>
            <CerfaElement metadata={ ProAContratMetadata.emploiViseProA } value={ contrat.emploiViseProA } />

            <Text style={styles.bold}>
              Classification de l'emploi dans la convention collective :
            </Text>
            <CerfaElement metadata={ ProAContratMetadata.classificationEmploiProA } value={ contrat.classificationEmploiProA } />

            <Text>Niveau de l'emploi :{' '}
              <CerfaElement metadata={ ProAContratMetadata.niveauEmploiProA } value={ contrat.niveauEmploiProA } />
            </Text>

            <Text>Coefficient hiérarchique :{' '}
              <CerfaElement metadata={ ProAContratMetadata.coefficientHierarchiqueProA } value={ contrat.coefficientHierarchiqueProA } />
            </Text>
          </View>
        </View>

        <Text> </Text>

        <View style={styles.cols}>
          <View style={styles.tripleCol}>
            <Text>
              Date de début d'exécution de la Pro-A :{' '}
              <CerfaElement metadata={ ProAContratMetadata.dateDebutProA } value={ contrat.dateDebutProA } />
            </Text>
          </View>
          <View style={styles.tripleCol}>
            <Text>Durée hebdomadaire du travail :</Text>
            <View>
              <CerfaElement metadata={ ProAContratMetadata.dureeHebdoProA } value={ contrat.dureeHebdoProA } />
            </View>
          </View>
          <View style={styles.tripleColLast}>
            <Text>
              Date de fin d'exécution de la Pro-A :{' '}
              <CerfaElement metadata={ ProAContratMetadata.dateFinProA } value={ contrat.dateFinProA } />
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
