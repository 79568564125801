import { BaseTextField, getFieldState, useFormContext, useRender } from '@dsid-opcoatlas/reform'
import { AtlasFieldText, AtlasFlex, AtlasInfo } from 'atlas-ds'
import { BaseFieldProps } from './utils/BaseFieldProps'

interface IBANFieldProps<T extends object> extends BaseFieldProps<string, T> {
    successMessage?: string
    warningMessage?: string
}

const ibanRegexp = /^[a-zA-Z0-9 ]*$/
const formatIban = (value: string | null) => {
    value = value?.toUpperCase().replace(/[^0-9A-Z]/g, '') ?? null
    if (!value)
        return ''
    const iban = value.match(/([0-9A-Z]{1,4})/g)
    return (iban ?? []).join(' ')
}

export default function IBANField<T extends object>(props: IBANFieldProps<T>) {

    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)

    const getInputValue = (value: string) => {
        if (value)
            return value.toUpperCase().replace(/[^0-9A-Z]/g, '')
        return null
    }

    return <AtlasFlex column gap="s">
        <AtlasFieldText
            label={ props.label }
            required={ fieldState.constraints.required }
            tooltipContent={ props.tooltip }
            suffix={ props.suffix }
            error={ fieldState.error }
            name={ props.name }
            disabled={ props.disabled }
            maxLength={ 34 + 8 } // Un IBAN peut faire jusqu'à 34 caractères
            autoComplete='off'
            inputConstructor={ (inputProps) => <BaseTextField
                {...inputProps}
                onChange={ props.onChange }
                onBlur={ props.onBlur }
                render={ render }
                toModelValue={ getInputValue }
                formatDisplayedValue={ formatIban }
                acceptInputValue={ (value) => ibanRegexp.test(value) }
            /> }
        />

        { props.successMessage && <AtlasInfo mini title="Succès">{ props.successMessage }</AtlasInfo> }

        { props.warningMessage && <AtlasInfo mini type="warning" title="Attention">{ props.warningMessage }</AtlasInfo> }
    </AtlasFlex>
}

