import { AtlasFlex, MyDiagnosticLogo } from "atlas-ds";
import { PageHeaderProps } from "components/PageHeader";

interface DiagPageHeaderProps {
  children: React.ReactElement<PageHeaderProps>;
}

export default function DiagPageHeader(props: DiagPageHeaderProps) {
  return <AtlasFlex justifyContent="space-between" alignItems="center" gap="xxl">
    <AtlasFlex.Grow>
        {props.children}
    </AtlasFlex.Grow>

    <AtlasFlex gap="s" alignItems="center">
        <p>avec</p>
        <MyDiagnosticLogo maxWidth='10rem'/>
    </AtlasFlex>
    </AtlasFlex>
}