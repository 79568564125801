import { AtlasButton, AtlasFlex } from 'atlas-ds'
import { useHistory } from 'react-router-dom'
import { Entreprise } from '../../api/referencesAPI'
import { MentionStrategiePlus } from './MentionBloc'
import { CompteStrategiePlus } from './StrategiePlus'
import { StrategiePlusSolde } from './StrategiePlusSolde'


export interface StrategiePlusMembreProps {
    detailEntreprise: Entreprise | null,
    compte: CompteStrategiePlus
}

export function StrategiePlusMembre(props: StrategiePlusMembreProps) {

    const history = useHistory()

    const goToVV = () => history.push('/versement-volontaire')

    return (
        <AtlasFlex column gap="xxl">
            <StrategiePlusSolde detailEntreprise={props.detailEntreprise} />

            <div>
                <AtlasButton level={ 1 } onClick={ goToVV }>
                    Alimenter mon compte Stratégie +
                </AtlasButton>
            </div>

            <MentionStrategiePlus />
        </AtlasFlex>
    )
}
