import { InputSelection } from '@dsid-opcoatlas/reform'
import { AtlasFieldText, AtlasOptions, AtlasOptionsGroup, AtlasOptionsItem, AtlasSearchDropdown, AtlasSearchDropdownAddButton } from 'atlas-ds'
import useSearchDebounce, { SearchDebounceOptions } from "components/reform/useSearchDebounce"
import { useId, useRef } from 'react'

export type AutocompleteFieldOption = {
    id: string,
    content: React.ReactNode,
    group?: string
}

interface AutocompleteBaseFieldProps<SearchType> {
    
    // Search input props
    label: string | JSX.Element
    defaultValue?: string
    error?: string
    tooltip?: string | JSX.Element
    placeholder?: string
    disabled?: boolean
    required?: boolean
    maxLength?: number
    acceptInput?: (value: string) => boolean
    onChange?: (text: string, previousText: string) => void
    onBlur?: (text: string, initialTextOnFocus: string) => void

    // Search function props
    search: (text: string) => Promise<SearchType[]>
    normalizeSearchString?: (text: string) => string
    searchDebounceOptions?: SearchDebounceOptions

    // Dropdown field props
    optionFor: (searchResult: SearchType) => AutocompleteFieldOption
    addButton?: AtlasSearchDropdownAddButton
    onSelect?: (value: SearchType) => void
    hideNoResultsMessage?: boolean
    noResultsMessage?: string
}

const convertToAtlasOptions = (autocompleteOptions: (AutocompleteFieldOption & { onClick: () => void })[]): AtlasOptionsItem[] | AtlasOptionsGroup[] => {
    if (autocompleteOptions.length === 0)
        return []
    
    if (autocompleteOptions[0].group == null)
        return autocompleteOptions
    
    return autocompleteOptions.reduce((result: AtlasOptionsGroup[], item) => {
        const { group, ...option } = item
        if (result.length === 0 || result[result.length - 1].label !== group)
            result.push({ id: `group-${ result.length }`, label: item.group ?? "", options: [] })
        result[result.length - 1].options.push(option)
        return result
    }, [])
}

export default function AutocompleteBaseField<SearchType>(props: AutocompleteBaseFieldProps<SearchType>) {

    const inputName = useId()
    const valueOnFocusRef = useRef<string>("")
    const inputRef = useRef<HTMLInputElement>(null)
    const previousInputValue = useRef("")
    const previousInputSelection = useRef<InputSelection>({ start: null, end: null })

    const [search, searching, values, setValues, error, options] = useSearchDebounce(props.search, props.searchDebounceOptions)

    const internalOnSelect = (event: React.FormEvent<HTMLInputElement>) => {
        const { selectionStart: start, selectionEnd: end, selectionDirection: direction } = event.currentTarget
        previousInputSelection.current = { start, end, direction: direction ?? undefined }
    }

    const internalOnInput = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.currentTarget
        if (props.acceptInput?.(target.value) === false) {
            target.value = previousInputValue.current
            const { start, end, direction }  = previousInputSelection.current!
            target.setSelectionRange(start, end, direction)
        }
    }
    
    const internalOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== previousInputValue.current) {
            setValues(null)
            const value = event.currentTarget.value
            props.onChange?.(value, previousInputValue.current)
            previousInputValue.current = value
            search(props.normalizeSearchString != null ? props.normalizeSearchString(value) : value)
        }
    }
        
    const internalOnFocus = (_: React.FocusEvent<HTMLInputElement>) => {
        valueOnFocusRef.current = inputRef.current!.value
        if (searching === false && values == null && options.immediate) {
            const value = inputRef.current!.value
            search(props.normalizeSearchString != null ? props.normalizeSearchString(value) : value)
        }
    }

    const internalOnBlur = (_: React.FocusEvent<HTMLInputElement>) => {
        setValues(null)
        props.onBlur?.(inputRef.current?.value ?? "", valueOnFocusRef.current)
    }

    const internalOnDropdownSelect = (value: SearchType) => {
        setValues(null)
        props.onSelect?.(value)
    }

    const addButton = props.addButton ? {
        label: props.addButton.label,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            props.addButton!.onClick(event)
            setValues(null)
        }
    } : undefined

    const autocompleteOptions = (values ?? []).map(value => ({ ...props.optionFor!(value), onClick: () => internalOnDropdownSelect(value) }) )
    const atlasOptions = convertToAtlasOptions(autocompleteOptions)

    // If this is the first render or if this input isn't currently edited
    const extraTextProps: { defaultValue?: string } = {}
    if (inputRef.current == null || inputRef.current !== document.activeElement) {
        const defaultValue = props.defaultValue ?? ""
        if (inputRef.current)
            inputRef.current.value = defaultValue
        else
            extraTextProps.defaultValue = defaultValue
        previousInputValue.current = defaultValue
    }

    const hideNoResultsMessage = (
        props.hideNoResultsMessage === true ||
        inputRef.current == null ||
        (inputRef.current.value.length === 0 && options.empty === false) ||
        inputRef.current.value.length < options.minChars ||
        values == null ||
        searching
    )

    return (
        <AtlasSearchDropdown 
            loading={ searching }
            resultsCount={ values?.length }
            hideNoResultsMessage={ hideNoResultsMessage }
            noResultsMessage={ props.noResultsMessage }
            error={ error || undefined }
            addButton={ addButton }
            onBlur={ internalOnBlur }
            input={
                <AtlasFieldText
                    { ...extraTextProps }
                    ref={ inputRef }
                    name={ inputName }
                    label={ props.label }
                    required={ props.required }
                    error={ error ?? props.error }
                    tooltipContent={ props.tooltip }
                    autoComplete="off"
                    placeholder={ props.placeholder }
                    maxLength={ props.maxLength }
                    onChange={ internalOnChange }
                    onInput={ internalOnInput }
                    onSelect={ internalOnSelect }
                    onFocus={ internalOnFocus}
                    disabled={ props.disabled }
                />
            }>
            
            <AtlasOptions options={ atlasOptions } />
        </AtlasSearchDropdown>
    )
}
