import { useEffect, useState } from 'react';

import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform';
import { AtlasFieldSelect } from 'atlas-ds';
import React from 'react';

export interface SelectFieldOption {
    label: string
    value: string
}

interface FiltreSelectFieldProps {
    name: string
    label: string
    options?: SelectFieldOption[] | (() => Promise<SelectFieldOption[]>)
}

export default function FiltreSelectField(props: FiltreSelectFieldProps) {

    const context = useFormContext()
    const fieldState = getFieldState<string>(context, props.name)
    
    const [options, setOptions] = useState([] as SelectFieldOption[])

    useEffect(() => {
        if (typeof props.options === 'function') {
            props.options().then(opts => setOptions(opts))
        } else {
            setOptions(props.options ?? [])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options])
    
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value
        context.setValue(props.name, value, true)
    }

    return <AtlasFieldSelect
        name={ props.name }
        label={ props.label }
        defaultValue={ fieldState.value }
        onChange={ onChange }
        options={ options }
    />
}
