import { Config, PeriodeSMIC } from './referencesAPI'
import store from 'app/store'


export function config(): Config | null {
    return store.getState().configState.fulfilled
}

export function findDispositifIds(id: string): string[] | undefined {
    return config()?.Menu.find(item => item.Id === id ? item : null)?.DispositifFOIds
}

export function findAlternanceDispositifIds(): string[] | undefined {
    return findDispositifIds('alternance')
}

export function getPeriodeSMIC(cfg?: Config): PeriodeSMIC[] | undefined {
    return (cfg ?? config())?.SMIC
}
