import { unwrapResult } from '@reduxjs/toolkit'
import { DiagDiagnosticBesoinDTO } from 'api/diagatlasAPI'
import { useAppDispatch } from 'app/store'
import { AtlasBreadcrumb, AtlasCheckbox, AtlasFlex, AtlasHeading, AtlasSpacer, MyForm } from 'atlas-ds'
import Button from 'components/reform/Button'
import { Link } from 'react-router-dom'
import { Diagnostic, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import DiagLayout from './DiagLayout'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


interface MonDiagnosticBesoinsProps {
    step: number
}

const RUBRIQUES = [
    { type: 'TBE1001', libelle: 'Attractivité' },
    { type: 'TBE1002', libelle: 'Efficacité RH' },
    { type: 'TBE1003', libelle: 'Développement des compétences' },
    { type: 'TBE1004', libelle: 'Anticipation des mutations' },
]

export default function MonDiagnosticBesoins(props: MonDiagnosticBesoinsProps) {

    const { diagnostic, saving } = useDiagnostic(props.step, 'besoins')
    const navigation = useDiagnosticNavigation()

    return <DiagLayout step={props.step} stepMax={diagnostic?.etape ?? 1}
        headingTitle="Les besoins de l'entreprise"
        headingInfoBlock={ <AtlasFlex column gap="s">
            <p>Quels sont actuellement les besoins RH de votre entreprise ?</p>
            <p><strong>Cochez les sujets stratégiques pour votre entreprise.</strong></p>
            <p><i>Vos choix permettront d’établir des recommandations d’actions adaptées à vos besoins.</i></p>
        </AtlasFlex> }>
        
        <EditBesoins diagnostic={ diagnostic } />

        <AtlasSpacer size="xl" />

        <MyForm>
            <MyForm.Actions>
                <Button level={2} disabled={ saving } onClick={ () => navigation.navigatePrev() }>
                    Etape précédente
                </Button>
                <Button disabled={ saving } spinner={{ spinning: saving }} onClick={ () => navigation.navigateNext() }>
                    Passer à l'étape suivante
                </Button>
            </MyForm.Actions>
        </MyForm>
    </DiagLayout>
}

export function MonDiagnosticModifierBesoins() {

    const dispatch = useAppDispatch()
    const { diagnostic, saving } = useDiagnostic(null, 'besoins')
    const navigation = useDiagnosticNavigation()

    const validerDiagnostic = () => {
        dispatch(diagnosticSliceActions.validerPlan(diagnostic as any))
            .then(unwrapResult)
            .then(() => {
                navigation.navigateToPath('actions')
            })
    }

    return <DiagLayout 
        headingTitle="Les besoins de l'entreprise"
        headingInfo="Sur cette page, vous pouvez définir les besoins de votre entreprise pour obtenir des recommandations adaptées."
        breadcrumb={<AtlasBreadcrumb currentPageLabel="Besoins de l'entreprise"
            linkConstructor={ (props, content) => <Link to={ props.href }>{ content }</Link> }
            parts={[{
                label: "Actions préconisées",
                href: "/mon-diagnostic-actions",
            }]} />
        }
    >

        <EditBesoins diagnostic={ diagnostic } />

        <AtlasSpacer size="xl" />

        <MyForm>
            <MyForm.Actions>
                <Button disabled={ saving } spinner={{ spinning: saving }} onClick={ validerDiagnostic }>
                    Valider les modifications
                </Button>
            </MyForm.Actions>
        </MyForm>
    </DiagLayout>
}

function EditBesoins(props: { diagnostic: Diagnostic | null }) {
    const dispatch = useAppDispatch()

    const onChange = (besoin: DiagDiagnosticBesoinDTO, choix: boolean) => {
        dispatch(diagnosticSliceActions.updateBesoin({ besoin: besoin.Besoin.Id, choix }))
    }

    const besoinsParRubrique = RUBRIQUES.map(r => {
        const besoins = props.diagnostic?.besoins?.filter(b => b.Besoin.Type === r.type)
        return {
            type: r.type,
            libelle: r.libelle,
            besoins,
        }
    })

    return <AtlasFlex column gap="l">
        { besoinsParRubrique?.filter(r => !!r.besoins)?.map(r => <div key={ r.type }>
            <AtlasHeading tag="h3" size="s">{ r.libelle }</AtlasHeading>

            <AtlasFlex column gap="m">{ r.besoins?.map(b => <AtlasCheckbox
                key={ b.Besoin.Code }
                value={ b.Besoin.Code }
                name={ b.Besoin.Code }
                label={ b.Besoin.Libelle }
                checked={ !!b.Choix }
                onChange={ event => onChange(b, event.currentTarget.checked) }
            />)
            }</AtlasFlex>
        </div>) }
    </AtlasFlex>
}
