import { createSlice } from '@reduxjs/toolkit'

interface MaintenanceState {
  value: boolean
}

const initialState = { value: false } as MaintenanceState

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    maintenanceOn(state) {
      state.value = true
    },
    maintenanceOff(state) {
      state.value = false
    }
  },
})

export const { maintenanceOn, maintenanceOff } = maintenanceSlice.actions
export default maintenanceSlice.reducer