import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkboxes from 'pages/dossier/cerfa/components/Checkboxes'
import { CAPPRepresentantMetadata } from 'pages/dossier-capp/CAPPContratMetadata'
import { DossierCAPPStagiaire } from 'pages/dossier-capp/CAPPModel'
import { CAPPStagiaireMetadata } from 'pages/dossier-capp/CAPPStagiaireMetadata'
import CerfaAdresseField from 'pages/dossier/cerfa/CerfaAdresseField'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import { DossierStagiaireRepresentant } from 'slices/dossierCommon'


export default function Apprenti({ stagiaire, representant }: { stagiaire: DossierCAPPStagiaire | null, representant: DossierStagiaireRepresentant | null }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>L'APPRENTI(E)</Text>
      <View style={styles.sectionContent}>
        <Text style={styles.bold}>
          Nom de naissance de l'apprenti(e) :{' '}
          <CerfaElement metadata={ CAPPStagiaireMetadata.nomNaissance } value={ stagiaire?.nomNaissance } /> 
        </Text>
        <Text style={styles.bold}>
          Nom d'usage :{' '}
          <CerfaElement metadata={ CAPPStagiaireMetadata.nom } value={ stagiaire?.nom } /> 
        </Text>
        <Text>
          <Text style={styles.bold}>Le premier prénom de l'apprenti(e)</Text> selon l'état civil :{' '}
          <CerfaElement metadata={ CAPPStagiaireMetadata.prenom } value={ stagiaire?.prenom } /> 
        </Text>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>
              NIR de l'apprenti(e) :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.nir } value={ stagiaire?.nir } /> 
            </Text>
            <Text style={styles.bold}>Adresse de l'apprenti(e) :</Text>
            <CerfaAdresseField value={ stagiaire?.adresse } />
            <Text>
              Téléphone :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.telephone } value={ stagiaire?.telephone } />
            </Text>
            <Text>
              Courriel :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.courriel } value={ stagiaire?.courriel } default="________________@________________" />
            </Text>
            <Text>
              <Text style={styles.bold}>Représentant légal</Text>{' '}
              <Text style={[styles.xSmall, styles.italic]}>
                (si l'apprenti est mineur non émancipé)
              </Text>
            </Text>
            <Text>
              Nom de naissance et prénom :{' '}
              <CerfaElement metadata={ CAPPRepresentantMetadata.nom } value={ representant?.nom } />
            </Text>
            <Text style={styles.bold}>Adresse du représentant légal :</Text>
            <CerfaAdresseField value={ representant?.adresse } />
            <Text>
              Courriel :{' '}
              <CerfaElement metadata={ CAPPRepresentantMetadata.email } value={ representant?.email } default="________________@________________" />
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              Date de naissance : <CerfaElement metadata={ CAPPStagiaireMetadata.dateNaissance } value={stagiaire?.dateNaissance} />
            </Text>
            <Text>
              Sexe :{' '}
              <Checkboxes
                value={stagiaire?.sexe}
                choices={[
                  { value: '1', label: 'M' },
                  { value: '2', label: 'F' },
                ]}
              ></Checkboxes>
              {'    '}
              Département de naissance :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.departementNaissance } value={stagiaire?.departementNaissance} />
            </Text>
            <Text>
              Commune de naissance :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.communeNaissance } value={stagiaire?.communeNaissance} />
            </Text>
            <Text>
              Nationalité :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.nationalite } value={stagiaire?.nationalite} />
              {'                 '}
              Régime social :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.regimeSocial } value={stagiaire?.regimeSocial} />
            </Text>
            <Text>
              {`Déclare être inscrit sur la liste des sportifs de haut\nniveau : `}
              <CerfaElement metadata={ CAPPStagiaireMetadata.listeSportifs } value={ stagiaire?.listeSportifs } />
            </Text>
            <Text>
              Déclare bénéficier de la reconnaissance travailleur handicapé :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.handicap } value={ stagiaire?.handicap } />
            </Text>
            <Text>
              Situation avant ce contrat :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.situationAvantContrat } value={stagiaire?.situationAvantContrat} />
            </Text>
            <Text>
              Dernier diplôme ou titre préparé :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.dernierDiplome } value={stagiaire?.dernierDiplome} />
            </Text>
            <Text>
              Dernière classe / année suivie :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.derniereClasse } value={stagiaire?.derniereClasse} />
            </Text>
            <Text>Intitulé précis du dernier diplôme ou titre préparé :</Text>
            <CerfaElement metadata={ CAPPStagiaireMetadata.intituleDernierDiplome } value={stagiaire?.intituleDernierDiplome} />
            <Text>
              Diplôme ou titre le plus élevé obtenu :{' '}
              <CerfaElement metadata={ CAPPStagiaireMetadata.plusHautDiplome } value={stagiaire?.plusHautDiplome} />
            </Text>
            <Text>
              {`Déclare avoir un projet de création ou de reprise\nd'entreprise : `}
              <CerfaElement metadata={ CAPPStagiaireMetadata.projetEntreprise } value={ stagiaire?.projetEntreprise } />
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
