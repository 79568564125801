import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { DATE_TYPE_CREATION, Dossier, getDossiers } from 'api/dossierAPI'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'

interface DossierProps {
    name: string
    getIdDossier?: (id: string) => void

}

export default function DossierSelector(props: DossierProps) {

    const form = useFormContext<any>()
    const fieldState = getFieldState<string | null>(form, props.name)

    async function searchDossiers(recherche: string) {
        const page = await getDossiers({
            page: 0,
            parPage: 10,
            tri: 'DateCreationFO__c',
            ordre: 'desc',
            etatDossierFo: [],
            dispositifFo: [],
            recherche: recherche,
            dateType: DATE_TYPE_CREATION,
            utilisateur: 'tous',
            financementVV: 'tous'
        })
        return page.items
    }

    const onSelect = (dossier: Dossier) => {
        form.setValue(props.name, dossier.NumeroDossier__c, true)
        if (dossier.Id)
            props.getIdDossier?.(dossier.Id)
    }

    return (
        <AutocompleteBaseField<Dossier>
            label="Dossier N°"
            defaultValue={ fieldState.value ?? "" }
            placeholder="Rechercher un dossier..."
            required={ fieldState.constraints.required }
            error={ fieldState.error }
            onBlur={ value => form.setValue(props.name, value === "" ? null : form.getValue(props.name), true) }
            
            search={ searchDossiers }

            optionFor={ dossier => ({
                id: dossier.NumeroDossier__c,
                content: `${dossier.NumeroDossier__c} - ${dossier.Intitule__c ?? "(brouillon)"}`
            })}
            onSelect={ onSelect }
        />
    )
}
