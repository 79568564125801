import { AppModule } from 'app/appInfo'
import { useAppContext } from 'app/useAppContext'
import { useAppState } from 'app/useAppState'
import { MyMenu } from "atlas-ds"
import { Location } from 'history'
import { matchPath } from 'react-router-dom'
import MenuItem from './MenuItem'

export interface MenuProps {
    toggleMenuOpen: (() => void)
}

export default function Menu(props: MenuProps) {
    const appState = useAppState()
    const appContext = useAppContext()

    const isActivePath = (module: AppModule, location?: Location): boolean => {
        const routes = appContext.resolvedRoutes.get(module.id)
        if (!routes) return false
        return location != null && routes.some(route => route && matchPath(location.pathname, route.props))
    }

    const menuItems = appState.app && appState.profil &&
        (appContext.appInfo.menuDisabled == null || !appContext.appInfo.menuDisabled(appState))
            ? appContext.appInfo.modules.map(moduleGroup => moduleGroup
                .flatMap(m => (appContext.resolvedRoutes.get(m.id) ?? [])
                    .filter(route => !!route.props.menuIcon && !!route.props.label)
                    .map(route => <MenuItem
                        key={ route.key }
                        href={ route.props.path }
                        iconName={ route.props.menuIcon! }
                        isActive={ loc => isActivePath(m, loc) }
                        onClick={ props.toggleMenuOpen }
                    >{ route.props.label! }</MenuItem>)
                )
            ) : []

    return <MyMenu onClose={props.toggleMenuOpen} itemsGroups={menuItems} />
}
