
import { AtlasButton } from 'atlas-ds'
import { useState } from 'react'
import RefBancaireDesactiverModal from './RefBancaireDesactiverModal'
import { RefBancaireActionProps } from './DetailRefBancaire'
import { checkDetailAction } from 'pages/detail-common/Detail'


export default function RefBancaireDesactiver(props: RefBancaireActionProps) {
    const refBancaire = checkDetailAction(props)
    if (!refBancaire) return null

    const [showDesactiverRefBancaire, setShowDesactiverRefBancaire] = useState(false)

    return <>
        <AtlasButton id="desactiver" level={ 2 } onClick={() => setShowDesactiverRefBancaire(true)}>
            Désactiver le RIB
        </AtlasButton>

        { showDesactiverRefBancaire && <RefBancaireDesactiverModal show={showDesactiverRefBancaire} refBancaire={refBancaire}
            refreshEvent='DetailRefBancaire:Refresh' close={ () => setShowDesactiverRefBancaire(false) } /> }
    </>
}
 