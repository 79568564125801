import { MyForm } from "atlas-ds";
import { ClassConstructor, FieldNames } from "components/elements/engine";
import { FormFields } from "components/elements/FormFields";
import { Adresse } from "slices/dossierClasses";


export function DossierAdresseElements<T>(props: { model: ClassConstructor<T>, names: FieldNames<T>, parentPath?: keyof T }) {

    const parent = { model: props.model, path: props.names }

    return (<>
        <FormFields parent={ parent } model={ Adresse } names={{
            appartement: true,
            batiment: true,
        }} />
        <MyForm.Field long>
            <FormFields parent={ parent } model={ Adresse } names={{
                adresse: true
            }} />
        </MyForm.Field>
        <MyForm.Field short>
            <FormFields parent={ parent } model={ Adresse } names={{
                complement: true
            }} />
        </MyForm.Field>
        <FormFields parent={ parent } model={ Adresse } names={{
            codePostal: true
        }} />
    </>)
}
