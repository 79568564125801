import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { modifierMotDePasse } from "api/monCompteAPI"
import { nil } from "app/public/MamcApp"
import { MotDePasseBloc, MotDePassePropertiesSchemas } from "app/public/MotDePasseBloc"
import { RootState } from "app/rootReducer"
import { AtlasBreadcrumb, AtlasColumns, AtlasInfo, MyForm } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import Link, { linkConstructor } from "components/Link"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import PasswordField from "components/reform/inputs/PasswordField"
import { RequiredString } from "components/yop/constraints"
import log from "loglevel"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

type PasswordValues = {
  ancienMotDePasse: string,
  motDePasse: string
}

export function MotDePasse() {
  const history = useHistory();

  const { pending: loading } = useSelector((state: RootState) => state.profilState)

  const [backendError, setBackendError] = useState<string | null>(null)

  const initialValues = {
    ancienMotDePasse: nil as string,
    motDePasse: nil as string
  }
  const validationSchema = Yop.object<PasswordValues>({
    ancienMotDePasse: RequiredString,
    ...MotDePassePropertiesSchemas
  })

  const form = useForm<PasswordValues>({
    initialValues,
    validationSchema,
    dispatchEvent: false,
    onSubmit: async (context: UseFormReturn<PasswordValues>) => {
      modifierMotDePasse(JSON.stringify({
        'oldPassword': context.values?.ancienMotDePasse,
        'password': context.values?.motDePasse
      }))
      .then(_ => {
          history.replace({
              pathname: "/mon-compte",
          })
          ToastService.showToast({
            type: "success",
            label: "Modification de mot de passe",
            content: "Votre mot de passe a bien été modifié."
          })
      })
      .catch(error => {
          log.error(error)
          const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
          setBackendError(message)
          context.setSubmitting(false)
      })}
  })

  return <>
    <PageHeader
      breadcrumb={<AtlasBreadcrumb
        parts={[{
          label: "Mon compte",
          href: "/mon-compte"
        }]}
        currentPageLabel="Mot de passe"
        linkConstructor={linkConstructor}
      />}
    >Modifier mon mot de passe</PageHeader>

    <AtlasColumns>
      <Form context={form} disabled={loading} noValidate>
        <MyForm>
          <MyForm.Field alone>
            <PasswordField name="ancienMotDePasse" label="Mot de passe actuel" autocomplete="current-password" />
          </MyForm.Field>
          <MyForm.Field alone>
            <MotDePasseBloc label="Nouveau mot de passe" />
          </MyForm.Field>

          {backendError && <AtlasInfo type="error" title="Erreur de mise à jour" >{ backendError }</AtlasInfo>}

          <MyForm.Actions toLeft>
            <Link level={2} to='/mon-compte' disabled={form.submitting}>Annuler</Link>
            <Button submit={true} spinner={{ spinning: form.submitting }}>Valider</Button>
          </MyForm.Actions>
        </MyForm>
      </Form>
    </AtlasColumns>
  </>
}
