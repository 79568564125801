import { AtlasInfo } from "atlas-ds"
import PageHeader from "components/PageHeader"

export function ReinitialiserMotDePasseConfirmation() {
    return <>
        <PageHeader>Mot de passe réinitialisé</PageHeader>

        <AtlasInfo
            type="success"
            title="Votre mot de passe a été correctement réinitialisé"
            link={<a href="/?contexte=compte">Me connecter à myAtlas</a>}
        >Vous pouvez l'utiliser pour vous connecter grâce au lien ci-dessous.</AtlasInfo>
    </>
}
