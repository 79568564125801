import { AtlasValue } from 'atlas-ds'
import { formatSuffix } from 'components/format/Format'

interface RecapNumberFieldProps {
    label: string,
    value: string | number | null | undefined,
    suffix?: string,
    type?: string,
}

export default function RecapNumberField(props: RecapNumberFieldProps) {
    const suffix = props.suffix ? ' ' + props.suffix : ''

    const value = props.value || props.value === 0 ? props.value + formatSuffix(suffix, (((typeof props.value === 'string' && parseFloat(props.value.toString()) >= 2) || (typeof props.value === 'number' && props.value >= 2)))) : ''

    return <AtlasValue label={ props.label }>{ value }</AtlasValue>
}
