import { TestValidationContext, ValidationContext } from '@dsid-opcoatlas/yop'
import { Profil } from 'api/profilAPI'
import { TYPE_CONTRAT_MODIFICATION_SITUATION_JURIDIQUE, TYPE_CONTRAT_NOUVEAU3, TYPE_CONTRAT_PREMIER, TYPE_DEROGATION_REDUCTION_DUREE, TYPE_MODIFICATION_AVENANT } from 'api/references'
import { Reference } from 'api/referencesAPI'
import { formatDate, parseDate } from 'components/format/Format'
import CurrencyField from 'components/reform/inputs/CurrencyField'
import SelectRefField from 'components/reform/inputs/SelectRefField'
import { addDays, addYears, differenceInMonths, isAfter, isBefore, isEqual, startOfToday } from 'date-fns'
import { cloneDeep } from 'lodash'
import { booleanToDoubleMapping, dateMapping, simpleMapping, stringToNumberMapping } from 'pages/dossier/metadata/DossierMapping'
import { ElementContext, FormMetadata, FormStep, IgnoredFieldMetadata, ObjectMetadata, override, requiredIf } from 'pages/dossier/metadata/FormMetadata'
import { AdresseFieldMetadata, adresseMapping } from 'components/metadata/AdresseFieldMetadata'
import { BooleanFieldMetadata, CheckboxFieldMetadata } from 'components/metadata/BooleanFieldMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { RequiredDurationMnFieldMetadata, durationMnMapping, durationMnSchema } from 'components/metadata/DurationMnFieldMetadata'
import { NumberFieldMetadata } from 'components/metadata/NumberFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { EmailFieldMetadata, FirstNameFieldMetadata, NameFieldMetadata, TextFieldMetadata } from 'components/metadata/TextFieldMetadata'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import CerfaBooleanField from 'pages/dossier/cerfa/CerfaBooleanField'
import { DossierStagiaireRepresentant, createDossierStagiaireRepresentant } from 'slices/dossierCommon'
import { RemunerationFieldMetadata } from './RemunerationFieldMetadata'
import { rebuildAnneesRemuneration } from './rebuildAnneesRemuneration'
import { SetValueOptions, UseFormReturn, reformContext } from '@dsid-opcoatlas/reform'
import { DossierCAPP, DossierCAPPContrat, v10required } from './CAPPModel'
import { CAPPFormationMetadata } from './CAPPFormationMetadata'
import { CAPPStagiaireMetadata } from './CAPPStagiaireMetadata'
import { CAPPMaitre1Metadata, CAPPMaitre2Metadata } from './CAPPMaitresMetadata'
import { EditDossierCAPP } from './CAPPEditFormMetadata'


export const SECTION_CAPP_CONTRAT = 'contrat'
export const SECTION_CAPP_CONTRAT_SALAIRE = 'salaire'
export const SECTION_CAPP_CONTRAT_AVANTAGES = 'avantages'
export const SECTION_CAPP_CONTRAT_REPRESENTANT = 'representant'
export const SECTION_CAPP_CONTRAT_MODIFICATION = 'modification'

const today = startOfToday()
const date2020 = new Date(2020, 0, 1)

const IDCC_NIVEAU = ['0438', '0653', '0787', '1468', '1486', '1672', '1679', '1726', '1801', '2120', '2247', '2335', '2357', '3210', '3213', '5005']

export const isCAPPMineur = (dossier: DossierCAPP): boolean => {
    if (!dossier?.contrat?.dateConclusion || !dossier?.stagiaire?.dateNaissance)
        return false
    const dateNaissanceStagiaire = parseDate(dossier?.stagiaire?.dateNaissance)
    const dateConclusion = parseDate(dossier?.contrat?.dateConclusion)
    if (!dateConclusion || !dateNaissanceStagiaire)
        return false
    const date18 = addYears(dateNaissanceStagiaire, 18)
    return isAfter(date18, dateConclusion)
}

export const isCAPPPlus21Ans = (dossier: DossierCAPP): boolean => {
    if (!dossier?.contrat?.dateConclusion || !dossier?.stagiaire?.dateNaissance)
        return false
    const dateNaissanceStagiaire = parseDate(dossier?.stagiaire?.dateNaissance)
    const dateConclusion = parseDate(dossier?.contrat?.dateConclusion)
    if (!dateConclusion || !dateNaissanceStagiaire)
        return false
    const date21 = addYears(dateNaissanceStagiaire, 21)
    return !isAfter(date21, dateConclusion)
}

const isCoefApplicable = (dossier: DossierCAPP) => {
    return isCAPPPlus21Ans(dossier) && IDCC_NIVEAU.includes(dossier.employeur.codeIdcc)
}

const validateContratDates = (ctx: TestValidationContext<any, DossierCAPPContrat, DossierCAPP & EditDossierCAPP>) => {
    if (ctx.root == null || ctx.parent == null)
        return true

    const form = reformContext(ctx)
    const dateDebut = ctx.parent.dateDebut
    const dateFin = ctx.parent.dateFin
    const dateConclusion = ctx.parent.dateConclusion

    if (dateDebut != null && dateFin != null) {
        if (isBefore(dateFin, dateDebut)) {
            form.touch(ctx.path === "contrat.dateDebut" ? "contrat.dateFin" : "contrat.dateDebut")
            ctx.createError("La date de début de contrat est postérieure à la date de fin de contrat", "contrat.dateDebut")
            return ctx.createError("La date de fin de contrat est antérieure à la date de début de contrat", "contrat.dateFin")
        }
        if (isAfter(dateFin, addYears(dateDebut, 4))) {
            form.touch(ctx.path === "contrat.dateDebut" ? "contrat.dateFin" : "contrat.dateDebut")
            ctx.createError("La durée du contrat ne peut pas dépasser 4 ans", "contrat.dateDebut")
            return ctx.createError("La durée du contrat ne peut pas dépasser 4 ans", "contrat.dateFin")
        }
        const typeContrat = (ctx.root as EditDossierCAPP).typeContratInitial ?? ctx.parent.typeContrat
        if (typeContrat != null && typeContrat !== TYPE_CONTRAT_NOUVEAU3 && differenceInMonths(addDays(dateFin, 1), dateDebut) < 6) {
            form.touch(ctx.path === "contrat.dateDebut" ? "contrat.dateFin" : "contrat.dateDebut")
            ctx.createError("La durée du contrat ne peut, sauf dans certains cas dérogatoires, être inférieure à 6 mois", "contrat.dateDebut")
            return ctx.createError("La durée du contrat ne peut, sauf dans certains cas dérogatoires, être inférieure à 6 mois", "contrat.dateFin")
        }
    }

    if (dateConclusion != null && dateDebut != null && ctx.root?.typeModificationContrat !== TYPE_MODIFICATION_AVENANT) {
        if (isAfter(dateConclusion, dateDebut)) {
            form.touch(ctx.path === "contrat.dateDebut" ? "contrat.dateConclusion" : "contrat.dateDebut")
            ctx.createError("La date de conclusion (signature) est postérieure à la date de début de contrat", "contrat.dateConclusion")
            return ctx.createError("La date de début de contrat est antérieure à la date de conclusion (signature)", "contrat.dateDebut")
        }

        if (differenceInMonths(dateDebut, dateConclusion) >= 12) {
            form.touch(ctx.path === "contrat.dateDebut" ? "contrat.dateConclusion" : "contrat.dateDebut")
            ctx.createError("La date de début du contrat doit être antérieure à la date de conclusion plus 12 mois", "contrat.dateDebut")
            return ctx.createError("La date de début du contrat doit être antérieure à la date de conclusion plus 12 mois", "contrat.dateConclusion")
        }
    }

    if (dateConclusion != null && dateFin != null && differenceInMonths(dateFin, dateConclusion) >= 50) {
        form.touch(ctx.path === "contrat.dateFin" ? "contrat.dateConclusion" : "contrat.dateFin")
        ctx.createError("La date de fin de contrat doit être antérieure à la date de conclusion plus 50 mois", "contrat.dateFin")
        return ctx.createError("La date de fin de contrat doit être antérieure à la date de conclusion plus 50 mois", "contrat.dateConclusion")
    }

    return true
}

export const CAPPContratBaseMetadata: ObjectMetadata<DossierCAPPContrat> = {
    dateConclusion: DateFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Date de conclusion (date de signature du contrat)',
            tooltip: TOOLTIPS.capp.contrat.dateConclusion,
        },
        mapping: dateMapping('DateConclusionContrat__c'),
    }),
    anneeFormationEntreeContrat: SelectRefFieldMetadata({
        section: SECTION_CAPP_CONTRAT_SALAIRE,
        props: {
            label: 'Année de formation à l\'entrée du contrat',
            options: () => [
                { Id: '1', Libelle__c: 'Année 1'} as Reference,
                { Id: '2', Libelle__c: 'Année 2'} as Reference,
                { Id: '3', Libelle__c: 'Année 3'} as Reference,
                { Id: '4', Libelle__c: 'Année 4'} as Reference,
                { Id: '5', Libelle__c: 'Année 5'} as Reference,
            ],
            tooltip: TOOLTIPS.capp.contrat.anneeFormationEntreeContrat,
        },
        mapping: stringToNumberMapping('AnneeFormationEntreeContrat__c', 'module'),
    }).mutate(yop => yop.oneOf(['1', '2', '3', '4', '5'], 'L\'année de formation doit être entre 1 et 5')),
    niveauOuEchelon: TextFieldMetadata({
        section: SECTION_CAPP_CONTRAT_SALAIRE,
        props: {
            label: 'Niveau ou échelon dans la grille de classification de la convention collective',
            tooltip: TOOLTIPS.capp.contrat.niveauOuEchelon,
        },
        visibleIf: (ctx: ElementContext<DossierCAPPContrat, DossierCAPP>) => isCoefApplicable(ctx.root),
        mapping: simpleMapping('NiveauOuEchelon__c', 'module'),
    }).mutate(yop => yop.max(4)),
    coefficientHierarchique: TextFieldMetadata({
        section: SECTION_CAPP_CONTRAT_SALAIRE,
        props: {
            label: 'Coefficient hiérarchique',
            tooltip: TOOLTIPS.capp.contrat.coefficientHierarchique,
        },
        visibleIf: (ctx: ElementContext<DossierCAPPContrat, DossierCAPP>) => isCoefApplicable(ctx.root),
        mapping: simpleMapping('CoefficientHierarchique__c', 'module'),
    }).mutate(yop => yop.max(4)),
} as any

export const CAPPContratMetadata: ObjectMetadata<DossierCAPPContrat> = {
    typeContrat: SelectRefFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Type de contrat',
            tooltip: TOOLTIPS.capp.contrat.typeContrat,
            options: refs => refs.REF_TYPE_CONTRAT.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        input: props => <SelectRefField {...props} onChange={ (value, form) => {
            if (value === TYPE_CONTRAT_PREMIER) { 
                form.setValue('contrat.numContratPrecedent', '', SetValueOptions.Untouch | SetValueOptions.Validate)
                // form.touched.add('contrat.dateDebut')   // ??
                // form.touched.add('contrat.dateFin')     // ??
            }
        }} />,
        mapping: simpleMapping('TypeContrat__c'),
    }).required().mutate(yop => yop.test<DossierCAPPContrat, EditDossierCAPP>(context => {
        if (context.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT && context.value === TYPE_CONTRAT_MODIFICATION_SITUATION_JURIDIQUE) {
            return context.createError(`
                Le type d'avenant « 31 Modification de la situation juridique de l’employeur » correspond à un changement de SIREN dans le cadre d’une succession, vente, fusion (Article L1224-1 du Code du travail). Le dossier étant strictement rattaché à un Siren, ce dernier ne peut être modifié dans ce formulaire.
                <br/><br/>
                Nous vous invitons à déposer un avenant signé dans la rubrique « Documents » accessible dans le détail du dossier. Si la modification porte sur un établissement rattaché à ce Siren, sélectionner le type d'avenant 37 Modification du lieu d’exécution du contrat.`
            )
        }
        return true
    })),
    numContratPrecedent: TextFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Numéro du contrat précédent ou du contrat sur lequel porte l’avenant',
            tooltip: TOOLTIPS.capp.contrat.numContratPrecedent,
            disabled: (ctx: ElementContext<DossierCAPPContrat>) => ctx.parent?.typeContrat === '' || ctx.parent?.typeContrat === TYPE_CONTRAT_PREMIER,
        },
        mapping: simpleMapping('NumeroPrecedentContrat__c'),
    }).mutate(yop => yop.matches(/^(.){15}$/, 'Format de numéro de contrat incorrect (15 caractères)')),  // Non obligatoire, cf ticker #2482
    typeDerogation: SelectRefFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Type dérogation',
            tooltip: TOOLTIPS.capp.contrat.typeDerogation,
            options: refs => refs.REF_TYPE_DEROGATION.filter(ref => ref.IsActive__c && ref.Priorite__c && ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            code: true,
        },
        input: props => <SelectRefField {...props} emptyOptionLabel="Aucune dérogation" onChange={ (value, form) => {
            if (value !== TYPE_DEROGATION_REDUCTION_DUREE) {
                form.setValue('contrat.motifReduction', null, SetValueOptions.Untouch | SetValueOptions.Validate)
            }
        }} />,
        mapping: simpleMapping('TypeDerogation__c'),
    }),
    motifReduction: SelectRefFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Motif réduction durée de contrat',
            tooltip: TOOLTIPS.capp.contrat.typeDerogation,
            options: refs => refs.REF_MOTIF_REDUCTION.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            code: true,
            disabled: (ctx: ElementContext<DossierCAPPContrat>) => ctx.parent?.typeDerogation !== TYPE_DEROGATION_REDUCTION_DUREE,
        },
        mapping: simpleMapping('MotifReductionDureeContrat__c'),
    }).mutate(yop => yop.requiredIf<DossierCAPPContrat>(ctx => ctx.parent?.typeDerogation === TYPE_DEROGATION_REDUCTION_DUREE)),
    modeContractuelApprentissage: SelectRefFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: "Mode contractuel de l'apprentissage",
            options: refs => refs.REF_TYPE_MODE_CONTRACTUEL.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            code: true,
        },
        mapping: simpleMapping('TypeModeContractuel__c'),
    }).required(),
    dateDebut: DateFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: "Date de début d'exécution du contrat",
            tooltip: TOOLTIPS.capp.contrat.dateDebut,
        },
        mapping: dateMapping('DateDebutContrat__c'),
    }).required().mutate(yop => yop.test(validateContratDates) /*validateDateDebut*/),
    dateDebutPratique: requiredIf(DateFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Date de début de formation pratique chez l’employeur',
            tooltip: TOOLTIPS.capp.contrat.dateDebutPratique,
        },
        mapping: dateMapping('DateFormationPratiqueEmployeur__c'),
    }), v10required).mutate(yop => yop
        .min((c: ValidationContext<any, DossierCAPPContrat>) => c.parent?.dateDebut as any)
        .test<DossierCAPPContrat>(ctx => {
            if (ctx.parent?.dateFin != null && (isBefore(ctx.parent.dateFin, ctx.value) || isEqual(ctx.parent.dateFin, ctx.value)))
                ctx.createError(`La date doit être antérieure à la date de fin de contrat, soit antérieure au ${ formatDate(ctx.parent.dateFin) }`)
            return true
        })
    ),
    dateFin: (
        DateFieldMetadata({
            section: SECTION_CAPP_CONTRAT,
            props: {
                label: "Date de fin du contrat ou de la période d'apprentissage",
                tooltip: TOOLTIPS.capp.contrat.dateFin,
            },
            mapping: dateMapping('DateFinContrat__c'),
        })
        .required()
        .mutate(yop => yop.test(validateContratDates))
    ),
    dateConclusion: override(CAPPContratBaseMetadata.dateConclusion, {
        props: {
            onBlur: (value: any, context: UseFormReturn<any>, _: any, change: boolean) => {
                if (change) {
                    const representant = context.getValue('representant')
                    if (isCAPPMineur(context.values as DossierCAPP)) {
                        if (representant == null)
                            context.setValue('representant', createDossierStagiaireRepresentant())
                    }
                    else if (representant !== null)
                        context.setValue('representant', null)
                }
            },
        },
    }).required().mutate(yop => yop
        .min(date2020, "Date de conclusion antérieure au 01/01/2020 : seuls les contrats conclus en 2020 peuvent être saisis sur myAtlas, veuillez " +
            "nous retourner le Cerfa signé par voie postale")
        .max(today, "Le contrat pourra être transmis à Atlas lorsque le contrat aura effectivement été conclu. La date de conclusion s'entend " +
            "comme étant celle du jour où les deux signatures des parties au contrat (employeur et apprenti) sont recueillies, en principe un contrat " +
            "doit être signé avant de débuter.")
        .test(validateContratDates)),
    dureeHebdomadaireTravail: RequiredDurationMnFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Durée hebdomadaire du travail',
            tooltip: TOOLTIPS.capp.contrat.dureeHebdomadaireTravail,
        },
        mapping: durationMnMapping('DureeHebdomadaireTravailHeures__c', 'DureeHebdomadaireTravailMinutes__c'),
        dependencies: [
            { stepName: "Apprenti(e)", path: "stagiaire.dateNaissance", field: CAPPStagiaireMetadata.dateNaissance },
            { stepName: "Apprenti(e)", path: "stagiaire.situationAvantContrat", field: CAPPStagiaireMetadata.situationAvantContrat },
            { stepName: "Maître d'apprentissage N°1", path: "maitre1.dateNaissance", field: CAPPMaitre1Metadata.dateNaissance },
            { stepName: "Maître d'apprentissage N°2", path: "maitre2.dateNaissance", field: CAPPMaitre2Metadata.dateNaissance },
            { stepName: "Formation", path: "formation.majorationHandicapCout", field: CAPPFormationMetadata.majorationHandicapCout },
            { stepName: "Formation", path: "formation.dateDebutFormation", field: CAPPFormationMetadata.dateDebutFormation, visibleWith: "formation.dateFinFormation" },
            { stepName: "Formation", path: "formation.dateFinFormation", field: CAPPFormationMetadata.dateFinFormation },
        ]
    }).mutate(_ => durationMnSchema(48, 'Maximum 48 heures par semaine')),
    travailDangereux: BooleanFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
        props: {
            label: 'Travail sur des machines dangereureuses ou exposition à des risques particuliers',
            tooltip: TOOLTIPS.capp.contrat.travailDangereux,
        },
        cerfa: props => <CerfaBooleanField {...props} spacing={5} />,
        mapping: simpleMapping('MachinesDangereuses__c'),
    }).required(),
    remuneration: RemunerationFieldMetadata({
        section: SECTION_CAPP_CONTRAT,
    }),
    anneeFormationEntreeContrat: override(CAPPContratBaseMetadata.anneeFormationEntreeContrat, {}).required(),
    niveauOuEchelon: override(CAPPContratBaseMetadata.niveauOuEchelon, {}).mutate(yop => yop.requiredIf(ctx => isCoefApplicable(ctx.root))),
    coefficientHierarchique: override(CAPPContratBaseMetadata.coefficientHierarchique, {}).mutate(yop => yop.requiredIf(ctx => isCoefApplicable(ctx.root))),
    salaireEmbauche: NumberFieldMetadata({
        section: SECTION_CAPP_CONTRAT_SALAIRE,
        props: {
            label: "Salaire mensuel brut à l'embauche",
            tooltip: TOOLTIPS.capp.contrat.salaireEmbauche,
            fractionDigits: 2,
            suffix: '€ / mois',
        },
        input: CurrencyField,
        mapping: simpleMapping('MontantSalaire__c'),
    }).required().mutate(yop => yop.min(100).max(9999.99)),
    nourriture: NumberFieldMetadata({
        section: SECTION_CAPP_CONTRAT_AVANTAGES,
        props: {
            label: 'Nourriture',
            fractionDigits: 2,
            suffix: '€ / repas',
        },
        input: CurrencyField,
        mapping: simpleMapping('AvantagesNourriture__c'),
    }).mutate(yop => yop.min(0).max(99.99)),
    logement: NumberFieldMetadata({
        section: SECTION_CAPP_CONTRAT_AVANTAGES,
        props: {
            label: 'Logement',
            fractionDigits: 2,
            suffix: '€ / mois',
        },
        input: CurrencyField,
        mapping: simpleMapping('AvantagesLogement__c'),
    }).mutate(yop => yop.min(0).max(999.99)),
    autres: CheckboxFieldMetadata({
        section: SECTION_CAPP_CONTRAT_AVANTAGES,
        props: {
            label: 'Autres',
        },
        mapping: booleanToDoubleMapping('AutresAvantages__c'),
    }),
    dateEffet: IgnoredFieldMetadata(),
    dateConclusionAvenant: IgnoredFieldMetadata(),
}

export const CAPPRepresentantMetadata: ObjectMetadata<DossierStagiaireRepresentant> = {
    nom: NameFieldMetadata({
        section: SECTION_CAPP_CONTRAT_REPRESENTANT,
        props: {
            label: 'Nom', 
        },
        mapping: simpleMapping('NomRepresentantLegal__c'),
    }).required().mutate(yop => yop.max(50)),
    prenom: FirstNameFieldMetadata({
        section: SECTION_CAPP_CONTRAT_REPRESENTANT,
        props: {
            label: 'Premier prénom selon l’état civil',
        },
        mapping: simpleMapping('PrenomRepresentantLegal__c'),
    }).required().mutate(yop => yop.matches(/^[\s-]*[^\s-]*([\s-]*[^\s-]*)?[\s-]*$/, 'Merci de saisir uniquement le premier prénom').max(50)),
    email: EmailFieldMetadata({
        section: SECTION_CAPP_CONTRAT_REPRESENTANT,
        props: {
            label: 'E-mail',
        },
        mapping: simpleMapping('CourrielRepresentantLegal__c'),
    }),
    adresse: AdresseFieldMetadata({
        section: SECTION_CAPP_CONTRAT_REPRESENTANT,
        mapping: adresseMapping(
            'Adresse1RepresentantLegal__c', 'Adresse2RepresentantLegal__c', 'Adresse3RepresentantLegal__c', 
            'Adresse4RepresentantLegal__c', 'CodePostalRepresentantLegal__c', 'CommuneRepresentantLegal__c'
        ),
    }).required(),
}

export function dossierCAPPContratInitializer(dossier: DossierCAPP, metadata: FormMetadata<DossierCAPP>, _: Profil | null, step: FormStep<DossierCAPP>) {
    const dateNaissance = parseDate(dossier.stagiaire?.dateNaissance)
    const dateConclusion = parseDate(dossier.contrat?.dateConclusion)
    if (dateNaissance && dateConclusion && isAfter(addYears(dateNaissance, 18), dateConclusion)) {
        if (dossier.representant == null)
            dossier.representant = createDossierStagiaireRepresentant()
    } else {
        dossier.representant = null
    }

    if (dossier.contrat?.remuneration) {
        rebuildAnneesRemuneration(dossier, metadata.api.isEdit ?? false)
    }

    return dossier
}
