import { ContactEntreprise, getContactsEntreprise } from 'api/profilAPI'
import { Entreprise } from 'api/referencesAPI'
import useApiState from 'api/useApiState'
import useEventListener, { AppEvent } from 'app/events'
import { AtlasButton, AtlasHeading, AtlasIcon, AtlasLoading, MyTable } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import { useEffect, useState } from 'react'
import ContactEntrepriseModal from './ContactEntrepriseModal'
import DeleteContactModal from './DeleteContactModal'


export const contactRefreshEvent = "ListContacts:Refresh"

export default function ContactsCompteEntreprise(props: {
    entreprise: Entreprise | null,
    editing: boolean,
    showAddContact: boolean,
    setShowAddContact: (e: boolean) => void
}) {

    const [updateContact, setUpdateContact] = useState<ContactEntreprise | null>(null)
    const [deleteContact, setDeleteContact] = useState<ContactEntreprise | null>(null)

    const PAR_PAGE_CONTACT = 100

    const [{ pending, value: contacts, error }, withGetContactsEntreprise] = useApiState(getContactsEntreprise)
    useEffect(() => {
        if (props.entreprise?.IdHeroku)
            withGetContactsEntreprise(props.entreprise.IdHeroku, PAR_PAGE_CONTACT)
    }, [withGetContactsEntreprise, props.entreprise?.IdHeroku])


    useEventListener(contactRefreshEvent, (event: AppEvent<string>) => {
        ToastService.showToast({
            label: "Contacts de l'entreprise",
            content: event.detail
        })
        withGetContactsEntreprise(props.entreprise?.IdHeroku!)
    })

    const contactsActions = (contact: ContactEntreprise) => props.editing ? [] : [
        <AtlasButton
            key="supprimer"
            icon="trash"
            level={ 3 }
            onClick={ () => setDeleteContact(contact) }
        >Supprimer</AtlasButton>,
        <AtlasButton
            key="modifier"
            icon="pen"
            level={ 3 }
            onClick={ () => setUpdateContact(contact) }
        >Modifier</AtlasButton>
    ]

    return (
        <>
            <div>
                <AtlasHeading
                    tag="h2"
                    size="m"
                    button={<AtlasButton
                        onClick={() => props.setShowAddContact(true)}
                        disabled={props.editing}
                        level={3}
                        icon="plus"
                    >Ajouter un contact</AtlasButton>}
                >Contacts</AtlasHeading>
                <AtlasLoading loading={pending}>
                    <MyTable
                        caption="Contacts"
                        rows={ contacts ?? [] }
                        emptyText="Aucun contact n'est associé à cette entreprise pour le moment."
                        error={error ?? undefined}
                        rowActions={contactsActions}
                        columns={[
                            {
                                id: "name",
                                label: "Nom",
                                value: (row: any) => <>
                                    {row.defaultCampus__c && <><AtlasIcon name="success" title="Contact principal" size="xs" />&nbsp;</>}
                                    {row.FirstName} {row.LastName}
                                </>
                            },
                            {
                                id: "Phone",
                                label: "Téléphone"
                            },
                            {
                                id: "Email",
                                label: "Adresse mail"
                            },
                        ]}
                    />
                </AtlasLoading>
            </div>

            { props.showAddContact &&
            <ContactEntrepriseModal entreprise={ props.entreprise } forceDefault={ (contacts?.length ?? 0) === 0 } close={() => props.setShowAddContact(false)} refreshEvent={ contactRefreshEvent } />
            }

            { updateContact &&
            <ContactEntrepriseModal entreprise={ props.entreprise } contact={ updateContact } forceDefault={ (contacts?.length ?? 0) === 1 } close={ () => setUpdateContact(null) } refreshEvent={ contactRefreshEvent } />
            }

            { deleteContact &&
            <DeleteContactModal contact={ deleteContact } close={ () => setDeleteContact(null) } refreshEvent={ contactRefreshEvent } />
            }
        </>
    )
}
