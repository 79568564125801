import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

interface MyScrollToTopProps {
  /**
   * L'élément en haut duquel remonter doit être englobé par le composant
   */
  children: React.ReactNode;

  /**
   * Label du bouton
   */
  label?: string;
}

/**
 * Un bouton permettant de remonter en haut de la page ou d'un élément.
 * Le bouton apparait uniquement en présence de scroll, lorsque l'utilisateur
 * commence à remonter.
 */
export function MyScrollToTop(props: MyScrollToTopProps) {
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const top = useRef<HTMLDivElement>();
  const scrollPosition = useRef<number>(0);

  const toggleScrollToTopVisible = (_: Event) => {
    const newScrollPosition = window.pageYOffset;
    const scrollToTopOffset = top.current?.getBoundingClientRect().top ?? 0;
    setScrollToTopVisible(
      scrollToTopOffset < 0 && newScrollPosition < scrollPosition.current
    );
    scrollPosition.current = newScrollPosition;
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleScrollToTopVisible);
    return () => {
      window.removeEventListener("scroll", toggleScrollToTopVisible);
    };
  }, []);

  return (
    <div
      className="my-scrollToTop"
      ref={(div) => {
        top.current = div ?? undefined;
      }}
    >
      {props.children}
      <div className="my-scrollToTop__inner">
        <button
          type="button"
          className={classNames("my-scrollToTop__button", {
            "my-scrollToTop__button--visible": scrollToTopVisible,
          })}
          onClick={(_) => top.current?.scrollIntoView(true)}
        >
          {props.label || "Remonter en haut"}
        </button>
      </div>
    </div>
  );
}
