import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { loadDossierAF, saveDossierAF, sendDossierAF } from 'api/dossierAPI'
import { DISPOSITIF_TUT } from 'api/references'
import { mapDTOToDossierAF, mapDossierAFToDTO } from 'pages/dossier/metadata/DossierMapping'
import { employeurMetadata } from 'pages/dossier/metadata/EmployeurMetadata'
import { DOCNAME_DPC, DOCTYPE_DPC, IgnoredFieldMetadata, ObjectMetadata, override, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA, SECTION_MODULE_OF, moduleAgoraFields, moduleFields } from 'pages/dossier/metadata/ModulesMetadata'
import { salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import { ArrayFieldMetadata, ObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierModulesEditForm } from 'pages/dossier/DossierModulesEditForm'
import { DossierModulesElements, dossierModulesInitializer } from 'pages/dossier/elements/DossierModulesElements'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { DossierSalariesEditForm } from 'pages/dossier/DossierSalariesEditForm'
import { dossierMultiSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import { DossierSalariesTable } from 'pages/dossier/DossierSalariesTable'
import { exportDossier } from 'pages/dossier/ExportDossierDPC'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import { AppType } from 'slices/contextSlice'
import { DossierAF, DossierSalarie, createDossierAF, createDossierModuleAgora, createDossierSalarie } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'
import { createCerfaDpc } from 'pages/dossier/cerfa/CerfaDpc'


function createDossierTUTModule() { return { ...createDossierModuleAgora(), 
  
}}
type DossierTUTModule = ReturnType<typeof createDossierTUTModule>


type DossierTUT= DossierAF<DossierSalarie, DossierTUTModule>
function createDossierTUT(): DossierTUT { return {
    ...createDossierAF(DISPOSITIF_TUT),
}}


const api: DossierAPI<DossierTUT> = {
    create: createDossierTUT,
    createModule: createDossierTUTModule,
    createSalarie: createDossierSalarie,
    load: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        return mapDTOToDossierAF(MetadataTUT, dossierDTO)
    },
    loadWithPieces: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        return mapDTOToDossierAF(MetadataTUT, dossierDTO)
    },
    save: async (dossier: DossierTUT) => {
        const dossierDTO = mapDossierAFToDTO(MetadataTUT, dossier)
        const savedDossierDTO = await saveDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataTUT, savedDossierDTO)
    },
    send: async (dossier: DossierTUT) => {
        const dossierDTO = mapDossierAFToDTO(MetadataTUT, dossier)
        const sentDossierDTO = await sendDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataTUT, sentDossierDTO)
    },
    postSend: async (dossier: DossierTUT) => {
        await postSendDossier(MetadataTUT, dossier)
    },
}

const TUTModuleMetadata: ObjectMetadata<DossierTUTModule> = {
    formationInterne: override(moduleFields.formationInterne, { section: SECTION_MODULE_OF }),
    cfa: override(moduleFields.cfa, { props: { da: true }, section: SECTION_MODULE_OF }),
    subrogation: override(moduleFields.subrogation, { section: SECTION_MODULE_OF }),
    intitulePrecis: moduleFields.intitulePrecis,
    dureeTotale: moduleFields.dureeTotale,
    dateDebutFormation: moduleFields.dateDebutFormation,
    dateFinFormation: moduleFields.dateFinFormation,
    coutPedagogique: moduleFields.coutPedagogique,
    typeFormation: moduleFields.typeFormation,
    commentaire: moduleFields.commentaire,
    formationCertifianteAgora: moduleAgoraFields.formationCertifianteAgora,
    categorieActionAgora: moduleAgoraFields.categorieActionAgora,
    objectifAgora: moduleAgoraFields.objectifAgora,
}

const TUTSalarieMetadata: ObjectMetadata<DossierSalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: salarieFields.prenom,
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    handicap: salarieFields.handicap,
    statut: salarieFields.statut,
    contrat: salarieFields.contrat,
    niveauFormation: salarieFields.niveauFormation,
    dateEntree: salarieFields.dateEntree,
    categorieAction: salarieFields.categorieAction,
    heures: salarieFields.heures,
    heuresFoad: salarieFields.heuresFoad,
    coefficient: salarieFields.coefficient,
    autreMetier: salarieFields.autreMetier,
    situationHandicap: IgnoredFieldMetadata(),
    heuresHTT: IgnoredFieldMetadata(),
    heuresAfest: IgnoredFieldMetadata(),
}

type DossierTUTLight = Omit<DossierTUT, 'pieces'>
const TUTMetadataLight: ObjectMetadata<DossierTUTLight> = {
    employeur: ObjectFieldMetadata(employeurMetadata),
    modules: ArrayFieldMetadata(TUTModuleMetadata),
    salaries: ArrayFieldMetadata(TUTSalarieMetadata),
    signature: ObjectFieldMetadata(signatureMetadata),
}
const TUTMetadata = TUTMetadataLight as ObjectMetadata<DossierTUT>

export const MetadataTUT = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_TUT,
    title: "Dépôt dossier Formation tuteur de l'alternance",
    pathname: '/nouveau-dossier-tut',
    steps: [
        { 
            title: 'Employeur',
            sections: [],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            formContent: DossierEmployeurElements,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Formation',
            sections: [SECTION_MODULE_AGORA],
            formContent: DossierModulesElements,
            editForm: DossierModulesEditForm,
            recap: RecapModules,
            initializer: dossierModulesInitializer,
            yopPath: 'modules',
            options: {
                minElements: 1,
            },
           
        }, {
            title: 'Salariés',
            sections: [],
            preFormContent: DossierSalariesTable,
            editForm: DossierSalariesEditForm,
            recap: RecapSalaries,
            initializer: dossierMultiSalariesInitializer,
            yopPath: 'salaries',
            options: {
                noFormHeading: true,
                minElements: { value: 1, message: 'Vous devez ajouter au moins un salarié' },
                noSaving: true,
                forceValidate: (dossier) => dossier.salaries?.length >= 1,
            },
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: DossierPiecesElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: TUTMetadata,
    exportProps: [{
        exportDossier: async (metadata, dossier) => createCerfaDpc(exportDossier(metadata, dossier)),
        documentType: DOCTYPE_DPC,
        documentName: DOCNAME_DPC,
    }],
})

