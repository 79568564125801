import { useCallback, useEffect, useRef, useState } from 'react'

export class ApiState<T> {
    pending = true
    value = null as (T | null)
    error = null as (string | null)
}

export function apiStateSuccess<T>(value: T): ApiState<T> {
    return { pending: false, value, error: null }
}

export function apiStateError<T>(error: any): ApiState<T> {
    return { pending: false, value: null, error }
}

export function useSimpleApiState<T>(apiFunctionCaller: (() => Promise<T>), initialState: Partial<ApiState<T>> = {}): ApiState<T> {
    const [state, setState] = useState({ ...new ApiState<T>(), ...initialState})

    useEffect(() => {
        if (!state.pending)
            setState({ ...state, pending: true })
        
        apiFunctionCaller()
            .then(value => {
                setState({ pending: false, value: value, error: null })
            })
            .catch(error => {
                let message = "Une erreur inattendue s'est produite"
                if (error.isAxiosError)
                    message = error?.response?.data?.message ?? message
                setState({ pending: false, value: null, error: message })
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return state
}

export default function useApiState<T, P extends any[]>(apiFunction: ((...args: P) => Promise<T>), initialState: Partial<ApiState<T>> | null = null)
    : [ApiState<T>, ((...args: P) => Promise<T>), React.Dispatch<React.SetStateAction<ApiState<T>>>] {
    
    const [state, setState] = useState(initialState ? { ...new ApiState<T>(), ...initialState } : new ApiState<T>())
    
    const apiFunctionRef = useRef<((...args: P) => Promise<T>)>()
    useEffect(() => {
        apiFunctionRef.current = (...args: P) => {
            if (!state.pending)
                setState({ ...state, pending: true })

            const promise = apiFunction(...args)
    
            promise.then(value => {
                if (apiFunctionRef.current)
                    setState({ pending: false, value: value, error: null })
            }).catch(error => {
                if (apiFunctionRef.current) {
                    let message = "Une erreur inattendue s'est produite"
                    if (error.isAxiosError)
                        message = error?.response?.data?.message ?? message
                    setState({ pending: false, value: null, error: message })
                }
            })
            
            return promise
        }

        return () => {
            apiFunctionRef.current = undefined
        }
    }, [state, setState, apiFunction])

    const withApiFunction = useCallback((...args: P) => {
        if (apiFunctionRef.current)
            return apiFunctionRef.current!(...args)
        return Promise.reject('Component unmounted!')
    }, [])

    return [state, withApiFunction, setState]
}
