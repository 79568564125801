import { AtlasFieldText } from 'atlas-ds'

import { formatDate, formatSuffix } from 'components/format/Format'

type PseudoTextFieldProps = {
    label: string
    type?: string
    value?: any
    tooltip?: string | JSX.Element
    suffix?: string
}

export default function PseudoTextField(props: PseudoTextFieldProps) {

    let value = props.value
    if (props.type === 'date') {
        if (value instanceof Date && isNaN(value.getTime()))
            value = null
        else
            value = formatDate(value)
    }

    return <AtlasFieldText
        name={ `pseudo-${props.label}` }
        label={ props.label }
        value={ value }
        tooltipContent={ props.tooltip }
        suffix={ typeof props.suffix === 'string' ? formatSuffix(props.suffix, true) : props.suffix }
        disabled
    />
}
