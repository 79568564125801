import { BaseTextField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldText, AtlasFieldTextType } from "atlas-ds"
import { formatSuffix } from "components/format/Format"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"

export interface TextFieldProps<T extends object> extends BaseFieldProps<string, T> {
    maxLength?: number
    autoComplete?: string
    uppercase?: boolean
    type?: AtlasFieldTextType
    placeholder?: string
    trim?: boolean
}

const asNumber = (value: any) => {
    return typeof value === 'number' ? value : undefined
}

export default function TextField<T extends object>(props: TextFieldProps<T>) {
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)
    const render = useRender()

    const toModelValue = (value: string) => {
        if (props.uppercase) {
            value = value.toUpperCase()
        }
        if (props.trim) {
            value = value.trim()
        }
        return value
    }

    return <AtlasFieldText
        type={ props.type }
        name={ props.name }
        label={ props.label }
        placeholder={ props.placeholder }
        autoComplete={ props.autoComplete }
        maxLength={ props.maxLength ?? asNumber(fieldState.constraints.max) }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        suffix={ props.suffix ? formatSuffix(props.suffix, true) : undefined }
        error={ fieldState.error }
        disabled={ props.disabled }
        inputConstructor={(inputProps) => <BaseTextField
            { ...inputProps }
            onChange={ props.onChange }
            onBlur={ props.onBlur }
            render={ render }
            toModelValue={ toModelValue }
        />}
    />
}
