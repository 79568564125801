import { pdf } from '@react-pdf/renderer'
import { AtlasButton } from 'atlas-ds'
import { useIdcc } from 'components/reform/inputs/utils/useIdcc'
import { saveAs } from 'file-saver'
import Infos from 'pages/dossier/cerfa/Infos'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useState } from 'react'
import { BaseDossier } from 'slices/dossierCommon'
import { exportDossierChanges } from './ExportDossierChanges'

interface ImprimerDocProps<D extends BaseDossier> {
    dossier: D | null
    dossierId: string
    metadata: FormMetadata<D>
    index?: number
}

export function ImprimerDoc<D extends BaseDossier>(props: ImprimerDocProps<D>) {

    const refsIdcc = useIdcc()
    const [generating, setGenerating] = useState(false)

    const exportProp = props.metadata.exportProps[props.index ?? 0]

    const generatePdf = async () => {
        setGenerating(true)
        try {
            const doc = await exportProp.exportDossier(props.metadata, props.dossier!)
            const instance = pdf(doc)
            const blob = await instance.toBlob()
            saveAs(blob, `dossier-${exportProp.documentType.toLowerCase()}-${props.dossierId}.pdf`)
        }
        finally {
            setGenerating(false)
        }
    }

    return (
        <AtlasButton level={2} disabled={ generating || refsIdcc == null } onClick={generatePdf}>
            {generating ? 'Téléchargement...' : (exportProp.label ?? 'Télécharger la demande de prise en charge')}
        </AtlasButton>
    )
}

export function ImprimerChanges<D extends BaseDossier>(props: ImprimerDocProps<D>) {

    const refsIdcc = useIdcc()
    const [generating, setGenerating] = useState(false)

    const generatePdf = async () => {
        setGenerating(true)
        try {
            const doc = await exportDossierChanges(Infos, props.metadata, props.dossier!, props?.dossier?.signature.nom ?? '')
            const instance = pdf(doc)
            const blob = await instance.toBlob()
            saveAs(blob, `dossier-modifications-${props.dossierId}.pdf`)
        }
        finally {
            setGenerating(false)
        }
    }

    return (
        <AtlasButton level={2} disabled={ generating  || refsIdcc == null } onClick={generatePdf}>
            {generating ? 'Téléchargement...' : 'Télécharger les modifications'}
        </AtlasButton>
    )
}
