import { RootState } from 'app/rootReducer'
import { MySteps } from 'atlas-ds'
import Button from 'components/reform/Button'
import { FormMetadata, FormStep } from 'pages/dossier/metadata/FormMetadata'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BaseDossier } from 'slices/dossierCommon'

const campusUrl = import.meta.env.REACT_APP_CAMPUS_URL

export function DossierACNCampusElements<D extends BaseDossier>(props: { metadata: FormMetadata<D> }) {
    return  (<>
        <div>Vous allez être redirigé vers notre site campusAtlas afin de choisir votre action de formation et sélectionner la session.</div>
        <div>&nbsp;</div>
    </>)
}

export function DossierACNCampusButtons() {
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const [ spinning, setSpinning ] = useState(false)

    const redirectToCampus = () => {
        setSpinning(true)
        setTimeout(() => {
            window.location.href = `${campusUrl}/catalogue/${entreprise?.Branche__c}?entreprise=${entreprise?.SIRET__c}`
        }, 25)
    }

    return <Button onClick={ redirectToCampus } spinner={{spinning: spinning}}>Continuer</Button>
}

export function DossierACNCampusStep(props: { step: FormStep<any> }) {
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const redirectToCampus = () => {
        window.location.href = `${campusUrl}/catalogue/${entreprise?.Branche__c}?entreprise=${entreprise?.SIRET__c}`
    }

    return <MySteps.Item
        key={ props.step.title }
        active={ true }
        disabled={ false }
        onClick={ e => { e.preventDefault(); redirectToCampus() } }
    >{ props.step.title }</MySteps.Item>
}