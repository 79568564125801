import { ChildCategorie, getQuestionCategories } from 'api/aideContactsAPI';
import useApiState from 'api/useApiState';
import { dispatchAppEvent } from 'app/events';
import { AtlasLoading, MyCategories } from 'atlas-ds';
import { useEffect, useState } from 'react';
import { aideContactCategorieChangeEvent } from '../pages/aide-contacts/AideContacts';

export const categoriesSelectAllButtonEvent = 'AideContacts:Categories:SelectAllButton'

export function Categories(props: { sourceCategorie?: string }) {
    const [{ value: categories }, withGetQuestionCategories] = useApiState(getQuestionCategories)
    const [activeCategory, setActiveCategory] = useState<string | null>(null)

    useEffect(() => {
        if (props.sourceCategorie && props.sourceCategorie.length > 0) {
            withGetQuestionCategories(props.sourceCategorie)
        }
    }, [props.sourceCategorie, withGetQuestionCategories])

    const onClickCategorie = (item: ChildCategorie | null) => {
        setActiveCategory(item?.name ?? null)
        dispatchAppEvent(aideContactCategorieChangeEvent, item)
    }

    return <AtlasLoading loading={!categories?.childCategories}>
        <MyCategories
            selectAllButtonLabel="Tous les sujets"
            categories={categories?.childCategories.map(
                category => ({...category, active: category.name === activeCategory})
            ) ?? []}
            onSelect={category => onClickCategorie(category as ChildCategorie)}
        />
    </AtlasLoading>
}
