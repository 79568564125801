import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import { RequiredString } from 'components/yop/constraints'
import SpecialiteFormationField from 'components/fields/SpecialiteFormationField'
import RecapRefField from 'pages/dossier/recap/RecapRefField'
import ExportRefField from 'pages/dossier/export/ExportRefField'
import { Reference, References } from 'api/referencesAPI'
import CerfaRefField from 'pages/dossier/cerfa/CerfaRefField'

export function RequiredSpecialiteFormationFieldMetadata(settings: SettingsFieldMetadata<string, {
    options?: (refs: References) => Reference[]
}> = {}) {
    return createMetadata(
        {
            props: {
                options: refs => refs.REF_SPECIALITE_FORMATION,   // Pour recap/export
            },
            yop: RequiredString,
            input: SpecialiteFormationField,
            recap: RecapRefField,
            export: ExportRefField,
            cerfa: CerfaRefField,
        },
        settings
    )
}
