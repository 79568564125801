import { DossierDetail } from 'api/dossierAPI'
import { parseDate } from 'components/format/Format'
import { nil } from 'slices/dossierCommon'
import { BaseFormMetadata } from './FormMetadata'
import { References } from 'api/referencesAPI'

export function createRuptureInfo(dossier: DossierDetail) { return {
    dateDebutContrat: parseDate(dossier.DateDebutContrat__c),
    dateFinContrat: parseDate(dossier.DateFinContrat__c),
    motif: nil as string,
    dateRupture: nil as Date,
} }
export type RuptureInfo = ReturnType<typeof createRuptureInfo>

export interface RuptureFormMetadata<R extends RuptureInfo = RuptureInfo> extends BaseFormMetadata<R> {
    nomDispositif: string
    showInfoStep: string
    disabled?: (dossier: DossierDetail) => boolean
    createRuptureInfo?: (dossier: DossierDetail) => R
    postRupture: (numeroDossier: string, refs: References, info: R, blob: Blob) => Promise<any>
}
