import { useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasInfo } from 'atlas-ds'
import { BaseDossier } from 'slices/dossierCommon'

interface ModeleInfo<D extends BaseDossier> {
    when: (dossier?: D) => boolean
    label: string
    href: string
}

interface ModelePieceProps<D extends BaseDossier> {
    titre?: string
    modeles: ModeleInfo<D>[]
}

export default function ModelePiece<D extends BaseDossier>(props: ModelePieceProps<D>) {
    const context = useFormContext<D>()

    if (!context.values)
        return null

    const modeles = props.modeles.filter(m => m.when(context.values!))
    return modeles.length > 0 ? <AtlasInfo title={ props.titre ?? "Modèles de documents" }>
        Pour vous aider, nous mettons à votre disposition { modeles.length > 1 ? 'les modèles suivants' : 'le modèle suivant' }:
        <ul>
            { modeles.map(modele => <li key={ modele.label }><a href={ modele.href } rel="noopener noreferrer" target="_blank">{ modele.label }</a></li>)}
        </ul>
    </AtlasInfo> : null
}
