import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ObjectMetadata, requiredIf, override, IgnoredFieldMetadata, FormMetadata } from "pages/dossier/metadata/FormMetadata"
import { tuteurFields, tuteur18ans } from "pages/dossier/metadata/TuteursMetadata"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { DossierCAPP, DossierCAPPMaitre, v10required } from "./CAPPModel"
import { addYears, isAfter } from "date-fns"
import { TestValidationContext } from '@dsid-opcoatlas/yop'
import { TYPE_BRANCHE_BUREAU_ETUDE, TYPE_BRANCHE_QUALITE_AIR, TYPE_BRANCHE_AGENTS_GENERAUX_ASSURANCE, TYPE_BRANCHE_COURTIERS_ASSURANCE, TYPE_BRANCHE_ASSURANCE, TYPE_BRANCHE_ASSISTANCE } from "api/references"
import { entreprise } from "api/entreprise"
import { UseFormReturn } from "@dsid-opcoatlas/reform"
import { getTuteurs } from "api/commonAPI"
import { parseApiDate } from "api/util"
import { BaseDossierTuteur } from "slices/dossierCommon"


const CONTROLES_AFMA: Record<string, (m: DossierCAPPMaitre) => boolean | null> = {
    [TYPE_BRANCHE_BUREAU_ETUDE]: m => m.formation || m.exerciceFonction2Annees,
    [TYPE_BRANCHE_QUALITE_AIR]: m => m.formation || m.exerciceFonction2Annees,
    [TYPE_BRANCHE_AGENTS_GENERAUX_ASSURANCE]: m => m.formation,
    [TYPE_BRANCHE_COURTIERS_ASSURANCE]: m => m.formation,
    [TYPE_BRANCHE_ASSURANCE]: m => m.formation,
    [TYPE_BRANCHE_ASSISTANCE]: m => m.formation,
}

function dateNaissanceTest(ctx: TestValidationContext<Date, DossierCAPPMaitre, DossierCAPP>) {
    const dateDebutContrat = ctx.root?.contrat?.dateDebut
    if (dateDebutContrat != null) {
        const dateNaissance = ctx.value
        const date18ans = addYears(dateNaissance, 18)
        if (isAfter(date18ans, dateDebutContrat))
            return ctx.createError("Le maître d'apprentissage n'a pas l'âge requis pour encadrer un(e) apprenti(e). Il doit être âgé de 18 ans minimum à la date de début du contrat.")
    }
    return true
}

async function searchMaitre(form: UseFormReturn<DossierCAPP>, recherche: string, metadata: FormMetadata<DossierCAPP>): Promise<BaseDossierTuteur[]> {
    const idEntreprise = form.values?.employeur?.ParentId
    if (idEntreprise == null)
        return []

    const tuteurs = await getTuteurs(idEntreprise, recherche)
    return tuteurs.map(tuteur => ({ 
        ...metadata.api.createTuteur!(),
        IdContact: tuteur.Id,
        nomNaissance: tuteur.LastName,
        nom: tuteur.LastName,
        prenom: tuteur.FirstName,
        sexe: tuteur.Salutation,
        email: tuteur.Email,
        dateNaissance: parseApiDate(tuteur.DateNaissance__c as any) as Date,
    }))
}

export const CAPPMaitre1Metadata: ObjectMetadata<DossierCAPPMaitre> = {
    nomNaissance: tuteurFields.nomNaissance(searchMaitre),
    nom: tuteurFields.nom,
    prenom: override(tuteurFields.prenom, { props: { label: "Premier prénom selon l’état civil" }})
        .mutate(yop => yop.matches(/^[\s-]*[^\s-]*([\s-]*[^\s-]*)?[\s-]*$/, 'Merci de saisir uniquement le premier prénom')),
    sexe: tuteurFields.sexe,
    dateNaissance: tuteurFields.dateNaissance.mutate(yop => yop
        .max(tuteur18ans, "Le maître d'apprentissage doit être majeur")
        .test(dateNaissanceTest)
        .ignoredIf(ctx => ctx.parent == null)
    ),
    email: requiredIf(tuteurFields.email, v10required),
    plusHautDiplome: requiredIf(tuteurFields.plusHautDiplome, v10required),
    niveauPlusHautDiplome: requiredIf(tuteurFields.niveauPlusHautDiplome, v10required),
    emploiOccupe: tuteurFields.emploiOccupe,
    employeurDirigeant: override(tuteurFields.employeurDirigeant, { props: {
        label: "Maître d'apprentissage employeur dirigeant",
    }}),
    anneesExperience: tuteurFields.anneesExperience,
    salariesSuivis: override(tuteurFields.salariesSuivis, { props: {
        label: "Nombre d’alternants actuellement suivis par le maître d’apprentissage",
    }}),
    formation: override(tuteurFields.formation, { props: {
        label: "Le maître d'apprentissage a-t-il suivi ou va-t-il suivre une formation de maître d'apprentissage ?",
    }}),
    dateFormation: tuteurFields.dateFormation,
    priseEnChargeFonctionTutorale: override(tuteurFields.priseEnChargeFonctionTutorale, { props: {
        label: "Souhait de bénéficier de l'aide à la fonction de maître d'apprentissage ?",
        tooltip: TOOLTIPS.capp.maitre.aideFonction,
    }}).required().mutate(yop => yop.test<DossierCAPPMaitre, DossierCAPP>(ctx => {
        if (ctx.value !== true || ctx.parent == null || ctx.root?.employeur == null)
            return true
        
        const afma1 = ctx.parent.employeurDirigeant !== true || ctx.root.employeur.effectifSalarie <= 11
        const controleBrancheAFMA = CONTROLES_AFMA[entreprise()!.Branche__c!]
        const afma2 = controleBrancheAFMA ? controleBrancheAFMA(ctx.parent) : true
        if (!afma1 || !afma2)
            return ctx.createError("Le maître d’apprentissage ne remplit pas les conditions pour bénéficier de l’aide à la fonction de maître d’apprentissage")
    
        return true
    })),
    exerciceFonction2Annees: tuteurFields.exerciceFonction2Annees,
}

export const CAPPMaitre2Metadata: ObjectMetadata<DossierCAPPMaitre> = {
    nomNaissance: override(tuteurFields.nomNaissance(searchMaitre), { mapping: simpleMapping('NomNaissanceTuteur2__c') }),
    nom: override(tuteurFields.nom, { mapping: simpleMapping('NomTuteur2__c') }),
    prenom: override(tuteurFields.prenom, { 
        props: { 
            label: "Premier prénom selon l’état civil" 
        }, 
        mapping: simpleMapping('PrenomTuteur2__c') 
    }).mutate(yop => yop.matches(/^[\s-]*[^\s-]*([\s-]*[^\s-]*)?[\s-]*$/, 'Merci de saisir uniquement le premier prénom')),
    sexe: override(tuteurFields.sexe, { mapping: simpleMapping('SexeTuteur2__c') }),
    dateNaissance: override(tuteurFields.dateNaissance, { mapping: simpleMapping('DateNaissanceTuteur2__c') }).mutate(yop => yop
        .max(tuteur18ans, "Le maître d'apprentissage doit être majeur")
        .test(dateNaissanceTest)
        .ignoredIf(ctx => ctx.parent == null)
    ),
    email: requiredIf(override(tuteurFields.email, { mapping: simpleMapping('EmailTuteur2__c')} ), v10required),
    plusHautDiplome: requiredIf(override(tuteurFields.plusHautDiplome, {
        mapping: simpleMapping("DiplomePlusEleveObtenuTuteur2__c")
    }), v10required),
    niveauPlusHautDiplome: requiredIf(override(tuteurFields.niveauPlusHautDiplome, {
        mapping: simpleMapping("NiveauPlusEleveObtenuTuteur2__c")
    }), v10required),
    emploiOccupe: override(tuteurFields.emploiOccupe, { mapping: simpleMapping('EmploiTuteur2__c') }),
    employeurDirigeant: IgnoredFieldMetadata(),
    anneesExperience: IgnoredFieldMetadata(),
    salariesSuivis: IgnoredFieldMetadata(),
    formation: IgnoredFieldMetadata(),
    dateFormation: IgnoredFieldMetadata(),
    priseEnChargeFonctionTutorale: IgnoredFieldMetadata(),
    exerciceFonction2Annees: IgnoredFieldMetadata(),
}
