import { PieceDTO } from 'api/documentAPI'
import useApiState from "api/useApiState"
import useEventListener from "app/events"
import { AtlasHeading, AtlasLoading, MyTable } from "atlas-ds"
import State from "components/State"
import TelechargerDoc from 'components/docs/TelechargerDoc'
import { formatDate } from "components/format/Format"
import Feature from 'components/joyride/feature/Feature'
import { useEffect } from "react"
import { Step } from 'react-joyride'
import { useLocation } from "react-router-dom"
import DetailSupprimerDoc from './DetailSupprimerDoc'


interface DetailDocumentsProps {
    idParent: string | null
    transmissionEnCours?: boolean
    name: string
    documentsRefreshEvent: string
    getDocuments: (idParent: string) => Promise<PieceDTO[]>
    downloadDoc: (doc: PieceDTO) => Promise<void>
    deleteDoc?: (idParent: string, idDoc: string) => Promise<string[]>
    depotPieces?: (documents: PieceDTO[]) => JSX.Element | undefined
}

export default function DetailDocuments(props: DetailDocumentsProps) {

    const [{ pending, value: documents, error }, withGetDocuments] = useApiState(props.getDocuments)
    useEffect(() => {
        if (props.idParent)
            withGetDocuments(props.idParent)
    }, [props.idParent, withGetDocuments])
    
    const location = useLocation()

    const documentsRefreshEvent = props.documentsRefreshEvent
    useEventListener(documentsRefreshEvent, (_) =>
        withGetDocuments(props.idParent!)
    )

    const documentsAjoutes = documents?.filter(d => d.fichier) ?? []

    const documentsActions = (doc: PieceDTO) => {
        const actions = [<TelechargerDoc downloadDoc={() => props.downloadDoc(doc)} key="telechargerDoc" />]
        if (props.deleteDoc && canDelete(doc)) {
            actions.push(<DetailSupprimerDoc idParent={ props.idParent } doc={ doc } documentsRefreshEvent={props.documentsRefreshEvent} 
                deleteDocument={ props.deleteDoc } key="supprimerDoc" />)
        }
        return actions
    }

    const canDelete = (doc: PieceDTO): boolean => {
        return !!doc.fichier && !!doc.ContentVersionId
    }

    const step: Step = {
        target: '#documentsBoutonAjout',
        placement: 'right',
        content: 'Veuillez vérifier le courrier de relance et ajouter les pièces incorrectes ou manquantes'
    }

    return (
        <>
            {
                ((location.state as any)?.showIncomplete && documents)
                && <Feature step={step} alwaysShow={true} />
            }

            <AtlasHeading tag="h2" size="m" button={ documents ? props.depotPieces?.(documents) : undefined }>
                Documents
            </AtlasHeading>
            <AtlasLoading loading={pending}>
                <MyTable
                    caption="Documents"
                    rows={ documentsAjoutes ?? [] }
                    error={ error ?? undefined }
                    rowActions={ documentsActions }
                    emptyText="Aucun document"
                    columns={[
                        {
                            id: "type",
                            label: "Type document"
                        },
                        {
                            id: "statut",
                            label: "Statut",
                            value: (row: any) => <State type="bill" id={ canDelete(row) ? '4' : '3' }>
                                { canDelete(row) ? 'Non\xa0transmis' : 'Transmis' }
                            </State>
                        },
                        {
                            id: "fichier",
                            label: "Nom document",
                            wrap: true
                        },
                        {
                            id: "dateAjout",
                            label: "Date d'ajout",
                            value: (doc: any) => doc.dateAjout ? formatDate(doc.dateAjout) : 'En attente'
                        }
                    ]}
                />
            </AtlasLoading>
        </>
    )
}
