import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierProAContrat, ProAContratMetadata } from 'pages/dossier-proa/ProAFormMetadata'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'

export default function EmploiOccupe({ contrat }: { contrat: DossierProAContrat }) {
  return (
    <View style={[styles.section, styles.pageLastSection]}>
      <View style={styles.sectionHeader}>
        <Text>L'EMPLOI OCCUPÉ</Text>
        <Text style={styles.sectionSubheader}>
          (rappel des éléments constitutifs du contrat de travail)
        </Text>
      </View>
      <View style={styles.sectionContent}>
        <Text>Emploi occupé dans le cadre du contrat de travail (intitulé précis) :</Text>
        <CerfaElement metadata={ ProAContratMetadata.emploiOccupe } value={ contrat.emploiOccupe } />
        <Text> </Text>

        <Text>Classification de l'emploi dans la convention collective :{' '}
          <CerfaElement metadata={ ProAContratMetadata.classificationEmploi } value={ contrat.classificationEmploi } />
        </Text>

        <View style={styles.cols}>
          <View style={styles.tripleCol}>
            <Text>Niveau :{' '}
              <CerfaElement metadata={ ProAContratMetadata.niveauEmploi } value={ contrat.niveauEmploi } />
            </Text>
          </View>
          <View style={styles.tripleCol}>
            <Text>Coefficient hiéarchique :{' '}
              <CerfaElement metadata={ ProAContratMetadata.coefficientHierarchique } value={ contrat.coefficientHierarchique } />
            </Text>
          </View>
          <View style={styles.tripleColLast}>
            <Text>Date de début d'exécution du contrat de travail :</Text>
            <CerfaElement metadata={ ProAContratMetadata.dateDebutContrat } value={ contrat.dateDebutContrat } />
          </View>
        </View>
      </View>
    </View>
  );
}
