import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { DataSummariesExtension } from 'components/DataSummaries'
import { relanceMessagesEntreprise } from 'pages/detail-dossier/relanceff/entreprise'
import { relanceMessagesPrestataire } from 'pages/detail-dossier/relanceff/prestataire'
import DetailFacture, { DetailFactureExtension } from 'pages/detail-facture/DetailFacture'
import { DetailAlertes } from 'pages/relanceff/Alertes'
import FacturesStatistiques from './FacturesStatistiques'

const facturesModule = (mesFactures: any, labelStats?: string) => {
    return {
        id: 'mes-factures',
        routes: [
            <AppRoute key="factures1" path="/mes-factures" component={mesFactures} label="Factures" menuIcon="factures" />,

            <AppRoute key="factures2" path="/detail-facture/:idfacture">
                <DetailFacture />
            </AppRoute>,
        ],
        extensions: {
            dataSummaries: {
                stats:  <FacturesStatistiques key="facturesStats" label={ labelStats } />,
            },
            entreprise: {
                DetailFacture: {
                    alertes: <DetailAlertes key="relanceFF" label="Cette facture" messages={ relanceMessagesEntreprise } />,
                },
            } as DetailFactureExtension,
            prestataire: {
                DetailFacture: {
                    alertes: <DetailAlertes key="relanceFF" label="Cette facture" messages={ relanceMessagesPrestataire } />,
                },
            } as DetailFactureExtension,
        } as DataSummariesExtension,

    } as AppModule
}

export default facturesModule