import { getFieldState, useFormContext, useRender } from '@dsid-opcoatlas/reform'
import { IAllProps } from '@tinymce/tinymce-react'
import { MyFieldEditor } from 'atlas-ds'

interface HtmlEditorFieldProps extends IAllProps {
    name: string
    label?: string
    tooltip?: string | JSX.Element
}

export default function HtmlEditorField(props: HtmlEditorFieldProps) {
    const render = useRender()
    const context = useFormContext<any>()
    const fieldState = getFieldState<string>(context, props.name)

    const onEditorChange = (value: string) => {
        context.setValue(props.name, value)
        context.validateAt(props.name, false) && render()
    }

    return <MyFieldEditor
        apiKey='vge8x8cgplfpmmzxfrz0q6a9ook8j187s9gdq8jb3wphzcv6'
        name={ props.name }
        label={ props.label }
        error={ fieldState.error }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        initialValue={ fieldState.value }
        onEditorChange={ onEditorChange }

    />
}

