import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { AtlasFlex, AtlasLink, MyAppAccess } from 'atlas-ds'
import Link from 'components/Link'
import { AppType } from 'slices/contextSlice'
import { AccueilCompteExtension } from '../accueil/AccueilCompte'
import { AttacherUnPrestataire } from './AttacherUnPrestataire'
import DetailPrestataire from './DetailPrestataire'
import { MesPrestataires } from './MesPrestataires'

const mesPrestatairesModule = {
    id: 'mes-prestataires',
    routes: (_, appState) => [
        ...appState?.appTypes?.includes(AppType.Prestataire) ? [
            <AppRoute exact key="mes-prestataires" path="/mes-prestataires" label="Mes prestataires" menuIcon="dossiers">
                <MesPrestataires />
            </AppRoute>,
            <AppRoute exact key="mes-prestataires-detail" path="/mes-prestataires/detail/:idEntreprise">
                <DetailPrestataire />
            </AppRoute>,
        ] : [],
        <AppRoute exact key="mes-prestataires-attacher" path="/mes-prestataires/attacher" label="Attacher un prestataire">
            <AttacherUnPrestataire />
        </AppRoute>,
    ],
    extensions: {
        accueilCompte: {
            appAccess: (
                <MyAppAccess
                    key="access.prestataire"
                    app="prestataire"
                    keyPoints={[
                        "Suivre mes formations",
                        "Présenter mon entreprise",
                        "Voir mes règlements"
                    ]}
                    link={<AtlasLink href="/?contexte=prestataire">Accéder à myAtlas Prestataire</AtlasLink>} />
            ),
            attach: (
                <AtlasFlex key="attach.entreprise" alignItems="baseline" wrap columnGap="m">
                    <p>Vous représentez également une entreprise ?</p>
                    <Link level={3} to="/mes-entreprises/attacher" icon="plus">
                        Attacher une entreprise
                    </Link>
                </AtlasFlex>
            ),
        },
    } as AccueilCompteExtension,
} as AppModule

export default mesPrestatairesModule