import { AtlasValue, AtlasValues } from "atlas-ds"

export function CompanySummary(props: {
    nom?: string
    siret?: string | null
    siren?: string | null
    adresse?: string
}) {

    return <AtlasValues label={props.nom ?? ''}>
        <AtlasValues.Grid>
            <AtlasValue label={props.siren ? "SIREN" : "SIRET"}>
                {props.siren ? props.siren : props.siret}
            </AtlasValue>
            <AtlasValue label="Adresse">{props.adresse}</AtlasValue>
        </AtlasValues.Grid>
    </AtlasValues>
}
