import { DossierDetail } from 'api/dossierAPI'
import { AtlasButton } from 'atlas-ds'
import ChangerPeriodeModal from 'components/ChangerPeriodeModal'
import { useState } from 'react'
import { DossierActionProps } from './DetailDossierContrat'
import { checkDetailAction } from 'pages/detail-common/Detail'

interface DossierChangerPeriodeProps extends DossierActionProps {
    getDateFinValidite?: (dossier: DossierDetail) => Promise<string>
}

export default function DossierChangerPeriode(props: DossierChangerPeriodeProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const [showChangerPeriode, setShowChangerPeriode] = useState<boolean>(false)

    return <>
        <AtlasButton id="report" level={ 2 } onClick={() => setShowChangerPeriode(true)}>
            Reporter les dates de formation
        </AtlasButton>

        { showChangerPeriode &&
        <ChangerPeriodeModal show={ true } dossier={ dossier } getDateFinValidite={ props.getDateFinValidite }
            close={() => setShowChangerPeriode(false)} />
        }
    </>
}
 