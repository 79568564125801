import Arial from './arial.ttf';
import ArialItalic from './arial-italic.ttf';
import ArialBold from './arial-bold.ttf';
import ArialBoldItalic from './arial-bold-italic.ttf';
import Inconsolata from './inconsolata.ttf';
import OpenSans from './opensans.ttf';
import OpenSansSemiBold from './opensans-semibold.ttf';
import Palanquin from './palanquin.ttf';

const fonts = [
    {
        family: 'Arial',
        fonts: [
            { src: Arial },
            { src: ArialItalic, fontStyle: 'italic' },
            { src: ArialBold, fontWeight: 700 },
            { src: ArialBoldItalic, fontStyle: 'italic', fontWeight: 700 },
        ],
    },
    {
        family: 'Inconsolata',
        fonts: [{ src: Inconsolata }],
    },
    {
        family: 'OpenSans',
        fonts: [{ src: OpenSans }, { src: OpenSansSemiBold, fontWeight: 700 }],
    },
    {
        family: 'Palanquin',
        fonts: [{ src: Palanquin }],
    },
];

export default fonts;
