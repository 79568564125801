import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import AideContacts from './AideContacts'
import ServicesAssistance from './services/ServicesAssistance'
import ServicesConseil from './services/ServicesConseil'
import ServicesCotisation from './services/ServicesCotisation'
import ServicesFacture from './services/ServicesFacture'


const aideModule = (title: string, services: string[]) => {
    return {
        id: 'aide',
        routes: [
            <AppRoute key="aide2" path="/aide-contacts" render={ () => <AideContacts title={title} /> } label={title} menuIcon="aide" />,
            ...services.includes("facture") ? [<AppRoute key="aide3" path="/services-facture" render={()=> <ServicesFacture />} label="Suivi de facture" />] : [],
            ...services.includes("assistance") ? [<AppRoute key="aide4" path="/services-assistance" render={()=> <ServicesAssistance /> } label="Assistance service en ligne" />] : [],
            ...services.includes("conseil") ? [<AppRoute key="aide5" path="/services-conseil" render={() =><ServicesConseil />} label="Conseils et informations sur vos dossiers" />] : [],
            ...services.includes("cotisation") ? [<AppRoute key="aide6" path="/services-cotisation" render={()=><ServicesCotisation />} label="Questions sur vos cotisations" />] : [],
        ],
    } as AppModule
}

export default aideModule