import { Form, SetValueOptions, useForm } from "@dsid-opcoatlas/reform"
import { Service, findAnswers } from "api/aideContactsAPI"
import useApiState from "api/useApiState"
import { useAppContext } from 'app/useAppContext'
import { AtlasColumns, AtlasHeading, AtlasLoading, AtlasSpacer, AtlasToastsItemType, MyCard, MyContainer, MyForm } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import Breadcrumb from 'components/Breadcrumb'
import PageHeader from "components/PageHeader"
import { Questions } from "components/Questions"
import useDebounce from "components/debounce/useDebounce"
import Button from "components/reform/Button"
import RadioField from "components/reform/inputs/RadioField"
import SelectField from "components/reform/inputs/SelectField"
import TextField from "components/reform/inputs/TextField"
import { OptionalString, RequiredString, optionalObject } from "components/yop/constraints"
import { useEffect, useState } from "react"
import DossierSelector from "./DossierSelector"
import FactureSelector from "./FactureSelector"
import NousEcrireModal from "./NousEcrireModal"

export interface ServicesTemplateProps {
    service?: Service
}

export interface ServiceSearch {
    contexte: string | null
    objet: string | null
    numero: string | null
    sujet: string | null
}

export default function ServicesTemplate(props: ServicesTemplateProps) {

    const [{ pending: answersPending, value: answers }, withFindAnswers] = useApiState(findAnswers, { pending: false })
    const [sujet, setSujet, sujetDebounced] = useDebounce('')
    const appContext = useAppContext()
    const [showNousEcrireModal, setShowNousEcrireModal] = useState(false)
    const [referenceId, setReference] = useState('')

    const initialValues: ServiceSearch = {
        contexte: null,
        objet: null,
        numero: null,
        sujet: null
    }


    const validationSchema = optionalObject<ServiceSearch>({
        contexte: RequiredString,
        objet: RequiredString,
        numero: OptionalString
            .requiredIf<ServiceSearch>(context => ['Dossier', 'Facture'].includes(context.parent?.objet ?? '')),
        sujet: RequiredString.min(3).max(150)
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            context.setSubmitting(false)
        },
    })

    useEffect(() => {
        if ((sujet?.length ?? 0) >= 3)
            withFindAnswers({ search: sujet! })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sujetDebounced])

    const clearObjet = () => {
        form.setValue("objet", null, SetValueOptions.Untouch | SetValueOptions.Validate)
    }

    const EventMessage = (text: string, status: AtlasToastsItemType) => {
        ToastService.showToast({
            type: status,
            label: "Nous écrire",
            content: text
        })
    }

    const handleReference = (Id: string) => {
        setReference(Id)
    }

    return (<>
        <PageHeader
            breadcrumb={<Breadcrumb
                parts={[{ href: '/aide-contacts', label: 'Aide' }]}
                currentPageLabel={props.service?.label || ''}
            />}
        >{props.service?.label || ''}</PageHeader>

        <AtlasColumns>
            <div>
                <MyContainer>
                    <Form context={form} noValidate>
                        <MyForm>
                            {props.service && <>
                                <RadioField
                                    name="contexte"
                                    label="Pour vous accompagner au mieux et nous écrire, pouvez-vous nous préciser le contexte de votre demande&nbsp;?"
                                    options={props.service.type.map(type => ({ value: type.ID, label: type.label }))}
                                    onChange={clearObjet}
                                />

                                <SelectField
                                    name="objet"
                                    label="Veuillez préciser l'objet de votre demande"
                                    options={
                                        (props.service.type.find(type => type.ID === form.values?.contexte)?.sousType ?? []).map(sousType => (
                                            { value: sousType.ID, label: sousType.label }
                                        ))
                                    }
                                />
                            </>}

                            {form.values?.objet === 'Dossier' &&
                                <DossierSelector name="numero" getIdDossier={handleReference} />
                            }
                            {form.values?.objet === 'Facture' &&
                                <FactureSelector name="numero" getIdFacture={handleReference} />
                            }

                            <TextField
                                name="sujet"
                                label="Sujet"
                                onChange={value => setSujet(value ?? '')}
                                placeholder="Indiquez-nous votre problématique…"
                            />
                        </MyForm>
                    </Form>
                </MyContainer>

                <AtlasSpacer size="xxl" />

                {(answersPending || answers) && <>
                    <AtlasHeading tag="h2" size="m">Les informations suivantes pourraient vous intéresser :</AtlasHeading>
                    <Questions answers={answers} loading={answersPending} />
                    <AtlasSpacer size="xxl" />
                </>}

                <div>
                    <AtlasHeading tag="h2" size="m">Vous avez toujours une question ?</AtlasHeading>
                    <p>Si vous n’avez pas trouvé une réponse à votre question avec notre assistance et nos guides, vous pouvez toujours nous contacter
                        en sélectionnant la thématique adéquate. Nous nous efforcerons de vous apporter une réponse rapidement.</p>
                </div>

                <AtlasSpacer />

                <Button
                    onClick={() => setShowNousEcrireModal(true)}
                    disabled={(form.values?.objet && form.values?.contexte && form.values?.sujet) ? false : true}
                >Nous écrire</Button>

                {showNousEcrireModal && <NousEcrireModal message={EventMessage} close={() => setShowNousEcrireModal(false)} infoNousEcrire={
                    {
                        Subject: sujet,
                        Priority: "Moyenne",
                        // ContactId: profil?.Contact.Id ?? '',
                        // AccountId: profil?.Contact.AccountId ?? '',
                        Origin: appContext?.appInfo?.options?.typeOrigine ?? '',
                        Type: form.values?.contexte ?? '',
                        SousType__c: form.values?.objet ?? '',
                        Reference: referenceId ?? ''

                    }
                } />}
            </div>

            <div>
                <AtlasHeading tag="h2" size="m">Vos contacts</AtlasHeading>
                <MyContainer colored>
                    <AtlasLoading loading={!props.service}>
                        <MyCard
                            icon="handshake"
                            title={props.service?.serviceTel || ''}
                        >
                            {props.service?.nomConseiller && props.service.nomConseiller !== "POLE SCM" && (
                                <p>{props.service.nomConseiller}</p>
                            )}
                            <p>Tél : {props.service?.numeroTel}</p>
                        </MyCard>
                    </AtlasLoading>
                </MyContainer>
            </div>
        </AtlasColumns>
    </>
    )
}