import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatRef, formatRefReduit } from 'components/format/Format'
import { useSelector } from 'react-redux'
import { references } from 'api/references'

interface ExportRefFieldProps {
    label: string
    exportLabel?: string
    cell?: boolean
    row?: boolean
    options: (refs: References, parent?: any, root?: any) => Reference[] | undefined
    value: string
    parent?: any
    root?: any
    reduit?: boolean
}

export default function ExportRefField(props: ExportRefFieldProps) {

    const refs = references()

    const options = refs ? (props.options(refs, props.parent, props.root) ?? []) : []
    const value = props.reduit ? formatRefReduit(options, props.value) : formatRef(options, props.value)

    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.exportLabel ?? props.label,
        value: value,
    }} />
}
