import { OidcConfiguration } from "@axa-fr/react-oidc"

const server = import.meta.env.REACT_APP_OIDC_SERVER_URL

export const oidcConfiguration: OidcConfiguration = {
    client_id: import.meta.env.REACT_APP_OIDC_CLIENT_ID,
    redirect_uri: server + '/authentication/callback',
    silent_redirect_uri: server + '/authentication/silent_callback',
    scope: 'openid profile email',
    authority: import.meta.env.REACT_APP_OIDC_AUTHORITY,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
}