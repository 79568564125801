import { getFieldState, SetValueOptions, useFormContext } from "@dsid-opcoatlas/reform"
import { Commune, getReferencesCommune } from "api/referencesAPI"
import { MyForm } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import AutocompleteBaseField from "./AutocompleteBaseField"
import { set } from "date-fns"
import { unaccentLowerCase } from "components/fields/FieldUtil"
import { PartialNullable } from "utilityTypes"

interface CodePostalCommuneFieldProps<T extends object> extends BaseFieldProps<Commune, T> {
    codePostalName?: string
    communeName?: string
    departementName?: string
    regionName?: string
}

export default function CodePostalCommuneField<T extends object>(props: CodePostalCommuneFieldProps<T>) {

    const form = useFormContext<T>()

    const codePostalPath = props.name + (props.codePostalName ?? ".codePostal")
    const communePath = props.name + (props.communeName ?? ".commune")
    const codePostalFieldState = getFieldState<string | null>(form, codePostalPath)
    const communeFieldState = getFieldState<string | null>(form, communePath)

    const renderCommune = (commune: PartialNullable<Commune>) => {
        return commune.CdCodex__c && commune.Nom__c ? commune.CdCodex__c + ' - ' + commune.Nom__c : ""
    }

    const search = async (text: string) => {
        text = text.trim()
        const codePostal = /^(\d{3,5})/.exec(text)?.[1]
        if (codePostal != null) {
            text = unaccentLowerCase(text)
            return (await getReferencesCommune(codePostal)).filter(commune => unaccentLowerCase(renderCommune(commune)).startsWith(text))
        }
        return await getReferencesCommune(text)
    }

    return (
        <MyForm.Field full>
            <AutocompleteBaseField<Commune>
                label={ props.label ?? "Code postal et commune" }
                defaultValue={ renderCommune({ CdCodex__c: codePostalFieldState.value, Nom__c: communeFieldState.value }) }
                error={ codePostalFieldState.error ?? communeFieldState.error }
                required={ codePostalFieldState.constraints.required || communeFieldState.constraints.required }
                disabled={ props.disabled }
                placeholder="Rechercher par code postal ou commune (3 caractères minimum)"
                onBlur={ value => {
                    form.setValue(codePostalPath, value === "" ? null : form.getValue(codePostalPath), SetValueOptions.Touch)
                    form.setValue(communePath, value === "" ? null : form.getValue(communePath), true)
                }}
                
                search={ search }
                
                optionFor={ commune => ({
                    id: commune.Id,
                    content: renderCommune(commune)
                })}
                onSelect={ commune => {
                    form.setValue(codePostalPath, commune.CdCodex__c)
                    form.setValue(communePath, commune.Nom__c, true)
                    if (props.departementName)
                        form.setValue(props.name + props.departementName, commune.Departement__c)
                    if (props.regionName)
                        form.setValue(props.name + props.regionName, commune.Region__c)
                }}
            />
        </MyForm.Field>
    )
}
