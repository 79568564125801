import { ServiceStrategiePlus } from 'api/strategiePlusAPI'
import { AtlasFlex, AtlasHeading, AtlasLoading, MyCard, MyContainer } from 'atlas-ds'
import Link from 'components/Link'


export interface ItemServiceProps {
    name: string,
    body: string,
    to: string,
}

export interface ServiceProps {
    service: ServiceStrategiePlus[],
    loading: boolean
}

export function ServicesStrategiePlus(props: ServiceProps) {
    return <div>
        <AtlasHeading tag="h2" size="m">
            Les prestations mobilisables avec mon compte Stratégie&nbsp;+
        </AtlasHeading>
        <MyContainer colored>
            <AtlasLoading loading={ props.loading }>
                <AtlasFlex gap="m" column>
                    { props.service?.map((item, index) =>
                        <MyCard
                            key={ index }
                            title={ item.nom }
                            icon="handshake"
                            link={<Link href={ item.lien } external>En savoir plus</Link>}
                        >{ item.description }</MyCard>
                    )}
                </AtlasFlex>
            </AtlasLoading>
        </MyContainer>
    </div>
}
