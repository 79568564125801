import { SchemaForType } from "@dsid-opcoatlas/yop"
import { OptionalBoolean, OptionalDate, OptionalNumber, OptionalPhone, OptionalString, RequiredString } from "components/yop/constraints"
import PhoneField from "components/reform/inputs/PhoneField"
import RadioBooleanField from "components/reform/inputs/RadioBooleanField"
import RadioField, { RadioOption } from "components/reform/inputs/RadioField"
import NumberField from "components/reform/inputs/NumberField"
import SelectField, { SelectOption } from "components/reform/inputs/SelectField"
import TextField from "components/reform/inputs/TextField"
import { applyValidationConstraints, ClassConstructor, classFieldsMetadataDecorator, ClassOverride, createValidationSchema, fieldMetadataDecorator, FieldMetadataDecoratorProps, FormContextProperty, FormFieldContext, ValidationContextProperty, ValidationFieldMetadataDecoratorProps } from "./engine"
import CodePostalCommuneField from "components/reform/inputs/CodePostalCommuneField"
import DateField from "components/reform/inputs/DateField"
import CurrencyField from "components/reform/inputs/CurrencyField"
import NumeroDossierApprentissageField from "components/fields/NumeroDossierApprentissageField"
import RadioRefField from "components/reform/inputs/RadioRefField"
import { Reference, References } from "api/referencesAPI"
import SelectRefField from "components/reform/inputs/SelectRefField"
import { rest } from "lodash-es"

export function overrides<T extends ClassConstructor>(fields: ClassOverride<T>) {
    return classFieldsMetadataDecorator<T>(fields)
}

export function array<T extends object>(props: ValidationFieldMetadataDecoratorProps<(T | null)[] | null> & {
    elements: ValidationFieldMetadataDecoratorProps<T | null> & { type: ClassConstructor<T> }
}) {
    const { elements, ...arrayValidation } = props
    const { type, ...elementValidation } = elements
    let schema = createValidationSchema(props.elements.type, elementValidation)
    schema = applyValidationConstraints(schema, arrayValidation)
    return fieldMetadataDecorator({ array: type, schema })
}

export function object<T extends object>(props: ValidationFieldMetadataDecoratorProps<T | null> & {
    type: ClassConstructor<T>
    dto?: string
    overrides?: ClassOverride<ClassConstructor<T>>
}) {
    const { type, ...validation } = props
    let schema = createValidationSchema(props.type)
    schema = applyValidationConstraints(schema, validation)
    return fieldMetadataDecorator({
        object: type,
        schema: schema as SchemaForType<T | null>,
        dto: props.dto,
        overrides: props.overrides as ClassOverride<ClassConstructor<T | null>>
    })
}

export function input(value: ((props: any) => JSX.Element)) {
    return fieldMetadataDecorator<any>({ input: value })
}

export function dto(value: string) {
    return fieldMetadataDecorator<any>({ dto: value })
}

export function schema<T>(value: SchemaForType<T> | (() => SchemaForType<T>)) {
    return fieldMetadataDecorator<T>({ schema: value })
}

export function label<R extends object = any>(value: FormContextProperty<string | JSX.Element, R>) {
    return fieldMetadataDecorator<any>({ label: value })
}

export function tooltip<R extends object = any>(value: FormContextProperty<string | JSX.Element, R>) {
    return fieldMetadataDecorator<any>({ tooltip: value })
}

export function disabled<R extends object = any>(value: FormContextProperty<boolean, R>) {
    return fieldMetadataDecorator<any>({ disabled: value })
}

export function visible<R extends object = any>(value: FormContextProperty<boolean, R>) {
    return fieldMetadataDecorator<any>({ visible: value })
}

export function required<T, P extends object = any, R extends object = any, C = any>(value?: ValidationContextProperty<boolean, T, P, R, C>) {
    return fieldMetadataDecorator<any>({ required: value ?? true })
}

export function ignored<T, P extends object = any, R extends object = any, C = any>(value?: ValidationContextProperty<boolean, T, P, R, C>) {
    return fieldMetadataDecorator<any>({ ignored: value ?? true })
}

export function textField(props?: FieldMetadataDecoratorProps<string | null>) {
    return fieldMetadataDecorator<string | null>({
        input: TextField,
        schema: OptionalString,
        ...props
    })
}

export function numberField(props?: FieldMetadataDecoratorProps<number | null>) {
    return fieldMetadataDecorator<number | null>({
        input: NumberField,
        schema: OptionalNumber,
        ...props
    })
}

export function currencyField(props?: FieldMetadataDecoratorProps<number | null>) {
    return fieldMetadataDecorator<number | null>({
        input: CurrencyField,
        schema: OptionalNumber,
        ...props
    })
}

export function dateField(props?: FieldMetadataDecoratorProps<Date | null>) {
    return fieldMetadataDecorator<Date | null>({
        input: DateField,
        schema: OptionalDate,
        ...props
    })
}

export function numeroDossierApprentissageField(props?: FieldMetadataDecoratorProps<string | null>) {
    return fieldMetadataDecorator<string | null>({
        input: NumeroDossierApprentissageField,
        label: props?.label ?? "Numéro dossier apprentissage",
        schema: OptionalString,
        ...props
    })
}

export function durationField(props?: FieldMetadataDecoratorProps<number | null>) {
    return fieldMetadataDecorator<number | null>({
        input: props => <NumberField { ...props } fractionDigits={ 1 } minimumFractionDigits={ 0 } />,
        schema: OptionalNumber,
        ...props
    })
}

export function heuresField(props?: FieldMetadataDecoratorProps<number | null>) {
    return durationField({ ...props, suffix: "heure(s)" })
}

export function phoneField(props?: FieldMetadataDecoratorProps<string | null>) {
    return fieldMetadataDecorator<string | null>({
        input: PhoneField,
        schema: OptionalPhone,
        label: "Téléphone",
        ...props
    })
}

export function codePostalCommuneField(props?: FieldMetadataDecoratorProps<string | null>) {
    
    function removeLastSegment(path: string) {
        const lastDot = path.lastIndexOf('.')
        return lastDot >= 0 ? path.substring(0, lastDot) : path
    }

    return fieldMetadataDecorator<string | null>({
        input: (props?: any) => CodePostalCommuneField({ ...props, name: removeLastSegment(props.name) }),
        schema: RequiredString
            .max(10)
            .matches(/^[0-9]{5}$/, "Le code postal doit être composé de 5 chiffres"),
        label: props?.label ?? "Code postal et commune",
        ...props
    })
}

export function radioBooleanField(props?: FieldMetadataDecoratorProps<boolean | null>& {
    labelYes?: FormContextProperty<string | JSX.Element>
    labelNo?: FormContextProperty<string | JSX.Element>
}) {
    return fieldMetadataDecorator<boolean | null>({ 
        input: RadioBooleanField,
        schema: OptionalBoolean,
        ...props
    })
}

function radioAnyField<T, C>(props?: FieldMetadataDecoratorProps<T> & {
    schema?: SchemaForType<T> | (() => SchemaForType<T>)
    options?: RadioOption[] | ((context: C) => RadioOption[] | undefined | null)
    convertOptionValue?: (value: string) => T
}) {
    return fieldMetadataDecorator<T>({ 
        input: RadioField,
        contextConsumer: (context: C) => {
            if (typeof props?.options === "function")
                return { options: props.options(context) ?? [] }
        },
        ...props
    })
}

export function radioStringField<C = any>(props?: FieldMetadataDecoratorProps<string | null> & {
    options?: RadioOption[] | ((context: C) => RadioOption[] | undefined | null)
}) {
    return radioAnyField<string | null, C>({ 
        schema: OptionalString,
        ...props
    })
}

export function radioNumberField<C = any>(props?: FieldMetadataDecoratorProps<number | null> & {
    options?: RadioOption[] | ((context: C) => RadioOption[] | undefined | null)
}) {
    return radioAnyField<number | null, C>({ 
        schema: OptionalNumber,
        convertOptionValue: value => {
            let converted: number | null = parseInt(value)
            if (isNaN(converted))
                converted = null
            return converted
        },
        ...props
    })
}

export function radioRefField(props?: FieldMetadataDecoratorProps<string | null> & {
    options: (refs: References, context: FormFieldContext) => Reference[]
}) {
    return fieldMetadataDecorator<string | null>({ 
        input: props => {
            const { context, options, ...rest } = props
            return <RadioRefField { ...rest } options={ refs => options(refs, context)} />
        },
        schema: OptionalString,
        ...props
    })
}


export function selectRefField(props?: FieldMetadataDecoratorProps<string | null> & {
    sorted?: boolean
    options: (refs: References, context: FormFieldContext) => Reference[]
}) {
    return fieldMetadataDecorator<string | null>({ 
        input: props => {
            const { context, options, ...rest } = props
            return <SelectRefField { ...rest } options={ refs => options(refs, context)} />
        },
        schema: OptionalString,
        ...props
    })
}


export function selectField<C = any>(props?: FieldMetadataDecoratorProps<any> & {
    options?: SelectOption[] | ((context: C) => SelectOption[] | undefined | null)
}) {
    return fieldMetadataDecorator<any>({ 
        input: SelectField,
        contextConsumer: (context: C) => {
            if (typeof props?.options === "function")
                return { options: props.options(context) ?? [] }
        },
        ...props
    })
}
