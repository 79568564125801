import { UseFormReturn } from "@dsid-opcoatlas/reform"
import { InputHTMLAttributes } from "react"

export const join = (...args: any[]) => {
    return args.filter(Boolean).join(' ')
}

export interface InputSelection {
    start: number | null
    end: number | null
}

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'name' | 'value' | 'checked' | 'disabled' | 'onChange' | 'onBlur'>

export interface BaseFieldProps<FieldValueType, FormObjectType extends object> {
    name: string
    label: string | JSX.Element

    section?: string
    parentPath?: string
    parent?: any
    root?: any

    tooltip?: string | JSX.Element
    suffix?: string
    disabled?: boolean
    link?: JSX.Element
    button?: JSX.Element

    onChange?: (value: FieldValueType | null, form: UseFormReturn<FormObjectType>, parentPath?: string) => void
    onBlur?: (value: FieldValueType | null, form: UseFormReturn<FormObjectType>, parentPath?: string, change?: boolean, editMode?: boolean) => void

    error?: string | JSX.Element
    onErrorLink?: { [key: string]: () => unknown }

    editMode?: boolean
}
