import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import cerfaImage from 'pages/dossier/cerfa/img/cerfa.png'
import ministryImage from './logo-v4.png'
import styles from 'pages/dossier/cerfa/global.styles'
import Employeur from './parts/Employeur'
import Salarie from './parts/Salarie'
import Tuteurs from './parts/Tuteurs'
import Contrat from './parts/Contrat'
import Formation from './parts/Formation'
import Opco from './parts/Opco'
import { DossierVAEI } from 'pages/dossier-vaei/VAEIModel'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'

export default function CerfaVAEI<D extends DossierVAEI>({ metadata, dossier }: { metadata: FormMetadata<D>, dossier: D }) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image source={ministryImage} style={{ width: 80, marginLeft: 20, marginBottom: 20 }}></Image>
          <View style={styles.titles}>
            <Text style={styles.title}>Contrat de professionnalisation</Text>
            <Text style={styles.subtitle}>
              (art. L. 6325-1 à L. 6325-24 du code du travail)
            </Text>
            <Text style={styles.notice}>
              (Lire ATTENTIVEMENT la notice Cerfa avant de remplir ce document)
            </Text>
          </View>
          <View style={styles.cerfa}>
            <Image source={cerfaImage} style={styles.cerfaImage}></Image>
            <Text style={styles.cerfaReference}>N° 12434*04</Text>
          </View>
        </View>
        <View style={styles.main}>
          <Employeur employeur={dossier.employeur} dossier={ dossier }></Employeur>
          <Salarie salarie={dossier.salarie}></Salarie>
          <Tuteurs dossier={dossier}></Tuteurs>
        </View>
        <View style={styles.main}>
          <Contrat metadata={ metadata } dossier={ dossier } contrat={ dossier.contrat }></Contrat>
          <Formation dossier={dossier}></Formation>
          <Opco />
          <View style={styles.footer}>
            <Text style={[styles.boldItalic, styles.xSmall]}>
              Pour remplir le contrat et pour plus d’informations sur le
              traitement des données reportez-vous à la notice.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
