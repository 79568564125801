import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatSuffix } from 'components/format/Format'

interface ExportNumberFieldProps {
    label: string
    cell?: boolean
    row?: boolean
    value: string | number | null | undefined
    suffix?: string
}

export default function ExportNumberField(props: ExportNumberFieldProps) {
    if (props.value == null) {
        return props.cell ? <Value cell value={{ value: '' }} /> : null
    }

    const suffix = props.suffix === '%' ? '%' : props.suffix ? ' ' + props.suffix : ''
    const value = props.value || props.value === 0 ? props.value + formatSuffix(suffix, (((typeof props.value === 'string' && parseFloat(props.value.toString()) >= 2) || (typeof props.value === 'number' && props.value >= 2)))) : ''

    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.label,
        value,
    }} />
}
