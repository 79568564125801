import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatCurrency } from 'components/format/Format'

interface ExportCurrencyFieldProps {
    label: string
    value: string | number | undefined | null
    suffix?: string
    cell?: boolean
    row?: boolean
}

export default function ExportCurrencyField(props: ExportCurrencyFieldProps) {
    const suffix = props.suffix && props.suffix.startsWith("€") ? props.suffix.substring(1) : props.suffix

    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.label,
        value: formatCurrency(props.value, suffix),
    }} />
}
