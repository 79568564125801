import { AtlasInfo } from "atlas-ds"

type ErrorBannerProps = {
    title: string
    message?: string
}

export default function ErrorBanner(props: ErrorBannerProps) {
    return props.message ? <AtlasInfo type="error" title={ props.title }>
        { props.message }
    </AtlasInfo> : <></>
}
