
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasInfo } from 'atlas-ds'
import Link from 'components/Link'
import log from 'loglevel'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { parametresActions } from 'slices/parametresSlice'


interface PageAlertProps {
    config: string
}

export default function PageAlert(props: PageAlertProps) {
    const dispatch = useAppDispatch()

    const { bandeauxBancaires } = useSelector((state: RootState) => state.parametresState)
    useEffect(() => {
        if (!bandeauxBancaires) {
            switch(props.config) {
                case "BandeauxRefBancaires":
                    dispatch(parametresActions.loadBandeauxBancaires())
                    break
                default:
                    log.error("PageAlert config value not supported")
                    break
            }
        }
    }, [bandeauxBancaires, dispatch, props.config])

    return <>{ bandeauxBancaires?.map(bandeau =>
        <AtlasInfo
            key={ props.config + '.' + bandeau.id }
            type={ bandeau.type }
            title={ bandeau.titre }
            link={ bandeau.lien ? <Link to={ bandeau.lien }>{ bandeau.labelLien }</Link> : undefined }
        >
            <p>{ bandeau.contenu }</p>
            { bandeau.items && <ul>
                { bandeau.items.map((item, index) => <li key={props.config + '.' + bandeau.id + '.' + index}>
                    <strong>{ item.item }</strong> : { item.description }
                </li> )}
            </ul> }
        </AtlasInfo>
    ) }</>
}
