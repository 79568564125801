import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { postAcceptCgu } from "api/monCompteAPI"
import { ContactPatch, Profil, getProfil, getProfilServices, setContact } from "api/profilAPI"
import { findService } from 'api/references'
import { Service } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { AxiosError } from "axios"
import { AppType } from "./contextSlice"

export const SERVICE_ENTREPRISE = 'sel-gad-adh'
export const SERVICE_PRESTATAIRE = 'selof'
export const SERVICE_URL_INTERNE ='URL-INTERNE-FRONT'

interface ValidationErrors {
    message: string
    status: string
  }
  
interface ProfilApiState {
    // Profil utilisateur (dépendant du contexte)
    pending: boolean
    profil: Profil | null
    rejected: string | null

    // Services (indépendants du contexte)
    servicesPending: boolean
    services: Service[] | null
    appTypes: AppType[] | null
}

const loadProfil = createAsyncThunk('profil/get', 
    async (_,  { rejectWithValue }) => {
    try {
        const response = await getProfil()
        return response
    } catch (err:any) {
      
        let error : AxiosError<ValidationErrors> =err
       
        if (!error.response) {
            throw err
          }
          // We got validation errors, let's return those so we can reference in our component and set form errors
          return rejectWithValue(error.response.data)
    }
    }
)

const loadProfilServices = createAsyncThunk('profil/getServices', 
    async (_, thunkAPI) => {
        const data = await getProfilServices()
        const services = data.map(serviceId => findService(thunkAPI.getState() as RootState, serviceId)).filter(s => !!s)
        return services
    }
)

const saveProfil = createAsyncThunk('profil/save',
    async (contact: ContactPatch, thunkAPI) => {
        await setContact(contact)
        return await getProfil()
    }
)

const acceptCgu = createAsyncThunk('profil/acceptCgu',
    async () => {
        await postAcceptCgu()
        return await getProfil()
    }
)

const slice = createSlice({
    name: 'profil',
    initialState: { pending: false, profil: null, rejected: null } as ProfilApiState,
    reducers: {
        clearProfil: (state: ProfilApiState) => {
            state.pending = false
            state.profil = null
            state.rejected = null
        },
        clearServices: (state: ProfilApiState) => {
            state.servicesPending = false
            state.services = null
            state.appTypes = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadProfil.pending, (state: ProfilApiState, action: any) => {
                state.pending = true
            })
            .addCase(loadProfil.fulfilled, (state: ProfilApiState, action: any) => {
                
                state.profil = action.payload as Profil
                state.pending = false
                state.rejected = null
            })
            .addCase(loadProfil.rejected, (state: ProfilApiState, action: any) => {
                if (action.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                    state.rejected = 'Status : '+action.payload.status +'| Message : '+ action.payload.message
                  } else {
                    state.rejected = action.error.message
                  }
                
                state.profil = null
                state.pending = false
            })

        builder
            .addCase(loadProfilServices.pending, (state: ProfilApiState, action: any) => {
                state.servicesPending = true
            })
            .addCase(loadProfilServices.fulfilled, (state: ProfilApiState, action: any) => {
                const services = action.payload as Service[]
                state.services = services
                state.appTypes = []
                if (!!services.find(service => service.Id === SERVICE_ENTREPRISE))
                    state.appTypes.push(AppType.Entreprise)
                if (!!services.find(service => service.Id === SERVICE_PRESTATAIRE))
                    state.appTypes.push(AppType.Prestataire)
                state.servicesPending = false
            })
            .addCase(loadProfilServices.rejected, (state: ProfilApiState, action: any) => {
                state.services = null
                state.appTypes = null
                state.servicesPending = false
            })

        builder
            .addCase(saveProfil.pending, (state: ProfilApiState, action: any) => {
                state.pending = true
            })
            .addCase(saveProfil.fulfilled, (state: ProfilApiState, action: any) => {
                state.profil = action.payload as Profil
                state.pending = false
                state.rejected = null
            })
            .addCase(saveProfil.rejected, (state: ProfilApiState, action: any) => {
                state.rejected = action.error.message
                state.profil = null
                state.pending = false
            })

        builder
            .addCase(acceptCgu.pending, (state: ProfilApiState, action: any) => {
                state.pending = true
            })
            .addCase(acceptCgu.fulfilled, (state: ProfilApiState, action: any) => {
                state.profil = action.payload as Profil
                state.pending = false
                state.rejected = null
            })
            .addCase(acceptCgu.rejected, (state: ProfilApiState, action: any) => {
                state.rejected = action.error.message
                state.profil = null
                state.pending = false
            })
    }
})

export const profilSlice = {
    slice
}

export const profilActions = {
    clear: slice.actions.clearProfil,
    get: loadProfil,
    getServices: loadProfilServices,
    clearServices: slice.actions.clearServices,
    save: saveProfil,
    acceptCgu: acceptCgu
}
