import { AppModule, AppState, campusEnabled } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import GestionCompteEntreprise from './GestionCompteEntreprise'

const gestionCompteEntrepriseModule = () => {
    return {
        id: 'gestion-compte-entreprise',
        isEnabled: (appContext: AppState) => campusEnabled(appContext),
        routes: [
            <AppRoute key="gestion-compte-entreprise" path="/gestion-compte-entreprise" component={GestionCompteEntreprise} label="Gestion du compte OF Campus" />,
        ],
    } as AppModule
}

export default gestionCompteEntrepriseModule
