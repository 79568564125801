import { AtlasLoading } from "atlas-ds";

export interface MyNumberProps {
  /**
   * Le nombre
   */
  children: number | string;
}

/**
 * Un nombre
 */
export function MyNumber(props: MyNumberProps) {
  return (
    <div className="my-number">
      <AtlasLoading.Loader />
      <span className="my-number__content">{props.children}</span>
    </div>
  );
}
