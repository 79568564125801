import { Text, View } from '@react-pdf/renderer';
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierCPROSalarie } from 'pages/dossier-cpro/CPROModel';
import { CPROSalarieMetadata } from 'pages/dossier-cpro/CPROSalarieMetadata';
import CerfaAdresseField from 'pages/dossier/cerfa/CerfaAdresseField';
import CerfaElement from 'pages/dossier/cerfa/CerfaElement';

export default function Salarie({ salarie }: { salarie?: DossierCPROSalarie | null }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeaderV4}>LE SALARIÉ</Text>
      <View style={styles.sectionContent}>
        <Text style={styles.bold}>
          Nom de naissance du salarié :{' '}
          <CerfaElement metadata={ CPROSalarieMetadata.nomNaissance } value={ salarie?.nomNaissance } />
        </Text>
        <Text style={styles.bold}>
          Nom d'usage :{' '}
          <CerfaElement metadata={ CPROSalarieMetadata.nom } value={ salarie?.nom } />
        </Text>
        <Text style={styles.bold}>
          Le premier prénom du salarié selon l’état civil :{' '}
          <CerfaElement metadata={ CPROSalarieMetadata.prenom } value={ salarie?.prenom } />
        </Text>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Adresse du salarié :</Text>
            <CerfaAdresseField value={ salarie?.adresse } />
            <Text>
              Téléphone :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.telephone } value={ salarie?.telephone }/>
            </Text>
            <Text>Courriel :</Text>
            <CerfaElement metadata={ CPROSalarieMetadata.courriel } value={ salarie?.courriel }
              default="________________@________________"/>
            <Text>
              NIR du salarié* :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.nir } value={ salarie?.nir }/>
            </Text>
            <Text>
              Date de naissance :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.dateNaissance } value={ salarie?.dateNaissance }/>
            </Text>
            <Text>
              Sexe :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.sexe } value={ salarie?.sexe }/>
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              Département de naissance :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.departementNaissance } value={ salarie?.departementNaissance }/>
            </Text>
            <Text>
              Commune de naissance :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.communeNaissance } value={ salarie?.communeNaissance }/>
            </Text>
            <Text>
              Déclare bénéficier de la reconnaissance travailleur handicapé :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.handicap } value={ salarie?.handicap }/>
            </Text>
            <Text> </Text>
            <Text>
              Inscrit à Pôle Emploi :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.poleEmploi } value={ salarie?.poleEmploi }/>
            </Text>
            <Text>
              Si oui, numéro d'inscription :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.numeroPoleEmploi } value={ salarie?.numeroPoleEmploi }/>
            </Text>
            <Text>
              Durée :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.dureePoleEmploi } value={ salarie?.dureePoleEmploi }/>
            </Text>
            <Text> </Text>
            <Text>
              Situation avant ce contrat :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.situationAvantContrat } numberOnly value={ salarie?.situationAvantContrat }/>
            </Text>
            <Text>
              Type de minimum social, si bénéficiaire :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.typeMinimumSocial } numberOnly value={ salarie?.typeMinimumSocial }/>
            </Text>
            <Text>
              Diplôme ou titre le plus élevé obtenu :{' '}
              <CerfaElement metadata={ CPROSalarieMetadata.plusHautDiplome } numberOnly value={ salarie?.plusHautDiplome }/>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
