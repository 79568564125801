import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import RadioField, { RadioFieldProps } from "./RadioField"

interface RadioBooleanFieldProps<T extends object> extends BaseFieldProps<boolean, T> {
    labelYes?: string
    labelNo?: string
    reverse?: boolean
    full?: boolean
}

export default function RadioBooleanField<T extends object>(props: RadioBooleanFieldProps<T>) {

    const { labelYes, labelNo, ...otherProps } = props

    const options = [{
        value: "yes",
        label: labelYes ?? "Oui"
    }, {
        value: "no",
        label: labelNo ?? "Non"
    }]

    if (props.reverse)
        options.reverse()

    return (
        <RadioField
            { ...(otherProps as RadioFieldProps<T, boolean>) }
            options={ options }
            convertOptionValue={ (optionValue) => optionValue === 'yes' } />
    )
}
