import { AtlasButton, MyModal } from 'atlas-ds'

interface ConfirmModalProps {
    title: string
    confirmLabel?: string
    confirm: () => void
    cancelLabel?: string
    cancel: () => void
    children: React.ReactNode
}

export default function ConfirmModal(props: ConfirmModalProps) {

    return (
        <MyModal
            onClose={ props.cancel }
            label={ props.title }
            okButton={ <AtlasButton onClick={ props.confirm }>{ props.confirmLabel ?? "Confirmer" }</AtlasButton> }
            cancelButton={ <AtlasButton onClick={ props.cancel }>{ props.cancelLabel ?? "Annuler" }</AtlasButton> }>
            { props.children }
        </MyModal>
    )
}
