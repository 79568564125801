import { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import LastLocationContext, { LastLocationType } from './LastLocationContext'

let lastLocation: LastLocationType = null

interface Props extends RouteComponentProps {
    children: React.ReactNode
}

function LastLocationProvider(props: Props) {
    const [currentLocation, setCurrentLocation] = useState<LastLocationType>(null)

    useEffect(() => {
        if (currentLocation !== null && props.location.pathname !== currentLocation.pathname) {
            lastLocation = { ...currentLocation }
        }
        setCurrentLocation(props.location)
    }, [props.location])

    return (
        <LastLocationContext.Provider value={ lastLocation }>{props.children}</LastLocationContext.Provider>
    )
}

export default withRouter(LastLocationProvider)
