import { Form, UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasFlex, AtlasInfo, AtlasSpacer, MyForm, MyModal } from 'atlas-ds'
import Button from 'components/reform/Button'
import SelectField from 'components/reform/inputs/SelectField'
import { RequiredString, optionalObject } from 'components/yop/constraints'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DiagnosticUpdate, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { secteursActions } from 'slices/diagatlas/secteursSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import { nil } from 'slices/dossierCommon'
import DiagLayout from './DiagLayout'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


export default function MonDiagnosticEntreprise() {

    const dispatch = useAppDispatch()

    const { fulfilled } = useSelector((state: RootState) => state.diagSecteursState)
    const { diagnostic, saving } = useDiagnostic(1)
    const navigation = useDiagnosticNavigation()

    const [change, setChange] = useState(false)
    const [changeInitial, setChangeInitial] = useState(false)
 
    const secteurs = fulfilled ? [ ...fulfilled ] : null


    useEffect(() => { 
        if (!secteurs)
            dispatch(secteursActions.load())
        else
            secteurs.sort((s1, s2) => s1.Libelle === s2.Libelle ? 0 : (s1.Libelle < s2.Libelle ? -1 : 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const initialValues = {
        tailleEntreprise: (diagnostic?.tailleEntreprise ?? nil) as string,
        secteur: (diagnostic?.secteur ?? nil) as string,
    }

    const validationSchema = optionalObject<typeof initialValues>({
        tailleEntreprise: RequiredString,
        secteur: RequiredString,
    })

    const doCancel = (context: UseFormReturn<typeof initialValues>) => {
        setChangeInitial(false) 
        setChange(false)
        context.reset()
    }

    const doSubmit = (context: UseFormReturn<typeof initialValues>, reset: boolean) => {
        setChangeInitial(false)
        setChange(false)
        dispatch(diagnosticSliceActions.save({ diagnostic : { ...context.values, entrepriseId: diagnostic?.entrepriseId } as DiagnosticUpdate, reset }))
            .then(unwrapResult)
            .then(() => {
                navigation.navigateNext()
            })
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            if (diagnostic?.id && context.isDirty()) {
                setChange(true)
                context.setSubmitting(false)
            } else if (!diagnostic?.id && context.isDirty()) {
                setChangeInitial(true)
                context.setSubmitting(false)
            } else {
                doSubmit(context, false)
            }
        },
    })

    return <DiagLayout step={1} stepMax={diagnostic?.etape ?? 1}
        headingAlert={ <>
            <AtlasInfo title="Bienvenue sur votre diagnostic !">
                <p>5 étapes pour identifier les mutations des emplois et compétences au sein de votre entreprise et les actions à déployer en réponse à ces mutations.</p>
                <ol>
                    <li>Renseignez le profil de votre entreprise.</li>
                    <li>Indiquez-nous vos grands enjeux RH actuels.</li>
                    <li>Positionnez-vous sur les tendances d'évolution de vos activités.</li>
                    <li>Confirmez les métiers et compétences en mutation.</li>
                    <li>Construisez un plan d'action personnalisé.</li>
                </ol>
            </AtlasInfo>
            <AtlasSpacer size="xl" />
        </> }
        headingTitle="Taille d'entreprise et secteur d'activité"
        headingInfoBlock={ <AtlasFlex column gap="s">
            <p><strong>Confirmez ou ajustez le profil de votre entreprise.</strong></p>
            <p><i>Ces éléments impactent les prédictions de mutation de vos emplois et compétences.</i></p>
        </AtlasFlex> }>

        <Form context={form} autoComplete="off" noValidate disabled={form.submitting}>
            <MyForm>
                <MyForm.Grid>
                    <SelectField name="tailleEntreprise"
                        label="Taille de l'entreprise"
                        options={[ 
                            { value: 'TPE', label: 'TPE (-11 salariés)' },
                            { value: 'PME', label: 'PME (11 à 299 salariés)' },
                            { value: 'GE', label: 'GE (300 et + salariés)' },
                        ]}></SelectField>

                    <SelectField name="secteur"
                        label="Secteur d'activité de l'entreprise"
                        options={ (secteurs ?? [])
                            .map(s => ({ value: s.Id, label: s.Libelle })
                        ) }></SelectField>
                </MyForm.Grid>

                <p><small>* champs obligatoires</small></p>

                <MyForm.Actions>
                    <Button submit={true} disabled={ saving } spinner={{ spinning: saving }}>
                        Lancer le diagnostic
                    </Button>
                </MyForm.Actions>
            </MyForm>
        </Form>

        { changeInitial && <AskChangeInitialDiagnosticModal close={ () => doCancel(form) } next={ () => doSubmit(form, true) } /> }

        { change && <AskResetDiagnosticModal close={ () => doCancel(form) } next={ () => doSubmit(form, true) } /> }
    </DiagLayout>
}

export function AskChangeInitialDiagnosticModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Modification du profil Entreprise"
            okButton={ <Button onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button onClick={ props.close }>Annuler</Button> }
        >
            <p>Les modifications de taille et secteur d'entreprise impactent les résultats du diagnostic. Souhaitez-vous continuer?</p>
        </MyModal>
    )
}

export function AskResetDiagnosticModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Modification du profil Entreprise"
            okButton={ <Button onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button onClick={ props.close }>Annuler</Button> }
        >
            <p>Attention, la modification de ces paramètres va entraîner la réinitialisation des informations présentées dans les étapes suivantes.</p>
        </MyModal>
    )
}