import { AtlasValue, AtlasValues } from 'atlas-ds'
import { formatAdresse } from 'components/format/Format'
import { Adresse } from 'slices/dossierCommon'

interface RecapAdresseProps {
    parent?: { adresse?: Adresse }
}

export default function RecapAdresse(props: RecapAdresseProps) {
    const adresse = props.parent?.adresse

    if (adresse == null)
        return null

    return (
        <AtlasValues>
            <AtlasValues.Grid>
                <AtlasValue label="Appartement">
                    { adresse?.appartement }
                </AtlasValue>
                <AtlasValue label="Bâtiment">
                    { adresse?.batiment }
                </AtlasValue>
                <AtlasValue label="Adresse">
                    { formatAdresse(adresse, adresse?.codePostal, adresse?.commune) }
                </AtlasValue>
                <AtlasValue label="Lieu dit/BP">
                    { adresse?.complement }
                </AtlasValue>
            </AtlasValues.Grid>
        </AtlasValues>
    )
}
