import { AdresseEntreprise, deleteAdresseEntreprise } from 'api/profilAPI'
import { dispatchAppEvent } from 'app/events'
import { AtlasButton, MyModal } from 'atlas-ds'
import { useState } from 'react'

interface DeleteAdresseProps {
    adresse: AdresseEntreprise
    refreshEvent: string
    close: () => void
}

export default function DeleteAdresseModal(props: DeleteAdresseProps) {

    const [deletePending, setDeletePending] = useState<boolean>(false)

    const handleDelete = () => {
        setDeletePending(true)
        deleteAdresseEntreprise(props.adresse.IdHeroku!)
            .then(() => dispatchAppEvent(props.refreshEvent, "L'adresse a été supprimée avec succès"))
            .finally(() => {
                props.close()
            })
    }

    return <MyModal
        show
        label="Confirmation"
        isPending={deletePending}
        okButton={<AtlasButton onClick={handleDelete}>Confirmer</AtlasButton>}
        cancelButton={<AtlasButton onClick={props.close}>Annuler</AtlasButton>}
    >
        <p>Êtes-vous sûr de vouloir supprimer cette adresse ?</p>
    </MyModal>
}