import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import rootReducer, { RootState } from './rootReducer'

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: import.meta.env.REACT_APP_ALLOW_DECORATORS === "true" ? false : undefined,
    })
})

export const initialRootState = {
    ...store.getState()
}

// if (process.env.NODE_ENV === 'development' && module.hot) {
//     module.hot.accept('./rootReducer', () => {
//         const newRootReducer = require('./rootReducer').default
//         store.replaceReducer(newRootReducer)
//     })
// }

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
