import { combineReducers } from '@reduxjs/toolkit'

import { configSlice } from 'slices/configSlice'
import contextReducer, { setApp, setEnterprise } from 'slices/contextSlice'
import { profilSlice } from 'slices/profilSlice'
import { referencesSlice } from 'slices/referencesSlice'
import { referencesIdccSlice } from 'slices/referencesIdccSlice'
import { thematiquesCampusSlice } from 'slices/thematiquesCampusSlice'
import recherchesSliceReducer from 'slices/recherchesSlice'
import { organismesSlice } from 'slices/organismesSlice'
import historyStackReducer from 'slices/historySlice'
import { onboardingSlice } from 'slices/onboardingSlice'
import maintenanceSliceReducer from 'slices/maintenanceSlice'
import { initialRootState } from './store'
import { notificationsSlice } from 'slices/notificationsSlice'
import { delaisTraitementDossierSlice, delaisTraitementDossierPrestataireSlice } from 'slices/delaisTraitementSlice'
import dossierSlice from 'slices/dossierSlice'
import { refsBancairesSlice } from 'slices/refsBancairesSlice'
import { secteursSlice } from 'slices/diagatlas/secteursSlice'
import { diagnosticDocumentsSlice } from 'slices/diagatlas/diagnosticDocumentsSlice'
import diagnosticSlice from 'slices/diagatlas/diagnosticSlice'
import { parametresSlice } from 'slices/parametresSlice'

const appReducer = combineReducers({
    configState: configSlice.slice.reducer,
    contextState: contextReducer,
    delaisTraitementDossierPrestataireState: delaisTraitementDossierPrestataireSlice.reducer,
    delaisTraitementDossierState: delaisTraitementDossierSlice.reducer,
    diagnosticDocumentsState: diagnosticDocumentsSlice.slice.reducer,
    diagnosticState: diagnosticSlice.reducer,
    diagSecteursState: secteursSlice.slice.reducer,
    dossierState: dossierSlice.reducer,
    historyStackState: historyStackReducer,
    maintenanceState: maintenanceSliceReducer,
    notificationsState: notificationsSlice.slice.reducer,
    onboardingState: onboardingSlice.reducer,
    organismesState: organismesSlice.slice.reducer,
    parametresState: parametresSlice.slice.reducer,
    profilState: profilSlice.slice.reducer,
    recherchesState: recherchesSliceReducer,
    referencesIdccState: referencesIdccSlice.slice.reducer,
    referencesState: referencesSlice.slice.reducer,
    refsBancairesState: refsBancairesSlice.slice.reducer,
    thematiquesCampusState: thematiquesCampusSlice.slice.reducer,
})

type appReducerParams = Parameters<typeof appReducer>

const rootReducer = (state: appReducerParams[0], action: appReducerParams[1]) => {
    if (state && (
            (action.type === setApp.type.toString() && state.contextState?.app !== action.payload) ||
            (action.type === setEnterprise.type.toString() && state.contextState?.entreprise !== action.payload)
    )) {
        state = {
            ...initialRootState,
            configState: state.configState,
            profilState: state.profilState,
            referencesState: state.referencesState,
            referencesIdccState: state.referencesIdccState,
            contextState: state.contextState,
            onboardingState: state.onboardingState,
            maintenanceState: state.maintenanceState,
            notificationsState: state.notificationsState,
            delaisTraitementDossierState: state.delaisTraitementDossierState,
        } as appReducerParams[0]
    }

    return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer