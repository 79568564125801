import { MyForm, MyModal } from 'atlas-ds'
import { useLastLocation } from 'components/lastlocation'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'


interface AskCancelModalProps {
    dossierId?: string
    close: () => void
}

export default function AskCancelModal(props: AskCancelModalProps) {

    const history = useHistory()
    const lastLocation = useLastLocation()
    const [navPending, setNavPending] = useState(false)

    const handleAlert = () => {
        setNavPending(true)
        if (props.dossierId) {
            history.push(`/detail-dossier-contrat/${props.dossierId}`)
        } else {
            if (lastLocation && lastLocation?.pathname !== '/authentication') {
                history.goBack()
            } else {
                history.push('/mes-dossiers-contrats')
            }
        }
        setNavPending(false)
        props.close()
    }

    return <>
        <MyModal label="Modifications non sauvegardées"
            onClose={ props.close }
        >
            <form noValidate>
                <MyForm>
                    <p>Vous n'avez pas terminé la modification du dossier, si vous abandonnez maintenant vos corrections seront perdues.</p>

                    <MyForm.Actions>
                        <Button
                            destructive
                            onClick={handleAlert}
                            spinner={{spinning: navPending}}
                            disabled={navPending}
                        >
                            Abandonner
                        </Button>
                        <Button level={ 2 } onClick={props.close} disabled={navPending}>
                            Continuer la modification
                        </Button>
                    </MyForm.Actions>
                </MyForm>
            </form>
        </MyModal>
    </>
}
