import {
  AtlasButton,
  AtlasButtonProps,
  AtlasDetails,
  AtlasIcon,
  AtlasLinkProps,
} from "atlas-ds";
import classNames from "classnames";
import { useRef, useState } from "react";

export interface MyDropdownMenuProps {
  /**
   * Le nom du menu
   */
  label: string;
  /**
   * Une série de liens et/ou boutons contenus dans le menu
   */
  items: React.ReactElement<AtlasLinkProps | AtlasButtonProps>[];
  /**
   * Désactiver le menu
   */
  disabled?: boolean;
  /**
   * Remplacer l'élément d'ouverture par un élément sur mesure
   */
  opener?: React.ReactNode;
}

/**
 * Un menu déroulant avec un bouton d'ouverture
 */
export function MyDropdownMenu(props: MyDropdownMenuProps) {
  const [above, setAbove] = useState(false);
  const [left, setLeft] = useState(false);
  const detailsRef = useRef<HTMLDetailsElement>(null);

  const items = props.items.map((item) => ({
    ...item,
    props: {
      level: 3,
      icon: "arrow-right",
      ...item.props,
    },
  }));

  const onToggle = () => {
    if (detailsRef.current?.open) {
      const position = detailsRef.current.getBoundingClientRect();

      setAbove(position.top > window.innerHeight / 2);
      setLeft(position.left > window.innerWidth / 2);
    }
  };

  if (props.disabled) {
    return <AtlasButton disabled>{props.label}</AtlasButton>;
  }

  return (
    <AtlasDetails
      ref={detailsRef}
      className={classNames("my-dropdownMenu", {
        "my-dropdownMenu--above": above,
        "my-dropdownMenu--left": left,
      })}
      onToggle={onToggle}
    >
      <summary>
        {props.opener ?? (
          <div className="my-dropdownMenu__cta">
            <span>{props.label}</span>
            <AtlasIcon name="arrow-down" size="xs" />
          </div>
        )}
      </summary>
      <ul className="my-dropdownMenu__list">
        {items.map((item, index) => (
          <li key={`item-${index}`}>{item}</li>
        ))}
      </ul>
    </AtlasDetails>
  );
}
