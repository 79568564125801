import { Notification as NotificationModel } from "api/notificationAPI"
import { DISPOSITIF_ACN } from "api/references"
import { AtlasCampusLogo, MyNotifications } from "atlas-ds"
import State from "components/State"
import { intervalToDuration } from "date-fns"
import { Link } from "react-router-dom"

interface NotificationContent {
    message: JSX.Element | null
    link: string | null
    icon: string
}

const notificationDefaut = (notification: NotificationModel, isPrestataire: boolean) => {
    return {
        message: '',
        link: null,
        icon: 'file',
    }
}

interface NotificationHandlers {
    [index: string]: (notification: NotificationModel, isPrestataire: boolean) => NotificationContent
}

const notificationDossier = (notification: NotificationModel, isPrestataire: boolean) => {
    const notif = {
        message: null as JSX.Element | null,
        link: null as string | null,
        icon: 'dossiers',
    }

    if (!(isPrestataire && ['DossierIncomplet', 'DossierAnnule'].includes(notification.TypeEvenement__c))) {
        notif.link = '/detail-dossier-contrat/' + notification.RefId__c
        if (notification.TypeEvenement__c === 'DossierIncomplet')
            notif.link += '#documents'
    }

    if (import.meta.env.REACT_APP_RELANCE === 'true') {
        const states = new Map<string, JSX.Element>([
            [ 'DossierAccorde', <State small type="file" id="4">accordé</State> ],
            [ 'DossierIncomplet', <State small type="file" id="3">incomplet</State> ],
            [ 'DossierRefuse', <State small type="file" id="5">refusé</State> ],
            [ 'DossierAnnule', <State small type="file" id="8">annulé</State> ],
            [ 'DossierRupture',  <State small type="file" id="7">rupture</State> ],
        ])
    
        notif.message =
            <p>
                Dossier {
                notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name } {
                states.get(notification.TypeEvenement__c) } {
                notification.Complement__c }
            </p>
    }
    else {
        switch (notification.TypeEvenement__c) {
            case 'DossierAccorde':
                notif.message = <p>Votre dossier {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} a été <State small type="file" id="4">accordé</State></p>
                break
            case 'DossierIncomplet':
                notif.message = <p>Votre dossier {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} est <State small type="file" id="3">incomplet</State>, merci de prendre connaissance du courrier de relance</p>
                break
            case 'DossierRefuse':
                notif.message = <p>Votre dossier {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} a été <State small type="file" id="5">refusé</State></p>
                break
            case 'DossierAnnule':
                notif.message = <p>Votre dossier {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} a été <State small type="file" id="8">annulé</State></p>
                break
            case 'DossierRupture':
                notif.message = <p>Votre dossier {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} est en <State small type="file" id="7">rupture</State></p>
                break
        }
    }

    return notif
}

const notificationFacture = (notification: NotificationModel, isPrestataire: boolean) => {
    const notif = {
        message: null as JSX.Element | null,
        link: null as string | null,
        icon: 'factures',
    }

    notif.link = '/detail-facture/' + notification.RefId__c
    if (notification.TypeEvenement__c === 'FactureIncomplete')
        notif.link += '#documents'

    if (import.meta.env.REACT_APP_RELANCE === 'true') {
        const states = new Map<string, JSX.Element>([
            [ 'FacturePayee', <State small type="bill" id="3">payée</State> ],
            [ 'FactureRefusee', <State small type="bill" id="4">refusée</State> ],
            [ 'FactureIncomplete', <State small type="bill" id="2">incomplète</State> ],
        ])
    
        notif.message =
            <p>
                Facture {
                notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name } {
                states.get(notification.TypeEvenement__c) } {
                notification.Complement__c }
            </p>
    }
    else {
        switch (notification.TypeEvenement__c) {
            case 'FacturePayee':
                notif.message = <p>Votre facture {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} a été <State small type="bill" id="3">payée</State></p>
                break
            case 'FactureRefusee':
                notif.message = <p>Votre facture {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} a été <State small type="bill" id="4">refusée</State></p>
                break
            case 'FactureIncomplete':
                notif.message = <p>Votre facture {notif.link ? <Link to={notif.link}> {notification.Name}</Link> : notification.Name} est <State small type="bill" id="2">incomplète</State>, merci de prendre connaissance du courrier de relance</p>
                break
        }
    }

    return notif
}

const notificationReglement = (notification: NotificationModel, isPrestataire: boolean) => {
    const notif = {
        message: null as JSX.Element | null,
        link: null as string | null,
        icon: 'reglements',
    }

    switch (notification.TypeEvenement__c) {
        case 'ReglementEmis':
            notif.message = <p>Un règlement a été émis</p>
            break
    }

    return notif
}

const NOTIFICATIONS: NotificationHandlers = {
    'Dossier': notificationDossier,
    'Facture': notificationFacture,
    'Reglement': notificationReglement,
}

interface DatedNotification extends NotificationModel {
    date: Date
}

interface NotificationProps {
    notification: DatedNotification
    update: ((id: string, lu: boolean) => void)
    isPrestataire: boolean
}

const getDaysSinceDate = (date: Date): string => {
    const { years, months, days} =  intervalToDuration({
        start: date,
        end: new Date()
    });

    if (years) {
        return `${years} an${years > 1 ? 's' : ''}`
    }

    if (months) {
        return `${months} mois`
    }

    return days == null || days === 0 ? "Auj." : days === 1 ? "1 jour" : `${days} jours`
}

export default function Notification(props: NotificationProps) {

    const notifHandler = NOTIFICATIONS[props.notification.TypeReference__c] ?? notificationDefaut
    const notif = notifHandler(props.notification, props.isPrestataire)

    return <MyNotifications.Item
        error={ import.meta.env.REACT_APP_RELANCE === 'true' && props.notification.Alerte__c === true }
        onMarkAsRead={() => props.update(props.notification.Id, true)}
        linkedServiceLogo={props.notification.DispositifFO__c === DISPOSITIF_ACN && <AtlasCampusLogo />}
        date={getDaysSinceDate(props.notification.date)}
    >{notif.message}</MyNotifications.Item>
}