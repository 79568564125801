import { AtlasValue } from 'atlas-ds'

interface RecapDurationMnFieldProps {
    label: string,
    value: { hours: number, minutes: number } | null | undefined,
}

export default function RecapDurationMnField(props: RecapDurationMnFieldProps) {
    return <AtlasValue label={ props.label }>
        { props.value && props.value.hours ? props.value.hours + 'h ' + props.value.minutes + 'mn' : '' }
    </AtlasValue>
}
