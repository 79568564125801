import { DATE_FORMAT } from 'app/constants';
import store from 'app/store';
import axios from 'axios'
import { getRequestConfig, Page } from './baseAPI';
import { format, startOfToday, subMonths } from 'date-fns'
import { normalizeAPIDate } from 'components/format/Format';

export interface Notification {
    Id: string
    Destinataire__c: string
    Reference__c: string
    RefId__c: string
    EntrepriseId__c: string
    CreatedDate: string
    Contexte__c: string
    TypeMessage__c: string
    CreePar__c: string
    DateLu__c: string
    GraviteMessage__c: string
    Lu__c: boolean
    ModifiePar__c: string
    Name: string
    TypeReference__c: string
    TypeEvenement__c: string
    DispositifFO__c: string
    Dispositif__c: string
    Alerte__c?: boolean
    Complement__c?: string
}

export interface NotificationSearchQuery {
    page?: number
    parPage?: number
    dateDebut?: string
    dateFin?: string
    reference?: string
    typeEvenement?: string
    typeReference?: string
    DispositifFO?: string
    lu?: boolean
}

export interface NotificationsPage extends Page<Notification> {
    page: number
    siren: string
}

export async function getNotifications(query?: NotificationSearchQuery): Promise<NotificationsPage> {
    if (!query)
        query = { lu: false }

    const requestConfig = await getRequestConfig()
    
    const page = (query.page ?? 0)
    
    const params = new URLSearchParams()
    params.append('page', page.toFixed())
    params.append('par-page', (query.parPage ?? 50).toFixed())
    
    const today = startOfToday()
    params.append('date-debut', query.dateDebut ?? format(subMonths(today, 6), DATE_FORMAT))
    params.append('date-fin', query.dateFin ?? format(today, DATE_FORMAT))

    if (query.reference)
        params.append('reference', query.reference)
    if (query.typeEvenement)
        params.append('type-evenement', query.typeEvenement)
    if (query.typeReference)
        params.append('type-reference', query.typeReference)
    if (query.DispositifFO)
        params.append('dispositif-fo', query.DispositifFO)
    if (query.lu !== undefined)
        params.append('lu', query.lu.toString())

    const siren = store.getState().contextState.entreprise?.SIREN__c ?? ''
    params.append('siren', siren)

    requestConfig.params = params

    const { data, headers } = await axios.get<Notification[]>('/notifications/recherche', requestConfig)

    data.forEach((notification) => {
        notification.CreatedDate = normalizeAPIDate(notification.CreatedDate)
    })

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length

    return {
        siren,
        page: page,
        totalCount: totalCount,
        items: data
    }
}

export async function updateNotification(id: string, lu = true): Promise<void> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.append('siren', store.getState().contextState.entreprise?.SIREN__c ?? '')
    requestConfig.params = params

    await axios.patch<string>('/notifications/' + id, { Lu__c: lu }, requestConfig)
}

export async function deleteAllNotifications(): Promise<void> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    const siren = store.getState().contextState.entreprise?.SIREN__c ?? ''
    const today = startOfToday()
    params.append('date-debut', format(subMonths(today, 6), DATE_FORMAT))
    params.append('date-fin',  format(today, DATE_FORMAT))
    requestConfig.params = params

    await axios.delete<string>('/notifications/delete?siren=' + siren + '&date-debut=' + format(subMonths(today, 6), DATE_FORMAT) + '&date-fin=' + format(today, DATE_FORMAT) , requestConfig)
}