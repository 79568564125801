import { DetailFormationCampus, SessionFormation, getFormation, getSession } from 'api/formationAPI'
import useApiState from 'api/useApiState'
import { RootState } from 'app/rootReducer'
import { AtlasButton } from 'atlas-ds'
import { Location } from 'history'
import DetailCampus from 'pages/detail-common/DetailCampus'
import AdresseEntrepriseModal from 'pages/gestion-compte-entreprise/AdresseEntrepriseModal'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from "react-router-dom"
import InformationsSessionForm from './InformationsSessionForm'

const PARAM_ID_FORMATION = "idformation"

export default function NouvelleSession() {

    const history = useHistory()
    const params = useParams<any>()
    const idSession = params["idsession"]


    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const [showModalAdresse, setShowModalAdresse] = useState(false)
    const [modeDupliquer, setModeDupliquer] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ value: session, pending: sessionPending }, withGetSession] = useApiState(getSession)

    //dupliquer Session
    useEffect(() => {
        if (idSession && idSession !== 'session') {
            withGetSession(idSession)
            setModeDupliquer(true)
        }
    }, [idSession, withGetSession])



    const breadcrumb = (location: Location<any>, formation: DetailFormationCampus | null) => {
        return [
            { title: 'Formations', pathname: '/mes-formations', search: '' },
            { title: formation?.Libelle__c ?? 'Formation sélectionnée', pathname: `/detail-formation/${formation?.IdHeroku}`, search: '' }
        ]
    }

    const sections = (formation: DetailFormationCampus | null, formationPending: boolean) => {

        if (formation == null)
            return []

        const onSuccess = (session: SessionFormation) => history.push(`/detail-session/${session?.IdHeroku}`)
        const onCancel = () => history.push(`/detail-formation/${formation?.IdHeroku}`)

        return [
            <InformationsSessionForm
                key="informationsSessionForm"
                formation={ formation }
                onSuccess={ onSuccess }
                onCancel={ onCancel }
                session={ session! }
                modeDupliquer={ modeDupliquer }
                heading={ true } />,
            ...showModalAdresse ? [<AdresseEntrepriseModal key="modalAdresse" close={() => setShowModalAdresse(false)} entreprise={entreprise} />] : []
        ]
    }

    const getTitle = (formation: DetailFormationCampus | null) => {
        return `Nouvelle session pour la formation "${formation?.Libelle__c}"`
    }

    const getActions = (formation: DetailFormationCampus | null) => [<AtlasButton
        key="ajouterAdresse"
        level={2}
        onClick={() => setShowModalAdresse(true)}
    >Ajouter une adresse</AtlasButton>]

    return <DetailCampus<DetailFormationCampus> name="addSession"
        paramName={PARAM_ID_FORMATION}
        title={(formation: DetailFormationCampus | null) => getTitle(formation)}
        getId={(formation: DetailFormationCampus | null) => formation?.IdHeroku as number}
        getDetail={getFormation}
        loadErrorMessage="Erreur lors de récupération de la formation"
        history={breadcrumb}
        sections={sections}
        getActions={getActions}
    />
}