import { Text, View } from '@react-pdf/renderer'
import { TYPE_MODIFICATION_AVENANT } from 'api/references'
import styles from 'pages/dossier/cerfa/global.styles'
import { FormMetadata, atField } from 'pages/dossier/metadata/FormMetadata'
import { DossierCPRO, DossierCPROContrat } from 'pages/dossier-cpro/CPROModel'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'

export default function Contrat<D extends DossierCPRO>({ metadata, dossier, contrat }: { metadata: FormMetadata<D>, dossier?: D, contrat?: DossierCPROContrat | null }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeaderV4}>LE CONTRAT</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>Nature du contrat :</Text>
            <CerfaElement metadata={ atField(metadata, 'contrat', 'natureContrat') } value={ contrat?.natureContrat } />
            <Text>
              Type de contrat :{' '}
              { /* root nécessaire pour le filtrage des options de l'avenant */ }
              <CerfaElement metadata={ atField(metadata, 'contrat', 'typeContrat') } root={ dossier } numberOnly value={ contrat?.typeContrat } />
            </Text>
            <Text></Text>
            <Text></Text>
          </View>
          <View style={styles.colRight}>
            <Text><Checkbox></Checkbox> Si le présent contrat de professionnalisation vaut
              avenant au contrat de travail initial cochez la case ci-contre*</Text>
            <Text style={styles.info}>* uniquement dans le cadre d'un contrat de professionnalisation
              associant des actions de validation des acquis de l'expérience
              mentionné à l'article 11 de la loi n° 2022-1598 du 21 décembre 2022
            </Text>
          </View>
        </View>
        <Text>
          Emploi occupé pendant le contrat (intitulé précis) :{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'emploiOccupe') } value={ contrat?.emploiOccupe } />
        </Text>
        <Text>
          Classification de l'emploi dans la convention collective :{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'classificationEmploi') } value={ contrat?.classificationEmploi } />
        </Text>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>
              Niveau :{' '}
              <CerfaElement metadata={ atField(metadata, 'contrat', 'niveau') } value={ contrat?.niveau } />
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              Coefficient hiérarchique :{' '}
              <CerfaElement metadata={ atField(metadata, 'contrat', 'coefficient') } value={ contrat?.coefficient } />
            </Text>
          </View>
        </View>
        <View style={styles.cols}>
          <View style={styles.tripleCol}>
            <Text>Durée de la période d'essai :</Text>
            <CerfaElement metadata={ atField(metadata, 'contrat', 'dureePeriodeEssai') } value={ contrat?.dureePeriodeEssai } />
            <Text> </Text>
            <Text>Date de fin du CDD ou de l'action de professionnalisation si CDI :</Text>
            <CerfaElement metadata={ atField(metadata, 'contrat', 'dateFin') } value={ contrat?.dateFin } />
          </View>
          <View style={styles.tripleCol}>
            <Text>Date de début d'exécution du contrat :</Text>
            <CerfaElement metadata={ atField(metadata, 'contrat', 'dateDebut') } value={ contrat?.dateDebut } />
            <Text>Durée hebdomadaire du travail :</Text>
            <CerfaElement metadata={ atField(metadata, 'contrat', 'dureeHebdomadaireTravail') } value={ contrat?.dureeHebdomadaireTravail } />
          </View>
          <View style={styles.tripleColLast}>
            <Text>Si avenant, date d'effet :</Text>
            { (dossier as any).typeModificationContrat === TYPE_MODIFICATION_AVENANT && <CerfaElement metadata={ atField(metadata, 'contrat', 'dateEffet') } value={ contrat?.dateEffet } /> }
            <Text> </Text>
            <Text>Date de conclusion :</Text>
            <Text style={styles.xSmall}>(date de signature du présent contrat)</Text>
            { (dossier as any).typeModificationContrat === TYPE_MODIFICATION_AVENANT ?
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dateConclusionAvenant') } value={ contrat?.dateConclusionAvenant } />
              :
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dateConclusion') } value={ contrat?.dateConclusion } />
            }
          </View>
        </View>
        <Text> </Text>
        <Text style={styles.bold}>
          Salaire brut mensuel à l'embauche :{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'salaireEmbauche') } value={ contrat?.salaireEmbauche } />
        </Text>
        <Text style={styles.small}>
          (ou salaire brut horaire pour un contrat de travail temporaire)
        </Text>
      </View>
    </View>
  )
}
