import { createEmptyMetadata, FieldMetadata, ObjectMetadata } from 'pages/dossier/metadata/FormMetadata'

export interface HolderFieldMetadata<T, A = T> extends FieldMetadata<A> {
    fields: ObjectMetadata<T>
    type: 'array' | 'object'
    categorie?: string
    optional?: boolean
}

export function ObjectFieldMetadata<T>(fields: ObjectMetadata<T>, categorie?: string): HolderFieldMetadata<T> {
    return {
        ...createEmptyMetadata(),
        fields,
        type: 'object',
        categorie,
    }
}

export function OptionalObjectFieldMetadata<T>(fields: ObjectMetadata<T>, categorie?: string): HolderFieldMetadata<T | null> {
    return {
        ...createEmptyMetadata(),
        fields,
        type: 'object',
        categorie,
        optional: true,
    }
}

export function ArrayFieldMetadata<T>(fields: ObjectMetadata<T>, categorie?: string): HolderFieldMetadata<T, T[]> {
    return {
        ...createEmptyMetadata(),
        fields,
        type: 'array',
        categorie,
    }
}

export function OptionalArrayFieldMetadata<T>(fields: ObjectMetadata<T>, categorie?: string): HolderFieldMetadata<T, (T | null)[]> {
    return {
        ...createEmptyMetadata(),
        fields,
        type: 'array',
        categorie,
    }
}
