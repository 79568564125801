import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { MyModal } from 'atlas-ds'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierACN } from 'pages/dossier-acn/ACNFormMetadata'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dossierSliceActions } from 'slices/dossierSlice'


export default function DossierACNEmployeurElements(props: { metadata: FormMetadata<DossierACN> }) {
    
    const dispatch = useAppDispatch()

    const [ saving, setSaving ] = useState(false)

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const from = params.get('from') ?? null
    const codeStage = params.get('codeStage') ?? ''

    useEffect(() => {
        if (from === 'campus' && codeStage) {
            setSaving(true)
            const dossier = props.metadata.api.create({ campusSession: codeStage })
            dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier }))
                .then(unwrapResult)
                .finally(() => setSaving(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, codeStage])

    return (<>
        <DossierEmployeurElements { ...props } />

        <MyModal
            isLoader
            show={ saving }
            label="Vérification du stage en cours…"
        />
    </>)
}
