import { references } from 'api/references'
import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import Checkboxes from 'pages/dossier/cerfa/components/Checkboxes'
import { useSelector } from 'react-redux'

interface CerfaRadioRefFieldProps {
    value: string | boolean | null | undefined
    options: (refs: References) => Reference[] | undefined,
}

export default function CerfaRadioRefField(props: CerfaRadioRefFieldProps) {
    const refs = references()

    if (props.value === null || props.value === undefined) {
        return null
    }

    const options = refs ? (props.options(refs) ?? []) : []
    const choices = options.map(opt => ({ value: opt.Id, label: opt.Libelle__c }))
    
    return <Checkboxes value={ props.value } choices={ choices }></Checkboxes>
}
