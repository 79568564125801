import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { validerMDPOublie } from "api/monCompteAPI"
import useApiState from "api/useApiState"
import { AtlasInfo, MyForm } from "atlas-ds"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import log from 'loglevel'
import { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { MotDePasse, MotDePasseBloc, MotDePassePropertiesSchemas, createMotDePasse } from "./MotDePasseBloc"

export function ReinitialiserMotDePasse() {

    const { token } = useParams<{ token: string }>()
    const history = useHistory()

    const [, withValiderMDPOublie, setApiState] = useApiState(validerMDPOublie, { pending: false })
    const [backendError, setBackendError] = useState<string | null>(null)

    useEffect(() => {
        if (token == null || token === '')
            setApiState({ pending: false, value: null, error: "URL de réinitialisation incorrecte" })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initialValues = createMotDePasse()
    const validationSchema = Yop.object<MotDePasse>(MotDePassePropertiesSchemas)

    const form = useForm<MotDePasse>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<MotDePasse>) => {
            withValiderMDPOublie(token, context.values!.motDePasse)
                .then(() => {
                    history.replace("/reinitialiser-mot-de-passe-confirmation");
                })
                .catch((error) => {
                    log.error("Erreur reinitialisation mot de passe", error)
                    const message = error?.response?.data?.message || "Une erreur inattendue s'est produite."
                    setBackendError(message);
                })
                .finally(() => {
                    context.setSubmitting(false);
                });
        },
    });

    return <>
        <PageHeader>Réinitialisez votre mot de passe</PageHeader>

        <Form context={form} autoComplete="off" noValidate>
            <MyForm>
                <MyForm.Grid>
                    <MotDePasseBloc label="Nouveau mot de passe" />
                </MyForm.Grid>

                {backendError && <AtlasInfo
                    type="error"
                    title="Erreur"
                    link={<Link to="/mot-de-passe-oublie">Retour à la page de réinitialisation du mot de passe</Link>}
                >{ backendError }</AtlasInfo>}

                <MyForm.Actions toLeft>
                    <Button submit={true} spinner={{ spinning: form.submitting }}>Valider</Button>
                </MyForm.Actions>
            </MyForm>
        </Form>
    </>
}
