import { BaseDossier } from "slices/dossierCommon"
import { ElementContext, FieldMetadata, FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { isEqual } from 'lodash'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE, DISPOSITIF_CONTRAT_PROFESSIONNALISATION, DISPOSITIF_VAEI } from "api/references"


function getObjectDiff(obj1: any, obj2: any) {
    if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return [obj1, obj2]
    }
    const diffKeys = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key)
        } else if (isEqual2(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key)
            result.splice(resultKeyIndex, 1)
        }
        return result
    }, Object.keys(obj2))

    const diffObj1 = {} as any
    const diffObj2 = {} as any
    diffKeys.forEach((key: string) => {
        diffObj1[key] = obj1[key]
        diffObj2[key] = obj2[key]
    })
    return [diffObj1, diffObj2] 
}

const isEqual2 = (val1: any, val2: any) => {
    return isEqual(val1, val2) || (val1 === false && val2 == null) || (val2 === false && val1 == null)
}

const exportNames = new Map<string, string>([
    [DISPOSITIF_CONTRAT_PROFESSIONNALISATION, "contrat de professionnalisation"],
    [DISPOSITIF_VAEI, "contrat VAEI"],
    [DISPOSITIF_CONTRAT_APPRENTISSAGE, "contrat d'apprentissage"],
])

export async function exportDossierChanges<D extends BaseDossier>(Infos: any, metadata: FormMetadata<D>, currentDossier: any, authName : string, numModifContrat: number =0) {
    
    const numModifContratText = numModifContrat > 0  ? `n° ${numModifContrat}` : ''
    const data = {
        title: `Liste des modifications du type ${currentDossier?.typeModificationContrat}  ${numModifContratText} du`,
        subtitle: `${ exportNames.get(currentDossier?.DispositifFO__c) ?? "dossier" } n° ${currentDossier?.NumeroDossier__c}`,
        auth: authName,
        tables: [] as any[],
    }

    if (currentDossier === null) {
        return <Infos data={data} />
    }

    const initialDossier = await metadata.api.load(currentDossier.NumeroDossier__c ?? '') as any
    if (!initialDossier) {
        return <Infos data={data} />
    }
    
    Object.entries(metadata.fields).forEach(([key, hmd]) => {
        if (hmd.type === 'object') {
            const initialObject = initialDossier[key] as any
            const currentObject = currentDossier[key] as any

            const changes = [] as any
            Object.entries(hmd.fields).forEach(([name, f]) => {
                const fmd = f as FieldMetadata<any>
                let initialValue = initialObject?.[name] ?? null
                if (fmd.convert)
                    initialValue = fmd.convert(initialValue)
                const currentValue = currentObject?.[name] ?? null
                if (fmd.export && !isEqual2(currentValue, initialValue)) {
                    const Export = fmd.export as any
                    const [initialDiffValue, currentDiffValue] = fmd.diff ? fmd.diff(initialValue, currentValue) : getObjectDiff(initialValue, currentValue)

                    let label = fmd.props?.exportLabel ?? fmd.props?.label ?? name
                    if (typeof label === 'function') {
                        const elementContext: ElementContext<any, any> = {
                            name: name,
                            key: name,
                            root: currentDossier,
                            parent: null,
                            parentPath: key,
                            index: 0,
                            path: name,
                            editMode: metadata.api.isEdit,
                        }
                        label = label(elementContext)
                    }
    
                    if (Array.isArray(initialDiffValue) && Array.isArray(currentDiffValue)) {
                        const size = Math.max(initialDiffValue.length, currentDiffValue.length)
                        for (let i = 0; i < size; i++) {
                            const parentPath = key
                            const change = [
                                label + ' n°' + (i+1),
                                <Export key={ `${parentPath}.${name}.init-${i}` } name={ parentPath + '.' + name } { ...fmd.props } 
                                    root={ initialDossier } parentPath={ parentPath } 
                                    parent={ initialObject } cell value={ initialDiffValue[i] } />,
                                <Export key={ `${parentPath}.${name}.curr-${i}` } name={ parentPath + '.' + name } { ...fmd.props } 
                                    root={ currentDossier } parentPath={ parentPath } 
                                    parent={ currentObject } cell value={ currentDiffValue[i] } />,
                            ]
                            changes.push(change)
                        }
                    } else {
                        const parentPath = key
                        const change = [
                            label,
                            <Export key={ parentPath + '.' + name + '.init' } name={ parentPath + '.' + name } { ...fmd.props } 
                                root={ initialDossier } parentPath={ parentPath } 
                                parent={ initialObject } cell value={ initialDiffValue } />,
                            <Export key={ parentPath + '.' + name + '.curr' } name={ parentPath + '.' + name } { ...fmd.props } 
                                root={ currentDossier } parentPath={ parentPath } 
                                parent={ currentObject } cell value={ currentDiffValue } />,
                        ]
                        changes.push(change)
                    }
                }
            })

            if (changes.length > 0) {
                data.tables.push({
                    title: hmd.categorie,
                    headers: ['Champ', 'Ancienne valeur', 'Nouvelle valeur'],
                    changes,
                })
            }

        } else if (hmd.type === 'array') {
            const initialArray = initialDossier[key]
            const currentArray = currentDossier[key]
            const size = Math.max((initialArray?.length ?? 0), (currentArray?.length ?? 0))
            
            for (let idx = 0; idx < size; idx++) {
                const initialObject = idx < (initialArray?.length ?? 0) ? initialArray[idx] : {}
                const currentObject = idx < (currentArray?.length ?? 0) ? currentArray[idx] : {}

                const changes = [] as any
                Object.entries(hmd.fields).forEach(([name, f]) => {
                    const fmd = f as FieldMetadata<any>
                    let initialValue = initialObject?.[name] ?? null
                    if (fmd.convert)
                        initialValue = fmd.convert(initialValue)
                    const currentValue = currentObject?.[name] ?? null
                    if (fmd.export && !isEqual(currentValue, initialValue)) {
                        const Export = fmd.export as any
                        const [initialDiffValue, currentDiffValue] = getObjectDiff(initialValue, currentValue)

                        let label = fmd.props?.exportLabel ?? fmd.props?.label ?? name
                        if (typeof label === 'function') {
                            const elementContext: ElementContext<any, any> = {
                                name: name,
                                key: name,
                                root: currentDossier,
                                parent: null,
                                parentPath: key,
                                index: 0,
                                path: name,
                                editMode: metadata.api.isEdit,
                            }
                            label = label(elementContext)
                        }
    
                        const parentPath = `${key}[${idx}]`
                        const change = [
                            label,
                            <Export key={ parentPath + '.' + name + '.init' } name={ parentPath + '.' + name } { ...fmd.props } 
                                root={ initialDossier } parentPath={ parentPath } 
                                parent={ initialObject } index={ idx } cell value={ initialDiffValue } />,
                            <Export key={ parentPath + '.' + name + '.curr' } name={ parentPath + '.' + name } { ...fmd.props } 
                                root={ currentDossier } parentPath={ parentPath }
                                parent={ currentObject } index={ idx } cell value={ currentDiffValue } />,
                        ]
                        changes.push(change)
                    }
                })

                if (changes.length > 0) {
                    data.tables.push({
                        title: `${hmd.categorie} n°${idx+1}`,
                        headers: ['Champ', 'Ancienne valeur', 'Nouvelle valeur'],
                        changes,
                    })
                }
            }
        }
    })

    return <Infos data={data} />
}
