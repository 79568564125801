/* eslint-disable jsx-a11y/anchor-is-valid */
import { AtlasHeading, MyContainer, MySteps } from 'atlas-ds'
import { FormStep } from 'pages/dossier/metadata/FormMetadata'

interface SuiviEtapesProps {
    iddossier: string
    etapeCourante: number
    etapeMax: number
    steps: FormStep<any>[]
    goEtape: (etape: number) => void
}

export default function SuiviEtapes(props: SuiviEtapesProps) {

    const etapeCourante = props.etapeCourante > props.steps.length ? props.steps.length : props.etapeCourante
    let etapeMax = props.etapeMax > props.steps.length ? props.steps.length : props.etapeMax
    if (etapeMax < etapeCourante)
        etapeMax = etapeCourante

    const currentStep = props.steps[etapeCourante-1]

    return <div>
        <AtlasHeading tag="h2" size="m">Suivi des étapes</AtlasHeading>
        <MyContainer>
            <MySteps direction="column">
                { currentStep.stepButton ? [
                    <div key="current">{currentStep.stepButton({ step: currentStep })}</div>
                ] : props.steps.filter(step => !step.stepButton).map((step, index) => {
                return <MySteps.Item
                    key={ step.title }
                    active={ etapeCourante === index + 1}
                    disabled={ etapeMax < index + 1 }
                    onClick={ e => { e.preventDefault(); if (index+1 !== etapeCourante) { props.goEtape(index+1) } } }
                >{ step.title }</MySteps.Item> }) }
            </MySteps>
        </MyContainer>
    </div>
}