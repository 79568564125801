import { Etablissement, getEtablissements } from 'api/commonAPI'
import { MyForm } from 'atlas-ds'
import EtablissementSelectorField from 'components/reform/inputs/EtablissementSelectorField'
import { SECTION_EMPLOYEUR_ADRESSE, SECTION_EMPLOYEUR_CONTACT, SECTION_EMPLOYEUR_EXTRA, SECTION_EMPLOYEUR_IDENTIFICATION } from 'pages/dossier/metadata/EmployeurMetadata'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useParams } from 'react-router-dom'
import { BaseDossier } from 'slices/dossierCommon'
import { TOOLTIPS } from '../Tooltips'
import { DossierIdParams } from '../useDossier'
import { useDossierNavigation } from '../useDossierNavigation'

interface DossierEmployeurElementsProps<D extends BaseDossier> {
    metadata: FormMetadata<D>
    label?: string
    labelContact?: string
    tooltipContact?: string
    getEtablissements?: (recherche: string, actif?: boolean) => Promise<Etablissement[]>
}

export function DossierEmployeurElements<D extends BaseDossier>(props: DossierEmployeurElementsProps<D>) {
    
    const { dossierId } = useParams<DossierIdParams>()
    const { step } = useDossierNavigation(props.metadata)
    
    return (<>
        <MyForm.Grid>
            <EtablissementSelectorField label={ props.label } getEtablissements={ props.getEtablissements ?? getEtablissements} />
            
            <FormElements section={ SECTION_EMPLOYEUR_IDENTIFICATION } metadata={ props.metadata } path="employeur" type="input" />

            <MyForm.Fieldset legend="Adresse d'exécution du contrat" >
                <MyForm.Grid>
                    <FormElements section={ SECTION_EMPLOYEUR_ADRESSE } metadata={ props.metadata } path="employeur" type="input" />
                    { step.sections.includes(SECTION_EMPLOYEUR_EXTRA) &&
                        <FormElements section={ SECTION_EMPLOYEUR_EXTRA } metadata={ props.metadata } path="employeur" type="input" />
                    }
                </MyForm.Grid>
            </MyForm.Fieldset>
    
            { step.sections.includes(SECTION_EMPLOYEUR_CONTACT) &&
                <MyForm.Fieldset
                    legend={ props.labelContact ?? "Contact" }
                    tooltipContent={ props.tooltipContact ?? TOOLTIPS.employeur.contact }
                >
                    <MyForm.Grid>
                        <FormElements section={ SECTION_EMPLOYEUR_CONTACT } metadata={ props.metadata } path="employeur" type="input" />
                    </MyForm.Grid>
                </MyForm.Fieldset>
            }
        </MyForm.Grid>
    
        { !dossierId &&
            <p>La validation de cette étape déclenche la création d'un dossier brouillon, qui pourra être complété ultérieurement.</p>
        }
    </>)
}
