import { SessionFormation, getSession } from 'api/formationAPI'
import { MODALITE_STAGE_EN_LIGNE, TYPE_STAGE_INTER, TYPE_STAGE_INTRA } from 'api/references'
import { dispatchAppEvent } from "app/events"
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasHeading, AtlasValue, AtlasValues, MyContainer, MyGauge, MyNumber } from 'atlas-ds'
import { formatRef, formatTime, formatToFullDate } from 'components/format/Format'
import { Location } from 'history'
import DetailCampus from 'pages/detail-common/DetailCampus'
import AdresseEntrepriseModal from 'pages/gestion-compte-entreprise/AdresseEntrepriseModal'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import DetailStagiairesSessions from './DetailStagiairesSessions'
import InformationsSessionForm from './InformationsSessionForm'

const PARAM_ID_SESSION = "idsession"
const REFRESH_EVENT = 'DetailSession:Refresh'

export default function DetailSession() {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const [showModalAdresse, setShowModalAdresse] = useState(false)
    const [editing, setEditing] = useState(false)

    const history = useHistory()

    const onSuccess = () => {
        dispatchAppEvent(REFRESH_EVENT)
        setEditing(false)
    }

    const breadcrumb = (location: Location<any>, session: SessionFormation | null) => {
        return [
            { title: 'Formations', pathname: '/mes-formations', search: '' },
            { title: 'Formation sélectionnée', pathname: `/detail-formation/${session?.Stage?.IdHeroku}`, search: '' },
            { title: 'Session', pathname: location.pathname, search: location.search }
        ]
    }

    const sections = (session: SessionFormation | null) => {
        let pourcentageDisponible = 100;

        if (session?.Type__c === TYPE_STAGE_INTER && session?.Modalite__c !== MODALITE_STAGE_EN_LIGNE)
            pourcentageDisponible = ((session?.NbPlacesDisponibles__c ?? 0) / (session?.NbPlacesTotalAtlas__c ?? 1)) * 100

        const getLibellesTypes = () => {
            let libelles = ''
            session?.Type__c?.split(';').forEach((type) => {
                if (libelles)
                    libelles += ` et `
                libelles += formatRef(refs?.REF_CAMPUS_TYPE, type)
            })
            return libelles
        }

        const disponibilite = <section key="disponibilite">
            <AtlasHeading tag="h2" size="m">Disponibilité</AtlasHeading>
            <MyGauge
                percentage={pourcentageDisponible}
                remaining={<AtlasValue label="Places restantes">
                    {getPlacesRestantes(session)}
                </AtlasValue>}
                total={<AtlasValue label="Places totales">
                    {getPlacesDisponibles(session)}
                </AtlasValue>}
                used={<AtlasValue label="Inscrits">
                    {<MyNumber>{session?.NbPlacesOccupees__c ?? 0}</MyNumber>}
                </AtlasValue>}
            />
        </section>

        const form = session ?
            <InformationsSessionForm key="InformationsSessionForm" onSuccess={onSuccess} onCancel={() => setEditing(false)} session={session} formation={session.Stage!} /> :
            <></>

        const informationsPrincipales = <div key="informationsPrincipales">
            <AtlasHeading
                tag="h2"
                size="m"
                button={form ? <AtlasButton level={3} icon="arrow-right" onClick={() => setEditing(true)}>
                    Modifier
                </AtlasButton> : undefined}
            >Informations principales</AtlasHeading>
            { editing ? form : <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <AtlasValue label="Type de formation">
                            {getLibellesTypes()}
                        </AtlasValue>
                        <AtlasValue label="Modalités">
                            {formatRef(refs?.REF_CAMPUS_MODALITE, session?.Modalite__c)}
                        </AtlasValue>
                    </AtlasValues.Grid>
                    <AtlasValue label="Lieu">
                        {getLieu(session)}
                    </AtlasValue>
                    <AtlasValue label="Session garantie">
                        {(session?.SessionGarantie__c) ? 'Oui' : 'Non'}
                    </AtlasValue>
                </AtlasValues>
            </MyContainer> }
        </div>

        return [
            disponibilite,
            informationsPrincipales,
            <DetailStagiairesSessions key="sessions" session={session} />,
            ...showModalAdresse ? [<AdresseEntrepriseModal key="modalAdresse" close={() => setShowModalAdresse(false)} entreprise={entreprise} refreshEvent={REFRESH_EVENT} />] : []
        ]
    }

    const getTitle = (session: SessionFormation | null) => {
        if (session?.Type__c === TYPE_STAGE_INTRA)
            return "Session Intra"

        if (session?.Type__c === TYPE_STAGE_INTER && session?.Modalite__c !== MODALITE_STAGE_EN_LIGNE)
            return `Session Inter du ${formatToFullDate(session?.DateDebut__c)} au ${formatToFullDate(session?.DateFin__c)} de ${formatTime(session?.HeureDebut__c)} à ${formatTime(session?.HeureFin__c)}`

        if (session?.Modalite__c === MODALITE_STAGE_EN_LIGNE)
            return `Session Inter en ligne`
    }

    const getLieu = (session: SessionFormation | null) => {
        let lieu = '';

        if (session?.Modalite__c === MODALITE_STAGE_EN_LIGNE) {
            lieu = 'En ligne'
        } else {
            lieu += (session?.Adresse?.Adresse1__c) ? session?.Adresse?.Adresse1__c + ', ' : ''
            lieu += (session?.Adresse?.Adresse2__c) ? session?.Adresse?.Adresse2__c + ', ' : ''
            lieu += (session?.Adresse?.Adresse3__c) ? session?.Adresse?.Adresse3__c + ' ' : ''
            lieu += (session?.Adresse?.Adresse4__c) ? session?.Adresse?.Adresse4__c + ' ' : ''
            lieu += (session?.Adresse?.CodePostal__c) ? session?.Adresse?.CodePostal__c + ' ' : ''
            lieu += (session?.Adresse?.Ville__c) ? session?.Adresse?.Ville__c + ' ' : ''
        }
        return lieu
    }

    const getPlacesRestantes = (session: SessionFormation | null): React.ReactNode => {
        if (session?.Type__c === TYPE_STAGE_INTRA)
            return "Intra, piloté par l'organisme"
        else if (session?.Modalite__c === MODALITE_STAGE_EN_LIGNE)
            return "En ligne, piloté par l'organisme"
        else
            return <MyNumber>{session?.NbPlacesDisponibles__c ?? 0}</MyNumber>
    }

    const getPlacesDisponibles = (session: SessionFormation | null): React.ReactNode => {
        if (session?.Type__c === TYPE_STAGE_INTRA)
            return "Intra, piloté par l'organisme"
        else if (session?.Modalite__c === MODALITE_STAGE_EN_LIGNE)
            return "En ligne, piloté par l'organisme"
        else
            return <MyNumber>{session?.NbPlacesTotalAtlas__c ?? 0}</MyNumber>
    }

    const getActions = (session: SessionFormation | null) =>
        session ? [
            <AtlasButton key="ajouterAdresse" level={ 2 } onClick={() => setShowModalAdresse(true)}>
                Ajouter une adresse
            </AtlasButton>,
            <AtlasButton key="nouvelleSession" level={ 2 } onClick={() => history.push(`/nouvelle-session/${session.Stage!.IdHeroku}/session`)}>
                Ajouter une session
            </AtlasButton>
        ] : undefined

    return <DetailCampus<SessionFormation> name="DetailSession"
        paramName={PARAM_ID_SESSION}
        title={(session: SessionFormation | null) => getTitle(session) ?? ''}
        getId={(session: SessionFormation | null) => session?.IdHeroku as number}
        getDetail={getSession}
        loadErrorMessage="Erreur lors de récupération de la session"
        history={breadcrumb}
        sections={sections}
        getActions={getActions}
    />
}
