import { useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "app/rootReducer"
import { useAppDispatch } from "app/store"
import { FormMetadata, FormStep } from 'pages/dossier/metadata/FormMetadata'
import { DossierAPI, dossierSliceActions, DossierState } from "slices/dossierSlice"
import { BaseDossier } from "slices/dossierCommon"

export interface DossierIdParams {
    dossierId?: string
}

export interface BaseDossierComponentProps<D extends BaseDossier = any> {
    metadata: FormMetadata<D>
    step: FormStep<D>
    errorMessage?: string
}

const getDossierState = (state: RootState, effected: React.MutableRefObject<boolean>, dossierId?: string): DossierState => {
    if (effected.current || (dossierId && dossierId === state.dossierState.dossier?.NumeroDossier__c))
        return state.dossierState

    return { loading: true, saving: false, transmitting: false, dossier: null, dossierInitial: null, error: null, errorLink: null }
}

const compareDossierStates = (left: DossierState, right: DossierState) => {
    return (
        left.loading === right.loading &&
        left.saving === right.saving &&
        left.transmitting === right.transmitting &&
        left.dossier === right.dossier &&
        left.error === right.error &&
        left.errorLink === right.errorLink
    )
}

export const useDossier = <D extends BaseDossier = BaseDossier>(api: DossierAPI<D>, dossierId?: string, forceLoading = false, withPieces = false): DossierState<D> => {
    const dispatch = useAppDispatch()    
    const useEffectDone = useRef(api.isEdit ?? false)
    const dossierState = useSelector<RootState, DossierState>(state => getDossierState(state, useEffectDone, dossierId), compareDossierStates)

    useEffect(() => {
        if (!dossierId)
            dispatch(dossierSliceActions.create(api as DossierAPI<any>))
        else if (dossierId !== dossierState?.dossier?.NumeroDossier__c || ((!useEffectDone.current || api.isEdit) && forceLoading)) {
            if (withPieces)
                dispatch(dossierSliceActions.loadWithPieces({ api: (api as DossierAPI<any>), dossierId }))
            else
                dispatch(dossierSliceActions.load({ api: (api as DossierAPI<any>), dossierId }))
        }
        useEffectDone.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossierId, api, forceLoading])

    return dossierState as DossierState<D>
}
