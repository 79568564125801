
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapCurrencyField from 'pages/dossier/recap/RecapCurrencyField'
import ExportCurrencyField from 'pages/dossier/export/ExportCurrencyField'
import CurrencyField from 'components/reform/inputs/CurrencyField'
import { OptionalNumber, RequiredNumber } from 'components/yop/constraints'
import { Yop } from '@dsid-opcoatlas/yop'
import { TypeCout } from 'slices/dossierCommon'

const typeCoutSchema = Yop.object<TypeCout>({ 
    IdHeroku: OptionalNumber,
    montant: RequiredNumber.max(300_000),
}).required()

export function RequiredTypeCoutFieldMetadata(settings: SettingsFieldMetadata<TypeCout> = {}) {
    return createMetadata(
        {
            yop: typeCoutSchema,
            input: (props?: any) => <CurrencyField {...props} name={ `${props?.name ?? ''}.montant` } />,
            recap: (props?: any) => <RecapCurrencyField {...props} value={ props?.value?.montant } />,
            export: (props?: any) => <ExportCurrencyField {...props} value={ props?.value?.montant } />,
        },
        settings
    )
}
