import { PieceTypeDTO } from 'api/documentAPI'
import { DossierDetail } from 'api/dossierAPI'
import { AtlasInfo } from 'atlas-ds'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import { DetailDossierNumberoDepot } from 'pages/detail-dossier/DetailDossierNumeroDepot'
import { AppDossierModule } from 'pages/detail-dossier/modules'
import { actionsAnnulerDossier, actionsEntrepriseCorrectionAvenant, actionsEntrepriseDeposerFacture, actionsEntrepriseRompreDossier } from 'pages/detail-dossier/modules/entreprise'
import { actionsPrestataireDeposerProforma, actionsPrestataireRompreDossier, actionsPrestataireRuptureDeposerFacture } from 'pages/detail-dossier/modules/prestataire'
import { actionsEntrepriseRelanceAnnulerSubrogation, actionsEntrepriseRelanceDemanderSubrogation } from 'pages/detail-dossier/relanceff/entreprise'
import { actionsPrestataireRelanceAnnulation, actionsPrestataireRelanceAnnulerSubrogation } from 'pages/detail-dossier/relanceff/prestataire'
import { MetadataEditVAEI } from 'pages/dossier-vaei/VAEIEditFormMetadata'
import { MetadataVAEI } from 'pages/dossier-vaei/VAEIFormMetadata'
import { VAEIMAPRuptureFormMetadata, VAEIRuptureFormMetadata } from 'pages/dossier-vaei/VAEIRuptureFormMetadata'


const dossierVAEIModule: AppDossierModule = {
    name: 'VAEI',
    dispositifId: MetadataVAEI.dispositif,
    metadata: {
        entreprise: { create: MetadataVAEI, edit: MetadataEditVAEI },
    },
    extensions: {
        DetailDossierContrat: {
            infos: <DetailDossierNumberoDepot />,
        },
        entreprise: {
            DetailDossierContrat: {
                actions:  (dossier: DossierDetail, actions: JSX.Element[]) => {
                    actionsEntrepriseCorrectionAvenant(dossier, actions)
                    
                    actionsEntrepriseRompreDossier(dossier, VAEIRuptureFormMetadata, actions)
                    actionsAnnulerDossier(dossier, actions)
                    actionsEntrepriseDeposerFacture(dossier, actions)
                    
                    actionsEntrepriseRelanceDemanderSubrogation(dossier, actions)
                    actionsEntrepriseRelanceAnnulerSubrogation(dossier, actions)
                },
            },
        } as DetailDossierExtension,
        prestataire: {
            DetailDossierContrat: {
                actions: (dossier: DossierDetail, actions: JSX.Element[]) => {
                    actionsPrestataireDeposerProforma(dossier, actions)
                    actionsPrestataireRuptureDeposerFacture(dossier, actions)
                    actionsPrestataireRompreDossier(dossier, VAEIMAPRuptureFormMetadata, actions)
                    
                    actionsPrestataireRelanceAnnulerSubrogation(dossier, actions)
                    actionsPrestataireRelanceAnnulation(dossier, actions)
                }
            },
        } as DetailDossierExtension,
    } as DetailDossierExtension,
    documentTypeFilter: (docType: PieceTypeDTO) => {
        return docType.nom !== 'CERFA' && docType.nom !== 'AVENANT AU CONTRAT' && docType.nom !== 'COPIE LETTRE RUPTURE CONTRAT' 
    },
    documentWarningMessage: <AtlasInfo type="warning" title="Le CERFA a été automatiquement généré et joint à votre demande">
        <p>Merci de ne pas ajouter le CERFA original signé dans les pièces. En effet, les informations transmises sur myAtlas sont réputées conformes au contrat conclu avec le salarié.</p>
        <details>
            <summary>Une modification à transmettre ?</summary>
            <p>Pour toute modification de la demande à transmettre à l’Opco Atlas, merci d’utiliser le formulaire dédié, accessible dans la partie « Actions » en haut à droite de cette page :</p>
            <ul>
                <li>Avenant/correction : formulaire « Transmettre avenant / Corriger contrat »</li>
                <li>Rupture du contrat : formulaire « Informer la rupture du contrat »</li>
                <li>Annulation du contrat : formulaire « Annuler le dossier »</li>
            </ul>
        </details>
    </AtlasInfo>
}

export default dossierVAEIModule