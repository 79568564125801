import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PageLocation {
    title: string
    pathname: string
    search: string
}

export interface HistoryStackState {
    history: PageLocation[]
}

const initialState: HistoryStackState = { history: [] };

function firstPathSegment(path: string) {
    const index = path?.indexOf('/', 1) ?? -1;
    return index !== -1 ? path.substring(0, index) : path;
}

const historyStackSlice = createSlice({
    name: 'historyStack',
    initialState,
    reducers: {
        init(state, action: PayloadAction<PageLocation>) {
            state.history = [ action.payload ]
        },
        clear(state) {
            state.history = []
        },
        push(state, action: PayloadAction<PageLocation[]>) {
            const payload = action.payload
            if (!state.history || state.history.length < payload.length-1) {
                state.history = payload
            } else {
                const lastPathname = payload[payload.length-1].pathname
                const lastPathnameIndex = (state.history as PageLocation[]).findIndex(pageLocation => {
                    return firstPathSegment(pageLocation.pathname) === firstPathSegment(lastPathname)
                })
                if (lastPathnameIndex >= 0) {
                    state.history.splice(lastPathnameIndex, state.history.length - lastPathnameIndex, payload[payload.length-1])
                } else {
                    state.history.push(payload[payload.length-1])
                }
            }
        },
        pop(state) {
            state.history.pop()
        },
        go(state, action: PayloadAction<number>) {
            state.history.splice(action.payload + 1, state.history.length - action.payload)
        }
    },
})

export const historyStackActions = {
    init: historyStackSlice.actions.init,
    clear: historyStackSlice.actions.clear,
    push: historyStackSlice.actions.push,
    pop: historyStackSlice.actions.pop,
    go: historyStackSlice.actions.go,
}

export default historyStackSlice.reducer
