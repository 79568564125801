import classNames from "classnames";
import { SyntheticEvent, useState } from "react";

export interface MyProductProps {
  /**
   * Le nom du produit
   */
  label: string;
  /**
   * Le logo associé
   */
  logo: string;
  /**
   * La description du produit
   */
  children: string;
  /**
   * Le lien
   */
  href: string;
}

/**
 * Un lien vers un produit Atlas
 */
export function MyProduct(props: MyProductProps) {
  const [largerLogo, setLargerLogo] = useState(false);

  // Because different logo vary a lot in ratio, we need to test the width
  // of the loaded logo to make it bigger if necessary
  const adaptLogoSize = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    if ((event.target as HTMLImageElement).clientWidth < 100) {
      setLargerLogo(true);
    }
  };

  return (
    <a
      className="my-product"
      target="_blank"
      rel="nopener noreferrer"
      href={props.href}
    >
      <div
        className={classNames("my-product__logo", {
          "my-product__logo--larger": largerLogo,
        })}
      >
        <img src={props.logo} alt={props.label} onLoad={adaptLogoSize} />
      </div>
      <p>{props.children}</p>
    </a>
  );
}
