import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getNotifications, NotificationSearchQuery, NotificationsPage, updateNotification, deleteAllNotifications } from 'api/notificationAPI'
import { RootState } from 'app/rootReducer'


interface NotificationsApiState {
    pending: boolean
    notifications: NotificationsPage | null
    rejected: string | null
}

const loadNotifications = createAsyncThunk('notifications/get', 
    async (query: NotificationSearchQuery, thunkAPI) => {
        if ((query.page ?? 0) === 0)
            await thunkAPI.dispatch(notificationsActions.clear())
        return await getNotifications(query)
    }
)

const markNotificationAsRead = createAsyncThunk('notifications/markAsRead', 
    async (id: string, thunkAPI) => {
        await updateNotification(id, true)
        const { notificationsState } = thunkAPI.getState() as Pick<RootState, 'notificationsState'>
        return await getNotifications({
            page: 0,
            parPage: (notificationsState.notifications!.page + 1) * 50,
            lu: false
        })
    }
)

const delAllNotifications = createAsyncThunk('notifications/delete', 
    async (_, thunkAPI) => {
        await deleteAllNotifications()
        const { notificationsState } = thunkAPI.getState() as Pick<RootState, 'notificationsState'>
        return await getNotifications({
            page: 0,
            parPage: (notificationsState.notifications!.page + 1) * 50,
            lu: false
        })
    }
)

const slice = createSlice({
    name: 'profil',
    initialState: { pending: false, notifications: null, rejected: null } as NotificationsApiState,
    reducers: {
        clear: (state) => {
            state.pending = false
            state.notifications = null
            state.rejected = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadNotifications.pending, (state, _) => {
                state.pending = true
            })
            .addCase(loadNotifications.fulfilled, (state, action) => {
                if (!state.notifications || state.notifications?.siren !== action.payload.siren)
                    state.notifications = action.payload
                else {
                    const items = action.payload.items.filter((notification) => state.notifications!.items.find((not) => not.Id !== notification.Id))
                    state.notifications = { ...action.payload, items: state.notifications.items.concat(items) }
                }
                state.pending = false
                state.rejected = null
            })
            .addCase(loadNotifications.rejected, (state, action) => {
                state.rejected = action.error.message ?? null
                state.notifications = null
                state.pending = false
            })


        builder
            .addCase(markNotificationAsRead.pending, (state, _) => {
                state.pending = true
            })
            .addCase(markNotificationAsRead.fulfilled, (state, action) => {
                state.pending = false
                state.notifications = { ...action.payload, page: state.notifications!.page }
                state.rejected = null
            })
            .addCase(markNotificationAsRead.rejected, (state, action) => {
                state.rejected = action.error.message ?? null
                state.pending = false
            })

            builder
            .addCase(delAllNotifications.pending, (state, _) => {
                state.pending = true
            })
            .addCase(delAllNotifications.fulfilled, (state, action) => {
                state.pending = false
                state.notifications = { ...action.payload, page: state.notifications!.page }
                state.rejected = null
            })
            .addCase(delAllNotifications.rejected, (state, action) => {
                state.rejected = action.error.message ?? null
                state.pending = false
            })
    }
})


export const notificationsSlice = {
    slice
}

export const notificationsActions = {
    clear: slice.actions.clear,
    load: loadNotifications,
    markAsRead: markNotificationAsRead,
    delete: delAllNotifications
}
