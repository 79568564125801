import { References } from "api/referencesAPI"
import { RequiredBoolean } from "components/yop/constraints"
import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { employeurMetadata, employeurExtendedMetadata, SECTION_EMPLOYEUR_EXTRA, SECTION_EMPLOYEUR_CONTACT } from "pages/dossier/metadata/EmployeurMetadata"
import { ObjectMetadata } from "pages/dossier/metadata/FormMetadata"
import { BooleanFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata"
import { SelectRefFieldMetadata } from "components/metadata/RefFieldMetadata"
import { TextFieldMetadata, PhoneFieldMetadata, EmailFieldMetadata } from "components/metadata/TextFieldMetadata"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { DossierCAPPEmployeur } from "./CAPPModel"

export const CAPPEmployeurMetadata: ObjectMetadata<DossierCAPPEmployeur> = {
    ...employeurMetadata,
    codeNaf: employeurExtendedMetadata.codeNaf,
    codeIdcc: employeurExtendedMetadata.codeIdcc,
    caisseRetraiteComplementaire: TextFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Caisse de retraite complémentaire',
            tooltip: TOOLTIPS.employeur.caisseRetraiteComplementaire,
        },
        mapping: simpleMapping('CaisseRetraiteComplementaire__c'),
    }).required().mutate(yop => yop.max(80, 'Maximum 80 caractères')),
    effectifSalarie: NumberFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Effectif total salariés de l\'entreprise',
            tooltip: TOOLTIPS.employeur.effectifSalarie,
        },
        mapping: simpleMapping('NbSalariesEntreprise__c'),
    }).required().mutate(yop => yop.min(0, 'Le nombre de salariés doit être zéro ou plus')
        .max(2000000, 'Le nombre de salariés est trop élevé')),
    typeEmployeur: SelectRefFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Type d\'employeur',
            options: refs => refs.REF_TYPE_EMPLOYEUR.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            code: true,
            disabled: ctx => !ctx.parent?.Id,
        },
        mapping: simpleMapping('TypeEmployeur__c'),
    }).required(),
    employeurSpecifique: SelectRefFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Employeur spécifique',
            options: refs => refs.REF_EMPLOYEUR_SPECIFIQUE.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
            code: true,
            disabled: ctx => !ctx.parent?.Id,
        },
        mapping: simpleMapping('EmployeurSpecifique__c'),
    }).required(),
    employeurPublic: BooleanFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Employeur public',
            onChange(value, form) {
                form.setValue('employeur.regimeSpecifique', value ? false : null)
            },
            disabled: ctx => !ctx.parent?.Id,
        },
        mapping: simpleMapping('EmployeurPublic__c'),
    }).required(),
    regimeSpecifique: BooleanFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Adhésion de l’apprenti au régime spécifique d’assurance chômage',
            disabled: ctx => !ctx.parent?.Id || !ctx.parent?.employeurPublic,
        },
        mapping: simpleMapping('EmployeurAdhesionAuRegimeSpecifique__c'),
    }).mutate(yop => yop.when(ctx => ctx.parent?.employeurPublic ? RequiredBoolean : null)),
    nomContact: TextFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        props: {
            label: 'Nom',
        },
        mapping: simpleMapping('NomContactADH__c'),
    }).mutate(yop => yop.max(50)),
    prenomContact: TextFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        props: {
            label: 'Prénom',
        },
        mapping: simpleMapping('PrenomContactADH__c'),
    }).mutate(yop => yop.max(50)),
    telephone: PhoneFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        props: {
            label: 'Téléphone',
        },
        mapping: simpleMapping('TelephoneADH__c'),
    }).required(),
    email: EmailFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        props: {
            label: 'Email',
        },
        mapping: simpleMapping('EmailADH__c'),
    }).required(),
}
