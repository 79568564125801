import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { MyTags } from 'atlas-ds'

interface SelectedTextProps {
    label: string
    name: string
    options?: any[]
    defaultValue?: string
    value?: (val: any) => string
    hideRemove?: boolean
}

export default function SelectedText(props: SelectedTextProps) {
    const context = useFormContext()
    const value = getFieldState<string>(context, props.name)?.value ?? ''

    const defaultValue = props.defaultValue ?? ''
    if (value === defaultValue)
        return null

    let selectedValue = value
    if (props.value)
        selectedValue = props.value(selectedValue)
    if (props.options)
        selectedValue = props.options.find(opt => opt.value === selectedValue)?.label ?? ''

    return (
        <MyTags.Item
            label={ props.label }
            delete={props.hideRemove ? undefined : {
                ariaLabel: `Retirer le filtre ${selectedValue}`,
                onDelete: () => context.setValue(props.name, defaultValue, true)
            }}
        >{ selectedValue }</MyTags.Item>
    )
}
