import { Dossier } from "api/dossierAPI"
import { ETAT_DOSSIER_BROUILLON } from "api/references"
import useDossiersModuleContext from 'app/entreprise/mes-dossiers/useDossiersModuleContext'
import { AtlasButton } from "atlas-ds"
import Link from "components/Link"
import { getDossierCreationPath } from "pages/dossier/useDossierNavigation"
import { useHistory } from "react-router-dom"


export interface ActionsLinkProps {
    disabled?: boolean
    dossier: Dossier
}

export default function XLinkDossier(props: ActionsLinkProps) {
    const history = useHistory()
    const ctx = useDossiersModuleContext()

    return (
        props.dossier.EtatDossierFO__c === ETAT_DOSSIER_BROUILLON ?
        <AtlasButton
            key="finaliser"
            icon="details"
            level={ 3 }
            onClick={() => history.push(getDossierCreationPath(props.dossier, ctx!))}
        >Finaliser</AtlasButton> :

        props.disabled ?
        <AtlasButton
            key="detail"
            icon="details"
            level={ 3 }
            disabled={ true }
        >Voir le détail</AtlasButton> :

        <Link
            icon="details"
            to={ `/detail-dossier-contrat/${props.dossier.Id}` }
            level={ 3 }
        >Voir le détail</Link>
    )
}
