import axios, { AxiosRequestConfig } from 'axios'
import store from 'app/store'
import { maintenanceOn } from 'slices/maintenanceSlice'
import { dispatchErrorEvent } from 'app/events'

export const HTTP_ERROR_RESPONSE = "http-error-response"


axios.defaults.baseURL = import.meta.env.REACT_APP_API_ENDPOINT
axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY
axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error?.response?.config?.url === '/stagiaires/verifier-nir')
            return Promise.reject(error)

        const status = error?.response?.status
        if (status != null) {
            if (status === 503 && error?.response?.data?.includes("maintenance-mode"))
                store.dispatch(maintenanceOn())
            else if (error?.config?.ignoreErrors !== true && ![400, 409, 425, 502].includes(status) && error?.response?.data?.message)
                dispatchErrorEvent(HTTP_ERROR_RESPONSE, error!.response!)
        }

        return Promise.reject(error)
    }
)

export const axiosSalesforce = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT_SF,
    headers: {
        'Ocp-Apim-Subscription-Key': import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY_SF,
        'Ocp-Apim-Trace': import.meta.env.REACT_APP_API_TRACE,
        'Accept-Language': import.meta.env.REACT_APP_API_LANGUAGE ?? "fr",
    }
})

export const axiosActu = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT_ACTU,
    headers: {
        'Ocp-Apim-Subscription-Key': import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY_ACTU,
        'Ocp-Apim-Trace': import.meta.env.REACT_APP_API_TRACE,
        'X-API-Key': import.meta.env.REACT_APP_API_X_API_Key
    }
})

export const axiosSimu = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT_SIMU,
    headers: {
        'Ocp-Apim-Subscription-Key': import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY_SIMU,
        'Ocp-Apim-Trace': import.meta.env.REACT_APP_API_TRACE,
        'X-API-Key': import.meta.env.REACT_APP_API_X_API_Key
    }
})

export const axiosDiag = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT_DIAG,
    headers: {
        'Ocp-Apim-Subscription-Key': import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY_DIAG,
        'Ocp-Apim-Trace': import.meta.env.REACT_APP_API_TRACE,
        'X-API-Key': import.meta.env.REACT_APP_API_X_API_Key
    }
})

export const axiosMamc = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT_MAMC_PUBLIC,
    headers: {
        'Ocp-Apim-Subscription-Key': import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY_MAMC_PUBLIC,
        'Ocp-Apim-Trace': import.meta.env.REACT_APP_API_TRACE,
        'X-API-Key': import.meta.env.REACT_APP_API_X_API_Key
    }
})

export const axiosMamcPrivate = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ENDPOINT_MAMC,
    headers: {
        'Ocp-Apim-Subscription-Key': import.meta.env.REACT_APP_API_SUBSCRIPTION_KEY_MAMC,
        'Ocp-Apim-Trace': import.meta.env.REACT_APP_API_TRACE,
        'X-API-Key': import.meta.env.REACT_APP_API_X_API_Key
    }
})

// axios.interceptors.request.use(request => {
//    log.debug('Starting Request:', JSON.stringify(request, null, 2))
//    return request
// })

export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>

export async function getRequestConfig(): Promise<WithRequired<AxiosRequestConfig, "headers">> {
    const headers: any = {
    }
    if (store.getState().contextState.app)
        headers['Atlas-Context'] = store.getState().contextState.app
    return { headers }
}


export interface BaseSearchQuery {
    page: number
    parPage?: number
    tri?: string
    ordre?: string
    reload?: boolean
}

export interface BaseSearchQuery2 extends BaseSearchQuery {
    page2: number
    parPage2?: number
}

export interface Page<T> {
    alertCount?: number
    totalCount: number
    items: T[]
}

export function emptyPage<T>(): Page<T> {
    return {
        totalCount: 0,
        items: []
    }
}


export function createFormData(data: { [key: string]: string | { file: File | Blob, name?: string } | null | undefined }) {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
        const value = data[key]
        if (value != null) {
            if (typeof value === "string")
                formData.append(key, value)
            else
                formData.append(key, value.file, value.name)
        }
    })
    return formData
}

export type SuccessResponse = {
    success?: boolean
    message?: string
}

