import store from 'app/store'
import axios from 'axios'
import log from 'loglevel'
import { sleep } from 'slices/dossierSlice'
import { BaseSearchQuery, getRequestConfig, Page } from "./baseAPI"
import { parseNumber } from './util'

const TIMEOUT_POLLING = parseNumber(import.meta.env.REACT_APP_TIMEOUT_POLLING_REF_BANCAIRE_SECONDES, 1 * 60) * 1000

export interface RefBancaireDTO {
    Id: string
    banque: string
    guichet: string
    iban: string
    bic: string
    dispositif: string
    dateCreation: string | null
    creePar: string | null
    siret: string
    etablissement: string
    domiciliation: string
    adresse: string
    codePostal: string
    commune: string
    statut: string
    paiement: boolean
}


export const REF_TYPE_REGLEMENT = [
    { Id: '4', Libelle: 'Tous dispositifs' },
    { Id: '56', Libelle: 'Contrat apprentissage' },
    { Id: '5', Libelle: 'Plan de développement des compétences' },
    { Id: '6', Libelle: 'Contrat de professionnalisation' },
    { Id: '66', Libelle: 'Contrat PROA' },
]

export const findTypeReglement = (id: string) => {
    return REF_TYPE_REGLEMENT.find(t => t.Id === id)
}


export interface RefsBancairesSearchQuery extends BaseSearchQuery {
    iban?: string
    dispositif?: string
    dateCreation?: string
    etablissement?: string
}

function getSearchRefsBancairesParams(query: RefsBancairesSearchQuery): URLSearchParams {
    const params = new URLSearchParams()

    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }
    // if (query.recherche)
    //     params.append('recherche', query.recherche.trim())
    return params
}

const convertRefBancaire = (elt: any) => ({
    Id: elt.Id.toString(),
    statut: elt.Actif ? 'Actif' : 'Inactif',
    banque: elt.RIB?.Banque ?? '',
    guichet: elt.RIB?.Guichet ?? '',
    iban: elt.RIB?.Iban ?? '',
    bic: elt.RIB?.Bic ?? '',
    dispositif: elt.TypeTransaction?.Id?.toString(),
    siret: elt.Siret + '',
    etablissement: elt.Siret + '',
    dateCreation: elt.DateCreation,
    creePar: elt.Commentaire != null ? elt.Commentaire.replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/, "") : null,
    paiement: false,
    domiciliation: (elt.RIB?.Banque ?? '') + '\n ' + (elt.RIB?.Guichet ?? ''),
    adresse: elt.Adresse?.Libelle3 ?? '',
    codePostal: elt.Adresse?.CodePostal ?? '',
    commune: elt.Adresse?.Ville ?? '',
} as RefBancaireDTO)

export async function getRefsBancaires(query: RefsBancairesSearchQuery): Promise<Page<RefBancaireDTO>> {
    const requestConfig = await getRequestConfig()

    requestConfig.params = getSearchRefsBancairesParams(query)

    const eid = store.getState().contextState.entreprise?.Id ?? ''

    const { data, headers } = await axios.get<any>('/entreprises/' + eid + '/ref-bancaires', requestConfig)

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length

    return {
        totalCount: totalCount,
        items: data.map(convertRefBancaire)
    }
}

export async function checkEtablissementDispositifDoublon(etablissementId: string, dispositifId: string): Promise<string> {
    const requestConfig = await getRequestConfig()
    
    const params = new URLSearchParams()
    params.set('id', etablissementId)
    params.set('dispositif', dispositifId)
    requestConfig.params = params

    const { data } = await axios.get<string>("/ref-bancaires/verif-dispositif", requestConfig)

    return data ?? ""
}

export type IbanDTO = {
    IBAN: string
    Etablissement: string
    TypeReglement: string
}

export async function startIbanChecking(iban: IbanDTO): Promise<string> {
    const requestConfig = await getRequestConfig()
    
    const params = new URLSearchParams()
    params.set('id', iban.Etablissement)
    requestConfig.params = params

    const { data } = await axios.post<string>("/ref-bancaires/verif-iban", iban, requestConfig)

    return data ?? ""
}

type ReferenceBancaireContact = {
    Id: string | null
    Salutation: string
    FirstName: string
    LastName: string
    Email: string
    Phone: string
}

export type SorIbanDTO = {
    Contact: ReferenceBancaireContact
    TypeReglement: string
}

export type RefBancairePollingDTO = {
    IbanVerificationResult: {
        Iban: {
            Iban: string
            Status: string
            Status_Verification: string
            StatusLabel: string
            Match: string
            AccountKey: string
            IbanKey: string
            BankIdentifier: string
            BranchIdentifier: string
            AccountNumber: string
            Bic: string
            InstitutionName: string
            BranchInformation: string
            StreetAddress1: string
            StreetAddress2: string
            StreetAddress3: string
            StreetAddress4: string
            City: string
            Cps: string
            ZipCode: string 
            CountryName: string
            IsoCountryCode: string
            ParentInstitutionName: string
            SEPAMailNetwork: {
                Diamond: string
                AigueMarine: string
                Rubis: string
            }
        }
        Siret__c: string
        IdVerification: string
        DuplicateRib: boolean
    }
    Message: string
    Id: string
    Error_Message: string
    isVerified: boolean
}

export async function pollIbanChecking(etablissementId: string, lucyId: string, iban: SorIbanDTO): Promise<RefBancairePollingDTO> {
    const requestConfig = await getRequestConfig()
    
    const params = new URLSearchParams()
    params.set('id-lucy', lucyId)
    requestConfig.params = params

    const { data } = await axios.post<RefBancairePollingDTO>(`/entreprises/${ etablissementId }/ref-bancaires`, iban, requestConfig)

    return data
}

export async function createReferenceBancaire(etablissementId: string, dispositifId: string, iban: string, contact: ReferenceBancaireContact): Promise<RefBancairePollingDTO> {
    try {
        const lucyId = await startIbanChecking({ Etablissement: etablissementId, TypeReglement: dispositifId, IBAN: iban })

        const pollingInterval = 5 * 1000 // 5s
        for (let i = 0; i <= TIMEOUT_POLLING / pollingInterval; i++) {
            await sleep(5000)

            const ref = await pollIbanChecking(etablissementId, lucyId, {
                TypeReglement: dispositifId,
                Contact: contact
            })

            if (ref.isVerified)
                return ref
        }
    } catch (err: any) {
        const status = err.response?.status
        if (status === 404) {
            logMessage("warning", `La vérification de l'IBAN a échoué {
                etablissementId: ${etablissementId},
                dispositifId: ${dispositifId},
                iban: ${iban},
                contact: ${contact.FirstName} ${contact.LastName} - ${contact.Phone} - ${contact.Email},
                message: ${err.message},
            }`)
        }
        else if (status === 409) {
            throw { status: 409, message: err.response?.data?.message ?? "Un IBAN identique existe déjà"}
        }

        return Promise.reject({
            isAxiosError: true,
            response: { data: { message: "La vérification de l'IBAN a échoué" } }
        })
    }

    logMessage("warning", `La vérification de l'IBAN a mis trop de temps {
        etablissementId: ${etablissementId},
        dispositifId: ${dispositifId},
        iban: ${iban},
        contact: ${contact.FirstName} ${contact.LastName} - ${contact.Phone} - ${contact.Email},
    }`)

    return Promise.reject({
        isAxiosError: true,
        response: { data: { message: "La vérification de l'IBAN a mis trop de temps" } }
    })
}

export async function postAddRefBancaire(refBancaire: any): Promise<any> {
    const requestConfig = await getRequestConfig()

    const { data } = await axios.post<any>(`/entreprises/${refBancaire.Etablissement}/ref-bancaires`, refBancaire, requestConfig)

    return data
}

export async function patchDesactiverRefBancaire(refBancaire: any): Promise<any> {
    const requestConfig = await getRequestConfig()

    const eid = store.getState().contextState.entreprise?.Id ?? ''
    const { data } = await axios.patch<any>(`/entreprises/${eid}/ref-bancaires?transactionId=${refBancaire.Id}`, null, requestConfig)

    return data
}

export async function logMessage(type: "info" | "warning" | "error", message: string) {
    const requestConfig = await getRequestConfig()

    try {
        await axios.post<any>("/ref-bancaires/log", { type, message }, requestConfig)
    }
    catch (e) {
        log.error("Echec du log back", type, message)
    }
}

export interface VerifIbanDTO {
    IBAN: string,
    Etablissement: string,
    TypeReglement: string
}

export interface VerifIbanResult {
    Duplicate: boolean,
    IBAN: string,
    BIC: string,
    Banque: any,
}

export async function postValiderRefBancaire(verif: VerifIbanDTO): Promise<VerifIbanResult> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.append('id', verif.Etablissement ?? '')
    requestConfig.params = params

    const response = await axios.post<any>('/ref-bancaires/verif-iban', verif, requestConfig)
    if (response.status === 200) {
        const rep = response.data
        if (rep.Iban && rep.Iban.statusLabel === 'IBAN valide') {
            return {
                Duplicate: !!rep.DuplicateRib,
                IBAN: rep.Iban.Iban,
                BIC: rep.Iban.Bic,
                Banque: {
                    ID: rep.Iban.BankIdentifier,
                    Nom: rep.Iban.InstitutionName,
                    AgenceID: rep.Iban.BranchIdentifier,
                    AgenceNom: rep.Iban.BranchInformation,
                    Adresse1: rep.Iban.StreetAddress1,
                    Adresse2: rep.Iban.StreetAddress2,
                    Adresse3: rep.Iban.StreetAddress3,
                    Adresse4: rep.Iban.StreetAddress4,
                    Ville: rep.Iban.City,
                    CodePostal: rep.Iban.Cps,
                    Pays: rep.Iban.Pays,
                },
            }
        }
    }

    throw new Error('invalid')
}