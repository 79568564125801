import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import Alternance from 'pages/alternance/Alternance'

const alternanceModule = {
    id: 'alternance',
    routes: [
        <AppRoute key="alternance" path="/alternance" label="Alternance" menuIcon="alternance">
            <Alternance />
        </AppRoute>,
    ],
} as AppModule

export default alternanceModule