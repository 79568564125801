import { AtlasInfo, AtlasLoading, AtlasSpacer, MyTable } from 'atlas-ds'
import { formatApiDate } from 'components/format/Format'
import { BaseDossier } from 'slices/dossierCommon'
import { piecesSubmitDisabled } from '../elements/DossierPiecesElements'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapPieces<D extends BaseDossier>(props: RecapProps<D>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)
    
    const pieces = props.dossier?.pieces?.filter(piece => piece.dateAjout) ?? []
    const piecesEtape = steps.indexOf(props.step) + 1
    
    return (<>
        <RecapSection
            title={ props.step.title ?? 'Pièces' }
            onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, piecesEtape) }
        >
            <AtlasLoading loading={props.loading ?? false}>
                <MyTable
                    caption="Pièces"
                    emptyText="Aucune pièce"
                    rowKey={ doc => 'doc-' + doc.type }
                    rows={ pieces }
                    columns={[
                        {
                            id: "type",
                            label: "Type de document",
                            wrap: true
                        },
                        {
                            id: "dateAjout",
                            label: "Date d'ajout",
                            value: (row: any) => formatApiDate(row.dateAjout)
                        }
                    ]}
                />
            </AtlasLoading>

            { (props.dossier && piecesSubmitDisabled(props.dossier, props.loading ?? false)) && <>
                <AtlasSpacer />
                <AtlasInfo type="error" title="Erreur">
                    Des pièces obligatoires sont manquantes
                </AtlasInfo>
            </> }
        </RecapSection>
    </> )
}