import { Page, emptyPage } from 'api/baseAPI'
import { Identifiable } from 'api/dossierAPI'
import { useAppDispatch } from 'app/store'
import { AtlasLoading, MyTab } from 'atlas-ds'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { setQuery } from 'slices/recherchesSlice'

type Tab<T, Q> = {
    label: (count: number) => string,
    executeQuery: (query: Q) => Promise<Page<T>>
}

export function singleTab<T, Q>(executeQuery: (query: Q) => Promise<Page<T>>): Tab<T, Q>[] {
    return [{
        label: (_: number) => '',
        executeQuery: executeQuery
    }]
}

interface TabsProps<T, Q> {
    tabs: Tab<T, Q>[]
    name: string
    query: Q
    tabIndex: number
    pendings: React.MutableRefObject<boolean[]>
    setIndexedPending: (index: number, pending: boolean) => void,
    setIndexedError: (index: number, error: string | null) => void,
    setIndexedResultsPage: (index: number, page: Page<T>) => void,
    selectIndexedTab: (index: number, page: Page<T>, error: string | null) => void
}

export default function getTabs<
    T extends Identifiable,
    Q extends BaseSearchQueryImpl
>(props: TabsProps<T, Q>) {
    return props.tabs.map((tab, index) => <TabItem<T, Q>
        key={ 'tab-' + index }
        index={ index }
        first={ index === 0 }
        last={ index === props.tabs.length - 1}
        name={ props.name }
        label={ tab.label }
        active={ props.tabIndex === index }
        query={ props.query }
        executeQuery={ tab.executeQuery }
        pending={ props.pendings.current[index] }
        setPending={ (value) => props.setIndexedPending(index, value) }
        setResultPage={ (page) => props.setIndexedResultsPage(index, page) }
        setError={ (message) => props.setIndexedError(index, message) }
        selectTab={ (page, error) => props.selectIndexedTab(index, page, error) }
    />)
}


function TabItem<T extends Identifiable, Q extends BaseSearchQueryImpl>(props: {
    name: string,
    index: number,
    first: boolean,
    last: boolean,
    label: (count: number) => string,
    active: boolean,
    query: Q,
    executeQuery: (query: Q) => Promise<Page<T>>,
    pending: boolean,
    setPending: (pending: boolean) => void,
    setError: (error: string | null) => void,
    setResultPage: (page: Page<T>) => void,
    selectTab: (page: Page<T>, error: string | null) => void
}) {

    const location = useLocation<any>()
    const dispatch = useAppDispatch()

    const error = useRef<string | null>(null)
    const resultsPage = useRef(emptyPage<T>())

    useEffect(() => {
        if (typeof location.state?.tabIndex === 'number' && location.state!.tabIndex !== props.index)
            return
        
        props.setPending(true)
        
        error.current = null
        props.setError(null)
        
        let currentQuery = props.query
        let currentItems = resultsPage.current.items
        if (props.query.getPage(props.index) === 0) {
            if (resultsPage.current.items.length > 0) {
                resultsPage.current = emptyPage<T>()
                props.setResultPage(emptyPage<T>())
                currentItems = []
            }
        }
        else if (resultsPage.current.items.length === 0) { // && query.getPage(props.index) > 0
            currentQuery = props.query.clone() as Q
            currentQuery.setPage(props.index, 0)
            currentQuery.setParPage(props.index, (props.query.page + 1) * props.query.parPage)
        }
        
        props.executeQuery(currentQuery)
            .then((page) => {
                if (currentItems.length > 0) {
                    const ids = new Set(currentItems.map((value) => value.Id))
                    page.items = page.items.filter((d: any) => !ids.has(d.Id))
                }
                const result = {
                    alertCount: page.alertCount,
                    totalCount: page.totalCount,
                    items: currentItems.concat(page.items)
                }
                
                resultsPage.current = result
                props.setResultPage(result)
                
                if (props.active)
                    dispatch(setQuery({ name: props.name, query: props.query.toSearchParams(true).toString() }))
            })
            .catch((error) => {
                let message = 'Une erreur inattendue s\'est produite'
                if (error.isAxiosError)
                    message = error?.response?.data?.message ?? message
                
                resultsPage.current = emptyPage<T>()
                props.setResultPage(emptyPage<T>())

                error.current = message
                props.setError(message)
            })
            .finally(() => {
                props.setPending(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return <AtlasLoading loading={props.pending}>
        <MyTab
            active={props.active}
            onClick={ () => props.selectTab(resultsPage.current, error.current) }
            disabled={props.pending}
            first={props.first}
            last={props.last}
        >{ props.label(resultsPage.current.totalCount) }</MyTab>
    </AtlasLoading>
}
