import { useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasButton, MyForm } from 'atlas-ds'
import PseudoTextField from 'components/reform/inputs/PseudoTextField'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { useEffect, useState } from 'react'
import { coutPedagogiqueTotal, dateDebutCycleFormation, dateFinCycleFormation, dureeHeureEvaluation } from './CPROFormMetadata'
import { SECTION_MODULE_AFEST, SECTION_MODULE_AFEST_AGORA, SECTION_MODULE_PRINCIPAL, SECTION_MODULE_PRINCIPAL_AGORA, createDossierCPROModuleAfest } from './CPROFormationMetadata'
import { DossierCPRO } from './CPROModel'

export function DossierCPROFormationElements(props: { metadata: FormMetadata<DossierCPRO> }) {

    const context = useFormContext<DossierCPRO>()
    const [moduleAfest, setModuleAfest] = useState(context.values?.moduleAfest != null)

    const addModuleAfest = () => {
        context.setValue('moduleAfest', createDossierCPROModuleAfest(props.metadata.api, context.values!))
        setModuleAfest(true)
    }

    const removeModuleAfest = () => {
        context.setValue('moduleAfest', null)
        setModuleAfest(false)
    }

    useEffect(() => {
        if (context.values?.moduleAfest != null && moduleAfest !== true)
            setModuleAfest(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.values?.moduleAfest])

    return (
        <>
            <MyForm.Fieldset primary legend="Premier module de formation">
                <div>Saisissez le module par lequel débute le parcours de formation (en dehors du module AFEST).</div>
                
                <MyForm.Grid>
                    <FormElements section={ SECTION_MODULE_PRINCIPAL } metadata={ props.metadata } path="modulePrincipal" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>

            { !props.metadata.api.isEdit &&
            <MyForm.Fieldset legend="Informations complémentaires AGORA" tooltipContent={ TOOLTIPS.capp.formation.agora }>
                <MyForm.Grid>
                    <FormElements section={ SECTION_MODULE_PRINCIPAL_AGORA } metadata={ props.metadata } path="modulePrincipal" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }

            { moduleAfest
            ?
            <>
                <MyForm.Fieldset
                    primary
                    legend="Second module de formation AFEST"
                    button={ <AtlasButton icon='trash' onClick={ removeModuleAfest }>Retirer</AtlasButton> }>
                    <div>Saisissez le module complémentaire AFEST (Action de Formation En Situation de Travail). Ce module devra se dérouler dans votre
                        entreprise et ne peut pas être payé directement par l'OPCO à l'organisme de formation (subrogation).</div>
                    <MyForm.Grid>
                        <FormElements section={ SECTION_MODULE_AFEST } metadata={ props.metadata } path="moduleAfest" type="input" />
                    </MyForm.Grid>
                </MyForm.Fieldset>

                { !props.metadata.api.isEdit &&
                <MyForm.Fieldset legend="Informations complémentaires AGORA" tooltipContent={ TOOLTIPS.capp.formation.agora }>
                    <MyForm.Grid>
                        <FormElements section={ SECTION_MODULE_AFEST_AGORA } metadata={ props.metadata } path="moduleAfest" type="input" />
                    </MyForm.Grid>
                </MyForm.Fieldset>
                }

                <MyForm.Fieldset primary legend="Synthèse du parcours de formation">
                    <MyForm.Grid>
                        <PseudoTextField
                            label="Durée totale des actions d'évaluation, d'accompagnement et des enseignements"
                            value={ dureeHeureEvaluation(context.values)?.toString() }
                            suffix="heures"
                        />

                        <PseudoTextField
                            label="Dont durée des enseignements généraux, professionnels et technologiques"
                            value={ context.values?.modulePrincipal?.dureeEnseignement ?? 0 }
                            suffix="heures" />

                        <PseudoTextField
                            label="Date de début du cycle de formation"
                            type="date"
                            value={ dateDebutCycleFormation(context.values) } />

                        <PseudoTextField
                            label="Date prévue de fin des épreuves ou des examens"
                            type="date"
                            value={ dateFinCycleFormation(context.values) } />

                        <PseudoTextField
                            label="Coût pédagogique total"
                            value={ coutPedagogiqueTotal(context.values) }
                            suffix="€ HT" />
                    </MyForm.Grid>
                </MyForm.Fieldset>
            </>
            :
            <AtlasButton icon="plus" onClick={ addModuleAfest } level={ 2 }>
                Ajouter un module de formation AFEST
            </AtlasButton>
            }
        </>
    )
}

