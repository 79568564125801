
import DateField from 'components/reform/inputs/DateField'
import { createMetadata, FieldMetadata, merge, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import ExportTextField from 'pages/dossier/export/ExportTextField'
import RecapTextField from 'pages/dossier/recap/RecapTextField'
import { Ignored, OptionalDate } from 'components/yop/constraints'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'
import { format, parseISO, startOfYear } from 'date-fns'
import { DateSchema } from '@dsid-opcoatlas/yop'
import { FieldMapping } from 'pages/dossier/metadata/DossierMapping'

export const dateConverter = (value: Date | null) => {
    return typeof value === 'string' ? parseISO(value) : value
}

export const dateReverter = (value: any) => {
    return value instanceof Date ? format(value, 'yyyy-MM-dd') as any : value
}

export function DateFieldMetadata(settings: SettingsFieldMetadata<Date | null, { type?: string }> = {}) {
    return createMetadata(
        {
            props: {
                type: 'date',
            },
            convert: dateConverter,
            revert: dateReverter,
            diff: (d1: Date | null, d2: Date | null) => [d1, d2],
            yop: OptionalDate,
            input: DateField,
            recap: RecapTextField,
            export: ExportTextField,
            cerfa: CerfaTextField,
        },
        settings,
    )
}

export function IgnoredDateFieldMetadata<P = any>(mapping?: FieldMapping<Date | null>, mappedValue?: (context: { root: any, parent: P, value: Date | null }) => any): FieldMetadata<Date | null> {
    return merge(
        {
            convert: dateConverter,
            revert: dateReverter,
            yop: () => Ignored,
            input: undefined,
            recap: undefined,
            export: undefined,
            cerfa: undefined,
        },
        { mapping, mappedValue },
    )
}

const minDateNaissance = new Date(1900, 0, 1)
const maxDateNaissance = startOfYear(new Date())

export function dateNaissanceValidation<T extends Date | null | undefined>(yop: DateSchema<T>, messageMin?: string, messageMax?: string) { return yop
    .min(minDateNaissance, messageMin ?? 'La date de naissance doit être postérieure au 1er janvier 1900')
    .max(maxDateNaissance, messageMax ?? 'La date de naissance ne peut pas être dans le futur')
}

export function DateNaissanceFieldMetadata(settings: SettingsFieldMetadata<Date | null> = {}) {
    return DateFieldMetadata(settings).mutate(dateNaissanceValidation)
}
