import { CheckboxFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata"
import { startOfToday, subYears } from "date-fns"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { FormMetadata, ObjectMetadata, override } from "pages/dossier/metadata/FormMetadata"
import { tuteurFields } from "pages/dossier/metadata/TuteursMetadata"
import { DossierCPRO, DossierCPROTuteur, createDossierCPROTuteur } from "./CPROModel"
import { UseFormReturn } from "@dsid-opcoatlas/reform"
import { BaseDossier, BaseDossierTuteur, nil } from "slices/dossierCommon"
import { getTuteurs } from "api/commonAPI"
import { parseApiDate } from "api/util"

export const tuteur16ans = subYears(startOfToday(), 18)

export async function searchTuteur(form: UseFormReturn<BaseDossier>, recherche: string, metadata: FormMetadata<BaseDossier>): Promise<BaseDossierTuteur[]> {
    const idEntreprise = form.values?.employeur?.ParentId
    if (idEntreprise == null)
        return []

    const tuteurs = await getTuteurs(idEntreprise, recherche)
    return tuteurs.map(tuteur => ({
        ...metadata.api.createTuteur!(),
        IdContact: tuteur.Id,
        nomNaissance: tuteur.LastName,
        nom: tuteur.LastName,
        prenom: tuteur.FirstName,
        sexe: tuteur.Salutation,
        courriel: tuteur.Email,
        dateNaissance: parseApiDate(tuteur.DateNaissance__c as any) as Date,
        employeurDirigeant: nil as boolean,
    }))
}

export const CPROTuteurMetadata: ObjectMetadata<DossierCPROTuteur> = {
    nomNaissance: tuteurFields.nomNaissance(searchTuteur),
    nom: tuteurFields.nom,
    prenom: tuteurFields.prenom,
    dateNaissance: override(tuteurFields.dateNaissance, { props: {
        tooltip: TOOLTIPS.cpro.tuteur.dateNaissance,
    }}).mutate(yop => yop.max(tuteur16ans, "Le tuteur doit avoir au moins 16 ans")),
    emploiOccupe: tuteurFields.emploiOccupe,
    employeurDirigeant: tuteurFields.employeurDirigeant,
    exerciceFonction2Annees: override(tuteurFields.exerciceFonction2Annees, {
        props: {
            label: 'Tutorat exercé au cours des 2 dernières années',
            disabled: false,
        },
        mapping: simpleMapping('Exercice2AnneesTuteur__c'),
    }),
    anneesExperience: NumberFieldMetadata({
        props: {
            label: "Nombre d'années d'expérience",
        },
        mapping: simpleMapping('NbAnneeExperienceTuteur__c'),
    }).mutate(yop => yop.max(99)),
    salariesSuivis: NumberFieldMetadata({
        props: {
            label: "Nombre d'alternants actuellement suivis par le tuteur",
        },
        mapping: simpleMapping('NbSalariesSuiviTuteur__c'),
    }).mutate(yop => yop.min(1).max(99)),
    formation: tuteurFields.formation,
    dateFormation: tuteurFields.dateFormation.mutate(yop => yop.max(startOfToday(), "La date de formation doit être dans le passé")),
    priseEnChargeFonctionTutorale: tuteurFields.priseEnChargeFonctionTutorale,
    eligibilite: CheckboxFieldMetadata({
        props: {
            label: "L'employeur atteste sur l'honneur que le tuteur répond à l'ensemble des critères d'éligibilité à cette fonction.",
            tooltip: TOOLTIPS.cpro.maitre.eligibilite,
            full: true
        },
        mapping: simpleMapping('EmployeurAttesteSurHonneurTuteur__c'),
    }).required().mutate(yop => yop.oneOf([true], "Veuillez cocher cette case pour confirmer l'éligibilité du tuteur")),
}

export function dossierCPROTuteurInitializer<D extends DossierCPRO>(dossier: D) {
    if (dossier.salarie == null)
        dossier.tuteur = createDossierCPROTuteur()
    return dossier
}
