import { getFieldState, useFormContext, UseFormReturn } from '@dsid-opcoatlas/reform'
import { Identifiable } from 'api/dossierAPI'
import { DATE_FORMAT } from 'app/constants'
import { AtlasFieldPeriodPreset } from 'atlas-ds'
import SelectedCheckboxes from 'components/reform/SelectedCheckboxes'
import SelectedPeriod from 'components/reform/SelectedPeriod'
import SelectedText from 'components/reform/SelectedText'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import DropdownCheckboxes from 'components/reform/inputs/DropdownCheckboxes'
import DropdownDateRangePicker, { DateTypeOption } from 'components/reform/inputs/DropdownDateRangePicker'
import RadioField, { RadioOption } from 'components/reform/inputs/RadioField'
import TextField from 'components/reform/inputs/TextField'
import { addYears, format, parseISO, startOfYear } from 'date-fns'
import { dateTypeOptions } from 'pages/mes-dossiers-contrats/MesDossiersContrats'


export interface Filtre {
    input: JSX.Element
    filtre: (values: any, hideRemove?: boolean) => JSX.Element
    defaultValue: any
    initialValue: (values: any) => any
    valueToParam: (values: any, params: URLSearchParams) => void
    paramToValue: (params: URLSearchParams) => any
}

const decapitalize = (text: string) => text.charAt(0).toLowerCase() + text.slice(1)

export const FiltreRecherche = (props: { name?: string, label: string, placeholder: string }) => {
    const name = props.name ?? 'recherche'

    return {
        input: <TextField key={ name } name={ name } label={ props.label } placeholder={ props.placeholder }
            onChange={ (_, context) => context.renderForm() } />,
        filtre: (values: any, hideRemove?: boolean) =>
            <SelectedText key={ name } name={ name } label={ decapitalize(props.label) } hideRemove={ hideRemove } />,
        defaultValue: { [name]: '' },
        initialValue: (query: any) => { 
            return { [name]: query[name] || null } 
        },
        valueToParam: (values: any, params: URLSearchParams) => { 
            params.set(name, values[name] ?? '') 
        },
        paramToValue: (params: URLSearchParams) => { 
            return { [name]: params.get(name) ?? undefined }
        },
    }
}

type FiltreAutosuggestProps<T extends Identifiable> = {
    label: string,
    placeholder: string,
    name: string,
    getSuggestionText: (v: T | null) => string,
    getSuggestions: (recherche: string) => Promise<T[]>
    onSelectSuggestion?: ((value: T | null, form: UseFormReturn<T>) => void)
}

function FiltreAutocompleteField<T extends Identifiable>(props: FiltreAutosuggestProps<T>) {
    const form = useFormContext<T>()
    const fieldState = getFieldState<string | null>(form, props.name)

    return (
        <AutocompleteBaseField<T>
            label={ props.label }
            defaultValue={ fieldState.value ?? '' }
            placeholder={ props.placeholder }
            required={ fieldState.constraints.required }
            error={ fieldState.error }
            disabled={ false }
            onSelect={ value => props.onSelectSuggestion?.(value, form) }
            onBlur={ value => form.setValue(props.name, value === "" ? null : form.getValue(props.name), true) }
            
            search={ props.getSuggestions }
            
            optionFor={ result => ({
                id: result.Id,
                content: props.getSuggestionText(result)
            })}
        />
    )
}

export function FiltreAutosuggest<T extends Identifiable>(props: FiltreAutosuggestProps<T>) {
    return {
        input: <FiltreAutocompleteField key={ props.name } { ...props } />,
        filtre: (values: any, hideRemove?: boolean) =>
            <SelectedText key={ props.name } name={ props.name } label={ decapitalize(props.label) } hideRemove={ hideRemove } />,
        defaultValue: { [props.name]: '' },
        initialValue: (query: any) => { 
            return { [props.name]: query[props.name] ?? '' } 
        },
        valueToParam: (values: any, params: URLSearchParams) => { 
            params.set(props.name, values[props.name] ?? '') 
        },
        paramToValue: (params: URLSearchParams) => { 
            return { [props.name]: params.get(props.name) ?? undefined }
        },
    }
}

export const FiltrePeriode = (props: { label: string, placeholder: string, minDate?: Date, dateTypeOptions?: DateTypeOption[], defaultDateType?: string | DateTypeOption, dateRanges: AtlasFieldPeriodPreset[] }) => {
    return {
        input: <DropdownDateRangePicker
            key="periode" name="periode" label={ props.label } placeholder={ props.placeholder }
            dateTypeOptions={ props.dateTypeOptions } ranges={ props.dateRanges }
            minDate={ props.minDate ?? new Date(1980, 0, 1) } maxDate={ addYears(startOfYear(Date.now()), 30) } />,
        filtre: (values: any) => <SelectedPeriod key="periode" name="periode"
            label={ props.dateTypeOptions ? "période de " + (props.dateTypeOptions?.find(type => type.value === values.periodeType)?.label?.toLocaleLowerCase() ?? '') : decapitalize(props.label) }
            ranges={ props.dateRanges } />,
        defaultValue: {
            periodeType: props.defaultDateType,
            periode: [
                (props.dateRanges[0].values as Date[])[0],
                (props.dateRanges[0].values as Date[])[1],
            ]
        },
        initialValue: (query: any) => {
            return {
                periodeType: query.dateType ?? props.defaultDateType,
                periode: [
                    query.dateDebut ? parseISO(query.dateDebut) : (props.dateRanges[0].values as Date[])[0],
                    query.dateFin ? parseISO(query.dateFin) : (props.dateRanges[0].values as Date[])[1]
                ],
            }
        },
        valueToParam: (values: any, params: URLSearchParams) => {
            if (values.periodeType && params.get('date-type') !== values.periodeType) {
                const tri = dateTypeOptions.find(t => t.value === values.periodeType)?.field
                if (tri != null)
                    params.set('tri', tri)
                params.set('date-type', values.periodeType)
            }
            if (values.periode && values.periode.length === 2) {
                params.set('date-debut', format(values.periode[0], DATE_FORMAT))
                params.set('date-fin', format(values.periode[1], DATE_FORMAT))
            }
        },
        paramToValue: (params: URLSearchParams) => {
            return {
                dateType: params.get('date-type') ?? props.defaultDateType,
                dateDebut: params.get('date-debut') ?? undefined,
                dateFin: params.get('date-fin') ?? undefined,    
            }
        }
    }
}

export const FiltreCheckboxes = (props: { name: string, paramName: string, valueName: string, queryName: string, label: string, checkboxes: any[] }) => {
    return {
        input: <DropdownCheckboxes key={ props.name } name={ props.name } label={ props.label } checkboxes={ props.checkboxes ?? [] } />,
        filtre: (values: any, hideRemove?: boolean) =>
            <SelectedCheckboxes key={ props.name } name={ props.name } label={ decapitalize(props.label) }
                checkboxes={ props.checkboxes ?? [] } hideRemove={ hideRemove } />,
        defaultValue: {
            [props.name]: [],
        },
        initialValue: (query: any) => {
            return { [props.valueName]: query[props.queryName] ?? [] }
        },
        valueToParam: (values: any, params: URLSearchParams) => {
            params.delete(props.paramName)
            values[props.valueName]?.forEach((value: any) => params.append(props.paramName, value))        
        },
        paramToValue: (params: URLSearchParams) => {
            return { [props.queryName]: params.getAll(props.paramName) }
        },
    }
}

export const FiltreRadioGroup = (props: { name: string, label: string, options: RadioOption[], defaultValue: string, paramName: string, tooltip?: string }) => {
    const input = <RadioField key={props.name} label={props.label} name={ props.name } options={ props.options } tooltip={ props.tooltip } />

    return {
        input,
        filtre: (values: any, hideRemove?: boolean) =>
            <SelectedText key={ props.name } name={ props.name } label={ decapitalize(props.label) } defaultValue={ props.defaultValue }
                value={ val => props.options.find(o => o.value === val)?.selectedLabel ?? props.options.find(o => o.value === val)?.label ?? '' } hideRemove={ hideRemove }/>,
        defaultValue: {
            [props.name]: props.defaultValue,
        },
        initialValue: (query: any) => {
            return { [props.name]: query[props.name] ?? props.defaultValue }
        },
        valueToParam: (values: any, params: URLSearchParams) => {
            params.set(props.paramName, values[props.name] ?? props.defaultValue)
        },
        paramToValue: (params: URLSearchParams) => {
            return { [props.name]: params.get(props.paramName) ?? props.defaultValue }
        },
    }
}