import {
  AtlasFieldCheckboxProps,
  AtlasIcon,
  AtlasTags,
  AtlasTagsItemProps,
  AtlasTagsProps,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler } from "react";

export interface MyTagsProps extends AtlasTagsProps {
  /**
   * Cacher derrière un bouton cliquable les tags au delà de 3 éléments
   */
  expandable?: boolean;
}

/**
 * Une liste de tags. Étend le composant `AtlasTags`.
 */
export function MyTags(props: MyTagsProps) {
  const COLLAPSED_TAGS_LIMIT = 3;
  const hasCheckboxes = !!props.children.find(
    (child) => (child.props as MyTagsItemProps).tagCheckboxes
  );
  const classnames = classNames("my-tags", {
    "my-tags--hasCheckboxes": hasCheckboxes,
  });

  if (props.expandable && props.children.length > COLLAPSED_TAGS_LIMIT) {
    return (
      <details className={classnames}>
        <summary>
          <AtlasTags>
            {props.children.slice(0, COLLAPSED_TAGS_LIMIT).concat(
              <MyTags.Item key="tags-more" light>
                {`Voir ${props.children.length - COLLAPSED_TAGS_LIMIT} en plus`}
              </MyTags.Item>
            )}
          </AtlasTags>
        </summary>
        <AtlasTags>{props.children}</AtlasTags>
      </details>
    );
  }

  return (
    <div className={classnames}>
      <AtlasTags {...props} />
    </div>
  );
}

export interface MyTagsItemProps extends AtlasTagsItemProps {
  /**
   * Faire ressortir le tag avec une couleur différente
   */
  accent?: boolean;
  /**
   * Alléger la visibilité et l'impact du tag
   */
  light?: boolean;
  /**
   * Des cases à cocher associées au tag
   */
  tagCheckboxes?: React.ReactElement<AtlasFieldCheckboxProps>[];
}

MyTags.Item = (props: MyTagsItemProps) => {
  const { tagCheckboxes, ...propsWithoutCheckboxes } = props;

  return (
    <>
      <div
        className={classNames("my-tags__item", {
          "my-tags__item--accent": props.accent,
          "my-tags__item--light": props.light,
        })}
      >
        <AtlasTags.Item {...propsWithoutCheckboxes} />
      </div>
      {props.tagCheckboxes && (
        <div className="my-tags__checkboxes">{props.tagCheckboxes}</div>
      )}
    </>
  );
};

export interface MyTagsAddButtonProps {
  /**
   * Le label du bouton
   */
  label: string;
  /**
   * L'action à éxécuter au clic
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

MyTags.AddButton = (props: MyTagsAddButtonProps) => {
  return (
    <button className="my-tags__addButton" onClick={props.onClick}>
      <AtlasIcon size="s" name="plus" />
      {props.label}
    </button>
  );
};
