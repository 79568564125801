import { PieceDTO, deleteDocument } from 'api/documentAPI'
import { DossierDetail, DossierPieceDTO, downloadDossierDoc, getDossier, loadDossierPieces } from 'api/dossierAPI'
import { TYPE_FORMATION_INTERNE } from 'api/references'
import useEventListener from 'app/events'
import ExtensionPoint from 'app/ExtensionPoint'
import { AtlasFlex, AtlasHeading, AtlasHtml, AtlasInfo, AtlasLoading, AtlasValue, AtlasValues, MyContainer, MyItemPreview } from 'atlas-ds'
import { formatApiDate, formatCurrency, formatNumber } from 'components/format/Format'
import State from 'components/State'
import { Location } from 'history'
import Detail, { DetailActionProps } from 'pages/detail-common/Detail'
import DetailDocuments from 'pages/detail-common/DetailDocuments'
import { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import DetailDossierFactures from './DetailDossierFactures'
import DetailDossierReglements from './DetailDossierReglements'


const detailName = 'DetailDossierContrat'

export type DossierActionProps = DetailActionProps<DossierDetail>

export type DossierActionElement = ReactElement<DossierActionProps>

export type DetailDossierExtension = {
    [detailName]: {
        alertes?: JSX.Element
        infos?: JSX.Element
        actions?: DossierActionElement[] | ((detail: DossierDetail, actions: DossierActionElement[]) => void)
    }
}

export const detailDossierContratRefreshEvent = "DetailDossierContrat:Refresh"
export const detailDossierDocumentsRefreshEvent = "DetailDossierDocuments:Refresh"

export interface DetailDossierProps {
    getDossier: (id: string) => Promise<DossierDetail>
    depotPieces?: (dossier: DossierDetail, documents: PieceDTO[], pending: boolean) => JSX.Element | undefined
    actions?: DossierActionElement[] | ((detail: DossierDetail, actions: DossierActionElement[]) => void)
}

export default function DetailDossierContrat(props: DetailDossierProps) {
    // Rafraîchit contenu dossier
    const [tmpDossier, setTmpDossier] = useState<DossierDetail | null>(null)
    useEventListener<DossierDetail | null>(detailDossierContratRefreshEvent, event => {
        if (event) setTmpDossier(event.detail)
    })

    const history = (location: Location<any>) => [{
        title: 'Dossiers',
        pathname: '/mes-dossiers-contrats',
        search: '',
    }, {
        title: 'Dossier sélectionné',
        pathname: location.pathname,
        search: location.search,
    }]

    const sections = (dossier: DossierDetail | null, pending: boolean) => {
        return [
            <div id="vie" key="vie">
                <AtlasHeading tag="h2" size="m">Vie du dossier</AtlasHeading>
                <AtlasFlex column gap="m">
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValues.Grid>
                                { dossier ? <AtlasValue label='État'>
                                    <State type="file" id={dossier?.EtatDossierFO__c}>
                                        { dossier?.EtatDossierFORef?.LibelleReduit__c ?? '' }
                                    </State>
                                </AtlasValue> : <></> }
                                <AtlasValue label='Dispositif'>
                                    { dossier?.DispositifFORef?.Libelle__c }
                                </AtlasValue>
                                <AtlasValue label="Créé le">
                                    { formatApiDate(dossier?.DateCreationFO__c) }
                                </AtlasValue>
                                <AtlasValue label="Transmis le">
                                    { formatApiDate(dossier?.DateReception__c) }
                                </AtlasValue>
                            </AtlasValues.Grid>
                            { dossier?.NumModificationContrat__c ? <AtlasValues.Grid>
                                <AtlasValue label='Dernière modification'>
                                    {dossier?.TypeModificationContrat__c + ' ' + dossier?.NumModificationContrat__c}
                                </AtlasValue>
                                <AtlasValue label='Soumis le'>
                                    {formatApiDate(dossier?.DateModificationContrat__c)}
                                </AtlasValue>
                                <AtlasValue label='Statut'>
                                    { dossier?.StatutModificationContrat__c }
                                </AtlasValue>
                            </AtlasValues.Grid> : <></> }

                            { (tmpDossier?.RuptureCA__c ?? dossier?.RuptureCA__c) === true ?
                                <AtlasInfo title="Rupture enregistrée">
                                    La rupture déclarée le { formatApiDate(dossier?.DateRuptureCA__c) } a été enregistrée
                                </AtlasInfo>
                            : (tmpDossier?.DateRuptureCA__c ?? dossier?.DateRuptureCA__c) != null ?
                                <AtlasInfo title="Demande de rupture">
                                    Rupture en date du ${ formatApiDate(tmpDossier?.DateRuptureCA__c ?? dossier?.DateRuptureCA__c) } en attente de traitement
                                </AtlasInfo>
                            : null }
                        </AtlasValues>
                    </MyContainer>
                    { dossier?.CommentaireFO__c ? <MyContainer>
                        <AtlasValues>
                            <AtlasValue label="Commentaire">
                                <AtlasHtml>{dossier?.CommentaireFO__c}</AtlasHtml>
                            </AtlasValue>
                        </AtlasValues>
                    </MyContainer> : <></> }
                </AtlasFlex>
            </div>,

            <div id="infos" key="infos">
                <AtlasHeading tag="h2" size="m">Informations dossier</AtlasHeading>
                <AtlasFlex grow wrap gap="l">
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValue label='Intitulé du stage'>
                                { dossier?.Intitule__c }
                            </AtlasValue>
                            <AtlasValue label='Durée (heures)'>
                                { formatNumber(dossier?.Modules?.find(m => m.Principal__c)?.NombreHeureFormation__c) }
                            </AtlasValue>
                            <AtlasValue label='Montant accordé par Atlas' inline>
                                { formatCurrency(dossier?.MontantTotalEngage__c) }
                            </AtlasValue>

                            <ExtensionPoint<DetailDossierExtension> name={ detailName } point="infos" detail={ dossier } />
                        </AtlasValues>
                    </MyContainer>
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValue label='Établissement demandeur'>
                                {dossier?.EtablissementAdherent?.RaisonSociale__c ?? dossier?.EtablissementAdherent?.Name ?? ''}
                            </AtlasValue>
                            <AtlasValue label='SIRET'>
                                {dossier?.EtablissementAdherent?.SIRET__c ?? ''}
                            </AtlasValue>
                            <AtlasValue label='Numéro adhérent Atlas'>
                                {dossier?.EtablissementAdherent?.CodeAdherent__c ?? ''}
                            </AtlasValue>
                            <AtlasValue label="Adresse">
                                { dossier?.EtablissementAdherent?.BillingStreet }<br />
                                { (dossier?.EtablissementAdherent?.BillingPostalCode ?? '') + ' ' + (dossier?.EtablissementAdherent?.BillingCity ?? '') }
                            </AtlasValue>
                        </AtlasValues>
                    </MyContainer>
                </AtlasFlex>
            </div>,

            <div id="modules" key="modules">
                <AtlasHeading tag="h2" size="m">Modules de formation</AtlasHeading>
                { pending ? <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <AtlasValue label="Nom de la formation"> </AtlasValue>
                            <AtlasValue label="Type de formation"> </AtlasValue>
                            <AtlasValue label="Début"> </AtlasValue>
                            <AtlasValue label="Fin"> </AtlasValue>
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer> :  dossier?.Modules?.map(m => <MyContainer key={m.Id}>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <AtlasValue label="Nom de la formation">{ m.IntituleProgramme__c }</AtlasValue>
                            <AtlasValue label="Type de formation">{ m.TypeFormationRef?.LibelleReduit__c }</AtlasValue>
                            <AtlasValue label="Début">{ m.DateDebutFormation__c }</AtlasValue>
                            <AtlasValue label="Fin">{ m.DateFinFormation__c }</AtlasValue>
                        </AtlasValues.Grid>

                        { m.TypeFormationRef?.Id !== TYPE_FORMATION_INTERNE ? <>
                            <AtlasValue label="Organisme de formation">{ m.NomOF__c }</AtlasValue>
                            <AtlasValues.Grid>
                                <AtlasValue label="SIRET">{ m.SiretOF__c }</AtlasValue>
                                <AtlasValue label="N° de déclaration d'activité">{ m.NdaOF__c }</AtlasValue>
                            </AtlasValues.Grid>
                            <AtlasValue label="Adresse">
                                { dossier?.EtablissementAdherent.BillingStreet }<br />
                                { (dossier?.EtablissementAdherent.BillingPostalCode ?? '') + ' ' + (dossier?.EtablissementAdherent.BillingCity ?? '') }
                            </AtlasValue>
                            <AtlasValue label="Paiement direct par Atlas à l'organisme">
                                { m.SubrogationPaiement__c ? 'Oui' : 'Non' }
                            </AtlasValue>
                        </> : <></> }
                    </AtlasValues>
                </MyContainer> ) || <MyContainer>
                    <p>Aucun module de formation n'est associé à ce dossier.</p>
                </MyContainer>}
            </div>,

            <section id="stagiaires" key="stagiaires">
                <AtlasHeading tag="h2" size="m">
                    Stagiaires{dossier?.Stagiaires ? ` (${dossier?.Stagiaires?.length})` : ''}
                </AtlasHeading>
                { <AtlasLoading loading={pending} >
                    <AtlasLoading.Loaders>
                        {[1, 2].map(index => <MyItemPreview key={index}>
                            <AtlasValue label="Nom"> </AtlasValue>
                            <AtlasValue label="Date de naissance"> </AtlasValue>
                        </MyItemPreview>)}
                    </AtlasLoading.Loaders>
                    {dossier?.Stagiaires?.map(s =>
                        <MyItemPreview
                            key={ s.Id }
                            linkConstructor={s.Particulier__c ? (_, content) => <Link to={ `/detail-stagiaire/${dossier?.Id}/${s.Id}?cid=${s.Particulier__c}`}>{content}</Link> : undefined}
                        >
                            <AtlasValue label="Nom">{ s.Prenom__c + ' ' + s.Nom__c }</AtlasValue>
                            <AtlasValue label="Date de naissance">{ formatApiDate(s.DateNaissance__c) }</AtlasValue>
                        </MyItemPreview>
                    )}
                </AtlasLoading> }
            </section>,

            <section id="documents" key="documents">
                <DetailDocuments
                    idParent={dossier?.NumeroDossier__c ?? null} 
                    name="DetailDossierDocuments"
                    transmissionEnCours={ dossier?.EnCoursTransmissionSOR__c }
                    depotPieces={ (documents: PieceDTO[]) => dossier ? props.depotPieces?.(dossier, documents, pending) : undefined }
                    getDocuments={ loadDossierPieces }
                    downloadDoc={ (doc: DossierPieceDTO) => downloadDossierDoc(doc, dossier!) }
                    documentsRefreshEvent={ detailDossierDocumentsRefreshEvent }
                    deleteDoc={ deleteDocument }
                />
            </section>,

            <section id="factures" key="factures">
                <DetailDossierFactures key="factures" dossier={ dossier } />
            </section>,

            <section id="reglements" key="reglements">
                <DetailDossierReglements dossier={ dossier } />
            </section>
        ]
    }
    
    return (
        <Detail<DossierDetail>
            name={detailName}
            paramName="iddossier"
            title={ (dossier: DossierDetail | null) => `Dossier N°${dossier?.NumeroDossier__c ?? ''}`}
            getId={ (dossier: DossierDetail | null) => dossier?.NumeroDossier__c ?? null }
            getDetail={ id => getDossier(id).then(d => {
                setTmpDossier(null)
                return d
            }) }
            loadErrorMessage="Erreur lors de récupération du dossier"
            history={ history }
            nav={[
                { targetId: 'infos', label: 'Informations du dossier' },
                { targetId: 'modules', label: 'Modules de formation' },
                { targetId: 'stagiaires', label: 'Stagiaires' },
                { targetId: 'documents', label: 'Documents' },
                { targetId: 'factures', label: 'Factures' },
            ]}
            sections={ sections }
            actions={ props.actions }
        />
    )
}
