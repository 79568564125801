
import { Entreprise } from 'api/referencesAPI'
import { Adhesion, Presentation } from 'api/strategiePlusAPI'
import { AtlasButton, AtlasFlex, AtlasHeading, AtlasLink, AtlasLoading, MyContainer } from 'atlas-ds'
import { useHistory } from 'react-router-dom'
import { MentionStrategiePlus } from './MentionBloc'
import { CompteStrategiePlus } from './StrategiePlus'
import { StrategiePlusSolde } from './StrategiePlusSolde'


export interface ContenusProps {
    detailEntreprise: Entreprise | null,
    presentationDefault: Presentation[],
    adhesionDefault: Adhesion[],
    compte: CompteStrategiePlus,
    loading: boolean
}

export function StrategiePlusDefault(props: ContenusProps) {

    const history = useHistory()

    const goToVV = () => history.push('/versement-volontaire')

    return (
        <AtlasFlex column gap="xxl">
            { ((props.detailEntreprise?.VVSoldeAnneeN1__c ?? 0) > 0 || (props.detailEntreprise?.VVSoldeAnneeN2__c ?? 0) > 0) && 
                <StrategiePlusSolde detailEntreprise={props.detailEntreprise} /> 
            }

            <AtlasLoading loading={props.loading}>
                <AtlasLoading.Loaders>
                    <AtlasHeading tag="h2" size="m"> </AtlasHeading>
                    <AtlasFlex column gap="m">
                        <p>
                            <AtlasLoading.Loader />
                        </p>
                        <MyContainer dark>
                            <AtlasFlex column gap="s">
                                <AtlasLoading.Loader />
                                <AtlasLoading.Loader />
                                <AtlasLoading.Loader />
                            </AtlasFlex>
                        </MyContainer>
                    </AtlasFlex>
                </AtlasLoading.Loaders>
                {props.presentationDefault.map((item, index) => <div key={index}>
                    <AtlasHeading tag="h2" size="m">{ item.titre }</AtlasHeading>
                    <AtlasFlex column gap="m">
                        <p>{item.description}</p>
                        <MyContainer dark>
                            <AtlasHeading size="m" tag="h3">Besoin d’en savoir plus ?</AtlasHeading>
                            <AtlasFlex column gap="s">
                                <p>{ item.enSavoirPlus }</p>
                                <AtlasLink icon="arrow-right" level={3} href={item.lien}>
                                    Se rendre sur le site Opco Atlas
                                </AtlasLink>
                            </AtlasFlex>
                        </MyContainer>
                    </AtlasFlex>
                </div>)}
            </AtlasLoading>

            <div>
                <AtlasButton level={ 1 } onClick={ goToVV }>Devenir adhérent</AtlasButton>
            </div>

            <MentionStrategiePlus />
        </AtlasFlex>
    )
}