import { Text, View } from '@react-pdf/renderer';
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierVAEI, DossierVAEITuteur } from 'pages/dossier-vaei/VAEIModel';
import { VAEITuteurMetadata } from 'pages/dossier-vaei/VAEITuteurMetadata';
import CerfaElement from 'pages/dossier/cerfa/CerfaElement';

export default function Tuteurs({ dossier }: { dossier: DossierVAEI }) {

  const tuteur = dossier.tuteur

  function Tuteur({ label, tuteur, emptyLineAfterLabel }: { label: string, tuteur?: DossierVAEITuteur | null, emptyLineAfterLabel?: boolean }) {
    return (
      <>
        <Text style={styles.bold}>{label}</Text>
        {emptyLineAfterLabel && <Text> </Text>}
        <Text style={styles.bold}>
          Nom :{' '}
        </Text>
        <CerfaElement metadata={ VAEITuteurMetadata.nom } value={ tuteur?.nom } />
        <Text style={styles.bold}>
          Prénom :{' '}
        </Text>
        <CerfaElement metadata={ VAEITuteurMetadata.prenom } value={ tuteur?.prenom } />
        <Text>Emploi occupé :{' '}
        </Text>
        <CerfaElement metadata={ VAEITuteurMetadata.emploiOccupe } value={ tuteur?.emploiOccupe } />
        <Text>
          Date de naissance :{' '}
          <CerfaElement metadata={ VAEITuteurMetadata.dateNaissance } value={ tuteur?.dateNaissance } />
        </Text>
      </>
    );
  }

  return (
    <View style={[styles.section, styles.pageLastSection]}>
      <Text style={styles.sectionHeaderV4}>LE TUTEUR</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
          <Tuteur label="Tuteur au sein de l'établissement employeur" emptyLineAfterLabel tuteur={ dossier.tuteurTravailTemporaire === true ? undefined : tuteur } />
          </View>
          <View style={styles.colRight}>
            <Tuteur label="Tuteur de l'entreprise utilisatrice, si travail temporaire ou Groupement d'employeur"
              tuteur={ dossier.tuteurTravailTemporaire === false ? undefined : tuteur } />
            <Text>
              Code activité de l’entreprise utilisatrice (NAF) * :{' '}
            </Text>
            <CerfaElement metadata={ VAEITuteurMetadata.codeNAFEntrepriseUtilisatrice } value={ tuteur?.codeNAFEntrepriseUtilisatrice } />
            <Text style={styles.info}>
              *à renseigner dans le cadre d'un contrat de professionnalisation
              associant des actions de validation des acquis de l'expérience
              mentionné à l'article 11 de la loi n° 2022-1598 du 21 décembre
              2022
            </Text>
          </View>
        </View>
        <Text> </Text>
        <View style={styles.sectionContent}>
          <CerfaElement metadata={ VAEITuteurMetadata.eligibilite } value={ tuteur?.eligibilite ?? '' } />
        </View>
      </View>
    </View>
  );
}
