import { DossierDetail } from 'api/dossierAPI'
import { DetailsModuleContext } from 'app/AppContext'
import { AppModule, AppState } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { DataSummariesExtension } from 'components/DataSummaries'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import DetailStagiaire from 'pages/detail-dossier/DetailStagiaire'
import { AppDossierModule, buildRoutes } from 'pages/detail-dossier/modules'
import { relanceMessagesEntreprise } from 'pages/detail-dossier/relanceff/entreprise'
import { relanceMessagesPrestataire } from 'pages/detail-dossier/relanceff/prestataire'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import DossiersStatistiques from 'pages/mes-dossiers-contrats/DossiersStatistiques'
import { DetailAlertes, DetailDossierExtraInfos } from 'pages/relanceff/Alertes'

export interface DossierCreationMetadata {
    module: AppDossierModule
    create?: FormMetadata<any>
    edit?: FormMetadata<any>
}

export class DossiersModuleContext implements DetailsModuleContext<DossierDetail> {
    dossiersModules: AppDossierModule[] = []
    creationMetadata: DossierCreationMetadata[] = []

    findModule(detail: DossierDetail): AppDossierModule | undefined {
        return this.dossiersModules.find(m => m.dispositifId === detail.DispositifFO__c)
    }
}

export const DOSSIERS_MODULE_ID = 'mes-dossiers'

const dossiersModule = (menuLabel: string, buildModuleContext: (appState: AppState) => DossiersModuleContext, 
    MesDossiers: any, DetailDossier: any) => {

    const routes = (ctx: DossiersModuleContext) => {
        const rts = [
            <AppRoute key="dossiers1" path="/mes-dossiers-contrats" component={ MesDossiers } label={ menuLabel } menuIcon="dossiers" />,
            <AppRoute key="dossiers2" path="/detail-dossier-contrat/:iddossier" component={ DetailDossier } />,
            <AppRoute key="dossiers3" path="/detail-stagiaire/:iddossier/:idstagiaire" component={ DetailStagiaire } />,
        ]
 
        for (const metadata of ctx.creationMetadata) {
            if (metadata?.create) {
                buildRoutes(metadata.module.name, metadata.create, false, rts)
            }
            if (metadata?.edit) {
                buildRoutes(metadata.module.name, metadata.edit!, true, rts)
            }
        }
        return rts
    }
    
    return {
        id: DOSSIERS_MODULE_ID,
        context: buildModuleContext,
        routes,
        extensions: {
            dataSummaries: {
                stats: <DossiersStatistiques key="dossiersStats" />,
            },
            entreprise: {
                DetailDossierContrat: {
                    alertes: <DetailAlertes key="relanceffAlertes" label="Ce dossier" messages={ relanceMessagesEntreprise } />,
                    infos: <DetailDossierExtraInfos key="relanceffInfos" showAdh showOF />,
                },
            } as DetailDossierExtension,
            prestataire: {
                DetailDossierContrat: {
                    alertes: <DetailAlertes key="relanceffAlertes" label="Ce dossier" messages={ relanceMessagesPrestataire } />,
                    infos: <DetailDossierExtraInfos key="relanceffInfos" showOF />,
                },
            } as DetailDossierExtension,
        } as DataSummariesExtension,
    } as AppModule
}

export default dossiersModule