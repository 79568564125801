import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierProAEmployeur, ProAEmployeurMetadata } from 'pages/dossier-proa/ProAFormMetadata'
import CerfaAdresseField from 'pages/dossier/cerfa/CerfaAdresseField'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'

export default function Employeur({ employeur }: { employeur: DossierProAEmployeur | undefined }) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>L'EMPLOYEUR</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Nom et prénom ou dénomination :</Text>
            <CerfaElement metadata={ ProAEmployeurMetadata.nom } value={ employeur?.nom } />
            <Text> </Text>
            <Text>Adresse de l'établissement d'exécution du contrat :</Text>
            <CerfaAdresseField value={ employeur } />
            <Text>
              Téléphone :{' '}
              <CerfaElement metadata={ ProAEmployeurMetadata.telephone } value={ employeur?.telephone } />
            </Text>
            <Text>Courriel :{' '}
              <CerfaElement metadata={ ProAEmployeurMetadata.email } value={ employeur?.email } />
            </Text>
          </View>
          <View style={styles.colRight}>
            <View>
              <Text>
                <Text style={styles.bold}>N° SIRET </Text>
                de l'établissement d'exécution du contrat de travail :
              </Text>
              <CerfaElement metadata={ ProAEmployeurMetadata.numeroSiret } value={ employeur?.numeroSiret } />
              <Text> </Text>
            </View>
            <Text>
              Code activité de l'entreprise (NAF) :{' '}
              <CerfaElement metadata={ ProAEmployeurMetadata.codeNaf } value={ employeur?.codeNaf } />
            </Text>
            <Text>Convention collective appliquée :</Text>
            <Text style={styles.user}>
              <CerfaElement metadata={ ProAEmployeurMetadata.libelleCCN } value={ employeur?.libelleCCN } />
            </Text>
            <Text>
              Code IDCC de la convention collective applicable :{' '}
              <Text style={styles.user}>{employeur?.codeIdcc ?? ' '}</Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
