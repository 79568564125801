import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import MesDocuments from './MesDocuments'


const documentsModule = () => { 
    return {
        id: 'mes-documents',
        routes: [
            <AppRoute key="documents" path="/mes-documents" component={MesDocuments} label="Documents" menuIcon="documents" />,
        ],
    } as AppModule
}

export default documentsModule