import { ReactNode, useMemo } from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import AppContext, { AppContextType } from './AppContext'
import { AppInfo, RouteElement } from './appInfo'
import { useAppState } from './useAppState'


interface AppModuleProviderProps {
    appInfo: AppInfo
    layout: (root: JSX.Element) => JSX.Element
    children: ReactNode
}

export interface AppRouteProps extends RouteProps {
    path: string;
    /* Adding a label will show the link in the sitemap */
    label?: string;
    /* Adding a menu icon will show the link in the menu (it must have a label) */
    menuIcon?: string;
}

export const AppRoute = (props: AppRouteProps) => <Route {...props} />

export default function AppModuleProvider(props: AppModuleProviderProps) {
    const appState = useAppState()

    const appModules = useMemo(() => {
        if (appState.app && appState.config && appState.profil) {
            return props.appInfo?.modules
                .flatMap(appModules => appModules)
                .filter(appModule => appModule.isEnabled == null || appModule.isEnabled(appState))
        }
        return []
    }, [appState.app, appState.entreprise?.Id, appState.profil?.Contact?.Id])

    const appContext = useMemo(() => {
        const ctx = {
            appInfo: props.appInfo,
            enabledModules: props.appInfo.modules?.flatMap(m => m)?.filter(m => !m.isEnabled || m.isEnabled(appState)),
            resolvedRoutes: new Map<string, RouteElement[]>(), 
            context: new Map<string, any>()
        } as AppContextType
        appModules.forEach(appModule => {
            const moduleContext = appModule.context ? appModule.context(appState!) : undefined
            if (moduleContext)
                ctx.context.set(appModule.id, moduleContext)
            if (typeof appModule.routes === 'function')
                ctx.resolvedRoutes.set(appModule.id, appModule.routes(moduleContext, appState))
            else
                ctx.resolvedRoutes.set(appModule.id, appModule.routes)
        })
        return ctx
    }, [appModules, appState.config])

    return (
        <AppContext.Provider value={ appContext }>
            { props.layout(
                <Switch>
                    { [...appContext.resolvedRoutes.values()].flatMap(routes => routes) }
                    { props.children }
                </Switch>
             ) }
        </AppContext.Provider>
    )
}
