import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { useSelector } from 'react-redux'
import RadioField from './RadioField'


export interface RadioRefFieldProps<T extends object, V> extends BaseFieldProps<V, T> {
    options: (refs: References) => Reference[]
}

export default function RadioRefField<T extends object, V = string>(props: RadioRefFieldProps<T, V>) {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)
    const options = refs ? (props.options(refs) ?? []).map(opt => ({ value: opt.Id, label: opt.Libelle__c })) : []

    return (
        <RadioField name={ props.name }
            label={ props.label ?? '' }
            tooltip={ props.tooltip }
            parentPath={ props.parentPath }
            disabled={ props.disabled }
            options={ options }
            onChange={ props.onChange } />
    )
}
