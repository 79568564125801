import { PieceDTO } from 'api/documentAPI'
import useApiState from 'api/useApiState'
import { dispatchAppEvent } from 'app/events'
import { AtlasButton } from 'atlas-ds'
import log from 'loglevel'


interface DetailSupprimerDocProps {
    idParent: string | null
    doc: PieceDTO
    deleteDocument: (idParent: string, idDoc: string) => Promise<string[]>
    documentsRefreshEvent: string
}

export default function DetailSupprimerDoc(props: DetailSupprimerDocProps) {

    const [{ pending: deleting }, withDeleteDocument] = useApiState(props.deleteDocument, { pending: false })

    const removeDocument = () => {
        withDeleteDocument(props.idParent!, props.doc.Id).then(_ =>
            dispatchAppEvent(props.documentsRefreshEvent)
        ).catch(e => {
            log.error('Erreur suppression document ' + props.doc?.Id + ' parent ' + props.idParent, e)
        })
    }

    return (
        <AtlasButton
            key="supprimer"
            icon="trash"
            spinner={{spinning: deleting}}
            onClick={ () => deleting || removeDocument() }
            level={ 3 }
        >Supprimer</AtlasButton>
    )
}
