import { AtlasLoading } from 'atlas-ds';
import classNames from "classnames";

/**
 * Une catégorie
 */
export interface MyCategoriesCategory {
  /**
   * Label de la catégorie
   */
  label: string;
  /**
   * La catégorie est-elle actuellement sélectionée ?
   */
  active?: boolean;
}

export interface MyCategoriesProps {
  /**
   * La liste des categories
   */
  categories: MyCategoriesCategory[];
  /**
   * L'action à éxécuter lorsqu'une catégorie est sélectionnée
   */
  onSelect: (category?: MyCategoriesCategory) => void;
  /**
   * Le label du bouton permettant de sélectionner tous les catégories
   */
  selectAllButtonLabel?: string;
}

/**
 * Une liste de catégories sélectionnables
 */
export function MyCategories(props: MyCategoriesProps) {
  return (
    <div className="my-categories">
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      { props.selectAllButtonLabel && <button
        className={classNames("my-categories__category", {
          "my-categories__category--active": !props.categories.find(
            (category) => !!category.active
          ),
        })}
        type="button"
        onClick={() => props.onSelect()}
      >
        { props.selectAllButtonLabel }
      </button> }
      {props.categories.map((category, key) => (
        <button
          key={"category_" + key}
          className={classNames("my-categories__category", {
            "my-categories__category--active": category.active,
          })}
          type="button"
          onClick={() => props.onSelect(category)}
        >
          {category.label}
        </button>
      ))}
    </div>
  );
}
