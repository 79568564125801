import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entreprise } from 'api/referencesAPI'


export enum AppType {
    Entreprise = "entreprise",
    Prestataire = "prestataire",
    Compte = "compte",
}

export const InvalidAppType = null as unknown as AppType

export class AppTypes {

    public static includes(value?: string | null): boolean {
        if (value == null)
            return false
        return (Object.values(AppType) as string[]).includes(value)
    }

    public static enumOf(value?: string | null): AppType | undefined {
        if (value == null)
            return undefined
        return Object.values(AppType).find(type => type === value)
    }

    public static valueOf(value: AppType): 'entreprise' | 'prestataire' | 'compte' {
        switch (value) {
            case AppType.Entreprise: return AppType.Entreprise
            case AppType.Prestataire: return AppType.Prestataire
            case AppType.Compte: return AppType.Compte
        }
    }
}

interface ContextState {
    app: AppType | null
    entreprise: Entreprise | null
}

const initialState: ContextState = {
    app: null,
    entreprise: null
}

const contextSlice = createSlice({
    name: 'context',
    initialState: initialState,
    reducers: {
        setApp: (state, { payload }: PayloadAction<AppType>) => {
            if (state.app !== payload) {
                state.app = payload
                state.entreprise = null
            }
        },
        setEnterprise: (state, { payload }: PayloadAction<Entreprise | null>) => {
            if (state.entreprise !== payload)
                state.entreprise = payload
        }
    }
})

export const { setApp, setEnterprise } = contextSlice.actions

export default contextSlice.reducer

