import Joyride, { Step, CallBackProps, EVENTS } from 'react-joyride';
import { styles } from '../styles';
import { locale } from '../locale';

interface FeatureProps {
    step: Step
    alwaysShow?: boolean
    disableOverlay?: boolean
}

export default function Feature(props: FeatureProps) {
    const localStorageKey = 'seenFeatures';
    const savedSeenFeatures = window.localStorage.getItem(localStorageKey);
    const seenFeatures = savedSeenFeatures ? JSON.parse(savedSeenFeatures) : [];

    // Disable beacon of the step if joyride should always be shown
    const steps: Step[] = [Object.assign(
        {disableBeacon: !!props.alwaysShow},
        props.step
    )]; 

    // Return true if the step should be shown to the user
    function shouldShow(): boolean {
        return props.alwaysShow
            || !seenFeatures.includes(props.step.target as string);
    }

    // Store the seen step to not show it again
    function onJoyrideEvent(event: CallBackProps): void {
        if (event.type === EVENTS.TOUR_END) {
            seenFeatures.push(props.step.target as string);
            localStorage.setItem(localStorageKey, JSON.stringify(seenFeatures));
        }
    }

    return shouldShow() ? <Joyride
        steps={steps}
        disableOverlay={props.alwaysShow}
        locale={locale}
        styles={styles}
        callback={onJoyrideEvent}
    /> : null;
}
