import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossierTuteur, DossierAF } from 'slices/dossierCommon'


interface TuteurFieldsProps {
    metadata: FormMetadata<any>,
    name: string
    disabled?: boolean
    editForm?: boolean
    getValueForModel?: (value: BaseDossierTuteur) => any
}

export default function TuteurFields<FormObjectType extends DossierAF>(props: TuteurFieldsProps) {
    return (<>
        <MyForm.Grid>
            <FormElements metadata={ props.metadata } section="" path={ props.name } type="input" disabled={ props.disabled } />
        </MyForm.Grid>
    </>)
}