
export const DATE_FORMAT = 'yyyy-MM-dd';

export const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy'

export const DISPLAY_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm'

export const DISPLAY_FULL_DATE_FORMAT = 'd MMMM yyyy'

export const TIME_FORMAT = 'HH:mm'

export const SIREN_ADH = 'siren-adh';

export const EXTERNAL_LINK_REGEX = /^http[s]?:\/\//i

export const SIREN_PRESTATAIRE = 'siren'

export const REGEX_ISO_TIME = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/

export const REGEX_TIME = /^[0-9]{2}:[0-9]{2}$/

