import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import Accueil from 'pages/accueil/Accueil'


const accueilModule = (accesRapides: any, widgets?: JSX.Element[]) => {
    return {
        id: 'accueil',
        routes: [
            <AppRoute key="accueil" exact path="/" label="Accueil" menuIcon="accueil" >
                <Accueil accesRapides={accesRapides} widgets={widgets} />
            </AppRoute>,
        ],
    } as AppModule
}

export default accueilModule