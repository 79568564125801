import { ObjectPropertiesSchemas } from "@dsid-opcoatlas/yop"
import PasswordField from "components/reform/inputs/PasswordField"
import { RequiredString } from "components/yop/constraints"
import { useState } from "react"
import { nil } from "./MamcApp"

export const DECIMAL_PATTERN = /\d/
export const UPPERCASE_PATTERN = /\p{Lu}/u
export const LOWERCASE_PATTERN = /\p{Ll}/u
export const SPECIAL_PATTERN = /[~!@#$%^&*\-_+='|\\(){}[\]:;"<>,.? /]/
export const SPECIAL_PATTERN_TOOLTIP = SPECIAL_PATTERN.source.substring(1, SPECIAL_PATTERN.source.length-1).replaceAll(/\\(?!\\)/g, '')

export function createMotDePasse() { return {
    motDePasse: nil as string,
}}
export type MotDePasse = ReturnType<typeof createMotDePasse>

export const MotDePassePropertiesSchemas: ObjectPropertiesSchemas<MotDePasse> = {
    motDePasse: RequiredString.max(50).test(context => {
        if (!DECIMAL_PATTERN.test(context.value) ||
            !UPPERCASE_PATTERN.test(context.value) ||
            !LOWERCASE_PATTERN.test(context.value) ||
            !SPECIAL_PATTERN.test(context.value) ||
            context.value.length < 8)
            return context.createError("Mot de passe invalide")
        return true
    }),
}

export function MotDePasseBloc(props: {label?: string}) {

    const [passwordConstraints, setPasswordConstraints] = useState({
        decimal: false,
        uppercase: false,
        lowercase: false,
        special: false,
        length: false
    })

    const onPasswordChange = (value: string | null) => {
        const password = value ?? ''
        setPasswordConstraints({
            decimal: DECIMAL_PATTERN.test(password),
            uppercase: UPPERCASE_PATTERN.test(password),
            lowercase: LOWERCASE_PATTERN.test(password),
            special: SPECIAL_PATTERN.test(password),
            length: password.length >= 8
        })
    }

    const rules = [
        {
            label: "8 caractères minimum",
            pass: passwordConstraints.length
        },
        {
            label: "Au moins une minuscule",
            pass: passwordConstraints.lowercase
        },
        {
            label: "Au moins une majuscule",
            pass: passwordConstraints.uppercase
        },
        {
            label: "Au moins un chiffre",
            pass: passwordConstraints.decimal
        },
        {
            label: "Au moins un caractère spécial",
            pass: passwordConstraints.special
        }
    ]

    return <PasswordField
        rules={rules}
        onChange={onPasswordChange}
        name="motDePasse"
        label={props.label ?? "Mot de passe"}
    />
}
