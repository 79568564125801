import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasButton } from 'atlas-ds'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { diagnosticDocumentsActions } from 'slices/diagatlas/diagnosticDocumentsSlice'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'

export default function DiagExportsButton(props: { label?: string, level?: 1 | 2 | 3 | 4 }) {
    const dispatch = useAppDispatch()
    const navigation = useDiagnosticNavigation()
    const { fulfilled: documentsPage } = useSelector((state: RootState) => state.diagnosticDocumentsState)
    useEffect(() => {
        if (!documentsPage)
            dispatch(diagnosticDocumentsActions.load())
    }, [dispatch, documentsPage])
    
    if (!documentsPage || documentsPage.totalCount === 0)
        return null

    return <AtlasButton
        level={ props.level ?? 4 }
        icon="download"
        onClick={ () => navigation.navigateToPath('documents') }
    >
        { props.label ?? "Voir les Diagnostics Exportés" }
    </AtlasButton>
}