import { DossierSignature } from "slices/dossierCommon";
import { dateMapping, simpleMapping } from "./DossierMapping";
import { DateFieldMetadata } from "../../../components/metadata/DateFieldMetadata";
import { TextFieldMetadata } from "../../../components/metadata/TextFieldMetadata";
import { ObjectMetadata } from "./FormMetadata";

export const signatureMetadata: ObjectMetadata<DossierSignature> = {
    nom: TextFieldMetadata({
        props: {
            label: 'Je soussigné',
            exportLabel: 'Par',
        },
        mapping: simpleMapping('NomPrenomSignature__c'),
    }).required(),
    lieu: TextFieldMetadata({
        props: {
            label: 'Fait à', 
        },
        mapping: simpleMapping('LieuSignature__c'),
    }).required(),
    date: DateFieldMetadata({
        props: {
            label: 'Le',
        },
        mapping: dateMapping('DateSignature__c'),
        input: undefined
    }).required(),
}
