import axios from 'axios'
import { getRequestConfig } from './baseAPI';
import store from 'app/store'

export interface CFAKey {
    idExterne: string
    utilisateur: string
    cfaKey: string
}

export async function getCFAKey(): Promise<CFAKey> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<CFAKey>(`/entreprises/of/cfakey?siren=${store.getState().contextState.entreprise?.SIREN__c}`, requestConfig)
    return data
}