import TableauViz from 'app/tableau/TableauViz'
import { AtlasButton, MyCategories, MyCategoriesCategory } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import PageHeader from 'components/PageHeader'
import log from 'loglevel'
import { useState } from 'react'

interface MesRapportsCategory extends MyCategoriesCategory {
    src: string
    export: 'pdf' | 'xlsx'
}

// https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/classeurSuiviAlternance/dashSuiviAlternance?:iid=1
export default function MesRapports() {

    const [categories] = useState<MesRapportsCategory[]>([
        {label: "Suivi de l'alternance", src: 'https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/Suivi_Alternance/Dash_Suivi_Alternance', export: 'pdf', active: true},
        {label: "Suivi des actions de formation", src: 'https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/Suivi_Formations/Dash_Suivi_Formations', export: 'pdf'},
        /* {label: "Suivi des règlements & engagements"},
        {label: "Suivi des Versements Volontaires"},
        {label: "Détails des contrats d'apprentissage"},
        {label: "Détails des contrats de professionnalisation"},
        {label: "Détails des contrats PRO-A"}, */
    ])
    
    const [currentCategory, setCurrentCategory] = useState<MesRapportsCategory>(categories[0])

    const onSelect = (category?: MyCategoriesCategory) => {
        currentCategory.active = false
        if (category) category.active = true
        setCurrentCategory(category as MesRapportsCategory)
    }

    const exportData = async () => {
        const tableauViz = document.getElementById('tableauViz') as any
        const activeSheet = tableauViz.workbook.activeSheet
        if (currentCategory.export === 'pdf') {
            const publishedSheetsInfo = tableauViz.workbook.publishedSheetsInfo
            const selectedWorkbookSheetsForExport = publishedSheetsInfo.map((publishedSheetInfo: any) => publishedSheetInfo.name)
            const exportPDFOptions = {
              scaling: 'automatic',
              pageSize: 'A4',
              orientation: 'landscape',
            }
            tableauViz.exportPDFAsync(selectedWorkbookSheetsForExport, exportPDFOptions).then(() => {
              log.info(`Workbook: ${selectedWorkbookSheetsForExport.toString()}`);
              log.info('Options:', exportPDFOptions);
            })  
        } else {
            const worksheets = activeSheet.worksheets
            const selectedWorksheetForExport = worksheets[0]
            tableauViz.exportDataAsync(selectedWorksheetForExport.name)
                .then(() => {
                    log.info(`${selectedWorksheetForExport.name}: xlsx`)
                    ToastService.showToast({
                        label: "Export",
                        type: "success",
                        content: "Export réussi",
                    })
                })
        }
    }

    return (<>
        <MyCategories categories={categories} onSelect={onSelect} />

        <PageHeader 
            actions={
                <AtlasButton level={2} onClick={exportData}>
                    Exporter les données
                </AtlasButton>
            }
            helmet={ [
                <script key="tableau" type="module" src="https://online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"></script>
            ] } 
        >{ currentCategory.label }</PageHeader>

        <TableauViz src={ currentCategory.src }/>
    </>)
}

// https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/classeurSuiviAlternance/dashSuiviAlternance?:iid=1