import { BaseFormMetadata } from './FormMetadata'
import { formElements } from './FormElements'


export function exportObject<T extends object>(metadata: BaseFormMetadata<T>, rootObject: T | null, subtitle?: string, header?: string): any {
    if (rootObject === null) {
        return { 
            title: metadata.title,
            subtitle: '',
            tables: [], 
        }
    }

    const data = {
        title: metadata.title,
        subtitle,
        tables: [{
            title: header ?? 'Informations',
            values: formElements(metadata, false, rootObject, '', 0, undefined, 'export', undefined, undefined, { row: true })
                .map(x => [x]),
        }],
    }

    return data
}
