import { Form, UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { AtlasColumns, AtlasHeading, AtlasInfo, MyForm } from 'atlas-ds'
import PageHeader from "components/PageHeader"
import Button from 'components/reform/Button'
import log from 'loglevel'
import { convert, revert } from 'pages/dossier/metadata/DossierMapping'
import { useYopSchema } from 'pages/dossier/metadata/FormMetadata'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BaseDossier, BaseDossierModule } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'
import { DossierModulesElements } from './elements/DossierModulesElements'
import { BaseDossierComponentProps, DossierIdParams, useDossier } from './useDossier'
import { useDossierNavigation } from './useDossierNavigation'
import { useIntegerQueryParam } from './useQueryParam'

export function DossierModulesEditForm<D extends BaseDossier>(props: BaseDossierComponentProps<D> & {
    modulesProperty?: string
    createModule?: (dossier: D) => BaseDossierModule
 }) {
    
    const modulesProperty = props.modulesProperty ?? "modules"
    const createModule = props.createModule ?? props.metadata.api.createModule
    
    const dispatch = useAppDispatch() 
    const { dossierId } = useParams<DossierIdParams>()
    const moduleId = useIntegerQueryParam("id")
    
    const { etape, step, navigateTo } = useDossierNavigation(props.metadata)
    const { loading, saving, dossier: initialValues, error } = useDossier<D & { [key: string]: any }>(
        props.metadata.api,
        dossierId,
    )

    const index = Math.max(0, 
        moduleId == null ?
        initialValues?.[modulesProperty]?.length ?? 0 :
        (initialValues?.[modulesProperty] as BaseDossierModule[])?.findIndex(module => module.IdHeroku === moduleId) ?? 0
    )

    const validationSchema = useYopSchema(props.metadata, step)?.focusOn(`${ modulesProperty }[${ index }]`)

    const initialValuesConverter = (dossier: D) => {
        if (moduleId == null)
            ((dossier as { [key: string]: any })[modulesProperty] as BaseDossierModule[]).push(createModule!(dossier))
        return convert(dossier, props.metadata)
    }

    const form = useForm<D>({
        initialValues,
        initialValuesConverter,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<D>) => {
            const dossier = revert(context.values!, props.metadata!)
            dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier })).then(unwrapResult)
                .then(dossier => {
                    navigateTo(dossier.NumeroDossier__c!, etape)
                })
                .catch(error => {
                    log.error("Erreur save dossier module", error)
                    context.setSubmitting(false)
                })
        }
    })

    useEffect(() => {
        if (moduleId != null && initialValues != null && form.validateAt(`${ modulesProperty }[${ index }]`, false)) {
            const firstErrorKey = form.getErrorPaths()?.[0]
            const element = global.window.document.getElementById(firstErrorKey)
            if (element) {
                setTimeout(() => {
                    element.focus();
                    element.scrollIntoView({ block: 'center' });
                }, 250)
            }

            form.renderForm()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleId, initialValues])


    function onAnnuler () {
        navigateTo(dossierId!, etape)
    }

    return (
        <>
            <PageHeader>
                { `${ props.metadata.title } ${ dossierId ? `<br />${ dossierId }` : ''}` }
            </PageHeader>
            
            <AtlasColumns>
                <div>
                    <Form context={ form } autoComplete="off" noValidate disabled={ loading }>
                        <AtlasHeading tag="h2" size="m">{ moduleId == null ? "Ajouter un module" : "Modifier un module" }</AtlasHeading>
                        <MyForm pending={loading} >
                            { !loading && error && <AtlasInfo
                                type="error"
                                title="Erreur sur ce dossier"
                            >{ error }</AtlasInfo> }

                            <DossierModulesElements metadata={ props.metadata } step={ step } modulesProperty={ modulesProperty } index={ index } />

                            { !loading && <MyForm.Actions>
                                <Button level={ 2 } onClick={ onAnnuler }>Annuler</Button>
                                <Button submit={ true } spinner={{spinning: saving}}>Enregistrer</Button>
                            </MyForm.Actions> }
                        </MyForm>
                    </Form>
                </div>
            </AtlasColumns>
        </>
    )
}
