import { DossierDetail } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_AJOUT_SUBROGATION, DEMANDE_EN_COURS_ANNULATION, DEMANDE_EN_COURS_ANNULATION_SUBROGATION, DEMANDE_EN_COURS_CLOTURE, DEMANDE_EN_COURS_MODIFICATION_DATES, ETAT_DOSSIER_CLOTURE } from 'api/references'
import { DossierRelanceCloturer } from './DossierRelanceCloturer'
import { DossierRelanceSubrogationAnnuler, DossierRelanceSubrogationDemander } from './DossierRelanceSubrogation'
import { peutRelancerDossier } from '.'

const alertesMessagesEntreprise = (dossier: DossierDetail | null) => {
    const messages = []
    const alertes = dossier?.TypeAlerteADH__c?.split(',')?.map(t => parseInt(t))
    if (alertes?.some(type => type >= 1322 && type <= 1325))
        messages.push("Formation terminée mais solde entreprise restant")
    if (alertes?.some(type => type === 5000))
        messages.push("Dossier incomplet")
    return messages
}

const demandesMessagesEntreprise = (dossier: DossierDetail | null) => {
    const messages: string[] = []
    dossier?.DemandesEnCours?.forEach(type => {
        if (type === DEMANDE_EN_COURS_ANNULATION)
            messages.push("Demande d'annulation")
        else if (type === DEMANDE_EN_COURS_CLOTURE)
            messages.push("Demande de clôture - réalisation partielle")
        else if (type === DEMANDE_EN_COURS_ANNULATION_SUBROGATION)
            messages.push("Demande d'annulation de subrogation")
        else if (type === DEMANDE_EN_COURS_AJOUT_SUBROGATION)
            messages.push("Demande de subrogation")
        else if (type === DEMANDE_EN_COURS_MODIFICATION_DATES)
            messages.push("Demande de modification des dates de formation")
    })
    return messages
}

export const relanceMessagesEntreprise = (dossier: DossierDetail) => {
    return {
        demandes: demandesMessagesEntreprise(dossier),
        alertes: alertesMessagesEntreprise(dossier),
    }
}

const alertesEntrepriseTermineNonSolde = (dossier: DossierDetail) => {
    const alertes = dossier?.TypeAlerteADH__c?.split(',')?.map(t => parseInt(t))
    return alertes?.some(type => type >= 1322 && type <= 1325) === true
}

const alertesOFTermineNonSolde = (dossier: DossierDetail) => {
    const alertes = dossier?.TypeAlerteOF__c?.split(',')?.map(t => parseInt(t))
    return alertes?.some(type => type >= 1326 && type <= 1329) === true
}

const peutCloturerDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return (dossier.EtatDossierFO__c !== ETAT_DOSSIER_CLOTURE &&
        dossier.SubrogationPaiement__c === false &&
        alertesEntrepriseTermineNonSolde(dossier) &&
        // dossier.DemandesEnCoursADH__c?.split(',')?.map(t => parseInt(t))?.includes(DEMANDE_EN_COURS_CLOTURE) !== true &&
        (dossier.MontantCoutFormationPaye__c ?? 0) > 0.01)
}

const peutDemanderSubrogationDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return dossier.SubrogationPaiement__c === true &&
        alertesOFTermineNonSolde(dossier) &&
        // dossier.DemandesEnCoursADH__c?.split(',')?.map(t => parseInt(t))?.includes(DEMANDE_EN_COURS_ANNULATION_SUBROGATION) !== true &&
        (dossier.MontantCoutFormationPaye__c ?? 0) < 0.01
}

const peutAnnulerSubrogationDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return dossier.SubrogationPaiement__c === false &&
        alertesEntrepriseTermineNonSolde(dossier) &&
        // dossier.DemandesEnCoursADH__c?.split(',')?.map(t => parseInt(t))?.includes(DEMANDE_EN_COURS_AJOUT_SUBROGATION) === false &&
        (dossier.MontantCoutFormationPaye__c ?? 0) < 0.01
}

export const actionsEntrepriseRelanceCloture = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceCloturer key="cloturer" detail={ dossier } when={ peutCloturerDossier } />)
}

export const actionsEntrepriseRelanceDemanderSubrogation = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceSubrogationAnnuler key="demanderSubrogation" detail={ dossier } when={ peutDemanderSubrogationDossier } />)
}

export const actionsEntrepriseRelanceAnnulerSubrogation = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceSubrogationDemander key="annulerSubrogation" detail={ dossier } when={ peutAnnulerSubrogationDossier } />)
}
