import { Diagnostic } from 'slices/diagatlas/diagnosticSlice'
import { Page, axiosDiag, getRequestConfig } from './baseAPI'
import { isArray } from 'lodash'
import { DocumentsSearchQuery, PieceDTO, getSearchDocumentsParams } from './documentAPI'
import store from 'app/store'
import axios from 'axios'

export interface DiagSecteurDTO {
    Id: string
    Code: string
    Libelle: string
}

export interface DiagDiagnosticProfilDTO {
    Entreprise: string
    DateCreation?: string
    ContactCreation?: string
    DateRealisation?: string
    ContactRealisation?: string
    DateModification?: string
    ContactModification?: string
    Statut?: string
    Etape?: string
    Secteur: string
    TailleEntreprise: string
}

export interface DiagFacteurDTO {
    Id: string
    Code: string
    Categorie: {
        Code: string
        Libelle: string
        Description: string
    }
    Type: string
    Libelle: string
    Description: string
    LienURL1?: string
    LienURL2?: string
}

export interface DiagDiagnosticScenarioDTO {
    Scenario: {
        Categorie: string
        Code: string
        Facteur: DiagFacteurDTO
        Libelle: string
    }
    Choix: boolean
    Prechoix: boolean
}

export interface DiagDiagnosticBesoinDTO {
    Besoin: {
        Id: string
        Type: string
        Code: string
        Libelle: string
    }
    Choix: boolean
}

export interface DiagMetierDTO {
    Id: string
    Famille: {
        Id: string
        Code: string
        Libelle: string
    }
    Code: string
    Libelle: string
}

export interface DiagCompetenceDTO {
    Id: string
    Famille: {
        Id: string
        Code: string
        Libelle: string
    }
    Code: string
    Libelle: string
}

export interface DiagDiagnosticMetierDTO {
    Metier: DiagMetierDTO
    AjoutEntreprise: boolean
    Sinformer: boolean
    Former: boolean
    Recruter: boolean
}

export interface DiagDiagnosticCompetenceDTO {
    Competence: DiagCompetenceDTO
    AjoutEntreprise: boolean
    Actif: boolean
}

export const CATEGORIES_TYPES_ACTIONS = {
    'CAC_1001': 'Formation',
    'CAC_1002': 'Recrutement',
    'CAC_1003': 'Pratiques RH',
}

export interface DiagTypeActionDTO {
    Id: string
    Code: string
    Categorie: keyof typeof CATEGORIES_TYPES_ACTIONS
    CategorieLibelle: string
    Libelle: string
}

export interface DiagActionDTO {
    Id: string
    Type: DiagTypeActionDTO
    Source: string
    Entreprise: string
    Code: string
    Libelle: string
    URL: string
}

export interface DiagDiagnosticActionDTO {
    Id: string
    ExternalIdHeroku: string
    Action: DiagActionDTO
    AjoutEntreprise: boolean
    DateAjout: string
    DiagnosticDateModification: string | null
    DiagnosticContactModification: string | null
    Statut: string
    Ordre: number
}

export interface DiagDiagnosticDTO {
    Id: string
    Profil: DiagDiagnosticProfilDTO
    Scenarios?: DiagDiagnosticScenarioDTO[]
    Besoins?: DiagDiagnosticBesoinDTO[]
    Metiers?: DiagDiagnosticMetierDTO[]
    Competences?: DiagDiagnosticCompetenceDTO[]
    PlanAction?: DiagDiagnosticActionDTO[]
    HasPlanAction?: boolean | null
}

export async function getSecteurs(): Promise<DiagSecteurDTO[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.get<DiagSecteurDTO[]>('/secteurs', requestConfig)
    return data
}

export async function getFacteur(facteurId: string): Promise<DiagFacteurDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.get<DiagFacteurDTO>('/facteurs/' + facteurId, requestConfig)
    return data
}

export async function getMetiers(recherche: string): Promise<DiagMetierDTO[]> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('recherche', recherche)
    requestConfig.params = params
    const { data } = await axiosDiag.get<DiagMetierDTO[]>('/metiers', requestConfig)
    return data
}

export async function getCompetences(recherche: string): Promise<DiagCompetenceDTO[]> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('recherche', recherche)
    requestConfig.params = params
    const { data } = await axiosDiag.get<DiagCompetenceDTO[]>('/competences', requestConfig)
    return data
}

export async function getTypesAction(entrepriseId: string): Promise<DiagTypeActionDTO[]> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('entreprise', entrepriseId)
    requestConfig.params = params
    const { data } = await axiosDiag.get<DiagTypeActionDTO[]>('/types-action', requestConfig)
    data.forEach(t => t.CategorieLibelle = CATEGORIES_TYPES_ACTIONS[t.Categorie])
    return data
}

export async function getActions(entrepriseId: string, type: string, recherche: string): Promise<DiagActionDTO[]> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('entreprise', entrepriseId)
    params.append('type', type)
    params.append('recherche', recherche)
    requestConfig.params = params
    const { data } = await axiosDiag.get<DiagActionDTO[]>('/actions', requestConfig)
    data.forEach(a => a.Type.CategorieLibelle = CATEGORIES_TYPES_ACTIONS[a.Type.Categorie])
    return data
}

export async function getDiagnostic(entrepriseId: string, detailed?: boolean, section?: string | string[], categorie?: string, statuts?: string | string[]): Promise<DiagDiagnosticDTO> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    if (detailed)
        params.append('detailed', 'true')
    if (section && isArray(section))
        section.forEach(s => params.append('sections', s))
    else if (section)
        params.append('sections', section)
    if (categorie)
        params.append('categories', categorie)
    if (statuts && isArray(statuts))
        statuts.forEach(s => params.append('statut', s))
    else if (statuts)
        params.append('statuts', statuts)
    requestConfig.params = params
    const { data } = await axiosDiag.get<DiagDiagnosticDTO>('/diagnostics/' + entrepriseId, requestConfig)
    return data
}

export async function saveDiagnostic(diagnosticDTO: DiagDiagnosticDTO, reset: boolean): Promise<DiagDiagnosticDTO> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('reset', reset?.toString() ?? 'false')
    requestConfig.params = params
    const { data } = await axiosDiag.post<DiagDiagnosticDTO>('/diagnostics', diagnosticDTO, requestConfig)
    return data
}

export async function deleteDiagnostic(diagnosticDTO: DiagDiagnosticDTO): Promise<DiagDiagnosticDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.delete<DiagDiagnosticDTO>(`/diagnostics/${diagnosticDTO.Profil.Entreprise}`, requestConfig)
    return data
}

export async function saveDiagnosticEtape(diagnosticDTO: DiagDiagnosticDTO, section?: string | string[], categorie?: string): Promise<DiagDiagnosticDTO> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    if (section && isArray(section))
        section.forEach(s => params.append('sections', s))
    else if (section)
        params.append('sections', section)
    if (categorie)
        params.append('categories', categorie)
    requestConfig.params = params
    const { data } = await axiosDiag.patch<DiagDiagnosticDTO>(`/diagnostics/${diagnosticDTO.Profil.Entreprise}`, diagnosticDTO, requestConfig)
    return data
}

export async function updateDiagnosticScenario({ Entreprise, Facteur, Categorie }: { Entreprise: string, Facteur: string, Categorie: string }): Promise<DiagDiagnosticScenarioDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticScenarioDTO>(`/diagnostics/${Entreprise}/scenarios`, { Facteur, Categorie }, requestConfig)
    return data
}

export async function updateDiagnosticBesoin({ Entreprise, Besoin, Choix }: { Entreprise: string, Besoin: string, Choix: boolean }): Promise<DiagDiagnosticBesoinDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticBesoinDTO>(`/diagnostics/${Entreprise}/besoins`, { Besoin, Choix }, requestConfig)
    return data
}

export async function updateDiagnosticCompetence({ Entreprise, Competence, AjoutEntreprise, Actif }: { Entreprise: string, Competence: string, AjoutEntreprise?: boolean | null, Actif?: boolean | null }): Promise<DiagDiagnosticCompetenceDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticCompetenceDTO>(`/diagnostics/${Entreprise}/competences`, { Competence, AjoutEntreprise, Actif }, requestConfig)
    return data
}

export async function deleteDiagnosticCompetence({ Entreprise, Competence }: { Entreprise: string, Competence: string }): Promise<void> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.delete<void>(`/diagnostics/${Entreprise}/competences/${Competence}`, requestConfig)
    return data
}

export async function updateDiagnosticMetier({ Entreprise, Metier, AjoutEntreprise, Sinformer, Former, Recruter }: { Entreprise: string, Metier: string, AjoutEntreprise?: boolean | null, Sinformer?: boolean | null, Former?: boolean | null, Recruter?: boolean | null }): Promise<DiagDiagnosticMetierDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticMetierDTO>(`/diagnostics/${Entreprise}/metiers`, { Metier, AjoutEntreprise, Sinformer, Former, Recruter }, requestConfig)
    return data
}

export async function deleteDiagnosticMetier({ Entreprise, Metier }: { Entreprise: string, Metier: string }): Promise<DiagDiagnosticMetierDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.delete<DiagDiagnosticMetierDTO>(`/diagnostics/${Entreprise}/metiers/${Metier}`, requestConfig)
    return data
}

export async function validerPlanActions(diagnosticDTO: DiagDiagnosticDTO): Promise<DiagDiagnosticDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticDTO>(`/diagnostics/${diagnosticDTO.Profil.Entreprise}/creer-plan-action`, diagnosticDTO, requestConfig)
    return data
}

export async function addDiagnosticAction({ Entreprise, Action, preconisee }: { Entreprise: string, Action: string, preconisee: boolean }): Promise<DiagDiagnosticActionDTO> {
    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    if (preconisee)
        params.append('preconisee', 'true')
    requestConfig.params = params
    const { data } = await axiosDiag.post<DiagDiagnosticActionDTO>(`/diagnostics/${Entreprise}/ajout-action`, { Action }, requestConfig)
    data.Action.Type.CategorieLibelle = CATEGORIES_TYPES_ACTIONS[data.Action.Type.Categorie]
    return data
}

export async function createDiagnosticAction({ Entreprise, ActionInfo }: { Entreprise: string, ActionInfo: { Categorie: string, Libelle: string, URL?: string } }): Promise<DiagDiagnosticActionDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticActionDTO>(`/diagnostics/${Entreprise}/creer-action`, ActionInfo, requestConfig)
    data.Action.Type.CategorieLibelle = CATEGORIES_TYPES_ACTIONS[data.Action.Type.Categorie]
    return data
}

export async function moveDiagnosticAction({ Entreprise, Action, Statut, Ordre }: { Entreprise: string, Action: string, Statut: string, Ordre?: number }): Promise<DiagDiagnosticActionDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticActionDTO>(`/diagnostics/${Entreprise}/change-action`, { Action, Statut, Ordre }, requestConfig)
    data.Action.Type.CategorieLibelle = CATEGORIES_TYPES_ACTIONS[data.Action.Type.Categorie]
    return data
}

export async function desactiverDiagnosticAction({ Entreprise, Action }: { Entreprise: string, Action: string }): Promise<DiagDiagnosticActionDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axiosDiag.post<DiagDiagnosticActionDTO>(`/diagnostics/${Entreprise}/desactiver-action/${Action}`, null, requestConfig)
    return data
}

export async function getDiagnosticExport(diagnostic: Diagnostic): Promise<any> {
    const requestConfig = await getRequestConfig()
    const content = await axiosDiag.post<any>(`/diagnostics/${diagnostic.entrepriseId}/exporter-diagnostic`, null, { ...requestConfig, responseType: 'blob' })
    return content
}

export function openDocumentExport(docName: string, response: any) {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', docName)
    document.body.appendChild(fileLink)

    fileLink.click()
}

export async function downloadDiagnosticExportDoc(diagnostic: Diagnostic, filename: string) {
    return getDiagnosticExport(diagnostic).then(response => {
        openDocumentExport(filename, response)
    })
}

export async function getDiagnosticDocuments(query?: DocumentsSearchQuery | null): Promise<Page<PieceDTO>> {
    const requestConfig = await getRequestConfig()

    requestConfig.params = getSearchDocumentsParams(query ?? { page: 0, parPage: 20, ordre: 'desc', tri: 'dateAjoutTechnique' })
    requestConfig.params.append('type', 'DIAGNOSTIC')

    // const entrepriseId = store.getState().contextState.entreprise?.Id ?? ''
    // const { data, headers } = await axiosDiag.get<PieceDTO[]>(`/diagnostics/${entrepriseId}/documents`, requestConfig)
    const siren = store.getState().contextState.entreprise?.SIREN__c ?? ''
    const { data, headers } = await axios.get<PieceDTO[]>('/entreprises/siren/' + siren + '/documents', requestConfig)

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length

    return {
        totalCount: totalCount,
        items: data,
    }
}
