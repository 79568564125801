import styles from 'pages/dossier/cerfa/global.styles'
import { Style } from '@react-pdf/types'
import { formatNumber } from 'components/format/Format'
import { Text } from '@react-pdf/renderer'

interface CerfaNumberFieldProps {
    value: number | '' | null | undefined
    suffix?: string
    placeholder?: string
    fractionDigits?: number
    style?: Style | Style[]
}

export default function CerfaNumberField(props: CerfaNumberFieldProps) {
    if (props.value === null || props.value === undefined) {
        return null
    }

    const style = props.style ?? styles.user

    const suffix = props.suffix === '%' ? '%' : props.suffix ? ' ' + props.suffix : ''
    const placeholder = props.placeholder ?? ' '
    const value = props.value ? formatNumber(props.value, suffix, placeholder, props.fractionDigits) : placeholder

    return <Text style={ style }>{ value }</Text>
}
