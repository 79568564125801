import { AtlasButtonProps, AtlasFieldTextProps } from "atlas-ds";

export interface MySearchProps {
  /**
   * L'input de recherche
   */
  input: React.ReactElement<AtlasFieldTextProps>;
  /**
   * Le bouton de validation
   */
  button: React.ReactElement<AtlasButtonProps>;
}

/**
 * Un input de recherche accompagné de son bouton de validation
 */
export function MySearch(props: MySearchProps) {
  return (
    <div className="my-search">
      {props.input}
      {props.button}
    </div>
  );
}
