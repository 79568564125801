import { Image, Text } from '@react-pdf/renderer';
import checkboxOnImage from 'pages/dossier/cerfa/img/checkbox-on.png';
import checkboxOffImage from 'pages/dossier/cerfa/img/checkbox-off.png';

type Choice = { label: string, value: string | boolean }

export default function Checkboxes({ value, choices, spacing = 1 }: {
  value: string | boolean | null | undefined,
  choices?: Choice[],
  spacing?: number
}) {
  const space = Array(spacing).fill(' ').join('');

  if (!choices) {
    choices = [
      { label: 'oui', value: true },
      { label: 'non', value: false },
    ];
  }

  /** Compare choice's value (or label) with user's value */
  function choiceIsSelected(choice: Choice) {
    if (choice.value !== undefined) {
      return value === choice.value;
    } else {
      return value === choice.label;
    }
  }

  return (
    <>{ choices.map((choice, index) => 
      <Text key={index}>
        <Image
          source={choiceIsSelected(choice) ? checkboxOnImage : checkboxOffImage}
        ></Image>{' '}
        <Text>{choice.label}</Text>
        {index < (choices ?? []).length - 1 && space}
      </Text>
    )}
    </>
  );
}
