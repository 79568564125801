import { validerMonCompte } from "api/monCompteAPI"
import useApiState from "api/useApiState"
import { AtlasFlex, AtlasInfo } from "atlas-ds"
import PageHeader from "components/PageHeader"
import log from 'loglevel'
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

export function CreerMonCompteSucces() {

    const location = useLocation();
    const [backendError, setBackendError] = useState<string | null>(null);
    const [reponseMAMC, setResponseMAMC] = useState<any>();

    const [, withValidateCompte] = useApiState(validerMonCompte, { pending: false });

    useEffect(() => {
        const pathnameSubstring = location.pathname.substring(25);

        if (pathnameSubstring) {
            withValidateCompte(pathnameSubstring)
                .then(response => {
                    setResponseMAMC(response as any);
                })
                .catch(error => {
                    log.error('Erreur validation compte', error);
                    const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite.";
                    setBackendError(message);
                });
        }
    }, [location.pathname,withValidateCompte]);

    const validateSuccess = (typeof reponseMAMC?.success !== 'undefined') ? reponseMAMC?.success : null;

    return (
        <>
            <PageHeader>
                {reponseMAMC?.success ? "Votre compte est validé" : validateSuccess != null ? "Erreur de validation du compte" : ""}
            </PageHeader>

            <AtlasFlex column gap="m">
                {(reponseMAMC?.success && validateSuccess != null) ? (
                    <AtlasInfo
                        type="success"
                        title="Votre espace est ouvert !"
                        link={<a href="/?contexte=compte">Me connecter à myAtlas</a>}
                    >Accédez dès maintenant à tous nos services en ligne en vous connectant à votre compte myAtlas.</AtlasInfo>
                ) : (
                    (!reponseMAMC?.success && validateSuccess != null) ? (
                        <AtlasInfo
                            type="error"
                            title="Erreur"
                            link={<Link to="/creer-mon-compte">Retour à la page de création de compte</Link>}
                        >{ reponseMAMC?.message }</AtlasInfo>
                    ) : null
                )}

                {backendError && (
                    <AtlasInfo
                        type="error"
                        title="Erreur"
                        link={<Link to="/creer-mon-compte">Retour à la page de création de compte</Link>}
                    >{ backendError }</AtlasInfo>
                )}
            </AtlasFlex>
        </>
    );

}