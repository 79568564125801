import { PieceDTO, uploadDocument } from 'api/documentAPI'
import { DossierDetail, DossierPieceDTO, getDossier, getDossierDocumentTypes, getDossierDocumentsStatus, sendDossierDocuments } from 'api/dossierAPI'
import DepotFichierModal, { DepotFichierModalProps } from 'components/docs/DepotFichierModal'
import DepotFichier from 'pages/detail-common/DepotFichier'
import DetailDossierContrat, { detailDossierDocumentsRefreshEvent } from 'pages/detail-dossier/DetailDossierContrat'
import DossierDeposerFactureSelfe from 'pages/detail-dossier/DossierDeposerFactureSelfe'
import useDossiersModuleContext from './useDossiersModuleContext'


export default function DetailDossierEntreprise() {
    const ctx = useDossiersModuleContext()
    
    const depotPieces = (dossier: DossierDetail, documents: PieceDTO[], pending: boolean) => {
        const appModule = ctx?.findModule(dossier)
        
        return <DepotFichier idParent={ dossier?.NumeroDossier__c }
            documents={ documents ?? [] }
            transmissionEnCours={ dossier?.EnCoursTransmissionSOR__c }
            disabled={ pending }
            sendDocuments={ sendDossierDocuments }
            checkSendDocumentsStatus={ getDossierDocumentsStatus }
            confirmTitle="Confirmer l'ajout de nouvelles pièces"
            confirmMessage="Les nouvelles pièces ajoutées seront transmises à nos services."
            sendSuccessMessage="Les nouvelles pièces ont bien été ajoutées au dossier"
            refreshEvent={ detailDossierDocumentsRefreshEvent }
            DepotFichierModal={ (props: DepotFichierModalProps<DossierPieceDTO>) =>
                <DepotFichierModal<DossierPieceDTO>
                    { ...props }
                    warningMessage={ appModule?.documentWarningMessage }
                    documentTypeFilter={ appModule?.documentTypeFilter }
                    getDocumentTypes={ getDossierDocumentTypes }
                    upload={ uploadDocument }
                />
            }
        />
    }
    
    return <DetailDossierContrat getDossier={ getDossier } depotPieces={ depotPieces }
        actions={ (dossier, actions) => {
            if (actions.length === 0) {
                // Ajout de facture par défaut pour les types de dossiers non gérés
                actions.push(<DossierDeposerFactureSelfe key="deposerFacture" detail={ dossier } mode="facture" />)
            }
        } } />
}
