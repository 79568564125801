import { AtlasFlex, AtlasHeading, AtlasLoading, MyContainer } from "atlas-ds"

interface ToolboxProps {
    heading: string
    items: JSX.Element | JSX.Element[] | undefined | null
    loading?: boolean
}

export default function Toolbox(props: ToolboxProps) {
    if (!props.items || (Array.isArray(props.items) && props.items.length === 0)) {
        return undefined
    }
    return <section>
        <AtlasHeading tag="h3" size="m">{ props.heading }</AtlasHeading>
        <MyContainer colored>
            <AtlasLoading loading={ props.loading ?? false }>
                <AtlasFlex gap="m" column>
                    { props.items }
                </AtlasFlex>
            </AtlasLoading>
        </MyContainer>
    </section>
}
