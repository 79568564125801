import { DISPOSITIF_ACN } from 'api/references'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasLoading, AtlasSelect, MyNotifications } from 'atlas-ds'
import { parseISO } from 'date-fns'
import { useSelector } from 'react-redux'
import { notificationsActions } from 'slices/notificationsSlice'
import { ToastService } from './BandeauAlert'
import Notification from './Notification'

export interface NotificationsProps {
    toggleNotificationsOpen: (() => void)
    isPrestataire: boolean
}

export default function Notifications(props: NotificationsProps) {
    const dispatch = useAppDispatch()

    const { pending, notifications: notificationsPage } = useSelector((state: RootState) => state.notificationsState)

    const update = (id: string, lu: boolean) => {
        dispatch(notificationsActions.markAsRead(id)).catch((_) => {
            ToastService.showToast({
                type: "error",
                label: "Notifications",
                content: "La notification n'a pas pu être marquée comme lue. Veuillez réessayer ultérieurement."
            })
        })
    }

    const loadMore = (typeRef?: string) => {
        dispatch(notificationsActions.load({
            page: (notificationsPage?.page ?? -1) + 1,
            parPage: 50,
            lu: false,
            typeReference: typeRef ?? ""
        }))
    }

    function NotifHandleChange(event: any) {

        dispatch(notificationsActions.load({
            page: 0,
            parPage: 50,
            lu: false,
            typeReference: event.target.value === "DossierCampusAtlas" ? '' : event.target.value,
            DispositifFO: event.target.value === "DossierCampusAtlas" ? DISPOSITIF_ACN : ''

        }))
    }

    const deleteAllNotifications = () => {
        dispatch(notificationsActions.delete()).catch((_) => {
            ToastService.showToast({
                type: "error",
                label: "Notifications",
                content: "La notification n'a pas pu être marquée comme lue. Veuillez réessayer ultérieurement."
            })
        })

    }

    const notifications = notificationsPage?.items.map((notification) => {
        return { ...notification, date: parseISO(notification.CreatedDate) }
    }) ?? []


    return (
        <AtlasLoading loading={pending}>
            <MyNotifications
                notifications={notifications
                    .map((notification) => <Notification
                        notification={notification}
                        update={update}
                        key={notification.Id}
                        isPrestataire={props.isPrestataire}/>
                    )}
                onClose={props.toggleNotificationsOpen}
                onDeleteAll={deleteAllNotifications}
                select={<AtlasSelect
                    name="notifications-type"
                    ariaLabel="Filtrage des notifications par type"
                    onChange={NotifHandleChange}
                    options={[{
                        value: "",
                        label: "Toutes"
                    }, {
                        value: "Dossier",
                        label: "Dossiers"
                    }, {
                        value: "DossierCampusAtlas",
                        label: "Dossiers (campusAtlas)"
                    }, {
                        value: "Facture",
                        label: "Factures"
                    }]}
                />}
                onScrollEnd={loadMore}
                totalCount={notificationsPage?.totalCount ?? 0}
                loadedCount={notifications.length}
            />
        </AtlasLoading>
    )
}
