import { BaseSearchQuery } from 'api/baseAPI'
import { AtlasButton } from 'atlas-ds'
import { useState } from 'react'
import ExportModal from '../ExportModal'

export function ExportAction<Q extends BaseSearchQuery>(props: {
    disabled: boolean,
    query: Q,
    export: (query: Q, email: string) => Promise<string>,
    children: JSX.Element[]
    initialValues?: any
}) {
    const [show, setShow] = useState(false);

    return (
        <>
            <AtlasButton level={2} disabled={props.disabled} onClick={() => setShow(true)}>
                Exporter mes données filtrées
            </AtlasButton>
            { show &&
            <ExportModal show={true} query={props.query} export={props.export} close={() => setShow(false)} initialValues={props.initialValues}>
                {props.children}
            </ExportModal>
            }
        </>
    );
}
