import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasColumns, AtlasFlex, AtlasHeading, AtlasValue, AtlasValues, MyContainer } from 'atlas-ds'
import Breadcrumb from 'components/Breadcrumb'
import PageHeader from "components/PageHeader"
import Toolbox from 'components/Toolbox'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AdressesCompteEntreprise from './AdressesCompteEntreprise'
import ContactsCompteEntreprise from './ContactsCompteEntreprise'
import PresentationCompteEntreprise from './PresentationCompteEntreprise'

export default function GestionCompteEntreprise() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const [editing, setEditing] = useState(false)

    const [showAddContact, setShowAddContact] = useState(false)
    const [showAddAdresse, setShowAddAdresse] = useState(false)

    return (
        <>
            <PageHeader
                breadcrumb={<Breadcrumb
                    parts={[{
                        label: "Formations Campus",
                        href: "/mes-formations"
                    }]}
                    currentPageLabel="Gestion du compte OF Campus"
                />}
            >{ entreprise?.Name || ''}</PageHeader>

            <AtlasColumns>
                <AtlasFlex column gap="xl">
                    <section>
                        <AtlasHeading tag="h2" size="m">Informations principales</AtlasHeading>
                        <MyContainer>
                            <AtlasValues>
                                <AtlasValue label="Adresse">
                                    { entreprise?.BillingStreet }<br />{ entreprise?.BillingPostalCode } { entreprise?.BillingCity }
                                </AtlasValue>
                                <AtlasValue label="SIREN">
                                    { entreprise?.SIREN__c }
                                </AtlasValue>
                                <AtlasValues.Grid>
                                    <AtlasValue label="Code OF">
                                        { entreprise?.CodeEtablissement__c}
                                    </AtlasValue>
                                    <AtlasValue label="NDA">
                                        { entreprise?.NumeroDA__c}
                                    </AtlasValue>
                                </AtlasValues.Grid>
                            </AtlasValues>
                        </MyContainer>
                    </section>

                    <PresentationCompteEntreprise entreprise={ entreprise } editing={ editing } setEditing={ setEditing } />

                    <ContactsCompteEntreprise entreprise={ entreprise } editing={ editing } showAddContact={ showAddContact } setShowAddContact={ setShowAddContact } />

                    <AdressesCompteEntreprise entreprise={ entreprise } editing={ editing } showAddAdresse={ showAddAdresse } setShowAddAdresse={ setShowAddAdresse } />
                </AtlasFlex>

                <div>
                    <Toolbox
                        heading="Actions"
                        items={<>
                            <AtlasButton level={ 2 } disabled={ editing } onClick={() => setShowAddContact(true)}>
                                Ajouter un contact
                            </AtlasButton>
                            <AtlasButton level={ 2 } disabled={ editing } onClick={() => setShowAddAdresse(true)}>
                                Ajouter une adresse
                            </AtlasButton>
                        </>}
                    />
                </div>
            </AtlasColumns>
        </>
    )
}
