import { ValidationContext } from '@dsid-opcoatlas/yop'
import { FORMATION_CERTIFIANTE_NON, FORMATION_CERTIFIANTE_OUI, OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES, OBJECTIF_GENERAL_OF_CERTIFICATION, TYPE_COUT_PEDAGOGIQUE, TYPE_FORMATION_INTERNE } from 'api/references'
import { textField, codePostalCommuneField, object, radioBooleanField, dto, overrides, dateField, heuresField, currencyField, numeroDossierApprentissageField, array, radioRefField, selectRefField } from "components/elements/decorators"
import { ClassConstructor, FormFieldContext } from 'components/elements/engine'
import { observer } from 'components/elements/observers'
import { OptionalDA } from 'components/metadata/CFAFieldMetadata'
import { OptionalSiret } from "components/metadata/SiretFieldMetadata"
import { TOOLTIPS } from 'pages/dossier/Tooltips'

export class Adresse {
    
    @textField({ label: 'Appartement', max: 38 })
    appartement: string | null = null
    
    @textField({ label: 'Bâtiment', max: 38 })
    batiment: string | null = null
    
    @textField({ label: 'N° et voie', max: 43 })
    adresse: string | null = null
    
    @textField({ label: 'Lieu dit / BP', max: 38 })
    complement: string | null = null
    
    @codePostalCommuneField({ required: true })
    codePostal: string | null = null
    
    @textField({ visible: false, required: true, max: 50 })
    commune: string | null = null
}

@overrides({
    appartement: { dto: "Adresse1ADH__c", disabled: true },
    batiment: { dto: "Adresse2ADH__c", disabled: true },
    adresse: { dto: "Adresse3ADH__c", disabled: true },
    complement: { dto: "Adresse4ADH__c", disabled: true },
    codePostal: { dto: "CodePostalADH__c", disabled: true },
    commune: { dto: "CommuneADH__c", disabled: true },
})
export class DossierEmployeur extends Adresse {

    @dto("EtablissementAdherentId__c")
    Id: string | null = null

    @dto("EntrepriseAdherentId__c")
    ParentId: string | null = null

    @textField({ label: "Raison sociale", disabled: true, required: true, dto: "NomADH__c" }) 
    nom: string | null = null
    
    @textField({ label: "SIRET", disabled: true, schema: OptionalSiret, required: true, dto: "SiretADH__c" })
    numeroSiret: string | null = null
}

export class DossierSignature {
    
    // exportLabel: 'Par',
    @textField({ label: "Je soussigné", required: true, dto: "NomPrenomSignature__c" })
    nom: string | null = null
    
    @textField({ label: "Fait à", required: true, dto: "LieuSignature__c" })
    lieu: string | null = null
    
    @dateField({ label: "Le", required: true, visible: false, dto: "DateSignature__c" })
    date: Date | null = null
}


export class BaseDossier {
    
    @dto("DispositifFO__c")
    DispositifFO__c: string
    
    @dto("Id")
    Id: string | null = null
    
    @dto("IdHeroku")
    IdHeroku: number | null = null
    
    @dto("NumeroDossier__c")
    NumeroDossier__c: string | null = null
    
    @dto("EtatDossierFO__c")
    EtatDossierFO__c: string | null = null
    
    @dto("EnCoursTransmissionSOR__c")
    traitementEnCours: boolean | null = null

    @object({ type: DossierEmployeur, required: true })
    @dto("/")
    employeur: DossierEmployeur

    // pieces: [] as DossierPieceDTO[]

    @object({ type: DossierSignature, required: true })
    @dto("/")
    signature: DossierSignature

    constructor(DispositifFO__c: string, employeur?: DossierEmployeur) {
        this.DispositifFO__c = DispositifFO__c
        
        this.employeur = employeur ?? new DossierEmployeur()
        this.signature = new DossierSignature()
    }
}
    
function cfaFieldDisabled(context: FormFieldContext<any>) {
    return context.getParent<DossierCFA>()?.EtablissementOFId__c !== null
}

function cfaAdresseFieldDisabled(context: FormFieldContext<any>) {
    return context.getParent<DossierCFA>() === null
}

@overrides({
    appartement: { dto: "Adresse1OF__c", disabled: cfaAdresseFieldDisabled },
    batiment: { dto: "Adresse2OF__c", disabled: cfaAdresseFieldDisabled },
    adresse: { dto: "Adresse3OF__c", disabled: cfaAdresseFieldDisabled },
    complement: { dto: "Adresse4OF__c", disabled: cfaAdresseFieldDisabled },
    codePostal: { dto: "CodePostalOF__c", disabled: cfaFieldDisabled },
    commune: { dto: "CommuneOF__c", visible: false },
})
export class DossierCFA extends Adresse {
    
    @dto("IdHeroku")
    IdHeroku: number | null = null
    
    @dto("EtablissementOFId__c")
    EtablissementOFId__c: string | null = null
    
    @dto("CodeEtablissementOF__c")
    CodeEtablissementOF__c: string | null = null

    @dto("EtatEtablissementOF__c")
    etat: string | null = null
    
    @textField({ label: "Dénomination de l’organisme de formation", disabled: cfaFieldDisabled, required: true, max: 200, dto: "NomOF__c" })
    nom: string | null = null

    @textField({ label: "N° de SIRET de l'établissement de formation responsable", disabled: cfaFieldDisabled, schema: OptionalSiret, dto: "SiretOF__c" })
    siret: string | null = null

    @textField({ label: "N° de déclaration d'activité", tooltip: TOOLTIPS.cfa.numeroDA, disabled: cfaFieldDisabled, schema: OptionalDA, dto: "NdaOF__c" })
    numeroDA: string | null = null
    
    @textField({ label: "N° UAI de l'organisme", disabled: cfaFieldDisabled, schema: OptionalDA, dto: "UaiOF__c" })
    numeroUAI: string | null = null
}

export class TypeCout {
    
    @dto("IdHeroku")
    IdHeroku: number | null = null

    @dto("MontantCoutPedagogique__c")
    TypeCout__c: string | null
    
    @currencyField({ suffix: "€ HT", required: true, max: 300_000, dto: "MontantDemande__c" })
    montant: number | null = null

    constructor(typeCout?: string | null) {
        this.TypeCout__c = typeCout ?? null
    }
}

/*
        if (value) {
            context.setValue(`${props.parentPath}.typeFormation`, TYPE_FORMATION_INTERNE)
            context.setValue(`${props.parentPath}.cfa`, null, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.subrogation`, false, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.attestationFormationInterne`, null, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.adresseRealisationIdentiqueAgora`, true, true)
        }
        else {
            context.untouch(`${props.parentPath}.cfa`)
            context.setValue(`${props.parentPath}.typeFormation`, null, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.attestationFormationInterne`, false, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.adresseRealisationIdentiqueAgora`, null, SetValueOptions.Untouch | SetValueOptions.Validate)
        }

*/
export class BaseDossierModule {
    
    IdHeroku: number | null = null

    @radioBooleanField({
        label: "Il s'agit d'un service de formation interne",
        labelYes: "Oui, formation réalisée dans mon entreprise",
        labelNo: "Non, formation réalisée par un organisme tiers",
        required: true
    })
    formationInterne = false
    
    @object({
        type: DossierCFA,
        required: (context: ValidationContext<DossierCFA | null, BaseDossierModule>) => context.parent?.formationInterne !== true
    })
    @observer("formationInterne", context => context.setValue(context.observedValue === true ? null : context.currentValue, { untouch: true }))
    cfa: DossierCFA | null = null

    @radioBooleanField({
        label: "Paiement direct par l'OPCO à l'organisme de formation (subrogation)",
        required: true,
        disabled: context => context.getParent<BaseDossierModule>()?.formationInterne === true,
        dto: "SubrogationPaiement__c",
    })
    @observer("formationInterne", context => context.setValue(context.observedValue === true ? false : null, { untouch: true }))
    subrogation: boolean | null = null

    @textField({ label: "Intitulé du stage", required: true, max: 255, dto: "IntituleProgramme__c" })
    intitulePrecis: string | null = null

    @heuresField({ label: "Durée", required: true, dto: "NombreHeureFormation__c" })
    dureeTotale: number | null = null

    @dateField({ label: "Date de début de la formation", required: true, dto: "DateDebutFormation__c" })
    dateDebutFormation: Date | null = null

    @dateField({ label: "Date de fin de la formation", required: true, dto: "DateFinFormation__c" })
    dateFinFormation: Date | null = null
    
    @object({ type: TypeCout, required:  true, dto: "TypeCout", overrides: { montant: { label: "Coût pédagogique total" } } })
    coutPedagogique: TypeCout | null = new TypeCout(TYPE_COUT_PEDAGOGIQUE)

    @radioRefField({
        label: "Type de formation",
        tooltip: TOOLTIPS.formation.typeFormation,
        options: refs => refs.REF_TYPE_FORMATION.filter(ref => ref.IsActive__c && ref.Priorite__c && ref.Categorie__c === 'EXT'),
        visible: context => context.getParent<BaseDossierModule>()?.formationInterne === false,
        required: true,
        dto: "TypeFormation__c",
    })
    @observer("formationInterne", context => context.setValue(context.observedValue === true ? TYPE_FORMATION_INTERNE : null, { untouch: true }))
    typeFormation: string | null = null

    constructor(subrogation?: boolean) {
        this.subrogation = subrogation ?? null
    }
}

export class DossierAFModule extends BaseDossierModule {

    @numeroDossierApprentissageField({
        visible: (context) => context.getParent<BaseDossierModule>()?.formationInterne !== null,
        dto: "NumeroDossierApprentissage__c",
    })
    numeroDossierApprentissage: string | null = null

    @selectRefField({
        label: "Formation certifiante",
        tooltip: TOOLTIPS.formation.formationCertifianteAgora,
        options: refs => refs.REF_FORMATION_CERTIFIANTE.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('1')),
        sorted: true,
        required: true,
        dto: "FormationCertifianteAgora__c",
    })
    formationCertifianteAgora: string | null = null

    @selectRefField({
        label: "Catégorie d'action",
        options: (refs, context) => {
            const certification = context.getParent<DossierAFModule>()?.formationCertifianteAgora
            return refs.REF_OBJECTIF_GENERAL_OF.filter(ref =>
                ref.IsActiveFO__c &&
                ref.Categorie__c?.split(',').includes('1') &&
                ref.Id === OBJECTIF_GENERAL_OF_CERTIFICATION ? certification === FORMATION_CERTIFIANTE_OUI : certification === FORMATION_CERTIFIANTE_NON
            )
        },
        disabled: context => context.getParent<DossierAFModule>()?.formationCertifianteAgora !== FORMATION_CERTIFIANTE_NON,
        sorted: true,
        required: true,
        dto: "ObjectifGeneralOfAgora__c",
    })
    @observer("formationCertifianteAgora", context => context.setValue(
        context.observedValue === FORMATION_CERTIFIANTE_OUI ? OBJECTIF_GENERAL_OF_CERTIFICATION : null,
        { untouch: true }
    ))
    categorieActionAgora: string | null = null

    @selectRefField({
        label: "Objectif",
        options: refs => refs.REF_OBJECTIF_GENERAL_FORMATION.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('1')),
        disabled: context => context.getParent<DossierAFModule>()?.formationCertifianteAgora !== FORMATION_CERTIFIANTE_NON,
        sorted: true,
        required: true,
        dto: "ObjectifGeneralFormationAgora__c",
    })
    @observer("formationCertifianteAgora", context => context.setValue(
        context.observedValue === FORMATION_CERTIFIANTE_OUI ? OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES : null,
        { untouch: true }
    ))
    objectifAgora: string | null = null
}

export class DossierAF extends BaseDossier {

    @array({ elements: { type: DossierAFModule } })
    modules: DossierAFModule[] = []
}
