import { roundCurrency } from 'api/util'
import { MyCalculator } from 'atlas-ds'
import { formatCurrency, formatNumber } from 'components/format/Format'
import { useEffect, useState } from 'react'

const TVA_DEFAUT = 20.0

interface CalculVVProps {
    montant: number | null
    typeTVA: string | null
    tauxTVALocal: number | null
    tauxTVA: number | null
    montantHT?: number
    montantTVA?: number
    montantTTC?: number
    calculTVA?: ({ montantHT, montantTVA, montantTTC }: { montantHT: number, montantTVA: number, montantTTC: number }) => void
}

interface TVAState {
    tauxTVA: number | null
    montantHT: number | null
    montantTVA: number | null
    montantTTC: number | null
}

function calculMontants(tauxTVALocal: number, typeTVA: string | null, tauxTVA: number | null, montant: number | null): TVAState {
    const tva = {
    } as TVAState

    if (montant === null)
        return tva

    // Recalcule en local si non fourni
    tva.tauxTVA = getTauxTVA(tauxTVALocal, tauxTVA, typeTVA)
    const montantBase = montant

    tva.montantHT = montantBase
    if (tva.tauxTVA === null) {
        // Non assujetti : TVA = x% mais TTC = HT
        // const montantTTCTheorique = montantBase + Math.round(montantBase * (tauxTVALocal ?? TVA_DEFAUT)) / 100.0
        tva.montantTTC = tva.montantHT
        tva.montantTVA = 0
    } else {
        // Assujetti : TVA = x%, TTC = HT + TVA
        tva.montantTVA = roundCurrency(montantBase * (tva.tauxTVA ?? tauxTVALocal ?? TVA_DEFAUT) / 100)
        tva.montantTTC = montantBase + tva.montantTVA
        // tva.montantTTC = montantBase + Math.round(montantBase * (tva.tauxTVA ?? tauxTVALocal ?? TVA_DEFAUT)) / 100.0
        // tva.montantTVA = tva.montantTTC - tva.montantHT
    }
    return tva
}

const getTauxTVA = (tauxTVALocal: number | null, tauxTVA: number | null, typeTVA: string | null) => {
    if (typeTVA === 'totale') {
        return tauxTVALocal
    } else if (typeTVA === 'aucune') {
        return null
    }
    return tauxTVA
}

export default function CalculVV(props: CalculVVProps) {

    const [tva, setTVA] = useState<TVAState>({ 
        tauxTVA: props.tauxTVA ?? null,
        montantHT: props.montantHT ?? null, 
        montantTVA: props.montantTVA ?? null, 
        montantTTC: props.montantTTC ?? null 
    })
    const { 
        montant, 
        tauxTVALocal, typeTVA, tauxTVA, 
        montantHT, montantTVA, montantTTC, 
        calculTVA,
    } = props

    useEffect(() => {
        if (montant === null) {
            return
        }

        let tvaCalculee = calculMontants(tauxTVALocal ?? TVA_DEFAUT, typeTVA, tauxTVA, montant)
        setTVA(tvaCalculee)

        if (calculTVA && tvaCalculee.montantHT != null && tvaCalculee.montantTVA != null && tvaCalculee.montantTTC != null) {
            calculTVA({ 
                montantHT: tvaCalculee.montantHT, 
                montantTVA: tvaCalculee.montantTVA, 
                montantTTC: tvaCalculee.montantTTC,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [montant, tauxTVALocal, 
        typeTVA, tauxTVA, montantHT, montantTVA, montantTTC, calculTVA])

    return <MyCalculator
        label="Calcul de versement volontaire"
        columns={[
            {
                "values": [
                    {
                        "label": "Montant HT",
                        "value": formatCurrency(tva.montantHT, '', '-', false),
                    },
                    {
                        "label": `TVA${ props.typeTVA === 'aucune' ? '' : ' ' + formatNumber(tva.tauxTVA ?? props.tauxTVALocal ?? TVA_DEFAUT, '%', '', 2) }`,
                        "value": formatCurrency(tva.montantTVA, '', '-', false),
                    },
                    {
                        "label": "Montant TTC",
                        "value": formatCurrency(tva.montantTTC, '', '-', false),
                        "outputFor": "montant typeTVA_totale typeTVA_partielle typeTVA_aucune tauxTVA"
                    }
                ]
            }
        ]}
    />
}
