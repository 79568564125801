import { AccesRapide } from 'api/referencesAPI'
import { EXTERNAL_LINK_REGEX } from 'app/constants'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasFlex, AtlasHeading, MyContainer, MyQuickAccess } from 'atlas-ds'
import AccessesModal from 'components/AccessesModal'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export interface AccesRapidesProps {
    storageId: string
    filtre: (accesRapides: AccesRapide[] | undefined) => AccesRapide[] | undefined
}

export default function AccesRapides(props: AccesRapidesProps) {


    const REACT_APP_NB_ACCES_RAPIDE_MAX = 4
    const SELFE_ID = "depot-facture" 
    const PRESTATAIRE_LINK_SIREN = ["deposer-facture" , "generer-proforma"]

    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { fulfilled: config } = useSelector((state: RootState) => state.configState)

    const acces: AccesRapide[] | undefined = getFromLocalStorage()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [accesRapidesModal, setAccesRapidesModal] = useState<AccesRapide[]>([])
    const [accesRapidesAffiches, setAccesRapidesAffiches] = useState<AccesRapide[] | undefined>(acces)
    const [accesRapidesEligibles, setAccesRapidesEligibles] = useState<AccesRapide[] | undefined>([])

    function setToLocalStorage(accesRapides: AccesRapide[]) {
        localStorage.setItem(props.storageId, JSON.stringify(accesRapides))
    }

    function getFromLocalStorage() {
        const json = localStorage.getItem(props.storageId)
        return (json) ? JSON.parse(json) : undefined
    }

    useEffect(() => {
        //On synchronise le localStorage avec les liens disponibles
        if (accesRapidesAffiches && config?.AccesRapides) {
            const accesRapidesVerifies = accesRapidesAffiches.filter(acces => config.AccesRapides.filter(accesDispo => accesDispo.Id === acces.Id).length)
            if (accesRapidesVerifies.length !== accesRapidesAffiches.length) {
                setToLocalStorage(accesRapidesVerifies)
                setAccesRapidesAffiches(accesRapidesAffiches)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accesRapidesAffiches, config])

    useEffect(() => {
        if (entreprise && config?.AccesRapides) {

            const accesEligible = props.filtre(config?.AccesRapides)
            const accesParDefaut = accesEligible?.filter(accesRapide => accesRapide.Defaut === 'true').slice(0,REACT_APP_NB_ACCES_RAPIDE_MAX)
            const accesLocalStorage = props.filtre(getFromLocalStorage())

            setAccesRapidesAffiches(accesLocalStorage ?? accesParDefaut ?? [])
            setAccesRapidesModal(accesLocalStorage ?? accesParDefaut ?? [])
            setAccesRapidesEligibles(accesEligible)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entreprise, config])

    function save(accesRapides: AccesRapide[]) {
        setAccesRapidesAffiches(accesRapides.slice(0))
        setToLocalStorage(accesRapides)
    }

    function annuler() {
        setShowModal(false)
        // On attends la fermeture de la modal avant de synchroniser la liste
        setTimeout(() => { setAccesRapidesModal(accesRapidesAffiches?.slice() ?? []) }, 500)
    }

    function generationLink(accesRapide:AccesRapide){
        let lien = ""
        if(accesRapide.Id ==="depot-facture")
        {
            lien = (SELFE_ID.includes(accesRapide.Id)) ? `${config?.Selfe?.lien}?siren=${entreprise?.SIREN__c ?? ''}` : accesRapide.Lien ?? ''
            return lien
        }
        
        lien = (PRESTATAIRE_LINK_SIREN.includes(accesRapide.Id)) ? `${accesRapide.Lien}?siren=${entreprise?.SIREN__c ?? ''}` : accesRapide.Lien ?? ''
         return lien
    }

    return (<>
        <div id="acces-rapides">
            <AtlasHeading
                tag="h2"
                size="m"
                button={<AtlasButton level={4} icon="pen" onClick={() => setShowModal(true)}>
                    Gérer
                </AtlasButton>}
            >Accès rapides</AtlasHeading>
            <MyContainer colored>
                <AtlasFlex column gap="m">
                    { accesRapidesAffiches?.length ? accesRapidesAffiches?.map(accesRapide => {
                        const lien = generationLink(accesRapide)
                        return <MyQuickAccess
                            key={accesRapide.Nom}
                            href={lien}
                            external={EXTERNAL_LINK_REGEX.test(lien)}
                            linkConstructor={(props: any, content: React.ReactNode) => (EXTERNAL_LINK_REGEX.test(lien))
                                ? <a key={accesRapide.Id} {...props}>{content}</a>
                                : <Link to={props.href} key={accesRapide.Id} {...props}>{content}</Link>
                            }
                        >{accesRapide.Nom}</MyQuickAccess>
                    }) : <>
                        <p>Aucun accès rapide n'est configuré pour le moment.</p>
                        <AtlasButton level={2}  onClick={() => setShowModal(true)}>
                            Configurer les accès rapides
                        </AtlasButton>
                    </> }
                </AtlasFlex>
            </MyContainer>
        </div>
        <AccessesModal
            show={showModal}
            nbMaxAcces={REACT_APP_NB_ACCES_RAPIDE_MAX}
            accesRapidesModal={accesRapidesModal}
            setAccesRapidesModal={setAccesRapidesModal}
            accesRapidesEligibles={accesRapidesEligibles}
            annuler={annuler}
            save={save}
            close={() => setShowModal(false)}
        />
    </>)
}
