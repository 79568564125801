import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { DISPOSITIF_VAEI, TYPE_FORMATION_INTER, TYPE_FORMATION_INTERNE } from 'api/references'
import { mapDTOToEmployeur, mapEmployeurToDTO, SECTION_EMPLOYEUR_CONTACT, SECTION_EMPLOYEUR_EXTRA } from 'pages/dossier/metadata/EmployeurMetadata'
import { ObjectFieldMetadata, OptionalObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { FormMetadata, ObjectMetadata, mapDTOToPointsReserve, registerMetadata, validatePointsReserve } from 'pages/dossier/metadata/FormMetadata'
import { MappingContext, mapCoreDTOToBaseDossier, mapDTOToModel, mapModelToDTO } from 'pages/dossier/metadata/DossierMapping'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import { nil, normalizePiecesDTO } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import { DossierProModuleDTO, DossierProfessionnalisationDTO, loadDossierProfessionnalisation, saveDossierProfessionnalisation, sendDossierProfessionnalisation } from 'api/dossierAPI'
import { mapDTOToCFA } from 'pages/dossier/metadata/ModulesMetadata'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { AppType } from 'slices/contextSlice'
import { DossierVAEIFormationElements } from './DossierVAEIFormationElements'
import { DossierVAEI, DossierVAEISalarie, createDossierVAEI, createDossierVAEIModule, createDossierVAEISalarie, createDossierVAEITuteur } from './VAEIModel'
import { VAEIEmployeurMetadata } from './VAEIEmployeurMetadata'
import { VAEIModuleAFESTMetadata, VAEIModulePrincipalMetadata, SECTION_MODULE_AFEST, SECTION_MODULE_PRINCIPAL, dossierVAEIFormationInitializer } from './VAEIFormationMetadata'
import { max, min } from 'date-fns'
import { optionalDate2APIDate, parseDate } from 'components/format/Format'
import { VAEISalarieMetadata, dossierVAEISalarieInitializer } from './VAEISalarieMetadata'
import { SECTION_SALARIE_AGORA, mapDTOToStagiaire, mapStagiaireToDTO } from 'pages/dossier/metadata/SalariesMetadata'
import { DossierSalarieElements } from 'pages/dossier/elements/DossierSalariesElements'
import { VAEITuteurMetadata, dossierVAEITuteurInitializer, tuteurTravailTemporaireMetadata } from './VAEITuteurMetadata'
import { VAEIContratMetadata, SECTION_VAEI_CONTRAT, dossierVAEIContratInitializer } from './VAEIContratMetadata'
import { DossierVAEIContratElements } from './DossierVAEIContratElements'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import RecapSalarie from 'pages/dossier/recap/RecapSalarie'
import RecapTuteur from 'pages/dossier/recap/RecapTuteur'
import RecapContrat from 'pages/dossier/recap/RecapContrat'
import RecapVAEIFormation from './RecapVAEIFormation'
import { dossierVAEIPointsReserve } from './Recos'
import CerfaVAEI from './cerfav4/CerfaVAEI'
import { DossierVAEITuteurElements } from './DossierVAEITuteurElements'


const DOCTYPE_CERFA_VAEI = 'CERFA'
const DOCNAME_CERFA_VAEI = `${ DOCTYPE_CERFA_VAEI }-VAEI.pdf`

export function coutPedagogiqueTotal(dossier: DossierVAEI | null): number | null {
    if (dossier?.modulePrincipal == null)
        return null
    return (dossier?.modulePrincipal?.coutPedagogique.montant ?? 0) + (dossier?.moduleAfest?.coutPedagogique.montant ?? 0)
}

export function dureeHeureEvaluation(dossier: DossierVAEI | null): number | null {
    if (dossier?.modulePrincipal == null)
        return null
    return (dossier?.modulePrincipal?.dureeTotale ?? 0) + (dossier?.moduleAfest?.dureeTotale ?? 0)
}

export function dateDebutCycleFormation(dossier: DossierVAEI | null): Date | null {
    const dateDebutPrincipal = parseDate(dossier?.modulePrincipal?.dateDebutFormation)
    const dateDebutAfest = parseDate(dossier?.moduleAfest?.dateDebutFormation)

    if (dateDebutPrincipal != null && dateDebutAfest != null)
        return min([dateDebutPrincipal, dateDebutAfest])
    return dateDebutPrincipal ?? dateDebutAfest ?? null
}

export function dateFinCycleFormation(dossier: DossierVAEI | null): any {
    const dateFinPrincipal = parseDate(dossier?.modulePrincipal?.dateFinFormation)
    const dateFinAfest = parseDate(dossier?.moduleAfest?.dateFinFormation)

    if (dateFinPrincipal != null && dateFinAfest != null)
        return max([dateFinPrincipal, dateFinAfest])
    return dateFinPrincipal ?? dateFinAfest ?? null
}

export function mapDTOToDossierVAEI<D extends DossierVAEI>(metadata: FormMetadata<D>, dossierDTO: DossierProfessionnalisationDTO): D {

    const dossier = metadata.api.create()
    mapCoreDTOToBaseDossier(dossier, dossierDTO)

    const context: MappingContext<DossierVAEI, DossierProfessionnalisationDTO> = {
        metadata: metadata.fields,
        editMode: metadata.api.isEdit ?? false,
        dossier,
        dossierDTO,
    }

    // Employeur
    mapDTOToEmployeur(context, (metadata.fields.employeur as any).fields, dossier.employeur, dossierDTO)

    // Modules
    dossierDTO.Modules
        ?.filter(moduleDTO => moduleDTO.Principal__c === true || (moduleDTO.FormationAFEST__c === true && dossierDTO.CPROExperimental__c !== true))
        ?.forEach(moduleDTO => {
        const principal = moduleDTO.Principal__c === true
        const moduleFields = principal ? VAEIModulePrincipalMetadata : VAEIModuleAFESTMetadata

        const module = createDossierVAEIModule()
        if (principal)
            dossier.modulePrincipal = module
        else
            dossier.moduleAfest = module
        
        module.IdHeroku = moduleDTO.IdHeroku
        module.formationInterne = moduleDTO.TypeFormation__c === TYPE_FORMATION_INTERNE
        if (!module.formationInterne)
            module.cfa = mapDTOToCFA(moduleDTO)
        mapDTOToModel(context, moduleFields, module, moduleDTO)
        mapDTOToModel(context, moduleFields, module, dossierDTO, 'dossier')
        // mapDTOToModel(context, (metadata.fields.contrat as any).fields, dossier.contrat, moduleDTO, 'module')
    })

    // Salarie
    const salarieDTO = dossierDTO.Stagiaires?.[0]
    if (salarieDTO) {
        dossier.salarie = metadata.api.createSalarie() as DossierVAEISalarie
        mapDTOToStagiaire(context, (metadata.fields.salarie as any).fields, dossier.salarie, salarieDTO)
    }

    // Tuteur
    dossier.tuteurTravailTemporaire = dossierDTO.Tuteur_TT__c as boolean
    if (dossierDTO.NomTuteur__c != null) {
        dossier.tuteur = createDossierVAEITuteur()
        dossier.tuteur.IdContact = dossierDTO.Tuteur__c ?? null
        mapDTOToModel(context, VAEITuteurMetadata, dossier.tuteur, dossierDTO)
    }


    // Contrat
    mapDTOToModel(context, (metadata.fields.contrat as any).fields, dossier.contrat, dossierDTO)
    if (dossier.contrat != null && salarieDTO)
        dossier.contrat.natureContrat = salarieDTO.NatureContrat__c as string

    // Pièces
    dossier.pieces = normalizePiecesDTO(dossierDTO.Pieces)
    // dossier.attestationPieces = metadata.api.isEdit ? false : dossierDTO.EmployeurAttestePiecesJustificatives__c ?? false

    // Signature
    mapDTOToModel(context, (metadata.fields.signature as any).fields, dossier.signature, dossierDTO)

    // // Points de réserve
    dossier.pointsReserve = mapDTOToPointsReserve(dossierDTO.PointsReserve__c ?? null)
    validatePointsReserve(dossier, dossierVAEIPointsReserve)

    return dossier
}

export function mapDossierVAEIToDTO<D extends DossierVAEI>(metadata: FormMetadata<D>, dossier: D): DossierProfessionnalisationDTO {
    const dto = {
        IdHeroku: dossier.IdHeroku ?? null,
        NumeroDossier__c: dossier.NumeroDossier__c ?? null,
        // NumeroVersionCerfaCPRO__c: '1',
        TypeDossier__c: null,   // Non utilisé
        DispositifFO__c: DISPOSITIF_VAEI,

        // Employeur
        ...mapEmployeurToDTO((metadata.fields.employeur as any).fields, dossier, dossier.employeur),

        // Formation
        VAEIExperimental__c: false,
        EmployeurAttesteVAEIExperimental__c: null,
        EncadrementTiersFacilitateur__c: null,

        Intitule__c: dossier.modulePrincipal?.intitulePrecis ?? null,
        // SpecialiteFormation__c: dossier.modulePrincipal?.specialiteFormation ?? null,
        CoutPedagogique__c: coutPedagogiqueTotal(dossier),
        AttestationEmployeurFormationInterne__c: dossier.modulePrincipal?.attestationFormationInterne ?? null,
        EmployeurAttesteAFEST__c: dossier.moduleAfest?.attestationFormationInterne ?? null,
        
        TypeQualification__c: dossier.modulePrincipal?.typeQualification ?? null,
        DureeHeureEvaluation__c: dureeHeureEvaluation(dossier),
        DureeHeureEnseignement__c: dossier.modulePrincipal?.dureeEnseignement ?? null,
        DateDebutCycleFormation__c: optionalDate2APIDate(dateDebutCycleFormation(dossier)),
        DateExamen__c: optionalDate2APIDate(dateFinCycleFormation(dossier)), // TODO: à vérifier
        NbOf__c: dossier.modulePrincipal == null ? null : (dossier.modulePrincipal?.cfa?.CodeEtablissementOF__c != null ? 1 : 0),
        
        // Salaries
        Stagiaires: dossier.salarie ?
            [ mapStagiaireToDTO((metadata.fields.salarie as any).fields, dossier, dossier.salarie) ]
            : null,

        // Tuteur
        Tuteur_TT__c: dossier.tuteurTravailTemporaire ?? null,
        Tuteur__c: dossier.tuteur?.IdContact ?? null,
        ...mapModelToDTO((metadata.fields.tuteur as any).fields, dossier, dossier.tuteur),

        // Contrat
        ...mapModelToDTO((metadata.fields.contrat as any).fields, dossier, dossier.contrat),

        // Signature
        ...mapModelToDTO((metadata.fields.signature as any).fields, dossier, dossier.signature),
    } as DossierProfessionnalisationDTO

    if (dossier.modulePrincipal != null) {
        if (dto.Modules == null)
            dto.Modules = []

        const moduleDTO = {} as DossierProModuleDTO
        moduleDTO.Principal__c = true
        moduleDTO.IdHeroku = dossier.modulePrincipal.IdHeroku ?? null
        moduleDTO.TypeFormation__c = dossier.modulePrincipal.formationInterne ? TYPE_FORMATION_INTERNE : TYPE_FORMATION_INTER
        mapModelToDTO((metadata.fields.modulePrincipal as any).fields, dossier, dossier.modulePrincipal, moduleDTO)
        mapModelToDTO((metadata.fields.modulePrincipal as any).fields, dossier, dossier.modulePrincipal, dto, 'dossier')
        
        dto.Modules.push(moduleDTO)

        if (dossier.moduleAfest != null) {
            const moduleDTO = {} as DossierProModuleDTO
            moduleDTO.Principal__c = false
            moduleDTO.IdHeroku = dossier.moduleAfest.IdHeroku ?? null
            moduleDTO.TypeFormation__c = TYPE_FORMATION_INTERNE
            moduleDTO.FormationAFEST__c = true
            mapModelToDTO((metadata.fields.moduleAfest as any).fields, dossier, dossier.moduleAfest, moduleDTO)
            mapModelToDTO((metadata.fields.moduleAfest as any).fields, dossier, dossier.moduleAfest, dto, 'dossier')

            dto.Modules.push(moduleDTO)
        }
    }

    // Formation niveau dossier
    // mapModelToDTO((metadata.fields.formation as any).fields, dossier, dossier.formation, dto, 'dossier')

    // Contrat niveau dossier
    // mapModelToDTO((metadata.fields.contrat as any).fields, dossier, dossier.contrat, dto)

    if (dto.Stagiaires?.length === 1)
        dto.Stagiaires[0].NatureContrat__c = dossier.contrat.natureContrat

    return dto
}

const api: DossierAPI<DossierVAEI> = {
    create: createDossierVAEI,
    createSalarie: createDossierVAEISalarie,
    createTuteur: createDossierVAEITuteur,
    createModule: createDossierVAEIModule,
    load: async (dossierId: string, storedState?: DossierVAEI | null) => {
        const dossierDTO = await loadDossierProfessionnalisation(dossierId)
        const loadedDossier = mapDTOToDossierVAEI(MetadataVAEI, dossierDTO)
        return loadedDossier
    },
    loadWithPieces: async (dossierId: string, storedState?: DossierVAEI | null) => {
        const dossierDTO = await loadDossierProfessionnalisation(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        const loadedDossier = mapDTOToDossierVAEI(MetadataVAEI, dossierDTO)
        return loadedDossier
    },
    save: async (dossier: DossierVAEI) => {
        const dossierDTO = mapDossierVAEIToDTO(MetadataVAEI, dossier)
        const savedDossierDTO = await saveDossierProfessionnalisation(dossierDTO)
        const savedDossier = mapDTOToDossierVAEI(MetadataVAEI, savedDossierDTO)
        return savedDossier
    },
    send: async (dossier: DossierVAEI) => {
        const dossierDTO = mapDossierVAEIToDTO(MetadataVAEI, dossier)
        const sentDossierDTO = await sendDossierProfessionnalisation(dossierDTO)
        return mapDTOToDossierVAEI(MetadataVAEI, sentDossierDTO)
    },
    postSend: async (dossier: DossierVAEI) => {
        await postSendDossier(MetadataVAEI, dossier)
    },
}


type DossierVAEILight = Omit<DossierVAEI, 'pieces' | 'pointsReserve'>
const VAEIMetadataLight: ObjectMetadata<DossierVAEILight> = {
    employeur: ObjectFieldMetadata(VAEIEmployeurMetadata),
    modulePrincipal: ObjectFieldMetadata(VAEIModulePrincipalMetadata),
    moduleAfest: OptionalObjectFieldMetadata(VAEIModuleAFESTMetadata),
    salarie: ObjectFieldMetadata(VAEISalarieMetadata),
    tuteurTravailTemporaire: tuteurTravailTemporaireMetadata,
    tuteur: ObjectFieldMetadata(VAEITuteurMetadata),
    contrat: ObjectFieldMetadata(VAEIContratMetadata),
    signature: ObjectFieldMetadata(signatureMetadata),
}
const VAEIMetadata = VAEIMetadataLight as ObjectMetadata<DossierVAEI>


export const MetadataVAEI = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_VAEI,
    title: "Dépôt dossier VAEI",
    pathname: '/nouveau-dossier-vaei',
    steps: [
        {
            title: 'Employeur',
            rubrique: 'EMPLOYEUR',
            sections: [ SECTION_EMPLOYEUR_EXTRA, SECTION_EMPLOYEUR_CONTACT ],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            formContent: props => <DossierEmployeurElements { ...props } label="Sélectionner l'établissement d'exécution du contrat" />,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Formation',
            rubrique: 'FORMATION',
            initializer: dossierVAEIFormationInitializer,
            sections: [ SECTION_MODULE_PRINCIPAL, SECTION_MODULE_AFEST ],
            recap: RecapVAEIFormation,
            yopPath: [ 'modulePrincipal', 'moduleAfest' ],
            formContent: DossierVAEIFormationElements,
        }, {
            title: 'Salarié',
            rubrique: 'APPRENTI',
            sections: [ SECTION_SALARIE_AGORA ],
            recap: RecapSalarie,
            initializer: dossierVAEISalarieInitializer,
            yopPath: 'salarie',
            formContent: props => <DossierSalarieElements { ...props } selectLabel="Choix du salarié" />,
        }, {
            title: "Tuteur",
            rubrique: 'MAITRE',
            sections: [],
            recap: RecapTuteur,
            initializer: dossierVAEITuteurInitializer,
            yopPath: ['tuteurTravailTemporaire', 'tuteur'],
            formContent: DossierVAEITuteurElements,
        }, {
            title: 'Contrat',
            rubrique: 'CONTRAT',
            initializer: dossierVAEIContratInitializer,
            sections: [ SECTION_VAEI_CONTRAT ],
            recap: RecapContrat,
            yopPath: ['contrat'],
            formContent: DossierVAEIContratElements,
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: DossierPiecesElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            rubrique: 'RECAP',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
                excludePointsReserve: true,
            },
        },
    ],
    api,
    fields: VAEIMetadata,
    exportProps: [{
        label: 'Imprimer le Cerfa',
        exportDossier: async (metadata, dossier) => <CerfaVAEI metadata={ metadata } dossier={ dossier } />,
        documentType: DOCTYPE_CERFA_VAEI,
        documentName: DOCNAME_CERFA_VAEI,
    }],
})