import styles from 'pages/dossier/cerfa/global.styles'
import { Style } from '@react-pdf/types'
import { Text } from '@react-pdf/renderer'
import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { formatRef, formatRefCode, formatRefNumber, formatRefReduit } from 'components/format/Format'
import { useSelector } from 'react-redux'
import store from 'app/store'
import { references } from 'api/references'

interface CerfaRefFieldProps {
    options: (refs: References, parent?: any, root?: any) => Reference[] | undefined
    value: string
    parent?: any
    root?: any
    reduit?: boolean
    codeOnly?: boolean
    numberOnly?: boolean
    placeholder?: string
    style?: Style | Style[]
}

export default function CerfaRefField(props: CerfaRefFieldProps) {

    const refs = references()

    const options = refs ? (props.options(refs, props.parent, props.root) ?? []) : []
    const value =
        props.numberOnly ?
            formatRefNumber(options, props.value)
        : props.codeOnly ?
            formatRefCode(options, props.value) 
        : (props.value ? (props.reduit ?
            formatRefReduit(options, props.value) : formatRef(options, props.value)
        ) : null) ?? props.placeholder ?? ''

    return <Text style={ props.style ?? styles.user }>{ value ?? ' ' }</Text>
}
