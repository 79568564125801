import { createAsyncThunk, AsyncThunk, Draft, createSlice, Slice, PayloadAction, ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import log from 'loglevel'


export interface ApiState<T> {
    pending: boolean
    fulfilled: T | null
    rejected: string | null
    cacheKey: string | undefined
}

interface ApiSlice<T, P> {
    actions: {
        clear: ActionCreatorWithoutPayload<string>,
        caller: AsyncThunk<T, P | undefined, {}>
    },
    slice: Slice<ApiState<T>>
}

interface ApiSliceOptions<T, P extends any> {
    name: string
    useCachedState?: string
    getCacheKey?: (state: any) => string
    cacheable?: (data: any) => boolean
    call: (args?: P | undefined) => Promise<T>
    postcall?: () => void
}

export function createApiSlice<T, P extends any>(options: ApiSliceOptions<T, P>): ApiSlice<T, P> {
    const caller = createAsyncThunk(options.name,
        async (args: P | undefined, thunkAPI: any): Promise<T> => {
            if (options.useCachedState && options.getCacheKey) {
                const key = options.getCacheKey(thunkAPI.getState())
                const state = (thunkAPI.getState() as any)[options.useCachedState]
                const data = state?.fulfilled
                if (key === state?.cacheKey && (data ?? false)) {
                    return data
                }
            }
            
            if (options.cacheable) {
                const cacheKey = 'myatlas.' + options.name + (args ? '/' + args : '')
                const cached = sessionStorage.getItem(cacheKey)
                if (cached) {
                    try {
                        const data = JSON.parse(cached)
                        if (options.cacheable(data)) {
                            return data
                        }
                    } catch (e) {
                        log.warn("Could not parse cached data ", options.name, ":", e)
                    }
                    // Obsolete : supprime du storage
                    sessionStorage.removeItem(cacheKey)
                }
            }

            const result = await options.call(args)

            if (options.useCachedState && options.getCacheKey)
                thunkAPI.dispatch(slice.actions.setCacheKey(options.getCacheKey(thunkAPI.getState())))
            
            if (options.postcall)
                options.postcall()

            return result
        }
    )

    const slice = createSlice({
        name: options.name,
        initialState: { pending: false, fulfilled: null, rejected: null } as ApiState<T>,
        reducers: {
            clear(state) {
                state.fulfilled = null
                state.pending = false
                state.rejected = null
            },
            setCacheKey(state, action: PayloadAction<string>) {
                state.cacheKey = action.payload
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(caller.pending, (state: Draft<ApiState<T>>, action: any) => {
                    state.pending = true
                })
                .addCase(caller.fulfilled, (state: Draft<ApiState<T>>, action: any) => {
                    state.pending = false
                    state.fulfilled = action.payload
                    state.rejected = null
                    
                    if (options.cacheable) {
                        const cacheKey = 'myatlas.' + options.name + (action.meta.arg ? '/' + action.meta.arg : '')
                        sessionStorage.setItem(cacheKey, JSON.stringify(action.payload))
                    }
                })
                .addCase(caller.rejected, (state: Draft<ApiState<T>>, action: any) => {
                    state.pending = false
                    state.fulfilled = null
                    state.rejected = action.error.message
                    if (options.useCachedState)
                        state.cacheKey = undefined
                })
        }
    })
    
    return {
        actions: {
            clear: slice.actions.clear,
            caller,
        },
        slice,
    }
}
