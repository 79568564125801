import { SetValueOptions, UseFormReturn, useFormContext } from '@dsid-opcoatlas/reform'
import { TYPE_FORMATION_INTERNE } from 'api/references'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'

export interface FormationInterneFieldProps {
    name: string
    parentPath: string
    label: string
    labelYes?: string
    labelNo?: string
    tooltip?: string | JSX.Element,
    onChange?: ((value: boolean | null, form: UseFormReturn<object>, parentPath?: string | undefined) => void)
    disabled?: boolean | ((ctx: UseFormReturn<any>, parentPath: string) => boolean)
}

export default function FormationInterneField(props: FormationInterneFieldProps) {

    const context = useFormContext<any>()
    const disabled = typeof props.disabled === 'function' ? props.disabled(context, props.parentPath) : props.disabled

    const onUpdateFormationInterne = (value: boolean | null) => {
        if (value) {
            context.setValue(`${props.parentPath}.typeFormation`, TYPE_FORMATION_INTERNE)
            context.setValue(`${props.parentPath}.cfa`, null, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.subrogation`, false, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.attestationFormationInterne`, null, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.adresseRealisationIdentiqueAgora`, true, true)
        }
        else {
            context.untouch(`${props.parentPath}.cfa`)
            context.setValue(`${props.parentPath}.typeFormation`, null, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.attestationFormationInterne`, false, SetValueOptions.Untouch)
            context.setValue(`${props.parentPath}.adresseRealisationIdentiqueAgora`, null, SetValueOptions.Untouch | SetValueOptions.Validate)
        }
    }

    return <RadioBooleanField
        parentPath={ props.parentPath }
        disabled={ disabled }
        name={ props.name }
        label={ props.label }
        labelYes={ props.labelYes }
        labelNo={ props.labelNo }
        tooltip={ props.tooltip }
        onChange={ props.onChange ?? onUpdateFormationInterne }
    />
}