import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
    page: {
        fontFamily: 'OpenSans',
        fontSize: 11,
        padding: 40,
        lineHeight: 1.6,
        textAlign: 'justify'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ministryImage: {
        width: 75,
    },
    logo: {
        width: 110,
    },
    title: {
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.3,
    },
    sectionTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    strong: {
        fontWeight: 'bold',
    },
    part: {
        marginTop: 10
    },
    pageNumber: {
        position: 'absolute',
        bottom: 20,
        right: 25
    },
});
