import { FacturePieceDTO, getFactureDocumentTypes, uploadDocumentFacture } from 'api/factureAPI'
import { RootState } from 'app/rootReducer'
import { AtlasInfo } from 'atlas-ds'
import DepotFichierModal from 'components/docs/DepotFichierModal'
import { DepotFichierModalProps } from 'pages/detail-common/DepotFichier'
import { useSelector } from 'react-redux'

export default function FactureDepotFichierModal(props: DepotFichierModalProps) {
    
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { fulfilled: config } = useSelector((state: RootState) => state.configState)

    return (
        <DepotFichierModal<FacturePieceDTO> 
            idParent={ props.idParent }
            nom={ props.nom }
            withType={ props.withType }
            getDocumentTypes={ getFactureDocumentTypes }
            withObligatoires={ props.withObligatoires }
            warningMessage={ <AtlasInfo type="warning" title="Factures, avoirs et justificatifs">
                Les factures, avoirs et leurs justificatifs sont à envoyer depuis <a href={`${config?.Selfe?.lien ?? '#'}?siren=${entreprise?.SIREN__c ?? ''}`} target="_blank" rel="noopener noreferrer">ce lien</a>
            </AtlasInfo>}
            isUpdating={ props.isUpdating }
            upload={ uploadDocumentFacture }
            uploaded={ props.uploaded }
            close={ props.close }
        />
    )
}