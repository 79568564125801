import { TestValidationContext } from '@dsid-opcoatlas/yop'
import TextField from 'components/reform/inputs/TextField'
import { OptionalString } from 'components/yop/constraints'
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import ExportTextField from 'pages/dossier/export/ExportTextField'
import RecapTextField from 'pages/dossier/recap/RecapTextField'

// https://groupes.renater.fr/sympa/nomenu/arc/pstage-utilisateurs/2014-02/msg00035.html
const SIRET_WHITE_LIST: string[] = []
const validateSiret = (context: TestValidationContext<string | null>) => {
    const value = context.value

    if (value == null || SIRET_WHITE_LIST.includes(value))
        return true
    
    if (!value || value.length < 14)
        return true

    let sum = 0;
    for (let i = 0; i < 14; i++) {
        let num = parseInt(value.charAt(i))
        if (i % 2 === 0) {
            num *= 2
            if (num >= 10)
                num -= 9
        }
        sum += num
    }
    if ((sum % 10) === 0)
        return true

    // Cas particulier des établissements de La Poste
    if (!value.startsWith('356000000'))
        return false
    sum = 3 + 5 + 6
    for (let i = 9; i < 14; i++) {
        sum += parseInt(value.charAt(i))
    }
    return (sum % 5) === 0
}

export const OptionalSiren = OptionalString.matches(/^(\d){9}$/, 'Le SIREN doit comporter exactement 9 chiffres')
export const RequiredSiren = OptionalSiren.required()

export const OptionalSiret = OptionalString.matches(/^(\d){14}$/, 'Le SIRET doit comporter exactement 14 chiffres')
    .test(validateSiret, 'Numéro de SIRET invalide')
export const RequiredSiret = OptionalSiret.required()


export function SiretFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    return createMetadata(
        {
            yop: OptionalSiret,
            input: TextField,
            recap: RecapTextField,
            export: ExportTextField,
        },
        settings
    )
}

export function RequiredSiretFieldMetadata(settings: SettingsFieldMetadata<string> = {}) {
    return createMetadata(
        {
            yop: RequiredSiret,
            input: TextField,
            recap: RecapTextField,
            export: ExportTextField,
        },
        settings
    )
}
