import { AtlasLinkConstructor, defaultLinkConstructor } from "atlas-ds";

export interface MyQuickAccessProps {
  /**
   * Le texte du lien
   */
  children: string;
  /**
   * La cible du lien
   */
  href?: string;
  /**
   * Le lien pointe-t-il vers l'extérieur du site ? Si oui, les attributs
   * `rel="noopener noreferrer" et `target="_blank"` seront ajoutés.
   */
  external?: boolean;
  /**
   * Si un lien classique ne suffit pas, un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Un lien d'accès rapide
 */
export function MyQuickAccess(props: MyQuickAccessProps) {
  const linkConstructor = props.linkConstructor || defaultLinkConstructor;

  return linkConstructor(
    {
      className: "my-quickAccess",
      href: props.href,
      target: props.external ? "_blank" : null,
      rel: props.external ? "noopener noreferrer" : null,
    },
    props.children
  );
}
