/* eslint-disable @typescript-eslint/no-unused-expressions */
/*import { htmlFormat } from 'date-fns';*/
import { getServicesStrategiePlus } from 'api/strategiePlusAPI'
import useApiState from 'api/useApiState'
import { RootState } from 'app/rootReducer'
import { AtlasColumns } from 'atlas-ds'
import PageHeader from "components/PageHeader"
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ServicesStrategiePlus } from './ServicesBloc'
import { StrategiePlusDefault } from './StrategiePlusDefault'
import { StrategiePlusMembre } from './StrategiePlusMembre'

export interface CompteStrategiePlus {
    NomEntreprise: string,
    Siret: string,
    CodeAdherent : string,
    Subject: string,
    Description :string,
    Priority: string,
    ContactId : string,
    AccountId : string,
    Origin : "adhesionStrategiePlus" |  "abondementStrategiePlus"
    LinkBordereauVV : string
}

export default function StrategiePlus() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { profil } = useSelector((state: RootState) => state.profilState)
    
    const [{ pending: strategiesPlusPending, value: strategiesPlus }, withGetServicesStrategiePlus] = useApiState(getServicesStrategiePlus)
    useEffect(() => {
        withGetServicesStrategiePlus()
    }, [withGetServicesStrategiePlus])
    
    const bordereauVV = useSelector((state: RootState) => state.configState.fulfilled?.BordereauVV)
    
    const compteVersement: CompteStrategiePlus = {
        NomEntreprise: entreprise?.Name as string,
        Siret: entreprise?.SIRET__c as string,
        CodeAdherent: entreprise?.CodeAdherent__c as string,
        Subject: entreprise?.VV__c ? 'Abondement versement VV Stratégie Plus' : 'Demande Adhésion Stratégie Plus',
        Description: entreprise?.VV__c ? 'Abondement versement VV Stratégie' : 'Nouvelle demande d\'adhésion Stratégie Plus',
        Priority: "Moyenne",
        Origin : "adhesionStrategiePlus",
        ContactId: profil?.Contact.Id ?? '', 
        AccountId: profil?.Contact.AccountId ?? '',
        LinkBordereauVV: bordereauVV?.lien ?? ''
    }

    return (
        <>
            <PageHeader>
                { entreprise?.VV__c ? 'Adhérent Stratégie +' : 'Adhérer à Stratégie +' }
            </PageHeader>

            <AtlasColumns>
                { entreprise?.VV__c && (entreprise?.VVSoldeAnneeN1__c || entreprise?.VVSoldeAnneeN2__c)
                    ? <StrategiePlusMembre detailEntreprise= { entreprise } 
                        compte={compteVersement} />
                    : <StrategiePlusDefault detailEntreprise= { entreprise } 
                        presentationDefault={ strategiesPlus?.presentation ?? [] } 
                        adhesionDefault={ strategiesPlus?.adhesion ?? [] } compte={compteVersement} 
                        loading={ strategiesPlusPending }/>   
                }
                <ServicesStrategiePlus service={ strategiesPlus?.services ?? [] } loading={ strategiesPlusPending } />      
            </AtlasColumns>
        </>
    )
}
