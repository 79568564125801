import { AtlasButton, AtlasHeading } from "atlas-ds"

export interface RecapSectionProps {
  title: string;
  onModifier: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

export function RecapSection(props: RecapSectionProps) {
  return <div>
    <AtlasHeading
        tag="h3"
        size="s"
        button={<AtlasButton id="modifier" icon="arrow-right" level={3} onClick={props.onModifier}>Modifier</AtlasButton>}
    >{ props.title }</AtlasHeading>
    { props.children }
  </div>
}
