import { useFormContext } from '@dsid-opcoatlas/reform'
import { Etablissement } from 'api/commonAPI'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import { CompanySummary } from 'components/reform/inputs/CompanySummary'
import { join } from 'components/reform/inputs/utils/BaseFieldProps'

interface AttacherEtablissementFieldProps {
    name: string
    label: string
    disabled?: boolean
    search: (recherche: string) => Promise<Etablissement[]>
    onChange?: (text: string, previousText: string) => void
    onSelect?: (etablissement: Etablissement) => void
}

export default function AttacherEtablissementField(props: AttacherEtablissementFieldProps) {

    const form = useFormContext<any>()

    const onSelect = (etablissement: Etablissement) => {
        form.setValue(props.name, etablissement, true)
        props.onSelect?.(etablissement)
    }

    return (
        <AutocompleteBaseField<Etablissement>
            label={ props.label }
            disabled={ props.disabled }
            placeholder="SIREN (9 chiffres)"
            maxLength={ 9 }
            hideNoResultsMessage={ true }
            acceptInput={ value => /^[0-9]*$/.test(value) }
            onChange={ (value, previousValue) => {
                if (form.getValue(props.name) !== null)
                    form.setValue(props.name, null, true)
                props.onChange?.(value, previousValue)
            }}
            
            search={ props.search }
            searchDebounceOptions={ { minChars: 9 } }

            optionFor={ etablissement => ({
                id: etablissement.Id,
                content: <CompanySummary
                    nom={ etablissement.Name }
                    siren={ etablissement.SIREN__c }
                    adresse={ join(etablissement.Adresse3__c ?? etablissement.BillingStreet, etablissement.BillingPostalCode, etablissement.BillingCity) }
                />
            }) }
            onSelect={ onSelect }
        />
    )
}
