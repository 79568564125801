import { TYPE_MONTANT_SMC, TYPE_MONTANT_SMIC } from "api/references"
import { parseDate } from "components/format/Format"
import { isValid, isAfter, isBefore, isEqual, addYears, addMonths, addDays, isSameDay, startOfMonth, differenceInYears, isSameMonth } from "date-fns"
import { DossierCAPP } from "./CAPPModel"
import { nil } from "slices/dossierCommon"


// Tranche 2:  0 - 18 ans
// Tranche 3: 18 - 21 ans 
// Tranche 4: 21 - 26 ans
// Tranche 5: +26 ans
export const GRILLE_LEGALE = [
    { 2: 27, 3: 43, 4: 53, 5: 100 },
    { 2: 39, 3: 51, 4: 61, 5: 100 },
    { 2: 55, 3: 67, 4: 78, 5: 100 },
    { 2: 55, 3: 67, 4: 78, 5: 100 },
] as any[]


const IDCC_SMC = ['438', '653', '787', '1468', '1486', '1672', '1679', '1726', '1801', '2120', '2247', '2335', '2357', '3210', '3213', '5005']

export const rebuildAnneesRemuneration = (values: DossierCAPP, editMode: boolean, rebuild: boolean = false) => {
    if (!values.contrat?.dateDebut || !values.contrat?.dateFin || !values.stagiaire?.dateNaissance) {
        return
    }

    const dateDebut = parseDate(values.contrat.dateDebut)!
    const dateFin = parseDate(values.contrat.dateFin)!
    if (!isValid(dateDebut) || !isValid(dateFin) || isAfter(dateFin, addYears(dateDebut, 4)))
        return

    const dateNaissance = parseDate(values.stagiaire.dateNaissance)
    if (!dateNaissance)
        return
    const tranches = [18, 21, 26].map(age => {
        const date = addYears(dateNaissance, age) as Date
        return {
            dateAnniversaire: date,
            debutMoisSuivant: addMonths(startOfMonth(date), 1) as Date
        }
    })
    
    const date21ans = tranches[1].dateAnniversaire
    const defaultType = (date: Date) => IDCC_SMC.includes(values.employeur.codeIdcc) && isBefore(date, date21ans) ? TYPE_MONTANT_SMIC : null
    const forceType = (date: Date) => IDCC_SMC.includes(values.employeur.codeIdcc) && (isAfter(date, date21ans) || isEqual(date, date21ans)) ? TYPE_MONTANT_SMC : null
    
    const annees: any[][] = []
    let dateDebutAnnee = dateDebut
    let dateFinAnnee = addYears(dateDebutAnnee, 1)
    const dansTranche = (tranche: any) => {
        return !isBefore(tranche.dateAnniversaire, dateDebutAnnee) && isBefore(tranche.dateAnniversaire, dateFinAnnee)
    }
    const numTrancheForDate = (date: Date) => {
        let numTranche = 0
        while (numTranche < tranches.length) {
            const dateAnniversaire = tranches[numTranche].dateAnniversaire
            if (isBefore(date, dateAnniversaire))
                break
            numTranche++
        }

        // let numTranche = 0
        // while (numTranche < tranches.length && (isAfter(date, startOfMonth(tranches[numTranche].dateAnniversaire)) || isSameDay(date, startOfMonth(tranches[numTranche].dateAnniversaire)))) {
        //     numTranche++
        // }
        numTranche += 2  // Tranches numérotées de 2 à 5
        return numTranche
    }

    // #2805
    if (values.contrat.remuneration.modeManuelRemuneration || editMode) {
        values.contrat.remuneration.anneesRemuneration.forEach((annee, index) => {
            if (annee.remunerationDateDebut) {
                annee.remunerationTranche1 = numTrancheForDate(parseDate(annee.remunerationDateDebut)!)
                annee.remunerationGrille1 = GRILLE_LEGALE[index][annee.remunerationTranche1]
            }
            if (annee.remunerationDateChangement) {
                annee.remunerationTranche2 = numTrancheForDate(parseDate(annee.remunerationDateChangement)!)
                annee.remunerationGrille2 = GRILLE_LEGALE[index][annee.remunerationTranche2]
            }
        })
        return
    }

    const buildAnnee = (index: number, annee: any[]) => {
        if (annee === undefined) {
            return {
                remunerationDateDebut: null as unknown as Date,
                remunerationDateChangement1: null as unknown as Date,
                remunerationDateChangement: null as unknown as Date,
                remunerationDateFin: null as unknown as Date,
                remunerationTranche1: null,
                remunerationTranche2: null,
                remunerationGrille1: 0,
                remunerationGrille2: 0,
                remunerationForceType1: null,
                remunerationForceType2: null,
                remunerationAge1: 0,
                remunerationAge2: 0,
            }
        }
        const dateChangement = annee[1]
        const numTranche = annee[3] as number
        const numTranche2 = annee[4] as number
        return {
            remunerationDateDebut: annee[0] as Date,
            remunerationDateChangement1: dateChangement ? addDays(dateChangement, -1) as Date : null,
            remunerationDateChangement: dateChangement ? dateChangement as Date : null,
            remunerationDateFin: annee[2] as Date,
            remunerationTranche1: numTranche,
            remunerationTranche2: numTranche2,
            remunerationGrille1: GRILLE_LEGALE[index][numTranche] as number,
            remunerationGrille2: dateChangement ? GRILLE_LEGALE[index][numTranche2] as number : 0,
            remunerationForceType1: forceType(annee[0]),
            remunerationForceType2: dateChangement ? forceType(dateChangement) : null,
            remunerationAge1: annee[5], //differenceInYears(subDays(addMonths(startOfMonth(annee[0]), 1), 1), dateNaissance),
            remunerationAge2: annee[6], //dateChangement ? differenceInYears(subDays(addMonths(startOfMonth(dateChangement), 1), 1), dateNaissance) : 0,
        }
    }

    do {
        if (isAfter(dateFinAnnee, dateFin))
            dateFinAnnee = dateFin
        
        let changementTranche = tranches.find(dansTranche)
        let numTranche = numTrancheForDate(dateDebutAnnee)

        // let usePreviousNumTranche = false
        if (annees.length === 0 && changementTranche === undefined && dateNaissance.getDate() > 1) {
            changementTranche = tranches.find(tranche => {
                return isSameMonth(tranche.dateAnniversaire, dateDebut)
            })
            // if (changementTranche)
            //     numTranche-- // usePreviousNumTranche = true
        }
        
        let dateChangement = changementTranche?.debutMoisSuivant ?? null
        if (dateChangement && !isBefore(dateChangement, dateFinAnnee)) {
            // Pas de changement de tranche si après fin de période
            dateChangement = null
        }
        if (dateChangement && isSameDay(dateChangement, dateDebutAnnee!)) {
            // Pas de changement de tranche si début de période
            dateChangement = null
        }
        const numTranche2 = dateChangement != null ? numTrancheForDate(dateChangement) : 0

        annees.push([
            dateDebutAnnee, 
            dateChangement, 
            isAfter(dateFinAnnee, dateFin) || isEqual(dateFinAnnee, dateFin) ? dateFinAnnee : addDays(dateFinAnnee, -1), 
            numTranche,
            numTranche2,
            differenceInYears(dateDebutAnnee, dateNaissance),
            dateChangement ? differenceInYears(dateChangement, dateNaissance) : 0,
        ])
        
        dateDebutAnnee = dateFinAnnee
        dateFinAnnee = addYears(dateDebutAnnee, 1)
    }
    while (isBefore(dateDebutAnnee, dateFin))

    const prevData = values.contrat.remuneration.anneesRemuneration.flatMap(ar => {
        return [{ 
            date: ar.remunerationDateDebut ? parseDate(ar.remunerationDateDebut): null,
            type: ar.remunerationType1,
            pourcentage: ar.remunerationPourcentage1,
        }, {
            date: ar.remunerationDateChangement ? parseDate(ar.remunerationDateChangement) : null,
            type: ar.remunerationType2,
            pourcentage: ar.remunerationPourcentage2,
        }]
    }).filter(ar => ar.date !== null)

    values.contrat.remuneration.anneesRemuneration.forEach((ar, index, array) => {
        if (annees[index]) {
            const newar = { ...ar, ...buildAnnee(index, annees[index]) }
            const forceType1 = forceType(annees[index][0])
            const forceType2 = forceType(annees[index][1])
            newar.remunerationForceType1 = forceType1 !== undefined ? forceType1 : ar.remunerationForceType1
            newar.remunerationForceType2 = forceType2 !== undefined ? forceType2 : ar.remunerationForceType2
            const prevRemuneration1 = rebuild ? null : prevData.find(ar => ar.date && isEqual(ar.date, annees[index][0]))
            const prevRemuneration2 = rebuild ? null : prevData.find(ar => ar.date && isEqual(ar.date, annees[index][1]))
            newar.remunerationType1 = forceType1 ?? (prevRemuneration1?.type) ?? defaultType(annees[index][0])
            newar.remunerationType2 = forceType2 ?? (prevRemuneration2?.type) ?? defaultType(annees[index][1])
            newar.remunerationPourcentage1 = (prevRemuneration1?.pourcentage) ?? null
            newar.remunerationPourcentage2 = (prevRemuneration2?.pourcentage) ?? null
            array[index] = newar
        }
        else {
            const newar = { ...ar }
            newar.remunerationDateDebut = null
            newar.remunerationDateChangement1 = null
            newar.remunerationDateChangement = null
            newar.remunerationDateFin = null
            newar.remunerationPourcentage1 = null
            newar.remunerationType1 = null ?? ar.remunerationType1
            newar.remunerationPourcentage2 = null
            newar.remunerationType2 = null ?? ar.remunerationType2
            newar.remunerationTranche1 = null
            newar.remunerationTranche2 = null
            newar.remunerationGrille1 = 0
            newar.remunerationGrille2 = 0
            newar.remunerationForceType1 = null ?? ar.remunerationForceType1
            newar.remunerationForceType2 = null ?? ar.remunerationForceType2
            newar.remunerationAge1 = 0
            newar.remunerationAge2 = 0
            array[index] = newar
        }
    })
    if (values.contrat.remuneration.anneesRemuneration.length < annees.length) {
        for (let i = values.contrat.remuneration.anneesRemuneration.length; i < annees.length; i++) {
            values.contrat.remuneration.anneesRemuneration.push({
                IdHeroku1: nil as number,
                IdHeroku2: nil as number,
                dossierC: nil as string,
                ...buildAnnee(i, annees[i]),
                remunerationPourcentage1: nil as number,
                remunerationType1: forceType(annees[i][0]) ?? defaultType(annees[i][0]),
                remunerationPourcentage2: nil as (number | null),
                remunerationType2: forceType(annees[i][1]) ?? defaultType(annees[i][1]),
                remunerationForceType1: forceType(annees[i][0]),
                remunerationForceType2: forceType(annees[i][1]),
            })
        }
    }
    if (values.contrat.remuneration.anneesRemuneration.length < 4) {
        for (let i = values.contrat.remuneration.anneesRemuneration.length; i < 4; i++) {
            values.contrat.remuneration.anneesRemuneration.push({
                IdHeroku1: nil as number,
                IdHeroku2: nil as number,
                dossierC: nil as string,
                remunerationDateDebut: null,
                remunerationDateChangement1: null,
                remunerationDateChangement: null,
                remunerationDateFin: null,
                remunerationPourcentage1: nil as number,
                remunerationType1: null,
                remunerationPourcentage2: nil as (number | null),
                remunerationType2: null,
                remunerationTranche1: null,
                remunerationGrille1: 0,
                remunerationTranche2: nil as (number | null),
                remunerationGrille2: 0,
                remunerationForceType1: null,
                remunerationForceType2: null,
                remunerationAge1: 0,
                remunerationAge2: 0,
            })
        }
    }
}
