import { AtlasValues, MyContainer } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { SECTION_CAPP_CONTRAT, SECTION_CAPP_CONTRAT_AVANTAGES, SECTION_CAPP_CONTRAT_SALAIRE } from 'pages/dossier-capp/CAPPContratMetadata'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'


export default function RecapCAPPContrat(props: RecapProps<DossierCAPP>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)
    
    const metadata = props.metadata
    const contratEtape = steps.indexOf(props.step) + 1

    const skeleton = props.loading ? [{ cfa: {} }] : []
    const contrat = props.dossier?.contrat ?? skeleton
    const dossier = props.dossier ?? { contrat } as DossierCAPP

    const onModifier = (etape?: number) => navigateTo(props.dossier!.NumeroDossier__c!, etape ?? contratEtape)

    return <RecapSection
        title={ props.step.title ?? 'Contrat' }
        onModifier={ () => onModifier() }
    >
        <MyContainer>
            <AtlasValues>
                <AtlasValues.Grid>
                    <FormElements section={ SECTION_CAPP_CONTRAT } dossier={ dossier } metadata={ metadata } path="contrat" type="recap"/>
                </AtlasValues.Grid>
                <AtlasValues.Grid>
                    <FormElements section={ SECTION_CAPP_CONTRAT_SALAIRE } dossier={ dossier } metadata={ props.metadata } path="contrat" type="recap" />
                </AtlasValues.Grid>
                <AtlasValues.Grid label="Avantages en nature">
                    <FormElements section={ SECTION_CAPP_CONTRAT_AVANTAGES } dossier={ dossier } metadata={ props.metadata } path="contrat" type="recap" />
                </AtlasValues.Grid>
            </AtlasValues>
        </MyContainer>
    </RecapSection>
}
