import {
  AtlasNavProps,
  AtlasToastsProps,
  MyCompanySelectorProps,
  MyMenuProps,
  MySiteInfoProps,
} from "atlas-ds";
import classNames from "classnames";
import { useEffect, useRef } from "react";

export interface MyTemplateProps {
  /**
   * Le menu principal
   */
  menu: React.ReactElement<MyMenuProps>;
  /**
   * La barre de navigation
   */
  topNav: React.ReactElement<AtlasNavProps>;
  /**
   * Les informations sur le site
   */
  siteInfo: React.ReactElement<MySiteInfoProps>;
  /**
   * Le contenu principal
   */
  children: React.ReactNode;
  /**
   * Le menu est-il ouvert ? En mobile, il n'est pas visible par défaut.
   */
  isMenuOpen: boolean;
  /**
   * Le sélecteur d'entreprise
   */
  companySelector?: React.ReactElement<MyCompanySelectorProps>;
  /**
   * Une liste de toasts à présenter
   */
  toasts?: React.ReactElement<AtlasToastsProps>;
}

/**
 * Le template principal de l'application. Il organise les différents composants
 * de premier niveau visuellement.
 * Pour des contraintes d'implémentation, l'état d'ouverture du menu est à la
 * charge du contexte appelant.
 */
export function MyTemplate(props: MyTemplateProps) {
  const ref = useRef<HTMLDivElement>(null);

  // We need to get scrollbar width for some CSS calc()
  // See https://github.com/w3c/csswg-drafts/issues/6026
  useEffect(() => {
    const setScrollbarWidth = () =>
      ref.current?.style.setProperty(
        "--my-template-scrollbar-width",
        window.innerWidth - document.documentElement.clientWidth + "px"
      );

    window.addEventListener("resize", setScrollbarWidth);
    setScrollbarWidth();

    return () => window.removeEventListener("resize", setScrollbarWidth);
  });

  return (
    <div className="my-template" ref={ref}>
      <div className="my-template__nav">{props.topNav}</div>

      {props.companySelector && (
        <nav
          className="my-template__company"
          aria-label="Choix de l'entreprise"
        >
          {props.companySelector}
        </nav>
      )}
      <header
        className={classNames("my-template__menu", {
          "my-template__menu--open": props.isMenuOpen,
        })}
      >
        {props.menu}
      </header>
      <footer className="my-template__siteInfo">{props.siteInfo}</footer>
      <div className="my-template__main">
        <div className="my-template__content">
          <div className="my-template__contentInner">
            {props.toasts}

            <main id="main">{props.children}</main>
          </div>
        </div>
      </div>
    </div>
  );
}

interface MyTemplateBreakoutProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
}

/**
 * Utilisez ce conteneur pour étendre un contenu sur toute la largeur du <main>,
 * sans tenir compte des marges de base
 */
MyTemplate.Breakout = (props: MyTemplateBreakoutProps) => {
  return <div className="my-template__breakout">{props.children}</div>;
};
