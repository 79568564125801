(Symbol as any).metadata ??= Symbol.for("Symbol.metadata")

import React from "react"
import { Yop, ValidationContext, SchemaForType, AnySchema, StringSchema, NumberSchema, BooleanSchema, DateSchema, FileSchema, ObjectSchema, ArraySchema } from "@dsid-opcoatlas/yop"
import { UseFormReturn } from "@dsid-opcoatlas/reform"
import { ObserverMetadata } from "./observers"
import log from "loglevel"
import { Entreprise, References } from "api/referencesAPI"

export type ElementsContextValue<T = any> = {
    model: ClassConstructor<T>,
    entreprise: Entreprise | null,
    references: References | null,
    map: { [key: string]: any }
}

export const ElementsContext = React.createContext<ElementsContextValue | null>(null)

export function useElementsContext<T>() {
    return React.useContext(ElementsContext) as ElementsContextValue<T>
}


export type PathElement = {
    class: ClassConstructor
    property: string
    index?: number
}

export type Path = PathElement[]

Array.prototype.at ??= function(index: number) {
    if (index < 0)
        return this[this.length + index]
    return this[index]
}

export class ExtendedPath extends Array<PathElement> {

    slice(start?: number, end?: number) {
        return new ExtendedPath(...super.slice(start, end))
    }

    get rootClass(): ClassConstructor | undefined {
        return this[0]?.class
    }

    getValue<T>(rootValue: any) {
        return this.reduce(
            (value, pathElement) => pathElement.index != null ? value?.[pathElement.property]?.[pathElement.index] : value?.[pathElement.property],
            rootValue
        ) as T
    }

    toString() {
        return this.map(pathElement => pathElement.property + (pathElement.index != null ? `[${ pathElement.index }]` : "")).join('.')
    }

    static fromString(path: string, model: ClassConstructor) {
        const pathElements: PathElement[] = []
        
        for (const pathElement of path.split('.')) {
            if (model === Object) {
                log.error(`Invalid path "${ path }" for model ${ model.name }`)
                break
            }
            
            const metadata = getMetadata(model)
            const bracketIndex = pathElement.indexOf('[')
            const property = bracketIndex !== -1 ? pathElement.slice(0, bracketIndex) : pathElement
            const index = bracketIndex !== -1 ? parseInt(pathElement.slice(bracketIndex + 1, -1)) : undefined
            pathElements.push({ class: model, property, index })

            model = metadata[property]?.object ?? metadata[property]?.array ?? Object
        }

        return new ExtendedPath(...pathElements)
    }
}


export type ClassConstructor<T = any> = new (...args: any[]) => T

export type ValidationContextProperty<V, T, P extends object = any, R extends object = any, C = any> = (
    V |
    ((context: ValidationContext<T, P, R, C>) => V)
)

export class FormFieldContext<R extends object = any> {
    
    private _path: ExtendedPath | undefined = undefined

    constructor(
        readonly rootClass: ClassConstructor<R>,
        readonly rawPath: string,
        readonly form: UseFormReturn<R>,
        readonly fieldContext?: any
    ) {}

    get path() {
        if (this._path == null)
            this._path = ExtendedPath.fromString(this.rawPath, this.rootClass)
        return this._path
    }

    getAt<T>(index?: number) {
        const path = index == null ? this.path : this.path.slice(0, index)
        return path.getValue<T | null | undefined>(this.form.values)
    }

    get<T>() {
        return this.getAt<T>()
    }

    getParent<T>() {
        return this.getAt<T>(-1)
    }

    getRoot<T>() {
        return this.getAt<T>(0)
    }
}

export type FormContextProperty<T, R extends object = any> = (
    T |
    ((context: FormFieldContext<R>) => T)
)

export type FormFieldMetadataDecoratorProps<R extends object = any> = {
    label?: FormContextProperty<string | JSX.Element, R>
    tooltip?: FormContextProperty<string | JSX.Element, R>
    disabled?: FormContextProperty<boolean, R>
    visible?: FormContextProperty<boolean, R>
}

export type ValidationFieldMetadataDecoratorProps<T, P extends object = any, R extends object = any, C = any> = {
    required?: ValidationContextProperty<boolean, T, P, R, C>
    ignored?: ValidationContextProperty<boolean, T, P, R, C>
    min?: ValidationContextProperty<number, T, P, R, C>
    max?: ValidationContextProperty<number, T, P, R, C>
}

export type FieldMetadataDecoratorProps<T, P extends object = any, R extends object = any, C = any> =
    FormFieldMetadataDecoratorProps<R> &
    ValidationFieldMetadataDecoratorProps<T, P, R, C> & {
    
    input?: ((props: any) => JSX.Element)
    suffix?: FormContextProperty<string | JSX.Element, R>
    schema?: SchemaForType<T> | (() => SchemaForType<T>)
    override?: boolean
    dto?: string
}
export type ArrayItemType<ArrayType> = ArrayType extends Array<infer ItemType> ? ItemType : never

export type FormFieldMetadata<T> = FieldMetadataDecoratorProps<T> & {
    array?: ClassConstructor<ArrayItemType<T>>
    object?: ClassConstructor<T>

    overrides?: ClassOverride<ClassConstructor<T>>
    
    observers?: Map<string, ObserverMetadata<T>>

    contextConsumer?: (context: any) => any
}

export type FieldNames<T> = Partial<{ [P in keyof T]:
    [T[P]] extends [string | number | boolean | Date | File | null | undefined] ? boolean | FormFieldMetadata<T[P]> :
    [T[P]] extends [any[] | null | undefined] ? [number, FieldNames<ArrayItemType<T[P]>>] :
    [T[P]] extends [object | null | undefined] ? FieldNames<T[P]> :
    never
}>

export type ClassFieldsMetadata<T> = Record<keyof T, FormFieldMetadata<T>>

export const elementsSymbol = Symbol('elements')

export function getElementsMetadataForField<T>(context: ClassFieldDecoratorContext<unknown, T>, clear?: boolean): FormFieldMetadata<T> {
    if (typeof context.name === 'symbol')
        throw new Error('Symbol fields are not supported by Elements decorators')

    const metadata = context.metadata
    if (!Object.hasOwnProperty.bind(metadata)(elementsSymbol))
        metadata[elementsSymbol] = Object.create(metadata[elementsSymbol] ?? null)
    
    const elementsMetadata = metadata[elementsSymbol] as ClassFieldsMetadata<T>
    const fieldName = (context.private && context.name.startsWith('#') ? context.name.substring(1) : context.name) as keyof T
    if (!Object.hasOwnProperty.bind(elementsMetadata)(fieldName))
        elementsMetadata[fieldName] = clear === true ? {} : { ...elementsMetadata[fieldName] }
    return elementsMetadata[fieldName]
}

export function fieldMetadataDecorator<T>(value: FormFieldMetadata<T>) {
    return function _(_: any, context: ClassFieldDecoratorContext<unknown, T>) {
        const fieldMetadata = getElementsMetadataForField<T>(context, value.override)
        Object.assign(fieldMetadata, value)
    }
}

export type ClassConstructorType<ClassType> = ClassType extends ClassConstructor<infer Class> ? Class : never
export type ClassOverride<T extends ClassConstructor> = Partial<{ [P in keyof ClassConstructorType<T>]: FormFieldMetadata<ClassConstructorType<T>[P]> }>

export function classFieldsMetadataDecorator<T extends ClassConstructor>(fieldValues: ClassOverride<T>) {
    return function _(_: any, context: ClassDecoratorContext<T>) {
        const metadata = context.metadata
        if (!Object.hasOwnProperty.bind(metadata)(elementsSymbol))
            metadata[elementsSymbol] = Object.create(metadata[elementsSymbol] ?? null)
        
        const elementsMetadata = metadata[elementsSymbol] as ClassFieldsMetadata<ClassConstructorType<T>>
        for (const fieldName in fieldValues) {
            const value = fieldValues[fieldName]!
            if (!Object.hasOwnProperty.bind(elementsMetadata)(fieldName))
                elementsMetadata[fieldName] = { ...elementsMetadata[fieldName] }
            Object.assign(elementsMetadata[fieldName], value)
        }
    }
}

const metadataCache = new WeakMap<ClassConstructor, ClassFieldsMetadata<any>>()
const ObjectPrototype = Object.getPrototypeOf(Object)

export function getClassConstructor<T = unknown>(model: ClassConstructor, path: ExtendedPath) {
    let currentModel = model
    for (const pathElement of path) {
        const metadata = getMetadata(currentModel)
        const field = metadata[pathElement.property]
        if (field.array != null)
            currentModel = field.array
        else if (field.object != null)
            currentModel = field.object
        else
            return undefined
    }
    return currentModel as ClassConstructor<T>
}

/**
 * Get the metadata for the model.
 * 
 * @param model The model to get the metadata for
 * @returns The metadata for the model
 */
export function getMetadata<T>(model: ClassConstructor<T>, path?: string[]): ClassFieldsMetadata<any> {
    const key = model
    
    let metadata = metadataCache.get(key)
    if (metadata == null) {
        while (model != null && model !== ObjectPrototype) {
            metadata = { ...(model as any)[Symbol.metadata]?.[elementsSymbol], ...(metadata ?? {}) }
            model = Object.getPrototypeOf(model)
        }
        metadataCache.set(key, metadata!)
    }

    if (path != null && path.length > 0) {
        const field = metadata![path[0]]
        if (field?.object != null)
            metadata = getMetadata(field.object, path.slice(1))
        else if (field?.array != null)
            metadata = getMetadata(field.array, path.slice(1))
        else
            metadata = {}
    }

    return metadata as ClassFieldsMetadata<T>
}

const PrimitiveSchemas = new Map<ClassConstructor<object>, Pick<AnySchema<object>, "defined">>([
    [String, Yop.string()],
    [Number, Yop.number()],
    [Boolean, Yop.boolean()],
    [Date, Yop.date()],
    [File, Yop.file()],
])

export function applyValidationConstraints<T, S extends AnySchema<T>>(schema: S, constraints: ValidationFieldMetadataDecoratorProps<T>) {
    if (constraints.ignored === true)
        schema = schema.ignored()
    else if (typeof constraints.ignored === 'function')
        schema = schema.ignoredIf(constraints.ignored)

    if (constraints.required === true)
        schema = schema.required()
    else if (typeof constraints.required === 'function')
        schema = schema.requiredIf(constraints.required)

    if (constraints.min != null && (typeof (schema as any)['min'] === 'function'))
        schema = (schema as any).min(constraints.min)

    if (constraints.max != null && (typeof (schema as any)['max'] === 'function'))
        schema = (schema as any).max(constraints.max)
    
    return schema
}

export function createValidationSchema(model: ClassConstructor<String>): StringSchema<string | null>
export function createValidationSchema(model: ClassConstructor<Number>): NumberSchema<number | null>
export function createValidationSchema(model: ClassConstructor<Boolean>): BooleanSchema<boolean | null>
export function createValidationSchema(model: ClassConstructor<Date>): DateSchema<Date | null>
export function createValidationSchema(model: ClassConstructor<File>): FileSchema<File | null>
export function createValidationSchema<T extends object>(model: ClassConstructor<T>): ObjectSchema<T | null>
export function createValidationSchema<T extends object>(
    model: ClassConstructor<T>,
    arrayElementValidation: ValidationFieldMetadataDecoratorProps<T | null>
): ArraySchema<(T | null)[] | null>

export function createValidationSchema<T extends object>(model: ClassConstructor<T>, arrayElementValidation?: ValidationFieldMetadataDecoratorProps<T | null>) {
    let schema = PrimitiveSchemas.get(model)?.defined()

    if (schema == null) {
        const metadata = getMetadata(model)

        const properties = Object.fromEntries(Object.entries<FormFieldMetadata<any>>(metadata)
            .filter(([, fieldMetadata]) => fieldMetadata?.schema != null)
            .map(([fieldName, fieldMetadata]) => {
                let schema = fieldMetadata.schema!
                if (typeof schema === 'function')
                    schema = schema()
                return [fieldName, applyValidationConstraints(schema, fieldMetadata)]
            }))
    
        schema = Yop.object<any>(properties).defined()
    }

    if (arrayElementValidation != null) {
        schema = applyValidationConstraints(schema, arrayElementValidation)
        schema = Yop.array(schema).defined()
    }

    return schema
}
