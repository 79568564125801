import { unwrapResult } from '@reduxjs/toolkit'
import { DiagDiagnosticActionDTO } from 'api/diagatlasAPI'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasFlex, AtlasGrid, AtlasHeading, AtlasIcon, AtlasSpacer, MyAction, MyActionCategory, MyContainer, MyDiagnosticLogo, MyKanban } from 'atlas-ds'
import { formatToFullDate } from 'components/format/Format'
import { useHistory } from 'react-router-dom'
import { STATUTS_ACTIONS_PLAN, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import DiagExportsButton from './DiagExportsButton'
import { DiagnosticActionsPreconisees } from './MonDiagnosticActions'

export default function AccueilDiagAtlas() {
    const dispatch = useAppDispatch()
    const { loading, diagnostic, error } = useDiagnostic(null, 'plan-action', { statuts: 'accueil' })
    const history = useHistory()

    const goToDiagnostic = () => {
        history.push("/mon-diagnostic")
    }

    const goToDiagnosticActions = () => {
        history.push("/mon-diagnostic-actions")
    }

    const goToDiagnosticPlan = () => {
        history.push("/mon-diagnostic-plan")
    }

    const ajouterAction = (action: DiagDiagnosticActionDTO) => {
        return dispatch(diagnosticSliceActions.addAction({ action: action.Action.Id, preconisee: false }))
            .then(unwrapResult)
            .then(da => {
                history.push('/mon-diagnostic-plan')
                return da
            })
    }

    if (loading || !diagnostic || error) {
        return null
    }

    if (diagnostic?.statut === "Diagnostic en cours") {
        return <AtlasGrid columnsCount={2} gap="xl">
            <div>
                <MyDiagnosticLogo maxWidth="14rem" />
                <AtlasSpacer size="s" />
                <AtlasHeading tag="h2" size="m">
                    Anticipez les mutations des emplois et compétences au sein de votre entreprise&nbsp;!
                </AtlasHeading>
                <p>Découvrez quels seront les métiers et compétences en transition et anticipez leurs mutations grâce à un plan d'action personnalisé.</p>
                <AtlasSpacer size="xl" />
                <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
            </div>
            <MyContainer colored>
                <AtlasFlex column gap="l" alignItems='center'>
                    <p>Terminer votre diagnostic afin de découvrir les actions préconisées par l'Opco Atlas et commencer votre plan d'action.</p>
                    <AtlasFlex justifyContent="center">
                        <AtlasButton type="button" onClick={ goToDiagnostic }>
                            Terminer le diagnostic
                        </AtlasButton>
                    </AtlasFlex>
                </AtlasFlex>
            </MyContainer>
        </AtlasGrid>

    } else if (diagnostic?.statut === "Diagnostic réalisé") {
        return <AtlasFlex column gap="s">
            <MyDiagnosticLogo maxWidth="14rem" />
            <AtlasSpacer size="s" />
            <AtlasHeading tag="h1" size="m">Actions préconisées</AtlasHeading>

            <p>Diagnostic réalisé{ diagnostic?.dateRealisation ? ' le ' + formatToFullDate(diagnostic?.dateRealisation) : '' }{ diagnostic?.contactRealisation ? ' par ' + diagnostic?.contactRealisation : '' }</p>

            <DiagnosticActionsPreconisees diagnostic={diagnostic} maxElements={6}
                ajouterLabel="Ajouter à mon plan d'action" ajouterAction={ajouterAction} />

            <AtlasFlex justifyContent="start">
                <AtlasButton onClick={ goToDiagnosticActions }>Voir toutes les actions préconisées</AtlasButton>
            </AtlasFlex>

            <AtlasSpacer size="s" />
            <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
        </AtlasFlex>
        
    } else if (diagnostic?.statut === "Plan d'action en cours d'exécution") {
        return <AtlasFlex column gap="s">
            <MyDiagnosticLogo maxWidth="14rem" />
            <AtlasSpacer size="s" />
            <AtlasHeading tag="h1" size="m">Plan d'action</AtlasHeading>

            <AtlasFlex column gap="l">
                <p>Sur la base du diagnostic réalisé{ diagnostic?.dateRealisation ? ' le ' + formatToFullDate(diagnostic?.dateRealisation) : '' }{ diagnostic?.contactRealisation ? ' par ' + diagnostic?.contactRealisation : '' }{ diagnostic?.dateModification ? ' et modifié le ' + formatToFullDate(diagnostic?.dateModification) : '' }{ diagnostic?.contactModification ? ' par ' + diagnostic?.contactModification : '' }</p>

                <MyKanban
                    previewLink={ <AtlasButton onClick={ goToDiagnosticPlan }>Voir le plan d'action</AtlasButton> }
                    onCardMove={ () => {} }
                    onCardRemove={ () => {} }
                    addCta={ <AtlasButton>Ajouter une action</AtlasButton> }
                    columns={ STATUTS_ACTIONS_PLAN.map(s => ({
                        id: s.id,
                        label: s.label,
                        moveLabel: s.shortLabel,
                        emoji: s.emoji,
                        cards: diagnostic?.planAction?.filter(a => a.Statut === s.label)?.map(a => {
                            return { id: a.Id, card: <MyAction
                                key={ a.Id } 
                                tags={[{ label: a.Action.Type.Libelle, category: a.Action.Type.CategorieLibelle as MyActionCategory }]}
                                infoHref={ a.Action.URL }
                            >{ a.Action.Libelle }</MyAction> }
                        }) ?? [],
                    })) } />
            </AtlasFlex>

            <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
        </AtlasFlex>
    }

    return <div id="diagInfo"><AtlasGrid columnsCount={2} gap="xl">
        <div>
            <div>
                <MyDiagnosticLogo maxWidth="14rem" />
            </div>
            <AtlasSpacer size="s" />
            <AtlasHeading tag="h2" size="m">
                Anticipez les mutations des emplois et compétences au sein de votre entreprise !
            </AtlasHeading>
            <p>Découvrez quels seront les métiers et compétences en transition et anticipez leurs mutations grâce à un plan d'action personnalisé.</p>
            <AtlasSpacer size="xl" />
            <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
        </div>
        <MyContainer colored>
            <AtlasFlex column gap="l">
                <AtlasFlex column gap="m">
                    <p>Grâce à ce diagnostic, vous pourrez facilement repérer les actions disponibles pour accompagner la mutation de vos emplois et compétences.</p>
                    <AtlasFlex gap="s">
                        <AtlasIcon name="clock" size="s" />Temps moyen : 10 minutes
                    </AtlasFlex>
                </AtlasFlex>
                <AtlasFlex column alignItems="center">
                    <AtlasButton type="button" onClick={ goToDiagnostic }>
                        Commencer le diagnostic
                    </AtlasButton>
                </AtlasFlex>
            </AtlasFlex>
        </MyContainer>
    </AtlasGrid></div>
}