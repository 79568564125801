import TuteurFields from 'pages/dossier/elements/TuteurFields'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_TT } from './VAEITuteurMetadata'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { useFormContext } from '@dsid-opcoatlas/reform'
import { DossierVAEI } from './VAEIModel'

export function DossierVAEITuteurElements(props: { metadata: FormMetadata<DossierVAEI> }) {

    const context = useFormContext<DossierVAEI>()

    return (<>
        <FormElements section={ SECTION_TT } metadata={ props.metadata } type="input" />

        { context.values?.tuteurTravailTemporaire != null &&
        <TuteurFields metadata={ props.metadata } name="tuteur" />
        }
    </>)
}