import { AtlasValues, MyContainer } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { Fragment } from 'react'
import { BaseDossierStagiaire, DossierAF } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapSalaries<S extends BaseDossierStagiaire>(props: RecapProps<DossierAF<S, any>>) {
    const { navigateEdit, steps } = useDossierNavigation(props.metadata, props.navigationGuard)

    const metadata = props.metadata
    const salariesEtape = steps.indexOf(props.step) + 1

    const skeleton = props.loading ? [{ cfa: {} }] : []
    const salaries = props.dossier?.salaries ?? skeleton
    const dossier = props.dossier ?? { salaries } as any

    return <>{ salaries.map((s, index) => <Fragment key={ `salarie[${index}]` }>
            <RecapSection
                title={ `Salarié n°${index+1}` }
                onModifier={ () => navigateEdit(props.dossier!.NumeroDossier__c!, salariesEtape, (s as any).IdHeroku) }
            >
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <FormElements key={ `salaries[${index}]` } dossier={ dossier } metadata={ metadata } path="salaries" type="recap" index={ index } />
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
        </Fragment>) 
    }</>
}
