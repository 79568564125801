import { ObjectMetadata } from "pages/dossier/metadata/FormMetadata";
import { DossierVAEIEmployeur } from "./VAEIModel";
import { SECTION_EMPLOYEUR_CONTACT, SECTION_EMPLOYEUR_EXTRA, employeurExtendedMetadata, employeurMetadata } from "pages/dossier/metadata/EmployeurMetadata";
import { EmailFieldMetadata, PhoneFieldMetadata, TextFieldMetadata } from "components/metadata/TextFieldMetadata";
import { TOOLTIPS } from "pages/dossier/Tooltips";
import { simpleMapping } from "pages/dossier/metadata/DossierMapping";
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata";

export const VAEIEmployeurMetadata: ObjectMetadata<DossierVAEIEmployeur> = {
    ...employeurMetadata,
    codeNaf: employeurExtendedMetadata.codeNaf,
    codeIdcc: employeurExtendedMetadata.codeIdcc,
    caisseRetraiteComplementaire: TextFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Caisse de retraite complémentaire',
            tooltip: TOOLTIPS.employeur.caisseRetraiteComplementaire,
        },
        mapping: simpleMapping('CaisseRetraiteComplementaire__c'),
    }).required().mutate(yop => yop.max(80, 'Maximum 80 caractères')),
    organismePrevoyance: TextFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: 'Organisme de prévoyance',
            disabled: (props: any) => props.parent?.Id == null,
        },
        mapping: simpleMapping('OrganismePrevoyance__c'),
    }).mutate(yop => yop.max(80, 'Maximum 80 caractères')),
    effectifSalarie: NumberFieldMetadata({
        section: SECTION_EMPLOYEUR_EXTRA,
        props: {
            label: "Effectif total salariés de l'entreprise",
            tooltip: TOOLTIPS.employeur.effectifSalarie,
        },
        mapping: simpleMapping('NbSalariesEntreprise__c'),
    }).required().mutate(yop => yop
        .min(1, "Le nombre de salariés doit être d'au moins un")
        .max(2_000_000, "Le nombre de salariés est trop élevé")),
    telephone: PhoneFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        props: {
            label: 'Téléphone',
        },
        mapping: simpleMapping('TelephoneADH__c'),
    }).required(),
    email: EmailFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        props: {
            label: 'Email',
        },
        mapping: simpleMapping('EmailADH__c'),
    }).required().mutate(yop => yop.max(64, 'Maximum 64 caractères')),
}
