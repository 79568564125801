import { DossierDetail, postRelanceAnnulerSubrogationDossier, postRelanceDemanderSubrogationDossier } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_AJOUT_SUBROGATION, DEMANDE_EN_COURS_ANNULATION_SUBROGATION } from 'api/references'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { DossierRelanceActionProps } from '.'
import DossierRelanceAction from './DossierRelanceAction'


export function DossierRelanceSubrogationAnnuler(props: DossierRelanceActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    return <DossierRelanceAction
        id="annulerSubrogation"
        dossier={ dossier }
        label="Demander l'annulation de la subrogation"
        of={props.of}
        typeDemande={DEMANDE_EN_COURS_ANNULATION_SUBROGATION}
        successMessage="Votre demande d'annulation de la subrogation a bien été prise en compte. Elle sera traitée dans les meilleurs délais"
        formContent={<>
            <p>Confirmez-vous l'annulation de subrogation pour votre dossier N°{dossier.NumeroDossier__c} ?</p>
        </>}
        doAction={ (dossier: DossierDetail) => postRelanceAnnulerSubrogationDossier(dossier.NumeroDossier__c, 
            JSON.stringify({ 'Motif': ' ' })) }
    />
}

export function DossierRelanceSubrogationDemander(props: DossierRelanceActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    return <DossierRelanceAction
        id="demanderSubrogation"
        dossier={ dossier }
        label="Demander la subrogation"
        of={props.of}
        typeDemande={DEMANDE_EN_COURS_AJOUT_SUBROGATION}
        successMessage="Votre demande de subrogation a bien été prise en compte. Elle sera traitée dans les meilleurs délais"
        formContent={<>
            <p>Confirmez-vous la demande de subrogation pour votre dossier N°{dossier.NumeroDossier__c} ?</p>
        </>}
        doAction={ (dossier: DossierDetail) => postRelanceDemanderSubrogationDossier(dossier.NumeroDossier__c, 
            JSON.stringify({ 'Motif': ' ' })) }
    />
}
