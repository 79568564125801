import { RootState } from "app/rootReducer"
import Link from "components/Link"
import { useSelector } from "react-redux"


export default function VoirMesRapports() {

    const { fulfilled: config } = useSelector((state: RootState) => state.configState)

    const lien = `${config?.RapportsSelLegacy?.lien}`

    return <Link href={ lien } level={ 2 }>Voir mes rapports</Link>
}