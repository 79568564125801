import { getParentPath } from '@dsid-opcoatlas/yop'
import { startOfToday, subYears } from 'date-fns'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import CerfaRefField from 'pages/dossier/cerfa/CerfaRefField'
import RecapRefField from 'pages/dossier/recap/RecapRefField'
import { BaseDossierTuteur } from 'slices/dossierCommon'
import { BooleanFieldMetadata, CheckboxFieldMetadata } from '../../../components/metadata/BooleanFieldMetadata'
import { DateFieldMetadata, DateNaissanceFieldMetadata } from '../../../components/metadata/DateFieldMetadata'
import { NIRAsyncFieldMetadata } from '../../../components/metadata/NIRAsyncFieldMetadata'
import { NumberFieldMetadata } from '../../../components/metadata/NumberFieldMetadata'
import { RadioRefFieldMetadata, SelectRefFieldMetadata } from '../../../components/metadata/RefFieldMetadata'
import { AutocompleteNameFieldMetadata, EmailFieldMetadata, FirstNameFieldMetadata, NameFieldMetadata, TextFieldMetadata } from '../../../components/metadata/TextFieldMetadata'
import { dateMapping, simpleMapping } from './DossierMapping'
import { ElementContext, FormMetadata, IgnoredFieldMetadata, createEmptyMetadata } from './FormMetadata'
import { UseFormReturn } from '@dsid-opcoatlas/reform'


// export const PlusHautDiplomeMetadata = (diplomePath: string, niveauPath: string) => SelectRefFieldMetadata({
//     props: {
//         label: 'Diplôme ou titre le plus élevé obtenu',
//         options: refs => refs.REF_DIPLOME.filter(ref => ref.Categorie__c?.split(',').includes('5')),
//         sorted: true,
//     },
//     mapping: {
//         mapModelToDTO: (value: string | null, dto: any) => {
//             let niveau = null
//             if (value) {
//                 const ref = store.getState().referencesState.fulfilled?.REF_DIPLOME?.find(ref => ref.Id === value)
//                 niveau = ref?.LibelleReduit__c?.substring(0, 1) ?? null
//             }
//             set(dto, diplomePath, value)
//             set(dto, niveauPath, niveau)
//         },
//         mapDTOToModel: (dto: any, context: MappingContext) => {
//             return get(dto, diplomePath)
//         },
//     },
// })

export const tuteur18ans = subYears(startOfToday(), 18)


export const tuteurFields = {
    nom: NameFieldMetadata({
        props: {
            label: 'Nom', 
        },
        mapping: simpleMapping('NomTuteur__c'),
    }).required().mutate(yop => yop.max(50)),
    nomNaissance: (search: ((form: UseFormReturn<any>, recherche: string, metadata: FormMetadata<any>) => Promise<any[]>)) => AutocompleteNameFieldMetadata({
        search,
        props: {
            label: 'Nom de naissance',
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
        },
        mapping: simpleMapping('NomNaissanceTuteur__c'),
    }).required().mutate(yop => yop.max(50)),
    prenom: FirstNameFieldMetadata({
        props: {
            label: "Premier prénom selon l'état civil",
        },
        mapping: simpleMapping('PrenomTuteur__c'),
    }).required().mutate(yop => yop.max(50)),
    sexe: RadioRefFieldMetadata({
        props: {
            label: 'Sexe',
            options: refs => refs.REF_SEXE.filter(ref => ref.Id !== '0'),
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
            onChange(value, form, parentPath) {
                const nirPath = `${ parentPath }.nir`
                if (!form.isTouched(nirPath) && form.getValue(nirPath)) {
                    form.touch(nirPath)
                    form.validateAt(nirPath)
                }
            },
        },
        mapping: simpleMapping('SexeTuteur__c'),
    }).required(),
    dateNaissance: DateNaissanceFieldMetadata({
        props: {
            label: 'Date de naissance',
        },
        mapping: dateMapping('DateNaissanceTuteur__c'),
    }).required().mutate(yop => yop.max(tuteur18ans, "Le tuteur doit être majeur")),
    nir: NIRAsyncFieldMetadata({
        props: {
            label: 'Numéro de sécurité sociale (NIR)',
            tooltip: TOOLTIPS.capp.maitre.nir,
        },
        mapping: simpleMapping('NirTuteur__c'),
    }),
    ntt: IgnoredFieldMetadata<boolean>(simpleMapping('NttTuteur__c')),
    nirVerifError: IgnoredFieldMetadata<boolean>(simpleMapping('NirNonVerifieSNGITuteur__c')),
    nirValid: createEmptyMetadata(),
    email: EmailFieldMetadata({
        props: {
            label: 'E-mail',
        },
        mapping: simpleMapping('EmailTuteur__c'),
    }),
    plusHautDiplome: TextFieldMetadata({
        props: {
            label: 'Intitulé précis du dernier diplôme ou titre le plus élevé obtenu',
        },
        mapping: simpleMapping('DiplomePlusEleveObtenuTuteur__c'),
    }).mutate(yop => yop.max(255)),
    niveauPlusHautDiplome: SelectRefFieldMetadata({
        props: {
            label: 'Niveau de diplôme ou titre le plus élevé obtenu',
            options: refs => refs.REF_NIVEAU_DIPLOME.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            code: true,
            sorted: true,
        },
        mapping: simpleMapping('NiveauPlusEleveObtenuTuteur__c'),
        recap: RecapRefField,
        cerfa: CerfaRefField,
    }),
    emploiOccupe: TextFieldMetadata({
        props: {
            label: 'Emploi occupé',
        },
        mapping: simpleMapping('EmploiTuteur__c'),
    }).required().mutate(yop => yop.max(80)),
    employeurDirigeant: BooleanFieldMetadata({
        props: {
            label: 'Tuteur employeur dirigeant',
        },
        mapping: simpleMapping('EmployeurDirigeantTuteur__c'),
    }).required(),
    anneesExperience: NumberFieldMetadata({
        props: {
            label: "Nombre d'années d'expérience",
        },
        mapping: simpleMapping('NbAnneeExperienceTuteur__c'),
    }).required(),
    formation: BooleanFieldMetadata({
        props: {
            label: 'Le tuteur a-t-il suivi une formation préalable à la fonction tutorale ?',
            onChange(value, form, parentPath) {
                if (value === false)
                    form.setValue(`${ parentPath }.dateFormation`, null, true)
            },
        },
        mapping: simpleMapping('FormationPrealableTuteur__c'),
    }).required(),
    dateFormation: DateFieldMetadata({
        props: {
            label: 'Si oui, date de formation',
            disabled: (context: ElementContext<BaseDossierTuteur>) => context.parent?.formation !== true,
        },
        mapping: dateMapping('DateFormationPrealableTuteur__c'),
    }).mutate(yop => yop.requiredIf<BaseDossierTuteur>(context => context.parent?.formation === true)),
    salariesSuivis: NumberFieldMetadata({
        props: {
            label: "Nombre d'alternants actuellement suivis par le tuteur",
        },
        mapping: simpleMapping('NbSalariesSuiviTuteur__c'),
    }).required(),
    priseEnChargeFonctionTutorale: BooleanFieldMetadata({
        props: {
            label: 'Demande de prise en charge de la fonction tutorale',
            onChange(value, form, parentPath) {
                if (value === false)
                    form.setValue(`${ parentPath }.exerciceFonction2Annees`, null, true)
            },
        },
        mapping: simpleMapping('FonctionTutoraleTuteur__c'),
    }).required(),
    exerciceFonction2Annees: BooleanFieldMetadata({
        props: {
            label: 'Exercice de la fonction tutorale pendant les deux années précédentes ?',
            disabled: (context: ElementContext<BaseDossierTuteur>) => context.parent?.priseEnChargeFonctionTutorale !== true,
        },
        mapping: simpleMapping('Exercice2AnneesTuteur__c'),
    }).mutate(yop => yop.requiredIf<BaseDossierTuteur>(context => context.parent?.priseEnChargeFonctionTutorale === true)),
    eligibilite: CheckboxFieldMetadata({
        props: {
            label: "L'employeur atteste sur l'honneur que le tuteur répond à l'ensemble des critères d'éligibilité à cette fonction.",
            tooltip: TOOLTIPS.cproa.eligibilite,
            full: true
        },
        mapping: simpleMapping('EmployeurAttesteSurHonneurTuteur__c'),
    }).required().mutate(yop => yop.oneOf([true], "Veuillez cocher cette case pour confirmer l'éligibilité des maîtres d'apprentissage")),
}
