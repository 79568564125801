import { axiosSimu, BaseSearchQuery, getRequestConfig, Page } from 'api/baseAPI'
import store from 'app/store'
import { Identifiable } from './dossierAPI'


export type { Page } from 'api/baseAPI'

export interface SimulationsSearchQuery extends BaseSearchQuery {
}

export interface Simulation extends Identifiable {
    id: string,
    codeIDCC: string,
    name: string,
    dateCreation: string,
    dateModification: string,
    entrepriseCliente: string,
    status: string,
    type: string,
    age: number,
    dateNaissance: string | null,
    accesAide: boolean,
    alternantIdentifie: boolean,
    anneeDebutCycleFormation: number | null,
    beneficiaireDejaApprenti: boolean,
    caCoutPedagogiqueTotalFacture: number | null,
    caDureeTotaleDeFormation: number | null,
    cpCoutPedagogiqueTotalFacture: number | null,
    cpDureeTotaleDeFormationHeures: number | null,
    cpDureeTotaleDeFormationMois: number | null,
    categorieEmploi: string | null,
    createurEntreprise: boolean,
    dateDebutContrat: string | null,
    demandeurEmploi: boolean,
    libelle: string,
    effectif: string,
    formation: string,
    devisPrestataireDeFormationFacture: boolean,
    diplomeNiveauInferieur: boolean,
    domiciliationEntreprise: string | null,
    dureeDuContrat: string | null,
    niveauInitial: string | null,
    publicPrioritaire: boolean,
    repreneurEntreprise: boolean,
    sousCategorieEmploi: string | null,
    sportifHautNiveau: boolean,
    travailleurHandicape: boolean,
    tuteurExperimente: boolean,
    tuteurFonctionTutorale: boolean,
    tuteurMaitreFonctionTutorale: boolean,
    tuteurMaitreFormationTutorat: boolean,
    echecExamen: boolean,
    precedentContratRompu: boolean,
    typeDeCertificationVisee: string | null,
    formationLibelle: string | null
}

export interface SimulationDetail extends Simulation {
}

function getSearchSimulationsParams(query: SimulationsSearchQuery): URLSearchParams {

    const params = new URLSearchParams()

    const entreprise = store.getState().contextState.entreprise

    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }

    params.append('id-entreprise', entreprise?.Id ?? '')
    params.append('sauvegarde', 'true')

    return params
}

export async function getSimulations(query: SimulationsSearchQuery): Promise<Page<SimulationDetail>> {

    const requestConfig = await getRequestConfig()
    const params = getSearchSimulationsParams(query)
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    requestConfig.params = params

    const { data, headers } = await axiosSimu.get<SimulationDetail[]>('/simulations', requestConfig)
    const totalCount = parseInt(headers['x-total-count'], 10) || data.length
    return {
        totalCount: totalCount,
        items: data.map(s => ({ ...s, Id: s.id })),
    }
}

export async function supprimerSimulation(simulationId: string) {
    const requestConfig = await getRequestConfig()

    const { data } = await axiosSimu.delete<any>('/simulations/' + simulationId, requestConfig)
    return data
}

export async function creerDossierDepuisSimulation(simulationId: string) {
    const requestConfig = await getRequestConfig()

    const { data } = await axiosSimu.post<any>('/simulations/' + simulationId + '/convertir', null, requestConfig)
    return data
}