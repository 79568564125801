import { Text, View } from '@react-pdf/renderer';
import styles from 'pages/dossier/cerfa/global.styles'
import Checkboxes from 'pages/dossier/cerfa/components/Checkboxes'
import { CPROEmployeurMetadata } from 'pages/dossier-cpro/CPROEmployeurMetadata';
import { DossierCPROEmployeur } from 'pages/dossier-cpro/CPROModel';
import CerfaAdresseField from 'pages/dossier/cerfa/CerfaAdresseField';
import CerfaElement from 'pages/dossier/cerfa/CerfaElement';
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField';

export default function Employeur({ employeur }: { employeur?: DossierCPROEmployeur | null }) {

  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeaderV4}>L'EMPLOYEUR</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Nom et prénom ou dénomination :</Text>
            <CerfaElement metadata={ CPROEmployeurMetadata.nom } value={ employeur?.nom } />
            <Text>Adresse de l'établissement d'exécution du contrat :</Text>
            <CerfaAdresseField value={ employeur } />
            <Text>
              Téléphone :{' '}
              <CerfaElement metadata={ CPROEmployeurMetadata.telephone } value={ employeur?.telephone } />
            </Text>
            <Text>Courriel :</Text>
            <CerfaElement metadata={ CPROEmployeurMetadata.email } value={ employeur?.email } default="____________________@___________________" />
            <Text>Caisse de retraite complémentaire :</Text>
            <CerfaElement metadata={ CPROEmployeurMetadata.caisseRetraiteComplementaire } value={ employeur?.caisseRetraiteComplementaire } />
            <View style={styles.bordered}>
              <Text>Organisme de prévoyance le cas échéant :</Text>
              <CerfaElement metadata={ CPROEmployeurMetadata.organismePrevoyance } value={ employeur?.organismePrevoyance } />
            </View>
          </View>
          <View style={styles.colRight}>
            <View style={styles.bordered}>
              <Text>
                Particulier employeur :{' '}
                <Checkboxes value={false}></Checkboxes>
              </Text>
              <Text>N° URSSAF du particulier-employeur :</Text>
              <Text style={styles.user}>{' '}</Text>
            </View>
            <Text><Text style={styles.bold}>N° SIRET</Text> de l'établissement d'exécution du contrat :{' '}</Text>
            <CerfaElement metadata={ CPROEmployeurMetadata.numeroSiret } value={ employeur?.numeroSiret } />
            <Text>
              Code activité de l'entreprise (NAF) :{' '}
              <CerfaElement metadata={ CPROEmployeurMetadata.codeNaf } value={ employeur?.codeNaf } />
            </Text>
            <Text>Effectif total salarié de l'entreprise :</Text>
            <CerfaElement metadata={ CPROEmployeurMetadata.effectifSalarie } value={ employeur?.effectifSalarie } />
            <Text>Code IDCC de la convention collective applicable :</Text>
            <CerfaTextField value={ employeur?.codeIdcc } />
            <Text>Numéro du projet* :</Text>
            <Text style={styles.info}>*à renseigner dans le cadre d'un contrat de professionnalisation
              associant des actions de validation des acquis de l'expérience
              mentionné à l'article 11 de la loi n° 2022-1598 du 21 décembre
              2022
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
