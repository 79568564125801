import { BaseTextField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform";
import { AtlasFieldPassword, AtlasFieldPasswordRule } from "atlas-ds";
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps";

export interface PasswordFieldProps<T extends object> extends BaseFieldProps<string, T> {
  autocomplete?: "new-password" | "current-password";
  rules?: AtlasFieldPasswordRule[];
}

export default function PasswordField<T extends object>(props: PasswordFieldProps<T>) {
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)
    const render = useRender()

    return <AtlasFieldPassword
        name={props.name}
        label={(props.label as string) ?? "Mot de passe"}
        rules={props.rules}
        autocomplete={props.autocomplete ?? 'new-password'}
        error={fieldState.error}
        required
        inputConstructor={(inputProps) => <BaseTextField<any, any>
          {...inputProps}
          onChange={props.onChange}
          onBlur={ props.onBlur }
          render={render}
        />}
    />
}