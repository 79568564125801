import CodePostalCommuneField from "components/reform/inputs/CodePostalCommuneField"
import { AdresseValidationFieldsParams, RequiredString } from "components/yop/constraints"
import { createMetadata, SettingsFieldMetadata } from "pages/dossier/metadata/FormMetadata"
import ExportTextField from "pages/dossier/export/ExportTextField"
import RecapTextField from "pages/dossier/recap/RecapTextField"

export function RequiredCodePostalCommuneFieldMetadata(settings: SettingsFieldMetadata<string> = {}) {
    
    function removeLastSegment(path: string) {
        const lastDot = path.lastIndexOf('.')
        return lastDot >= 0 ? path.substring(0, lastDot) : path
    }

    return createMetadata(
        {
            yop: RequiredString
                .max(AdresseValidationFieldsParams.Cp)
                .matches(AdresseValidationFieldsParams.CpRegex, AdresseValidationFieldsParams.CpMessage),
            input: (props?: any) => CodePostalCommuneField({ ...props, name: removeLastSegment(props.name) }),
            recap: RecapTextField,
            export: ExportTextField,
        },
        settings
    )
}
