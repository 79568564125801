import { AtlasValue } from 'atlas-ds'
import { formatDate, formatSuffix } from 'components/format/Format'

interface RecapTextFieldProps {
    label: string,
    value: string | Date | null | undefined,
    suffix?: string,
    type?: string,
}

export default function RecapTextField(props: RecapTextFieldProps) {
    const suffix = props.suffix ? (' ' + props.suffix) : ''

    let value = ''
    if (props.value) {
        if (props.type === 'date')
            value = formatDate(props.value) + suffix
        else if (props.type === 'number' && typeof props.value === 'string' && parseFloat(props.value))
            value = props.value + formatSuffix(suffix, parseFloat(props.value) > 2)
        else
            value = props.value + suffix
    }

    return <AtlasValue label={ props.label }>{ value }</AtlasValue>
}
