import { AtlasButton, MyModal } from 'atlas-ds'
import Logo from 'components/Logo'
import { browserName, fullBrowserVersion } from 'react-device-detect'

const OLD_BROWSER_WARNED_KEY = "old-browser-warned"

export const hasBeenOldBrowserWarned = () => {
    return sessionStorage.getItem(OLD_BROWSER_WARNED_KEY) !== null
}

export default function OldBrowserWarning() {

    const warned = () => {
        sessionStorage.setItem(OLD_BROWSER_WARNED_KEY, "true")
        document.location.reload()
    }

    return (
        <MyModal
            isError
            label="Attention !"
            okButton={<AtlasButton onClick={ warned } level={ 2 }>Continuer malgré tout</AtlasButton>}
        >
            <Logo />
            <p>
                Vous utilisez un navigateur internet { browserName } en version { fullBrowserVersion }. L'application myAtlas n'est
                pas optimisée pour ce navigateur et vous risquez de ne pas pouvoir utiliser toutes ses fonctionnalités. Nous vous
                conseillons fortement de mettre à jour votre navigateur internet. 
            </p>
        </MyModal>
    )
}
