import { Simulation, supprimerSimulation } from 'api/simulationAPI'
import { MyModal } from "atlas-ds"
import { ToastService } from 'components/BandeauAlert'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'


interface DeleteSimulationModalProps {
    simulation: Simulation
    close: () => void
}

export default function DeleteSimulationModal(props: DeleteSimulationModalProps) {

    const history = useHistory()

    const [deletePending, setDeletePending] = useState<boolean>(false)

    const handleDelete = () => {
        setDeletePending(true)

        supprimerSimulation(props.simulation!.id)
            .then(_ => {
                ToastService.showToast({
                    label: 'Simulations',
                    type: 'success',
                    content: 'La simulation a été supprimée avec succès.'
                })
                history.push({
                    pathname: '/mes-simulations',
                })
            }).finally(() => {
                props.close()
            })
    }

    return (
        <MyModal
            label="Voulez-vous vraiment supprimer cette simulation ?"
            onClose={ props.close }
            okButton={<Button
                destructive
                onClick={ handleDelete }
                spinner={{spinning: deletePending}}
                disabled={ deletePending }
            >Supprimer</Button>}
            cancelButton={<Button onClick={ props.close } disabled={ deletePending }>Annuler</Button>}
        />
    )
}
