import { PieceDTO } from 'api/documentAPI'
import useApiState from 'api/useApiState'
import { EXTERNAL_ID_SOR_NULL, VersementDetail, downloadVersementDoc, getVersementDocuments } from 'api/versementAPI'
import { AtlasButton, AtlasHeading, AtlasLoading, MyTable } from 'atlas-ds'
import { formatApiDate } from 'components/format/Format'
import { useEffect, useState } from 'react'

export default function DetailVersementDocuments(props: { versement: VersementDetail | null, versementPending: boolean }) {

    const [generating, setGenerating] = useState(false)
    const [{ pending: documentsPending, value: documents }, withGetVersementDocuments] = useApiState(getVersementDocuments)

    useEffect(() => {
        if (props.versement)
            withGetVersementDocuments(props.versement)
    }, [props.versement, withGetVersementDocuments])

    const downloadDoc = (document: PieceDTO, show: string) => {
        if (show === 'bordereau') {
            setGenerating(true)

            downloadVersementDoc(document).finally(() =>
                setGenerating(false)
            )
        }
    }

    const documentsActions = (document: PieceDTO) => [
        <AtlasButton
            key="telecharger"
            level={3}
            icon="download"
            spinner={{spinning: generating}}
            onClick={() => generating || downloadDoc(document, 'bordereau')}
        >Télécharger</AtlasButton>
    ]

    return (
        <div id="documents">
            <AtlasHeading tag="h2" size="m">Documents</AtlasHeading>
            <AtlasLoading loading={props.versementPending || documentsPending}>
                <MyTable
                    caption='Documents'
                    rows={documents ?? []}
                    emptyText={ props.versement?.ExternalID_Sor__c == null ? EXTERNAL_ID_SOR_NULL : "Aucun document" }
                    rowActions={documentsActions}
                    columns={[
                        {
                            id: "type",
                            label: "Type"
                        },
                        {
                            id: "fichier",
                            label: "Nom document",
                            wrap: true
                        },
                        {
                            id: "dateAjout",
                            label: "Date d'Ajout",
                            value: (row: any) => formatApiDate(row.dateAjout)
                        }
                    ]}
                />
            </AtlasLoading>
        </div>
    )
}
