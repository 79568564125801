import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossier } from 'slices/dossierCommon'


interface MaitreFieldsProps {
    metadata: FormMetadata<any>,
    name: string
    disabled?: boolean
    editForm?: boolean
}

export default function MaitreFields<FormObjectType extends BaseDossier>(props: MaitreFieldsProps) {
    return (<>
        <MyForm.Grid>
            <FormElements metadata={ props.metadata } section="" path={ props.name } type="input" disabled={ props.disabled } />
        </MyForm.Grid>
    </>)
}