import {
  AtlasCheckbox,
  AtlasHeading,
  AtlasIcon,
  AtlasIconButton,
  MyModal,
  MyModalProps,
} from "atlas-ds";
import classNames from "classnames";
import { ChangeEventHandler, MouseEventHandler } from "react";

export interface MyQuickAccessesModalProps extends MyModalProps {
  /**
   * Les instructions d'utilisation de l'éditeur
   */
  instructions: string;
  /**
   * Le nombre d'accès pouvant être sélectionnés au total
   */
  slotsCount: number;
  /**
   * Les accès rapides sélectionnés
   */
  selectedItems: {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }[];
  /**
   * Les accès rapides disponibles
   */
  availableItems: {
    label: string;
    onClick: ChangeEventHandler<HTMLInputElement>;
  }[];
}

/**
 * Une modale d'édition des d'accès rapides
 */
export function MyQuickAccessesModal(props: MyQuickAccessesModalProps) {
  const availableSlots = props.slotsCount - props.selectedItems.length;

  return (
    <MyModal {...props} large internalAlignActionToLeft>
      <div className="my-quickAccessesModal">
        <AtlasHeading tag="h3" size="s">
          {props.instructions}
        </AtlasHeading>

        <div className="my-quickAccessesModal__inner">
          <div className="my-quickAccessesModal__selectedAccesses">
            {props.selectedItems.map((item) => (
              <div
                key={item.label}
                className="my-quickAccessesModal__access my-quickAccessesModal__access--filled "
              >
                {item.label}
                <AtlasIconButton
                  ariaLabel={`Retirer l'accès rapide ${item.label}`}
                  onClick={item.onClick}
                >
                  <AtlasIcon name="close" size="s" />
                </AtlasIconButton>
              </div>
            ))}

            {[...Array(availableSlots)].map((_, index) => (
              <div
                key={`empty-${index}`}
                className="my-quickAccessesModal__access"
              >
                Non sélectionné
              </div>
            ))}
          </div>
          <div className="my-quickAccessesModal__selector">
            <p className="my-quickAccessesModal__title">Liens disponibles</p>
            <ul className="my-quickAccessesModal__options">
              {props.availableItems?.map((item) => {
                const isSelected = !!props.selectedItems.find(
                  (selectedItem) => selectedItem.label === item.label
                );

                return (
                  <li
                    key={item.label}
                    className={classNames("my-quickAccessesModal__option", {
                      "my-quickAccessesModal__option--checked": isSelected,
                    })}
                  >
                    <AtlasCheckbox
                      name={item.label}
                      value={item.label}
                      label={item.label}
                      disabled={availableSlots === 0 && !isSelected}
                      onChange={item.onClick}
                      checked={isSelected}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {props.children}
      </div>
    </MyModal>
  );
}
