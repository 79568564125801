import { getFieldState, useFormContext, UseFormReturn } from '@dsid-opcoatlas/reform'
import { formatDate } from 'components/format/Format'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossier, BaseNamedEntity } from 'slices/dossierCommon'
import { TextFieldProps } from './TextField'
import AutocompleteBaseField from './AutocompleteBaseField'

interface NomAutocompleteFieldProps<D extends BaseDossier, T extends BaseNamedEntity> extends TextFieldProps<D> {
    metadata: FormMetadata<D>
    search: (form: UseFormReturn<D>, recherche: string, metadata?: FormMetadata<D>) => Promise<T[]>
    parent: any
    parentPath: string
    editMode?: boolean
}

export default function NomAutocompleteField<D extends BaseDossier, T extends BaseNamedEntity>(props: NomAutocompleteFieldProps<D, T>) {

    const form = useFormContext<D>()
    const fieldState = getFieldState<string | null>(form, props.name)
    const normalizeSearchString = (text: string) => text.trim().replace(/ +/g, ' ').replace(/( *)?-+( *)?/g, '-').replace(/^-|-$/g, '')

    return (
        <AutocompleteBaseField<T>
            label={ props.label }
            defaultValue={ fieldState.value ?? '' }
            tooltip={ props.tooltip }
            disabled={ props.disabled }
            error={ fieldState.error }
            required={ fieldState.constraints.required }
            acceptInput={ value => (/^[ \-'A-Za-zÀ-ÖØ-öø-ž]*$/u).test(value) }
            onChange={ value => form.setValue(props.name, value || null) }
            onBlur={ value => form.setValue(
                props.name,
                normalizeSearchString(value) || null,
                true
            )}

            search={ async (recherche: string) => await props.search(form, recherche, props.metadata) }
            normalizeSearchString={ normalizeSearchString }
            
            optionFor={ result => ({
                id: result.IdContact!,
                content: result.nomNaissance + ' ' + result.prenom + ' - ' + formatDate(result.dateNaissance)
            })}
            onSelect={ value => form.setValue(props.parentPath, value, true) }
            hideNoResultsMessage
        />
    )
}