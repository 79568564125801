import styles from 'pages/dossier/cerfa/global.styles'
import { Style } from '@react-pdf/types'
import { Text } from '@react-pdf/renderer'
import { FieldMetadata } from 'pages/dossier/metadata/FormMetadata'

interface CerfaElementProps<T> {
    metadata: FieldMetadata<T>
    root?: any
    parent?: any
    value: any
    default?: string
    style?: Style | Style[]
    numberOnly?: boolean
}

export default function CerfaElement<T>(props: CerfaElementProps<T>) {
    const style = props.style ?? styles.user

    if (props.metadata.cerfa) {
        const Cerfa = props.metadata.cerfa!
        return <Cerfa style={ style } { ...props.metadata.props } root={ props.root } parent={ props.parent } 
            value={ props.value } numberOnly={ props.numberOnly } />
    }
    
    return <Text style={ style }>{ props.value ?? props.default ?? ' ' }</Text>
}
