import { getDiagnosticDocuments } from 'api/diagatlasAPI'
import { DocumentsSearchQuery, downloadDoc, PieceDTO } from 'api/documentAPI'
import { AtlasBreadcrumb, MyTableColumn } from 'atlas-ds'
import TelechargerDoc from 'components/docs/TelechargerDoc'
import { Filtre } from 'components/recherche/Filtres'
import RechercheBase, { singleTab } from 'components/recherche/RechercheBase'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import { displayDocumentDate, DocumentsSearchQueryImpl } from 'pages/mes-documents/MesDocuments'
import { Link } from 'react-router-dom'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'


export default function MonDiagnosticDocuments() {
    
    const { diagnostic } = useDiagnostic(null)
    useScrollToTop()

    const filtres: Filtre[] = []

    const colonnes = (_: DocumentsSearchQuery): MyTableColumn[] => [
        {
            id: "fichier",
            label: "Nom document",
            sortable: true,
            wrap: true,
        }, {
            id: "dateAjoutTechnique",
            label: "Date d'ajout",
            sortable: true,
            value: (row: any) => displayDocumentDate(row),
        },
    ]

    const actions = (document: PieceDTO) => {
        const actions = []
        actions.push(<TelechargerDoc downloadDoc={() => downloadDoc(document)} key="dl" />)
        return actions
    }

    const newQuery = (params: URLSearchParams) => new DocumentsSearchQueryImpl(params, "dateAjoutTechnique")

    return (<RechercheBase<PieceDTO, DocumentsSearchQueryImpl>
        name="MonDiagnosticDocuments"
        title="Diagnostics"
        newQuery={ newQuery }
        filtres={ filtres }
        colonnes={ colonnes }
        tabs={ singleTab(getDiagnosticDocuments) }
        tableActions={ actions }
        breadcrumb={<AtlasBreadcrumb currentPageLabel="Diagnostics exportés"
            linkConstructor={ (props, content) => <Link to={ props.href }>{ content }</Link> }
            parts={[{
                label: diagnostic ? (diagnostic?.statut === 'Diagnostic en cours' ? 'Diagnostic' : 'Actions préconisées') : '',
                href: "/mon-diagnostic",
            }]} />
        }
    />
    )
}
