import { AtlasButton } from 'atlas-ds'
import log from 'loglevel'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { DossierActionProps } from './DetailDossierContrat'


export default function DossierConsulterEcheancier(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const openSelfe = () => {
        const params = new URLSearchParams({ 'CodeDossier': dossier.NumeroDossier__c })
        const url = (import.meta.env.REACT_APP_SELFE_PROFORMA ?? '') + '/timeline?' + params.toString()
        log.debug('Opening url: ' + url)
        window.open(url, '_blank')
    }

    return <AtlasButton level={ 2 } onClick={ openSelfe }>Voir échéancier</AtlasButton>
}
