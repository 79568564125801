import { AtlasHeading, AtlasLoading, MyContainer } from "atlas-ds";

export interface MyCalculatorValue {
  /**
   * Le label
   */
  label: string;
  /**
   * La valeur
   */
  value: string | number;
  /**
   * Dans le cas d'un calcul dont les valeurs peuvent changer dynamiquement en
   * fonction de champs présents sur la page, cet attribut permet de lier la
   * valeur aux identifiants des champs concernés. Le format attendu est une
   * chaîne de caractères contenant un ou plusieurs `id` séparés par des
   * espaces.
   */
  outputFor?: string;
}

export interface MyCalculatorProps {
  /**
   * Le label présentant le calcul
   */
  label: string;
  /**
   * Ls colonnes de données
   */
  columns: {
    values: MyCalculatorValue[];
  }[];
}

/**
 * Le détail d'un calcul
 */
export function MyCalculator(props: MyCalculatorProps) {
  return (
    <div className="my-calculator">
      <MyContainer colored>
        <AtlasHeading tag="h3" size="s">
          {props.label}
        </AtlasHeading>
        <div className="my-calculator__rows">
          {props.columns.map((column, index) => (
            <div key={`row-${index}`}>
              {column.values.map((value) => {
                const content = (
                  <>
                    <span>{value.label}</span>
                    <AtlasLoading.Loader />
                    <span>{value.value}</span>
                  </>
                );

                return value.outputFor ? (
                  <output
                    key={value.label}
                    className="my-calculator__value"
                    htmlFor={value.outputFor}
                  >
                    {content}
                  </output>
                ) : (
                  <p key={value.label} className="my-calculator__value">
                    {content}
                  </p>
                );
              })}
            </div>
          ))}
        </div>
      </MyContainer>
    </div>
  );
}
