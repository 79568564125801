import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { createMonCompte } from "api/monCompteAPI"
import { AtlasInfo, MyForm } from "atlas-ds"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import CheckboxField from "components/reform/inputs/CheckboxField"
import PhoneField from "components/reform/inputs/PhoneField"
import RadioField from "components/reform/inputs/RadioField"
import TextField from "components/reform/inputs/TextField"
import { Turnstile, TurnstileErrorType } from "components/turnstile/Turnstile"
import { OptionalBoolean, RequiredBoolean, RequiredEmail, RequiredPhone, RequiredString } from "components/yop/constraints"
import log from 'loglevel'
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { createEmailState } from "./MamcApp"
import { MotDePasseBloc, MotDePassePropertiesSchemas, createMotDePasse } from "./MotDePasseBloc"

export const nil = (null as unknown)

// export function createCompte() { return {
//     civilite: "MR",//nil as string,
//     prenom: "John",//nil as string,
//     nom: "Doe",//nil as string,
//     email: "john.doe@gmail.com",//nil as string,
//     telephone: "0101010101",//nil as string,
//     motDePasse: "Abc12try!",//nil as string,
// }}
export function createCompte() {
    return {
        civilite: nil as string,
        prenom: nil as string,
        nom: nil as string,
        email: nil as string,
        telephone: nil as string,
        ...createMotDePasse(),
        abonnementNewsletter: nil as boolean | null,
        confirmeRgpd: nil as boolean,
    }
}
export type Compte = ReturnType<typeof createCompte>

export function CreerMonCompte() {
    const history = useHistory();
    const [turnstileError, setTurnstileError] = useState<TurnstileErrorType | null>(null);
    const [backendError, setBackendError] = useState<string | null>(null);
    const [turnstile, setTurnstile] = useState<{ execute: () => Promise<string> } | null>(null);

    const initialValues = createCompte();
    const validationSchema = Yop.object<Compte>({
        civilite: RequiredString,
        prenom: RequiredString.max(250),
        nom: RequiredString.max(250),
        email: RequiredEmail,
        telephone: RequiredPhone,
        ...MotDePassePropertiesSchemas,
        confirmeRgpd: RequiredBoolean.oneOf([true], "Vous devez accepter les conditions générales"),
        abonnementNewsletter: OptionalBoolean,
    });

    const handleSubmission = (context: UseFormReturn<Compte>) => {
        turnstile
            ?.execute()
            .then(token => {
                setTurnstileError(null);
                setBackendError(null);

                const compte = context.values!;

                createMonCompte({
                    civilite: compte.civilite,
                    prenom: compte.prenom,
                    nom: compte.nom,
                    email: compte.email,
                    telephone: compte.telephone,
                    password: compte.motDePasse,
                    abonnementNewsletter: compte.abonnementNewsletter,
                    token: token,
                })
                    .then(_ => {
                        history.replace({
                            pathname: "/creer-mon-compte-validation-email",
                            state: createEmailState(compte.email),
                        });
                    })
                    .catch(handleBackendError(context));
            })
            .catch(handleTurnstileError(context));
    };

    const handleBackendError = (context: UseFormReturn<Compte>) => (error: any) => {
        log.error("Erreur creation compte", error)
        const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
        setBackendError(message)
        context.setSubmitting(false)
    }

    const handleTurnstileError = (context: UseFormReturn<Compte>) => (error: any) => {
        log.error("Erreur creation compte turnstile", error)
        setTurnstileError(error.type)
        setBackendError(null)
        context.setSubmitting(false)
    }

    const form = useForm<Compte>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: handleSubmission,
    })

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    return (
        <>
            <PageHeader>Créer mon compte myAtlas en un clic !</PageHeader>

            <Form context={form} noValidate>
                <MyForm>
                    <MyForm.Grid>
                        <MyForm.Field alone>
                            <RadioField name="civilite" label="Civilité" options={[
                                { value: "1", label: "M." },
                                { value: "2", label: "Mme" }
                            ]} />
                        </MyForm.Field>

                        <TextField type="text" name="prenom" label="Prénom" maxLength={50} />
                        <TextField name="nom" label="Nom" maxLength={50} />
                        <TextField type="email" name="email" label="Email" />
                        <PhoneField name="telephone" label="Téléphone" />
                        <MotDePasseBloc />
                    </MyForm.Grid>

                    <CheckboxField name="abonnementNewsletter" label="Je souhaite m'abonner à la newsletter Atlas" />
                    <CheckboxField
                        name="confirmeRgpd"
                        label="J'accepte les conditions générales d'utilisation des services en ligne."
                        link={<Link href="/public/conditions-utilisation">
                            Lire les conditions générales d'utilisation
                        </Link>}
                    />

                    {turnstileError && (
                        <AtlasInfo type="error" title="Erreur de validation">
                            Suite à une erreur de validation de notre côté, nous vous invitons à réessayer ultérieurement. Veullez nous excuser pour la gêne occasionnée.
                        </AtlasInfo>
                    )}
                    {backendError && (
                        <AtlasInfo type="error" title="Erreur de validation">{ backendError }</AtlasInfo>
                    )}

                    <MyForm.Actions>
                        <Button
                            submit={true}
                            spinner={{ spinning: form.submitting }}
                            disabled={form.submitting || turnstile === null}
                        >Créer mon compte</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>

            <Turnstile setTurnstile={setTurnstile} />
        </>
    );
}