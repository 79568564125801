import { AtlasButton, AtlasInfo } from 'atlas-ds'
import { ConfirmationContentProps } from 'pages/dossier/metadata/FormMetadata'
import { useHistory } from 'react-router-dom'
import { DossierCAPP } from './CAPPModel'

const indicateurIntroduction = "Les éléments saisis dans le formulaire (Cerfa), ont été contrôlés automatiquement en vue d'assurer la conformité du contrat au regard des conditions de dépôt auprès des services du Ministère en charge de la formation professionnelle, et en s’appuyant sur différents référentiels en vue de garantir la qualité des données."


interface ConfirmationCAPPContentProps<D extends DossierCAPP> extends ConfirmationContentProps<D> {
    masquerConformite?: boolean
    masquerFMA?: boolean
}

export default function DossierCAPPConfirmation<D extends DossierCAPP>(props: ConfirmationCAPPContentProps<D>) {
    const history = useHistory()

    const gotoFma = () => {
        history.push('/nouveau-dossier-fma')
    }

    const dossier = props.dossier

    return <>
        { dossier?.indicateurConformite && props.masquerConformite !== true && <>
            { dossier?.indicateurConformite === 'Vert' && <AtlasInfo type="success" title="Contrôles automatiques valides">
                { indicateurIntroduction }<br/>
                Ce contrat, dont les données satisfont à ces contrôles, va pouvoir être engagé et déposé dans les plus brefs délais.<br/>
                A noter : les boutons "Corriger le Contrat" et "Annuler le dossier" seront accessibles dès le lendemain de la date d'envoi du dossier.<br/>
            </AtlasInfo> }

            { dossier?.indicateurConformite === 'Orange' && <AtlasInfo type="warning" title="Données à vérifier par l'OPCO Atlas">
                { indicateurIntroduction }<br/>
                Certaines données saisies de ce contrat nécessitent d'être vérifiées par un gestionnaire, Atlas reviendra vers vous sur les éventuelles corrections ou compléments nécessaires.
            </AtlasInfo> }

            <p>Nous vous rappelons que seules les données dématérialisées transmises sont prises en compte lors de l'instruction par l'OPCO Atlas et que
            ces données doivent impérativement correspondre au cerfa original conclu avec l'apprenti.</p>
        </>}

        { !props.masquerFMA && <>
            <p>Vous souhaitez une prise en charge de la formation maître d'apprentissage&nbsp;?</p>

            <AtlasButton level={ 2 } onClick={gotoFma}>
                Faire une demande de formation de maître d'apprentissage
            </AtlasButton>

            <p>
                Si votre contrat est éligible à l’aide exceptionnelle/unique de l’Etat, il sera transmis à l’ASP organisme en charge du paiement de ces aides.<br /><br />

                Quelques recommandations :<br />

                • Prendre le temps de bien paramétrer votre outil de paye afin que la DSN soit transmise sans soucis (correspondance avec les éléments du contrat, en particulier SIRET et identité de l’alternant, et bien déclarer l’apprenti dans la rubrique type de politique publique)<br /><br />
                • Vérifier votre messagerie électronique et les indésirables. L’agence de services et paiement (l’ASP), communique via un émetteur noe.noreply@asp-public.fr<br /><br />
                • Se connecter régulièrement sur le portail <a href="https://sylae.asp-public.fr/sylae/" target="_blank" rel="noreferrer noopener" >SYLAé</a> pour y déposer vos coordonnées de paiement, et consulter vos avis de paiement<br /><br />

                Dépôt des coordonnées de paiement sur SYLAé<br />

                • Lorsque le contrat sera validé par l’ASP, vous devrez vous connecter sur <a href="https://sylae.asp-public.fr" target="_blank" rel="noreferrer noopener" >https://sylae.asp-public.fr </a> pour :<br /><br />

                – Déposer et/ou modifier vos coordonnées de paiement (RIB)<br />
                – Rattacher votre dossier (le contrat) au RIB sur lequel vous souhaitez être payé<br />
                – Suivre les différents versements en récupérant les avis de paiements<br /><br />

                • 1 SIRET = 1 compte SYLAé (si votre SIRET change en cours de contrat vous disposerez d’un nouveau compte SYLAé)<br />
                • Si votre établissement n’a jamais bénéficié de connexion SYLAé, un code d’accès vous est envoyé par courrier postal (attention envoi non nominatif)<br />
                • Si vous avez déjà bénéficié d’une connexion SYLAé pour un autre contrat ou dispositif, vos informations de connexion sont les mêmes<br /><br />

                • Pour aller plus loin voir <a href="https://www.youtube.com/watch?v=lpGOlZ1jtg4" target="_blank" rel="noreferrer noopener" >la vidéo de connexion SYLAé</a>
            </p>
        </> }
    </>
}