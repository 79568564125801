import { UseFormReturn } from '@dsid-opcoatlas/reform'
import { AnySchema, ObjectSchema, SchemaType, Yop } from '@dsid-opcoatlas/yop'
import { PointReserve } from 'api/dossierAPI'
import { Profil } from 'api/profilAPI'
import { Entreprise } from 'api/referencesAPI'
import { AppDispatch } from 'app/store'
import { Ignored } from 'components/yop/constraints'
import { cloneDeepWith, isArray, toPath } from 'lodash'
import { RecapProps } from 'pages/dossier/recap/RecapProps'
import { BaseDossierComponentProps } from 'pages/dossier/useDossier'
import { ReactNode, useMemo } from 'react'
import { AppType } from 'slices/contextSlice'
import { BaseDossier, DossierPointReserve, DossierWithPointsReserve, PointReserveCheck } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'
import { HolderFieldMetadata } from '../../../components/metadata/HolderFieldMetadata'
import { FieldMapping } from './DossierMapping'
import { ClassConstructor, ExtendedPath } from 'components/elements/engine'


export const DOCTYPE_DPC = 'DPC'
export const DOCNAME_DPC = `${ DOCTYPE_DPC }.pdf`

export interface FieldMetadataElements {
    input?: (props?: any) => any
    recap?: (props?: any) => any
    export?: (props?: any) => any
    diff?: (obj1: any, obj2: any) => any
}

type FieldMetadataProps<T> = {
    label?: string | JSX.Element | ((context: ElementContext<any, any>, form?: UseFormReturn<any>) => string | JSX.Element)
    exportLabel?: string
    placeholder?: string
    tooltip?: string | JSX.Element
    disabled?: boolean | ((context: ElementContext<any, any>, form?: UseFormReturn<any>) => boolean)
    value?: ((context: ElementContext<any, any>) => string)
    onChange?: (value: T, form: UseFormReturn<any>, parentPath?: string) => void
    onBlur?: (value: T, form: UseFormReturn<any>, parentPath?: string, change?: boolean, editMode?: boolean) => void
    suffix?: string
    fractionDigits?: number
    successMessage?: ReactNode | ((context: ElementContext<any, any>, form?: UseFormReturn<any>) => ReactNode)
    infoMessage?: ReactNode | ((context: ElementContext<any, any>, form?: UseFormReturn<any>) => ReactNode)
    warningMessage?: ReactNode | ((context: ElementContext<any, any>, form?: UseFormReturn<any>) => ReactNode)
    full?: boolean
    alone?: boolean
}

export type FieldMetadataDependency = {
    stepName: string
    path: string
    field: FieldMetadata<any>
    visibleWith?: string | string[]
}

export interface FieldMetadata<T, P = {}> extends FieldMetadataElements {
    props?: FieldMetadataProps<T> & P,
    section?: string,
    visibleIf?: (context: ElementContext<any, any>) => boolean
    mapping?: FieldMapping<T>
    mappedValue?: (context: { root: any, parent: P, value: T }) => any
    convert?: (value: T) => any
    revert?: (value: any) => T
    equals?: (value1: T, value2: T) => boolean
    cerfa?: (props?: any) => any
    yop: SchemaType<AnySchema<T>>
    dependencies?: FieldMetadataDependency[]
    // schemaFor: <D extends BaseDossier = BaseDossier>(step: FormStep<D>, initialDossier?: D) => SchemaType<AnySchema<T>>
    required: (message?: string) => FieldMetadata<NonNullable<T>, P>
    mutate: (mutator: (yop: SchemaType<AnySchema<T>>) => SchemaType<AnySchema<T>>) => FieldMetadata<T, P>
}

export interface SettingsFieldMetadata<T, P = {}> extends Omit<FieldMetadata<T, P>, "yop" | "schemaFor" | "required" | "mutate"> {
}

export function createMetadata<T, P = {}>(initial: Partial<FieldMetadata<T, P>>, settings: SettingsFieldMetadata<T, P>): FieldMetadata<T, P> {
    return merge(
        {
            yop: Ignored,
            // schemaFor<D extends BaseDossier = BaseDossier>(step: FormStep<D>, initialDossier?: D) {
            //     return (this as unknown as FieldMetadata<T, P>).yop
            // },
            required(message?: string): FieldMetadata<NonNullable<T>, P> {
                const clone = cloneDeepWith(this, ignoreFrozenCustomizer)
                clone.yop = clone.yop?.required(message) as any
                return clone as FieldMetadata<NonNullable<T>, P>
            },
            mutate(mutator: (yop: SchemaType<AnySchema<T>>) => SchemaType<AnySchema<T>>): FieldMetadata<T, P> {
                const clone = cloneDeepWith(this, ignoreFrozenCustomizer)
                clone.yop = mutator(clone.yop!)
                return clone as FieldMetadata<T, P>
            },
            ...initial,
        },
        settings as FieldMetadata<T, P>,
    )
}

export function createEmptyMetadata<T = any, P = {}>() {
    return createMetadata({} as SettingsFieldMetadata<T, P>, {})
}

export interface ElementContext<P extends object, D extends BaseDossier = BaseDossier> {
    name: string
    key: string
    root: D
    parentPath: string | undefined
    parent: P | undefined
    index: number | undefined
    path: string
    value?: any
    metadata?: BaseFormMetadata<D>
    editMode?: boolean
    form?: UseFormReturn<D>
}

export type ObjectMetadata<T> = { [P in keyof T]: FieldMetadata<T[P]> }

// export const propertiesKey = Symbol("propertiesKey")

// export function createObjectMetadata<T extends object | null>(properties: ObjectMetadata<T>): FieldMetadata<T> {
//     const metadata = createMetadata(
//         {
//             schemaFor<D extends BaseDossier = BaseDossier>(step: FormStep<D>, initialDossier?: D) {
//                 // const property = step.yopPath
//                 // if (!property)
//                 //     return step.yopSchema

//                 const properties = (this as any)[propertiesKey] as { [x: string]: FieldMetadata<any> }
//                 const shape = {} as any
//                 for (const [key, fieldMetadata] of Object.entries(properties)) {
//                     if (fieldMetadata)
//                         shape[key] = fieldMetadata.schemaFor(step, initialDossier)
//                 }

//                 let propertySchema = Yop.object<any>(shape).required(step.options?.requiredMessage) as any
//                 if (step.yopPathSchemaAppender)
//                     propertySchema = step.yopPathSchemaAppender(propertySchema)            
//                 const schema = Yop.object<T>({
//                     [property]: propertySchema
//                 }).defined()
        
//                 return schema


//             }
//         },
//         {}
//     )

//     Object.defineProperty(metadata, propertiesKey, { value: properties })

//     return metadata
// }

export interface ExportProps<D extends BaseDossier> {
    label?: string
    exportDossier: (metadata: FormMetadata<D>, dossier: D) => Promise<JSX.Element>
    documentType: string
    documentName: string
    ignoredIf?: (dossier: D) => boolean
    afterSubmit?: boolean
}

export interface PreFormContentProps<D extends BaseDossier> {
    metadata: FormMetadata<D>
    dossier: D | null
    step: FormStep<D>
    loading?: boolean
    saving?: boolean
    navigationGuard: (next: () => void) => boolean
    form?: UseFormReturn<D>
}

export interface FormContentProps<D extends BaseDossier> {
    metadata: FormMetadata<D>
    step: FormStep<D>
    loading?: boolean
}

export interface ConfirmationContentProps<D extends BaseDossier> {
    metadata: FormMetadata<D>
    dossier: D | null
}

export type Bound = number | { value: number, message: string }

export type YopPath<D extends BaseDossier> = keyof Omit<D, 'Id' | 'IdHeroku' | 'NumeroDossier__c' | 'DispositifFO__c' | 'traitementEnCours'>

export interface FormStep<D extends BaseDossier> {
    title: string
    pathname?: string
    rubrique?: string
    path?: ExtendedPath
    yopPath?: YopPath<D> | YopPath<D>[]
    yopPathSchemaAppender?: ((s: ObjectSchema<any>, path: YopPath<D>) => ObjectSchema<any>)
    yopSchema?: (md: FormMetadata<D>) => ObjectSchema<any>
    sections: string[]
    form?: (props: BaseDossierComponentProps<D>) => JSX.Element | null
    editForm?: (props: BaseDossierComponentProps<D>) => JSX.Element
    recap?: (props: RecapProps<any>) => any
    initializer?: (dossier: D, metadata: FormMetadata<D>, profil: Profil | null, step: FormStep<D>) => D
    preFormContent?: (props: PreFormContentProps<D>) => JSX.Element | null
    formContent?: (props: FormContentProps<D>) => JSX.Element
    formButtons?: () => JSX.Element
    stepButton?: (props: { step: FormStep<D> }) => JSX.Element
    onSubmit?: (context: UseFormReturn<D>, metadata: FormMetadata<D>, dossier: D, dispatch: AppDispatch, entreprise?: Entreprise | null) => Promise<boolean>
    submitDisabled?: (dossier: D, pending: boolean, dossierInitial?: D | null, entreprise?: Entreprise | null) => boolean
    options?: {
        step0?: boolean
        attestationPieces?: boolean
        contact?: boolean
        noFormHeading?: boolean
        forceLoading?: boolean
        withPieces?: boolean
        excludePointsReserve?: boolean
        minElements?: Bound | ((dossier: D) => Bound | null)
        maxElements?: Bound | ((dossier: D) => Bound | null)
        noSaving?: boolean
        localSave?: boolean
        forceValidate?: (dossier: D) => boolean
    }
}

export interface BaseFormMetadata<T extends object> {
    title: string | ((object: T | null) => string)
    fields: ObjectMetadata<T>
}

export interface FormMetadata<D extends BaseDossier> extends BaseFormMetadata<D> {
    dispositif: string
    model?: ClassConstructor<D>
    pathname: string
    steps: FormStep<D>[]
    options?: {
        noToolbox?: boolean,
    },
    confirmationContent?: ((props: ConfirmationContentProps<D>) => JSX.Element | null) | null
    api: DossierAPI<D>
    toolbox?: (dossier: D) => JSX.Element
    exportProps: ExportProps<D>[]
    onErrorLink?: (link: string) => void
}

export function IgnoredFieldMetadata<T, P = any>(mapping?: FieldMapping<T>, mappedValue?: (context: { root: any, parent: P, value: T }) => any): FieldMetadata<T> {
    return merge(
        {
            yop: () => Ignored,
            input: undefined,
            recap: undefined,
            export: undefined,
        },
        { mapping, mappedValue },
    )
}

const metadataRegistry: { [x: string]: FormMetadata<BaseDossier> } = {}

const editMetadataRegistry: { [x: string]: FormMetadata<BaseDossier> } = {}

export const registerMetadata = <T extends BaseDossier>(appType: AppType, metadata: FormMetadata<T>) => {
    metadataRegistry[appType + '-' + metadata.dispositif] = metadata as FormMetadata<any>
    return metadata
}

export const registerEditMetadata = <T extends BaseDossier>(appType: AppType, metadata: FormMetadata<T>) => {
    editMetadataRegistry[appType + '-' + metadata.dispositif] = metadata as FormMetadata<any>
    return metadata
}

// En commentaire pour le moment, normalement useDossiersModuleContext doit être utilisé
/* export const getMetadata = (appType: AppType, dispositifId?: string) => {
    return dispositifId ? metadataRegistry[appType + '-' + dispositifId] ?? undefined : undefined
}

export const getEditMetadata = (appType: AppType, dispositifId?: string) => {
    return dispositifId ? editMetadataRegistry[appType + '-' + dispositifId] ?? undefined : undefined
} */

export function atField<D extends BaseDossier, T = any>(metadata: FormMetadata<D>, parentName: string, fieldName: string) {
    const parentMetadata = (metadata.fields as any)[parentName] as unknown as HolderFieldMetadata<any>
    return parentMetadata.fields[fieldName] as FieldMetadata<T>
}

export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T

export function merge<T = any>(dst: T, src: T, stack = new Map<any, any>()) {
    if (dst === src || src == null || Object.isFrozen(dst))
        return src
    
    if (stack.has(src))
        return stack.get(src)
    
    const dstTag = Object.prototype.toString.call(dst)
    const srcTag = Object.prototype.toString.call(src)
    if (dstTag !== srcTag || (dstTag !== '[object Object]' && dstTag !== '[object Array]'))
        return src

    stack.set(src, dst)
    Object.keys(src).forEach(key =>
        dst[key as keyof T] = merge(dst[key as keyof T], src[key as keyof T], stack)
    )
    stack.delete(src)
    
    return dst
}

function ignoreFrozenCustomizer(value: any) {
    return Object.isFrozen(value) ? value : undefined
}

export function override<T, P>(
    metadata: FieldMetadata<T, P>, override: DeepPartial<FieldMetadata<T, P>>): FieldMetadata<T, P> {
    return merge(cloneDeepWith(metadata, ignoreFrozenCustomizer), override as any)
}

export function ignored<T, P>(metadata: FieldMetadata<T, P>): FieldMetadata<T, P> {
    return merge(
        {
            yop: () => Ignored,
            input: undefined,
            recap: undefined,
            export: undefined,
        },
        { mapping: metadata.mapping },
    )
}

export function disabled<T, P>(metadata: FieldMetadata<T, P>, value = true): FieldMetadata<T, P> {
    return override(metadata, { props: { disabled: value } } as DeepPartial<FieldMetadata<T, P>>)
}

export function requiredIf<T, P>(metadata: FieldMetadata<T, P>, condition: boolean, message?: string): FieldMetadata<any, any> {
    return condition ? metadata.required(message) : metadata
}

export function getBoundValue(bound?: Bound | null) {
    if (bound == null)
        return NaN
    return typeof bound === 'number' ? bound : bound.value
}
function getBoundMessage(bound?: Bound | null) {
    if (bound == null || typeof bound === 'number')
        return undefined
    return bound.message
}

export function getYopSchema<D extends BaseDossier>(metadata: FormMetadata<D>, step: FormStep<D>, initialDossier?: D) {
    const yopPath = step.yopPath
    if (!yopPath) {
        return step.yopSchema ? step.yopSchema(metadata) : undefined
    }

    const properties = isArray(step.yopPath) ? (yopPath as YopPath<D>[]) : [ yopPath as YopPath<D> ]
    const schemaShape = {} as any
    const extraValidationShapes = {} as { [x: string]: any }

    for (const property of properties) {
        const fieldMetadata = metadata.fields[property as keyof ObjectMetadata<D>]
        if (fieldMetadata.hasOwnProperty('type')) {
            const holderMetadata = fieldMetadata as unknown as HolderFieldMetadata<any>
            const fieldsMetadata = holderMetadata.fields as { [x: string]: FieldMetadata<any> }
        
            const shape = {} as any
            
            for (const [key, fmd] of Object.entries(fieldsMetadata)) {
                if (fmd) {
                    const schema = fmd.yop
                    if (schema instanceof AnySchema) {
                        shape[key] = schema
                    }
                    else if (typeof schema === 'object') {
                        for (const [k, s] of Object.entries(schema)) {
                            shape[k] = s
                        }
                    }

                    fmd.dependencies?.forEach(dependency => {
                        const pathElements = toPath(dependency.path)
                        if (pathElements.length === 2) {
                            const parentPath = pathElements[0]
                            const fieldName = pathElements[1]
                            let parentShape = extraValidationShapes[parentPath]
                            if (parentShape == null)
                                parentShape = extraValidationShapes[parentPath] = {}
                            parentShape[fieldName] = dependency.field.yop
                        }
                    })
                }
            }
            
            let propertySchema = Yop.object<any>(shape)
            if (step.yopPathSchemaAppender) {
                propertySchema = step.yopPathSchemaAppender(propertySchema, property)
            } else if (holderMetadata.optional !== true) {
                propertySchema = propertySchema.required()
            }

            if (holderMetadata.type === 'array') {
                const minElements = initialDossier && (
                    (typeof step.options?.minElements === 'function') ?
                    step.options?.minElements(initialDossier) :
                    step.options?.minElements as Bound | null
                )
                const maxElements = initialDossier && (
                    (typeof step.options?.maxElements === 'function') ?
                    step.options?.maxElements(initialDossier) :
                    step.options?.maxElements as Bound | null
                )
    
                schemaShape[property] = Yop.array<any>(propertySchema)
                    .min(getBoundValue(minElements), getBoundMessage(minElements))
                    .max(getBoundValue(maxElements), getBoundMessage(maxElements))
            } else {
                schemaShape[property] = propertySchema
            }

        } else {
            const schema = fieldMetadata.yop
            if (schema instanceof AnySchema) {
                schemaShape[property] = schema
            }
            else if (typeof schema === 'object') {
                for (const [k, s] of Object.entries(schema)) {
                    schemaShape[k] = s
                }
            }
        }
    }

    for (const [key, shape] of Object.entries(extraValidationShapes))
        schemaShape[key] = Yop.object<any>(shape)
    
    const schema: ObjectSchema<D> = Yop.object<any>(schemaShape)
    return schema
}

export function useYopSchema<D extends BaseDossier>(metadata: FormMetadata<D>, step: FormStep<D>, initialDossier?: D) {

    const memoSchema = useMemo(() => {
        return getYopSchema<D>(metadata, step, initialDossier)
    }, [metadata, step, initialDossier])

    return memoSchema
}

export function useSimpleYopSchema<T extends object>(metadata: BaseFormMetadata<T>, initialObject?: T) {

    const memoSchema = useMemo(() => {
        const fieldsMetadata = metadata.fields as { [x: string]: FieldMetadata<any> }
    
        const shape = {} as any
        
        for (const [key, fmd] of Object.entries(fieldsMetadata)) {
            if (fmd) {
                const schema = fmd.yop
                if (schema instanceof AnySchema) {
                    shape[key] = schema
                }
                else if (typeof schema === 'object') {
                    for (const [k, s] of Object.entries(schema)) {
                        shape[k] = s
                    }
                }
            }
        }
        
        return Yop.object<any>(shape)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metadata])

    return memoSchema
}


export const mapDTOToPointsReserve = (dtoPointsReserve: PointReserve[] | null): DossierPointReserve[] | null => {
    let pointsReserve: DossierPointReserve[] | null = null
    if (dtoPointsReserve && dtoPointsReserve.length > 0) {
        pointsReserve = dtoPointsReserve?.map(r => ({
            id: r.Id,
            type: r.Type,
            rubrique: r.Rubrique,
            label: r.CommentaireExtranet ?? '',
            message: r.Message ?? '',
        }) as DossierPointReserve) ?? []
    }
    return pointsReserve
}

export function validatePointsReserve<D extends BaseDossier & DossierWithPointsReserve>(dossier: D, pointsReserve: PointReserveCheck<D>[]) {
    pointsReserve.forEach(pr => {
        const validationErrors = pr.schema.validate(dossier)
        validationErrors.forEach(validationError => {
            if (!dossier.pointsReserve)
                dossier.pointsReserve = []
            dossier.pointsReserve.push({
                id: pr.id,
                type: pr.type ?? 'reserve',
                rubrique: pr.rubrique,
                label: '',
                message: validationError.message,
            })
        })
    })
}