import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { loadDossierProfessionnalisation } from 'api/dossierAPI'
import { DISPOSITIF_CONTRAT_PROFESSIONNALISATION, TYPE_MODIFICATION_AVENANT } from 'api/references'
import { ObjectFieldMetadata, OptionalObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { DossierAPI } from 'slices/dossierSlice'
import { FormStep, IgnoredFieldMetadata, ObjectMetadata, override, registerEditMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierModification, nil, normalizePiecesDTO } from 'slices/dossierCommon'
import { MetadataCPRO, mapDTOToDossierCPRO } from './CPROFormMetadata'
import { CPROContratMetadata, SECTION_CPRO_CONTRAT } from './CPROContratMetadata'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { AppType } from 'slices/contextSlice'
import { DossierCPRO, createDossierCPRO, createDossierCPROTuteur, createDossierCPROSalarie, DossierCPROContrat, createDossierCPROModule } from './CPROModel'
import { CPROEmployeurMetadata } from './CPROEmployeurMetadata'
import { CPROSalarieMetadata } from './CPROSalarieMetadata'
import { CPROModuleAFESTMetadata, CPROModulePrincipalMetadata } from './CPROFormationMetadata'
import { CPROTuteurMetadata } from './CPROTuteurMetadata'
import { salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { OptionalString, RequiredDate } from 'components/yop/constraints'
import { dateMapping, simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import { adresseMapping } from 'components/metadata/AdresseFieldMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { isAfter, isBefore } from 'date-fns'
import { onDossierModificationRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { isEqual } from 'lodash'
import { References } from 'api/referencesAPI'


function createEditDossierCPRO() { return {
    ...createDossierCPRO(),

    wasAvenant: nil as boolean,
    typeModificationContrat: nil as string,
    numModificationContrat: nil as number,
}}
export type EditDossierCPRO = ReturnType<typeof createEditDossierCPRO> & DossierCPRO & DossierModification


const api: DossierAPI<EditDossierCPRO> = {
    isEdit: true,
    create: createEditDossierCPRO,
    createSalarie: createDossierCPROSalarie,
    createTuteur: createDossierCPROTuteur,
    createModule: createDossierCPROModule,
    load: async (dossierId: string, storedState?: EditDossierCPRO | null) => {
        if (storedState)
            return storedState
        const dossierDTO = await loadDossierProfessionnalisation(dossierId)
        const loadedDossier = mapDTOToDossierCPRO(MetadataEditCPRO, dossierDTO)
        return loadedDossier
    },
    loadWithPieces: async (dossierId: string, storedState?: EditDossierCPRO | null) => {
        let loadedDossier = storedState
        if (!storedState) {
            const dossierDTO = await loadDossierProfessionnalisation(dossierId)
            loadedDossier = mapDTOToDossierCPRO(MetadataEditCPRO, dossierDTO)
        } else {
            loadedDossier = { ...storedState }
        }
        const pieces = await loadAndMergeDossierPieces(dossierId, [])
        loadedDossier.pieces = normalizePiecesDTO(pieces)
        return loadedDossier
    },
    postEdit: async (dossier: EditDossierCPRO) => {
        dossier.wasAvenant = AVENANT_CONTRAT_TYPES.includes(dossier.contrat.typeContrat)
        if (dossier.typeModificationContrat === TYPE_MODIFICATION_AVENANT && !dossier.wasAvenant)
            dossier.contrat.typeContrat = ''
    },
    save: async (dossier: EditDossierCPRO) => {
        return await new Promise<EditDossierCPRO>(resolve => setTimeout(() => resolve(dossier), 200))
    },
    send: async (dossier: EditDossierCPRO) => {
        // Non utilisé normalement
        return dossier
    },
    postSend: async (dossier: EditDossierCPRO) => {
        await postSendDossier(MetadataEditCPRO, dossier)
    },
}


const EditCPROStagiaireMetadata = {
    ...CPROSalarieMetadata,
    nir: salarieFields.nir,
    niveauMaximumClasseEntreeFormationAgora: salarieFields.niveauMaximumClasseEntreeFormationAgora.mutate(_ => OptionalString as any)
} as any

export const EditCPROModulePrincipalMetadata = {
    ...CPROModulePrincipalMetadata,
    modalitePedagogiqueAgora: IgnoredFieldMetadata(simpleMapping('ModalitePedagogiqueAgora__c')),
    adresseRealisationIdentiqueAgora: IgnoredFieldMetadata(simpleMapping('AdresseRealisationIdentiqueAgora__c')),
    lieuPrincipalFormationAgora: IgnoredFieldMetadata(adresseMapping(
        'LieuPrincipalFormationAdresse1Agora__c', 'LieuPrincipalFormationAdresse2Agora__c', 
        'LieuPrincipalFormationAdresse3Agora__c', 'LieuPrincipalFormationAdresse4Agora__c', 
        'LieuPrincipalFormationCodePostalAgora__c', 'LieuPrincipalFormationCommuneAgora__c',
    ))
} as any

export const EditCPROModuleAFESTMetadata = {
    ...CPROModuleAFESTMetadata,
    modalitePedagogiqueAgora: IgnoredFieldMetadata(simpleMapping('ModalitePedagogiqueAgora__c')),
    adresseRealisationIdentiqueAgora: IgnoredFieldMetadata(simpleMapping('AdresseRealisationIdentiqueAgora__c')),
    lieuPrincipalFormationAgora: IgnoredFieldMetadata(adresseMapping(
        'LieuPrincipalFormationAdresse1Agora__c', 'LieuPrincipalFormationAdresse2Agora__c', 
        'LieuPrincipalFormationAdresse3Agora__c', 'LieuPrincipalFormationAdresse4Agora__c', 
        'LieuPrincipalFormationCodePostalAgora__c', 'LieuPrincipalFormationCommuneAgora__c',
    ))
} as any

const AVENANT_CONTRAT_TYPES = ['5']

export const EditCPROContratMetadata = {
    ...CPROContratMetadata,
    typeContrat: override(CPROContratMetadata.typeContrat, { props: {
        options: (refs: References, _?: any, root?: EditDossierCPRO) => {
            return root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT || root?.wasAvenant
                ? refs.REF_TYPE_CONTRAT.filter(ref => AVENANT_CONTRAT_TYPES.includes(ref.Id))
                : refs.REF_TYPE_CONTRAT.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('2'))
        },
    } as any }),
    
    dateDebut : override(CPROContratMetadata.dateDebut, { props: { 
        disabled: (ctx: { root: { typeModificationContrat: string } }) => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? true : false, 
    } as any }),

    dateConclusion: override(CPROContratMetadata.dateConclusion.mutate(_ => RequiredDate), { props: { 
        disabled: (ctx: { root: { typeModificationContrat: string } }) => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? true : false, 
    } as any }),
    dateEffet: DateFieldMetadata({
        section: SECTION_CPRO_CONTRAT,
        props: {
            label: ctx => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? "Date d'effet de l'avenant" : "Date d'effet de la correction", 
        },
        mapping: dateMapping('DateEffetAvenantContrat__c'),
    }).required().mutate(yop => yop
        .test<DossierCPROContrat>(ctx => {
            const dateDebut = ctx.parent?.dateDebut
            const dateEffet = ctx.value
            if (!dateDebut || !dateEffet)
                return true
            
            if (ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT) {
                if (isBefore(dateEffet, dateDebut))
                    return ctx.createError("La date d'effet doit être postérieure à la date de début du contrat")
            }
            else if (isAfter(dateEffet, dateDebut))
                return ctx.createError("La date d'effet doit être antérieure à la date de début du contrat")
            
            return true
        })
    ),
    dateConclusionAvenant: DateFieldMetadata({
        section: SECTION_CPRO_CONTRAT,
        props: {
            label: "Date de conclusion de l'avenant",
        },
        visibleIf: ctx => ctx.root.typeModificationContrat === TYPE_MODIFICATION_AVENANT,
    }).mutate(yop => yop
        .requiredIf(ctx => ctx.root.typeModificationContrat === TYPE_MODIFICATION_AVENANT)
        .test<DossierCPROContrat>(ctx => {
            const dateDuJour = new Date();
            const dateConclusionAvenant = ctx.value
            const dateEffet = ctx.parent?.dateEffet
            if (!dateEffet || !dateConclusionAvenant)
                return true
            
            if (isAfter(dateConclusionAvenant, dateEffet))
                return ctx.createError("La date de conclusion de l'avenant doit être antérieure à la date d'effet")
               
            if (isAfter(dateConclusionAvenant, dateDuJour)) {
                    return ctx.createError("La date de conclusion de l'avenant doit être  égale ou antérieure à la date du jour de la modification")
                }
            const dateConclusion = ctx.parent?.dateConclusion
            if (dateConclusion && isBefore(dateConclusionAvenant, dateConclusion))
                return ctx.createError("La date de conclusion (avenant) doit être postérieure à la date de conclusion")

            return true
        })
    )
} as any

type EditDossierCPROLight = Omit<EditDossierCPRO, 'pieces' | 'pointsReserve' | 'indicateurConformite'
    | 'wasAvenant' | 'typeModificationContrat' | 'numModificationContrat' | 'nbModificationsContrat' | 'etatModificationContrat' | 'attestationMandat'>
export const EditCPROMetadataLight: ObjectMetadata<EditDossierCPROLight> = {
    employeur: ObjectFieldMetadata(CPROEmployeurMetadata, 'Employeur'),
    salarie: ObjectFieldMetadata(EditCPROStagiaireMetadata, 'Apprenti(e)'),
    modulePrincipal: ObjectFieldMetadata(EditCPROModulePrincipalMetadata, 'Module principal'),
    moduleAfest: OptionalObjectFieldMetadata(EditCPROModuleAFESTMetadata, 'Module AFEST'),
    tuteur: ObjectFieldMetadata(CPROTuteurMetadata, 'Tuteur'),
    contrat: ObjectFieldMetadata(EditCPROContratMetadata, 'Contrat'),
    signature: MetadataCPRO.fields.signature,
}
const EditCPROMetadata = EditCPROMetadataLight as ObjectMetadata<EditDossierCPRO>


export const MetadataEditCPRO = registerEditMetadata<EditDossierCPRO>(AppType.Entreprise, {
    dispositif: DISPOSITIF_CONTRAT_PROFESSIONNALISATION,
    title: (dossier: EditDossierCPRO | null) => (dossier?.typeModificationContrat ?? 'Modification') + " d'un contrat de professionnalisation",
    pathname: '/modifier-dossier-professionnalisation',
    steps: MetadataCPRO.steps.map(s => { 
        if (s.rubrique === 'APPRENTI') {
            return { ...s, sections: [] } as FormStep<EditDossierCPRO>
        } else if (s.rubrique === 'CONTRAT') {
             return { ...s,
                 // sections: s.sections.concat(SECTION_CPRO_CONTRAT_MODIFICATION),
                 submitDisabled: (dossier, pending, initialDossier) => isEqual(initialDossier, dossier),
             } as FormStep<EditDossierCPRO>
        } else if (s.rubrique === 'RECAP') {
            return { ...s,
                onSubmit: onDossierModificationRecapitulatifSubmit,
            } as FormStep<EditDossierCPRO>
        }
        return { ...s } as FormStep<EditDossierCPRO>
    }),
    confirmationContent: null,
    api,
    fields: EditCPROMetadata,
    exportProps: MetadataCPRO.exportProps as any,
})
