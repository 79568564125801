import { Dossier, deleteDossier } from 'api/dossierAPI'
import { MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BaseDossier } from 'slices/dossierCommon'

interface DeleteModalProps {
    dossier: BaseDossier | Dossier | null
    close?: () => void
}


export default function DeleteModal(props: DeleteModalProps) {

    const [deletePending, setDeletePending] = useState<boolean>(false)

    const history = useHistory()

    const handleDelete = () => {
        setDeletePending(true)

        deleteDossier(props.dossier!.NumeroDossier__c!).then(_ => {
            ToastService.showToast({
                label: 'Action de suppression du brouillon',
                type: 'success',
                content: 'Le brouillon a été supprimé avec succès.'
            })
            history.push({
                pathname: '/mes-dossiers-contrats'
            })
        }).finally(() => {
            props.close?.()
        })
    }

    return (
        <MyModal
            label="Voulez-vous vraiment supprimer ce brouillon ?"
            onClose={ props.close }
            okButton={<Button
                destructive
                onClick={ handleDelete }
                spinner={{ spinning: deletePending }}
                disabled={ deletePending }
            >Supprimer</Button>}
            cancelButton={<Button onClick={ props.close } disabled={ deletePending }>Annuler</Button>}
        />
    )
}
