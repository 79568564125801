import { AtlasColumns, AtlasHtml } from "atlas-ds"
import PageHeader from "components/PageHeader"
import { Link } from 'react-router-dom'

export default function Accessibilite () {
  return <>
      <PageHeader>Déclaration d'accessibilité</PageHeader>

      <AtlasColumns>
        <AtlasHtml>
          <p>Atlas s’engage à rendre ses services en ligne accessibles conformément à l’article 47 de la loi n°&nbsp;2005-102 du 11 février 2005.</p>

          <p>A cette fin, il met en œuvre la stratégie et les actions suivantes : <Link to="/accessibilite/plan-action">voir le plan d'action</Link>.</p>

          <p>Cette déclaration d’accessibilité s’applique au service en ligne myAtlas.</p>

          <h2>État de conformité</h2>

          <p>Le service en ligne myAtlas est en conformité partielle avec le référentiel général d’amélioration de l’accessibilité en raison des non-conformités énumérées ci-dessous.</p>

          <h2>Résultats des tests</h2>

          <p>L’audit de conformité réalisé en interne révèle que :</p>

          <ul>
            <li>65% des critères RGAA sont respectés.</li>
            <li>Le taux moyen de conformité du service en ligne s’élève à 88%.</li>
          </ul>

          <p>
            <a href="/rgaa-audit.ods">Télécharger le rapport de test au format ods</a>
          </p>

          <h2>Contenus non accessibles</h2>

          <p>Les contenus listés ci-dessous ne sont pas accessibles pour les raisons suivantes.</p>

          <h3>Non conformité</h3>

          <p>Note : ces problèmes sont progressivement résolus depuis l'établissement de cette déclaration. La liste sera mise à jour lors du prochain audit.</p>

          <ul>
            <li>
              <q>Critère 1.3 : Pour chaque image porteuse d'information ayant une alternative textuelle, cette alternative est-elle pertinente (hors cas particuliers) ?</q>
              <p>La page de connexion ne respecte pas ce critère.</p>
            </li>
            <li>
              <q>Critère 5.7 : Pour chaque tableau de données, la technique appropriée permettant d'associer chaque cellule avec ses en-têtes est-elle utilisée (hors cas particuliers) ?</q>
              <p>Les différents tableaux du site ne respectent pas ce critère.</p>
            </li>
            <li>
              <q>Critère 7.1 : Chaque script est-il, si nécessaire, compatible avec les technologies d'assistance ?</q>
              <p>Une partie des composants interactifs du site ne sont pas correctement restitué par les technologies d'assistance, notamment les fonctionnalités de filtrage de tableaux.</p>
            </li>
            <li>
              <q>Chaque script est-il contrôlable par le clavier et par tout dispositif de pointage (hors cas particuliers)  ?</q>
              <p>Une partie des composants interactifs du site ne sont pas contrôlable au clavier, notamment les sélecteurs de plages de dates.</p>
            </li>
            <li>
              <q>Critère 9.1 : Dans chaque page web, l'information est-elle structurée par l'utilisation appropriée de titres ?</q>
              <p>La page de connexion ne respecte pas ce critère.</p>
            </li>
            <li>
              <q>Critère 9.2 : Dans chaque page web, la structure du document est-elle cohérente (hors cas particuliers) ?</q>
              <p>La page de connexion ne respecte pas ce critère.</p>
            </li>
            <li>
              <q>Critère 9.3 : Dans chaque page web, chaque liste est-elle correctement structurée ?</q>
              <p>Le site contient des informations regroupées visuellement sous forme de liste mais qui ne sont pas organisées grâce aux éléments HTML appropriés.</p>
            </li>
            <li>
              <q>Critère 10.3 : Dans chaque page web, l'information reste-t-elle compréhensible lorsque les feuilles de styles sont désactivées ?</q>
              <p>Les tableaux de données complexes (dossiers, factures, documents...) sont difficilement compréhensibles sans le CSS associé.</p>
            </li>
            <li>
              <q>Critère 10.13 : Dans chaque page web, les contenus additionnels apparaissant à la prise de focus ou au survol d'un composant d'interface sont-ils contrôlables par l'utilisateur (hors cas particuliers ) ?</q>
              <p>Les boutons d'actions devenant visibles à la prise de focus d'une ligne de tableau de données ne peuvent pas être masqués par une action utilisateur sans déplacer le focus ou le pointeur de la souris.</p>
            </li>
            <li>
              <q>Critère 11.5 : Dans chaque formulaire, les champs de même nature sont-ils regroupés, si nécessaire ?</q>
              <p>Les boutons radios et cases à cocher qui devraient être regroupés n'utilisent pas les balises HTML appropriées.</p>
            </li>
            <li>
              <q>Critère 11.10 : Dans chaque formulaire, le contrôle de saisie est-il utilisé de manière pertinente (hors cas particuliers) ?</q>
              <p>Les messages d'erreur ne sont pas sémantiquement liés aux champs concernés.</p>
            </li>
            <li>
              <q>Critère 11.11 : Dans chaque formulaire, le contrôle de saisie est-il accompagné, si nécessaire, de suggestions facilitant la correction des erreurs de saisie ?</q>
              <p>Certains champs de formulaire ne proposent pas d'exemples de valeurs attendues en cas d'erreur de saisie.</p>
            </li>
            <li>
              <q>Critère 11.12 : Pour chaque formulaire qui modifie ou supprime des données, ou qui transmet des réponses à un test ou à un examen, ou dont la validation a des conséquences financières ou juridiques, les données saisies peuvent-elles être modifiées, mises à jour ou récupérées par l’utilisateur ?</q>
              <p>La page Stratégie + permet d'effectuer un versement sans mécanisme de confirmation ou d'annulation.</p>
            </li>
            <li>
              <q>Critère 11.13 : La finalité d'un champ de saisie peut-elle être déduite pour faciliter le remplissage automatique des champs avec les données de l'utilisateur ?</q>
              <p>La page de connexion n'utilise pas le mécanisme d'autocomplétion des navigateurs.</p>
            </li>
            <li>
              <q>Critère 12.1 : Chaque ensemble de pages dispose-t-il de deux systèmes de navigation différents, au moins (hors cas particuliers) ?</q>
              <p>Le menu principal est l'unique moyen de navigation.</p>
            </li>
            <li>
              <q>Critère 12.6 : Les zones de regroupement de contenus présentes dans plusieurs pages web (zones d'en-tête, de navigation principale, de contenu principal, de pied de page et de moteur de recherche) peuvent-elles être atteintes ou évitées ?</q>
              <p>Les pages du site ne respectent pas ce critère.</p>
            </li>
            <li>
              <q>Critère 12.8 : Dans chaque page web, l'ordre de tabulation est-il cohérent ?</q>
              <p>La page des conditions générales d'utilisation permet de tabuler vers un contenu invisible.</p>
            </li>
            <li>
              <q>Critère 12.9 : Dans chaque page web, la navigation ne doit pas contenir de piège au clavier. Cette règle est-elle respectée ?</q>
              <p>Le sélecteur de plage de dates présente un piège au clavier.</p>
            </li>
            <li>
              <q>Critère 12.11 : Dans chaque page web, les contenus additionnels apparaissant au survol, à la prise de focus ou à l'activation d'un composant d'interface sont-ils, si nécessaire, atteignables au clavier ?</q>
              <p>Les boîtes d'aide au remplissage des formulaires ne sont pas atteignables au clavier.</p>
            </li>
            <li>
              <q>Critère 13.3 : Dans chaque page web, chaque document bureautique en téléchargement possède-t-il, si nécessaire, une version accessible (hors cas particuliers) ?</q>
              <p>Les documents en téléchargement ne possèdent pas de version accessible.</p>
            </li>
          </ul>

          <h2>Établissement de cette déclaration d'accessibilité</h2>

          <p>Cette déclaration a été établie le <time dateTime="22-02-28">28 février 2022</time>.</p>
          {/* A la prochaine modification, ajouter : Elle a été mise à jour le XXX */}

          <h3>Technologies utilisées pour la réalisation du site web :</h3>

          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>Javascript</li>
            <li>React</li>
          </ul>

          <h3>Agents utilisateurs, technologies d’assistance et outils utilisés pour vérifier l’accessibilité</h3>

          <p>Les tests des pages web ont été effectués avec les combinaisons de navigateurs web et lecteurs d’écran suivants :</p>

          <ul>
            <li>Chrome 99 et Orca</li>
          </ul>

          <p>Les outils suivants ont été utilisés lors de l’évaluation :</p>

          <ul>
            <li>Inspection manuelle des pages et du code source</li>
            <li>Plugin Axe DevTools</li>
            <li>Plugin Taiko Accessibilite</li>
            <li>Validateur HTML W3C</li>
          </ul>

          <p>Pages du site ayant fait l’objet de la vérification de conformité :</p>

          <ul>
            <li>Accueil</li>
            <li>Contact</li>
            <li>Sujet d'aide</li>
            <li>CGU</li>
            <li>Accessibilité</li>
            <li>Page de connexion</li>
            <li>Alternance, Développement des compétences et Conseil RH</li>
            <li>Stratégie +</li>
            <li>Dossiers et Factures</li>
            <li>Détail d'un dossier et détail d'une facture</li>
            <li>Formulaire de transmission d'un contrat d'apprentissage</li>
          </ul>

          <h2>Retour d'information et contact</h2>

          <p>Si pour des raisons d’accessibilité vous n’arrivez pas à atteindre des contenus et services compréhensibles et utilisables vous pouvez contacter la <Link to="services-assistance">web assistance</Link> pour qu’une alternative puisse être identifiée afin de corriger l’accessibilité ou obtenir le contenu sous une autre forme ou format.</p>

          <h2>Voie de recours</h2>

          <p>Cette procédure est à utiliser dans le cas suivant.</p>

          <p>Vous avez signalé au responsable du site internet un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services du portail et vous n’avez pas obtenu de réponse satisfaisante.</p>

          <ul>
            <li>
              <a href="https://formulaire.defenseurdesdroits.fr/">Écrire un message au Défenseur des droits</a>
            </li>
            <li>
              <a href="https://www.defenseurdesdroits.fr/saisir/delegues">Contacter le délégué du Défenseur des droits dans votre région</a>
            </li>
            <li>
              Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) Défenseur des droits Libre réponse 71120 75342 Paris CEDEX 07
            </li>
          </ul>
      </AtlasHtml>
    </AtlasColumns>
  </>
}
