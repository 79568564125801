import classNames from "classnames";
import { MouseEventHandler } from "react";

export interface MyStepsProps {
  /**
   * Les étapes du formulaire
   */
  children: React.ReactElement<MyStepsItemProps>[];
  /**
   * L'orientation de la série d'étapes (en ligne par défaut). Attention: en
   * mode ligne, les étapes seront cachées en cas de place insuffisante (petits
   * écrans)
   */
  direction?: "row" | "column";
}

/**
 * Un indicateur de progression dans les étapes d'un formulaire
 */
export function MySteps(props: MyStepsProps) {
  return (
    <nav
      className={classNames("my-steps", {
        "my-steps--column": props.direction === "column",
      })}
    >
      <ol>
        {props.children.map((child, index) => (
          <li key={`step-${index}`}>{child}</li>
        ))}
      </ol>
    </nav>
  );
}

export interface MyStepsItemProps {
  /**
   * Le nom de l'étape
   */
  children: string;
  /**
   * Le lien vers l'étape. Un lien vers `#` sera utilisé si la valeur n'est pas
   * fournie (gestion du click en JavaScript)
   */
  href?: string;
  /**
   * Action exécutée au clic
   */
  onClick?: MouseEventHandler;
  /**
   * S'agit-il de l'étape actuellement consultée ?
   */
  active?: boolean;
  /**
   * Désactiver cette étape
   */
  disabled?: boolean;
}

/**
 * Une étape de formulaire
 */
MySteps.Item = (props: MyStepsItemProps) => {
  return (
    <div
      className={classNames("my-steps__item", {
        "my-steps__item--active": props.active,
        "my-steps__item--disabled": props.disabled,
      })}
    >
      {props.disabled ? (
        <span className="my-steps__link">{props.children}</span>
      ) : (
        <a
          className="my-steps__link"
          href={props.href || "#"}
          onClick={props.onClick}
          aria-current={props.active ? "step" : undefined}
        >
          {props.children}
        </a>
      )}
      <div className="my-steps__symbol" aria-hidden="true"></div>
    </div>
  );
};
