import { Text, View } from '@react-pdf/renderer';
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierCPROTuteur } from 'pages/dossier-cpro/CPROModel';
import { CPROTuteurMetadata } from 'pages/dossier-cpro/CPROTuteurMetadata';
import CerfaElement from 'pages/dossier/cerfa/CerfaElement';

export default function Tuteurs({ tuteur }: { tuteur?: DossierCPROTuteur | null }) {

  function Tuteur({ label, tuteur, emptyLineAfterLabel }: { label: string, tuteur?: DossierCPROTuteur | null, emptyLineAfterLabel?: boolean }) {
    return (
      <>
        <Text style={styles.bold}>{label}</Text>
        {emptyLineAfterLabel && <Text> </Text>}
        <Text style={styles.bold}>
          Nom :{' '}
        </Text>
        <CerfaElement metadata={ CPROTuteurMetadata.nom } value={ tuteur?.nom } />
        <Text style={styles.bold}>
          Prénom :{' '}
        </Text>
        <CerfaElement metadata={ CPROTuteurMetadata.prenom } value={ tuteur?.prenom } />
        <Text>Emploi occupé :{' '}
        </Text>
        <CerfaElement metadata={ CPROTuteurMetadata.emploiOccupe } value={ tuteur?.emploiOccupe } />
        <Text>
          Date de naissance :{' '}
        </Text>
        <CerfaElement metadata={ CPROTuteurMetadata.dateNaissance } value={ tuteur?.dateNaissance } />
      </>
    );
  }

  return (
    <View style={[styles.section, styles.pageLastSection]}>
      <Text style={styles.sectionHeaderV4}>LE TUTEUR</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Tuteur label="Tuteur au sein de l'établissement employeur" emptyLineAfterLabel tuteur={tuteur} />
          </View>
          <View style={styles.colRight}>
            <Tuteur label="Tuteur de l'entreprise utilisatrice, si travail temporaire ou Groupement d'employeur" />
            <Text>
              Code activité de l’entreprise utilisatrice (NAF) * :{' '}
            </Text>
            <Text style={styles.info}>
              *à renseigner dans le cadre d'un contrat de professionnalisation
              associant des actions de validation des acquis de l'expérience
              mentionné à l'article 11 de la loi n° 2022-1598 du 21 décembre
              2022
            </Text>
          </View>
        </View>
        <Text> </Text>
        <View style={styles.sectionContent}>
          <CerfaElement metadata={ CPROTuteurMetadata.eligibilite } value={ tuteur?.eligibilite ?? '' } />
        </View>
      </View>
    </View>
  );
}
