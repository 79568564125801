import { getStagiaires, SessionFormation, StagiaireSession, StagiairesSearchQuery } from 'api/formationAPI'
import { MyTableColumn } from "atlas-ds"
import { formatDate } from 'components/format/Format'
import Link from "components/Link"
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import RechercheBase, { singleTab } from 'components/recherche/RechercheBase'

class StagiairesSearchQueryImpl extends BaseSearchQueryImpl implements StagiairesSearchQuery {

    constructor(search: URLSearchParams) {
        super(search, 'Dossier.DateReception__c')
        this.applySearch(search)
    }

    clone() {
        return new StagiairesSearchQueryImpl(this.toSearchParams())
    }
}

export default function DetailStagiairesSessions(props: { session: SessionFormation | null }) {


    const colonnes = (_: StagiairesSearchQueryImpl): MyTableColumn[] => [
        {
            id: "Stagiaire",
            label: "Nom",
            wrap: true,
            value: (row: StagiaireSession) => `${row?.Prenom__c} ${row?.Nom__c}`
        },
        {
            id: "Dossiers.DateReception__c",
            label: "Date d'inscription",
            value: (row: StagiaireSession) => formatDate(row?.Dossier?.DateReception__c)
        },
        {
            id: "entreprise",
            label: "ENTREPRISE",
            wrap: true,
            value: (row: StagiaireSession) => row?.Entreprise__c
        },
        {
            id: "contact",
            label: "contact",
            wrap: true,
            value: (row: StagiaireSession) => row?.EmailContact__c
        },
        {
            id: "NumeroDossier__c",
            label: "Dossier",
            value: (row: StagiaireSession) => `N° ${row?.Dossier?.Name}`,
            nowrap: true
        },
    ]

    const actions = (stagiaire: StagiaireSession) => [
        <Link
            key="detailSession"
            icon="details"
            level={ 3 }
            to={`/detail-dossier-contrat/${stagiaire?.Dossier?.Id}`}
        >Accéder au dossier</Link>
    ]
    
    const newQuery = (params: URLSearchParams) => new StagiairesSearchQueryImpl(params)

    if (props.session == null)
        return null

    const getStagiairesQuery = (query: StagiairesSearchQuery) => {
        return getStagiaires(props.session!.IdHeroku!, query)
    }

    return (
        <RechercheBase<StagiaireSession, StagiairesSearchQueryImpl>
            name="StagiairesSession"
            title="Stagiaires"
            resultsLabel="stagiaire"
            noHeader
            newQuery={ newQuery }
            filtres={ [] }
            colonnes={ colonnes }
            tabs={ singleTab(getStagiairesQuery) }
            tableActions={ actions }
        />
    )
}
