import { FormationCampus, FormationsCampusSearchQuery, ThematiqueCampus, getFormationsCampus } from 'api/formationAPI'
import { RootState } from 'app/rootReducer'
import { AtlasFlex, AtlasIcon, MyTableColumn } from 'atlas-ds'
import Link from 'components/Link'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { FiltreAutosuggest, FiltreCheckboxes, FiltreRecherche } from 'components/recherche/Filtres'
import RechercheBase, { singleTab } from 'components/recherche/RechercheBase'
import State from 'components/State'
import { useSelector } from 'react-redux'


class FormationsCampusSearchQueryImpl extends BaseSearchQueryImpl implements FormationsCampusSearchQuery {

    recherche = ''
    thematique = ''
    statuts = [] as string[]

    constructor(search: URLSearchParams) {
        super(search, 'Libelle__c')
        this.applySearch(search)
    }

    applySearch(params: URLSearchParams): FormationsCampusSearchQueryImpl {
        super.applySearch(params)
        this.recherche = params.get('recherche') ?? this.recherche
        this.thematique = params.get('thematique') ?? this.thematique
        this.statuts = params.getAll('statut') ?? this.statuts
        return this
    }

    clone() {
        return new FormationsCampusSearchQueryImpl(this.toSearchParams())
    }

    toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        params.set('recherche', this.recherche)
        params.set('thematique', this.thematique)
        this.statuts.forEach((value) => params.append('statut', value))
        return params
    }
}

const statuts = [{
    label: "À compléter",
    value: "1"
}, {
    label: "Publié",
    value: "3"
}, {
    label: "Sessions à renseigner",
    value: "2"
}]

export default function MesFormations() {

    const { fulfilled: thematiquesCampus } = useSelector((state: RootState) => state.thematiquesCampusState)

    const getThematiquesCampus = (recherche: string) => {
        const texte = recherche.toLowerCase()
        return Promise.resolve(thematiquesCampus?.filter(t => {
            return t.Libelle__c.toLowerCase().indexOf(texte) >= 0
        }) ?? [])
    }

    const filtres = [
        FiltreRecherche({
            name: 'recherche',
            label: 'Recherche',
            placeholder: 'Nom de la formation...'
        }),
        FiltreAutosuggest<ThematiqueCampus>({
            name: 'thematique',
            label: 'Thématique',
            placeholder: 'Libellé...',
            getSuggestionText: thematique => thematique?.Libelle__c ?? '',
            getSuggestions: getThematiquesCampus,
            onSelectSuggestion: (value, form) => { form.setValue('thematique', value?.Libelle__c ?? null, true) }
        }),
        FiltreCheckboxes({
            name: 'statusIds',
            label: "Statut",
            paramName: 'statut',
            valueName: 'statusIds',
            queryName: 'statuts',
            checkboxes: statuts
        })
    ]


    const colonnes = (_: FormationsCampusSearchQuery): MyTableColumn[] => [
        {
            id: "Libelle__c",
            label: "Titre",
            sortable: true,
            wrap: true
        },
        {
            id: "Thematique.Libelle__c",
            label: "Thématique",
            sortable: true,
            value: (row: FormationCampus) => row.Thematique?.Libelle__c
        },
        {
            id: "Statut__c",
            label: "Statut",
            value: (row: FormationCampus) => <State type="formation" id={ row.Statut__c }>
                {statuts.find(statut => statut.value === row.Statut__c)?.label as string}
            </State>
        },
        {
            id: "NbSessions",
            label: "Nombre de sessions",
            value: (row: any) => (row.NbSessions || '0') === '0'
                ? <AtlasFlex gap="s" alignItems="center">
                    <AtlasIcon name="warning" size="xs" />
                    <p>Aucune</p>
                </AtlasFlex>
                : row.NbSessions
        }
    ]

    const actions = (formation: FormationCampus) => {
        return [
            <Link
                key="detail"
                icon="details"
                level={ 3 }
                to={'/detail-formation/' + formation.IdHeroku}
            >Voir le détail</Link>
        ]
    }

    const newQuery = (params: URLSearchParams) => new FormationsCampusSearchQueryImpl(params)

    return (
        <RechercheBase<FormationCampus, FormationsCampusSearchQueryImpl>
            name="MesFormations"
            title="Formations Campus"
            headerActions={<Link to={'/gestion-compte-entreprise'}>Gestion du compte OF Campus</Link>}
            newQuery={newQuery}
            filtres={filtres}
            colonnes={colonnes}
            tabs={singleTab(getFormationsCampus)}
            tableActions={actions}
        />
    )
}
