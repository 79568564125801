import { Form, useForm } from "@dsid-opcoatlas/reform"
import { AdresseEntreprise, patchAdresseEntreprise, postAddAdresseEntreprise } from "api/profilAPI"
import { Entreprise } from "api/referencesAPI"
import { dispatchAppEvent } from "app/events"
import { MyForm, MyModal } from "atlas-ds"
import Button from "components/reform/Button"
import CodePostalCommuneField from "components/reform/inputs/CodePostalCommuneField"
import TextField from "components/reform/inputs/TextField"
import { AdresseValidationFieldsParams, Ignored, OptionalString, RequiredString, requiredObject } from "components/yop/constraints"

interface AdresseEntrepriseModalProps {
    entreprise: Entreprise | null
    adresse?: AdresseEntreprise
    refreshEvent?: string
    close: () => void
}

export default function AdresseEntrepriseModal(props: AdresseEntrepriseModalProps) {

    const modification = props.adresse?.IdHeroku != null

    const initialValues: AdresseEntreprise = {
        Adresse1__c: props.adresse?.Adresse1__c ?? '',
        Adresse2__c: props.adresse?.Adresse2__c ?? '',
        Adresse3__c: props.adresse?.Adresse3__c ?? '',
        Adresse4__c: props.adresse?.Adresse4__c ?? '',
        CodePostal__c: props.adresse?.CodePostal__c ?? '',
        Ville__c: props.adresse?.Ville__c ?? '',
        Departement__c: props.adresse?.Departement__c ?? '',
        Region__c: props.adresse?.Region__c ?? ''
    }

    const validationSchema = requiredObject<AdresseEntreprise>({
        Adresse1__c: OptionalString.max(AdresseValidationFieldsParams.Apt),
        Adresse2__c: OptionalString.max(AdresseValidationFieldsParams.Bat),
        Adresse3__c: RequiredString.max(AdresseValidationFieldsParams.Apt),
        Adresse4__c: OptionalString.max(AdresseValidationFieldsParams.Bat),
        CodePostal__c: RequiredString.max(AdresseValidationFieldsParams.Cp).matches(
            AdresseValidationFieldsParams.CpRegex,
            AdresseValidationFieldsParams.CpMessage
        ),
        Ville__c: RequiredString.max(AdresseValidationFieldsParams.Com),
        Departement__c: Ignored,
        Region__c: Ignored,
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            if (modification) {
                patchAdresseEntreprise(props.adresse!.IdHeroku!, context.values)
                    .then(() => {
                        if (props.refreshEvent)
                            dispatchAppEvent(props.refreshEvent, "Votre adresse a été modifiée avec succès")
                        props.close()
                    }).finally(() => {
                        context.setSubmitting(false)
                    })
            }
            else {
                postAddAdresseEntreprise(props.entreprise!.IdHeroku, context.values)
                    .then(() => {
                        if (props.refreshEvent)
                            dispatchAppEvent(props.refreshEvent, "Votre adresse a été ajoutée avec succès")
                        props.close()
                    }).finally(() => {
                        context.setSubmitting(false)
                    })
            }
        },
    })

    return (
        <MyModal
            label={ modification ? "Modifier une adresse" : "Ajouter une adresse" }
            onClose={ props.close }
            isPending={ form.submitting }
        >
            <Form context={ form } noValidate>
                <MyForm>
                    <MyForm.Grid>
                        <CodePostalCommuneField name="" label="Code postal et commune" 
                            codePostalName="CodePostal__c" communeName="Ville__c"
                            departementName="Departement__c" regionName="Region__c" />
                        <TextField name={'Adresse1__c'} label="Appartement" />
                        <TextField name={'Adresse2__c'} label="Bâtiment" />
                        <TextField name={'Adresse3__c'} label="N° et voie" />
                        <TextField name={'Adresse4__c'} label="Lieu dit / BP" />
                    </MyForm.Grid>
                    <MyForm.Actions>
                        <Button submit={ true } spinner={{spinning: form.submitting}}>Enregistrer</Button>
                        <Button level={ 2 } onClick={ () => props.close() }>Annuler</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}
