import { ListeMesEntreprises } from "app/compte/mes-entreprises/ListeMesEntreprises"
import { RootState } from "app/rootReducer"
import { AtlasInfo, AtlasSpacer, MyConfettis } from "atlas-ds"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { AppType } from "slices/contextSlice"


export function MesPrestataires() {
    const { appTypes, profil, pending } = useSelector((state: RootState) => state.profilState)
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (!appTypes?.includes(AppType.Prestataire)) {
            const params = new URLSearchParams(location.search)

            history.replace({
                pathname: '/',
                search: params.toString()
            })
        }
    })

    const [nouveauPrestataireSiren, setNouveauPrestataireSiren] = useState("")
    const [envoiEmailFactureOF, setEnvoiEmailFactureOF] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        const nouveauPrestataireSirenParam = params.get("nouveauPrestataireSiren")
        if (nouveauPrestataireSirenParam) {
            setNouveauPrestataireSiren(nouveauPrestataireSirenParam)
            params.delete("nouveauPrestataireSiren")

            history.replace({
                pathname: location.pathname,
                search: params.toString()
            })
        }

        const envoiEmailFactureOF = localStorage.getItem("envoiEmailFactureOF");
        if (envoiEmailFactureOF) {
            setEnvoiEmailFactureOF(true);
            localStorage.removeItem("envoiEmailFactureOF")
        }
    }, [history, location.pathname, location.search])

    return (
        <>
            <PageHeader
                actions={<Link key="prestataireBoutonAjout" icon="plus" to="/mes-prestataires/attacher">Attacher un prestataire</Link>}
            >Mes prestataires</PageHeader>

            {nouveauPrestataireSiren && (
                <>
                    <MyConfettis>
                        <AtlasInfo
                            type="success"
                            title="Prestataire rattaché"
                            link={<a href={`/?contexte=prestataire&siren-adh=${nouveauPrestataireSiren}`}>Accéder à myAtlas Prestataire</a>}
                        >
                            Félicitations, votre prestataire a bien été rattaché à votre compte.<br/>
                            Vous pouvez désormais accéder à vos services en ligne et gérer vos dossiers et demandes.
                        </AtlasInfo>
                    </MyConfettis>
                    <AtlasSpacer size="xxl" />
                </>
            )}

            {envoiEmailFactureOF && (
                <>
                    <MyConfettis>
                        <AtlasInfo type="success" title="Documents envoyés">
                            Vos documents lié à votre premiere facture ont bien été reçu par Atlas, ils seront traités dans les plus brefs délais.
                        </AtlasInfo>
                    </MyConfettis>
                    <AtlasSpacer size="xxl" />
                </>
            )}

            <ListeMesEntreprises entreprises={profil?.EntreprisesOF ?? null} loading={pending} type="OrganismeFormation" />
        </>
    )
}