import { Reference } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { TextFieldProps } from 'components/reform/inputs/TextField'
import { useSelector } from 'react-redux'
import { unaccentLowerCase } from './FieldUtil'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'

interface SpecialiteFormationFieldProps<T extends object> extends TextFieldProps<T> {
    parent: any
    parentPath: string
    editMode?: boolean
}

export default function SpecialiteFormationField<T extends object>(props: SpecialiteFormationFieldProps<T>) {
    
    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const form = useFormContext<T>()
    const fieldState = getFieldState<string | null>(form, props.name)

    async function searchSpecialite(value: string) {
        const search = unaccentLowerCase(value.trim())
        if (search === '' || refs == null)
            return []

        return refs.REF_SPECIALITE_FORMATION.filter(ref =>
            ref.Id.indexOf(search) >= 0 || unaccentLowerCase(ref.Libelle__c).indexOf(search) >= 0
        )
    }

    return (
        <AutocompleteBaseField<Reference>
            label={ props.label }
            defaultValue={ fieldState.value ?? "" }
            tooltip={ props.tooltip }
            disabled={ props.disabled }
            placeholder="Rechercher par code ou libellé (3 caractères minimum)"
            required={ fieldState.constraints.required }
            error={ fieldState.error }
            onBlur={ value => form.setValue(props.name, value === "" ? null : form.getValue(props.name), true) }
            
            search={ searchSpecialite }

            optionFor={ reference => ({ id: reference.Id, content: `${ reference.Id } - ${ reference.Libelle__c }` }) }
            onSelect={ reference => form.setValue(props.name, reference.Id, true) }
        />
    )
}