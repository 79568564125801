import { ObjectSchema } from '@dsid-opcoatlas/yop'
import { DossierPieceDTO } from 'api/dossierAPI'
import { PAYS_FRANCE } from 'api/references'
import { normalizeAPIDate } from 'components/format/Format'


// Transforme les propriétés de T spécifiées dans K en propriétés requises (ni undefined, ni null)
type RequiredPick<T, K extends keyof T> = T & {
    [P in K]-?: Exclude<T[P], null | undefined>
}


export const nil = (null as unknown)


export function createAdresse() { return {
    appartement: nil as (string | null),
    batiment: nil as (string | null),
    adresse: nil as (string | null),
    complement: nil as (string | null),
    codePostal: nil as string,
    commune: nil as string,
}}
export type Adresse = ReturnType<typeof createAdresse>


export function createAdresseAgora() { return {
    ...createAdresse(),
    pays: PAYS_FRANCE as (string | null),
}}
export type AdresseAgora = ReturnType<typeof createAdresseAgora>


export function createDossierEmployeur() { return {
    ...({} as {
        Id?: string | null
        ParentId?: string | null
    }),
    ...createAdresse(),
    nom: nil as string,
    numeroSiret: nil as string,
}}
export type DossierEmployeur = ReturnType<typeof createDossierEmployeur>


export function createDossierEmployeurExtended() { return {
    ...createDossierEmployeur(),
    codeNaf: nil as string,
    codeIdcc: nil as string,
    telephone: nil as string,
    email: nil as string,
}}
export type DossierEmployeurExtended = ReturnType<typeof createDossierEmployeurExtended>


export function createDossierStagiaireRepresentant() { return {
    nom: nil as string,
    prenom: nil as string,
    email: nil as (string | null),
    adresse: createAdresse(),
}}
export type DossierStagiaireRepresentant = ReturnType<typeof createDossierStagiaireRepresentant>


export const TYPE_HANDICAP_VALUES = ['1', '2'] as const
export type TYPE_HANDICAP = typeof TYPE_HANDICAP_VALUES[number]

export function createBaseNamedEntity() { return {
    ...({} as {
        IdContact?: string | null
    }),
    nomNaissance: nil as string,
    nom: nil as string,
    prenom: nil as string,
    dateNaissance: nil as Date,
}}
export type BaseNamedEntity = ReturnType<typeof createBaseNamedEntity>

export function createBaseDossierStagiaire() { return {
    ...({} as {
        IdHeroku?: number | null
    }),
    ...createBaseNamedEntity(),
    sexe: nil as string,
    nir: nil as string,
    ntt: false as boolean,
    nirVerifError: false as boolean,
    courriel: nil as string,
    handicap: nil as TYPE_HANDICAP,
    situationHandicap: nil as string,
}}
export type BaseDossierStagiaire = ReturnType<typeof createBaseDossierStagiaire>


export function createDossierStagiaire() { return {
    ...createBaseDossierStagiaire(),
    // ...createAdresse(),
    // Stagiaire
    telephone: nil as (string | null),
    nationalite: nil as (string | null),
    // Commun CAPP/CPRO
    situationAvantContrat: nil as string,
    plusHautDiplome: nil as string,
    diplomeVise: nil as string,
    statutEntreeEnFormationAgora: nil as (string | null),
    niveauMaximumClasseEntreeFormationAgora: nil as (string | null),
}}
export type DossierStagiaire = ReturnType<typeof createDossierStagiaire> & RequiredPick<Adresse, 'adresse' | 'codePostal'>


export function createDossierSalarie() { return {
    ...createBaseDossierStagiaire(), 
    statut: nil as string,
    contrat: nil as string,
    niveauFormation: nil as string,
    dateEntree: nil as Date,
    categorieAction: nil as string,
    heures: nil as number,
    heuresHTT: nil as (number | null),
    heuresFoad: nil as number,
    heuresAfest: 0 as (number | null),
    coefficient: nil as (string | null),
    autreMetier: nil as (string | null),
}}
export type DossierSalarie = ReturnType<typeof createDossierSalarie>


export function createDossierSalarieAgora() { return {
    ...createDossierSalarie(),
    pays: PAYS_FRANCE as (string | null),
}}
export type DossierSalarieAgora = ReturnType<typeof createDossierSalarieAgora>


export function createBaseDossierTuteur() { return {
    ...({} as {
        Id?: string | null
    }),
    ...createBaseNamedEntity(),
    emploiOccupe: nil as string,
    employeurDirigeant: nil as boolean,
    anneesExperience: nil as (number | null),
    salariesSuivis: nil as (number | null),
    formation: nil as boolean,
    dateFormation: nil as (Date | null),
    priseEnChargeFonctionTutorale: nil as boolean,
    exerciceFonction2Annees: nil as (boolean | null),
}}
export type BaseDossierTuteur = ReturnType<typeof createBaseDossierTuteur>


export function createDossierCFA() { return {
    ...({} as {
        IdHeroku?: number | null
        EtablissementOFId__c?: string | null
        CodeEtablissementOF__c?: string | null
    }),
    ...createAdresse(),
    nom: nil as string,
    siret: nil as string | null,
    entreprise: nil as boolean,
    etat: nil as string,
    numeroUAI: nil as (string | null),
    numeroDA: nil as (string | null),
}}
export type DossierCFA = ReturnType<typeof createDossierCFA> & Adresse

export interface TypeCout {
    IdHeroku: number | null
    montant: number
}

export function createBaseDossierModule(initial?: { subrogation?: boolean }) { return {
    ...({} as {
        IdHeroku?: number | null
    }),
    intitulePrecis: nil as string,
    formationInterne: false,
    cfa: nil as (DossierCFA | null),
    typeFormation: nil as (string | null),
    dateDebutFormation: nil as Date,
    dateFinFormation: nil as Date,
    dureeTotale: nil as number,
    coutPedagogique: { IdHeroku: null, montant: nil as number } as TypeCout,
    subrogation: (initial?.subrogation ?? nil) as boolean,
    commentaire: nil as (string | null),
}}
export type BaseDossierModule = ReturnType<typeof createBaseDossierModule>

export interface DossierModuleWithRNCP {
    typeQualification: string
    codeRNCP: string | null
}

export function createDossierModuleAgora(initial?: { subrogation?: boolean, formationCertifianteAgora?: string, categorieActionAgora?: string, objectifAgora?: string }) { return {
    ...createBaseDossierModule(initial),
    formationCertifianteAgora: (initial?.formationCertifianteAgora ?? nil) as string,
    categorieActionAgora: (initial?.categorieActionAgora ?? nil) as string,
    objectifAgora: (initial?.objectifAgora ?? nil) as string,
}}
export type DossierModuleAgora = ReturnType<typeof createDossierModuleAgora>


export function createDossierProModule() { return {
    ...createBaseDossierModule(),
    niveauVise: nil as string,
    remuneration: nil as number,
    contexteSpecifique: nil as (string | null),
}}
export type DossierProModule = ReturnType<typeof createDossierProModule>


export function createDossierProModuleAgora() { return {
    ...createDossierProModule(),
    ...createDossierModuleAgora()
}}
export type DossierProModuleAgora = ReturnType<typeof createDossierProModuleAgora>

export interface ModuleAgora {
    modalitePedagogiqueAgora: string
    adresseRealisationIdentiqueAgora: boolean
    lieuPrincipalFormationAgora: Adresse | null
}

export interface DossierPointReserve {
    id: number
    rubrique: string | string[] | null
    type: 'reserve' | 'reco'
    label: string
    message: string
}

export interface DossierWithPointsReserve {
    pointsReserve: DossierPointReserve[] | null | undefined
}

export interface PointReserveCheck<D extends BaseDossier> {
    id: number
    type: 'reserve' | 'reco'
    rubrique: string | string[]
    schema: ObjectSchema<D>
}

function createDossierSignature() { return {
    nom: nil as string,
    lieu: nil as string,
    date: nil as Date,
}}
export type DossierSignature = ReturnType<typeof createDossierSignature>

export function createBaseDossier(dispositif: string, createEmployeur?: () => DossierEmployeur) { return {
    ...({ DispositifFO__c: dispositif } as {
        Id?: string
        IdHeroku?: number
        NumeroDossier__c?: string
        DispositifFO__c?: string
        EtatDossierFO__c?: string
        traitementEnCours?: boolean | null
    }),
    employeur: (createEmployeur ?? createDossierEmployeur)(),
    pieces: [] as DossierPieceDTO[],
    signature: createDossierSignature(),
}}
export type BaseDossier = ReturnType<typeof createBaseDossier>

export type DossierModification = BaseDossier & {
    typeModificationContrat: string
    numModificationContrat: number
    // nbModificationsContrat: number
    // etatModificationContrat: boolean
}


export function createDossierAF<S extends BaseDossierStagiaire, M extends BaseDossierModule>(dispositif: string) { return {
    ...createBaseDossier(dispositif),
    modules: [] as M[],
    salaries: [] as S[],
}}
// TODO: la syntaxe ReturnType<typeof createDossierAF<S, M>> n'est pas acceptée avant typescript 4.7 et
// la mise à jour dans package.json de typescript en 4.8.2 ne suffit pas à corriger le problème.
export type DossierAF<S extends BaseDossierStagiaire = DossierSalarie, M extends BaseDossierModule = BaseDossierModule> =
    ReturnType<typeof createBaseDossier> &
    {
        modules: M[],
        salaries: S[],
    } & DossierWithModules<M> & DossierWithSalaries<S>

export interface DossierWithModules<M extends BaseDossierModule = BaseDossierModule> extends BaseDossier {
    modules: M[]
}

export interface DossierWithSalaries<S extends BaseDossierStagiaire = BaseDossierStagiaire> extends BaseDossier {
    salaries: S[]
}


export const normalizePiecesDTO = (piecesDTO?: DossierPieceDTO[] | null): DossierPieceDTO[] => {
    return Array.from(piecesDTO ?? [], pieceDTO => {
        return { ...pieceDTO, dateAjout: normalizeAPIDate(pieceDTO.dateAjout) }
    })
}
