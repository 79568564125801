import styles from 'pages/dossier/cerfa/global.styles'
import { Text } from '@react-pdf/renderer'
import Checkboxes from 'pages/dossier/cerfa/components/Checkboxes'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'

interface CerfaBooleanProps {
    label?: string
    value: string | boolean | null | undefined
    spacing?: number
}

export default function CerfaBooleanField(props: CerfaBooleanProps) {
    if (props.value === null || props.value === undefined) {
        return null
    }

    return <Checkboxes value={ props.value } spacing={props.spacing}></Checkboxes>
}


export function CerfaCheckboxBooleanField(props: CerfaBooleanProps) {
    return (
        <Text style={[styles.bold, styles.italic, styles.xSmall]}>
        <Checkbox checked={ !!props.value }></Checkbox> { props.label }
        </Text>
    )
}