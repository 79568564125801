import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import { FieldMapping } from 'pages/dossier/metadata/DossierMapping'
import { Adresse } from 'slices/dossierCommon'
import { AdresseValidationFieldsParams, OptionalString, RequiredString } from 'components/yop/constraints'
import { AdresseFields } from 'components/reform/inputs/AdresseFields'
import { Yop } from '@dsid-opcoatlas/yop'
import ExportAdresseField from 'pages/dossier/export/ExportAdresseField'
import RecapAdresse from 'pages/dossier/recap/RecapAdresse'

const adresseSchema = Yop.object<Adresse | null>({
    appartement: OptionalString.max(AdresseValidationFieldsParams.Apt),
    batiment: OptionalString.max(AdresseValidationFieldsParams.Bat),
    adresse: RequiredString.max(AdresseValidationFieldsParams.Adr),
    complement: OptionalString.max(AdresseValidationFieldsParams.Cpt),
    codePostal: RequiredString.max(AdresseValidationFieldsParams.Cp).matches(AdresseValidationFieldsParams.CpRegex, AdresseValidationFieldsParams.CpMessage),
    commune: RequiredString.max(AdresseValidationFieldsParams.Com),
}).defined()

export function AdresseFieldMetadata(settings: SettingsFieldMetadata<Adresse | null> = {}) {
    return createMetadata(
        {
            yop: adresseSchema,
            input: AdresseFields,
            recap: RecapAdresse,
            export: ExportAdresseField,
        },
        settings,
    )
}

export function adresseMapping(path1: string, path2: string, path3: string, path4: string, pathCP: string, pathCommune: string): FieldMapping<Adresse | null> {
    return {
        mapModelToDTO(value, dto) {
            dto[path1] = value?.appartement ?? null
            dto[path2] = value?.batiment ?? null
            dto[path3] = value?.adresse ?? null
            dto[path4] = value?.complement ?? null
            dto[pathCP] = value?.codePostal ?? null
            dto[pathCommune] = value?.commune ?? null
        },
        mapDTOToModel(dto) {
            return {
                appartement: dto[path1],
                batiment: dto[path2],
                adresse: dto[path3],
                complement: dto[path4],
                codePostal: dto[pathCP],
                commune: dto[pathCommune],
            }
        },
    }
}