import { AtlasIcon, AtlasLinkProps, AtlasLoading } from "atlas-ds";

export interface MyCardProps {
  /**
   * Le titre de la card
   */
  title: string;
  /**
   * Le contenu
   */
  children?: React.ReactNode;
  /**
   * L'icône associée (une icône par défaut sera associée si le paramètre est
   * absent)
   */
  icon?: string;
  /**
   * Un lien
   */
  link?: React.ReactElement<AtlasLinkProps>;
}

/**
 * Une card pouvant contenir une icône, un titre, une description et un lien
 */
export function MyCard(props: MyCardProps) {
  const cardLink = props.link
    ? {
        ...props.link,
        props: {
          icon: "arrow-right",
          level: 3,
          ...props.link.props,
        },
      }
    : undefined;

  return (
    <div className="my-card">
      <AtlasLoading.Loader />
      <div className="my-card__icon">
        <AtlasIcon name={props.icon || "article"} />
      </div>
      <p className="my-card__title">{props.title}</p>
      {props.children && <p className="my-card__content">{props.children}</p>}
      {cardLink}
    </div>
  );
}
