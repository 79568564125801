import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { DelaiTraitementDossier, getDelaisTraitementDossier } from "api/dossierAPI"

interface DelaisTraitementDossierState {
    delais: DelaiTraitementDossier[] | null
}

const initialState = { delais: null } as DelaisTraitementDossierState

const delaisTraitementDossierThunk = createAsyncThunk(
    'delaisTraitementDossier/get',
    async (userId, thunkAPI) => {
        return await getDelaisTraitementDossier()
    }
)

export const delaisTraitementDossierSlice = createSlice({
    name: 'delaisTraitementDossier',
    initialState,
    reducers: {
        clear(state) {
            state.delais = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(delaisTraitementDossierThunk.fulfilled, (state, action) => {
            state.delais = action.payload
        })
    }
})

export const delaisTraitementDossierActions = {
    clear: delaisTraitementDossierSlice.actions.clear,
    load: delaisTraitementDossierThunk
}


const delaisTraitementDossierPrestataireThunk = createAsyncThunk(
    'delaisTraitementDossierPrestataire/get',
    async (userId, thunkAPI) => {
        return await getDelaisTraitementDossier(true)
    }
)

export const delaisTraitementDossierPrestataireSlice = createSlice({
    name: 'delaisTraitementDossierPrestataire',
    initialState,
    reducers: {
        clear(state) {
            state.delais = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(delaisTraitementDossierPrestataireThunk.fulfilled, (state, action) => {
            state.delais = action.payload
        })
    }
})

export const delaisTraitementDossierPrestataireActions = {
    clear: delaisTraitementDossierPrestataireSlice.actions.clear,
    load: delaisTraitementDossierPrestataireThunk
}