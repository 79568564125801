import { DossierDetail, postRelanceAnnulerDossier } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_ANNULATION } from 'api/references'
import { AtlasInfo } from 'atlas-ds'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { DossierRelanceActionProps } from '.'
import DossierRelanceAction from './DossierRelanceAction'


export function DossierRelanceAnnuler(props: DossierRelanceActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    return <DossierRelanceAction
        dossier={ dossier }
        id="annuler"
        label="Demander l'annulation du dossier"
        of={props.of}
        typeDemande={DEMANDE_EN_COURS_ANNULATION}
        successMessage="Votre demande d'annulation a bien été prise en compte. Elle sera traitée dans les meilleurs délais"
        formContent={<AtlasInfo type="warning" title="Aucune autre facture ne pourra être déposée.">
            Confirmez-vous la clôture de votre dossier N°{dossier.NumeroDossier__c} ?
        </AtlasInfo>}
        doAction={ (dossier: DossierDetail) => postRelanceAnnulerDossier(dossier.NumeroDossier__c, JSON.stringify({ 'Motif': ' ' })) }
    />
}
