import { Link } from 'react-scroll'

export default class ScrollLink extends Link {
    // handleClick = (event) => {
    //     /*
    //      * give the posibility to override onClick
    //      */
  
    //     if (this.props.onClick) {
    //       this.props.onClick(event);
    //     }
  
    //     /*
    //      * dont bubble the navigation
    //      */
  
    //     if (event.stopPropagation) event.stopPropagation();
    //     if (event.preventDefault) event.preventDefault();
  
    //     /*
    //      * do the magic!
    //      */
    //     this.scrollTo(this.props.to, this.props);
  
    //     Events.scrollEvent.register('end', () => {
    //         // if (scroller.getActiveLink() !== this.props.to) {
    //         //    const element = scroller.get(scroller.getActiveLink())
    //         //    element.setState({ active: false })
    //         // }

    //         scroller.setActiveLink(this.props.to)
    //         scrollHash.changeHash(this.props.to)
    //         this.setState({ active: true })
    //         Events.scrollEvent.remove('end')
    //     })
    // }
}
