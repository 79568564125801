import { DossierDetail, getDossierDetailFactures } from "api/dossierAPI"
import { TYPE_FACTURE_AVOIR } from "api/references"
import useApiState from "api/useApiState"
import { AtlasHeading, AtlasLoading, AtlasText, MyTable } from "atlas-ds"
import Link from "components/Link"
import { formatApiDate, formatCurrency } from "components/format/Format"
import { useEffect } from "react"
import DossierDeposerFactureSelfe from './DossierDeposerFactureSelfe'

export default function DetailDossierFactures(props: { dossier: DossierDetail | null }) {
    
    const [{ pending, value: factures, error }, withGetDossierDetailFactures] = useApiState(getDossierDetailFactures)
    useEffect(() => {
        if (props.dossier)
            withGetDossierDetailFactures(props.dossier.Id)
    }, [props.dossier, withGetDossierDetailFactures])
    
    const facturesActions = (fact: any) => [
        <Link
            key="detail"
            icon="details"
            to={ '/detail-facture/' + fact.Id }
            level={ 3 }
        >Détail</Link>
    ]

    return <div>
        <AtlasHeading
            tag="h2"
            size="m"
            button={ props.dossier
                ? <DossierDeposerFactureSelfe link icon="plus" mode="facture" label="Déposer une facture" detail={ props.dossier } />
                : undefined
            }
        >Factures</AtlasHeading>
        <AtlasLoading loading={pending}>
            <MyTable
                caption='Factures'
                rows={ factures ?? [] }
                emptyText="Aucune facture"
                error={ error ?? undefined }
                rowActions={ facturesActions }
                columns={[
                    {
                        id: "RefFactureClient__c",
                        label: "Référence facture",
                        nowrap: true,
                        value: (row: any) => [
                            <AtlasText bold>{(row.Type__c ?? '') === TYPE_FACTURE_AVOIR ? 'Avoir' : 'Facture'}</AtlasText>,
                            <AtlasText small>{row.RefFactureClient__c}</AtlasText>
                        ]
                    },
                    {
                        id: "MontantFactureHt__c",
                        label: "Montant HT",
                        value: (row: any) => [
                            <AtlasText bold>{formatCurrency(row.MontantTTC__c, ' TTC')}</AtlasText>,
                            <AtlasText small>{formatCurrency(row.MontantFactureHT__c, ' HT')}</AtlasText>
                        ]
                    },
                    {
                        id: "DateEmission__c",
                        label: "Date émission",
                        value: (row: any) => formatApiDate(row.DateEmission__c)
                    }
                ]}
            />
        </AtlasLoading>
    </div>
}
