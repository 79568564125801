import { Text, View } from '@react-pdf/renderer'
import { TYPE_MODIFICATION_AVENANT } from 'api/references'
import styles from 'pages/dossier/cerfa/global.styles'
import { FormMetadata, atField } from 'pages/dossier/metadata/FormMetadata'
import { CAPPEmployeurMetadata } from 'pages/dossier-capp/CAPPEmployeurMetadata'
import { DossierCAPP, DossierCAPPContrat, DossierCAPPEmployeur } from 'pages/dossier-capp/CAPPModel'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import CerfaNumberField from 'pages/dossier/cerfa/CerfaNumberField'
import CerfaRefField from 'pages/dossier/cerfa/CerfaRefField'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'

interface AnneeRemunation {
  dateDebut: Date | null
  dateFin: Date | null
  pourcentage: number | null
  type: string | null
}

export default function Contrat<D extends DossierCAPP>({ metadata, dossier, contrat, employeur }: { metadata: FormMetadata<D>, dossier: D, contrat: DossierCAPPContrat, employeur: DossierCAPPEmployeur }) {
  const editMode = metadata.api.isEdit
  const anneesRemuneration = contrat?.remuneration?.anneesRemuneration?.filter(a => a.remunerationDateDebut)
  const periodesRemuneration = anneesRemuneration?.map(a => {
    return ((contrat?.remuneration?.modeManuelRemuneration || editMode) && a.remunerationDateChangement1)
        || (!(contrat?.remuneration?.modeManuelRemuneration || editMode) && a.remunerationDateChangement1 && a.remunerationDateChangement) ?
        [{
            dateDebut: a.remunerationDateDebut,
            dateFin: a.remunerationDateChangement1,
            pourcentage: a.remunerationPourcentage1,
            type: a.remunerationType1,
        }, {
            dateDebut: a.remunerationDateChangement,
            dateFin: a.remunerationDateFin,
            pourcentage: a.remunerationPourcentage2,
            type: a.remunerationType2,
        }]
        :
        [{
            dateDebut: a.remunerationDateDebut,
            dateFin: a.remunerationDateFin,
            pourcentage: a.remunerationPourcentage1,
            type: a.remunerationType1,
        }]
  }) ?? []

  function RemunerationPeriode({ periode }: { periode: AnneeRemunation | null }) {
    return <>
        du <CerfaTextField type="date" value={periode?.dateDebut ?? ''} /> au{' '}
        <CerfaTextField type="date" value={periode?.dateFin ?? ''} /> :{' '}
        <CerfaNumberField value={periode?.pourcentage ?? ''} suffix="%" fractionDigits={2} placeholder="__%" /> du{' '}
        <CerfaRefField options={ refs => refs.REF_TYPE_MONTANT } value={periode?.type ?? ''} placeholder="___" />
    </>
  }

  function Remuneration({ index }: { index: number }) {
    const periodeRemuneration = periodesRemuneration[index]
    const empty = { dateDebut: null, dateFin: null, pourcentage: null, type: null }
    return (
      <Text style={styles.small}>
        {index + 1}
        <Text style={styles.sup}>{index === 0 ? 're' : 'e'}</Text> année,{' '}
        <RemunerationPeriode periode={periodeRemuneration?.[0] ?? empty}></RemunerationPeriode> ;{' '}
        <RemunerationPeriode periode={periodeRemuneration?.[1] ?? empty}></RemunerationPeriode>
      </Text>
    )
  }

  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>LE CONTRAT</Text>
      <View style={styles.sectionContent}>
        <View style={styles.compact}>
          <View style={styles.cols}>
            <View style={styles.colLeft}>
              <Text>
                Type de contrat ou d'avenant :{' '}
                { /* root nécessaire pour le filtrage des options de l'avenant */ }
                <CerfaElement metadata={ atField(metadata, 'contrat', 'typeContrat') } root={ dossier } value={ contrat.typeContrat } />
              </Text>
            </View>
            <View style={styles.colRight}>
              <Text>
                Type de dérogation :{' '}
                <CerfaElement metadata={ atField(metadata, 'contrat', 'typeDerogation') } value={ contrat.typeDerogation } />
              </Text>
              <Text style={[styles.italic, styles.small]}>
                à renseigner si une dérogation existe pour ce contrat
              </Text>
            </View>
          </View>
          <Text>
            Numéro du contrat précédent ou du contrat sur lequel porte l’avenant
            : <CerfaElement metadata={ atField(metadata, 'contrat', 'numContratPrecedent') } value={ contrat.numContratPrecedent } />
          </Text>
          <Text> </Text>
          <View style={styles.cols}>
            <View style={styles.tripleCol}>
              <Text>Date de conclusion</Text>
              <Text style={styles.xSmall}>
                (date de signature du présent contrat)
              </Text>
              { (dossier as any).typeModificationContrat === TYPE_MODIFICATION_AVENANT ?
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dateConclusionAvenant') } value={ contrat.dateConclusionAvenant } />
              :
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dateConclusion') } value={ contrat.dateConclusion } />
              }
            </View>
            <View style={styles.tripleCol}>
              <Text>Date de début d'exécution du contrat :</Text>
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dateDebut') } value={ contrat.dateDebut } />
            </View>
            <View style={styles.tripleColLast}>
              <Text>Date de début de formation</Text>
              <Text>pratique chez l'employeur :</Text>
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dateDebutPratique') } value={ contrat.dateDebutPratique } />
            </View>
          </View>
          <Text>Si avenant, date d'effet : { (dossier as any).typeModificationContrat === TYPE_MODIFICATION_AVENANT && <CerfaElement metadata={ atField(metadata, 'contrat', 'dateEffet') } root={ dossier } value={ contrat.dateEffet } /> }</Text> 
          <View style={styles.cols}>
            <View style={styles.colLeft}>
              <Text>Date de fin du contrat ou de la période</Text>
              <Text>d’apprentissage : <CerfaElement metadata={ atField(metadata, 'contrat', 'dateFin') } root={ dossier } value={ contrat.dateFin } /></Text>
            </View>
            <View style={styles.colRight}>
              <Text>Durée hebdomadaire du travail :</Text>
              <CerfaElement metadata={ atField(metadata, 'contrat', 'dureeHebdomadaireTravail') } value={ contrat.dureeHebdomadaireTravail } />
            </View>
          </View>
          <Text>
            Travail sur machines dangereuses ou exposition à des risques
            particuliers :{' '}
            <CerfaElement metadata={ atField(metadata, 'contrat', 'travailDangereux') } value={ contrat.travailDangereux } />
          </Text>
        </View>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Rémunération</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={[/*styles.alignRight, */styles.italic, styles.small]}>
              * Indiquer SMIC ou SMC (salaire minimum conventionnel)
            </Text>
          </View>
        </View>
        {[...Array(4)].map((_, i) => (
          <Remuneration key={i} index={i}></Remuneration>
        ))}
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>
              <Text style={styles.bold}>
                Salaire brut mensuel à l'embauche :{' '}
                <CerfaElement style={styles.bold} metadata={ atField(metadata, 'contrat', 'salaireEmbauche') } 
                  value={ contrat.salaireEmbauche } default="_____,__" />
              </Text>
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              Caisse de retraite complémentaire :{' '}
              <CerfaElement metadata={ CAPPEmployeurMetadata.caisseRetraiteComplementaire } 
                value={ employeur.caisseRetraiteComplementaire } default="_____,__" />
            </Text>
          </View>
        </View>
        <Text>
          Avantages en nature, le cas échéant : Nourriture :{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'nourriture') } value={ contrat.nourriture } default="___,___" />
          {'        '}Logement :{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'logement') } value={ contrat.logement } default="_____,___" />
          {'        '}Autre :{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'autres') } value={ contrat.autres } />
        </Text>
      </View>
    </View>
  )
}
