import {
  AtlasLinkConstructor,
  AtlasPageHeader,
  AtlasPageHeaderProps,
  defaultLinkConstructor,
} from "atlas-ds";
import classNames from "classnames";

export const MyPageHeaderAnchorActiveClass = "my-pageHeader__anchor--active";

export interface MyPageHeaderAnchorLink {
  /**
   * Le label affiché pour le lien
   */
  label: string;
  /**
   * L'identifiant de l'élément de la page vers lequel pointe le lien
   */
  targetId: string;
  /**
   * Le lien correspond-il à l'élément actuellement présenté à l'écran ? Cette
   * logique est à la charge du contexte appelant.
   */
  active?: boolean;
}

export interface MyPageHeaderAnchors {
  /**
   * La description accessible du menu de navigation
   */
  ariaLabel: string;
  /**
   * La liste de liens
   */
  links: MyPageHeaderAnchorLink[];
  /**
   * Si un simple lien ne suffit pas, un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
}

export interface MyPageHeaderProps extends AtlasPageHeaderProps {
  /**
   * Les ancres de navigation
   */
  anchors?: MyPageHeaderAnchors;
}

/**
 * Extension du composant global AtlasPageHeader, qui permet d'accueillir en
 * plus une liste d'ancres pour naviguer dans la page
 */
export function MyPageHeader(props: MyPageHeaderProps) {
  const linkConstructor =
    props.anchors?.linkConstructor || defaultLinkConstructor;

  return (
    <AtlasPageHeader
      {...props}
      additionalContent={
        props.anchors ? (
          <nav
            className="my-pageHeader__anchors"
            aria-label={props.anchors.ariaLabel}
          >
            <ul>
              {props.anchors.links.map((link, index) => {
                return (
                  <li className="my-pageHeader__anchorWrapper" key={index}>
                    {linkConstructor(
                      {
                        href: `#${link.targetId}`,
                        "data-label": link.label,
                        className: classNames("my-pageHeader__anchor", {
                          [MyPageHeaderAnchorActiveClass]: link.active,
                        }),
                      },
                      link.label,
                      index
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        ) : undefined
      }
    />
  );
}
