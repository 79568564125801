
import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatRef } from 'components/format/Format'
import { referencesIdcc } from 'api/references'

interface ExportCodeIdccFieldProps {
    cell?: boolean
    row?: boolean
    label: string
    value: string
}

export default function ExportCodeIdccField(props: ExportCodeIdccFieldProps) {
    const refsIdcc = referencesIdcc()
 
    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.label,
        value: `${ props.value } - ${ formatRef(refsIdcc, props.value) }`,
    }} />
}
