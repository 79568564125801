import { Yop } from '@dsid-opcoatlas/yop'
import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { loadDossierApprentissage } from 'api/dossierAPI'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE, TYPE_MODIFICATION_AVENANT } from 'api/references'
import { ObjectFieldMetadata, OptionalObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { DossierAPI } from 'slices/dossierSlice'
import { ElementContext, FormStep, ObjectMetadata, override, registerEditMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierModification, DossierWithPointsReserve, nil, normalizePiecesDTO } from 'slices/dossierCommon'
import { MetadataCAPP, mapDTOToDossierCAPP } from './CAPPFormMetadata'
import { CAPPContratBaseMetadata, CAPPContratMetadata, CAPPRepresentantMetadata, SECTION_CAPP_CONTRAT_MODIFICATION, SECTION_CAPP_CONTRAT_SALAIRE } from './CAPPContratMetadata'
import { onDossierModificationRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { dateMapping, simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import { SECTION_SALARIE_AGORA, salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { parseDate } from 'components/format/Format'
import { isAfter, isBefore } from 'date-fns'
import { References } from 'api/referencesAPI'
import { RemunerationFieldMetadata } from './RemunerationFieldMetadata'
import { OptionalString } from 'components/yop/constraints'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { isEqual } from 'lodash'
import { AppType } from 'slices/contextSlice'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { DossierCAPP, DossierCAPPContrat, DossierCAPPStagiaire, createDossierCAPP, createDossierCAPPMaitre, createDossierCAPPStagiaire } from './CAPPModel'
import { CAPPEmployeurMetadata } from './CAPPEmployeurMetadata'
import { CAPPStagiaireMetadata } from './CAPPStagiaireMetadata'
import { CAPPFormationBaseMetadata, CAPPFormationMetadata, SECTION_FORMATION_FRAIS } from './CAPPFormationMetadata'
import { CAPPMaitre1Metadata, CAPPMaitre2Metadata } from './CAPPMaitresMetadata'


function createEditDossierCAPP() { return {
    ...createDossierCAPP(),

    wasAvenant: nil as boolean,
    typeModificationContrat: nil as string,
    numModificationContrat: nil as number,
    typeContratInitial: nil as string,
}}
export type EditDossierCAPP = ReturnType<typeof createEditDossierCAPP> & DossierCAPP & DossierWithPointsReserve & DossierModification


const api: DossierAPI<EditDossierCAPP> = {
    isEdit: true,
    create: createEditDossierCAPP,
    createSalarie: createDossierCAPPStagiaire,
    createTuteur: createDossierCAPPMaitre,
    load: async (dossierId: string, storedState?: EditDossierCAPP | null) => {
        if (storedState)
            return storedState
        const dossierDTO = await loadDossierApprentissage(dossierId)
        const loadedDossier = mapDTOToDossierCAPP(MetadataEditCAPP, dossierDTO)
        return loadedDossier
    },
    loadWithPieces: async (dossierId: string, storedState?: EditDossierCAPP | null) => {
        let loadedDossier = storedState
        if (!storedState) {
            const dossierDTO = await loadDossierApprentissage(dossierId)
            loadedDossier = mapDTOToDossierCAPP(MetadataEditCAPP, dossierDTO)
        } else {
            loadedDossier = { ...storedState }
        }
        const pieces = await loadAndMergeDossierPieces(dossierId, [])
        loadedDossier.pieces = normalizePiecesDTO(pieces)
        return loadedDossier
    },
    postEdit: async (dossier: EditDossierCAPP) => {
        dossier.attestationPieces = nil as boolean
        dossier.wasAvenant = AVENANT_CONTRAT_TYPES.includes(dossier.contrat.typeContrat)
        dossier.typeContratInitial = dossier.contrat.typeContrat
        if (dossier.typeModificationContrat === TYPE_MODIFICATION_AVENANT)
            dossier.contrat.typeContrat = ''
    },
    save: async (dossier: EditDossierCAPP) => {
        return await new Promise<EditDossierCAPP>(resolve => setTimeout(() => resolve(dossier), 200))
    },
    send: async (dossier: EditDossierCAPP) => {
        // Non utilisé normalement
        return dossier
    },
    postSend: async (dossier: EditDossierCAPP) => {
        await postSendDossier(MetadataEditCAPP, dossier)
    },
}


const EditCAPPStagiaireMetadata = {
    ...CAPPStagiaireMetadata,
    nir: override(salarieFields.nir, { props: { tooltip: TOOLTIPS.capp.apprenti.nir }}),
    adresse: override(CAPPStagiaireMetadata.adresse, {})
        .mutate(_ => Yop.object<any>({
            appartement: OptionalString,
            batiment: OptionalString,
            adresse: OptionalString,
            complement: OptionalString,
            codePostal: OptionalString,
            commune: OptionalString,
        }).defined()),
    statutEntreeEnFormationAgora: SelectRefFieldMetadata({
        section: SECTION_SALARIE_AGORA,
        props: {
            label: 'Statut de la personne à l’entrée en formation',
            options: refs => refs.REF_STATUT_PERSONNE_ENTREE_FORMATION.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        mapping: simpleMapping('StatutEntreeEnFormationAgora__c'),
    }),
    niveauMaximumClasseEntreeFormationAgora: SelectRefFieldMetadata({
        section: SECTION_SALARIE_AGORA,
        props: {
            label: 'Niveau maximum de classe au moment de l\'entrée en formation',
            options: refs => refs.REF_NIVEAU_MAX_CLASSE_ENTREE_FORMATION.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        mapping: simpleMapping('NiveauMaximumClasseEntreeFormationAgora__c'),
    }),
} as any

export const EditCAPPFormationMetadata = {
    ...CAPPFormationMetadata,
    adresseRealisationIdentiqueAgora: override(CAPPFormationBaseMetadata.adresseRealisationIdentiqueAgora, {
        section: SECTION_FORMATION_FRAIS,
    }),
    organismePrincipalFormationAgora: override(CAPPFormationBaseMetadata.organismePrincipalFormationAgora(false, true), {
        section: SECTION_FORMATION_FRAIS,
    }),
    modalitePedagogiqueAgora: CAPPFormationBaseMetadata.modalitePedagogiqueAgora,
} as any

const AVENANT_CONTRAT_TYPES = ['13', '14', '15', '16', '17', '18', '19', '20']

export const EditCAPPContratMetadata = {
    ...CAPPContratMetadata,
    typeContrat: override(CAPPContratMetadata.typeContrat, { props: {
        options: (refs: References, _?: any, root?: EditDossierCAPP) => {
            return root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT || root?.wasAvenant
                ? refs.REF_TYPE_CONTRAT.filter(ref => AVENANT_CONTRAT_TYPES.includes(ref.Id))
                : refs.REF_TYPE_CONTRAT.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('5'))
        },
    } as any }),
    dateDebut : override(CAPPContratMetadata.dateDebut, { props: { 
        disabled: (ctx: ElementContext<any, EditDossierCAPP>) => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT, 
    } }),
    dateConclusion: override(CAPPContratBaseMetadata.dateConclusion, { props: { 
        disabled: (ctx: ElementContext<any, EditDossierCAPP>) => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT, 
    } }),
    anneeFormationEntreeContrat: CAPPContratBaseMetadata.anneeFormationEntreeContrat,
    niveauOuEchelon: CAPPContratBaseMetadata.niveauOuEchelon,
    coefficientHierarchique: CAPPContratBaseMetadata.coefficientHierarchique,
    remuneration: RemunerationFieldMetadata({
        section: SECTION_CAPP_CONTRAT_SALAIRE,
    }, true),
    dateEffet: DateFieldMetadata({
        section: SECTION_CAPP_CONTRAT_MODIFICATION,
        props: {
            label: ctx => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? "Date d'effet de l'avenant" : "Date d'effet de la correction", 
        },
        mapping: dateMapping('DateEffetAvenantContrat__c'),
    }).mutate(yop => yop
        .required()
        .test<DossierCAPPContrat>(ctx => {
            const dateDebut = parseDate(ctx.parent?.dateDebut)
            const dateEffet = parseDate(ctx.value)
            if (!dateDebut || !dateEffet)
                return true
            if (ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT) {
                if (isBefore(dateEffet, dateDebut)) {
                    return ctx.createError("La date d'effet doit être postérieure à la date de début du contrat")
                }
            } else {
                if (isAfter(dateEffet, dateDebut)) {
                    return ctx.createError("La date d'effet doit être antérieure à la date de début du contrat")
                }
            }
            return true
        })
    ),
    dateConclusionAvenant: DateFieldMetadata({
        section: SECTION_CAPP_CONTRAT_MODIFICATION,
        props: {
            label: "Date de conclusion de l'avenant",
        },
        visibleIf: ctx => ctx.root.typeModificationContrat === TYPE_MODIFICATION_AVENANT,
    }).mutate(yop => yop
        .requiredIf(ctx => ctx.root.typeModificationContrat === TYPE_MODIFICATION_AVENANT)
        .test<DossierCAPPContrat>(ctx => {
            const dateDuJour = new Date();
            const dateConclusionAvenant = parseDate(ctx.value)
            const dateEffet = parseDate(ctx.parent?.dateEffet)
            if (!dateEffet || !dateConclusionAvenant)
                return true
            
            if (isAfter(dateConclusionAvenant, dateEffet)) {
                return ctx.createError("La date de conclusion de l'avenant doit être antérieure à la date d'effet")
            }

            if (isAfter(dateConclusionAvenant, dateDuJour)) {
                return ctx.createError("La date de conclusion de l'avenant doit être égale ou antérieure à la date du jour de la modification")
            }

            const dateConclusion = parseDate(ctx.parent?.dateConclusion)
            if (dateConclusion && isBefore(dateConclusionAvenant, dateConclusion)) {
                return ctx.createError("La date de conclusion (avenant) doit être postérieure à la date de conclusion")
            }
            return true
        })
    )
}

type EditDossierCAPPLight = Omit<EditDossierCAPP, 'pieces' | 'pointsReserve' | 'indicateurConformite'
    | 'wasAvenant' | 'typeModificationContrat' | 'typeContratInitial' | 'numModificationContrat' | 'nbModificationsContrat' | 'etatModificationContrat' | 'attestationMandat'>
export const EditCAPPMetadataLight: ObjectMetadata<EditDossierCAPPLight> = {
    employeur: ObjectFieldMetadata(CAPPEmployeurMetadata, 'Employeur'),
    stagiaire: ObjectFieldMetadata(EditCAPPStagiaireMetadata, 'Apprenti(e)'),
    maitre1: ObjectFieldMetadata(CAPPMaitre1Metadata, "Maître d'apprentissage 1"),
    maitre2: OptionalObjectFieldMetadata(CAPPMaitre2Metadata, "Maître d'apprentissage 2"),
    eligibilite: MetadataCAPP.fields.eligibilite,
    formation: ObjectFieldMetadata(EditCAPPFormationMetadata, 'Formation'),
    contrat: ObjectFieldMetadata(EditCAPPContratMetadata, 'Contrat'),
    representant: OptionalObjectFieldMetadata(CAPPRepresentantMetadata, 'Apprenti(e)'),
    attestationPieces: MetadataCAPP.fields.attestationPieces,
    signature: MetadataCAPP.fields.signature,
}
const EditCAPPMetadata = EditCAPPMetadataLight as ObjectMetadata<EditDossierCAPP>


export const MetadataEditCAPP = registerEditMetadata<EditDossierCAPP>(AppType.Entreprise, {
    dispositif: DISPOSITIF_CONTRAT_APPRENTISSAGE,
    title: (dossier: EditDossierCAPP | null) => (dossier?.typeModificationContrat ?? 'Modification') + " d'un contrat d'apprentissage",
    pathname: '/modifier-dossier-apprentissage',
    steps: MetadataCAPP.steps.map(s => { 
        if (s.rubrique === 'CONTRAT') {
            return { ...s,
                sections: s.sections.concat(SECTION_CAPP_CONTRAT_MODIFICATION),
                submitDisabled: (dossier, pending, initialDossier) => isEqual(initialDossier, dossier),
            } as FormStep<EditDossierCAPP>
        } else if (s.rubrique === 'RECAP') {
            return { ...s,
                onSubmit: onDossierModificationRecapitulatifSubmit,
            } as FormStep<EditDossierCAPP>
        }
        return { ...s } as FormStep<EditDossierCAPP>
    }),
    confirmationContent: null,
    api,
    fields: EditCAPPMetadata,
    exportProps: MetadataCAPP.exportProps as any,
})
