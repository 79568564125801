import { useEffect } from "react"
import { getServices } from "api/aideContactsAPI"
import useApiState from "api/useApiState"
import ServicesTemplate from "./common/ServicesTemplate"
import { useSelector } from "react-redux"
import { RootState } from "app/rootReducer"

export default function ServicesConseil() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const [{ value: services }, withGetServices] = useApiState(getServices)
    useEffect(() => {
        withGetServices()
    }, [withGetServices])

    if (services?.conseil != null && entreprise != null)
        services.conseil.nomConseiller = entreprise.NomPrenomConseillerMyAtlas__c

    return (
        <ServicesTemplate service={ services?.conseil } />
    )
}
