import { BaseSelectField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldSelect, AtlasFlex, AtlasInfo } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { ReactNode, SelectHTMLAttributes, useEffect, useState } from "react"

export type SelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'name' | 'value' | 'disabled' | 'onChange' | 'onBlur'>

export interface SelectOption {
    value: string
    label: string
}

export interface SelectFieldProps<T extends object, V> extends BaseFieldProps<V, T> {
    options: SelectOption[]
    selectProps?: SelectProps
    convertOptionId?: (id: string) => V
    selectionner?: boolean
    successMessage?: ReactNode
    infoMessage?: ReactNode
    warningMessage?: ReactNode
    emptyOptionLabel?: string
}

export default function SelectField<T extends object, V = string>(props: SelectFieldProps<T, V>) {
    const [value, setValue] = useState<string>()

    const context = useFormContext<T>()
    const fieldState = getFieldState<V>(context, props.name)
    const convertOptionId = props.convertOptionId ?? ((id: string) => id as unknown as V)

    const render = useRender()

    useEffect(() => {
        setValue(props.options.find(option => convertOptionId(option.value) === fieldState.value)?.value ?? '')
    }, [props.options])

    return <AtlasFlex column gap="s">
        <AtlasFieldSelect
            name={ props.name }
            label={ props.label as string }
            disabled={ props.disabled }
            showEmptyOption={ props.selectionner }
            emptyOptionLabel={ props.disabled ? '' : props.emptyOptionLabel }
            options={ props.options }
            required={ fieldState.constraints.required }
            tooltipContent={ props.tooltip }
            error={ fieldState.error }
            inputConstructor={(inputProps) => <BaseSelectField
                {...inputProps}
                render={ render }
                modelValues={
                    [...(props.selectionner ? [null] : []),
                    ...props.options.map(option => option.value as V)]
                }
                toOptionValue={ modelValue => (modelValue as string) ?? "" }
                toOptionContent={ modelValue => modelValue == null
                    ? (props.disabled ? '' : props.emptyOptionLabel ?? "Sélectionner")
                    : props.options.find(option => option.value === modelValue)!.label
                }
                toModelValue={ optionValue => optionValue === "" ? null : optionValue as V }
                onChange={ props.onChange }
            />}
        />

        { props.successMessage && <AtlasInfo mini type="success" title="Succès">{ props.successMessage }</AtlasInfo> }

        { props.infoMessage && <AtlasInfo mini title="Information">{ props.infoMessage }</AtlasInfo> }

        { props.warningMessage && <AtlasInfo mini type="warning" title="Attention">{ props.warningMessage }</AtlasInfo> } 
    </AtlasFlex>
}
