import { Step } from 'react-joyride'
import { ELEMENTS } from '../elements'

export const facturesSteps: Step[] = [
  {
    target: ELEMENTS.TABLE,
    content:
      'Tableau de résultats contextualisés selon vos filtres actifs. Passez sur une des lignes de résultats pour voir les interactions possibles sur cette facture.',
    placementBeacon: 'top',
    placement: 'top',
  },
];
