/* eslint-disable jsx-a11y/anchor-is-valid */
import { MyContainer, MyForm } from 'atlas-ds'
import { BaseFieldProps } from 'components/reform/inputs/utils/BaseFieldProps'
import { RequiredBoolean } from 'components/yop/constraints'
import { SettingsFieldMetadata, createMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierVAEI } from './VAEIModel'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'

function FinancementPDC(props: BaseFieldProps<boolean, DossierVAEI>) {

    return (
        <MyForm.Field full>
            <MyContainer dark>
                <RadioBooleanField
                    name={ props.name }
                    label="Souhaitez-vous que le reste à charge soit financé avec les possibilités du plan de développement des compétences (pour les entreprises de moins de 50 salariés, sous réserve des fonds disponibles et de validation d'Atlas) ?"
                />
            </MyContainer>
        </MyForm.Field>
    )
}

export function FinancementPDCFieldMetadata(settings: SettingsFieldMetadata<boolean> = {}) {
    return createMetadata(
        {
            yop: RequiredBoolean,
            input: FinancementPDC,
            // recap: RecapBooleanField,
            // export: ExportBooleanField,
        },
        settings
    )
}
