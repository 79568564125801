import { Facture, Reglement } from 'api/dossierAPI'
import { FactureDetail, FacturePieceDTO, deleteDocumentFacture, downloadFactureDoc, getFacture, getFactureDetailFactures, getFactureDocumentsStatus, sendFactureDocuments } from 'api/factureAPI'
import { ETAT_FACTURE_INCOMPLET, TYPE_EMETTEUR_ADHERENT, TYPE_FACTURE_AVOIR, findModeReglement } from 'api/references'
import { useAppContext } from 'app/useAppContext'
import { AtlasButton, AtlasHeading, AtlasLoading, AtlasText, AtlasValue, AtlasValues, MyContainer, MyTable } from 'atlas-ds'
import Link from 'components/Link'
import State from 'components/State'
import { formatApiDate, formatCurrency } from 'components/format/Format'
import { Location } from 'history'
import { capitalize } from 'lodash'
import DepotFichier from 'pages/detail-common/DepotFichier'
import Detail, { DetailActionElement, DetailActionProps } from 'pages/detail-common/Detail'
import DetailDocuments from 'pages/detail-common/DetailDocuments'
import { AppType } from 'slices/contextSlice'
import FactureDepotFichierModal from './FactureDepotFichierModal'


const detailName = 'DetailFacture'

export type FactureActionProps = DetailActionProps<FactureDetail>

export type FactureActionElement = DetailActionElement<FactureDetail>

export type DetailFactureExtension = {
    [detailName]: {
        alertes?: JSX.Element
        actions?: FactureActionElement[] | ((detail: FactureDetail, actions: FactureActionElement[]) => void)
    }
}

export const detailFactureRefreshEvent = "DetailFacture:Refresh"
export const detailFactureDocumentsRefreshEvent = "DetailFacture:DocumentsRefresh"

export default function DetailFacture() {
    const appContext = useAppContext()

    const history = (location: Location<any>) => [
        { title: 'Factures', pathname: '/mes-factures', search: '' },
        { title: 'Facture sélectionnée', pathname: location.pathname, search: location.search }
    ]  
    const sections = (facture: Facture | null, facturePending: boolean) => {
        const dossiers = facture?.Dossier ? [facture.Dossier] : []

        const dossierActions = (doc: any) => [
            facture?.EmetteurEntreprise__c === (appContext?.appInfo?.app === AppType.Entreprise ? doc.EntrepriseAdherentId__c : doc.EntrepriseOFId__c)
                ? <Link
                    key="detail"
                    icon="details"
                    level={ 3 }
                    to={ `/detail-dossier-contrat/${doc.Id}` }
                >Voir dossier</Link>
                : <AtlasButton
                    key="detail-interdit"
                    icon="details"
                    level={ 3 }
                    disabled
                >Vous n'avez pas le droit de consulter ce dossier</AtlasButton>
        ]

        const reglements = facture?.Reglements ?? []

        const sommeReglements = (reglements: Reglement[]) => {
            var sommeReglementsHT = 0
            var sommeReglementsTTC = 0
            if (!reglements)
                return null
    
            // eslint-disable-next-line array-callback-return
            reglements?.map((item) => {
                sommeReglementsHT += parseFloat(item.MontantHT__c)
                sommeReglementsTTC += parseFloat(item.MontantTTC__c)
            })
           
            return (
                <AtlasValues.Grid>
                    <AtlasValue label="Somme réglements HT">{ formatCurrency(sommeReglementsHT) }</AtlasValue>
                    <AtlasValue label="Somme réglements TTC">{ formatCurrency(sommeReglementsTTC) }</AtlasValue>
                </AtlasValues.Grid>
            )
        }

        const depotPieces = (documents: FacturePieceDTO[]) => {
            if (facture === null || facture.EtatFactureFO__c !== ETAT_FACTURE_INCOMPLET )
                return undefined

            return <DepotFichier idParent={ facture.Id }
                documents={ documents ?? [] }
                transmissionEnCours={ facture.EnCoursTransmission__c }
                disabled={ facturePending }
                sendDocuments={ sendFactureDocuments }
                checkSendDocumentsStatus={ getFactureDocumentsStatus }
                confirmTitle="Confirmer l'ajout de nouvelles pièces"
                confirmMessage="Les nouvelles pièces ajoutées seront transmises à nos services."
                sendSuccessMessage="Les nouvelles pièces ont bien été ajoutées à la facture"
                refreshEvent={ detailFactureRefreshEvent }
                DepotFichierModal={ FactureDepotFichierModal } />
        }

        return [
            <div key="details" id="details">
                <AtlasHeading tag="h2" size="m">Détails facture</AtlasHeading>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { facture ? <AtlasValue label="État">
                                <State type="bill" id={facture.EtatFactureFO__c}>
                                    { facture.EtatFactureFORef?.LibelleReduit__c ?? '' }
                                </State>
                            </AtlasValue> : <></> }
                            <AtlasValue label="Type">{facture?.Type__c === TYPE_FACTURE_AVOIR ? 'Avoir' : 'Facture'}</AtlasValue>
                        </AtlasValues.Grid>
                        <AtlasValue label="Référence facture">{facture?.RefFactureClient__c || ''}</AtlasValue>
                        <AtlasValues.Grid>
                            <AtlasValue label="Émise le">{formatApiDate(facture?.DateEmission__c)}</AtlasValue>
                            <AtlasValue label="Reçue le">{formatApiDate(facture?.DateReception__c)}</AtlasValue>
                            <AtlasValue label="Montant HT">{formatCurrency(facture?.MontantFactureHT__c)}</AtlasValue>
                            <AtlasValue label="Montant TTC">{formatCurrency(facture?.MontantTTC__c)}</AtlasValue>
                        </AtlasValues.Grid>
                        { sommeReglements(reglements) || <></> }
                        <AtlasValue label="Type émetteur">{facture?.TypeEmetteur === TYPE_EMETTEUR_ADHERENT ? 'Adhérent' : 'Prestataire'}</AtlasValue>
                    </AtlasValues>
                </MyContainer>
            </div>,

            <div key="dossiers" id="dossiers">
                <AtlasHeading tag="h2" size="m">Dossiers</AtlasHeading>
                <MyTable
                    key="dossiers"
                    caption="Dossiers"
                    rows={dossiers}
                    emptyText="Aucun dossier"
                    rowActions={dossierActions}
                    columns={[
                        {
                            id: "NumeroDossier__c",
                            label: "Numéro",
                            bold: true,
                            nowrap: true
                        },
                        {
                            id: "Intitule__c",
                            label: "Intitulé"
                        },
                        {
                            id: "DateCreationFO__c",
                            label: "Date d'initialisation",
                            value: (row: any) => formatApiDate(row.DateCreationFO__c)
                        }
                    ]}
                />
            </div>,

            <div key="reglements" id="reglements">
                <AtlasHeading tag="h2" size="m">Règlements</AtlasHeading>
                <AtlasLoading loading={facturePending}>
                    <MyTable
                        key="reglements"
                        caption="Règlements"
                        emptyText="Aucun règlement"
                        rows={reglements}
                        columns={[
                            {
                                id: "ReferencePaiement__c",
                                label: "N° de règlement",
                                wrap: true
                            },
                            {
                                id: "NumeroDossier__c",
                                label: "N° dossier",
                                nowrap: true,
                                value: (row: any) => row.Dossier?.NumeroDossier__c
                            },
                            {
                                id: "DateReglement__c",
                                label: "Date de règlement",
                                value: (row: any) => [
                                    formatApiDate(row.DateReglement__c),
                                    <AtlasText small>{capitalize(findModeReglement(row.ModeReglement__c)?.LibelleReduit__c ?? '')}</AtlasText>
                                ]
                            },
                            {
                                id: "MontantHT__c",
                                label: "Montant",
                                value: (row: any) => [
                                    <AtlasText bold>{formatCurrency(row.MontantTTC__c, '\xa0TTC')}</AtlasText>,
                                    <AtlasText small>{formatCurrency(row.MontantHT__c, '\xa0HT')}</AtlasText>
                                ]
                            },
                            {
                                id: "TypeEmetteur",
                                label: "Bénéficiaire",
                                wrap: true,
                                value: (row: any) => row.Facture?.EtablissementEmetteur?.Name + ' - ' + row.Facture?.EtablissementEmetteur?.BillingCity
                            },
                        ]}
                    />
                </AtlasLoading>
            </div>,

            <div key="documents" id="documents">
                <DetailDocuments key="documents" idParent={ facture?.Id ?? null } 
                    name="DetailFactureDocuments"
                    depotPieces={ depotPieces } 
                    transmissionEnCours={ facture?.EnCoursTransmission__c }
                    getDocuments={ getFactureDetailFactures }
                    downloadDoc={ (doc: FacturePieceDTO) => downloadFactureDoc(doc, facture) }
                    documentsRefreshEvent={ detailFactureRefreshEvent }
                    deleteDoc={ deleteDocumentFacture }
                />
            </div>
        ]
    }

    return <Detail<Facture> name={ detailName }
        paramName="idfacture"
        title={ (facture: Facture | null) => `Facture N°${facture?.RefFactureClient__c ?? ''}`}
        getId={ (facture: Facture | null) => facture?.Id ?? null }
        getDetail={ getFacture }
        loadErrorMessage="Erreur lors de récupération de la facture"
        history={ history }
        nav={[
            {targetId: "details", label: "Détails facture"},
            {targetId: "dossiers", label: "Dossiers"},
            {targetId: "reglements", label: "Règlements"},
            {targetId: "documents", label: "Documents"}
        ]}
        sections={ sections }
    />
}
