import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import MesRapports from './MesRapports'


const rapportsModule = () => { 
    return {
        id: 'mes-rapports',
        isEnabled: () => import.meta.env.REACT_APP_MODULES?.includes('rapports'),
        routes: [
            <AppRoute key="tableaux-de-bord" path="/mes-tableaux-de-bord" component={MesRapports} label="Tableaux de bord" menuIcon="rapports" />,
        ],
    } as AppModule
}

export default rapportsModule