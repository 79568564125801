import { UseFormReturn, useFormContext } from "@dsid-opcoatlas/reform"
import { AtlasButton } from "atlas-ds"
import { ButtonHTMLAttributes } from "react"

interface ButtonProps<T extends object> extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type" | "value" | "onClick"> {
    children: string
    level?: 1 | 2 | 3 | 4
    submit?: boolean
    spinner?: { spinning: boolean }
    destructive?: boolean
    onClick?: (form: UseFormReturn<T>) => void
}

export default function Button<T extends object>(props: ButtonProps<T>) {

    const { children, level, submit, spinner, onClick, disabled, ...otherProps } = props

    const context = useFormContext<T>()

    const internalOnMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (props.submit !== true)
            event.preventDefault()
    }

    const internalOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(context)
    }

    return (
        <AtlasButton
            {...otherProps}
            type={ submit ? 'submit' : 'button' }
            level={ level }
            spinner={ spinner }
            onMouseDown={ internalOnMouseDown }
            onClick={ internalOnClick }
            disabled={ context?.submitting || disabled }
            destructive={ props.destructive }
        >{ children }</AtlasButton>
    )
}
