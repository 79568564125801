import { useEffect, useRef } from 'react'

export class AppEvent<T> extends CustomEvent<T> {

    readonly isError: boolean
    readonly error: any
    
    constructor(type: string, detail?: T, error?: any, isError = false) {
        super(type, { detail })
        this.error = error
        this.isError = isError
    }

    reject(): Promise<any> {
        return Promise.reject(this.error)
    }

    resolve<S>(resolver?: (detail: T) => S): Promise<S> {
        return Promise.resolve(resolver ? resolver(this.detail) : this.detail as unknown as S)
    }

    promise<S>(resolver?: (detail: T) => S): Promise<S> {
        return this.isError ? this.reject() : this.resolve(resolver)
    }
}

export class AppErrorEvent extends AppEvent<any> {

    constructor(type: string, error: any) {
        super(type, {}, error, true)
    }
}

export async function dispatchAppEvent<T>(type: string, value?: T) {
    return await dispatchEvent(new AppEvent<T>(type, value))
}

export async function dispatchErrorEvent(type: string, error: any) {
    return await dispatchEvent(new AppErrorEvent(type, error))
}

// export async function dispatchPromiseEvent<T>(type: string, promise: Promise<T>) {
//     try {
//         return await dispatchAppEvent(type, await promise)
//     }
//     catch (error) {
//         return dispatchErrorEvent(type, error)
//     }
// }

export default function useEventListener<T>(type: string, listener: ((ev: AppEvent<T>) => void)) {

    const savedListener = useRef<((ev: AppEvent<T>) => void)>()

    useEffect(() => {
        savedListener.current = listener
    }, [listener]);

    const eventListener = ((ev: AppEvent<T>) => savedListener.current && savedListener.current(ev)) as EventListener
    useEffect(() => {
        window.addEventListener(type, eventListener)
        return () => {
            window.removeEventListener(type, eventListener)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])
}
