import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'


export default function Signature({ signature }: { signature: any }) {
  return (
    <View style={[styles.section, styles.signatureSection]}>
      <View style={styles.sectionContent}>
        <Text style={styles.boldItalic}>
          <Checkbox checked={signature.piecesJustificatives}></Checkbox>{' '}
          L’employeur atteste disposer de l’ensemble des pièces justificatives
          nécessaires au dépôt du contrat
        </Text>
        <Text>
          Fait à <CerfaTextField value={ signature.lieu } />
        </Text>
        <View style={[styles.cols, styles.boldItalic, styles.small]}>
          <View style={styles.tripleCol}>
            <Text>Signature de l'employeur</Text>
          </View>
          <View style={styles.tripleCol}>
            <Text>Signature de l'apprenti(e)</Text>
          </View>
          <View style={styles.tripleColLast}>
            <Text>
              Signature du représentant légal de l’apprenti(e) mineur(e)
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
