import { AtlasCheckbox, AtlasLoading } from "atlas-ds";

export interface MyFilterProps {
  /**
   * Le nom du filtre
   */
  label: string;
  /**
   * L'identifiant utilisé pour la case à cocher
   */
  id: string;
  /**
   * L'action à exécuter lorsque le filtre change d'état
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Le filtre est-il activé ?
   */
  checked?: boolean;
  /**
   * La variable CSS de couleur associée à ce filtre
   */
  color?: string;
}

/**
 * Un filtre activable
 */
export function MyFilter(props: MyFilterProps) {
  return (
    <div
      className="my-filter"
      style={
        props.color
          ? ({
              "--my-filter-color": `${props.color}`,
              "--atlas-checkbox-accent-color": `${props.color}`,
            } as React.CSSProperties)
          : undefined
      }
    >
      <AtlasLoading.Loader />
      <AtlasCheckbox
        name={"filter-" + props.id}
        value={"filter-" + props.id}
        checked={props.checked}
        onChange={props.onChange}
        label={props.label}
      />
    </div>
  );
}
