import { useEffect, useMemo, useRef, useState } from "react"
import { debounce, DebouncedFunc } from "lodash"

export type SearchDebounceOptions = {
    minChars?: number
    delay?: number
    immediate?: boolean
    empty?: boolean
}

export default function useSearchDebounce<T>(apiMethod: (search: string) => Promise<T[] | null>, options?: SearchDebounceOptions):
    [
        DebouncedFunc<(value?: string | null) => void>,
        boolean,
        T[] | null,
        React.Dispatch<React.SetStateAction<T[] | null>>,
        string | null,
        Required<SearchDebounceOptions>
    ] {

    const searchOptions = {
        minChars: options?.minChars ?? 3,
        delay: options?.delay ?? 750,
        immediate: options?.immediate ?? false,
        empty: options?.empty ?? false,
    }
    const searchConfig = { ...searchOptions, apiMethod }
    const searchConfigRef = useRef(searchConfig)
    searchConfigRef.current = searchConfig

    const [searching, setSearching] = useState(searchConfigRef.current.immediate)
    const [error, setError] = useState<string | null>(null)
    const [values, setValues] = useState<T[] | null>(null)

    const search = (value: string) => {
        setSearching(true)
        setError(null)
        searchConfigRef.current.apiMethod(value).then(values => {
            setValues(values)
        }).catch(e => {
            setValues(null)
            setError(e?.body?.message ?? "Une erreur inattendue s'est produite")
        }).finally(() => {
            setSearching(false)
        })
    }

    const debouncedFunction = useMemo(() => (
        debounce((value?: string | null) => {
            const length = value?.length ?? 0
            if ((searchConfigRef.current.empty && length === 0) || length >= searchConfigRef.current.minChars)
                search(value ?? '')
            else
                setValues([])
        }, searchConfigRef.current.delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [])

    useEffect(() => {
        return () => debouncedFunction.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (searchConfigRef.current.immediate)
            search('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchConfigRef.current.immediate])

    return [debouncedFunction, searching, values, setValues, error, searchOptions]
}