import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { AtlasFlex, AtlasLink, MyAppAccess, MyTheme } from 'atlas-ds'
import Link from 'components/Link'
import { AppType } from 'slices/contextSlice'
import { AccueilCompteExtension } from '../accueil/AccueilCompte'
import { AttacherUneEntreprise } from './AttacherUneEntreprise'
import DetailEntreprise from './DetailEntreprise'
import { MesEntreprises } from './MesEntreprises'
import { WebAdhesion } from './WebAdhesion'
import { WebAdhesionSucces } from './WebAdhesionSuccess'

const mesEntreprisesModule = {
    id: 'mes-entreprises',
    routes: (_, appState) => [
        ...appState?.appTypes?.includes(AppType.Entreprise) ? [
            <AppRoute exact key="mes-entreprises" path="/mes-entreprises" label="Mes entreprises" menuIcon="dossiers">
                <MesEntreprises />
            </AppRoute>,
            <AppRoute exact key="mes-entreprise-detail" path="/mes-entreprises/detail/:idEntreprise">
                <DetailEntreprise />
            </AppRoute>
        ] : [],
        <AppRoute exact key="mes-entreprises-attacher" path="/mes-entreprises/attacher" label="Attacher une entreprise">
            <AttacherUneEntreprise />
        </AppRoute>,
        <AppRoute exact key="mes-entreprise-web-adhesion" path="/mes-entreprises/web-adhesion/:siren">
            <WebAdhesion />
        </AppRoute>,
        <AppRoute exact key="mes-entreprise-web-adhesion-succes" path="/mes-entreprises/web-adhesion-succes">
            <WebAdhesionSucces />
        </AppRoute>,
    ],
    extensions: {
        accueilCompte: {
            appAccess: (
                <MyAppAccess
                    key="access.entreprise"
                    app="entreprise"
                    keyPoints={[
                        "Effectuer des demandes",
                        "Suivre mes dossiers",
                        "Consulter mes factures"
                    ]}
                    link={<AtlasLink href="/?contexte=entreprise">Accéder à myAtlas Entreprise</AtlasLink>} />
            ),
            attach: (
                <MyTheme key="attach.prestataire" theme="prestataire">
                    <AtlasFlex alignItems="baseline" wrap columnGap="m">
                        <p>Vous représentez également un prestataire de formation ?</p>
                        <Link level={3} to="/mes-prestataires/attacher" icon="plus">Attacher un prestataire</Link>
                    </AtlasFlex>
                </MyTheme>
            ),
        },
    } as AccueilCompteExtension,
} as AppModule

export default mesEntreprisesModule