import { getReferences, References } from 'api/referencesAPI'
import { createApiSlice } from 'api/createApiSlice'
import { addDays, formatISO, isBefore, parseISO } from 'date-fns'
import { removeConfigFromStorage } from './configSlice'
import store from 'app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { profilActions } from './profilSlice'

const name = 'references/get'
const REFS_VERSION_MIN = 0
const REFERENCES_DATE_KEY = 'references-date'

const storeReferencesDate = () => {
    sessionStorage.setItem(REFERENCES_DATE_KEY, formatISO(new Date(), { representation: 'date' }))
}

export const referencesSlice = createApiSlice({
    name,
    cacheable: (refs: References) => (refs.Version ?? 0) >= REFS_VERSION_MIN && refs.REF_NIVEAU_MAX_CLASSE_ENTREE_FORMATION !== undefined,
    call: getReferences,
    postcall: storeReferencesDate
})

export const referencesActions = {
    load: () => referencesSlice.actions.caller(null),
    clear: () => referencesSlice.actions.clear(),
}

export const removeReferencesFromStorage = () => sessionStorage.removeItem('myatlas.' + name)

export const checkReferencesExpiry = () => {
    const date = sessionStorage.getItem(REFERENCES_DATE_KEY)

    if (!date)
        storeReferencesDate()
    else if (isBefore(parseISO(date), addDays(new Date(), -1))) {
        removeReferencesFromStorage()
        removeConfigFromStorage()

        store.dispatch(referencesActions.clear())
        store.dispatch(profilActions.clearServices())

        store.dispatch(referencesActions.load()).then(unwrapResult).then(_ => {
            store.dispatch(profilActions.getServices())
        })
        return true
    }

    return false
}
