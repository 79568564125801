import { Ignored, OptionalEmail, OptionalPhone, OptionalString } from 'components/yop/constraints'
import ExportTextField from 'pages/dossier/export/ExportTextField'
import RecapTextField from 'pages/dossier/recap/RecapTextField'
import TextField from 'components/reform/inputs/TextField'
import { createMetadata, FormMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import PhoneField from 'components/reform/inputs/PhoneField'
import NameField from 'components/reform/inputs/NameField'
import { UseFormReturn } from '@dsid-opcoatlas/reform'
import { BaseDossier } from 'slices/dossierCommon'
import NomAutocompleteField from 'components/reform/inputs/NomAutocompleteField'

export function TextFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    return createMetadata(
        {
            input: TextField,
            recap: RecapTextField,
            export: ExportTextField,
            yop: OptionalString,
        },
        settings,
    )
}

export function PhoneFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    return TextFieldMetadata({ input: PhoneField, ...settings }).mutate(_ => OptionalPhone)
}

export function FirstNameFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    // Champ spécifique pour les prénoms, à utiliser éventuellement plus tard pour les contrôles multi prénoms
    return TextFieldMetadata({ input: NameField, ...settings })
}

export function NameFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    return TextFieldMetadata({ input: NameField, ...settings })
}

export function AutocompleteNameFieldMetadata<D extends BaseDossier, T>(settings: SettingsFieldMetadata<string | null> & {
    search: ((form: UseFormReturn<D>, recherche: string, metadata: FormMetadata<D>) => Promise<T[]>)
}) {
    const { search, ...otherSettings } = settings
    return TextFieldMetadata({ input: (props) => <NomAutocompleteField { ...props } search={ search } />, ...otherSettings })
}

export function EmailFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    return TextFieldMetadata(settings).mutate(_ => OptionalEmail)
}

export function ExportedOnlyTextFieldMetadata(settings: SettingsFieldMetadata<string | null> = {}) {
    return createMetadata(
        {
            input: undefined,
            recap: undefined,
            export: ExportTextField,
            yop: Ignored,
        },
        settings,
    )
}
