import { AtlasLoading } from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler } from "react";

export interface MyTabProps {
  /**
   * Le label de l'onget
   */
  children: string;
  /**
   * L'action à éxécuter au clic sur l'onglet
   */
  onClick?: MouseEventHandler;
  /**
   * L'onglet est-il celui actif actuellement ?
   */
  active?: boolean;
  /**
   * Désactiver l'onglet
   */
  disabled?: boolean;
  /**
   * L'onglet est-il le premier de la série ?
   */
  first?: boolean;
  /**
   * L'onglet est-il le dernier de la série ?
   */
  last?: boolean;
}

/**
 * Un onglet permettant d'accéder à un sous-ensemble de données. Destiné à être
 * utilisé en groupe.
 */
export function MyTab(props: MyTabProps) {
  return (
    <button
      type="button"
      className={classNames("my-tab", {
        "my-tab--active": props.active,
        "my-tab--first": props.first,
        "my-tab--last": props.last,
      })}
      disabled={props.disabled}
      aria-hidden={props.disabled}
      onClick={props.onClick}
    >
      <AtlasLoading.Loader />
      <span>{props.children}</span>
    </button>
  );
}
