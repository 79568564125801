import { useEffect, useRef } from "react"

export interface MyConfettisProps {
  /**
   * L'élément duquel partiront visuellement les confettis
   */
  children: React.ReactNode;
}

/**
 * Englobez un élément dans ce composant pour en faire jaillir des confettis 🎉
 * Les confettis sont à consommer avec modération
 */
export function MyConfettis(props: MyConfettisProps) {
  // Following code is adapted from https://codepen.io/ksenia-k/pen/YzqzdxE

  if (window.matchMedia(`(prefers-reduced-motion: reduce)`).matches)
    return <>{props.children}</>;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const confetti = canvasRef.current;
    const wrapper = wrapperRef.current;

    if (!confetti) return;

    const confettiCtx = confetti.getContext("2d");
    let container: any;
    const confettiElements: any = [];

    const rand = (min: any, max: any) => Math.random() * (max - min) + min;

    const confettiParams = {
      // number of confetti per "explosion"
      number: 70,
      // min and max size for each rectangle
      size: { x: [5, 20], y: [10, 18] },
      // power of explosion
      initSpeed: 25,
      // defines how fast particles go down after blast-off
      gravity: 0.65,
      // how wide is explosion
      drag: 0.08,
      // how slow particles are falling
      terminalVelocity: 6,
      // how fast particles are rotating around themselves
      flipSpeed: 0.017,
    };
    const colors = [
      { front: "hsl(261, 74%, 22%)", back: "hsl(239, 28%, 70%)" },
      { front: "hsl(257, 67%, 59%)", back: "hsl(268, 100%, 85%)" },
      { front: "hsl(151, 81%, 35%)", back: "hsl(157, 65%, 72%)" },
    ];

    setupCanvas();
    updateConfetti();

    class Conf {
      randomModifier: any;
      colorPair: any;
      dimensions: any;
      position: any;
      rotation: any;
      scale: any;
      velocity: any;
      flipSpeed: any;
      terminalVelocity: any;
      color: any;

      constructor() {
        if (!wrapper) return;

        this.randomModifier = rand(-1, 1);
        this.colorPair = colors[Math.floor(rand(0, colors.length))];
        this.dimensions = {
          x: rand(confettiParams.size.x[0], confettiParams.size.x[1]),
          y: rand(confettiParams.size.y[0], confettiParams.size.y[1]),
        };

        const wrapperBox = wrapper.getBoundingClientRect();
        this.position = {
          x: (wrapperBox.left + wrapperBox.right) / 2,
          y: wrapperBox.bottom,
        };
        this.rotation = rand(0, 2 * Math.PI);
        this.scale = { x: 1, y: 1 };
        this.velocity = {
          x: rand(-confettiParams.initSpeed, confettiParams.initSpeed) * 0.4,
          y: rand(-confettiParams.initSpeed, confettiParams.initSpeed),
        };
        this.flipSpeed = rand(0.2, 1.5) * confettiParams.flipSpeed;

        if (this.position.y <= container.h) {
          this.velocity.y = -Math.abs(this.velocity.y);
        }

        this.terminalVelocity = rand(1, 1.5) * confettiParams.terminalVelocity;
      }

      update() {
        this.velocity.x *= 0.98;
        this.position.x += this.velocity.x;

        this.velocity.y += this.randomModifier * confettiParams.drag;
        this.velocity.y += confettiParams.gravity;
        this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity);
        this.position.y += this.velocity.y;

        this.scale.y = Math.cos(
          (this.position.y + this.randomModifier) * this.flipSpeed
        );
        this.color =
          this.scale.y > 0 ? this.colorPair.front : this.colorPair.back;
      }
    }

    function updateConfetti() {
      if (!confettiCtx) return;

      confettiCtx.clearRect(0, 0, container.w, container.h);

      confettiElements.forEach((c: any) => {
        c.update();
        confettiCtx.translate(c.position.x, c.position.y);
        confettiCtx.rotate(c.rotation);
        const width = c.dimensions.x * c.scale.x;
        const height = c.dimensions.y * c.scale.y;
        confettiCtx.fillStyle = c.color;
        confettiCtx.fillRect(-0.5 * width, -0.5 * height, width, height);
        confettiCtx.setTransform(1, 0, 0, 1, 0, 0);
      });

      confettiElements.forEach((c: any, idx: any) => {
        if (
          c.position.y > container.h ||
          c.position.x < -0.5 * container.x ||
          c.position.x > 1.5 * container.x
        ) {
          confettiElements.splice(idx, 1);
        }
      });
      window.requestAnimationFrame(updateConfetti);
    }

    function setupCanvas() {
      if (!confetti) return;

      container = {
        w: confetti.clientWidth,
        h: confetti.clientHeight,
      };
      confetti.width = container.w;
      confetti.height = container.h;
    }

    for (let i = 0; i < confettiParams.number; i++) {
      confettiElements.push(new (Conf as any)());
    }
  }, []);

  return (
    <div ref={wrapperRef} className="my-confettis">
      <canvas ref={canvasRef} />
      {props.children}
    </div>
  );
}
