import { AtlasValue } from 'atlas-ds'
import { DossierCAPPFormation } from 'pages/dossier-capp/CAPPModel'

interface RecapDiplomeProps {
    parent: DossierCAPPFormation | null
}

export default function RecapDiplome(props: RecapDiplomeProps) {
    return (<>
        <AtlasValue label="Intitulé">{ props.parent?.diplome?.intitulePrecis }</AtlasValue>
        <AtlasValue label="Code diplôme">{ props.parent?.diplome?.codeDiplome }</AtlasValue>
        <AtlasValue label="Code RNCP">{ props.parent?.diplome?.codeRNCP }</AtlasValue>
    </>)
}