import { AtlasColumns, AtlasHtml } from "atlas-ds"
import PageHeader from "components/PageHeader"

export default function PlanAction () {
  return <>
    <PageHeader>Plan d'action d'accessibilité</PageHeader>

    <AtlasColumns>
      <AtlasHtml>
        <p>Le plan d’action détaille les actions menées par Atlas pour améliorer continuellement l’accessibilité de ses services en ligne.</p>

        <p>Dernière mise à jour le <time dateTime="2022-03-22">29 août 2024</time>.</p>

        <h2>Actions réalisées</h2>

        <h3>2021</h3>

        <ul>
          <li>
            Refonte complète du service en utilisant des outils d’accessibilité :
            <ul>
              <li>Plugin Axe DevTools</li>
              <li>Plugin Taiko Accessibility</li>
              <li>Validateur HTML W3C</li>
              <li>Vérificateur de contrastes WebAIM</li>
            </ul>
          </li>
          <li>Mise en place de tests d’accessibilité automatisés sur une partie des pages</li>
        </ul>

        <h3>2022</h3>

        <ul>
          <li>Audit RGAA mené en interne</li>
          <li>Correction d’une partie des erreurs relevées au cours de l’audit</li>
          <li>Audit des améliorations envisageables sur le page de connexion (externe au projet)</li>
        </ul>

        <h3>2023</h3>

        <ul>
          <li>Mise en place d'un Design System permettant la généralisation de composants accessibles</li>
        </ul>

        <h3>2024</h3>

        <ul>
          <li>Refonte du système de formulaires pour notamment améliorer l’accessibilité des messages d’erreur et des composants interactifs</li>
          <li>Remplacement de la librairie de sélection de plages de date par une librairie accessible</li>
        </ul>

        <h2>Actions en cours et à venir en 2025</h2>

        <ul>
          <li>Refonte de l'interface de connexion</li>
          <li>Nouvel audit RGAA en interne</li>
        </ul>
      </AtlasHtml>
    </AtlasColumns>
  </>
}
