import { Value } from 'pages/dossier/cerfa/CerfaDpc'

interface ExportBooleanFieldProps {
    label: string
    exportLabel?: string
    row?: boolean
    cell: boolean
    value: boolean
}

export default function ExportBooleanField(props: ExportBooleanFieldProps) {
    if (props.value == null) {
        return props.cell ? <Value cell value={{ value: '' }} /> : null
    }

    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.exportLabel ?? props.label,
        value: props.value == null ? '' : (props.value ? 'Oui' : 'Non'),
    }} />
}
