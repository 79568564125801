import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierVAEI } from './VAEIModel'
import { SECTION_VAEI_CONTRAT } from './VAEIContratMetadata'

export function DossierVAEIContratElements<D extends DossierVAEI>(props: { metadata: FormMetadata<D> }) {

    return (
        <>
            <MyForm.Grid>
                <FormElements section={ SECTION_VAEI_CONTRAT } metadata={ props.metadata } path="contrat" type="input" />
            </MyForm.Grid>
        </>
    )
}

