import { formatAdresse } from "components/format/Format"
import { DossierAF } from "slices/dossierCommon"
import store from "app/store"
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { formElements } from 'pages/dossier/metadata/FormElements'
import { SECTION_EMPLOYEUR_CONTACT } from 'pages/dossier/metadata/EmployeurMetadata'


export function exportDossier<D extends DossierAF<any, any>>(metadata: FormMetadata<D>, dossier: D | null): any {

    const contact = store.getState().profilState.profil?.Contact

    if (dossier === null || !contact) {
        return { steps: [] }
    }

    const steps = metadata.steps.filter(s => !s.stepButton)

    const data = {
        nomDispositif: metadata.title,
        actionDispositif: 'Demande de prise en charge',
        steps: [ {
            title: steps[0].title ?? 'Employeur',
            values: [
              { label: 'Raison sociale', value: dossier.employeur.nom },
              { label: 'SIRET', value: dossier.employeur.numeroSiret },
              { label: 'Adresse', value: formatAdresse(dossier.employeur, dossier.employeur.codePostal, dossier.employeur.commune) },
            ].concat(steps[0].sections.includes(SECTION_EMPLOYEUR_CONTACT) ?
              [{ label: 'Contact', value: `${contact.FirstName ?? ''} ${contact.LastName ?? ''} -  ${contact.Phone ?? ''} - ${contact.Email ?? ''}` }] : []
            ) as ({ label: string, value: string } | any)[]
            
        } ].concat(
            dossier.modules?.map((_, index) => {
                return {
                    title: (steps[1].title ?? 'Formation') + ' ' + (index+1),
                    values: formElements(metadata, metadata.api.isEdit!, dossier, 'modules', index, undefined, 'export')
                }
            }) ?? []

        ).concat(
            dossier.salaries?.map((_, index) => {
                return {
                    title: 'Salarié ' + (index+1),
                    values: formElements(metadata, metadata.api.isEdit!, dossier, 'salaries', index, undefined, 'export')
                }
            }) ?? []

        ).concat([ {
            title: 'Signature',
            values: formElements(metadata, metadata.api.isEdit!, dossier, 'signature', undefined, undefined, 'export')
        } ])
    }

    return data
}
