import { HANDICAP_OUI, SITUATION_SALARIE, SITUATION_SALARIE_INACTIF, SITUATION_SALARIE_RECHERCHE_EMPLOI } from "api/references"
import { AdresseFieldMetadata, adresseMapping } from "components/metadata/AdresseFieldMetadata"
import { BooleanFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { DurationFieldMetadata } from "components/metadata/DurationFieldMetadata"
import { PhoneFieldMetadata, TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import CommuneField from "components/reform/inputs/CommuneField"
import DepartementField from "components/reform/inputs/DepartementField"
import RadioBooleanField from "components/reform/inputs/RadioBooleanField"
import { addYears, isBefore } from "date-fns"
import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ElementContext, IgnoredFieldMetadata, ObjectMetadata, override } from "pages/dossier/metadata/FormMetadata"
import { salarieFields } from "pages/dossier/metadata/SalariesMetadata"
import { DossierVAEI, DossierVAEISalarie, createDossierVAEISalarie } from "./VAEIModel"


export const isVAEISalarieInactif = (salarie?: DossierVAEISalarie, situationAvantContrat?: string, handicap?: string, typeMinimumSocial?: string | null) => {
    situationAvantContrat = situationAvantContrat ?? salarie?.situationAvantContrat
    handicap = handicap ?? salarie?.handicap
    typeMinimumSocial = typeMinimumSocial ?? salarie?.typeMinimumSocial
    return (situationAvantContrat === SITUATION_SALARIE_INACTIF 
        || situationAvantContrat === SITUATION_SALARIE_RECHERCHE_EMPLOI 
        || situationAvantContrat === SITUATION_SALARIE)
        && !typeMinimumSocial
        && handicap !== HANDICAP_OUI
}


export const VAEISalarieMetadata: ObjectMetadata<DossierVAEISalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: override(salarieFields.prenom, { props: { 
        tooltip: "Renseigner un seul prénom. Celui-ci peut être le prénom d’usage (le second ou troisième de l’état civil si choisi par le salarié)" } 
    }),
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    telephone: PhoneFieldMetadata({
        props: {
            label: 'Téléphone',
        },
        mapping: simpleMapping('Telephone__c'),
    }),
    handicap: salarieFields.handicap,
    adresse: AdresseFieldMetadata({
        mapping: adresseMapping('Adresse1__c', 'Adresse2__c', 'Adresse3__c', 'Adresse4__c', 'CodePostal__c', 'Ville__c')
    }).required(),
    naissanceFrance: BooleanFieldMetadata({
        props: {
            label: "Lieu de naissance",
            labelYes: 'France',
            labelNo: 'Etranger',
            alone: true
        } as any,
        input: props => <RadioBooleanField { ...props }
            onChange={ (value, ctx) => {
                ctx.setValue(props.parentPath + '.communeNaissance', '')
                if (value)
                    ctx.setValue(props.parentPath + '.departementNaissance', null)
                else {
                    ctx.setValue(props.parentPath + '.departementNaissance', '099')
                }
            } } />,
        mapping: {
            mapDTOToModel: (dto: any) => dto.DepartementNaissance__c !== '099',
            mapModelToDTO: (value, dto) => { if (value === false) { dto.DepartementNaissance__c = '099' }},
        },
    }).required(),
    communeNaissance: TextFieldMetadata({
        props: {
            label: 'Commune de naissance',
        },
        input: props => <CommuneField { ...props } departementName="departementNaissance" />,
        mapping: simpleMapping('CommuneNaissance__c'),
    }).required().mutate(yop => yop.max(50)),
    departementNaissance: TextFieldMetadata({
        props: {
            label: "Département de naissance",
        },
        input: props => <DepartementField { ...props } communeName="communeNaissance" />,
        mapping: simpleMapping('DepartementNaissance__c'),
        visibleIf: ctx => ctx.parent?.departementNaissance !== '099',
    }).required().mutate(yop => yop.matches(/^((\d){2,3}|2A|2B|69D|69M)$/, 'Le département doit comporter 2 ou 3 chiffres')),
    statut: override(salarieFields.statut, { props: {
        alone: true
    }}),
    situationAvantContrat: salarieFields.situationAvantContrat,
    plusHautDiplome: override(salarieFields.plusHautDiplome, {
        mapping: simpleMapping("DiplomeObtenu__c"),
    }),
    diplomeVise: salarieFields.diplomeVise,
    typeMinimumSocial: salarieFields.typeMinimumSocial,
    poleEmploi: override(salarieFields.poleEmploi, { props: {
        alone: true
    }}).mutate(yop => yop.test<DossierVAEISalarie, DossierVAEI>(ctx => {
        const dateNaissance = ctx.parent?.dateNaissance
        const dateDebutContrat = ctx.root?.contrat?.dateDebut
        if (ctx.value === false && dateNaissance != null && dateDebutContrat != null) {
            const plus26ans = isBefore(addYears(dateNaissance, 26), dateDebutContrat)
            if (plus26ans && isVAEISalarieInactif(ctx.parent))
                return ctx.createError("Public éligible au dossier VAEI : demandeur d'emploi de plus de 26 ans (Article L6325-1 du code du travail). Indiquez Oui dans Pôle emploi et renseignez le numéro et la durée.")
        }
        return true
    })),
    numeroPoleEmploi: TextFieldMetadata({
        props: {
            label: "Numéro d'inscription Pôle Emploi",
        },
        visibleIf: (ctx: ElementContext<DossierVAEISalarie>) => ctx.parent?.poleEmploi === true,
        mapping: simpleMapping('NumeroInscriptionPoleEmploi__c'),
    }).mutate(yop => yop.when<DossierVAEISalarie>(
        ctx => ctx.parent?.poleEmploi === true ?
        yop.required().matches(/^[\da-zA-Z]{1,9}$/, "Format de numéro Pôle Emploi incorrect (9 chiffres ou lettres maximum)") :
        yop.ignored()
    )),
    dureePoleEmploi: DurationFieldMetadata({
        props: {
            label: "Durée d'inscription Pôle Emploi",
            suffix: 'mois',
        },
        visibleIf: (ctx: ElementContext<DossierVAEISalarie>) => ctx.parent?.poleEmploi === true,
        mapping: simpleMapping('DureeMoisPoleEmploi__c'),
    }).mutate(yop => yop.requiredIf<DossierVAEISalarie>(ctx => ctx.parent?.poleEmploi === true)),
    niveauMaximumClasseEntreeFormationAgora: override(salarieFields.niveauMaximumClasseEntreeFormationAgora, {
        props: {
            full: true
        }
    }),
    situationHandicap: IgnoredFieldMetadata(simpleMapping('SituationHandicap__c')),
    nationalite: IgnoredFieldMetadata(),
    statutEntreeEnFormationAgora: IgnoredFieldMetadata(),
}

export function dossierVAEISalarieInitializer<D extends DossierVAEI>(dossier: D) {
    if (dossier.salarie == null)
        dossier.salarie = createDossierVAEISalarie()
    return dossier
}
