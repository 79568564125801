import { AtlasLinkConstructor, AtlasValueProps } from "atlas-ds";
export interface MyItemPreviewProps {
  /**
   * Les valeurs
   */
  children: React.ReactElement<AtlasValueProps>[];
  /**
   * Si nécessaire, un constructeur de lien qui rendra l'élément cliquable
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Un bloc contenant les informations principales d'un élément
 */
export function MyItemPreview(props: MyItemPreviewProps) {
  const values = <div className="my-itemPreview__values">{props.children}</div>;

  return (
    <article className="my-itemPreview">
      {props.linkConstructor ? props.linkConstructor({}, values) : values}
    </article>
  );
}
