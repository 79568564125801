import { MyModal } from 'atlas-ds'

interface SendModalProps {
    pending: boolean
}

export default function SendModal(props: SendModalProps) {
    return (
        <MyModal show={ props.pending } isLoader label="Transmission en cours…">
            <p>Votre dossier est en cours de transmission. Cette opération peut prendre quelques instants. Merci de patienter.</p>
        </MyModal>
    )
}