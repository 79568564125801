import { Form, UseFormReturn, getFieldState, useForm } from '@dsid-opcoatlas/reform'
import { Yop } from '@dsid-opcoatlas/yop'
import { unwrapResult } from '@reduxjs/toolkit'
import { DiagCompetenceDTO, DiagDiagnosticCompetenceDTO, DiagDiagnosticMetierDTO, DiagMetierDTO, getCompetences, getMetiers } from 'api/diagatlasAPI'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasBreadcrumb, AtlasCheckbox, AtlasFlex, AtlasHeading, AtlasInfo, AtlasSpacer, MyConfettis, MyForm, MyModal, MyTags } from 'atlas-ds'
import Button from 'components/reform/Button'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import { RequiredString } from 'components/yop/constraints'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Diagnostic, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import { nil } from 'slices/dossierCommon'
import DiagLayout from './DiagLayout'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


interface MonDiagnosticMetiersProps {
    step: number
}

export default function MonDiagnosticMetiers(props: MonDiagnosticMetiersProps) {

    const dispatch = useAppDispatch()
    const { diagnostic, saving } = useDiagnostic(props.step, ['metiers', 'competences'])
    const navigation = useDiagnosticNavigation()

    const [showCreerPlan, setShowCreerPlan] = useState(false)

    const validerDiagnostic = () => {
        dispatch(diagnosticSliceActions.validerPlan(diagnostic as any))
            .then(unwrapResult)
            .then(() => {
                navigation.navigateToPath('actions')
            })
            .finally(() => setShowCreerPlan(false))
    }

    const headingInfo = <AtlasInfo
        type='success'
        title="Bravo ! Vous allez à présent découvrir les métiers et compétences en mutation pour votre entreprise."
    >
        <AtlasFlex column gap="s">
            <p>Au regard des tendances d'évolution de vos activités, retrouvez ci-dessous la liste des 8 métiers et 8 compétences qui risquent d'être impactés dans les prochaines années. <strong>Vous pouvez ajuster ces résultats en ajoutant ou supprimant des métiers et compétences.</strong></p>
            <p>
                <strong>Pour les métiers en mutation, précisez quel type d'action vous envisagez de déployer.</strong><br/>
                <i>Ces informations nous permettront d'ajuster les actions recommandées pour votre entreprise.</i>
            </p>
        </AtlasFlex>
    </AtlasInfo>

    const [showConfettis, setShowConfettis] = useState(false)

    useEffect(() => {
        if (diagnostic?.dateCreation) {
            if (!localStorage.getItem('confettisDiagSeen-' + diagnostic.dateCreation.substring(0, 19))) {
                setShowConfettis(true)
                localStorage.setItem('confettisDiagSeen-' + diagnostic.dateCreation.substring(0, 19), 'true')
            }
        }
    }, [diagnostic?.dateCreation])


    return <DiagLayout step={props.step} stepMax={diagnostic?.etape ?? 1} noStepInTitle
        headingTitle="Vos résultats - Les métiers et compétences en mutation"
        headingInfoBlock={ showConfettis ? <MyConfettis>{ headingInfo }</MyConfettis> : headingInfo }>

        <EditMetiersCompetences diagnostic={ diagnostic } />

        <MyForm>
            <MyForm.Actions>
                <Button level={2} disabled={ saving } onClick={ () => navigation.navigatePrev() }>
                    Etape précédente
                </Button>
                <Button disabled={ saving } spinner={{ spinning: saving }} onClick={ () => setShowCreerPlan(true) }>
                    Valider mon diagnostic
                </Button>
            </MyForm.Actions>
        </MyForm>

        { showCreerPlan && <AskCreerPlanActionModal close={ () => setShowCreerPlan(false) } next={ validerDiagnostic } /> }
    </DiagLayout>
}

export function MonDiagnosticModifierMetiers() {

    const dispatch = useAppDispatch()
    const { diagnostic, saving } = useDiagnostic(null, ['metiers', 'competences'])
    const navigation = useDiagnosticNavigation()

    const validerDiagnostic = () => {
        dispatch(diagnosticSliceActions.validerPlan(diagnostic as any))
            .then(unwrapResult)
            .then(() => {
                navigation.navigateToPath('actions')
            })
    }

    return <DiagLayout 
        headingTitle="Les Métiers et compétences en mutation"
        headingInfoBlock={ <AtlasFlex column gap="s">
            <p>Au regard des facteurs externes qui s'imposent à votre activité et des choix stratégiques propres à votre entreprise, retrouvez ci-dessous la liste des 8 métiers et 8 compétences qui risquent d'être le plus fortement impactés dans les prochaines années.</p>
            <p>Pour les métiers en mutation, précisez quel type d'action vous souhaitez déployer. Vous pouvez également ajouter ou supprimer des métiers et compétences.</p>
        </AtlasFlex> }
        breadcrumb={<AtlasBreadcrumb currentPageLabel="Métiers et compétences en mutation"
        linkConstructor={ (props, content) => <Link to={ props.href }>{ content }</Link> }
        parts={[{
            label: "Actions préconisées",
            href: "/mon-diagnostic-actions",
        }]} />}
    >

        <EditMetiersCompetences diagnostic={ diagnostic } />

        <MyForm>
            <MyForm.Actions>
                <Button disabled={ saving } spinner={{ spinning: saving }} onClick={ validerDiagnostic }>
                    Valider les modifications
                </Button>
            </MyForm.Actions>
        </MyForm>
    </DiagLayout>
}


function EditMetiersCompetences(props: { diagnostic: Diagnostic | null }) {
    const dispatch = useAppDispatch()
    const [showAjouterMetier, setShowAjouterMetier] = useState(false)
    const [showAjouterCompetence, setShowAjouterCompetence] = useState(false)

    const onUpdateMetier = (metier: DiagDiagnosticMetierDTO, prop: 'Sinformer' | 'Former' | 'Recruter', value: boolean) => {
        const update = { ...metier, [prop]: value } 
        dispatch(diagnosticSliceActions.updateMetier({ metier: metier.Metier.Id, 
            sinformer: update.Sinformer, former: update.Former, recruter: update.Recruter 
        }))
    }

    const onDesactiverMetier = (metier: DiagDiagnosticMetierDTO) => {
        dispatch(diagnosticSliceActions.deleteMetier({ metier: metier.Metier.Id }))
    }

    const onDesactiverCompetence = (competence: DiagDiagnosticCompetenceDTO) => {
        dispatch(diagnosticSliceActions.updateCompetence({ competence: competence.Competence.Id, actif: false }))
    }

    return <>
        <div>
            <AtlasHeading tag="h3" size="s">Métiers en mutation</AtlasHeading>
            <MyTags>
                { (props.diagnostic?.metiers?.map(mm => <MyTags.Item key={ mm.Metier.Code }
                    accent={ mm.AjoutEntreprise }
                    delete={{ onDelete: () => onDesactiverMetier(mm), ariaLabel: "Supprimer le métier" }}
                    tagCheckboxes={[
                        <AtlasCheckbox
                            key={ mm.Metier.Code + '.sinformer' }
                            value={ mm.Metier.Code + '.sinformer' }
                            name={ mm.Metier.Code + '.sinformer' }
                            label="S'informer"
                            checked={ mm.Sinformer }
                            onChange={ event => onUpdateMetier(mm, 'Sinformer', event.currentTarget.checked) }
                        />,
                        <AtlasCheckbox
                            key={ mm.Metier.Code + '.former' }
                            value={ mm.Metier.Code + '.former' }
                            name={ mm.Metier.Code + '.former' }
                            label="Former"
                            checked={ mm.Former }
                            onChange={ event => onUpdateMetier(mm, 'Former', event.currentTarget.checked) }
                        />,
                        <AtlasCheckbox
                            key={ mm.Metier.Code + '.recruter' }
                            value={ mm.Metier.Code + '.recruter' }
                            name={ mm.Metier.Code + '.recruter' }
                            label="Recruter"
                            checked={ mm.Recruter }
                            onChange={ event => onUpdateMetier(mm, 'Recruter', event.currentTarget.checked) }
                        />,
                    ]} >{ mm.Metier.Libelle }</MyTags.Item>) ?? []).concat([
                        <MyTags.AddButton key="ajoutMetier" label="Ajouter un métier" onClick={ () => setShowAjouterMetier(true) } />
                    ])
                }
            </MyTags>
        </div>

        <AtlasSpacer size="xl" />

        <div>
            <AtlasHeading tag="h3" size="s">Compétences à développer</AtlasHeading>
            <MyTags>
                { (props.diagnostic?.competences?.filter(mc => mc.Actif)?.map(mc => <MyTags.Item key={ mc.Competence.Code }
                    accent={ mc.AjoutEntreprise }
                    delete={{ onDelete: () => onDesactiverCompetence(mc), ariaLabel: "Supprimer la compétence" }}>{ mc.Competence.Libelle }</MyTags.Item>) ?? []).concat([
                        <MyTags.AddButton key="ajoutCompetence" label="Ajouter une compétence" onClick={ () => setShowAjouterCompetence(true) } />
                    ])
                }
            </MyTags>
        </div>

        <AtlasSpacer size="xl" />

        { showAjouterMetier && <AjouterMetierModal show={ true } close={ () => setShowAjouterMetier(false) } 
            next={ () => setShowAjouterMetier(false) } /> }

        { showAjouterCompetence && <AjouterCompetenceModal show={ true } close={ () => setShowAjouterCompetence(false) } 
            next={ () => setShowAjouterCompetence(false) } /> }
    </>
}


export function AjouterMetierModal(props: { show: boolean, close: () => void, next: () => void }) {

    const dispatch = useAppDispatch()
    const { saving, error } = useSelector((state: RootState) => state.diagnosticState)

    const clearError = () => {
        dispatch(diagnosticSliceActions.clear())
    }

    const close = () => {
        clearError()
        props.close()
    }

    const searchMetiers = async (recherche: string) => {
        return (await getMetiers(recherche)).sort((a, b) => a.Famille.Code.localeCompare(b.Famille.Code) || a.Libelle.localeCompare(b.Libelle))
    }

    const initialValues = { metier: nil as { Id: string } }

    const validationSchema = Yop.object<typeof initialValues>({
        metier: Yop.object<{ Id: string }>({ Id: RequiredString }).defined().required()
    })

    const onSubmit = (context: UseFormReturn<typeof initialValues>) => {
        dispatch(diagnosticSliceActions.updateMetier({ metier: context.values!.metier.Id, ajoutEntreprise: true }))
            .then(unwrapResult)
            .then(() => props.next())
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit, 
    })
    const fieldState = getFieldState<DiagMetierDTO | null>(form, 'metier')

    return (
        <MyModal show={ props.show } label="Ajouter un métier du référentiel Atlas" allowOverflow>
            <Form context={form} noValidate>
                <MyForm>
                    <MyForm.Field>
                        <AutocompleteBaseField<DiagMetierDTO>
                            label="Métier"
                            defaultValue={ fieldState.value?.Libelle ?? "" }
                            error={ fieldState.error }
                            required={ fieldState.constraints.required }
                            placeholder="Intitulé métier"
                        
                            search={ searchMetiers }
                            searchDebounceOptions={{ immediate: true, empty: true, minChars: 0 }}
                            
                            optionFor={ metier => ({ id: metier.Code, content: metier.Libelle, group: metier.Famille.Libelle }) }
                            onSelect={ metier => { clearError(); form.setValue("metier", metier, true) } }
                            hideNoResultsMessage
                        />
                    </MyForm.Field>

                    { !saving && error && error.startsWith('metaMetier already exists') &&
                        <AtlasInfo type="warning" title="Erreur d'ajout">Ce métier est déjà présent dans le diagnostic</AtlasInfo>
                    }

                    <MyForm.Actions>
                        <Button
                            submit={true}
                            level={ 1 }
                            disabled={ !form.values?.metier }
                            spinner={{ spinning: saving }}
                        >Ajouter</Button>
                        <Button level={ 2 } onClick={ close }>Annuler</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}

export function AjouterCompetenceModal(props: { show: boolean, close: () => void, next: () => void }) {

    const dispatch = useAppDispatch()
    const { saving, error } = useSelector((state: RootState) => state.diagnosticState)

    const clearError = () => {
        dispatch(diagnosticSliceActions.clear())
    }

    const close = () => {
        clearError()
        props.close()
    }

    const searchCompetences = async (recherche: string) => {
        return (await getCompetences(recherche)).sort((a, b) => a.Famille.Code.localeCompare(b.Famille.Code) || a.Libelle.localeCompare(b.Libelle))
    }

    const initialValues = { competence: nil as { Id: string } }

    const validationSchema = Yop.object<typeof initialValues>({
        competence: Yop.object<{ Id: string }>({ Id: RequiredString }).defined().required()
    })

    const onSubmit = (context: UseFormReturn<typeof initialValues>) => {
        dispatch(diagnosticSliceActions.updateCompetence({ competence: context.values!.competence.Id, ajoutEntreprise: true, actif: true}))
            .then(unwrapResult)
            .then(() => props.next())
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit,
    })
    const fieldState = getFieldState<DiagCompetenceDTO | null>(form, 'competence')

    return (
        <MyModal show={ props.show } label="Ajouter une compétence du référentiel Atlas" allowOverflow>
            <Form context={form} noValidate>
                <MyForm>
                    <MyForm.Field>
                        <AutocompleteBaseField<DiagCompetenceDTO>
                            label="Compétence"
                            defaultValue={ fieldState.value?.Libelle ?? "" }
                            error={ fieldState.error }
                            required={ fieldState.constraints.required }
                            placeholder="Intitulé compétence"
                        
                            search={ searchCompetences }
                            searchDebounceOptions={{ immediate: true, empty: true, minChars: 0 }}
                            
                            optionFor={ competence => ({ id: competence.Code, content: competence.Libelle, group: competence.Famille.Libelle }) }
                            onSelect={ competence => { clearError(); form.setValue("competence", competence, true) } }
                            hideNoResultsMessage
                        />
                    </MyForm.Field>

                    { !saving && error && error.startsWith('metaCompetence already exists') &&
                        <AtlasInfo type="warning" title="Erreur d'ajout">Cette compétence est déjà présente dans le diagnostic</AtlasInfo>
                    }

                    <MyForm.Actions>
                        <Button
                            submit={true}
                            level={ 1 }
                            disabled={ !form.values?.competence }
                            spinner={{ spinning: saving }}
                        >Ajouter</Button>
                        <Button level={ 2 } onClick={ close }>Annuler</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}

export function AskCreerPlanActionModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Validation du diagnostic"
            okButton={ <Button onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button onClick={ props.close }>Annuler</Button> }
        >
            <p>En validant votre diagnostic, vous ne pourrez plus modifier le profil d'entreprise ni les facteurs de mutation.
            À l'inverse vous pourrez toujours ajuster vos besoins entreprise, métiers et compétences.</p>
        </MyModal>
    )
}