import { Page, Text, View, Document, Image } from "@react-pdf/renderer"
import styles from "./Infos.styles"
import logo from "pages/dossier/cerfa/img/opco-atlas.png"
import { RootState } from "app/rootReducer"
import { useSelector } from "react-redux"


type InfosProps = {
    title: string,
    subtitle: string,
    auth?: string,
    tables: {
        title: string,
        headers?: string[],
        values: (string | JSX.Element)[][],
        changes?: (string | JSX.Element)[][]
    }[]
}


export default function Infos({ data }: { data: InfosProps }) {
    const currentDateTime = new Date().toLocaleString()

    return (
        <Document>
            <Page style={styles.page}>
                <Image source={logo} style={styles.logo}></Image>

                <View style={styles.title}>
                    <Text>{data.title}</Text>
                    <Text style={styles.strong}>{data.subtitle}</Text>
                </View>

                {data.tables.map((table) => (
                    <View style={styles.part} key={table.title}>
                        <Text style={styles.partTitle}>{table.title}</Text>

                        <View>
                            {table.headers && (
                                <View style={[styles.row, styles.tableHead]}>
                                    {table.headers.map((header, index) =>
                                        <Text style={styles.cell} key={index}>{header}</Text>
                                    )}
                                </View>
                            )}

                            {table.values && table.values.map((values, index) => (
                                <View
                                    style={index % 2 === 0 ? [styles.row, styles.evenRow] : styles.row}
                                    key={index}
                                >
                                    {values.map((value, index) => typeof value === 'string'
                                        ? <Text style={styles.cell} key={index}>{value}</Text>
                                        : value
                                    )}
                                </View>
                            ))}
                            {table.changes && table.changes.map((values, index) => (
                                <View
                                    style={index % 2 === 0 ? [styles.row, styles.evenRow] : styles.row}
                                    key={index}
                                >
                                    {values.map((value, index) => typeof value === 'string'
                                        ? <Text style={styles.cell} key={index}>{value}</Text>
                                        : value
                                    )}
                                </View>
                            ))}
                        </View>
                    </View>
                ))}

                <Text style={styles.footerText}>
                    Document généré {data.auth ? `par ${data.auth} ` : ''}le : {currentDateTime}
                </Text>

            </Page>
        </Document>
    );
}
