import { useFormContext, UseFormReturn } from "@dsid-opcoatlas/reform"
import { AtlasHeading, AtlasInfo, AtlasSpacer, MyForm } from 'atlas-ds'
import RadioField from 'components/reform/inputs/RadioField'
import { startOfToday } from 'date-fns'
import { nil } from 'slices/dossierCommon'
import PrelevementBlock, { createPrelevement, Prelevement } from './PrelevementBlock'
import VirementBlock from './VirementBlockl'

export function createReglement() { return {
    typeReglement: nil as string,
    dateVirement: nil as (Date | null),
    prelevement: nil as (Prelevement | null),
}}
export type Reglement = ReturnType<typeof createReglement>

interface ReglementBlockProps {
    type: "conventionnel" | "volontaire"
    disabled: boolean
    disabledPrelevement?: boolean
}

export default function ReglementBlock(props: ReglementBlockProps) {
    const form = useFormContext<Reglement>()

    const onChangeTypeReglement = (type: string | null, form: UseFormReturn<Reglement>) => {
        if (type === 'prelevement') {
            form.setValue('dateVirement', null)
            form.setValue('prelevement', createPrelevement())
        }
        else if (type === 'virement') {
            form.setValue('dateVirement', startOfToday())
            form.setValue('prelevement', null)
        }
    }

    if (props.disabledPrelevement === true) {
        form.setValue('typeReglement', 'virement')
        return (<div>
            <AtlasHeading size="s" tag="h3">Règlement par virement</AtlasHeading>
            <AtlasSpacer size="s" />
            <VirementBlock type={ props.type } />
        </div>)
    }

    return <>
        <AtlasInfo title="Vous payez par prélèvement ?">
            En choisissant le paiement par prélèvement, vous accélérez et facilitez le traitement de vos demandes de prise en charge.
        </AtlasInfo>

        <MyForm.Field full>
            <RadioField name="typeReglement" label="Type de règlement"
                disabled={ props.disabled }
                options={[
                    { value: 'virement', label: 'Virement' },
                    { value: 'prelevement', label: 'Prélèvement' }
                ]}
                onChange={onChangeTypeReglement}
            />
        </MyForm.Field>

        { form.values?.typeReglement === 'virement' && <VirementBlock type={ props.type } /> }

        { form.values?.typeReglement === 'prelevement' && <PrelevementBlock /> }
    </>
}