import { Form, useForm } from "@dsid-opcoatlas/reform"
import useEventListener, { dispatchAppEvent } from 'app/events'
import { MySearch } from 'atlas-ds'
import { OptionalString, optionalObject } from "components/yop/constraints"
import { aideContactSearchChangeEvent } from '../pages/aide-contacts/AideContacts'
import Button from "./reform/Button"
import TextField from "./reform/inputs/TextField"

export const searchQuestionsClearEvent = 'AideContacts:Search:Clear'

export function HelpForm(props: { search?: string }) {

    const initialValues = {
        search: (props.search ?? '') as string | null
    }

    const validationSchema = optionalObject<typeof initialValues>({
        search: OptionalString.min(3)
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            dispatchAppEvent(aideContactSearchChangeEvent, context.values!.search).finally(() => {
                context.setSubmitting(false)
            })
        },
    })

    useEventListener(searchQuestionsClearEvent, _ => {
        form.setValue("search", "")
    })

    return (
        <Form context={ form } noValidate>
            <MySearch
                input={<TextField name="search" label="Terme de recherche" placeholder="Indiquer les termes de votre recherche, au minimum 3 caractères" />}
                button={<Button submit={ true } spinner={{spinning: form.submitting}}>Appliquer</Button>}
            />
        </Form>
    )
}
