import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MyModal } from 'atlas-ds'
import { creerDossierDepuisSimulation } from 'api/simulationAPI'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { setEnterprise } from 'slices/contextSlice'
import useDossiersModuleContext from 'app/entreprise/mes-dossiers/useDossiersModuleContext'
import { ToastService } from 'components/BandeauAlert'
import { getDossierCreationPath } from 'pages/dossier/useDossierNavigation'


export default function PriseEnChargeSimulation() {

    const dispatch = useAppDispatch()
    const history = useHistory()
    const ctx = useDossiersModuleContext()

    const { profil } = useSelector((state: RootState) => state.profilState)
    
    const [createPending, setCreatePending] = useState<boolean>(false)

    useEffect(() => {
        setCreatePending(true)

        const params = new URLSearchParams(history.location.search)
        const simulationId = params.get('id-simulation') ?? ''

        creerDossierDepuisSimulation(simulationId)
            .then(dossier => {
                const entreprise = profil?.Entreprises.find(entreprise => entreprise.SIRET__c === dossier.SiretADH__c)
                
                if (entreprise)
                    dispatch(setEnterprise(entreprise))
                
                ToastService.showToast({
                    label: 'Action de prise en charge',
                    type: 'success',
                    content: 'Le dossier de prise en charge a été créé avec succès.'
                })
                history.push({
                    pathname: getDossierCreationPath(dossier, ctx!),
                    search: `?contexte=entreprise`
                })
            })
            .catch(e => {
                log.error('Erreur création prise en charge ' + simulationId, e)
                ToastService.showToast({
                    label: 'Action de prise en charge',
                    type: 'error',
                    content: "Le dossier de prise en charge n'a pas pu être créé à partir la simulation demandée."
                })
                history.push({
                    pathname: '/mes-simulations',
                })
            }).finally(() => {
                // setCreatePending(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history])

    return (<>
        <MyModal
            isLoader
            show={ createPending }
            label="Création du dossier en cours..."
        />
    </>)
}
