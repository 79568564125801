import { HTTP_ERROR_RESPONSE } from 'api/baseAPI'
import useEventListener, { AppErrorEvent } from 'app/events'
import { AtlasButton, AtlasHtml, MyModal } from 'atlas-ds'
import Logo from 'components/Logo'
import { useState } from 'react'

const OLD_BROWSER_WARNED_KEY = "old-browser-warned"

export const hasBeenOldBrowserWarned = () => {
    return sessionStorage.getItem(OLD_BROWSER_WARNED_KEY) !== null
}

export default function HttpError() {

    const [error, setError] = useState<any | null>(null)

    useEventListener(HTTP_ERROR_RESPONSE, (event: AppErrorEvent) => {
        setError(event.error)
    })

    const maxMessageLength = 300
    let message = (error?.data?.message as string) ?? ''
    if (message.length > maxMessageLength)
        message = message.slice(0, maxMessageLength) + '...'

    return (<>
        <MyModal
            show={ error !== null }
            isError
            label="Une erreur inattendue s'est produite&nbsp;!"
            cancelButton={<AtlasButton onClick={ () => setError(null) }>Fermer</AtlasButton>}
        >
            <Logo />
            <AtlasHtml>
                <p><strong>{ message }</strong></p>
                <p>Détails techniques :</p>
                <ul>
                    <li>URL : { window.location.href }</li>
                    <li>Code : { error?.status }</li>
                    <li>Date : { error?.data?.timestamp }</li>
                </ul>
                <p>Nous vous invitons à faire une copie d'écran de ce message afin de faciliter le travail du support Atlas. Merci.</p>
            </AtlasHtml>
        </MyModal>
    </>)
}
