
import { useState } from 'react'
import { AtlasButton } from 'atlas-ds'
import InformationPoursuitePostRupturePrestataireModal from 'components/annuler/InformationPoursuitePostRupturePrestataireModal'
import { DossierActionProps } from './DetailDossierContrat'
import { checkDetailAction } from 'pages/detail-common/Detail'


export default function DossierInformationPoursuitePostRupturePrestataire(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const [showInfoPoursuite, setShowInfoPoursuite] = useState(false)

    return <>
        <AtlasButton
            id="rupturePoursuite"
            level={ 2 }
            onClick={() => setShowInfoPoursuite(true)}
        >Déclarer une poursuite de formation en CFA post rupture</AtlasButton>

        { showInfoPoursuite &&
        <InformationPoursuitePostRupturePrestataireModal show={ showInfoPoursuite } 
            dossier={ dossier } 
            close={ () => setShowInfoPoursuite(false) } />
        }
    </>
}
