
import { AppInfo } from 'app/appInfo'
import { AppType } from 'slices/contextSlice'
import accueil from './accueil/index'
import mesEntreprises from './mes-entreprises/index'
import mesPrestataires from './mes-prestataires/index'
import monCompte from './mon-compte/index'

const AppCompte: AppInfo = {
    id: 'MAMC',
    app: AppType.Compte,
    name: 'Compte',
    theme: 'entreprise',
    modules: [
        [
            accueil,
            monCompte,
            mesEntreprises,
            mesPrestataires,
        ],
    ],
    env: {
        matomoIDSite: import.meta.env.REACT_APP_MATOMO_ID_SITE_MAMC,    
    },
}

export default AppCompte