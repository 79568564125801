import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { loadDossierAF, saveDossierAF, sendDossierAF } from 'api/dossierAPI'
import { DISPOSITIF_FMA } from 'api/references'
import { createCerfaDpc } from 'pages/dossier/cerfa/CerfaDpc'
import { mapDTOToDossierAF, mapDossierAFToDTO } from 'pages/dossier/metadata/DossierMapping'
import { DOCNAME_DPC, DOCTYPE_DPC, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA } from 'pages/dossier/metadata/ModulesMetadata'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { DossierSalariesEditForm } from 'pages/dossier/DossierSalariesEditForm'
import { dossierMultiSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import { DossierSalariesTable } from 'pages/dossier/DossierSalariesTable'
import { exportDossier } from 'pages/dossier/ExportDossierDPC'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import { AppType } from 'slices/contextSlice'
import { DossierAPI } from 'slices/dossierSlice'
import { ElementsDossierForm } from 'pages/dossier/ElementsDossierForm'
import { DossierAFEmployeurElements } from 'pages/dossier/elements/DossierAFEmployeurElements'
import { DossierAF, DossierAFModule } from 'slices/dossierClasses'
import { DossierAFModulesElements, dossierModulesInitializer } from 'pages/dossier/elements/DossierAFModulesElements'
import { overrides } from 'components/elements/decorators'
import { ClassConstructor, ExtendedPath, getMetadata } from 'components/elements/engine'

@overrides({ intitulePrecis: { disabled: true } })
export class DossierFMAModule extends DossierAFModule {

    constructor() {
        super()
        this.intitulePrecis = "Formation maître d'apprentissage"
    }
}

@overrides({ modules: { array: DossierFMAModule } })
export class DossierFMA extends DossierAF {
    
    constructor() {
        super(DISPOSITIF_FMA)
    }
}

function mapDTOToDossier(model: ClassConstructor, value: any, dto: any) {
    const metadata = getMetadata(model)

    Object.entries(metadata).forEach(([name, fieldMetadata]) => {
        const dtoPath = fieldMetadata.dto
        if (fieldMetadata.object != null) {
            if (dtoPath == null || dtoPath === '/')
                mapDTOToDossier(fieldMetadata.object, value[name], dto)
            else {
                // TODO
            }
        }
        else if (fieldMetadata.array != null) {
            // TODO
        }
        else if (dtoPath != null) {
            if (dtoPath.startsWith('/')) {
                // TODO
            }
            else {
                const field: any = dto[dtoPath]
                value[name] = field ?? null
            }
        }
    })

    return value
}

function mapDossierToDTO(model: ClassConstructor, value: any, dto: any) {
    const metadata = getMetadata(model)

    Object.entries(metadata).forEach(([name, fieldMetadata]) => {
        const dtoPath = fieldMetadata.dto
        if (fieldMetadata.object != null) {
            if (dtoPath == null || dtoPath === '/')
                mapDossierToDTO(fieldMetadata.object, value[name], dto)
            else {
                // TODO
            }
        }
        else if (fieldMetadata.array != null) {
            // TODO
        }
        else if (dtoPath != null) {
            const field: any = value[name]
            if (dtoPath.startsWith('/')) {
                // TODO
            }
            else {
                dto[dtoPath] = field ?? null
            }
        }
    })

    return dto
}

const api: DossierAPI<any> = {
    create: () => new DossierFMA(),
    createModule: () => new DossierFMAModule() as any,
    createSalarie: () => null as any,
    load: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        return mapDTOToDossier(DossierFMA, new DossierFMA(), dossierDTO)
    },
    loadWithPieces: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        return mapDTOToDossierAF(MetadataFMA, dossierDTO)
    },
    save: async (dossier: DossierFMA) => {
        const dossierDTO = mapDossierToDTO(DossierFMA, dossier, {})
        const savedDossierDTO = await saveDossierAF(dossierDTO)
        return mapDTOToDossier(DossierFMA, new DossierFMA(), savedDossierDTO)
        // return mapDTOToDossierAF(MetadataFMA, savedDossierDTO)
    },
    send: async (dossier: DossierFMA) => {
        const dossierDTO = mapDossierAFToDTO(MetadataFMA, dossier)
        const sentDossierDTO = await sendDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataFMA, sentDossierDTO)
    },
    postSend: async (dossier: DossierFMA) => {
        await postSendDossier(MetadataFMA, dossier)
    },
}

/*
const FMAModuleMetadata: ObjectMetadata<DossierFMAModule> = {
    formationInterne: override(moduleFields.formationInterne, { section: SECTION_MODULE_OF }),
    cfa: override(moduleFields.cfa, { props: { da: true }, section: SECTION_MODULE_OF }),
    subrogation: override(moduleFields.subrogation, { section: SECTION_MODULE_OF }),
    intitulePrecis: override(moduleFields.intitulePrecis, { props: { disabled: true, tooltip: '' }}),
    dureeTotale: moduleFields.dureeTotale,
    dateDebutFormation: moduleFields.dateDebutFormation,
    dateFinFormation: moduleFields.dateFinFormation,
    coutPedagogique: moduleFields.coutPedagogique,
    numeroDossierApprentissage: TextFieldMetadata({
        props: {
            label: 'Numéro dossier apprentissage',
        },
        mapping: simpleMapping('NumeroDossierApprentissage__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, any>) => context.parent?.formationInterne !== null,
        input: NumeroDossierApprentissageField,
    }),
    typeFormation: moduleFields.typeFormation,
    commentaire: IgnoredFieldMetadata(),
    formationCertifianteAgora: moduleAgoraFields.formationCertifianteAgora,
    categorieActionAgora: moduleAgoraFields.categorieActionAgora,
    objectifAgora: moduleAgoraFields.objectifAgora,
}

const FMASalarieMetadata: ObjectMetadata<DossierSalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: salarieFields.prenom,
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    handicap: salarieFields.handicap,
    statut: salarieFields.statut,
    contrat: salarieFields.contrat,
    niveauFormation: salarieFields.niveauFormation,
    dateEntree: salarieFields.dateEntree,
    categorieAction: salarieFields.categorieAction,
    heures: salarieFields.heures,
    heuresFoad: salarieFields.heuresFoad,
    coefficient: salarieFields.coefficient,
    autreMetier: salarieFields.autreMetier,
    situationHandicap: IgnoredFieldMetadata(),
    heuresHTT: IgnoredFieldMetadata(),
    heuresAfest: IgnoredFieldMetadata(),
}
*/

// type DossierFMALight = Omit<DossierFMA, 'pieces'>
// const FMAMetadataLight: ObjectMetadata<DossierFMALight> = {
//     //employeur: ObjectFieldMetadata(employeurMetadata),
//     // modules: ArrayFieldMetadata(FMAModuleMetadata),
//     // salaries: ArrayFieldMetadata(FMASalarieMetadata),
//     // signature: ObjectFieldMetadata(signatureMetadata),
// }
// const FMAMetadata = FMAMetadataLight as ObjectMetadata<DossierFMA>

export const MetadataFMA = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_FMA,
    model: DossierFMA,
    title: "Dépôt dossier Formation maître d'apprentissage (X)",
    pathname: '/nouveau-dossier-fma',
    steps: [
        { 
            title: 'Employeur',
            sections: [],
            recap: RecapEmployeur,
            path: ExtendedPath.fromString('employeur', DossierFMA),
            formContent: DossierAFEmployeurElements,
            form: ElementsDossierForm,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Formation',
            sections: [SECTION_MODULE_AGORA],
            recap: RecapModules,
            initializer: dossierModulesInitializer,
            path: ExtendedPath.fromString('modules[0]', DossierFMA),
            formContent: DossierAFModulesElements,
            form: ElementsDossierForm,
            options: {
                minElements: 1,
            },
        }, {
            title: 'Salariés',
            sections: [],
            preFormContent: DossierSalariesTable,
            editForm: DossierSalariesEditForm,
            recap: RecapSalaries,
            initializer: dossierMultiSalariesInitializer,
            yopPath: 'salaries',
            options: {
                noFormHeading: true,
                minElements: { value: 1, message: "Vous devez ajouter au moins un salarié" },
                noSaving: true,
                forceValidate: (dossier) => dossier.salaries?.length >= 1,
            },
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: DossierPiecesElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: null as any,
    exportProps: [{
        exportDossier: async (metadata, dossier) => createCerfaDpc(exportDossier(metadata, dossier)),
        documentType: DOCTYPE_DPC,
        documentName: DOCNAME_DPC,
    }],
})

