import { Entreprise } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasOptions, AtlasValue, AtlasValues, MyCompanySelector } from 'atlas-ds'
import { Company } from 'components/Company'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { setEnterprise } from 'slices/contextSlice'


interface CompanySelectorProps {
    label: string
    labelCode: string
}

export default function CompanySelector(props: CompanySelectorProps) {

    const dispatch = useAppDispatch()
    const history = useHistory()

    const { profil } = useSelector((state: RootState) => state.profilState)
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const onEnterpriseSelect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, entreprise: Entreprise) => {
        dispatch(setEnterprise(entreprise))
        history.push('/')
    }

    return <MyCompanySelector
        label={props.label}
        currentCompany={entreprise?.Name ?? ''}
        companies={<AtlasOptions
            options={
                profil?.Entreprises.map((entreprise) => ({
                    id: entreprise.Id,
                    onClick: (event) => onEnterpriseSelect(event, entreprise),
                    content: (
                        <Company company={entreprise} labelCode={props.labelCode} />
                    ),
                })) || []
            }
            loader={
                <AtlasValues label="">
                    <AtlasValues.Grid>
                        <AtlasValue label="SIREN"> </AtlasValue>
                        <AtlasValue label="Code Adhérent"> </AtlasValue>
                    </AtlasValues.Grid>
                    <AtlasValue label="Adresse"> </AtlasValue>
                </AtlasValues>
            }
          />}
    />
}
