import axios from 'axios'
import { BaseSearchQuery, getRequestConfig, Page } from 'api/baseAPI'
import store from 'app/store'
import { format, startOfToday, subYears } from 'date-fns'
import { DATE_FORMAT, SIREN_PRESTATAIRE } from 'app/constants'
import { Entreprise } from './referencesAPI'
import { Etablissement } from './commonAPI'
import { getDocumentAlfresco, openDocumentAlfresco, PieceDTO } from './documentAPI'


export type { Page } from 'api/baseAPI'

export interface VersementsSearchQuery extends BaseSearchQuery {
    recherche?: string
    etatVersement?: string[]
    dateDebut?: string
    dateFin?: string
}

export interface Versement {
    Id: string
    IdHeroku: number
    Name: string
    ExternalID_Sor__c: string
    DatePaiement__c: string
    MontantTTC__c: number
    EtatVersement__c: string
    TypeVersement__c: string
}

export interface VersementDetail extends Versement {
    EntrepriseClient__c: string
    EtablissementClient__c: string
    Entreprise__c: Entreprise
    Etablissement__c: Etablissement
    AnneeFiscale__c: number
    DateTransmission__c: string
    EffectifETAMFemme__c: number
    EffectifETAMHomme__c: number
    EffectifCadreFemme__c: number
    EffectifCadreHomme__c: number
    EffectifTotal__c: number
    MasseSalariale__c: number
    TauxCC__c: number
    BudgetVV__c: string
    MontantBudgetVV__c: number
    FraisDeGestion__c: number
    TVAEntreprise__c: boolean
    TauxTVAEnterprise__c: number
    TauxTVAPartielle__c: number
    MoyenPaiement__c: string
    MontantHT__c: number
    MontantTVA__c: number
    MontantTTC__c: number
    NodeRefGED__c: string
    URLSignatureSlimpay__c: string | null
    ErreurSlimpay__c: string | null
}

export interface VersementInfo {
    Id: string
    IdHeroku: number
    NomAdh__c: string
    SirenAdh__c: string
    SiretAdh__c: string
    AdresseADH__c: string
    Branche__c: string
    CodeAdherent__c: string
    CodeIDCC__c: string
    CodeNAF__c: string
    CodePostalADH__c: string
    CommuneADH__c: string
    EffectifCadreFemme__c: number
    EffectifCadreHomme__c: number
    EffectifETAMFemme__c: number
    EffectifETAMHomme__c: number
    EffectifTotal__c: number
    MasseSalariale__c: number
    MontantHT__c: number
    MontantTTC__c: number
    MontantTVA__c: number
    MoyenPaiement__c: string | null
    TVAEntreprise__c: boolean
    TauxCC__c: number
    TauxTVAEnterprise__c: number
    TauxTVAPartielle__c: number | null
    Taux_frais: number
    AnneeFiscale: number
}

export interface VersementEnvoi {
    EffectifCadreFemme__c: number
    EffectifCadreHomme__c: number
    EffectifETAMFemme__c: number
    EffectifETAMHomme__c: number
    EffectifTotal__c : number
    MontantTTC__c: number
    MontantHT__c: number
    MontantTVA__c: number
    MasseSalariale__c: number
    TVAEntreprise__c: boolean
    TauxTVAEnterprise__c: number
    TauxTVAPartielle__c: number | null
    MoyenPaiement__c: string | null
    DatePaiement__c: string
    EtablissementTitulaire: string | null
    IBAN: string | null
    BIC: string | null
    Nom: string | null
    Prenom: string | null
    Telephone: string | null
    Email: string | null
    CGUVV__c: boolean
    NonConsentementTraitementDonnees__c: boolean
    NonConsentementTransmissionDonnees__c: boolean
    ByPassDoublon?: boolean
    returnUrl: string
}

export interface VersementVVEnvoi {
    BudgetVV__c: string
    MontantBudgetVV__c: number
    FraisDeGestion__c: number
    MontantTTC__c: number
    MontantHT__c: number
    MontantTVA__c: number
    TauxTVAEnterprise__c: number
    MoyenPaiement__c: string | null
    DatePaiement__c: string
    EtablissementTitulaire: string | null
    CGUVV__c: boolean
    RepresentantLegal__c : string

    IBAN?: string | null
    BIC?: string | null
    Nom?: string | null
    Prenom?: string | null
    Telephone?: string | null
    Email?: string | null
    returnUrl?: string
}

export const ETAT_VERSEMENT = {
    '0': 'En attente du règlement',
    '1': null,
    '2': null,
    '3': 'En cours de traitement',
    '4': 'Règlement validé',
    '5': null,
} as Record<string, string | null>

export const EXTERNAL_ID_SOR_NULL = 'Enregistrement en cours...'


function getSearchVersementsParams(query: VersementsSearchQuery): URLSearchParams {

    const params = new URLSearchParams()

    const entreprise = store.getState().contextState.entreprise

    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }

    if (query.recherche)
        params.append('recherche', query.recherche.trim())

    if (query.etatVersement && query.etatVersement.length > 0) {
        query.etatVersement.forEach(value => {
            params.append('etat-versement', value)
        })
    }

    const today = startOfToday()
    params.append('date-debut', query.dateDebut ?? format(subYears(today, 1), DATE_FORMAT))
    params.append('date-fin', query.dateFin ?? format(today, DATE_FORMAT))
    params.append(SIREN_PRESTATAIRE, entreprise?.SIREN__c ?? '')

    return params
}

export async function getVersements(query: VersementsSearchQuery, type: string): Promise<Page<Versement>> {

    const entreprise = store.getState().contextState.entreprise

    const requestConfig = await getRequestConfig()
    const params = getSearchVersementsParams(query)
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 50).toFixed(0))
    params.append('type-versement', type)
    requestConfig.params = params

    const { data, headers } = await axios.get<VersementDetail[]>(`/collecte/entreprises/${entreprise?.SIREN__c}`, requestConfig)

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length
    return {
        totalCount,
        items: data
    }
}

export async function getVersementInfo(type: 'VV' | 'CC'): Promise<VersementInfo> {
    const entreprise = store.getState().contextState.entreprise

    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('type', type)
    requestConfig.params = params

    const { data } = await axios.get<VersementInfo>(`/collecte/entreprises/${entreprise?.SIREN__c}/account`, requestConfig)
    return data
}

export async function getVersement(idHeroku: string): Promise<VersementDetail> {
    const entreprise = store.getState().contextState.entreprise

    const requestConfig = await getRequestConfig()

    const { data } = await axios.get<VersementDetail>(`/collecte/entreprises/detail/${entreprise?.SIREN__c}?id=${idHeroku}`, requestConfig)
    return data
}

export async function postVersement(versement: VersementEnvoi): Promise<VersementDetail> {
    const entreprise = store.getState().contextState.entreprise

    const requestConfig = await getRequestConfig()

    const { data } = await axios.post<VersementDetail>(`/collecte/entreprises/${entreprise?.SIREN__c}`, versement, requestConfig)
    return data
}

export async function postVersementVV(versement: VersementVVEnvoi): Promise<VersementDetail> {
    const entreprise = store.getState().contextState.entreprise

    const requestConfig = await getRequestConfig()

    const { data } = await axios.post<VersementDetail>(`/collecte-vv/entreprises/${entreprise?.SIREN__c}`, versement, requestConfig)
    return data
}

export async function downloadVersementDoc(document: PieceDTO) {
    let idNode = document.Id
    if (!idNode)
        return Promise.reject()

    return getDocumentAlfresco(idNode).then(response => {
        openDocumentAlfresco(document.type ?? 'bordereau', response)
    })
}

export async function getVersementDocuments(versement: VersementDetail) {
    if (versement.ExternalID_Sor__c == null)
        return []

    const entreprise = store.getState().contextState.entreprise

    const requestConfig = await getRequestConfig()
    const params = new URLSearchParams()
    params.append('siren', entreprise?.SIREN__c ?? '')
    requestConfig.params = params

    const { data } = await axios.get<PieceDTO[]>(`/versements/num/${versement.ExternalID_Sor__c}/documents`, requestConfig)
    return data
}
