import styles from 'pages/dossier/cerfa/global.styles'
import { Style } from '@react-pdf/types'
import { formatDate } from 'components/format/Format'
import { Text } from '@react-pdf/renderer'

interface CerfaTextFieldProps {
    value: string | Date | null | undefined
    suffix?: string
    type?: string
    placeholder?: string
    style?: Style | Style[]
}

export default function CerfaTextField(props: CerfaTextFieldProps) {
    const style = props.style ?? styles.user

    if (props.value == null || props.value === '') {
        return (props.type === 'date' ? <Text style={style}>__/__/___</Text> : null)
    }

    const suffix = props.suffix ? ' ' + props.suffix : ''
    const placeholder = props.placeholder ?? (props.type === 'date' ? '__/__/___' : ' ')
    const value = props.value ? (props.type === 'date' ?
        formatDate(props.value) + suffix : props.value + suffix
    ) : placeholder

    return <Text style={ style }>{ value }</Text>
}
