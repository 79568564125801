import { InseeDetail, verifierEntreprise } from "api/monCompteAPI"
import { AtlasColumns, AtlasInfo, AtlasLoading, AtlasSpacer } from "atlas-ds"
import PageHeader from "components/PageHeader"
import log from 'loglevel'
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import WebAdhesionError from "./WebAdhesionError"
import { WebAdhesionForm } from "./WebAdhesionForm"

export function WebAdhesion() {
    const location = useLocation()

    const [backendError, setBackendError] = useState<string | null>(null)
    const [dataInsee, setDataInsee] = useState<InseeDetail>()

    const paramsSiren = location.pathname.substring(location.pathname.length - 9) ?? '';

    useEffect(() => {
        if (paramsSiren) {
            verifierEntreprise(paramsSiren)
                .then(response => {
                    setDataInsee(response)
                })
                .catch(error => {
                    log.error('Erreur Web Adhesion verifierEntreprise : ', error);
                    const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite.";
                    setBackendError(message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsSiren])

    return <>
        <PageHeader>Faire adhérer l'entreprise à Atlas</PageHeader>

        <AtlasColumns>
            <div>
                <AtlasLoading loading={!dataInsee && !backendError}>
                    <AtlasLoading.Loaders>
                        <p>Récupération des informations en cours...</p>
                        <AtlasSpacer />
                        <AtlasLoading.Loader />
                    </AtlasLoading.Loaders>
                </AtlasLoading>

                {dataInsee && <>
                    {dataInsee.success
                        ? <WebAdhesionForm siren={paramsSiren} dataInsee={dataInsee}></WebAdhesionForm>
                        : <WebAdhesionError errorMessage={dataInsee.message} codesNAF={dataInsee.CodesNAF} />
                    }
                </>}

                {backendError && <AtlasInfo
                    type="error"
                    title="Erreur"
                >{ backendError }</AtlasInfo> }
            </div>
        </AtlasColumns>
    </>
}
