import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { useEffect } from 'react'
import Joyride, { CallBackProps, EVENTS, Step } from 'react-joyride'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import {
  clearSteps,
  saveAllStepsAsSeen,
  saveStepAsSeen,
  setSteps,
} from 'slices/onboardingSlice'
import { locale } from '../locale'
import { styles } from '../styles'
import { ELEMENTS } from './elements'
import { accueilSteps } from './steps/accueil'
import { dossiersSteps } from './steps/dossiers'
import { employeurSteps } from './steps/employeur'
import { facturesSteps } from './steps/factures'
import { piecesSteps } from './steps/pieces'
import { recapSteps } from './steps/recap'

export default function Onboarding() {
  let interval: any;

  const dispatch = useAppDispatch();
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onPageLoad(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.listen(onPageLoad), [history]);
  
  const { unseenSteps, id } = useSelector(
    (state: RootState) => state.onboardingState,
  );

  if (window.localStorage.getItem('ignoreOnboarding')) {
    return null;
  }

  // When a new page is displayed, search for steps to use
  function onPageLoad(): void {
    if (history.location.pathname.includes('nouveau-dossier')) {
      setTimeout(() => {
        switch (document.querySelector('h2')?.innerText) {
          case 'Employeur':
            setTimeout(() => {dispatch(setSteps(employeurSteps))}, 2000);
            break;
          case 'Pièces':
            setTimeout(() => {dispatch(setSteps(piecesSteps))}, 2000);
            break;
          case 'Récapitulatif':
            setTimeout(() => {dispatch(setSteps(recapSteps))}, 2000);
            break;
          default:
            clearInterval(interval);
            dispatch(clearSteps());
            break;
        }
      });
    } else {
      switch (history.location.pathname) {
        case '/':
          // No joyride on myAtlas mon compte
          if (history.location.search.includes('contexte=entreprise')
            || history.location.search.includes('contexte=prestataire')
          ) {
            setTimeout(() => {dispatch(setSteps(accueilSteps))}, 1000);
          }
          break;
        case '/mes-dossiers-contrats':
          setTimeout(() => {dispatch(setSteps(dossiersSteps))}, 1000);
          break;
        case '/mes-factures':
          setTimeout(() => {dispatch(setSteps(facturesSteps))}, 1000);
          break;
        default:
          clearInterval(interval);
          dispatch(clearSteps());
          break;
      }
    }
  }

  // The callback that will be passed to joyride
  function onJoyrideEvent(event: CallBackProps) {
    switch (event.type) {
      case EVENTS.TOOLTIP:
        if (event.step) {
          onTooltip(event.step);
        }
        break;
      case EVENTS.STEP_AFTER:
        if (event.step) {
          onStepAfter(event.step);
        }
        break;
      case EVENTS.TOUR_END:
        onTourEnd();
        break;
      default:
        break;
    }
  }

  // When a step tooltip is opened
  function onTooltip(step: Step): void {
    switch (step.target) {
      case ELEMENTS.COMPANY:
      case ELEMENTS.NEW_REQUEST:
        setTimeout(() => toggleDetails(step.target as string));
        break;
      case ELEMENTS.MENU:
        setTimeout(toggleMenu);
        break;
      default:
        break;
    }
  }

  // Once a step has been seen
  function onStepAfter(step: Step): void {
    dispatch(saveStepAsSeen(step));

    switch (step.target) {
      case ELEMENTS.COMPANY:
      case ELEMENTS.NEW_REQUEST:
        toggleDetails(step.target as string, false);
        break;
      case ELEMENTS.MENU:
        setTimeout(() => toggleMenu(false));
        break;
      default:
        break;
    }
  }

  // When the tour has ended
  function onTourEnd() {
    dispatch(saveAllStepsAsSeen());
    scroll.scrollToTop();
  }

  /** Open or close a <details> element */
  function toggleDetails(selector: string, open: boolean = true): void {
    const $target = document.querySelector(selector) as HTMLElement;

    if ($target) {
      const $details =
        $target.nodeName === 'DETAILS'
          ? $target as HTMLDetailsElement
          : $target.querySelector('details');

      if ($details) {
        if (open) {
          $target.style.setProperty('z-index', '999');
          $details.setAttribute('open', 'true');
        } else {
          $target.style.removeProperty('z-index');
          $details.removeAttribute('open');
        }
      }
    }
  }

  /** Toggle the menu for mobile onboarding */
  function toggleMenu(open = true): void {
    document
      .querySelector('.my-template__menu')
      ?.classList.toggle('my-template__menu--open', open);
  }

  return (
    <Joyride
      steps={unseenSteps}
      key={id}
      showProgress={unseenSteps.length > 1}
      continuous={true}
      showSkipButton={true}
      styles={styles}
      locale={locale}
      callback={onJoyrideEvent}
    ></Joyride>
  );
}
