
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import ExportTextField from 'pages/dossier/export/ExportTextField'
import RecapTextField from 'pages/dossier/recap/RecapTextField'
import { AsyncOptionalNIR } from 'components/fields/NIRValidator'
import { NIRAsyncField } from 'components/fields/NIRAsyncField'

export function NIRAsyncFieldMetadata(settings: SettingsFieldMetadata<string | null, {
    sexePath?: string
    nameNTT?: string
}> = {}) {
    return createMetadata(
        {
            props: {
                sexePath: 'sexe',
                nameNTT: 'ntt',
            },
            yop: AsyncOptionalNIR(settings.props?.sexePath ?? 'sexe'),
            input: NIRAsyncField,
            recap: RecapTextField,
            export: ExportTextField,
        },
        settings,
    )
}

