import { Entreprise } from 'api/referencesAPI'
import { AtlasFlex, AtlasHeading, AtlasSelect, AtlasValue, MyGauge, MyNumber } from 'atlas-ds'
import Link from 'components/Link'
import { useState } from 'react'

export interface StrategiePlusSoldeProps {
    detailEntreprise: Entreprise | null,
}

interface StrategiePlusInfo {
    annee: number
    solde: number
    enveloppe: number
    consomme: number
}

export function StrategiePlusSolde(props: StrategiePlusSoldeProps) {

    const [annee, setAnnee] = useState<'N1' | 'N2'>('N1')

    const infoParAnnee: Record<'N1' | 'N2', StrategiePlusInfo> = {
        N1: {
            annee: props.detailEntreprise?.VVAnneeN1__c ?? 0,
            solde: props.detailEntreprise?.VVSoldeAnneeN1__c ?? 0,
            enveloppe: props.detailEntreprise?.VVBudgetAnneeN1__c ?? 0,
            consomme: props.detailEntreprise?.VVEngageAnneeN1__c ?? 0,
        },
        N2: {
            annee: props.detailEntreprise?.VVAnneeN2__c ?? 0,
            solde: props.detailEntreprise?.VVSoldeAnneeN2__c ?? 0,
            enveloppe: props.detailEntreprise?.VVBudgetAnneeN2__c ?? 0,
            consomme: props.detailEntreprise?.VVEngageAnneeN2__c ?? 0,
        },
    }  

    const handleChange = (event: any) => {
        setAnnee(event.target.value)
    }

    const info = infoParAnnee[annee]
    const percentageRemaining = info.enveloppe
        ? (1 - ((info.consomme || 0) / info.enveloppe)) * 100
        : 100

    const periodOptions = [
        ...props.detailEntreprise?.VVAnneeN2__c
            ? [{value: "N2", label: props.detailEntreprise?.VVAnneeN2__c.toString()}]
            : [],
        ...props.detailEntreprise?.VVAnneeN1__c
            ? [{value: "N1", label: props.detailEntreprise?.VVAnneeN1__c.toString()}]
            : []
    ]

    return (
        <section>
            <AtlasHeading
                tag="h2"
                size="m"
                select={<AtlasSelect
                    name="annee"
                    ariaLabel="Sélectionner l'année de synthèse"
                    onChange={handleChange} 
                    defaultValue={annee}
                    options={periodOptions.map((option) => ({
                        value: option.value,
                        label: option.label
                    }))}
                />}
            >Synthèse budgétaire année</AtlasHeading>
            <AtlasFlex column gap="m">
                <MyGauge
                    percentage={ percentageRemaining }
                    remaining={<AtlasValue label="Solde">
                        <MyNumber>{`${info.solde} €`}</MyNumber>
                    </AtlasValue>}
                    total={<AtlasValue label="Enveloppe initiale">
                        <MyNumber>{`${info.enveloppe} €`}</MyNumber>
                    </AtlasValue>}
                    used={<AtlasValue label="Consommé">
                        <MyNumber>{`${info.consomme} €`}</MyNumber>
                    </AtlasValue>}
                />
                <div>
                    <Link
                        level={ 2 }
                        to={ `/mes-dossiers-contrats?page=0&par-page=20&recherche=&date-type=c&date-debut=${info.annee}-01-01&date-fin=${info.annee}-12-31&tri=DateCreationFO__c&ordre=desc&financement-vv=oui` }
                    >Consulter la liste des dossiers</Link>
                </div>
            </AtlasFlex>
        </section>
    )
}
