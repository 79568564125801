import classNames from "classnames";

export interface MyContainerProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
  /**
   * Appliquer une couleur de fond à l'élément
   */
  colored?: boolean;
  /**
   * Appliquer une couleur foncée à l'élément, avec un texte clair
   */
  dark?: boolean;
}

/**
 * Un conteneur visuel générique
 */
export function MyContainer(props: MyContainerProps) {
  return (
    <div
      className={classNames("my-container", {
        "my-container--colored": props.colored,
        "my-container--dark": props.dark,
      })}
    >
      {props.children}
    </div>
  );
}
