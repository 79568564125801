import { AtlasButton, MyModal } from 'atlas-ds'
import Logo from 'components/Logo'

export default function Maintenance() {
    return (
        <MyModal
            isError
            label="Le service est actuellement indisponible"
            okButton={<AtlasButton onClick={ () => document.location.reload() }>Réessayer maintenant</AtlasButton>}
        >
            <Logo />
            <p>Nous nous excusons pour la gêne occasionnée. Merci de réessayer ultérieurement.</p>
        </MyModal>
    )
}
