import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import cerfaImage from 'pages/dossier/cerfa/img/cerfa.png'
import ministryImage from 'pages/dossier/cerfa/img/ministry.png'
import styles from 'pages/dossier/cerfa/global.styles'
import { DossierProA } from 'pages/dossier-proa/ProAFormMetadata'
import Employeur from './parts/Employeur'
import Salarie from './parts/Salarie'
import EmploiOccupe from './parts/EmploiOccupe'
import EmploiVise from './parts/EmploiVise'
import Tuteur from './parts/Tuteur'
import Opco from './parts/Opco'
import Formation from './parts/Formation'

export function createCerfaProA(dossier: DossierProA) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image source={ministryImage} style={styles.ministryImage}></Image>
          <View style={styles.titles}>
            <Text style={styles.title}>
              Reconversion ou promotion par alternance (Pro-A)
            </Text>
            <Text style={styles.subtitle}>
              (art. L. 6324-1 à L. 6324-8 du code du travail)
            </Text>
            <Text style={styles.notice}>
              (Lire attentivement la notice Cerfa avant de remplir ce document)
            </Text>
          </View>
          <View style={styles.cerfa}>
            <Image source={cerfaImage} style={styles.cerfaImage}></Image>
            <Text style={styles.cerfaReference}>N° 16155*02</Text>
          </View>
        </View>
        <View style={styles.main}>
          <Employeur employeur={ dossier.employeur }></Employeur>
          <Salarie salarie={ dossier.salaries[0] }></Salarie>
          <EmploiOccupe contrat={ dossier.contrat }></EmploiOccupe>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.main}>
          <EmploiVise contrat={ dossier.contrat }></EmploiVise>
          <Tuteur tuteur={ dossier.tuteur }></Tuteur>
          <Formation dossier={ dossier }></Formation>
          <Opco opco={{ nom: '', numeroAdherent: '' } }></Opco>
          <View style={styles.footer}>
            <Text style={[styles.boldItalic, styles.xSmall]}>
              Pour remplir le CERFA et pour plus d’informations sur le
              traitement des données reportez-vous à la notice.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
