import { DossierCoreDTO } from 'api/dossierAPI'
import CodeIdccField from 'components/fields/CodeIdccField'
import { AdresseValidationFieldsParams } from 'components/yop/constraints'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import ExportCodeIdccField from 'pages/dossier/export/ExportCodeIdccField'
import RecapCodeIdccField from 'pages/dossier/recap/RecapCodeIdccField'
import { Adresse, DossierEmployeur, DossierEmployeurExtended } from 'slices/dossierCommon'
import { RequiredCodePostalCommuneFieldMetadata } from '../../../components/metadata/RequiredCodePostalCommuneFieldMetadata'
import { RequiredSiretFieldMetadata } from '../../../components/metadata/SiretFieldMetadata'
import { TextFieldMetadata } from '../../../components/metadata/TextFieldMetadata'
import { MappingContext, mapDTOToModel, mapModelToDTO, simpleMapping } from './DossierMapping'
import { ObjectMetadata, disabled } from './FormMetadata'


export const SECTION_EMPLOYEUR_ADRESSE = 'adresse'
export const SECTION_EMPLOYEUR_IDENTIFICATION = 'identification'
export const SECTION_EMPLOYEUR_EXTRA = 'extra'
export const SECTION_EMPLOYEUR_CONTACT = 'contact'

export const adresseMetadata: ObjectMetadata<Adresse> = {
    appartement: TextFieldMetadata({
        props: {
            label: 'Appartement',
        },
        section: SECTION_EMPLOYEUR_ADRESSE,
        mapping: simpleMapping('Adresse1ADH__c'),
    }).mutate(yop => yop?.max(AdresseValidationFieldsParams.Apt)),
    batiment: TextFieldMetadata({
        props: {
            label: 'Bâtiment',
        },
        section: SECTION_EMPLOYEUR_ADRESSE,
        mapping: simpleMapping('Adresse2ADH__c'),
    }).mutate(yop => yop?.max(AdresseValidationFieldsParams.Bat)),
    adresse: TextFieldMetadata({
        props: {
            label: 'N° et voie',
        },
        section: SECTION_EMPLOYEUR_ADRESSE,
        mapping: simpleMapping('Adresse3ADH__c'),
    }).mutate(yop => yop?.max(AdresseValidationFieldsParams.Adr)),
    complement: TextFieldMetadata({
        props: {
            label: 'Lieu dit / BP',
        },
        section: SECTION_EMPLOYEUR_ADRESSE,
        mapping: simpleMapping('Adresse4ADH__c'),
    }).mutate(yop => yop?.max(AdresseValidationFieldsParams.Cpt)),
    codePostal: RequiredCodePostalCommuneFieldMetadata({
        props: {
            label: 'Code postal et commune',
        },
        section: SECTION_EMPLOYEUR_ADRESSE,
        mapping: simpleMapping('CodePostalADH__c'),
    }),
    commune: TextFieldMetadata({
        section: SECTION_EMPLOYEUR_ADRESSE,
        mapping: simpleMapping('CommuneADH__c'),
        input: undefined
    }).required().mutate(yop => yop?.max(AdresseValidationFieldsParams.Com)),
}

export const employeurMetadata: ObjectMetadata<DossierEmployeur> = {
    nom: TextFieldMetadata({
        props: {
            label: 'Raison sociale',
            disabled: true,
        },
        section: SECTION_EMPLOYEUR_IDENTIFICATION,
        mapping: simpleMapping('NomADH__c'),
    }).required(),
    numeroSiret: RequiredSiretFieldMetadata({
        props: {
            label: 'SIRET',
            disabled: true,
        },
        section: SECTION_EMPLOYEUR_IDENTIFICATION,
        mapping: simpleMapping('SiretADH__c'),
    }),
    appartement: disabled(adresseMetadata.appartement),
    batiment: disabled(adresseMetadata.batiment),
    adresse: disabled(adresseMetadata.adresse),
    complement: disabled(adresseMetadata.complement),
    codePostal: disabled(adresseMetadata.codePostal),
    commune: disabled(adresseMetadata.commune),
}

export const employeurExtendedMetadata: ObjectMetadata<DossierEmployeurExtended> = { 
    ...employeurMetadata,
    codeNaf: TextFieldMetadata({
        props: {
            label: 'Code activité de l’entreprise (NAF)',
            disabled: true,
        },
        section: SECTION_EMPLOYEUR_EXTRA,
        mapping: simpleMapping('CodeNAF__c'),
    }).required(),
    codeIdcc: TextFieldMetadata({
        props: {
            label: 'Code IDCC de la convention collective applicable',
            tooltip: TOOLTIPS.employeur.codeIdcc,
            disabled: (props: any) => !props.parent?.Id,
        },
        section: SECTION_EMPLOYEUR_EXTRA,
        mapping: simpleMapping('CodeIDCC__c'),
        input: CodeIdccField,
        recap: RecapCodeIdccField,
        export: ExportCodeIdccField,
    }).required(),
    telephone: TextFieldMetadata({
        props: {
            label: 'Téléphone',
            disabled: (props: any) => !props.parent?.Id,
        },
        section: SECTION_EMPLOYEUR_EXTRA,
        mapping: simpleMapping('TelephoneADH__c'),
    }).required(),
    email: TextFieldMetadata({
        props: {
            label: 'E-mail',
            disabled: (props: any) => !props.parent?.Id,
        },
        section: SECTION_EMPLOYEUR_EXTRA,
        mapping: simpleMapping('EmailADH__c'),
    }).required(),
}


export function mapDTOToEmployeur<E extends DossierEmployeur, DTO extends DossierCoreDTO>(
    context: MappingContext,
    employeurMetadata: ObjectMetadata<E>,
    employeur: E,
    dto: DTO
) {
    employeur.Id = dto.EtablissementAdherentId__c
    employeur.ParentId = dto.EntrepriseAdherentId__c
    mapDTOToModel(context, employeurMetadata, employeur, dto)
}

export function mapEmployeurToDTO<E extends DossierEmployeur>(employeurMetadata: ObjectMetadata<E>, dossier: any, employeur: E): any {
    const dto = {
        EtablissementAdherentId__c: employeur?.Id ?? null,
        EntrepriseAdherentId__c: employeur?.ParentId ?? null,
        // CaisseRetraiteComplementaire__c: null, ce champ ne doit être transmis que pour les CAPP / CPRO
        // NbSalariesEntreprise__c: null, idem...
    } as unknown as E
    mapModelToDTO(employeurMetadata, dossier, employeur, dto)
    return dto
}
