import { DISPOSITIF_BILAN_COMPETENCE, DISPOSITIF_CSCA, DISPOSITIF_CSCM, DISPOSITIF_TPE_PME } from 'api/references'
import { Reference } from 'api/referencesAPI'
import { DetailDossierExtension } from '../DetailDossierContrat'
import { actionsEntrepriseRelanceAnnulerSubrogation, actionsEntrepriseRelanceCloture, actionsEntrepriseRelanceDemanderSubrogation } from '../relanceff/entreprise'
import { actionsPrestataireRelanceAnnulation, actionsPrestataireRelanceAnnulerSubrogation, actionsPrestataireRelanceCloture, actionsPrestataireRelanceModifierDates } from '../relanceff/prestataire'
import { actionsEntrepriseAnnulerDossier, actionsEntrepriseChangerPeriode } from './entreprise'


const dispositifs = [
    DISPOSITIF_BILAN_COMPETENCE,
    DISPOSITIF_CSCA,
    DISPOSITIF_CSCM,
    DISPOSITIF_TPE_PME,
]

const dossierDefaut = (dispositif: Reference) => {
    return {
        name: dispositif.LibelleReduit__c,
        dispositifId: dispositif.Id,
        metadata: {},
        extensions: {
            entreprise: {
                DetailDossierContrat: {
                    actions: (dossier, actions) => {
                        actionsEntrepriseChangerPeriode(dossier, actions)
                        
                        actionsEntrepriseAnnulerDossier(dossier, actions)
                        
                        if (dispositifs.includes(dossier.DispositifFO__c)) {
                            actionsEntrepriseRelanceCloture(dossier, actions)
                            actionsEntrepriseRelanceDemanderSubrogation(dossier, actions)
                            actionsEntrepriseRelanceAnnulerSubrogation(dossier, actions)
                        }
                    }
                }
            } as DetailDossierExtension,
            prestataire: {
                DetailDossierContrat: {
                    actions: (dossier, actions) => {
                        if (dispositifs.includes(dossier.DispositifFO__c)) {
                            actionsPrestataireRelanceCloture(dossier, actions)
                            actionsPrestataireRelanceAnnulerSubrogation(dossier, actions)
                            actionsPrestataireRelanceAnnulation(dossier, actions)
                            actionsPrestataireRelanceModifierDates(dossier, actions)
                        }
                    },
                },
            } as DetailDossierExtension,
        },
    }
}

export default dossierDefaut