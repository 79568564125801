import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'
import Checkboxes from 'pages/dossier/cerfa/components/Checkboxes'
import { CAPPEmployeurMetadata } from 'pages/dossier-capp/CAPPEmployeurMetadata'
import { DossierCAPPEmployeur } from 'pages/dossier-capp/CAPPModel'
import CerfaAdresseField from 'pages/dossier/cerfa/CerfaAdresseField'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'

export default function Employeur({ employeur }: { employeur: DossierCAPPEmployeur }) {
  return (
    <View style={styles.section}>
      <Text style={[styles.sectionHeader, styles.employeurHeader]}>
        <Text>L'EMPLOYEUR</Text>
        {'                '}
        <Checkboxes
          value={ !employeur?.employeurPublic }
          choices={[
            { label: 'employeur privé', value: true },
            { label: 'employeur « public »*', value: false },
          ]}
          spacing={12}
        ></Checkboxes>
      </Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text style={styles.bold}>Nom et prénom ou dénomination :</Text>
            <CerfaElement metadata={ CAPPEmployeurMetadata.nom } value={ employeur.nom } />
            <Text>Adresse de l’établissement d’exécution du contrat :</Text>
            <CerfaAdresseField value={ employeur } />
            <Text>
              Téléphone :{' '}
              <CerfaElement metadata={ CAPPEmployeurMetadata.telephone } value={ employeur.telephone } />
            </Text>
            <Text>
              Courriel :{' '}
              <Text style={styles.user}>
                <CerfaElement metadata={ CAPPEmployeurMetadata.email } value={ employeur.email } default="____________________@___________________" />
              </Text>
            </Text>
          </View>
          <View style={styles.colRight}>
            <Text>
              <Text style={styles.bold}>N°SIRET</Text> de l'établissement d'exécution du contrat :
            </Text>
            <CerfaElement metadata={ CAPPEmployeurMetadata.numeroSiret } value={ employeur.numeroSiret } />
            <Text>
              Type d'employeur :{' '}
              <CerfaElement metadata={ CAPPEmployeurMetadata.typeEmployeur } value={ employeur.typeEmployeur } />
            </Text>
            <Text>
              Employeur spécifique :{' '}
              <CerfaElement metadata={ CAPPEmployeurMetadata.employeurSpecifique } value={ employeur.employeurSpecifique } />
            </Text>
            <Text>
              Code activité de l'entreprise (NAF) :{' '}
              <CerfaElement metadata={ CAPPEmployeurMetadata.codeNaf } value={ employeur.codeNaf } />
            </Text>
            <Text>
              Effectif total salarié de l'entreprise :{' '}
              <CerfaElement metadata={ CAPPEmployeurMetadata.effectifSalarie } value={ employeur.effectifSalarie } />
            </Text>
            <Text>Code IDCC de la convention collective applicable :</Text>
            <CerfaTextField value={ employeur.codeIdcc } />
          </View>
        </View>
        <Text style={styles.employeurFooter}>
          <Text style={styles.bold}>
            *Pour les employeurs du secteur public,{' '}
          </Text>
          <Text>
            adhésion de l'apprenti au régime spécifique d'assurance chômage :
          </Text>{' '}
          <Checkbox checked={employeur.regimeSpecifique}></Checkbox>
        </Text>
      </View>
    </View>
  )
}
