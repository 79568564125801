import { getThematiquesCampus } from "api/formationAPI";
import { createApiSlice } from 'api/createApiSlice'


export const thematiquesCampusSlice = createApiSlice({
    name: 'thematiquesCampus/get',
    call: getThematiquesCampus,
    getCacheKey: (state: any) => state.contextState.app + '/thematiquesCampus',
    useCachedState: 'thematiquesCampusState',
})

export const thematiquesCampusActions = {
    load: () => thematiquesCampusSlice.actions.caller(null)
}
