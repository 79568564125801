import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import DetailRefBancaire from 'pages/detail-ref-bancaire/DetailRefBancaire'
import AjoutRefBancaireAdherent from './AjoutRefBancaireAdherent'
import AjoutRefBancaireFactor from './AjoutRefBancaireFactor'
import MesRefsBancaires from './MesRefsBancaires'


const refsBancairesModule = (key: string, labelAjout: string) => { 
    return {
        id: 'mes-refs-bancaires',
        isEnabled: () => import.meta.env.REACT_APP_MODULES?.includes(key),
        routes: [
            <AppRoute key="refs-bancaires" path="/mes-refs-bancaires" label="Références Bancaires" menuIcon="references-bancaires">
                <MesRefsBancaires labelAjout={ labelAjout } />
            </AppRoute>,
            <AppRoute key="ref-bancaire-adherent-ajout" path="/ajout-ref-bancaire-adherent" label={ labelAjout }>
                <AjoutRefBancaireAdherent />
            </AppRoute>,
            <AppRoute key="ref-bancaire-factor-ajout" path="/ajout-ref-bancaire-factor" label="Ajouter un RIB factor">
                <AjoutRefBancaireFactor />
            </AppRoute>,
            <AppRoute key="ref-bancaire-detail" path="/detail-ref-bancaire/:idrefbancaire" component={ DetailRefBancaire } />,
        ],
    } as AppModule
}

export default refsBancairesModule