import { getFieldState, useFormContext } from "@dsid-opcoatlas/reform"
import { AtlasFieldCheckboxGroup } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"

export interface CheckboxOption {
    value: string
    label: string
}

interface MultiselectCheckboxesFieldProps<T extends object> extends BaseFieldProps<string[], T> {
    options: CheckboxOption[]
}

export default function MultiselectCheckboxesField<T extends object>(props: MultiselectCheckboxesFieldProps<T>) {
    const context = useFormContext<T>()
    const fieldState = getFieldState<string[]>(context, props.name)

    const internalOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (context.getValue(props.name) ?? []) as string[]
        const optionId = event.target.value

        if (event.currentTarget.checked) {
            if (!value.includes(optionId)) {
                value.push(optionId)
                props.onChange?.(value, context)
            }
        } else {
            const idx = value.indexOf(optionId)
            if (idx >= 0) {
                value.splice(idx, 1)
                props.onChange?.(value, context)
            }
        }
        context.setValue(props.name, value, true)
    }

    return <AtlasFieldCheckboxGroup
        name={ props.name }
        label={ props.label }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        error={ fieldState.error }
        options={ props.options }
        onChange={ internalOnChange }
    />
}
