import { Config, getConfig } from 'api/referencesAPI'
import { createApiSlice } from 'api/createApiSlice'
import { AppType } from './contextSlice'


const name = 'config/get'
const CONFIG_VERSION_MIN = 10

export const configSlice = createApiSlice({
    name,
    cacheable: (config: Config) => (config.Version ?? 0) >= CONFIG_VERSION_MIN && config.ContexteSpecifiquePdc !== undefined,
    call: getConfig
})

export const configActions = {
    clear: () => configSlice.actions.clear(),
    load: (app: AppType) => configSlice.actions.caller(app)
}

export const removeConfigFromStorage = () => {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key?.startsWith('myatlas.' + name))
            sessionStorage.removeItem(key)
    }
}
