import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import { AccueilCompte } from './AccueilCompte'

const accueilModule = {
    id: 'accueil-compte',
    routes: [
        <AppRoute exact key="accueil" path="/" label="Accueil" menuIcon="accueil">
            <AccueilCompte />
        </AppRoute>,
    ],
} as AppModule

export default accueilModule