import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapBooleanField from 'pages/dossier/recap/RecapBooleanField'
import ExportBooleanField from 'pages/dossier/export/ExportBooleanField'
import { OptionalBoolean } from 'components/yop/constraints'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'
import CerfaBooleanField, { CerfaCheckboxBooleanField } from 'pages/dossier/cerfa/CerfaBooleanField'
import CheckboxField from 'components/reform/inputs/CheckboxField'

export function BooleanFieldMetadata(settings: SettingsFieldMetadata<boolean | null, {
    labelYes?: string
    labelNo?: string
    reverse?: boolean
}> = {}) {
    return createMetadata(
        {
            yop: OptionalBoolean,
            input: RadioBooleanField,
            recap: RecapBooleanField,
            export: ExportBooleanField,
            cerfa: CerfaBooleanField,
        },
        settings,
    )
}

export function CheckboxFieldMetadata(settings: SettingsFieldMetadata<boolean | null> = {}) {
    return BooleanFieldMetadata({ input: CheckboxField, cerfa: CerfaCheckboxBooleanField, ...settings })
}