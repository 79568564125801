import { MyForm } from 'atlas-ds'
import { SECTION_CONTRAT_EMPLOI_OCCUPE, SECTION_CONTRAT_EMPLOI_VISE } from 'pages/dossier/metadata/ContratMetadata'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossier } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'

export function DossierContratElements<D extends BaseDossier>(props: { metadata: FormMetadata<D> }) {
    
    const { step } = useDossierNavigation(props.metadata)

    return (
        <>
            { step.sections?.includes(SECTION_CONTRAT_EMPLOI_OCCUPE) &&
            <MyForm.Fieldset legend="Emploi occupé">
                <MyForm.Grid>
                    <FormElements section={ SECTION_CONTRAT_EMPLOI_OCCUPE } metadata={ props.metadata } path="contrat" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }

            { step.sections?.includes(SECTION_CONTRAT_EMPLOI_VISE) &&
            <MyForm.Fieldset legend="Emploi visé">
                <MyForm.Grid>
                    <FormElements section={ SECTION_CONTRAT_EMPLOI_VISE } metadata={ props.metadata } path="contrat" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }
        </>
    )
}
