import { compareAsc, differenceInDays, differenceInMonths, isValid, parseISO } from 'date-fns'
import { round } from 'lodash'

export function roundCurrency(value: number): number {
    return round(value, 2)
}

export function parseNumber(value: string, defaultValue: number = 0): number {
    return parseInt(value, 10) || defaultValue
}

export function parseApiDate(date?: string | null): Date | null {
    if (date) {
        const parsed = parseISO(date)
        if (isValid(parsed))
            return parsed
    }
    return null
}

interface SearchParams {
    [key: string]: string | string[] | null | undefined
}

export function toSearchParams(params: SearchParams): URLSearchParams {
    return setSearchParams(new URLSearchParams(), params)
}

export function toSearchString(params: SearchParams): string {
    return toSearchParams(params).toString()
}

export function setSearchParams(urlParams: URLSearchParams, params: SearchParams): URLSearchParams {
    for (let [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            urlParams.delete(key)
            for (let item of (value as string[]))
                urlParams.append(key, item)
        }
        else if (value !== null && value !== undefined) {
            urlParams.set(key, value)
        }
    }
    return urlParams
}

export function runAfterRender(callback: () => void) {
    setTimeout(() => { requestAnimationFrame(callback) })
}

export const diffDays = (date1?: Date | string | null, date2?: Date | string | null): number => {
    if (!date1 || !date2)
        return Number.NaN
    const dt1 = typeof date1 === 'string' ? parseISO(date1) : new Date(date1)
    const dt2 = typeof date2 === 'string' ? parseISO(date2) : new Date(date2)
    return differenceInDays(dt1, dt2)
}

export const diffMonths = (date1?: Date | string | null, date2?: Date | string | null): number => {
    if (!date1 || !date2)
        return Number.NaN

    const dt1 = typeof date1 === 'string' ? parseISO(date1) : new Date(date1)
    const dt2 = typeof date2 === 'string' ? parseISO(date2) : new Date(date2)
    return differenceInMonths(dt1, dt2)
}

export const compareDates = (date1?: Date | string | null, date2?: Date | string | null): number => {
    if (!date1 || !date2)
        return Number.NaN
        const dt1 = typeof date1 === 'string' ? parseISO(date1) : new Date(date1)
        const dt2 = typeof date2 === 'string' ? parseISO(date2) : new Date(date2)
        return compareAsc(dt1, dt2)
}
