import { BaseRadioField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldRadioGroup, AtlasFlex, AtlasInfo } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { ReactNode } from "react"

export interface RadioOption {
    value: string
    label: any
    selectedLabel?: string
}

export interface RadioFieldProps<T extends object, V> extends BaseFieldProps<V, T> {
    options: RadioOption[]
    convertOptionValue?: (value: string) => V
    successMessage?: ReactNode
    infoMessage?: ReactNode
    warningMessage?: ReactNode
}

export default function RadioField<T extends object, V = string>(props: RadioFieldProps<T, V>) {
    const context = useFormContext<T>()
    const fieldState = getFieldState<V>(context, props.name)
    const render = useRender()

    return <AtlasFlex column gap="s">
        <AtlasFieldRadioGroup
            name={ props.name }
            options={ props.options }
            label={ props.label }
            required={ fieldState.constraints.required }
            tooltipContent={ props.tooltip }
            error={ fieldState.error }
            disabled={ props.disabled }
            inputConstructor={(inputProps) => <BaseRadioField
                { ...inputProps }
                render={ render }
                modelValue={ props.convertOptionValue ? props.convertOptionValue(inputProps.value) : inputProps.value as V }
                onChange={ props.onChange }
            />}
        />

        { props.successMessage && <AtlasInfo mini type="success" title="Succès">{ props.successMessage }</AtlasInfo> }

        { props.infoMessage && <AtlasInfo mini title="Information">{ props.infoMessage }</AtlasInfo> }

        { props.warningMessage && <AtlasInfo mini type="warning" title="Attention">{ props.warningMessage }</AtlasInfo> }
    </AtlasFlex>
}
