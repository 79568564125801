import { SettingsFieldMetadata } from "pages/dossier/metadata/FormMetadata"
import { BooleanFieldMetadata } from "./BooleanFieldMetadata"

export function RequiredFormationInterneFieldMetadata(settings: SettingsFieldMetadata<boolean | null, {
    labelYes?: string,
    labelNo?: string,
}> = {}) {
    return BooleanFieldMetadata(settings).required()
}

