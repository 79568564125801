import { AppModule } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import StrategiePlus from 'pages/strategie-plus/StrategiePlus'

const strategiePlusModule = {
    id: 'strategie-plus',
    routes: [
        <AppRoute key="strategiePlus" path="/strategie-plus" component={StrategiePlus} label="Stratégie +" menuIcon="strategie-plus" />
    ],
} as AppModule

export default strategiePlusModule