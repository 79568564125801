import { BaseTextAreaField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldTextArea } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { InputHTMLAttributes } from "react"

export type TextAreaProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'type' | 'name' | 'value' | 'checked' | 'disabled' | 'onChange' | 'onBlur'>

export interface MultilineTextFieldProps<T extends object> extends BaseFieldProps<string, T> {
    max?: number
    rows?: number
    textAreaProps?: TextAreaProps
}

const asNumber = (value: any) => {
    return typeof value === 'number' ? value : undefined
}

export default function MultilineTextField<T extends object>(props: MultilineTextFieldProps<T>) {
    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)

    return <AtlasFieldTextArea
        name={ props.name }
        label={ props.label }
        disabled={ props.disabled }
        required={ fieldState.constraints.required  }
        tooltipContent={ props.tooltip }
        error={ fieldState.error }
        rows={ props.rows }
        maxLength={ props.max ?? asNumber(fieldState.constraints.max) }
        inputConstructor={ (inputProps) => <BaseTextAreaField
            { ...inputProps }
            onChange={ props.onChange }
            render={ render }
        /> }
    />
}
