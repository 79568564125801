import { BaseFileField, getFieldState, useFormContext, useRender } from "@dsid-opcoatlas/reform"
import { AtlasFieldFile } from "atlas-ds"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { AcceptedDocuments } from "components/yop/constraints"

interface FileFieldProps<T extends object> extends BaseFieldProps<File, T> {
    accept?: string[]
}

export default function FileField<T extends object>(props: FileFieldProps<T>) {
    const render = useRender();

    const context = useFormContext<T>()
    const fieldState = getFieldState<File>(context, props.name)

    const accept = (props.accept ?? Object.keys(AcceptedDocuments)).join(',')

    return <AtlasFieldFile
        name={ props.name }
        label={ props.label }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        disabled={ props.disabled }
        accept={ accept }
        selectedFileName={ fieldState.value?.name }
        error={ fieldState.error }
        inputConstructor={ (inputProps) => <BaseFileField
            { ...inputProps }
            onChange={ props.onChange }
            render={ render }
        /> }
    />
}
