import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatDate, formatSuffix } from 'components/format/Format'

interface ExportTextFieldProps {
    label: string
    exportLabel?: string
    row?: boolean
    cell?: boolean
    value: string | Date | null | undefined
    suffix?: string
    type?: string
    hiddenExport?: boolean
}

export default function ExportTextField(props: ExportTextFieldProps) {
    if (props.hiddenExport && !props.cell) {
        return null
    }
    if (props.value == null) {
        return props.cell ? <Value cell value={{ value: '' }} /> : null
    }
    
    const suffix = props.suffix ? ' ' + formatSuffix(props.suffix, true) : ''

    let value = ''
    if (props.value) {
        if (props.type === 'date')
            value = formatDate(props.value) + suffix
        else if (props.type === 'number' && typeof props.value === 'string' && parseFloat(props.value))
            value = props.value + formatSuffix(suffix, parseFloat(props.value) >= 2)
        else
            value = props.value + suffix
    }
    
    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.exportLabel ?? props.label,
        value,
    }} />
}
