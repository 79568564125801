import { AtlasValue, AtlasValues } from 'atlas-ds'
import { formatAdresse } from 'components/format/Format'
import { get } from 'lodash'
import { BaseDossier, BaseDossierModule } from 'slices/dossierCommon'
import RecapBooleanField from './RecapBooleanField'


interface RecapOrganismeFormationProps {
    root: BaseDossier | null
    parent: BaseDossierModule | null
    path: string
    entreprise?: boolean
    uai?: boolean
    uaiLabel?: string
    da?: boolean
}

export default function RecapOrganismeFormation(props: RecapOrganismeFormationProps) {
    const cfa = get(props.root, props.path)
    return (<AtlasValues>
        <AtlasValues.Grid>
            <AtlasValue label="Nom de l'établissement">{ cfa?.nom }</AtlasValue>
            <AtlasValue label="SIRET">{ cfa?.siret }</AtlasValue>

            { props.da && <AtlasValue label="N° de déclaration d'activité">{ cfa?.numeroDA }</AtlasValue> }
            { props.uai && <AtlasValue label={ props.uaiLabel ?? 'N° UAI de l\'organisme' }>{ cfa?.numeroUAI }</AtlasValue> }
            { props.entreprise && <RecapBooleanField label="CFA entreprise" value={ cfa?.entreprise } /> }
        </AtlasValues.Grid>
        <AtlasValue label="Adresse">{ formatAdresse(cfa?.adresse, cfa?.codePostal, cfa?.commune) }</AtlasValue>
    </AtlasValues>)
}
