import { BlockValue } from 'pages/dossier/cerfa/CerfaDpc'
import { formatAdresse } from 'components/format/Format'
import { get } from 'lodash'
import { BaseDossier, DossierCFA } from 'slices/dossierCommon'
import { BaseDossierModule } from 'slices/dossierCommon'


interface ExportOrganismeFormationProps {
    label?: string
    cell?: boolean
    name?: string
    value?: DossierCFA | null
    root?: BaseDossier | null
    parent?: BaseDossierModule | null
    entreprise?: boolean
    uai?: boolean
    da?: boolean
}

export default function ExportOrganismeFormation(props: ExportOrganismeFormationProps) {
    if (props.parent?.formationInterne === true || !props.parent?.cfa) {
        return false
    }
    const cfa = props.value ?? (props.name && props.root ? get(props.root, props.name) : props.parent?.cfa)
    
    let values = [
        { label: 'Nom', value: cfa?.nom },
        { label: 'SIRET', value: cfa?.siret },
    ].concat(props.da ? [
        { label: 'Numéro DA', value: cfa?.numeroDA ?? '' }] : []
    ).concat(props.uai ? [
        { label: 'Numéro UAI', value: cfa?.numeroUAI ?? '' }] : []
    ).concat(props.entreprise ? [
        { label: 'CFA entreprise', value: cfa?.entreprise ? 'Oui' : (cfa?.entreprise === false ? 'Non' : '') }] : []
    ).concat(cfa?.appartement ? [
        { label: 'Appartement', value: cfa?.appartement }] : []
    ).concat(cfa?.batiment ? [
        { label: 'Bâtiment', value: cfa?.batiment }] : []
    ).concat([
        { label: 'Adresse', value: formatAdresse(cfa?.adresse, cfa?.codePostal, cfa?.commune) },
    ]).concat(cfa?.complement ? [
        { label: 'Complement', value: cfa?.complement }] : []
    )
    if (props.cell) {
        values = values.filter(v => v.value)
    }

    return <BlockValue value={{
        cell: props.cell,
        title: props.label,
        values
    }} />
}