import { AtlasInfo } from "atlas-ds"
import PageHeader from "components/PageHeader"
import { Link } from "react-router-dom"

export function WebAdhesionSucces() {

    return <>
        <PageHeader>Faire adhérer l'entreprise à Atlas</PageHeader>

        <AtlasInfo
            title="Votre entreprise est en cours d'adhésion"
            link={<Link to="/">Revenir à l'accueil</Link>}
        >Votre demande d'adhésion a bien été reçue, elle sera traitée dans les meilleurs délais.</AtlasInfo>
    </>
}
