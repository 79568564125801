/* eslint-disable jsx-a11y/anchor-is-valid */
import ExtensionPoint from 'app/ExtensionPoint'
import { AtlasColumns, AtlasFlex } from 'atlas-ds'
import DataSummaries from 'components/DataSummaries'
import PageHeader from "components/PageHeader"


export interface AccueilProps {
    accesRapides : any
    widgets?: JSX.Element | JSX.Element[]
}

export type AccueilExtension = {
    accueil: {
        widgets?: JSX.Element | JSX.Element[]
    }
}

export default function Accueil(props:AccueilProps) {
    return <>
        <PageHeader>Accueil</PageHeader>

        <AtlasFlex column gap="xxl">
            <AtlasColumns reversed>
                <props.accesRapides />

                <DataSummaries />
            </AtlasColumns>

            {props.widgets ? props.widgets : <ExtensionPoint<AccueilExtension> name="accueil" point="widgets" />}
        </AtlasFlex>
    </>
}