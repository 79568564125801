import { AtlasIcon, AtlasTooltipProps } from 'atlas-ds';
import { MouseEventHandler } from "react";

export interface MyFileCtaProps {
  /**
   * Le label du bouton
   */
  label: string;
  /**
   * L'action à éxécuter au clic sur le bouton
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * Une tooltip d'informations (par exemple sur les types/poids de fichier supportés)
   */
  tooltip?: React.ReactElement<AtlasTooltipProps>;
}

/**
 * Un bouton d'ajout de document
 */
export function MyFileCta(props: MyFileCtaProps) {
  return (
    <div className="my-fileCta">
      <button
        type="button"
        className="my-fileCta__button"
        onClick={props.onClick}
      >
        <AtlasIcon name="add" />
        {props.label}
      </button>
      {props.tooltip}
    </div>
  );
}
