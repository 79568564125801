import { DISPOSITIF_VAEI } from "api/references"
import { DossierPointReserve, createAdresse, createBaseDossier, createBaseDossierModule, createBaseDossierTuteur, createDossierEmployeurExtended, createDossierStagiaire, nil } from "slices/dossierCommon"

function createDossierVAEIEmployeur() { return {
    ...createDossierEmployeurExtended(),
    caisseRetraiteComplementaire: nil as string,
    organismePrevoyance: null as (string | null),
    effectifSalarie: nil as number,
}}
export type DossierVAEIEmployeur = ReturnType<typeof createDossierVAEIEmployeur>

export function createDossierVAEIModule() { return {
    ...createBaseDossierModule(),
    attestationFormationInterne: nil as (boolean | null),
    formationAfest: false,
    attestationFormationAfest: nil as (boolean | null),
    typeQualification: nil as string,
    numeroProjet: nil as string,
    codeRNCP: nil as (string | null),
    specialiteFormation: nil as string,
    dureeEnseignement: nil as number,
    fraisAnnexesInternational: nil as boolean,
    conditionRealisation: nil as (string | null),
    modalitePedagogiqueAgora: nil as string,
    adresseRealisationIdentiqueAgora: true,
    lieuPrincipalFormationAgora: createAdresse(),
}}
export type DossierVAEIModule = ReturnType<typeof createDossierVAEIModule>


export function createDossierVAEISalarie() { return {
    ...createDossierStagiaire(),
    adresse: createAdresse(),
    naissanceFrance: true as boolean,
    communeNaissance: nil as string,
    departementNaissance: nil as string,
    // Salarie
    statut: nil as string,
    poleEmploi: nil as boolean,
    numeroPoleEmploi: nil as (string | null),
    dureePoleEmploi: nil as (number | null),
    typeMinimumSocial: nil as (string | null),
    niveauMaximumClasseEntreeFormationAgora: nil as string,
}}
export type DossierVAEISalarie = ReturnType<typeof createDossierVAEISalarie>


export function createDossierVAEITuteur() { return {
    ...createBaseDossierTuteur(),
    codeNAFEntrepriseUtilisatrice: nil as string,
    eligibilite: nil as boolean,
    employeurDirigeant: false,
    priseEnChargeFonctionTutorale: false,
    exerciceFonction2Annees: false as (boolean | null),
    formateurAfest: false as (boolean | null),
}}
export type DossierVAEITuteur = ReturnType<typeof createDossierVAEITuteur>

export function createDossierVAEIContrat() { return { 
    ...({} as {
        Id?: string | null
    }),
    natureContrat: nil as string,
    typeContrat: nil as string,
    avenantContratInitial: false as (boolean | null),
    emploiOccupe: nil as string,
    dateDebut: nil as Date,
    dateFin: nil as Date,
    dateConclusion: nil as Date,
    dureePeriodeEssai: nil as number,
    dureeHebdomadaireTravail: { hours: nil as number, minutes: nil as number },
    salaireEmbauche: nil as number,
    classificationEmploi: nil as (string | null),
    niveau: nil as string,
    coefficient: nil as (string | null),
    financementPdc: false,
    strategiePlus: false,
    dateEffet: nil as (Date | null),
    dateConclusionAvenant: nil as (Date | null),
}}
export type DossierVAEIContrat = ReturnType<typeof createDossierVAEIContrat>


export function createDossierVAEI(dispositif?: string) { return {
    ...createBaseDossier(dispositif ?? DISPOSITIF_VAEI),
    employeur: createDossierVAEIEmployeur(),
    modulePrincipal: nil as DossierVAEIModule,
    moduleAfest: null as (DossierVAEIModule | null),
    salarie: nil as DossierVAEISalarie,
    tuteurTravailTemporaire: nil as boolean,
    tuteur: nil as DossierVAEITuteur,
    contrat: createDossierVAEIContrat(),
    pointsReserve: null as DossierPointReserve[] | null | undefined,
}}
export type DossierVAEI = ReturnType<typeof createDossierVAEI>
