import { DossierDetail } from 'api/dossierAPI'
import DossierAnnulerEntreprise from '../DossierAnnulerEntreprise'
import { dossierEstAccorde, peutAnnulerDossier, peutChangerPeriodeDossier, peutCorrigerDossier, peutRompreDossier } from '.'
import DossierDeposerFactureSelfe from '../DossierDeposerFactureSelfe'
import DossierInformationRupture from '../DossierInformationRupture'
import { RuptureFormMetadata } from 'pages/dossier/metadata/RuptureFormMetadata'
import DossierAnnuler from '../DossierAnnuler'
import DossierAvenant from '../DossierAvenant'
import DossierChangerPeriode from '../DossierChangerPeriode'
import { DossierActionElement } from '../DetailDossierContrat'


export function actionsEntrepriseCorrectionAvenant(dossier: DossierDetail, actions: DossierActionElement[]) {
    actions.push(<DossierAvenant key="avenant" detail={ dossier } when={ peutCorrigerDossier } />)
}

export function actionsAnnulerDossier(dossier: DossierDetail, actions: DossierActionElement[]) {
    actions.push(<DossierAnnuler key="annuler" detail={ dossier } when={ peutAnnulerDossier } />)
}

export function actionsEntrepriseAnnulerDossier(dossier: DossierDetail, actions: DossierActionElement[]) {
    actions.push(<DossierAnnulerEntreprise key="annuler" detail={dossier} when={ peutAnnulerDossier } />)
}

export function actionsEntrepriseDeposerFacture(dossier: DossierDetail, actions: DossierActionElement[]) {
    actions.push(<DossierDeposerFactureSelfe key="deposerFacture" detail={dossier} when={ dossierEstAccorde } mode="facture" />)
}

export function actionsEntrepriseRompreDossier(dossier: DossierDetail, metadata: RuptureFormMetadata<any>, actions: DossierActionElement[]) {
    actions.push(<DossierInformationRupture key="infoRupture" formMetadata={metadata} detail={dossier} when={ peutRompreDossier } />)
}

export function actionsEntrepriseChangerPeriode(dossier: DossierDetail, actions: DossierActionElement[], 
    getDateFinValidite?: (dossier: DossierDetail) => Promise<string>) {
    actions.push(<DossierChangerPeriode key="changerPeriode" detail={ dossier } when={ peutChangerPeriodeDossier } getDateFinValidite={ getDateFinValidite } />)
}